import { Injectable, signal } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/service/data.service';
import { BoletoModuleActivationStatus, BoletoPjBank, BoletoPjBankCreate, BoletoPjBankPaginated } from '../interface/boleto-pjbank';
import { CashTitle } from '../interface/cash-title';
import { LayoutService } from './layout.service';
import { SnackbarService } from './snackbar.service';
import { NF_response } from '../interface/NF-response';
import { Client } from '../interface/client';
import { Supplier } from '../interface/supplier';

@Injectable({
  providedIn: 'root'
})
export class BoletoService {
  private _isFromMatrix = true;
  boletos = signal<BoletoPjBank[] | BoletoPjBankPaginated>([]);
  private _webhookUrl = `${environment.mkgoURL}/api/v1/pj-bank/webhook`;

  constructor(
    private http: HttpClient,
    private _dataService: DataService,
    private snackbar: SnackbarService,
    private layoutService: LayoutService
  ) { }

  async getAll(): Promise<BoletoPjBank[]> {
    const url = `${environment.mkgoURL}/api/v1/pj-bank/boletos`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const response = await firstValueFrom(this.http
      .get<BoletoPjBank[]>(url, header))

    this.boletos.set(response)

    return response;
  }

  async getAllPaginated({ page = 1, limit = 10, status, search, dueDateStart, dueDateEnd, paymentDateStart, paymentDateEnd, createDateStart, createDateEnd }: { page?: number, limit?: number, status?: string, search?: string, dueDateStart?: string, dueDateEnd?: string, paymentDateStart?: string, paymentDateEnd?: string, createDateStart?: string, createDateEnd?: string }): Promise<BoletoPjBankPaginated> {
    let url: string;
    let urlParams: string[] = [];

    if (status) urlParams.push(`status=${status}`);
    if (search) urlParams.push(`search=${search}`);
    if (dueDateStart) urlParams.push(`dueDateStart=${dueDateStart}`);
    if (dueDateEnd) urlParams.push(`dueDateEnd=${dueDateEnd}`);
    if (paymentDateStart) urlParams.push(`paymentDateStart=${paymentDateStart}`);
    if (paymentDateEnd) urlParams.push(`paymentDateEnd=${paymentDateEnd}`);
    if (createDateStart) urlParams.push(`createDateStart=${createDateStart}`);
    if (createDateEnd) urlParams.push(`createDateEnd=${createDateEnd}`);

    const urlParamsString = urlParams.length ? `&${urlParams.join('&')}` : '';

    url = `${environment.mkgoURL}/api/v1/pj-bank/boletos/paginated?page=${page}&limit=${limit}${urlParamsString}`;

    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const response = await firstValueFrom(this.http
      .get<BoletoPjBankPaginated>(url, header))

    this.boletos.set(response)

    return response;
  }

  async create(boleto: BoletoPjBankCreate): Promise<BoletoPjBank> {
    if (this._dataService.company.boletos !== BoletoModuleActivationStatus.enabled) return;

    const url = `${environment.mkgoURL}/api/v1/pj-bank/boletos`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const response = await firstValueFrom(this.http
      .post<BoletoPjBank>(url, {
        ...boleto,
        webhook: this._webhookUrl
      }, header))

    return response;
  }

  async getBoletoById({ boletoId }: { boletoId: string }): Promise<BoletoPjBank> {
    if (this._dataService.company.boletos !== BoletoModuleActivationStatus.enabled) return;

    const url = `${environment.mkgoURL}/api/v1/pj-bank/boletos/${boletoId}`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const response = await firstValueFrom(this.http.get<BoletoPjBank>(url, header))
    return response;
  }

  async cancelBoleto({ boletoId }: { boletoId: string }): Promise<void> {
    if (this._dataService.company.boletos !== BoletoModuleActivationStatus.enabled) return;

    const url = `${environment.mkgoURL}/api/v1/pj-bank/boletos/${boletoId}`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    await firstValueFrom(this.http.delete(url, header))
  }

  async cancelBoletoByTitle({ titleId }: { titleId: string }): Promise<void> {
    if (this._dataService.company.boletos !== BoletoModuleActivationStatus.enabled) return;

    const url = `${environment.mkgoURL}/api/v1/pj-bank/boletos/title/${titleId}`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    await firstValueFrom(this.http.delete(url, header))
  }


  async updateBoleto({ boletoId, boleto }: { boletoId: string, boleto: BoletoPjBankCreate }): Promise<BoletoPjBank> {
    if (this._dataService.company.boletos !== BoletoModuleActivationStatus.enabled) return;

    const url = `${environment.mkgoURL}/api/v1/pj-bank/boletos/${boletoId}`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const response = await firstValueFrom(this.http.put<BoletoPjBank>(url, boleto, header))
    return response;
  }

  async createBoletoFromOs({ osId, titles }: { osId: string, titles: string[] }): Promise<BoletoPjBank[]> {
    if (this._dataService.company.boletos !== BoletoModuleActivationStatus.enabled) return;

    try {
      this.layoutService.loader = true;

      const url = `${environment.mkgoURL}/api/v1/pj-bank/boletos/os/${osId}`;
      const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
      const response = await firstValueFrom(this.http.post<{ message: string, data: BoletoPjBank[] }>(url, { titles, webhook: this._webhookUrl }, header))

      this.snackbar.success('Boleto criado com sucesso');

      return response.data;
    } catch (error) {
      this.snackbar.error('Erro ao criar boleto');
      throw error;
    } finally {
      this.layoutService.loader = false;
    }
  }

  async createBoletoFromInvoice(data: { invoice: NF_response, titles: string[], client?: Client | Supplier }): Promise<BoletoPjBank[]> {
    if (this._dataService.company.boletos !== BoletoModuleActivationStatus.enabled) return;

    try {
      const { invoice, titles, client } = data;

      const requestData = {
        titles,
        webhook: this._webhookUrl,
        client: invoice.request.Documento.dest || client
      }
      this.layoutService.loader = true;

      const url = `${environment.mkgoURL}/api/v1/pj-bank/boletos/invoice/${invoice.id}`;
      const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
      const response = await firstValueFrom(this.http.post<{ message: string, data: BoletoPjBank[] }>(url, requestData, header))

      this.snackbar.success('Boleto criado com sucesso');

      return response.data;
    } catch (error) {
      console.log(error)
      this.snackbar.error('Erro ao criar boleto');
      throw error;
    } finally {
      this.layoutService.loader = false;
    }
  }

  async createBoletoFromTitle(data: { title?: CashTitle, titles?: string[], client?: Client | Supplier }): Promise<BoletoPjBank[]> {
    if (this._dataService.company.boletos !== BoletoModuleActivationStatus.enabled) return;

    try {
      this.layoutService.loader = true;

      const { title, titles, client } = data;

      const requestData = {
        titles: titles ? titles : [title._id],
        webhook: this._webhookUrl,
        client: {
          name: title?.supplier_client.name || client?.name,
          email: title?.supplier_client.email || client?.email,
          phone1: title?.supplier_client.phone1 || client?.phone1,
          document: title?.supplier_client.document || client?.document,
          address: title?.supplier_client.address || client?.address
        }
      }

      const url = `${environment.mkgoURL}/api/v1/pj-bank/boletos/titles`;
      const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
      const response = await firstValueFrom(this.http.post<{ message: string, data: BoletoPjBank[] }>(url, requestData, header))

      this.snackbar.success('Boleto gerado com sucesso');

      return response.data;
    } catch (error) {
      console.log(error)
      this.snackbar.error('Erro ao gerar boleto');
    } finally {
      this.layoutService.loader = false;
    }
  }

  async editBoletoClient(data: { clientId: string, formContent: BoletoPjBankCreate }): Promise<void> {
    if (this._dataService.company.boletos !== BoletoModuleActivationStatus.enabled) return;

    const url = `${environment.mkgoURL}/api/v1/pj-bank/boletos/client/${data.clientId}`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    await firstValueFrom(this.http.put(url, data.formContent, header))
  }
}
