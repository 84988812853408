import { Utilities } from "../../class/utilities";
import { detItem55 } from "../../interface/NF-e";
import { detItem65 } from "../../interface/NFC-e";
import { Client } from "../../interface/client";
import { Company } from "../../interface/company";
import { Operation } from "../../interface/operation";
import { Part } from "../../interface/part";
import { FiscalService } from "./fiscal.service";

export class FiscalParts {

  public part: Part;
  public amount: number;
  public discount: number = 0;
  public additionalDiscount: number = 0;
  public nota: FiscalService;

  constructor(part: Part, amount: number, discount: number = 0, additionalDiscount:number = 0){
    this.part = part;
    this.amount = amount;
    this.discount = discount;
    this.additionalDiscount = additionalDiscount;
  }

  public getDetItem65():detItem65 {

    const company: Company = this.nota.company;
    const operation: Operation = this.nota.operation;
    const client: Client = this.nota.client;

    const isIntern = company.address.state === client.address.state;
    const qTrib = (this.part.unitOfMeasurements === 'kilo' ? this.amount * (this.part.grossWeight || 1) : this.amount);
    const ICMS = (this.part.ICMS && this.part.ICMS.CST ? this.part.ICMS : operation.ICMS);
    const grossValue = this.amount * this.part.saleValue;
    const pis_aliquot = (this.part.PIS && this.part.PIS.CST ? this.part.PIS.Aliquot : (operation.PIS ? operation.PIS.Aliquot : 0));
    const cofins_aliquot = (this.part.COFINS && this.part.COFINS.CST ? this.part.COFINS.Aliquot : (operation.COFINS ? operation.COFINS.Aliquot : 0));
    const additionalDiscount = Utilities.calcPercentage(this.additionalDiscount, qTrib * this.part.saleValue);
    const partDiscount = Utilities.calcPercentage(this.nota.discount, qTrib * this.part.saleValue - additionalDiscount);
    const osDiscount = Utilities.calcPercentage(this.nota.discount, grossValue - additionalDiscount - partDiscount);
    const totalDiscount = additionalDiscount + partDiscount + osDiscount;
    const item: detItem65 = {
      prod: {
        xProd: this.part.description,
        NCM: this.part.NCM.toString(),
        CFOP: this.getCFOPofPart(this.part, operation, isIntern),
        CEST: this.part.CEST,
        cEAN: this.part.EANcode,
        cEANTrib: this.part.EANcode,
        cProd: this.part.code,
        indTot: 1,
        nTipoItem: this.getNtipoItemOf(this.part),
        uCOM: this.part.unitOfMeasurements,
        uTrib: this.part.unitOfMeasurements,
        qCOM: qTrib,
        qTrib: qTrib,
        vUnCom: this.part.saleValue,
        vUnTrib: this.part.saleValue,
        vProd: grossValue,
        vDesc: totalDiscount,
      },
      imposto: {
        ICMS: {
          CST: ICMS.CST,
          modBC: 0,
          orig: this.part.origin,
          pICMS: ICMS ? ICMS.Aliquot : 0,
          vICMS_icms: Utilities.calcPercentage(ICMS ? ICMS.Aliquot : 0, grossValue),
          vBC: company.crt === "1" || company.crt === "4" ? 0 : grossValue,
        } as any
      }
    };
    return item;
  }

  public getDetItem55():detItem55 {

    const company: Company = this.nota.company;
    const operation: Operation = this.nota.operation;
    const client: Client = this.nota.client;

    const isIntern = company.address.state === client.address.state;
    const qTrib = (this.part.unitOfMeasurements === 'kilo' ? this.amount * (this.part.grossWeight || 1) : this.amount);
    const ICMS = (this.part.ICMS && this.part.ICMS.CST ? this.part.ICMS : operation.ICMS);
    const grossValue = this.amount * this.part.saleValue;
    const pis_aliquot = (this.part.PIS && this.part.PIS.CST ? this.part.PIS.Aliquot : (operation.PIS ? operation.PIS.Aliquot : 0));
    const cofins_aliquot = (this.part.COFINS && this.part.COFINS.CST ? this.part.COFINS.Aliquot : (operation.COFINS ? operation.COFINS.Aliquot : 0));
    const additionalDiscount = Utilities.calcPercentage(this.additionalDiscount, qTrib * this.part.saleValue);
    const partDiscount = Utilities.calcPercentage(this.nota.discount, qTrib * this.part.saleValue - additionalDiscount);
    const osDiscount = Utilities.calcPercentage(this.nota.discount, grossValue - additionalDiscount - partDiscount);
    const totalDiscount = additionalDiscount + partDiscount + osDiscount;
    const item: detItem55 = {
      prod: {
        xProd: this.part.description,
        NCM: this.part.NCM ? this.part.NCM.toString() : "0",
        CFOP: this.getCFOPofPart(this.part, operation, isIntern),
        CEST: this.part.CEST,
        cEAN: this.part.EANcode,
        cEANTrib: this.part.EANcode,
        cProd: this.part.code,
        indTot: 1,
        nTipoItem: this.getNtipoItemOf(this.part),
        uCOM: this.part.unitOfMeasurements,
        uTrib: this.part.unitOfMeasurements,
        qCOM: qTrib,
        qTrib: qTrib,
        vUnCom: this.part.saleValue,
        vUnTrib: this.part.saleValue,
        vProd: (qTrib * this.part.saleValue),
        vDesc: totalDiscount,
        pRedBC: 0
      } as any,
      imposto: {
        ICMS: {
          CST: ICMS.CST,
          modBC: 0,
          orig: this.part.origin,
          pICMS: ICMS ? ICMS.Aliquot : 0,
          vICMS_icms: Utilities.calcPercentage(ICMS ? ICMS.Aliquot : 0, (qTrib * this.part.saleValue)),
          vBC: company.crt === "1" || company.crt === "4" ? 0 : (qTrib * this.part.saleValue),
        } as any,
        PIS: {
          CST_pis: this.part.PIS.CST || operation.PIS.CST,
          pPIS: pis_aliquot,
          vPIS: (pis_aliquot ? (grossValue * pis_aliquot / 100) : 0)
        },
        COFINS: {
          CST_cofins: this.part.COFINS.CST || operation.COFINS.CST,
          vCOFINS: (cofins_aliquot ? (this.getLiquidValue() * cofins_aliquot / 100) : 0)
        }
      }
    };
    return item;
  }

  public getLiquidValue(){
    let valor = (this.part.saleValue*this.amount)
    let valorFinal = valor-Utilities.calcPercentage(this.discount,valor);
    return valorFinal-Utilities.calcPercentage(this.additionalDiscount,valorFinal);
  }

  private getNtipoItemOf(part: Part): detItem65["prod"]['nTipoItem'] {
    return (part.groupObject ? ([2, 3].includes(part.groupObject.type) ? 4/** combustível/lubrificante */ : 0) : 0)
  }

  private getCFOPofPart(part: Part, operation: Operation, sameState = true, devolution?: boolean): any {
    const stateOperation = (sameState ? 'stateOperations' : 'interstateOperations');
    let cfopCode = "";

    if (!devolution) {
      // get CFOP/interstateCFOP of part
      if (part.ICMS) {
        if (sameState) {
          cfopCode = this.part.ICMS.CFOP ? this.part.ICMS.CFOP : "";
        } else {
          cfopCode = this.part.ICMS.CFOP_I ? this.part.ICMS.CFOP_I : "";
        }
      }

      // set CFOP according part group
      if (!cfopCode && part.groupObject) {
        if ([2, 3].includes(part.groupObject.type)) {
          /** combustível/lubrificante */
          cfopCode = operation[stateOperation].naturalezaCombLubrificante || cfopCode;
        }
        if (part.groupObject.type === 7) {
          /** industrializado */
          cfopCode = operation[stateOperation].naturalezaItemIndustrializado || cfopCode;
        }
      }

      if (!cfopCode && operation && operation.ICMS) {
        if (sameState) {
          cfopCode = operation.ICMS.CFOP || cfopCode
        } else {
          cfopCode = operation.ICMS.CFOP_I || cfopCode
        }
      }
      return cfopCode;
    }
  }
}
