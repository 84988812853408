
import { Entrega } from "src/app/interface/NF-e";
import { Utilities } from "../../../../../class/utilities";
import { Client } from "../../../../../interface/client";
import { Part } from "../../../../../interface/part";
import { PaymentCondition } from "../../../../../interface/paymentCondition";
import { Finance } from "../../../../../interface/ro-finance";
import { ORDER_STATUS } from "../const/order-status";
import { PRESENCE_INDICATION } from "../const/presence-indication";
import { IOrderItem } from "../interface/order-item.interface";
import { IOrder } from "../interface/order.interface";
import { OrderItem } from "./order-item";

export class OrderFull implements IOrder {
  _id?: string;
  /** Auto-generated by API */
  code?: number;
  client: string;
  grossValue: number;
  name: string;
  operation: string;
  parts: IOrderItem[];
  delivery?: Entrega;
  finances: Finance[];
  phone: string;
  presence: string;
  refer: string;
  seller: string;
  status?: ORDER_STATUS;
  /** The observation to be sent into invoice */
  obsevation?: string;
  /** The id of cashTitles generateds by this order */
  title?: string[];
  companyOrder: string;

  avaliable?: boolean;
  company?: string;
  createdAt?: string;
  updatedAt?: string;

  idFarmer: string;

  private _generalDiscont: number = 0;
  private _discountValue: number = 0;

  // local only
  /** Map<part_id, OrderItem> */
  itemsMap: Map<string, OrderItem>;

  /** A map with removed items from itemsMap, used to revertStock */
  removedItemsMap: Map<string, number>;
  private _liquidValue: number;

  constructor(apiOrder?: IOrder, parts: Part[] = [], conditions: PaymentCondition[] = []) {
    if (apiOrder) {
      if(apiOrder._id){
        this._id = apiOrder._id;
      }
      this.client = apiOrder.client;
      this.grossValue = apiOrder.grossValue;
      this._generalDiscont = apiOrder.generalDiscont || 0;
      this._discountValue = apiOrder.discountValue || 0;
      this.name = apiOrder.name;
      this.delivery = apiOrder.delivery;
      this.operation = apiOrder.operation;
      this.parts = apiOrder.parts;
      this.phone = apiOrder.phone;
      this.presence = apiOrder.presence;
      this.refer = apiOrder.refer;
      this.seller = apiOrder.seller;
      this.status = apiOrder.status;
      if(apiOrder.createdAt){
        this.createdAt = apiOrder.createdAt;
      }
      if(apiOrder.code){
        this.code = apiOrder.code;
      }
      if(apiOrder.observation){
        this.obsevation = apiOrder.observation;
      }
      if(apiOrder.title){
        this.title = apiOrder.title;
      }
      if (apiOrder.idFarmer) {
        this.idFarmer = apiOrder.idFarmer;
      }

      this.finances = [];
      for (const apiFinance of apiOrder.paymentCondition) {
        const condition = conditions.find(cond => cond._id === apiFinance.paymentCondition);
        const finance = new Finance(apiFinance, condition);
        this.finances.push(finance)
      }
    }

    // define the initial status
    if (!Number.isFinite(this.status)) {
      this.status = ORDER_STATUS.ORÇAMENTO;
    }

    // define items map
    this.itemsMap = new Map([]);
    for (const item of this.parts || []) {
      const part = parts.find(p => p.id === item.part);
      const orderItem = new OrderItem(item, part, item.amount)
      this.itemsMap.set(item.part, orderItem);
    }
    this.removedItemsMap = new Map();

    // general discount as 0 by default
    if (!this._generalDiscont) {
      this._generalDiscont = 0;
    }

    if (!this.presence) {
      // default value for presence indicator
      this.presence = PRESENCE_INDICATION.slice().pop().code.toString(); // 0 - "Não se aplica"
    }

    this.calculateTotal();
  }

  public calculateTotal() {
    if (!this.itemsMap) {
      this.grossValue = 0;
      this._liquidValue = 0;
      this._discountValue = 0;
      return;
    }

    this.parts = Array.from(this.itemsMap.values()).map(i => {
      const item: IOrderItem = {
        additionalDiscount: i.additionalDiscount,
        discountValue: i.discountValue,
        amount: i.amount,
        liquidValue: i.liquidValue,
        observation: i.observation,
        part: i.part,
        purchaseAverage: i.purchaseAverage,
        purchaseValue: i.purchaseValue,
        saleValue: i.saleValue,
        seq: i.seq,
        id: i.id,
        nroPedido: i.nroPedido,
        seqItem: i.seqItem
      }
      if (i.mechanic) {
        item.mechanic = i.mechanic;
      }
      return item;
    });

    this.grossValue = this.parts.length ? this.parts.slice().map(item => item.liquidValue).reduce((a, b) => a + b) : 0;
    const orderDiscount = Utilities.calcPercentage(this.generalDiscont, this.grossValue);
    // avoid loop, don't use the liquidValue setter here
    this._liquidValue = this.discountValue === orderDiscount ? (this.grossValue - orderDiscount) : (this.grossValue - (this.discountValue || 0));
  }

  public clearItems() {
    this.itemsMap.clear();
    this.calculateTotal();
  }

  public setClient(client: Partial<Client>) {
    this.name = client.name;
    this.phone = client.phone1 || client.phone2;
    if (client.id) {
      this.client = client.id;
    }
  }

  public toAPI(farmer = false): IOrder {
    let order: IOrder = {
      client: this.client,
      generalDiscont: this.generalDiscont,
      delivery: this.delivery,
      grossValue: this.grossValue,
      name: this.name,
      operation: this.operation,
      parts: this.parts,
      paymentCondition: this.finances ? this.finances.slice().map(fin => fin.toAPI()) : [],
      phone: this.phone,
      presence: this.presence,
      refer: this.refer,
      seller: this.seller,
      status: this.status
    }
    if (this._id) {
      order._id = this._id;
    }
    if(this.delivery){
      order.delivery = this.delivery;
      order.delivery.cPais_entr = 1058; // Brasil
      order.delivery.xPais_entr = 'Brasil';
    }
    if(this.obsevation){
      order.observation = this.obsevation;
    }
    if(this.title){
      order.title = this.title;
    }
    if (farmer) {
      order.idFarmer = this.idFarmer;
    }
    if(this._discountValue){
      order.discountValue = this._discountValue;
    }
    if(this._generalDiscont){
      order.generalDiscont = this._generalDiscont;
    }
    return order;
  }

  setAmountOf(part: Part, newAmount: number): void {
    const item = this.itemsMap.get(part.id);
    if (newAmount === 0) {
      // remove
      this.itemsMap.delete(part.id);
      if(this._id){ // avoid to change stock of not included items in new orders
        this.removedItemsMap.set(part.id, item.oldAmount);
      }
      this._setItemsSeq();
    } else {
      if (item) {
        // update
        item.amount = newAmount;
        this.itemsMap.set(part.id, item);
      } else {
        // insert
        const newItem = OrderItem.fromPart(part, newAmount);
        this.itemsMap.set(part.id, newItem);
        this.removedItemsMap.delete(part.id);
        this._setItemsSeq();
      }
    }
    this.calculateTotal();
  }

  setNroPedido(itemId: string, value: string) {
    const item = this.itemsMap.get(itemId);
    item.nroPedido = value;
    this.itemsMap.set(itemId, item);
  }

  setSeqItem(itemId: string, value: number) {
    const item = this.itemsMap.get(itemId);
    item.seqItem = value;
    this.itemsMap.set(itemId, item);
  }

  setSaleValueOf(partId, saleValue: number): void {
    const item = this.itemsMap.get(partId);
    item.saleValue = saleValue;
    this.itemsMap.set(partId, item);
    this.calculateTotal();
  }

  setAdditionalDiscountOf(partId, discount: number): void {
    const item = this.itemsMap.get(partId);
    item.additionalDiscount = discount;
    this.itemsMap.set(partId, item);
    this.calculateTotal();
  }

  setDiscountValuePecentOf(partId, discount: number) {
    const item = this.itemsMap.get(partId);
    item.discountValue = discount;
    this.itemsMap.set(partId, item);
    this.calculateTotal();
  }

  public get liquidValue(): number {
    return this._liquidValue;
  }
  public set liquidValue(value: number) {
    const paidPercentage = Utilities.getPercentage(value, this.grossValue, 8);
    // avoid a loop, don't use the generalDiscount setter here
    this._generalDiscont = 100 - paidPercentage;
    this._liquidValue = value;
    this.calculateTotal();
  }

  public get generalDiscont(): number {
    return this._generalDiscont;
  }
  public set generalDiscont(value: number) {
    this._discountValue = Utilities.calcPercentage(value, this.grossValue);
    this._generalDiscont = value;
    this.calculateTotal();
  }

  public get discountValue(): number {
    return this._discountValue;
  }

  public set discountValue(value: number) {
    this._generalDiscont = Utilities.getPercentage(value, this.grossValue);
    this._discountValue = value;
    this.calculateTotal();
  }

  private _setItemsSeq() {
    const keys = Array.from(this.itemsMap.keys());
    for (let i = 0; i < this.itemsMap.size; i++) {
      const item = this.itemsMap.get(keys[i]);
      item.seq = i + 1;
    }
  }

  public get canEdit(){
    return this.status && this.status < ORDER_STATUS.FATURADO;
  }

  getPartsWithOutStock(): Array<OrderItem>{
    return Array.from(this.itemsMap.values()).filter(orderItem => orderItem.needStock())
  }

}
