import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { first, map } from "rxjs/operators";




import { environment } from "../../../../../../environments/environment";
import { CashTitle } from "../../../../../interface/cash-title";
import { ITEM_MODIFY, ItemResponse } from "../../../../../interface/item-response";
import { Part } from "../../../../../interface/part";
import { PaymentCondition } from "../../../../../interface/paymentCondition";
import { ClientService } from "../../../../../service/client.service";
import { FiscalParts } from "../../../../../service/fiscal/fiscal.parts";
import { FiscalService } from "../../../../../service/fiscal/fiscal.service";
import { NfceService } from "../../../../../service/fiscal/nfce.service";
import { NfeService } from "../../../../../service/fiscal/nfe.service";
import { OperationService } from "../../../../../service/operation.service";
import { PartService } from "../../../../../service/part.service";
import { PaymentService } from "../../../../../service/payment.service";
import { PreviewTitlesPipe } from "../../../../../shared/pipes/preview-titles.pipe";
import { IOrderItem } from '../interface/order-item.interface';
import { IOrder } from "../interface/order.interface";
import { OrderFull } from '../model/order.model';
import { Utilities } from "../../../../../class/utilities";
import { Company } from "../../../../../interface/company";
import { Operation } from "../../../../../interface/operation";
import { ApiFinanceIN, Finance, PreviewTitle } from "../../../../../interface/ro-finance";
import { DataService } from "../../../../../service/data.service";
import { firstValueFrom } from "rxjs";
import { IFiscalParts } from "src/app/interface/ifiscal-parts";

@Injectable({
  providedIn: "root" // also used into InvoiceModule
})
export class OrderService {

  private _isFromMatrix = false;


  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private fiscal: FiscalService,
    private operationService: OperationService,
    private clientService: ClientService,
    private partService: PartService,
    private previewTitlesPipe: PreviewTitlesPipe,
    private paymentService: PaymentService
  ) {
  }

  async getAll(cnpj?: string): Promise<IOrder[]> {
    const url = `${environment.mkgoURL}/api/v1/order`;
    const header = await firstValueFrom(this.dataService.httpOptions(cnpj || this._isFromMatrix));
    return firstValueFrom(this.http.get<{ order: IOrder[] }>(url, header)
      .pipe(
        first(),
        map(resp => resp.order),
        map(orders => orders.map(order => {
          order.cnpj = cnpj || this.dataService.company.cnpj;
          return order;
        }))
      ));
  }

  async get(id: string): Promise<IOrder> {
    const url = `${environment.mkgoURL}/api/v1/order/` + id;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const response: any = await this.http.get(url, header).pipe(first()).toPromise();
    let order: IOrder = response;
    return order;
  }

  async create(order: IOrder) {
    const url = `${environment.mkgoURL}/api/v1/order`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const body = IOrder.removeReadOnlyProps(order);
    const response: any = await this.http.post(url, body, header).pipe(first()).toPromise();
    let orders: string= response["id"];
    return orders;
  }


  async update(order: IOrder) {
    const url = `${environment.mkgoURL}/api/v1/order/` + order._id;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const body = IOrder.removeReadOnlyProps(order)
    const response: any = await this.http.put(url, body, header).pipe(first()).toPromise();
    let orders: string = response["id"];
    return orders;
  }

  async save(order: IOrder): Promise<string> {
    if (order._id != null) {
      return this.update(order) as any;
    }
    return this.create(order);
  }

  async getNF(orderModel: OrderFull, nf: NfeService | NfceService, company: Company) {
    let operation = await this.operationService.getById(orderModel.operation);
    let client = await this.clientService.get(orderModel.client);

    this.fiscal.setNota(nf);
    await this.fiscal.setEmitByCompany(company);
    await this.fiscal.setDestByClient(client);
    await this.fiscal.setOperation(operation);
    /** @todo refactor */
    await this.setParts(orderModel.parts, orderModel.generalDiscont);
    this.fiscal.setIde();
    return this.fiscal.getGeneretedNfe();
  }

  async orderItemToItensResponse(orderItens: any[]) {
    let itemResponse: ItemResponse[] = [];
    let part: Part;
    for (let item of orderItens) {
      part = await this.partService.get(item.part);
      itemResponse.push({
        "code": Utilities.removeSpecialCharacters(`${part.code}`).trim(),
        "keyId": part.id,
        "modify": ITEM_MODIFY.not_modified,
        "amount": item.amount
      });
    }
    return itemResponse;
  }

  public async getParts(order: IOrder): Promise<Part[]> {
    const parts: Part[] = [];
    let part: Part;
    for (let orderItem of order.parts) {
      part = await this.partService.get(orderItem.part as any);
      parts.push(part)
    }
    return parts;
  }

  public async getTitlesByOrder(order: OrderFull, invoiceNumber: number, operation: Operation) {
    const apiOrder = order.toAPI();
    const condition = apiOrder.paymentCondition;
    let titles: CashTitle[] = [];
    let titlesPreview: PreviewTitle[] = [];
    let titlesCondition: PreviewTitle[];
    for (let conditionItem of condition) {
      titlesCondition = await this.getTitlesByRoFinance(conditionItem);
      titlesPreview = titlesPreview.concat(titlesCondition);
    }

    for (const preview of titlesPreview) {
      let title: CashTitle = {
        /**
         * create title as inactive by default
         *
         * set to active when the invoice created by this order is approved
         *
        */
        status: 0,
        value: preview.value,
        movementDate: new Date(),
        expirationDate: new Date(preview.expiration.valueOf()),
        typeId: operation.titleTypeId,
        supplier_clientId: order.client,
        paymentConditionId: preview.condition._id,
        expenseTypeId: operation.expenseTypeId,
        parcel: preview.parcel,
        balance: preview.value,
        paymentCondition: preview.condition,
        invoiceNumber: invoiceNumber.toString(),
        adiantamento: false,
        orderId: order._id,
        companyOrder: order.code.toString()
      }
      titles.push(title)
    }
    return titles;
  }

  private async getTitlesByRoFinance(apiObj: ApiFinanceIN): Promise<PreviewTitle[]> {
    const payment: PaymentCondition = await this.paymentService.getById(apiObj.paymentCondition || apiObj._id);
    const finance = new Finance(apiObj, payment);
    return this.previewTitlesPipe.transform(finance);
  }

  private async getFiscalPart(orderModelItem: IOrderItem, generalDiscont: number): Promise<FiscalParts> {
    let part: Part = await this.partService.get(orderModelItem.part);
    return new FiscalParts(part, orderModelItem.amount, orderModelItem.additionalDiscount, generalDiscont);
  }

  private async setParts(orderModelItens: IOrderItem[], generalDiscount: number) {
    let fiscalPart: IFiscalParts;
    for (let i = 0; i < orderModelItens.length; i++) {
      fiscalPart = await this.getFiscalPart(orderModelItens[i], generalDiscount);
      this.fiscal.addPart(fiscalPart);
    }
  }

}
