import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Part } from '../interface/part';
import { DataService } from '../service/data.service';
import { PartService } from '../service/part.service';
import { MkgOSPart } from '../class/mkg-os-part';
import { LOG_REPORTER_KEYS, LogReporterService } from '../service/log-reporter.service';
import { MkgOS } from '../class/mkg-os';
import { MoveTypes } from '../shared/lists/move-type';
import { MovementService } from '../service/movement.service';
import { OperationFunction } from '../shared/lists/operation-functions';
import { NF_response } from '../interface/NF-response';
import { Operation } from '../interface/operation';
import { InvoiceItem } from '../interface/invoice-item';
import { OrderFull } from '../component/page/company/order/model/order.model';
import { OrderItem } from '../component/page/company/order/model/order-item';
import { IOrder } from '../component/page/company/order/interface/order.interface';
import { IOrderItem } from '../component/page/company/order/interface/order-item.interface';
import { FieldChanged } from '../shared/type-aliases/field-changed';
import moment from 'moment';


/** Handle stock of part */
@Injectable({
  providedIn: 'root'
})
export class StockController {

  private _partEdited: PartEdited;

  constructor(
    private partService: PartService,
    private snackbar: MatSnackBar,
    private translateService: TranslateService,
    private router: Router,
    private dataService: DataService,
    private _logReporter: LogReporterService,
    private _movementService: MovementService,
  ) {
  }

  public async onBudgetTurnedIntoOS(osPart: MkgOSPart, movementData: {
    OS: MkgOS;
    movementCaller: string;
    operationFunction: OperationFunction;
    isSupplementaryApproved: boolean;
  }): Promise<void> {
    const { OS, movementCaller, operationFunction, isSupplementaryApproved } = movementData

    const partId = typeof osPart.part === "string" ? osPart.part : osPart.part.id;

    const txtLogger = this._logReporter.textLogger(
      `StockController.onBudgetTurnedIntoOS(partId: ${partId}, amount: ${osPart.amount()}); previousCallers: ${movementCaller}; StockController.onBudgetTurnedIntoOS()`,
      LOG_REPORTER_KEYS.STOCK);
    const errLogger = this._logReporter.errorLogger("StockController.onBudgetTurnedIntoOS()", LOG_REPORTER_KEYS.STOCK);
    try {
      let part = await this.partService.get(partId);
      const loginfo = {
        code: part.code,
        stock: part.stock,
        requestedStock: part.requestedStock,
        inOrderStock: part.inOrderStock,
        availableStock: part.availableStock,
        osId: OS.id,
        osCode: OS.code,
        osBudget: OS.budgetCode,
      }
      txtLogger(JSON.stringify({ antes: loginfo }));
      part.inOrderStock = (part.inOrderStock || 0) + osPart.amount();
      this.calcAvailableStockOf(part, movementCaller);
      await this.partService.addOrUpdate(part, "StockController.onBudgetTurnedIntoOS()");
      this.verifyStockMinOf(part);

      await this._movementService.createMovementFromOS({
        moveType: isSupplementaryApproved ? MoveTypes.approvedComplementar : MoveTypes.openedRo,
        OS,
        roPart: osPart,
        operationFunction,
        part,
        diference: osPart.amount()
      }, movementCaller + "; StockController.onBudgetTurnedIntoOS()");
    } catch (error) {
      console.error(error);
      errLogger(error);
    }
  }

  public async onOSrevertedToBudget(osPart: MkgOSPart, movementData: {
    OS: MkgOS;
    movementCaller: string;
    operationFunction: OperationFunction;
    isSupplementaryBudget: boolean;
  }): Promise<void> {
    const { OS, movementCaller, operationFunction, isSupplementaryBudget } = movementData;
    const partId = typeof osPart.part === "string" ? osPart.part : osPart.part.id;

    const txtLogger = this._logReporter.textLogger(
      `StockController.onOSrevertedToBudget(partId: ${partId}, amount: ${osPart.amount()}); previousCallers: ${movementCaller}; StockController.onOSrevertedToBudget()`,
      LOG_REPORTER_KEYS.STOCK);
    const errLogger = this._logReporter.errorLogger("StockController.onOSrevertedToBudget()", LOG_REPORTER_KEYS.STOCK);
    try {
      let part = await this.partService.get(partId);
      const loginfo = {
        code: part.code,
        stock: part.stock,
        requestedStock: part.requestedStock,
        inOrderStock: part.inOrderStock,
        availableStock: part.availableStock,
        osId: OS.id,
        osCode: OS.code,
        osBudget: OS.budgetCode,
        isSupplementaryBudget
      }

      const dayToCompareAndPreventStockErrors = moment("2025-04-01T03:00:00.000Z").startOf("day");

      if (!isSupplementaryBudget || (isSupplementaryBudget && (osPart.createdAt && osPart.createdAt?.isBefore(dayToCompareAndPreventStockErrors, "hours")))) {
        txtLogger(JSON.stringify({ antes: loginfo }));
        part.inOrderStock = (part.inOrderStock || 0) - osPart.amount();
        this.calcAvailableStockOf(part, movementCaller);
        await this.partService.addOrUpdate(part, "StockController.onOSrevertedToBudget()");
        this.verifyStockMinOf(part);

        await this._movementService.createMovementFromOS({
          moveType: isSupplementaryBudget ? MoveTypes.rejectedComplementar : MoveTypes.turnedIntoRejected,
          OS,
          roPart: osPart,
          operationFunction,
          part,
          diference: osPart.amount()
        }, movementCaller + "; StockController.onOSrevertedToBudget()");
      }
    } catch (error) {
      console.error(error);
      errLogger(error);
    }
  }

  // apiMovementToPost nao criado por conta de que iria duplicar a movimentação
  public async onOSfinished(part: Part, amount: number, movementData: {
    value: number;
    operation: Operation;
    invoice: NF_response;
    senderID: string;
    caller: string;
    OS: MkgOS
  }): Promise<Part> {
    const { OS, caller, operation, invoice, value, senderID } = movementData

    const txtLogger = this._logReporter.textLogger(
      `StockController.onOSfinished(partId: ${part.id}, amount: ${amount}); previousCallers: ${caller}; StockController.onOSfinished()`,
      LOG_REPORTER_KEYS.STOCK);
    const errLogger = this._logReporter.errorLogger("StockController.onOSfinished()", LOG_REPORTER_KEYS.STOCK);
    try {
      const loginfo = {
        code: part.code,
        stock: part.stock,
        requestedStock: part.requestedStock,
        inOrderStock: part.inOrderStock,
        availableStock: part.availableStock,
        invoiceNumber: invoice.numero,
        invoiceId: invoice.id,
        osBudgetCode: OS.budgetCode,
        osNumber: OS.codeSystem,
        partId: part.id
      }
      txtLogger(JSON.stringify({ antes: loginfo }));
      if (operation.changeStock) {
        part.stock = (part.stock || 0) - amount;
        part.inOrderStock = (part.inOrderStock || 0) - amount;
        this.calcAvailableStockOf(part, caller);
        this.verifyStockMinOf(part);
        await this.partService.addOrUpdate(part, "StockController.onOSfinished()");
      }

      await this._movementService.createMovementFromNF({
        part,
        amount,
        value,
        operation,
        invoice,
        senderID,
        caller: caller + "; StockController.onOSfinished()",
        OS
      });

      return part
    } catch (error) {
      console.error(error);
      errLogger(error);
    }
  }

  public async onEntryInvoiceCreated(part: Part, amount: number, movementData: {
    item: InvoiceItem;
    operation: Operation;
    invoice: NF_response;
    senderID: string;
    moveType: MoveTypes;
    caller: string;
    isCanceled: boolean;
    isSupplier: boolean;
    OSId?: string;
    orderId?: string;
  }): Promise<Part> {
    const { isSupplier, caller, invoice, item, moveType, operation, senderID, isCanceled, OSId, orderId } = movementData;

    const txtLogger = this._logReporter.textLogger(
      `StockController.onEntryInvoiceCreated(partId: ${part.id}, amount: ${amount}); previousCallers: ${caller}; StockController.onEntryInvoiceCreated()`,
      LOG_REPORTER_KEYS.STOCK);
    const errLogger = this._logReporter.errorLogger("StockController.onEntryInvoiceCreated()", LOG_REPORTER_KEYS.STOCK);
    try {
      const loginfo = {
        code: part.code,
        stock: part.stock,
        requestedStock: part.requestedStock,
        inOrderStock: part.inOrderStock,
        availableStock: part.availableStock,
        invoiceNumber: invoice?.numero,
        invoiceId: invoice?.id,
        OSId,
        orderId,
        isCanceled
      }
      txtLogger(JSON.stringify({ antes: loginfo }));
      if (operation.changeStock) {
        part.stock = (part.stock || 0) + amount;
        this.calcAvailableStockOf(part, caller);
        await this.partService.addOrUpdate(part, "StockController.onEntryInvoiceCreated()");
      }

      await this._movementService.createMovementFromInvoice({
        caller: caller + "; StockController.onEntryInvoiceCreated()",
        invoice,
        item,
        moveType,
        operation,
        part,
        senderID,
        amount,
        operationFuntion: isCanceled && operation.changeStock ? "Cancelamento" : operation.operationFunction,
        OSId,
        orderId,
        isSupplier
      })

      return part
    } catch (error) {
      console.error(error);
      errLogger(error);
    }
  }

  public async onOutPutInvoiceCreated(partId: string, amount: number, movementData: {
    item: InvoiceItem;
    operation: Operation;
    invoice: NF_response;
    senderID: string;
    moveType: MoveTypes;
    caller: string;
    isCanceled: boolean;
    isSupplier: boolean;
    OSId?: string;
    orderId?: string;
  }): Promise<void> {
    const { caller, invoice, item, moveType, operation, senderID, isCanceled, OSId, orderId, isSupplier } = movementData;

    const txtLogger = this._logReporter.textLogger(
      `StockController.onOutPutInvoiceCreated(partId: ${partId}, amount: ${amount}); previousCallers: ${caller}; StockController.onOutPutInvoiceCreated()`,
      LOG_REPORTER_KEYS.STOCK);
    const errLogger = this._logReporter.errorLogger("StockController.onOutPutInvoiceCreated()", LOG_REPORTER_KEYS.STOCK);
    try {
      let part = await this.partService.get(partId);
      const loginfo = {
        code: part.code,
        stock: part.stock,
        requestedStock: part.requestedStock,
        inOrderStock: part.inOrderStock,
        availableStock: part.availableStock,
        isCanceled,
        OSId,
        orderId,
      }
      txtLogger(JSON.stringify({ antes: loginfo }));
      if (operation.changeStock) {
        part.stock = (part.stock || 0) - amount;
        this.calcAvailableStockOf(part, caller);
        this.verifyStockMinOf(part)
      }
      await this.partService.addOrUpdate(part, "StockController.onOutPutInvoiceCreated()");

      await this._movementService.createMovementFromInvoice({
        caller: caller + "; StockController.onOutPutInvoiceCreated()",
        invoice,
        item,
        moveType,
        operation,
        part,
        senderID,
        amount,
        operationFuntion: isCanceled && operation.changeStock ? "Cancelamento" : operation.operationFunction,
        OSId,
        orderId,
        isSupplier
      })
    } catch (error) {
      console.error(error);
      errLogger(error);
    }
  }

  public async onInvoiceEntryCanceled(partId: string, amount: number, movementData: {
    item: InvoiceItem;
    operation: Operation;
    invoice: NF_response;
    senderID: string;
    moveType: MoveTypes;
    caller: string;
    isSupplier: boolean;
    OSId?: string;
    orderId?: string;
  }) {
    const { caller, invoice, item, moveType, operation, senderID, OSId, orderId, isSupplier } = movementData;

    const txtLogger = this._logReporter.textLogger(
      `StockController.onInvoiceEntryCanceled(partId: ${partId}, amount: ${amount}); previousCallers: ${caller}; StockController.onOutPutInvoiceCreated()`,
      LOG_REPORTER_KEYS.STOCK);
    const errLogger = this._logReporter.errorLogger("StockController.onInvoiceEntryCanceled()", LOG_REPORTER_KEYS.STOCK);
    try {
      let part = await this.partService.get(partId);
      const loginfo = {
        code: part.code,
        stock: part.stock,
        requestedStock: part.requestedStock,
        inOrderStock: part.inOrderStock,
        availableStock: part.availableStock,
        invoiceNumber: invoice?.numero,
        invoiceId: invoice?.id,
        OSId,
        orderId
      }
      txtLogger(JSON.stringify({ antes: loginfo }));
      if (operation.changeStock) {
        part.stock = (part.stock || 0) - amount;
        this.calcAvailableStockOf(part, caller);
        await this.partService.addOrUpdate(part, "StockController.onInvoiceEntryCanceled()");
      }

      await this._movementService.createMovementFromInvoice({
        caller: caller + "; StockController.onOutPutInvoiceCreated()",
        invoice,
        item,
        moveType,
        operation,
        part,
        senderID,
        amount,
        operationFuntion: "Cancelamento",
        OSId,
        orderId,
        isSupplier
      })
    } catch (error) {
      console.error(error)
      errLogger(error)
      console.warn(`O estoque da peça de id "${partId}" não foi alterado`)
    }
  }

  public async onInvoiceOutCanceled(partId: string, amount: number, movementData: {
    item: InvoiceItem;
    operation: Operation;
    invoice: NF_response;
    senderID: string;
    moveType: MoveTypes;
    caller: string;
    isSupplier: boolean;
    OSId?: string;
    orderId?: string;
  }) {
    const { caller, invoice, item, moveType, operation, senderID, OSId, orderId, isSupplier } = movementData;

    const txtLogger = this._logReporter.textLogger(
      `StockController.onInvoiceOutCanceled(partId: ${partId}, amount: ${amount}); previousCallers: ${caller}; StockController.onOutPutInvoiceCreated()`,
      LOG_REPORTER_KEYS.STOCK);
    const errLogger = this._logReporter.errorLogger("StockController.onInvoiceOutCanceled()", LOG_REPORTER_KEYS.STOCK);
    try {
      let part = await this.partService.get(partId);
      const loginfo = {
        code: part.code,
        stock: part.stock,
        requestedStock: part.requestedStock,
        inOrderStock: part.inOrderStock,
        availableStock: part.availableStock,
        invoiceNumber: invoice?.numero,
        invoiceId: invoice?.id,
        OSId,
        orderId
      }
      txtLogger(JSON.stringify({ antes: loginfo }));
      if (operation.changeStock) {
        part.stock = (part.stock || 0) + amount;
        this.calcAvailableStockOf(part, caller);
        await this.partService.addOrUpdate(part, "StockController.onInvoiceOutCanceled()");
      }

      await this._movementService.createMovementFromInvoice({
        caller: caller + "; StockController.onOutPutInvoiceCreated()",
        invoice,
        item,
        moveType,
        operation,
        part,
        senderID,
        amount,
        operationFuntion: "Cancelamento",
        OSId,
        orderId,
        isSupplier
      })
    } catch (error) {
      console.error(error)
      errLogger(error);
      console.warn(`O estoque da peça de id "${partId}" não foi alterado`)
    }
  }

  /** Add requested stock */
  public async onOrderItemAdded(partId: string, amount: number, movementData: {
    order: OrderFull;
    orderItem: OrderItem;
    moveType: MoveTypes;
    operationFunction: OperationFunction;
    caller: string;
    operationId: string;
  }) {
    const { order, orderItem, moveType, operationFunction, caller, operationId } = movementData;

    const txtLogger = this._logReporter.textLogger(
      `StockController.onOrderItemAdded(partId: ${partId}, amount: ${amount}); previousCallers: ${caller}; StockController.onOrderItemAdded()`,
      LOG_REPORTER_KEYS.STOCK);
    const errLogger = this._logReporter.errorLogger("StockController.onOrderItemAdded()", LOG_REPORTER_KEYS.STOCK);
    try {
      const part = await this.partService.get(partId);
      const loginfo = {
        code: part.code,
        stock: part.stock,
        requestedStock: part.requestedStock,
        inOrderStock: part.inOrderStock,
        availableStock: part.availableStock,
        orderNumber: order?.code,
        companyOrder: order?.companyOrder,
      }
      txtLogger(JSON.stringify({ antes: loginfo }));
      part.requestedStock = (part.requestedStock || 0) + amount;
      part.availableStock = this.calcAvailableStockOf(part, caller);
      await this.partService.addOrUpdate(part, "StockController.onOrderItemAdded()");

      await this._movementService.createMovementFromOrder({
        caller: caller + "; StockController.onOrderItemAdded()",
        moveType,
        operationFunction,
        operationId,
        order,
        orderItem,
        diference: orderItem.amount - orderItem.oldAmount
      });
    } catch (error) {
      console.error(error);
      errLogger(error);
    }
  }

  /** Remove requested stock */
  public async onOrderItemRemoved(partId: string, amount: number, movementData: {
    order: OrderFull;
    orderItem: OrderItem;
    moveType: MoveTypes;
    operationFunction: OperationFunction;
    caller: string;
    operationId: string;
  }) {
    const { order, orderItem, moveType, operationFunction, caller, operationId } = movementData;

    const txtLogger = this._logReporter.textLogger(
      `StockController.onOrderItemRemoved(partId: ${partId}, amount: ${amount}); previousCallers: ${caller}; StockController.onOrderItemRemoved()`,
      LOG_REPORTER_KEYS.STOCK);
    const errLogger = this._logReporter.errorLogger("StockController.onOrderItemRemoved()", LOG_REPORTER_KEYS.STOCK);
    try {
      const part = await this.partService.get(partId);
      const loginfo = {
        code: part.code,
        stock: part.stock,
        requestedStock: part.requestedStock,
        inOrderStock: part.inOrderStock,
        availableStock: part.availableStock,
        orderNumber: order?.code,
        companyOrder: order?.companyOrder,
      }
      txtLogger(JSON.stringify({ antes: loginfo }));
      part.requestedStock = (part.requestedStock || 0) - Math.abs(amount);
      part.availableStock = this.calcAvailableStockOf(part, caller);
      await this.partService.addOrUpdate(part, "StockController.onOrderItemRemoved()");

      await this._movementService.createMovementFromOrder({
        caller: caller + "; StockController.onOrderItemRemoved()",
        moveType,
        operationFunction,
        operationId,
        order,
        orderItem,
        diference: orderItem.amount - orderItem.oldAmount
      });
    } catch (error) {
      console.error(error);
      errLogger(error);
    }
  }

  public async onOrderFinished(invoiceItem: InvoiceItem, amount: number, movementData: {
    operation: Operation;
    invoice: NF_response;
    moveType: MoveTypes;
    orderFull: OrderFull;
    caller: string;
  }) {
    const { caller, invoice, moveType, operation, orderFull } = movementData

    const txtLogger = this._logReporter.textLogger(
      `StockController.onOrderFinished(partId: ${invoiceItem.part.id}, amount: ${amount}); previousCallers: ${caller}; StockController.onOrderFinished()`,
      LOG_REPORTER_KEYS.STOCK);
    const errLogger = this._logReporter.errorLogger("StockController.onOrderFinished()", LOG_REPORTER_KEYS.STOCK);
    try {
      const part = await this.partService.get(invoiceItem.part.id);
      const loginfo = {
        code: part.code,
        stock: part.stock,
        requestedStock: part.requestedStock,
        inOrderStock: part.inOrderStock,
        availableStock: part.availableStock,
        orderNumber: orderFull?.code,
        companyOrder: orderFull?.companyOrder,
        invoiceNumbeer: invoice?.numero,
        invoiceId: invoice?.id
      }
      txtLogger(JSON.stringify({ antes: loginfo }));
      if (operation.changeStock) {
        part.stock = (part.stock || 0) - amount;
        part.requestedStock = (part.requestedStock || 0) - amount;
        part.availableStock = this.calcAvailableStockOf(part, caller);
        await this.partService.addOrUpdate(part, "StockController.onOrderFinished()");
      }

      await this._movementService.createMovementFromInvoice({
        caller: caller + "; StockController.onOrderFinished()",
        invoice,
        operationFuntion: operation.operationFunction,
        item: invoiceItem,
        moveType,
        operation,
        part,
        senderID: orderFull.client,
        orderId: orderFull._id,
        isSupplier: false
      })

    } catch (error) {
      console.error(error);
      errLogger(error);
    }
  }

  public async onOrderCanceled(partId: string, amount: number, movementData: {
    order: OrderFull | IOrder;
    orderItem: OrderItem | IOrderItem;
    moveType: MoveTypes;
    operationFunction: OperationFunction;
    caller: string;
    operationId: string;
  }) {
    const { caller, moveType, operationFunction, operationId, order, orderItem } = movementData;

    const txtLogger = this._logReporter.textLogger(
      `StockController.onOrderCanceled(partId: ${partId}, amount: ${amount}); previousCallers: ${caller}; StockController.onOrderCanceled()`,
      LOG_REPORTER_KEYS.STOCK);
    const errLogger = this._logReporter.errorLogger("StockController.onOrderCanceled()", LOG_REPORTER_KEYS.STOCK);
    try {
      const part = await this.partService.get(partId);
      const loginfo = {
        code: part.code,
        stock: part.stock,
        requestedStock: part.requestedStock,
        inOrderStock: part.inOrderStock,
        availableStock: part.availableStock,
        orderId: order._id,
        orderNumber: order?.code,
        companyOrder: order?.companyOrder,
      }
      txtLogger(JSON.stringify({ antes: loginfo }));
      part.requestedStock = (part.requestedStock || 0) - amount;
      part.availableStock = this.calcAvailableStockOf(part, caller);
      await this.partService.addOrUpdate(part, "StockController.onOrderCanceled()");

      await this._movementService.createMovementFromOrder({
        caller: caller + "; StockController.onOrderCanceled()",
        moveType,
        operationFunction,
        operationId,
        order,
        orderItem,
        diference: orderItem.amount
      });
    } catch (error) {
      console.error(error);
      errLogger(error);
    }
  }

  private verifyStockMinOf(part: Part) {
    if (part.stock <= (part.stockMin || 0)) {
      const message = this.translateService.instant(`MESSAGE.PART.MIN_STOCK.CONTENT`, { description: part.description });
      const action = this.translateService.instant('ACTION.SHARED.SEE');
      const config: MatSnackBarConfig = {
        duration: 8000,
        horizontalPosition: 'right',
        panelClass: 'snackbar-info',
        verticalPosition: 'top'
      };

      this.snackbar.open(message, action, config).onAction().subscribe(() => {
        this.router.navigate(['/company', this.dataService.company.id, 'parts', part.id])
      })
    }
  }

  public async onPartUpdatedIntoOS(roPart: MkgOSPart, diference: number, movementData: {
    OS: MkgOS;
    caller: string;
    moveType: MoveTypes;
    operationFunction: OperationFunction;
    fieldChanged: FieldChanged;
  }) {
    const { OS, caller, moveType, operationFunction, fieldChanged } = movementData;

    const txtLogger = this._logReporter.textLogger(
      `StockController.onPartUpdatedIntoOS(partId: ${roPart.part.id}, diference: ${diference}); fieldChanged: ${fieldChanged}; previousCallers: ${caller}; StockController.onPartUpdatedIntoOS(); fieldChanged: ${fieldChanged}`,
      LOG_REPORTER_KEYS.STOCK);
    const errLogger = this._logReporter.errorLogger("StockController.onPartUpdatedIntoOS()", LOG_REPORTER_KEYS.STOCK);

    try {
      const part = await this.partService.get(roPart.part.id)
      const loginfo = {
        code: part.code,
        stock: part.stock,
        requestedStock: part.requestedStock,
        inOrderStock: part.inOrderStock,
        availableStock: part.availableStock,
        OSId: OS.id,
        caller,
        moveType,
        operationFunction,
        fieldChanged,
        partId: part.id,
        partCode: part.code,
        roPartAmount: roPart.amount(),
      }
      txtLogger(JSON.stringify({ antes: loginfo }));

      if (fieldChanged === "amount" && diference !== 0) {
        part.inOrderStock = (part.inOrderStock ?? 0) + diference;
        part.availableStock = this.calcAvailableStockOf(part, caller);

        await this._movementService.createMovementFromOS({
          roPart,
          moveType,
          OS,
          operationFunction,
          part,
          diference
        }, `${caller}; StockController.onPartUpdatedIntoOS(); fieldChanged: ${fieldChanged}`);

        this._partEdited = {
          partId: part.id,
          availableStock: part.availableStock,
          inOrderStock: part.inOrderStock,
          requestedStock: part.requestedStock,
          stock: part.stock
        }
      } else {
        if (this._partEdited?.partId && this._partEdited?.partId === part.id) {
          part.availableStock = this._partEdited.availableStock;
          part.inOrderStock = this._partEdited.inOrderStock;
          part.requestedStock = this._partEdited.requestedStock;
          part.stock = this._partEdited.stock;
        }
      }

      await this.partService.addOrUpdate(part, "StockController.onPartUpdatedIntoOS()");
    } catch (error) {
      console.error(error);
      errLogger(error);
    }
  }

  public calcAvailableStockOf(part: Part, caller: string): number {
    const txtLogger = this._logReporter.textLogger(caller + "; StockContoller.calcAvailableStockOf()", LOG_REPORTER_KEYS.STOCK);
    txtLogger(`partId: ${part.id}; availableStock: ${part.availableStock}; stock: ${part.stock}; inOrderStock: ${part.inOrderStock}; requestedStock: ${part.requestedStock}; before`)
    part.availableStock = (part.stock || 0) - (part.inOrderStock || 0) - (part.requestedStock || 0);
    txtLogger(`partId: ${part.id}; availableStock: ${part.availableStock}; stock: ${part.stock}; inOrderStock: ${part.inOrderStock}; requestedStock: ${part.requestedStock}; after`)
    return part.availableStock;
  }
}

interface PartEdited {
  partId: string;
  availableStock: number;
  stock: number;
  inOrderStock: number;
  requestedStock: number;
}