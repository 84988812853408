/**
 * Country codes used into NFe
 * 
 * @see http://www.nfe.fazenda.gov.br/portal/exibirArquivo.aspx?conteudo=PfPDd6dW200=
 */
export const COUNTRIES = [
    { code: "132", name: "AFEGANISTAO" },
    { code: "153", name: "ALAND, ILHAS" },
    { code: "175", name: "ALBANIA, REPUBLICA  DA" },
    { code: "200", name: "CURACAO" },
    { code: "230", name: "ALEMANHA" },
    { code: "310", name: "BURKINA FASO" },
    { code: "370", name: "ANDORRA" },
    { code: "400", name: "ANGOLA" },
    { code: "418", name: "ANGUILLA" },
    { code: "420", name: "ANTARTICA" },
    { code: "434", name: "ANTIGUA E BARBUDA" },
    { code: "477", name: "ANTILHAS HOLANDESAS" },
    { code: "531", name: "ARABIA SAUDITA" },
    { code: "590", name: "ARGELIA" },
    { code: "639", name: "ARGENTINA" },
    { code: "647", name: "ARMENIA, REPUBLICA DA" },
    { code: "655", name: "ARUBA" },
    { code: "698", name: "AUSTRALIA" },
    { code: "728", name: "AUSTRIA" },
    { code: "736", name: "AZERBAIJAO, REPUBLICA DO" },
    { code: "779", name: "BAHAMAS, ILHAS" },
    { code: "809", name: "BAHREIN, ILHAS" },
    { code: "817", name: "BANGLADESH" },
    { code: "833", name: "BARBADOS" },
    { code: "850", name: "BELARUS, REPUBLICA DA" },
    { code: "876", name: "BELGICA" },
    { code: "884", name: "BELIZE" },
    { code: "906", name: "BERMUDAS" },
    { code: "930", name: "MIANMAR(BIRMANIA)" },
    { code: "973", name: "BOLIVIA, ESTADO PLURINACIONAL DA" },
    { code: "981", name: "BOSNIA - HERZEGOVINA(REPUBLICA DA)" },
    { code: "990", name: "BONAIRE" },
    { code: "1015", name: "BOTSUANA" },
    { code: "1023", name: "BOUVET, ILHA" },
    { code: "1058", name: "BRASIL" },
    { code: "1082", name: "BRUNEI" },
    { code: "1112", name: "BULGARIA, REPUBLICA DA" },
    { code: "1155", name: "BURUNDI" },
    { code: "1198", name: "BUTAO" },
    { code: "1279", name: "CABO VERDE, REPUBLICA DE" },
    { code: "1376", name: "CAYMAN, ILHAS" },
    { code: "1414", name: "CAMBOJA" },
    { code: "1457", name: "CAMAROES" },
    { code: "1490", name: "CANADA" },
    { code: "1504", name: "GUERNSEY, ILHA DO CANAL(INCLUI ALDERNEY E SARK)" },
    { code: "1508", name: "JERSEY, ILHA DO CANAL" },
    { code: "1511", name: "CANARIAS, ILHAS" },
    { code: "1538", name: "CAZAQUISTAO, REPUBLICA DO" },
    { code: "1546", name: "CATAR" },
    { code: "1589", name: "CHILE" },
    { code: "1600", name: "CHINA, REPUBLICA POPULAR" },
    { code: "1619", name: "FORMOSA(TAIWAN)" },
    { code: "1635", name: "CHIPRE" },
    { code: "1651", name: "COCOS(KEELING), ILHAS" },
    { code: "1694", name: "COLOMBIA" },
    { code: "1732", name: "COMORES, ILHAS" },
    { code: "1775", name: "CONGO" },
    { code: "1830", name: "COOK, ILHAS" },
    { code: "1872", name: "COREIA(DO NORTE), REP.POP.DEMOCRATICA" },
    { code: "1902", name: "COREIA(DO SUL), REPUBLICA DA" },
    { code: "1937", name: "COSTA DO MARFIM" },
    { code: "1953", name: "CROACIA(REPUBLICA DA)" },
    { code: "1961", name: "COSTA RICA" },
    { code: "1988", name: "COVEITE" },
    { code: "1988", name: "KUWAIT" },
    { code: "1996", name: "CUBA" },
    { code: "2003", name: "CURACAO" },
    { code: "2291", name: "BENIN" },
    { code: "2321", name: "DINAMARCA" },
    { code: "2356", name: "DOMINICA, ILHA" },
    { code: "2399", name: "EQUADOR" },
    { code: "2402", name: "EGITO" },
    { code: "2437", name: "ERITREIA" },
    { code: "2445", name: "EMIRADOS ARABES UNIDOS" },
    { code: "2453", name: "ESPANHA" },
    { code: "2461", name: "ESLOVENIA, REPUBLICA DA" },
    { code: "2470", name: "ESLOVACA, REPUBLICA" },
    { code: "2496", name: "ESTADOS UNIDOS" },
    { code: "2518", name: "ESTONIA, REPUBLICA DA" },
    { code: "2534", name: "ETIOPIA" },
    { code: "2550", name: "FALKLAND(ILHAS MALVINAS)" },
    { code: "2593", name: "FEROE, ILHAS" },
    { code: "2674", name: "FILIPINAS" },
    { code: "2712", name: "FINLANDIA" },
    { code: "2755", name: "FRANCA" },
    { code: "2810", name: "GABAO" },
    { code: "2852", name: "GAMBIA" },
    { code: "2895", name: "GANA" },
    { code: "2917", name: "GEORGIA, REPUBLICA DA" },
    { code: "2925", name: "ILHAS GEORGIA DO SUL E SANDWICH DO SUL" },
    { code: "2933", name: "GIBRALTAR" },
    { code: "2976", name: "GRANADA" },
    { code: "3018", name: "GRECIA" },
    { code: "3050", name: "GROENLANDIA" },
    { code: "3093", name: "GUADALUPE" },
    { code: "3131", name: "GUAM" },
    { code: "3174", name: "GUATEMALA" },
    { code: "3212", name: "GUERNSEY, ILHA DO CANAL(INCLUI ALDERNEY E SARK)" },
    { code: "3255", name: "GUIANA FRANCESA" },
    { code: "3298", name: "GUINE" },
    { code: "3310", name: "GUINE - EQUATORIAL" },
    { code: "3344", name: "GUINE - BISSAU" },
    { code: "3379", name: "GUIANA" },
    { code: "3417", name: "HAITI" },
    { code: "3433", name: "ILHA HEARD E ILHAS MCDONALD" },
    { code: "3450", name: "HONDURAS" },
    { code: "3514", name: "HONG KONG" },
    { code: "3557", name: "HUNGRIA, REPUBLICA DA" },
    { code: "3573", name: "IEMEN" },
    { code: "3595", name: "MAN, ILHA DE" },
    { code: "3599", name: "BONAIRE" },
    { code: "3611", name: "INDIA" },
    { code: "3654", name: "INDONESIA" },
    { code: "3697", name: "IRAQUE" },
    { code: "3727", name: "IRA, REPUBLICA ISLAMICA DO" },
    { code: "3751", name: "IRLANDA" },
    { code: "3794", name: "ISLANDIA" },
    { code: "3832", name: "ISRAEL" },
    { code: "3867", name: "ITALIA" },
    { code: "3913", name: "JAMAICA" },
    { code: "3930", name: "JERSEY, ILHA DO CANAL" },
    { code: "3964", name: "JOHNSTON, ILHAS" },
    { code: "3999", name: "JAPAO" },
    { code: "4030", name: "JORDANIA" },
    { code: "4111", name: "KIRIBATI" },
    { code: "4200", name: "LAOS, REP.POP.DEMOCR.DO" },
    { code: "4235", name: "LEBUAN, ILHAS" },
    { code: "4260", name: "LESOTO" },
    { code: "4278", name: "LETONIA, REPUBLICA DA" },
    { code: "4316", name: "LIBANO" },
    { code: "4340", name: "LIBERIA" },
    { code: "4383", name: "LIBIA" },
    { code: "4405", name: "LIECHTENSTEIN" },
    { code: "4421", name: "LITUANIA, REPUBLICA DA" },
    { code: "4456", name: "LUXEMBURGO" },
    { code: "4472", name: "MACAU" },
    { code: "4499", name: "MACEDONIA, ANT.REP.IUGOSLAVA" },
    { code: "4499", name: "MACEDONIA DO NORTE" },
    { code: "4502", name: "MADAGASCAR" },
    { code: "4525", name: "MADEIRA, ILHA DA" },
    { code: "4553", name: "MALASIA" },
    { code: "4588", name: "MALAVI" },
    { code: "4618", name: "MALDIVAS" },
    { code: "4642", name: "MALI" },
    { code: "4677", name: "MALTA" },
    { code: "4723", name: "MARIANAS DO NORTE" },
    { code: "4740", name: "MARROCOS" },
    { code: "4766", name: "MARSHALL, ILHAS" },
    { code: "4774", name: "MARTINICA" },
    { code: "4855", name: "MAURICIO" },
    { code: "4880", name: "MAURITANIA" },
    { code: "4885", name: "MAYOTTE(ILHAS FRANCESAS)" },
    { code: "4898", name: "MAYOTTE(ILHAS FRANCESAS)" },
    { code: "4901", name: "MIDWAY, ILHAS" },
    { code: "4936", name: "MEXICO" },
    { code: "4944", name: "MOLDAVIA, REPUBLICA DA" },
    { code: "4952", name: "MONACO" },
    { code: "4979", name: "MONGOLIA" },
    { code: "4985", name: "MONTENEGRO" },
    { code: "4995", name: "MICRONESIA" },
    { code: "5010", name: "MONTSERRAT, ILHAS" },
    { code: "5053", name: "MOCAMBIQUE" },
    { code: "5070", name: "NAMIBIA" },
    { code: "5088", name: "NAURU" },
    { code: "5118", name: "CHRISTMAS, ILHA(NAVIDAD)" },
    { code: "5177", name: "NEPAL" },
    { code: "5215", name: "NICARAGUA" },
    { code: "5258", name: "NIGER" },
    { code: "5282", name: "NIGERIA" },
    { code: "5312", name: "NIUE, ILHA" },
    { code: "5355", name: "NORFOLK, ILHA" },
    { code: "5380", name: "NORUEGA" },
    { code: "5428", name: "NOVA CALEDONIA" },
    { code: "5452", name: "PAPUA NOVA GUINE" },
    { code: "5487", name: "NOVA ZELANDIA" },
    { code: "5517", name: "VANUATU" },
    { code: "5568", name: "OMA" },
    { code: "5665", name: "PACIFICO, ILHAS DO(POSSESSAO DOS EUA)" },
    { code: "5738", name: "PAISES BAIXOS(HOLANDA)" },
    { code: "5754", name: "PALAU" },
    { code: "5762", name: "PAQUISTAO" },
    { code: "5780", name: "PALESTINA" },
    { code: "5800", name: "PANAMA" },
    { code: "5860", name: "PARAGUAI" },
    { code: "5894", name: "PERU" },
    { code: "5932", name: "PITCAIRN, ILHA" },
    { code: "5991", name: "POLINESIA FRANCESA" },
    { code: "6033", name: "POLONIA, REPUBLICA DA" },
    { code: "6076", name: "PORTUGAL" },
    { code: "6114", name: "PORTO RICO" },
    { code: "6238", name: "QUENIA" },
    { code: "6254", name: "QUIRGUIZ, REPUBLICA" },
    { code: "6289", name: "REINO UNIDO" },
    { code: "6408", name: "REPUBLICA CENTRO - AFRICANA" },
    { code: "6475", name: "REPUBLICA DOMINICANA" },
    { code: "6602", name: "REUNIAO, ILHA" },
    { code: "6653", name: "ZIMBABUE" },
    { code: "6700", name: "ROMENIA" },
    { code: "6750", name: "RUANDA" },
    { code: "6769", name: "RUSSIA, FEDERACAO DA" },
    { code: "6777", name: "SALOMAO, ILHAS" },
    { code: "6858", name: "SAARA OCIDENTAL" },
    { code: "6874", name: "EL SALVADOR" },
    { code: "6904", name: "SAMOA" },
    { code: "6912", name: "SAMOA AMERICANA" },
    { code: "6955", name: "SAO CRISTOVAO E NEVES, ILHAS" },
    { code: "6939", name: "SAO BARTOLOMEU" },
    { code: "6971", name: "SAN MARINO" },
    { code: "6980", name: "SAO MARTINHO, ILHA DE(PARTE FRANCESA)" },
    { code: "6998", name: "SAO MARTINHO, ILHA DE(PARTE HOLANDESA)" },
    { code: "7005", name: "SAO PEDRO E MIQUELON" },
    { code: "7056", name: "SAO VICENTE E GRANADINAS" },
    { code: "7102", name: "SANTA HELENA" },
    { code: "7153", name: "SANTA LUCIA" },
    { code: "7200", name: "SAO TOME E PRINCIPE, ILHAS" },
    { code: "7285", name: "SENEGAL" },
    { code: "7315", name: "SEYCHELLES" },
    { code: "7358", name: "SERRA LEOA" },
    { code: "7370", name: "SERVIA" },
    { code: "7412", name: "CINGAPURA" },
    { code: "7447", name: "SIRIA, REPUBLICA ARABE DA" },
    { code: "7480", name: "SOMALIA" },
    { code: "7501", name: "SRI LANKA" },
    { code: "7544", name: "SUAZILANDIA" },
    { code: "7544", name: "ESSUATINI" },
    { code: "7552", name: "SVALBARD E JAN MAYERN" },
    { code: "7560", name: "AFRICA DO SUL" },
    { code: "7595", name: "SUDAO" },
    { code: "7600", name: "SUDÃO DO SUL" },
    { code: "7641", name: "SUECIA" },
    { code: "7676", name: "SUICA" },
    { code: "7706", name: "SURINAME" },
    { code: "7722", name: "TADJIQUISTAO, REPUBLICA DO" },
    { code: "7765", name: "TAILANDIA" },
    { code: "7803", name: "TANZANIA, REP.UNIDA DA" },
    { code: "7811", name: "TERRAS AUSTRAIS E ANTARTICAS FRANCESAS" },
    { code: "7820", name: "TERRITORIO BRIT.OC.INDICO" },
    { code: "7838", name: "DJIBUTI" },
    { code: "7889", name: "CHADE" },
    { code: "7919", name: "TCHECA, REPUBLICA" },
    { code: "7951", name: "TIMOR LESTE" },
    { code: "8001", name: "TOGO" },
    { code: "8052", name: "TOQUELAU, ILHAS" },
    { code: "8109", name: "TONGA" },
    { code: "8150", name: "TRINIDAD E TOBAGO" },
    { code: "8206", name: "TUNISIA" },
    { code: "8230", name: "TURCAS E CAICOS, ILHAS" },
    { code: "8249", name: "TURCOMENISTAO, REPUBLICA DO" },
    { code: "8273", name: "TURQUIA" },
    { code: "8281", name: "TUVALU" },
    { code: "8311", name: "UCRANIA" },
    { code: "8338", name: "UGANDA" },
    { code: "8451", name: "URUGUAI" },
    { code: "8478", name: "UZBEQUISTAO, REPUBLICA DO" },
    { code: "8486", name: "VATICANO, EST.DA CIDADE DO" },
    { code: "8508", name: "VENEZUELA" },
    { code: "8583", name: "VIETNA" },
    { code: "8630", name: "VIRGENS, ILHAS(BRITANICAS)" },
    { code: "8664", name: "VIRGENS, ILHAS(E.U.A.)" },
    { code: "8702", name: "FIJI" },
    { code: "8753", name: "WALLIS E FUTUNA, ILHAS" },
    { code: "8737", name: "WAKE, ILHA" },
    { code: "8885", name: "CONGO, REPUBLICA DEMOCRATICA DO" },
    { code: "8907", name: "ZAMBIA" }
]