
import { Entrega } from "src/app/interface/NF-e";
import { ApiFinanceIN } from "../../../../../interface/ro-finance";
import { ORDER_STATUS } from "../const/order-status";
import { IOrderItem } from "./order-item.interface";

export class IOrder {
  _id?: string;
  client: string;
  parts: Array<IOrderItem>;
  paymentCondition?: Array<ApiFinanceIN>;
  operation: string;
  seller: string;
  name: string;
  refer: string;
  phone: string;
  presence: string;
  /**
   * "ORÇAMENTO" = 1,
  * "PEDIDO" = 2,
  * "FATURADO" = 3,
  * "CANCELADO" = 4
   */
  status?: ORDER_STATUS;
  grossValue: number;
  delivery?: Entrega;
  companyOrder?: string;
  orderId?: string;
  generalDiscont: number;
  discountValue?: number;
  code?: number;
  observation?: string;
  title?: Array<string>;

  idFarmer?: string;

  readonly avaliable?: boolean;
  readonly company?: string;
  readonly createdAt?: string;
  readonly updatedAt?: string;

  /** local only */
  cnpj?: string;

  /** Use to prevent { error: 40 } */
  static removeReadOnlyProps(fullApiOrder: IOrder) {
    const treated = { ...fullApiOrder };
    // delete readonly properties
    delete treated.avaliable;
    delete treated.company;
    delete treated.createdAt;
    delete treated.updatedAt;
    return treated
  }
}
