import { Company } from "../interface/company";
import { detItem55, NFe } from "../interface/NF-e";
import { detItem65, NFCe } from "../interface/NFC-e";
import { Operation } from "../interface/operation";
import { Part } from "../interface/part";
import { ReduceSumPipe } from "../shared/pipes/reduce-sum.pipe";
import { Money } from "./money";
import { Utilities } from "./utilities";

export class NfUtilities extends Utilities {

  /** Remove the special characters of two codes and evaluate if they are equals */
  static compareCodes(code1: string, code2: string): boolean {
    return NfUtilities.removeSpecialCharacters(`${code1}`).trim() === NfUtilities.removeSpecialCharacters(`${code2}`).trim();
  }

  static removeSpecialCharactersOf(object: any) {
    if (!object || Number.isNaN(object)) {
      return
    }

    if (typeof object === 'number') {
      return
    }

    if (typeof object === 'undefined') {
      return
    }

    if (typeof object === 'object') {
      // ignore Maps
      if (object.size) {
        return
      }

      // ignore DateObject
      if (object.getTime) {
        return
      }

      // interate arrays
      if (object.length) {
        object.forEach(item => NfUtilities.removeSpecialCharactersOf(item))
        return
      }
    }

    if (Object.keys(object).length) {
      Object.keys(object).forEach(key => {
        switch (typeof object[key]) {
          case 'string':
            object[key] = this.removeSpecialCharacters(object[key]);
            break;
          // interate childs
          case 'object':
            if (object[key] === null) {
              // delete object[key]
            } else {
              NfUtilities.removeSpecialCharactersOf(object[key]);
            }
            break;
          default:
            return
        }
      });
      return
    }

  }

  static calculateICMSof(prod: detItem55['prod'] | detItem65['prod'], params: {
    part: Part,
    companyCRT: Company['crt'],
    icmsConfig: Operation['ICMS'] | Part['ICMS'],
    isDevolution?: boolean,
    pRedBC?: number,
  }) {

    const pICMS = Number(params.icmsConfig.Aliquot) || 0;
    let vBC = 0;

    const ICMS: Partial<detItem55['imposto']['ICMS']> = {
      CST: params.icmsConfig.CST,
      modBC: 0,
      orig: params.part.origin,
      pICMS
    }

    if (pICMS) {
      if (params.isDevolution) {
        if (params.icmsConfig.CST == '900') {
          const readjusmentPercentage = params.pRedBC || 0;
          vBC = Utilities.roundNumber(prod.vProd - (prod.vProd * (readjusmentPercentage) / 100));
        }
      } else if (["2", "3"].includes(params.companyCRT)) {
        const { vProd = 0, vDesc = 0, vFrete = 0, vOutro_item = 0 } = prod;
        vBC = (vProd - vDesc) + vFrete + (Number(vOutro_item) || 0);
      }
    }

    if (params.icmsConfig.CST == "500" || params.icmsConfig.CST == "60") {
      vBC = 0;
    }

    ICMS.vBC = Money(vBC);
    ICMS.vICMS_icms = Utilities.calcPercentage(pICMS, vBC);
    return ICMS as detItem55['imposto']['ICMS']
  }

  static calculatePISof(params: {
    pisConfig: Operation['PIS'] | Part['PIS'] | undefined,
    vBC_pis: number
  }): detItem55['imposto']['PIS'] | null {
    if (params && params.pisConfig?.CST) {
      const vPIS = Utilities.calcPercentage(params.pisConfig.Aliquot, params.vBC_pis);
      return {
        CST_pis: params.pisConfig.CST,
        pPIS: params.pisConfig.Aliquot || 0,
        vBC_pis: params.vBC_pis,
        vPIS
      }
    }
    return null
  }

  static calculateCOFINSof(params: {
    cofinsConfig: Operation['COFINS'] | Part['COFINS'] | undefined,
    vBC_cofins: number
  }): detItem55['imposto']['COFINS'] | null {
    if (params && params.cofinsConfig) {
      const vCOFINS = Utilities.calcPercentage(params.cofinsConfig.Aliquot, params.vBC_cofins);
      return {
        CST_cofins: params.cofinsConfig.CST,
        pCOFINS: params.cofinsConfig.Aliquot || 0,
        vBC_cofins: params.vBC_cofins,
        vCOFINS
      }
    }
    return null
  }

  static calculateIPIof(CST_IPI: string): detItem55['imposto']['IPI'] | null {
    if (CST_IPI) {
      const cEnq = ["00", "49", "99"].includes(CST_IPI) ? null : "";
      return {
        CSTIPI: {
          CST_IPI: CST_IPI as any,
        },
        cEnq
      }
    }
    return null
  }

  static sumICMStotOfNFeItems(detItems: detItem55[]) {
    const reducer = new ReduceSumPipe();
    return {
      vCOFINS_ttlnfe: Money(reducer.transform(detItems, (item) => Number(item.imposto?.COFINS?.vCOFINS))),
      vFrete_ttlnfe: Money(reducer.transform(detItems, (item) => Number(item.prod.vFrete))),
      vICMS_ttlnfe: Money(reducer.transform(detItems, (item) => Number(item.imposto?.ICMS?.vICMS_icms))),
      vIPI_ttlnfe: Money(reducer.transform(detItems, (item) => Number(item.imposto?.IPI?.CSTIPI?.vIPI))),
      vPIS_ttlnfe: Money(reducer.transform(detItems, (item) => Number(item.imposto?.PIS?.vPIS))),
      vSeg_ttlnfe: Money(reducer.transform(detItems, (item) => Number(item.prod.vSeg))),
      vST_ttlnfe: Money(reducer.transform(detItems, (item) => Number(item.imposto?.ICMS?.vICMSST_icms))),
      vOutro: Money(reducer.transform(detItems, (item) => Number(item.prod.vOutro_item))),
    }
  }

  static sumICMStotOfNFCeItems(detItems: detItem65[]) {
    const reducer = new ReduceSumPipe();
    return {
      vFrete_ttlnfe: Money(reducer.transform(detItems, (item) => Number(item.prod.vFrete))),
      vICMS_ttlnfe: Money(reducer.transform(detItems, (item) => Number(item.imposto?.ICMS?.vICMS_icms))),
      vSeg_ttlnfe: Money(reducer.transform(detItems, (item) => Number(item.prod.vSeg))),
      vST_ttlnfe: Money(reducer.transform(detItems, (item) => Number(item.imposto?.ICMS?.vICMSST_icms))),
      vOutro: Money(reducer.transform(detItems, (item) => Number(item.prod.vOutro_item))),
    }
  }



  /** @deprecated Use minor functions instead */
  static calculateTotals(invoice: NFe | NFCe, isDevolution?: boolean): (NFe | NFCe) {
    let netValue = 0
    // let taxes = 0;
    let totalICMS = 0
    let totalPis = 0;
    let totalIPI = 0;
    let totalCofins = 0;
    let totalST = 0;
    let totalDiscount = 0;
    // const isSupersimples = invoice.emit.CRT === 1;

    for (const item of invoice.det) {
      let sum = 0;

      // cast string values to number
      item.prod.vFrete = Number(item.prod.vFrete) || 0;

      // calc gross value
      item.prod.vProd = Money(item.prod.vUnTrib * item.prod.qTrib) || 0;
      netValue += item.prod.vProd;

      totalDiscount += (item.prod.vDesc || 0);


      const icmsAliquot = Number(item.imposto.ICMS.pICMS);
      const cst = item.imposto?.ICMS?.CST;

      if (!icmsAliquot || (cst == "60" || cst == "500")) {
        // Produtos com alíquota zero não devem informar BC
        item.imposto.ICMS.vBC = 0;
      } else if (isDevolution) {
        if (item.imposto.ICMS.CST == '900') {
          const readjusmentPercentage = item.imposto.ICMS.pRedBC || 0;
          item.imposto.ICMS.vBC = Utilities.roundNumber(item.prod.vProd - (item.prod.vProd * (readjusmentPercentage) / 100));
        }
        // else: keep values of original invoice
      } else {
        const { vProd = 0, vDesc = 0, vFrete = 0, vOutro_item = 0 } = item.prod;
        const vBC = (vProd - vDesc) + vFrete + (Number(vOutro_item) || 0);
        item.imposto.ICMS.vBC = Money(vBC);
      }


      // calc ICMS values
      item.imposto.ICMS.vICMS_icms = Utilities.roundNumber(icmsAliquot ? (item.imposto.ICMS.vBC * icmsAliquot / 100) : (Number(item.imposto.ICMS['vICMS']) || Number(item.imposto.ICMS.vICMS_icms)));
      item.imposto.ICMS.pRedBC = 0;
      totalICMS += item.imposto.ICMS.vICMS_icms;

      // calc PIS value
      if ((item as detItem55).imposto.PIS && (item as detItem55).imposto.PIS.CST_pis) {
        (item as detItem55).imposto.PIS.vPIS = Utilities.roundNumber((item as detItem55).imposto.PIS.pPIS ? (item.prod.vProd * (item as detItem55).imposto.PIS.pPIS / 100) : 0);
        totalPis += (item as detItem55).imposto.PIS.vPIS;
        sum += (item as detItem55).imposto.PIS.vPIS;
      } else {
        delete item.imposto['PIS'];
      }

      // calc COFINS value
      if ((item as detItem55).imposto.COFINS && (item as detItem55).imposto.COFINS.CST_cofins) {
        (item as detItem55).imposto.COFINS.vCOFINS = Utilities.roundNumber((item as detItem55).imposto.COFINS.pCOFINS ? (item.prod.vProd * (item as detItem55).imposto.COFINS.pCOFINS / 100) : 0);
        totalCofins += (item as detItem55).imposto.COFINS.vCOFINS;
        sum += (item as detItem55).imposto.COFINS.vCOFINS;
      } else {
        delete item.imposto['COFINS'];
      }

      // calc IPI value
      const ipi_value = Utilities.roundNumber((item as detItem55).imposto.IPI && (item as detItem55).imposto.IPI.CSTIPI && Number.isFinite((item as detItem55).imposto.IPI.CSTIPI.vIPI) ? (item as detItem55).imposto.IPI.CSTIPI.vIPI : 0);
      totalIPI += ipi_value;
      sum += ipi_value;

      // calc SuperSimple
      item.imposto.ICMS.vCredICMSSN = Utilities.roundNumber((item.imposto.ICMS.pCredSN ? (item.prod.vProd * item.imposto.ICMS.pCredSN / 100) : 0));
      sum += item.imposto.ICMS.vCredICMSSN;

      if (isDevolution && item.imposto.ICMS.CST === "900") {
        // calc ICMS_ST value
        let originalItem = invoice.det.find(det => det.prod.NCM === item.prod.NCM);
        item.imposto.ICMS.vBCST = Number((Number(originalItem.imposto.ICMS.vBCST) / item.prod.qTrib).toFixed(2));
        item.imposto.ICMS.vICMSST_icms = Number((Number(originalItem.imposto.ICMS.vICMSST_icms || originalItem.imposto.ICMS['vICMSST']) || 0 / item.prod.qTrib).toFixed(2));
      } else {
        /** rejection 553: Total da BC ICMS-ST difere do somatório dos itens */
        item.imposto.ICMS.vBCST = 0;
        item.imposto.ICMS.vICMSST_icms = 0;
      }

      totalST += (item.imposto.ICMS.vICMSST_icms || 0);
      sum += item.imposto.ICMS.vICMSST_icms;
      // taxes += sum;

      /**
       * Rejeição: Total do Valor Aproximado dos Tributos difere do
       * somatório dos itens
       */
      item.imposto.vTotTrib = null;
    }

    const reducer = new ReduceSumPipe();
    const detArray = invoice.det as detItem55[];

    // sum freigth
    const freight_value = reducer.transform(detArray, (item) => Number(item.prod.vFrete));

    // sum others
    const other_expenses = reducer.transform(detArray, (item) => Number(item.prod.vOutro_item));

    // sum insurance
    const insurance_value = reducer.transform(detArray, (item) => Number(item.prod.vSeg));

    // sum BC_ST
    const bcst = reducer.transform(detArray, (item) => Number(item.imposto.ICMS.vBCST));

    // sum volumes (transporter amount)
    const totalAmount = reducer.transform(detArray, (item) => item.prod.qTrib);

    if (invoice.transp) {
      if(invoice['transp']['vol'] && invoice.transp.modFrete != 9){
        invoice['transp']['vol'][0].qVol = totalAmount;
      }
    }

    const ipiDevol = NfUtilities.getPropertyOf(invoice, 'vIPIDevol');

    // sum BC_icms
    let vBC = reducer.transform(detArray, (item) => Number(item.imposto.ICMS.vBC));

    // sum taxes
    invoice['total']['ICMStot'] = {
      vICMS_ttlnfe: Money(totalICMS),
      vPIS_ttlnfe: Money(totalPis),
      vIPI_ttlnfe: Money(totalIPI),
      vCOFINS_ttlnfe: Money(totalCofins),
      vST_ttlnfe: Money(totalST),
      vProd_ttlnfe: Money(netValue),
      // vTotTrib_ttlnfe: Utilities.roundNumber(taxes),
      vBC_ttlnfe: Money(vBC),
      vDesc_ttlnfe: Money(totalDiscount),
      vNF: Money(netValue - totalDiscount),
      vII_ttlnfe: 0,
      vFCPSTRet_ttlnfe: 0,
      vFCPST_ttlnfe: 0,
      vFCP_ttlnfe: 0,
      vIPIDevol_ttlnfe: Money(ipiDevol),
      vOutro: Money(other_expenses),
      vFrete_ttlnfe: Money(freight_value),
      vBCST_ttlnfe: Money(bcst),
      vSeg_ttlnfe: Money(insurance_value),
    }

    delete invoice.total['ICMSTot'];

    invoice.total.ICMStot.vNF = Money(netValue
      + (freight_value || 0)
      + (insurance_value || 0)
      + (other_expenses || 0)
      - (totalDiscount || 0));

    if (isDevolution) {
      invoice.total.ICMStot.vNF += (totalST || 0);
    }

    /** Fix rejection: Ausência de troco quando o valor dos pagamentos informados for maior que o total da nota */
    if (!isDevolution && invoice.pag && invoice.pag.length) {
      Utilities.fixCentsDifference(invoice.pag, "vPag", invoice.total.ICMStot.vNF)
    }

    return invoice
  }

  public static getPropertyOf(invoice: NFe | NFCe, property: string) {
    if (!invoice) {
      return 0
    }

    const tot = invoice['total']['ICMStot'] || invoice['total']['ICMSTot'];

    if (invoice.det && invoice.det.length) {
      switch (property) {
        case "vOutro":
          return invoice.det
            .slice()
            .map(detItem => detItem.prod ? (Number.isFinite(detItem.prod.vOutro_item as any) ? Number(detItem.prod.vOutro_item) : Number(detItem.prod['vOutro']) || 0) : 0)
            .reduce((a, b) => a + b)
        case "vBCST":
          return invoice.det
            .slice()
            .map(detItem => {
              return detItem.imposto && detItem.imposto.ICMS ? (detItem.imposto.ICMS.vBCST || 0) : 0
            }).reduce((a, b) => a + b)
        case "vFrete":
          return invoice.det
            .slice()
            .map(detItem => detItem.prod ? Number(detItem.prod.vFrete) || 0 : 0)
            .reduce((a, b) => a + b)
        case "vSeg":
          return invoice.det
            .slice()
            .map(detItem => detItem.prod ? (detItem.prod.vSeg || 0) : 0)
            .reduce((a, b) => a + b)
        case "vIPIDevol": return Number(tot['vIPIDevol_ttlnfe'] || tot['vIPIDevol']) || 0
        default:
          return 0
      }
    }
    return 0

  }
}
