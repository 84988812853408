<h2 mat-dialog-title>Verificar o valor das peças</h2>
<mat-dialog-content>
  <p>
    Peças com custo médio e/ou último custo igual a zero ou maior que o valor líquido da peça
  </p>
  @if (!isMobile) {
    <table [dataSource]="parts" mat-table>
      <!-- 'code', 'description', 'purchaseAverage', 'purchaseValue', 'liquidValue' -->
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef>Código</th>
        <td mat-cell *matCellDef="let part"> {{ part.part.code }} </td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>descrição</th>
        <td mat-cell *matCellDef="let part"> {{ part.part.description }} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
  } @else {
    @for (part of parts; track $index) {
      <div class="container">
        <div class="added-parts content-box mx-auto">
          <div class="header added-part-header d-flex flex-row justify-content-between align-items-center">
            <mat-icon class="remove-icon">code</mat-icon>
            <span>{{ part.part.code }}</span>
          </div>
          <div class="content">
            <div class="part-data-block">
              <div class="d-flex data-block flex-row align-items-center my-2">
                <span class="label">Descrição:</span>
                <p class="data-description text-wrap m-0">{{ part.part?.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button color="primary" mat-dialog-close>Ok</button>
</mat-dialog-actions>