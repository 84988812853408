import { Component, Inject, OnInit, signal } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '../../../service/data.service';
import { LayoutService } from '../../../service/layout.service';
import { Company } from '../../../interface/company';
import { MigrateCommonService } from 'src/app/service/migrate-common.service';
import { MkgOS } from 'src/app/class/mkg-os';
import { INVOICE_STATE } from 'src/app/shared/lists/invoice-state';
import { MigrateService } from 'src/app/service/migrate.service';
import { CashTitle } from 'src/app/interface/cash-title';
import { BoletoService } from 'src/app/service/boleto.service';
import { NF_response } from 'src/app/interface/NF-response';
import { Client } from 'src/app/interface/client';
import { Supplier } from 'src/app/interface/supplier';
import { BoletoPjBank } from 'src/app/interface/boleto-pjbank';

export interface AskRedirectComponentDataType {
  model: 'NFCe' | 'NFe';
  linkNf: string;
  linkNFSe?: string;
  backTo: string,
  codeSystem?: number,
  invoiceState?: number,
  companyLabors?: Company,
  companyParts?: Company,
  osAsk?: MkgOS,
  boletoTitles?: string[],
  os?: string,
  invoice?: NF_response,
  client?: Client | Supplier
}

@Component({
  selector: 'app-ask-redirect',
  templateUrl: './ask-redirect.component.html',
  styleUrls: ['./ask-redirect.component.scss']
})
export class AskRedirectComponent implements OnInit {

  nfError;
  nfseError;
  os: MkgOS | undefined;
  createdBoleto = signal<BoletoPjBank | null>(null);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AskRedirectComponentDataType,
    public dataService: DataService,
    public layout: LayoutService,
    private _migrateCommonService: MigrateCommonService,
    private boletoService: BoletoService,
    private _migrateService: MigrateService
  ) { }

  ngOnInit() {
    if (this.data.osAsk) {
      this.os = this.data.osAsk;
    }
    this.getErrors()
  }

  isOIM(state): string {
    let isOIM: string = '';
    if (state == INVOICE_STATE.INPUT_WITHOUT_XML) {
      isOIM = 'OIM'
    } else {
      isOIM = 'OIP'
    }
    return isOIM;
  }

  private async getErrors() {
    if (this.data.linkNf) {
      const nf = await this._migrateService.getNFById(this.data.linkNf, this.data.companyParts.cnpj);
      if (!nf) {
        this.nfError = "not_found";
      } else {
        const error = this._migrateCommonService.getErrors(nf, false)
        if (!error || (error.migrateCode && error.sefazCode && error.migrateCode === 100 && error.sefazCode === 100)) {
          this.nfError = null
        } else {
          this.nfError = error
        }
      }
    }

    if (this.data.linkNFSe) {
      const nfse = await this._migrateService.getNFSEById(this.data.linkNFSe, this.data.companyLabors.cnpj);
      if (!nfse) {
        this.nfseError = "not_found";
      } else {
        const error = this._migrateCommonService.getErrors(nfse, true);
        if (!error || (error.migrateCode && error.sefazCode && error.migrateCode === 100 && error.sefazCode === 100)) {
          this.nfseError = null
        } else {
          this.nfseError = error
        }
      }
    }
  }

  createBoleto(): void {
    const invoice = this.data.invoice;
    const titles = this.data.boletoTitles;
    const os = this.data.os;
    const client = this.data.client;

    if (os) {
      this.boletoService.createBoletoFromOs({ osId: os, titles: titles }).then((boletos) => {
        this.createdBoleto.set(boletos[0]);
      }).catch(() => {
        this.createdBoleto.set(null);
      })
    } else {
      this.boletoService.createBoletoFromInvoice({ invoice: invoice, titles: titles, client }).then((boletos) => {
        this.createdBoleto.set(boletos[0]);
      }).catch(() => {
        this.createdBoleto.set(null);
      })
    }
  }

}
