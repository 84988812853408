import { Injectable } from '@angular/core';
import { AskRedirectComponent } from '../component/dialog/ask-redirect/ask-redirect.component';
import { MatDialog } from '@angular/material/dialog';
import { Company } from '../interface/company';
import { NF_response } from '../interface/NF-response';
import { NFSE_response, ResponseRPS } from '../interface/NFSE-response';
import { INVOICE_STATE } from '../shared/lists/invoice-state';
import { first, firstValueFrom } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SnackbarService } from './snackbar.service';
import { environment } from 'src/environments/environment';
import { DataService } from './data.service';
import { companyStates } from '../shared/lists/company-states';
import { LayoutService } from './layout.service';
import { detItem55 } from '../interface/NF-e';

@Injectable({
  providedIn: 'root'
})
export class MigrateCommonService {

  readonly URL = environment.migrate.URL;
  readonly NO_ACCESS_KEY = "_SEM_CHAVE_DE_ACESSO";

  constructor(
    private _dialog: MatDialog,
    private http: HttpClient,
    private layout: LayoutService,
    private dataService: DataService,
    private snackbar: SnackbarService,
  ) { }

  openAskRedirectComponent(data: any, disableClose: boolean) {
    this._dialog.open(AskRedirectComponent, {
      data,
      disableClose
    })
  }
  public async getAll_NF(company: Company, filter?: {
    osId?: string,
    orderID?: string
  }): Promise<NF_response[]> {
    // verify if this company is integrated with migrate
    try {
      const created = await this.isCompanyCreated(company);
      if (!created) {
        return []
      }
    } catch (error) {
      console.error(error)
      return []
    }

    let url = `${this.URL}/v1/invoice-mkgo/nfce`;
    const options = await firstValueFrom(this.dataService.httpOptions(company.cnpj));
    if (filter) {
      const params = new HttpParams({ fromObject: filter })
      options['params'] = params;
    }
    const resp = await firstValueFrom(this.http.get<NF_response[]>(url, options));

    return resp.filter(nf => {
      if (Boolean(nf.request?.Documento)) {
        return true;
      } else {
        console.warn(`A nota de id "${nf.id}" está oculta por não possuir a tag <Documento>`)
        return false;
      }
    }).map(nf => {
      let newDet = []
      Object.defineProperty(nf, "cnpj", {
        value: company.cnpj,
        writable: false,
        enumerable: false
      });
      if (!Array.isArray(nf.request.Documento.det)) {
        // det need be a array
        nf.request.Documento.det = [nf.request.Documento.det];
      }
      nf.request.Documento.det.slice().map(detItem => {
        let newItem = { ...detItem };
        /**
         * detItem.imposto.ICMS.ICMS00 = { ... }
         *
         * detItem.imposto.ICMS = { ... }
         */
        const icmsTag = detItem.imposto.ICMS;
        if (icmsTag) {
          const keys = Object.keys(icmsTag);
          const childName = keys.find(key => key.startsWith('ICMS'));
          if (childName) {
            newItem['imposto']['ICMS'] = icmsTag[childName]
          }
        }

        const ipiTag = detItem.imposto.IPI;
        if (ipiTag) {
          const keys = Object.keys(ipiTag);
          const childName = keys.find(key => key.startsWith('IPI'));
          if (childName) {
            let CSTIPI = ipiTag[childName];
            const subkey = Object.keys(CSTIPI).find(key => key.startsWith('IPI'));
            CSTIPI['CST_IPI'] = CSTIPI['subkey'];

            // xml.imposto.IPI.IPINT.CST
            (newItem as detItem55)['imposto']['IPI']['CSTIPI'] = CSTIPI
          }
        }

        newDet.push(newItem)
      })
      nf.request.Documento.det = newDet;
      return nf;
    }).reverse();
  }

  isGerencial(invoiceState: INVOICE_STATE): boolean {
    return [
      INVOICE_STATE.GERENCIAL,
      INVOICE_STATE.GERENCIAL_DEVOLUTIONED,
      INVOICE_STATE.IMPORTED_VIA_XML,
      INVOICE_STATE.INPUT_WITHOUT_XML].includes(invoiceState);
  }

  getErrors(obj: NF_response | NFSE_response, isNFSE: boolean, ignoreMigrate?: boolean): null | {
    migrateCode;
    migrateDescription;
    sefazCode;
    sefazDescription
  } {
    if (!obj) {
      return null
    }

    if (/** !obj.request || */ this.isGerencial(obj.state) || (obj.response && (obj.response as any).Documento)) {
      // internal
      return null
    }
    let migrateCode;
    let migrateDescription;
    let sefazCode;
    let sefazDescription;

    if (isNFSE) {
      const sr = (obj as NFSE_response);
      // get NFSe codes
      migrateCode = sr.Codigo;
      migrateDescription = sr.Descricao
      const response: ResponseRPS = sr.response['Documentos'] && sr.response['Documentos'].length ? sr.response['Documentos'][0] : sr.response['Situacao'];
      if (response) {
        if (response.Situacao && response.Situacao.SitCodigo) {
          sefazCode = response.Situacao.SitCodigo;
          sefazDescription = response.Situacao.SitDescricao;
        } else if ((response as any).DocSitCodigo) {
          // Lexsom response
          sefazCode = (response as any).DocSitCodigo;
          sefazDescription = (response as any).DocSitDescricao;
        }
      }
      if (sefazCode === 100) {
        return null
      }
    } else {
      // get NFCe or NFe response codes
      const response: any = obj.response;
      if (response) {
        if (response.Codigo) {
          migrateCode = response.Codigo;
          migrateDescription = response.Descricao

          const docs = response.Documentos;
          if (docs && docs.length) {
            if (docs[0].Situacao && docs[0].Situacao.SitCodigo) {
              sefazCode = docs[0].Situacao.SitCodigo;
              sefazDescription = docs[0].Situacao.SitDescricao
            } else if ((docs[0] as any).DocSitCodigo) {
              sefazCode = (docs[0] as any).DocSitCodigo;
              sefazDescription = (docs[0] as any).DocSitDescricao
            }
          }
        } else {
          if (response.length) {
            migrateCode = response[0].Codigo;
            migrateDescription = response[0].Descricao
            const docs = response[0].Documentos;
            if (docs && docs.length) {
              if (docs[0].Situacao && docs[0].Situacao.SitCodigo) {
                sefazCode = docs[0].Situacao.SitCodigo;
                sefazDescription = docs[0].Situacao.SitDescricao
              } else if (docs[0].DocSitCodigo) {
                sefazCode = docs[0].DocSitCodigo;
                sefazDescription = docs[0].DocSitDescricao
              }
            }
          }
        }
      }
    }

    if ((ignoreMigrate || migrateCode === 100) && sefazCode === 100) {
      return null
    }

    return {
      migrateCode: migrateCode,
      migrateDescription: migrateDescription,
      sefazCode: sefazCode,
      sefazDescription: sefazDescription
    }
  }

  async getAll_NFSE(company: Company, osId?: string): Promise<NFSE_response[]> {
    try {
      // verify if this company is integrated with migrate
      const created = await this.isCompanyCreated(company);
      if (!created) {
        console.error('Company not created into invoice API')
        return []
      }
    } catch (error) {
      console.error(error)
      return [];
    }

    const url = `${this.URL}/v1/invoice-mkgo/nfse`;
    const options = await firstValueFrom(this.dataService.httpOptions(company.cnpj));
    if (osId) {
      const params = new HttpParams().append("osId", osId);
      options['params'] = params;
    }
    const resp = await this.http.get(url, options).pipe(first()).toPromise();
    return resp as NFSE_response[];
  }

  public async isCompanyCreated(company: Company) {
    const isMakena = environment.makena || [companyStates.MAKENA_ADMIN.id, companyStates.MAKENA_CLIENT.id].includes(company.currentState);
    if (isMakena) {
      return false;
    }
    let existent;
    try {
      existent = await this.getCompanyByCNPJ(company.cnpj);
    } catch (error) {
      console.error(error)
    }
    if (!existent) {
      let accessKey;
      let lastNSU;
      if (company.migrateIntegrated) {
        accessKey = company.accessKeyInvoice
        lastNSU = company.lastNSU;
        if (!accessKey) {
          this.layout.loader = false;
          accessKey = prompt('Digite a chave de acesso para integração');
        }
        if (!lastNSU) {
          this.layout.loader = false;
          lastNSU = prompt('Digite o último NSU para integração');
        }
      } else {
        accessKey = `${company.cnpj}${this.NO_ACCESS_KEY}`;
        lastNSU = '1';
      }
      if (!accessKey || !lastNSU) {
        return false
      }

      const newCompany = await this.registerCompany(accessKey, lastNSU, !company.migrateIntegrated);
      if (!newCompany) {
        this.snackbar.error('COMPANY.NOT_INTEGRATED');
        return false
      }
      this.snackbar.success('COMPANY.INTEGRATED');
    }
    return true
  }

  public async getCompanyByCNPJ(cnpj: string) {
    const url = `${this.URL}/v1/company`;
    const options = await firstValueFrom(this.dataService.httpOptions(cnpj));
    const resp = await this.http.get(url, options).pipe(first()).toPromise();
    return resp;
  }

  public async registerCompany(accessKey: string, lastNSU: string, gerencial: boolean) {
    const url = `${this.URL}/v1/company`;

    const options = await firstValueFrom(this.dataService.httpOptions(false))
    let body = {
      "accessKey": accessKey,
      "lastNSU": lastNSU.padStart(15, '0'),
    }

    body["status"] = gerencial ? 3 : 2;

    const resp = await this.http.post(url, body, options).pipe(first()).toPromise();
    return resp
  }
}
