"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NATUREZAS = void 0;
;
exports.NATUREZAS = [
    { code: 1, description: "Tributação no município" },
    { code: 2, description: "Tributação fora do município" },
    { code: 3, description: "Isenção" },
    { code: 4, description: "Imune" },
    { code: 5, description: "Exigibilidade suspensa por decisão judicial" },
    { code: 6, description: "Exigibilidade suspensa por procedimento administrativo" },
    { code: 7, description: "Exigível" },
    { code: 8, description: "Não incidência" },
    { code: 9, description: "Exportação" },
    { code: 10, description: "ISS fixo" },
    { code: 11, description: "Substituição tributária" },
    { code: 12, description: "Simples Nacional" },
    { code: 13, description: "Isenção parcial" },
    { code: 14, description: "Cancelado" },
    { code: 15, description: "ISS retido pelo tomador / intermediário" },
    { code: 16, description: "Não tributável" },
    { code: 17, description: "Micro Empreendedor Individual(MEI)" },
    { code: 18, description: "Serviço para imóvel(Construção Civil)" },
    { code: 19, description: "Serviço em via pública" },
    { code: 20, description: "Tributada integralmente com ISSRF" },
    { code: 21, description: "Tributada com redução da base de cálculo" },
    { code: 22, description: "Tributada com redução da base de cálculo com ISSRF" },
    { code: 23, description: "Tributada com redução da base de cálculo com Sub.Tributária" },
    { code: 24, description: "Não tributada - ISS regime estimativa" },
    { code: 25, description: "Não Tributada - ISS construção civil recolhido antecipadamente" },
    { code: 26, description: "Não Tributada - Ato Cooperado" },
    { code: 27, description: "Simples Nacional e com o ISS retido na fonte" },
    { code: 28, description: "Substituição tributária pelo agenciador ou intermediário da prestação do serviço" },
    { code: 29, description: "Simples Nacional e substituição tributária" },
    { code: 30, description: "ISSRetidoAnteriormenteSubstitutoTributario" },
    { code: 31, description: "Tributada com redução da base de cálculo ou alíquota e com ISS retido na fonte" },
    { code: 32, description: "Tributada com reducao da base de cálculo ou alíquota e sujeita ao regime da substituicao tributária" },
    { code: 33, description: "Tributada com reducao da base de cálculo  ou aliquota e com o ISS retido anteriormente pelo substituto tributário" },
    { code: 34, description: "Nao tributada em razao do destino dos bens ou objetos - Mercadorias para a industrializacao ou comercializacao" },
    { code: 35, description: "Nao tributada em razao do diferimento da prestacao do servico" },
    { code: 36, description: "Tributado no município, porém Isento" },
    { code: 37, description: "Tributado Fora do município, porém Isento" },
    { code: 38, description: "Tributado no município, porém Imune" },
    { code: 39, description: "Tributado Fora do município, porém Imune" },
    { code: 40, description: "Tributado no município, porém Exigibilidade Suspensa" },
    { code: 41, description: "Tributado Fora do município, porém Exigibilidade Suspensa" },
    { code: 42, description: "Tratamento Fiscal Diferenciado" },
    { code: 43, description: "Estimado" },
    { code: 51, description: "Imposto devido no município, com retenção na fonte(serviço prestado no município)" },
    { code: 52, description: "Imposto devido no município, sem retenção na fonte(serviço prestado no município)" },
    { code: 58, description: "Não tributável(serviço prestado no município)" },
    { code: 59, description: "Simples Nacional(serviço prestado no município)" },
    { code: 61, description: "Imposto devido no município, com retenção na fonte(serviço prestado fora do município)" },
    { code: 62, description: "Imposto devido no município, sem retenção na fonte(serviço prestado fora do município)" },
    { code: 63, description: "Imposto devido fora do município, com retenção na fonte(serviço prestado fora do município)" },
    { code: 64, description: "Imposto devido fora do município, sem retenção na fonte(serviço prestado fora do município)" },
    { code: 68, description: "Não tributável(serviço prestado fora do município)" },
    { code: 69, description: "Simples Nacional(serviço prestado fora do Município)" },
    { code: 78, description: "Não tributável(serviço prestado no exterior)" },
    { code: 511, description: "Serviço no município - ISS mensal sem retenção na fonte" },
    { code: 512, description: "Serviço no município - ISS mensal com retenção na fonte" },
    { code: 521, description: "Construção civil - no município - ISS mensal sem retenção na fonte" },
    { code: 522, description: "Construção civil - no município - ISS mensal com retenção na fonte" },
    { code: 611, description: "Serviço em outro município - ISS mensal sem retenção na fonte" },
    { code: 612, description: "Serviço em outro município - ISS mensal com retenção na fonte" },
    { code: 621, description: "Construção civil - outro município - ISS mensal sem retenção na fonte" },
    { code: 622, description: "Construção civil - outro município - ISS mensal com retenção na fonte" },
    { code: 911, description: "Serviço não enquadrada nas situações anteriores - sem retenção" },
    { code: 912, description: "Serviço não enquadrada nas situações anteriores - com retenção" },
    { code: 501, description: "ISS devido para o município(Simples Nacional)" },
    { code: 502, description: "ISS devido para outro município(Simples Nacional)" },
    { code: 551, description: "Escritório Contábil(Simples Nacional)" },
    { code: 601, description: "ISS retido pelo tomador / intermediário(Simples Nacional)" },
    { code: 701, description: "Operação imune, isenta ou não tributada(Simples Nacional)" },
];
