import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable, firstValueFrom, of } from 'rxjs';
import { catchError, first, map, switchMap, timeout } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { VehicleUtilities } from '../class/vehicle-utilities';
import { Breakdowns } from '../interface/breakdowns';
import { RO_STATES } from '../shared/lists/ro-states';
import { DataService } from './data.service';
import { ApiPaginatedOSQueryParams, ApiPaginatedResponse, PaginationService } from './pagination.service';
import { PaginatedOS } from '../interface/paginated-os';
import { RoListStateGroup } from '../shared/pipes/ro-state-group.pipe';
import { RoTypeService } from './ro-type.service';
import { CompanyService } from './company.service';
import { RoType } from '../interface/ro-type';
import { Operation } from '../interface/operation';
import { OperationService } from './operation.service';
import { partStates } from '../shared/lists/part-states';
import { laborStates } from '../shared/lists/labor-states';
import { ApiOS, MkgOS } from '../class/mkg-os';
import { SessionOSservice } from './session/session-os.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../component/dialog/confirmation-dialog/confirmation-dialog.component';
import { LayoutService } from './layout.service';
import { SessionError } from './session/mkg-session';
import { UserService } from './user.service';
import moment from 'moment';
import { Utilities } from '../class/utilities';

@Injectable({
  providedIn: 'root'
})
export class RoService {
  private _isFromMatrix = false;
  public timestamps = new Map<string, Map<number, number>>();
  private roList = new Map<string, Map<string, MkgOS>>();
  private _http = inject(HttpClient);
  private _dataService = inject(DataService);
  private _dialog = inject(MatDialog);
  private _layout = inject(LayoutService);
  private _osSessionService = inject(SessionOSservice);
  private _storage = inject(AngularFireStorage);
  private _roTypeService = inject(RoTypeService);
  private _companyService = inject(CompanyService);
  private _operationService = inject(OperationService);
  private _userService = inject(UserService);
  public rosWithSessionError = new Set<string>();

  /**
   * Now, when getting an OS, the API will create a session and no one else will be able to obtain that OS, except by
   * providing the session hash.
   *
   * If the OS is in use by another user, the response will be different,
   * returning data with the session of the user who is using the OS and the session start date.
   *
   * Ask the user if they want to terminate the session of the user who is using the OS.
   *
   */
  async get(id: string, cnpj?: string, skipSessionValidation?: boolean): Promise<MkgOS> {
    const url = `${environment.mkgoURL}/api/v1/os/${id}`;
    const options = await firstValueFrom(this._dataService.httpOptions(cnpj ?? this._isFromMatrix));

    const params = (options.params || new HttpParams()).append("hash", this._osSessionService.sessionHash);
    options.params = params;
    return firstValueFrom(this._http.get<ApiOS>(url, options).pipe(
      first(),
      catchError(async error => { // if the OS is in use, the API will return an error
        if (error.status === 400 && (error.error as SessionError).userId) {
          const sessionError = error.error as SessionError;
          const sessionTimeStarted = new Date(+(sessionError as any).hash)
          const timeFormated = Utilities.transformTime(moment().diff(sessionTimeStarted, "seconds"));

          const currentUser = this._dataService.user;
          const user = await this._userService.get(sessionError.userId)

          if (skipSessionValidation) {
            return await firstValueFrom(this._osSessionService.closeSession(id, sessionError._id));
          };

          this.rosWithSessionError.add(id);

          if (user.id === currentUser.id) {
            return await firstValueFrom(this._osSessionService.closeSession(id, sessionError._id));
          }

          const result = await firstValueFrom(this._dialog.open(ConfirmationDialogComponent, {
            data: {
              text: "OS_IN_USE",
              param: {
                ...sessionError,
                name: user.name,
                email: user.email,
                date: timeFormated
              },
              uniqueAction: false,
            }
          }).afterClosed());
          if (result) {
            const os = await firstValueFrom(this._osSessionService.closeSession(id, sessionError._id));
            return os;
          }
        }
        throw error;
      }),
      map(resp => {
        return new MkgOS({ ...resp, cnpj });
      }),
    ));
  }

  /**
   * @deprecated
   * @param roState The max state to get, for example:
   * if roState is 9, the API will return ROs with currentState between 1 and 9.
   * @param maxTimestamp The last timestamp know
   */
  async getAll(roState: number, maxTimestamp = 0, params?: {
    startDate: string,
    endDate: string
  }, cnpj?: string): Promise<Map<string, MkgOS>> {
    if (roState == RO_STATES.all) {
      roState = RO_STATES.evaluation;
    } else if (roState == RO_STATES.rejected) {
      roState = RO_STATES.finished;
    }
    const timestamps = this.timestamps.get(this._dataService.company.id)
      || new Map<number, number>();
    this.timestamps.set(this._dataService.company.id, timestamps);

    timestamps.forEach((timestamp, state) => {
      if (state >= roState) {
        maxTimestamp = Math.max(maxTimestamp, timestamp);
      }
    });
    const url = `${environment.mkgoURL}/api/v1/os`;
    const options = {
      headers: { 'Company-CNPJ': cnpj || this._dataService.company.cnpj },
      params: { state: (roState + 1).toString(), timestamp: maxTimestamp.toString() }
    };

    if (params) {
      options.params = {
        // state: options.params.state,
        startDate: params.startDate,
        endDate: params.endDate
      } as any
    }

    const osArray = await firstValueFrom(this._http.get<{ os: ApiOS[] }>(url, options)
      .pipe(
        first(),
        map(resp => resp.os.reverse()),
        map(list => list.map(apiOS => {
          try {
            const os = new MkgOS(apiOS);
            return os
          } catch (error) {
            console.warn("Não foi possível converter o seguint objeto os em MKGOS", apiOS);
            throw error;
          }
        }))
      ));

    const osMap = new Map<string, MkgOS>();
    for (const os of osArray) {
      osMap.set(os.id, os)
    }

    return osMap;
  }

  getPaginated(state: RoListStateGroup, queryParams: ApiPaginatedOSQueryParams = { all: 1 }, cnpj?: string): Observable<ApiPaginatedResponse<PaginatedOS>> {
    const url = `${environment.mkgoURL}/api/v1/os/mobile`;
    let params;
    // if (queryParams.search) {
    // params = queryParams;
    // } else {
    const states = this._getStateCodes(state);
    params = PaginationService.getParams({ ...queryParams, state: states });
    // }
    const _cnpj = cnpj !== undefined ? cnpj : this._isFromMatrix;

    if (queryParams.user) {
      queryParams.user = this._transformSpecialCharacters(queryParams.user);
    }

    return this._dataService.httpOptions(_cnpj, params).pipe(
      switchMap(options => this._http.get<ApiPaginatedResponse<PaginatedOS>>(url, options)),
      first(),
      switchMap(resp => {
        if (queryParams.all && resp.hasNextPage) {
          queryParams.limit = resp.totalDocs;
          params = PaginationService.getParams(queryParams);
          return this._dataService.httpOptions(cnpj, params).pipe(
            switchMap(options2 => this._http.get<ApiPaginatedResponse<PaginatedOS>>(url, options2)),
            first()
          );
        } else if (Array.isArray(resp)) {
          return of({
            docs: resp,
            hasNextPage: false,
            hasPrevPage: false,
            limit: queryParams.limit,
            nextPage: false,
            page: queryParams.page,
            prevPage: false,
            totalDocs: resp.length,
            pagingCounter: undefined,
            totalPages: 0
          } as ApiPaginatedResponse<PaginatedOS>);
        } else {
          return of(resp);
        }
      }),
      map(resp => { // divide some values by 100
        resp.docs.map(os => {
          os.cnpj = cnpj || this._dataService.company.cnpj;

          if (state === 'rejected') {
            os.currentState = RO_STATES.rejected;
          }
          os.liquidValue =  (os.liquidValue || 0) / 100;

          os.parts.map(ospart => {
            ospart.purchaseValue = (ospart.purchaseValue || 0) / 100;
            ospart.purchaseAverage = (ospart.purchaseAverage || 0) / 100;
            ospart.saleValue = (ospart.saleValue || 0) / 100;
            ospart.liquidValue = (ospart.liquidValue || 0) / 100;
            return ospart;
          });

          os.labors.map(oslabor => {
            oslabor.saleValue = (oslabor.saleValue || 0) / 100;
            oslabor.liquidValue = (oslabor.liquidValue || 0) / 100;
            oslabor.discountValue = (oslabor.discountValue || 0) / 100;
            oslabor.discountProp = (oslabor.discountProp || 0) / 100;
            oslabor.discountPropGeral = (oslabor.discountPropGeral || 0) / 100;
            return oslabor;
          });

          os.thirdPartyServices.map(outsourced => {
            outsourced.collectedValue = (outsourced.collectedValue || 0) / 100;
            outsourced.paymentValue = (outsourced.paymentValue || 0) / 100;
            return outsourced;
          });

          os.liquidValueParts = os.parts.reduce((p1, p2) => p1 + p2.liquidValue, 0);
          os.liquidValueLabors = os.labors.reduce((l1, l2) => l1 + l2.liquidValue, 0);
          os.outsourcedPaid = os.thirdPartyServices.reduce((s1, s2) => s1 + s2.paymentValue, 0);
          os.outsourcedCollected = os.thirdPartyServices.reduce((s1, s2) => s1 + s2.collectedValue, 0);
          os.partsGrossValue = os.parts.filter(osPart => osPart.available !== partStates.rejected.id).reduce((sum, roPart) => sum + roPart.amount * roPart.saleValue, 0);
          os.laborsGrossValue = os.labors.filter(osLabor => osLabor.available !== laborStates.rejected.id).reduce((sum, osLabor) => sum + osLabor.amount * osLabor.saleValue, 0);
          os.grossValue = os.partsGrossValue + os.laborsGrossValue + os.outsourcedCollected;

          return os;
        });

        return resp;
      })
    );
  }

  private _transformSpecialCharacters(email: string) {
    return email.replaceAll(/@/g, "%40");
  }

  async getOsByPlate(plate: string): Promise<MkgOS[]> {
    const unmasked = VehicleUtilities.removeDiacritics(plate);
    const url = `${environment.mkgoURL}/api/v1/os/vehicle/${unmasked}`;
    const options = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    return firstValueFrom(this._http.get<{
      os: any[],
      vehicle: { id: string }
    }>(url, options).pipe(
      first(),
      map(resp => resp.os),
      map(osList => osList.map(apiOs => new MkgOS(apiOs)))
    ));
  }

  async getOsByClient(client: string): Promise<MkgOS[]> {
    const url = `${environment.mkgoURL}/api/v1/os/client/${client}`;
    const options = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const response = await firstValueFrom(this._http.get<{ clientOses: any[] }>(url, options)
      .pipe(
        first(),
        map(resp => resp.clientOses),
        map(rolist => rolist.map(ro => new MkgOS({...ro, id: ro._id })))
      ));
    return response;
  }

  async getPdf(id: string): Promise<string> {
    const url = `${environment.mkgoURL}/api/v1/pdf/os/${id}`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const response: any = firstValueFrom(await this._http.get(url, header));
    return response.url;
  }

  async downloadPdf(key: string) {
    const url = `${environment.mkgoURL}/short-pdf/${key}`;
    const options = {
      headers: { 'Company-CNPJ': this._dataService.company.cnpj }, responseType: 'blob' as 'blob'
    };
    return firstValueFrom(this._http.get(url, options));
  }

  async add(formValue: any) {
    const os = MkgOS.fromForm(formValue);
    os['lastUpdate'] = new Date().toISOString();
    const url = `${environment.mkgoURL}/api/v1/os`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    return firstValueFrom(this._http.post<{
      id: string,
      code: string
    }>(url, os, header));
  }

  /** state 2(budget) to state 4(authorization) */
  async requestAuthorization(ro: MkgOS, cnpj?: string) {
    const url = `${environment.mkgoURL}/api/v1/os/${ro.id}/authorization`;
    let body = {
      discountLabors: ro.discountLabors,
      discountParts: ro.discountParts,
      lastUpdate: new Date().toISOString()
    }
    if (ro.deliveryForecast) {
      body['deliveryForecast'] = new Date(ro.deliveryForecast).toISOString();
    }
    const header = await firstValueFrom(this._dataService.httpOptions(cnpj ?? this._isFromMatrix));
    return firstValueFrom(this._http.post(url, body, header));
  }

  /** return budget to state 2 */
  async returnToBudget(id: string) {
    const url = `${environment.mkgoURL}/api/v1/os/${id}/budget`;
    const body = { lastUpdate: new Date().toISOString() };
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    return firstValueFrom(this._http.post(url, body, header));
  }

  /** from state 4(authorization) to state 5(appointment) */
  async authorize(id: string, authorizationType: number, cnpj?: string) {
    const url = `${environment.mkgoURL}/api/v1/os/${id}/authorization/${authorizationType}`;
    const body = { 'lastUpdate': new Date().toISOString() }
    const header = await firstValueFrom(this._dataService.httpOptions(cnpj ?? this._isFromMatrix));
    return firstValueFrom(this._http.post(url, body, header));
  }

  /** Make the appointment, no changing the ro state **/
  async makeAppointment(id: string, appointment: Date, cnpj?: string) {
    const url = `${environment.mkgoURL}/api/v1/os/${id}/appointment`;
    const body = {
      'appointment': appointment.toISOString(),
      'lastUpdate': new Date().toISOString()
    }
    const header = await firstValueFrom(this._dataService.httpOptions(cnpj ?? this._isFromMatrix));
    return firstValueFrom(this._http.post(url, body, header));
  }

  /** from state 5(appointment) or 6(ready to start) to state 7(started) */
  async open(id: string, cnpj?: string) {
    const url = `${environment.mkgoURL}/api/v1/os/${id}/start`;
    const body = { 'lastUpdate': new Date().toISOString() };
    const header = await firstValueFrom(this._dataService.httpOptions(cnpj ?? this._isFromMatrix));
    return firstValueFrom(this._http.post(url, body, header));
  }

  /** from state 7(started) to state 8(to receive) */
  async close(id: string) {
    const url = `${environment.mkgoURL}/api/v1/os/${id}/finish`;
    const body = { 'lastUpdate': new Date().toISOString() };
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    return firstValueFrom(this._http.post(url, body, header));
  }

  /** form state 8(to receive) to state 10(finished) */
  async finish(id: string, cnpj?: string) {
    const url = `${environment.mkgoURL}/api/v1/os/${id}/end`;
    const body = { 'lastUpdate': new Date().toISOString() };
    const header = await firstValueFrom(this._dataService.httpOptions(cnpj ?? this._isFromMatrix));
    return firstValueFrom(this._http.post(url, body, header));
  }


  /** Update fields of OS
   *
   * if you want to change `paymentCondition`, use a [PaymentService](./payment.service.ts) instance
   *
   * if you want to change the `checklist` array, use a [ChecklistService](./checklist.service.ts) instance
  *
   * if you want to change `breakdowns`, use the function {@link saveBreakdows}
   */
  async update(id: string, args: { field: keyof MkgOS, newValue: any }[]) {
    const url = `${environment.mkgoURL}/api/v1/os/${id}`;
    let params = { 'lastUpdate': new Date().toISOString() }
    for (const arg of args) {
      params[arg.field] = arg.newValue;
    }
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    return firstValueFrom(this._http.put(url, JSON.stringify(params), header));
  }

  async updateDiscount(ro: MkgOS) {
    const url = `${environment.mkgoURL}/api/v1/os/${ro.id}/discount`;
    const params: Partial<MkgOS> = {
      grossValueParts: (ro.grossValueParts ?? 0),
      discountParts: (ro.discountParts ?? 0),
      valueDiscountParts: (ro.valueDiscountParts ?? 0),
      liquidValueParts: (ro.liquidValueParts ?? 0),
      grossValueLabors: (ro.grossValueLabors ?? 0),
      discountLabors: (ro.discountLabors ?? 0),
      valueDiscountLabors: (ro.valueDiscountLabors ?? 0),
      liquidValueLabors: (ro.liquidValueLabors ?? 0),
      grossValue: (ro.grossValue ?? 0),
      generalDiscount: (ro.generalDiscount ?? 0),
      valueDiscountOs: (ro.valueDiscountOs ?? 0),
      liquidValue: (ro.liquidValue ?? 0) * 100,
      retISS: (ro.retISS ?? 0),
      valDeducoesParts: (ro.valDeducoesParts ?? 0),
      valDeducoesLabors: (ro.valDeducoesLabors ?? 0),
      lastUpdate: new Date().toISOString()
    };
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    return firstValueFrom(this._http.post(url, params, header));
  }

  async createBudgetCode(ro: MkgOS): Promise<number> {
    const url = `${environment.mkgoURL}/api/v1/os/${ro.id}`;
    let options = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const params = new HttpParams().append("budgetCode", "1");
    options['params'] = params;
    const resp = firstValueFrom(await this._http.post(url, {}, options));

    // update local roList
    if (resp['budgetCode']) {
      const roList = this.roList.get(this._dataService.company.id) || new Map<string, MkgOS>();
      ro.budgetCode = resp['budgetCode']
      roList.set(ro.id, ro)
      this.roList.set(this._dataService.company.id, roList);
      return resp['budgetCode']
    }
    return undefined
  }


  async createCodeSystem(ro: MkgOS): Promise<number> {
    const url = `${environment.mkgoURL}/api/v1/os/${ro.id}`;
    let options = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const params = new HttpParams().append("codeSystem", "1");
    options['params'] = params;
    const resp = firstValueFrom(await this._http.post(url, {}, options));

    // update local roList
    if (resp['codeSystem']) {
      const roList = this.roList.get(this._dataService.company.id) || new Map<string, MkgOS>();
      ro.codeSystem = resp['codeSystem']
      roList.set(ro.id, ro)
      this.roList.set(this._dataService.company.id, roList);
      return resp['codeSystem']
    }
    return undefined
  }

  // async updateProblem(id: string, problem: string) {
  //   const url = `${environment.mkgoURL}/api/v1/os/${id}/update`
  //   const params = {
  //     'problem': problem,
  //     'lastUpdate': new Date().toISOString()
  //   }
  //   const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
  //   return this.http.put(url, params, header).pipe(first()).toPromise();
  // }

  /** @todo test */
  public async storeDamagesImage(osId: string, blob: Blob): Promise<string> {
    const path = `${this._dataService.company.id}/breakdowns/${osId}`;
    // save the image into firebase
    await this._storage.upload(path, blob);

    // retrieve public url for image
    return firstValueFrom(this._storage.ref(path).getDownloadURL());
  }

  /** @todo test */
  public async storeAditionalDamagesImage(osId: string, blob: Blob, index: number): Promise<string> {
    const path = `${this._dataService.company.id}/breakdowns_attachments/${osId}/${index}`;
    // save the image into firebase
    await this._storage.upload(path, blob);

    // retrieve public url for image
    return firstValueFrom(this._storage.ref(path).getDownloadURL());
  }

  public async storeSignatureImage(osId: string, blob: Blob): Promise<string> {
    // prepare path
    const path = `${this._dataService.company.id}/signature/${osId}`;

    // save the image into firebase
    await this._storage.upload(path, blob);

    // retrieve public url for image
    return firstValueFrom(this._storage.ref(path).getDownloadURL());
  }

  public async getBreakdownsImage(osId: string) {
    const url = `${environment.mkgoURL}/api/v1/os/${osId}/image`;
    const options = {
      headers: { 'Company-CNPJ': this._dataService.company.cnpj },
      responseType: 'blob' as 'blob'
    };
    const resp = await firstValueFrom(this._http.get(url, options).pipe(timeout(15000)))
    return resp;
  }

  public async getSignatureImage(osId: string) {
    const url = `${environment.mkgoURL}/api/v1/os/${osId}/signature`;
    const options = {
      headers: { 'Company-CNPJ': this._dataService.company.cnpj },
      responseType: 'blob' as 'blob'
    };
    return firstValueFrom(this._http.get(url, options).pipe(timeout(15000)))
  }


  /** Avoid CORS downloading firebase resources */
  async getBlob(firebaseUrl: string): Promise<Blob> {
    const url = `${environment.mkgoURL}/api/v1/image-downloader`;
    const options = {
      headers: { 'Company-CNPJ': this._dataService.company.cnpj },
      responseType: 'blob' as 'blob'
    };
    return firstValueFrom(this._http.post(url, { url: firebaseUrl }, options));
  }

  async saveBreakdows(osId: string, breakdowns: Breakdowns) {
    const url = `${environment.mkgoURL}/api/v1/os/${osId}/breakdowns`;
    const options = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    return firstValueFrom(this._http.post(url, breakdowns, options));
  }

  private _getStateCodes(stateGroup: RoListStateGroup) {
    switch (stateGroup) {
      case 'budget':
        return [
          RO_STATES.budget,
          RO_STATES.authorization,
          RO_STATES.appointment,
          RO_STATES.readyToStart
        ];
      case 'opened':
        return [RO_STATES.started];
      case 'integrated_opened':
        return [
          RO_STATES.started,
          RO_STATES.toReceive,
          RO_STATES.evaluation
        ];
      case 'closed':
        return [RO_STATES.toReceive, RO_STATES.evaluation];
      case 'ended':
        return [RO_STATES.finished];
      case 'rejected':
        return [RO_STATES.rejected];
      case 'closed-budget':
        return [RO_STATES.closedBudget];
      case "all":
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      default:
        return []
    }
  }

  /** Find cnpj to use on invoices and titles */
  public async getMatrixConfigFor(OS: MkgOS) {
    let companyParts = this._dataService.company;
    let companyLabors = this._dataService.company;
    let operationParts: Operation;
    let operationLabors: Operation;
    let matrixIsFor: "parts" | "labors" | "none" = "none";
    let roType: RoType;

    if (OS.type) {
      roType = await this._roTypeService.get(typeof OS.type === "string" ? OS.type : OS.type.id);

      if (roType) {
        if (roType.nfeCnpj?.trim() && roType.nfeCnpj != companyParts.cnpj.trim()) {
          companyParts = await this._companyService.getByCnpj(roType.nfeCnpj, true);
          matrixIsFor = 'parts';
        }
        if (roType.nfseCnpj?.trim() && roType.nfseCnpj != companyLabors.cnpj.trim()) {
          companyLabors = await this._companyService.getByCnpj(roType.nfseCnpj, true);
          matrixIsFor = 'labors';
        }

        if (roType.operation && matrixIsFor !== "none") {
          let operation: Operation;
          try {
            operation = await this._operationService.getById(roType.operation);
          } catch (error) {
            console.error("Operação do tipo de OS não encontrada", { id: roType.operation })
          }
          if (operation) {
            if (matrixIsFor === "parts") {
              operationParts = operation;
            } else if (matrixIsFor == "labors") {
              operationLabors = operation;
            }
          }
        }
      }
    }

    if (!operationParts && !operationLabors) {
      const commonOperation = await this._operationService.getById(OS?.operationId ?? (OS.operation?.id ?? OS.operation as any));
      operationLabors = commonOperation;
      operationParts = commonOperation;
    } else {
      if (!operationParts) {
        try {
          operationParts = await this._operationService.getById(OS?.operationId ?? (OS.operation?.id ?? OS.operation as any));
        } catch (error) {
          console.error("Operação da OS não encontrada", { id: OS?.operationId ?? (OS.operation?.id ?? OS.operation as any) })
        }
      }
      if (!operationLabors) {
        try {
          operationLabors = await this._operationService.getById(OS?.operationId ?? (OS.operation?.id ?? OS.operation as any));
        } catch (error) {
          console.error("Operação da OS não encontrada", { id: OS?.operationId ?? (OS.operation?.id ?? OS.operation as any) })
        }
      }
    }

    return { companyParts, companyLabors, matrixIsFor, roType, operationParts, operationLabors }
  }


  async getStatistics(receiveDateStart: string, receiveDateEnd: string/*params?: {
    receiveDateStart: string,
    receiveDateEnd: string
  }*/)/*: Promise<any>*/ {
    const url = `${environment.mkgoURL}/api/v1/os/statistics`;
    const options = {
      headers: { 'Company-CNPJ': this._dataService.company.cnpj },
      params: {
        receiveDateStart: receiveDateStart, // params.receiveDateStart,
        receiveDateEnd: receiveDateEnd // params.receiveDateEnd
      }
    };

    return firstValueFrom(this._http.get(url, options));
  }

  async getRosWithSomePart(partId: string) {
    const url = `${environment.mkgoURL}/api/v1/os/any-os/parts/${partId}`;
    const options = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const response = await firstValueFrom(this._http.get<{ os: MkgOS[] }>(url, options).pipe(first()));
    return response.os;
  }
}
