import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ObjectId } from '../../shared/type-aliases/object-id';
import { ApiOS } from '../../class/mkg-os';
import { DataService } from '../data.service';
import { switchMap } from 'rxjs';
import { MkgSession } from './mkg-session';

@Injectable({
  providedIn: 'root'
})
export class SessionOSservice extends MkgSession<ApiOS> {

  private readonly _isFromMatrix = false;
  private _http = inject(HttpClient);
  private _dataService = inject(DataService);

  constructor() {
    super("osSessionHash")
  }

  override closeSession(osId: ObjectId, sessionId: ObjectId) {
    const url = `${environment.mkgoURL}/api/v1/os/${osId}/session/close/${sessionId}`;
    return this._dataService.httpOptions(this._isFromMatrix)
      .pipe(
        switchMap(options => {
          this.createHash();
          let params = options.params || new HttpParams();
          params = params.set('hash', this.sessionHash);
          options.params = params;
          return this._http.get<ApiOS>(url, options)
        })
      )
  }

  override forceCloseAllSessions(osId: ObjectId) {
    const url = `${environment.mkgoURL}/api/v1/os-sessions/${osId}`;
    return this._dataService.httpOptions(this._isFromMatrix)
      .pipe(
        switchMap(options => this._http.post(url, {}, options))
      );
  }

}
