"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Grouper = void 0;
;
class Grouper {
    constructor(array) {
        this._labelMap = new Map();
        this._dataSource = array;
    }
    getDataGroupedBy(property, withDefault = false) {
        const responseMap = new Map();
        if (this._labelMap.has(property)) {
            const { arrayData, defaultLabel, getLabelFn } = this._labelMap.get(property);
            for (const element of this._dataSource) {
                let key = getLabelFn(element, arrayData);
                if (!key) {
                    if (!withDefault) {
                        continue;
                    }
                    else {
                        key = defaultLabel;
                    }
                }
                if (responseMap.has(key)) {
                    responseMap.get(key)?.push(element);
                }
                else {
                    responseMap.set(key, [element]);
                }
            }
        }
        else {
            for (const element of this._dataSource) {
                const value = element[property];
                const key = `${value}`;
                if (!key && !withDefault) {
                    continue;
                }
                if (responseMap.has(key)) {
                    responseMap.get(key)?.push(element);
                }
                else {
                    responseMap.set(key, [element]);
                }
            }
        }
        return responseMap;
    }
    defineLabelHandler(propertyName, defaultLabel, arrayData, getLabelFn) {
        this._labelMap.set(propertyName, {
            arrayData, defaultLabel, getLabelFn
        });
    }
}
exports.Grouper = Grouper;
;
