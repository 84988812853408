<h1 mat-dialog-title>
  {{ !os?.operation?.docFiscal && !os?.operation?.docFiscalServico ? 'Emitir operação interna' : 'Emitir nota' +
  (data?.linkNFSe ? 's' : '') }}
</h1>


<mat-dialog-content>
  <p class="d-flex"
     *ngIf="data.linkNf">
    <span>{{ !os?.operation?.docFiscal && !os?.operation?.docFiscalServico ? this.isOIM(data.invoiceState) :
      data.model
      }}</span>
    <mat-spinner class="mx-3"
                 *ngIf="nfError === undefined"
                 [diameter]="20"></mat-spinner>

    <ng-container *ngIf="nfError !== undefined">
      <mat-icon class="mx-3 red"
                *ngIf="nfError">error_outline</mat-icon>
      <span *ngIf="nfError && nfError !== 'not_found'">Houve um erro.</span>
    </ng-container>

    <ng-container *ngIf="nfError === null">
      <mat-icon class="mx-3 green">check_circle_outline</mat-icon>
      <span>Emitida com sucesso. </span>
    </ng-container>

    <a mat-button
       *ngIf="nfError !== 'not_found'"
       class="line-heigth-initial"
       [mat-dialog-close]="false"
       [routerLink]="['/company', data.companyParts.id, 'invoices', 'nf', data.linkNf]"
       color="primary">{{
      !os?.operation?.docFiscal && !os?.operation?.docFiscalServico ?
      'Ver operação' : 'Ver nota'}}</a>
  </p>

  <p class="d-flex"
     *ngIf="data.linkNFSe">
    <span>{{ !os?.operation?.docFiscal && !os?.operation?.docFiscalServico ? 'OIS' : 'NFSe'}}</span>
    <mat-spinner class="mx-3"
                 *ngIf="nfseError === undefined"
                 [diameter]="20"></mat-spinner>


    <ng-container *ngIf="nfseError !== undefined">
      <mat-icon class="mx-3 red"
                *ngIf="nfseError">error_outline</mat-icon>
      <span *ngIf="nfseError && nfseError !== 'not_found'">Houve um erro.</span>
    </ng-container>

    <ng-container *ngIf="nfseError === null">
      <mat-icon class="mx-3 green">check_circle_outline</mat-icon>
      <span>Emitida com sucesso. </span>
    </ng-container>

    <a mat-button
       *ngIf="data?.linkNFSe"
       class="line-heigth-initial"
       [mat-dialog-close]="false"
       [routerLink]="['/company', data.companyLabors.id, 'invoices', 'nfse', data.linkNFSe]"
       color="primary">{{ !os?.operation?.docFiscal && !os?.operation?.docFiscalServico ?
      'Ver operação' : 'Ver nota'}}</a>

  </p>
</mat-dialog-content>

<mat-dialog-actions [ngClass]="{'mobile': (layout.isHandset$ | async) }"
                    *ngIf="dataService.company">

  <button mat-raised-button
          [mat-dialog-close]="false"
          color="accent">Fechar</button>

  <a mat-raised-button
     *ngIf="data.backTo === 'budget'"
     [mat-dialog-close]="false"
     [routerLink]="['/company', dataService.company.id, 'os', 'create']"
     color="primary">Abrir outro orçamento</a>

  <a mat-raised-button
     *ngIf="data.backTo === 'partial'"
     [mat-dialog-close]="false"
     [routerLink]="['/company', dataService.company.id, 'invoices', 'partials', 'new']"
     color="primary">Abrir outra parcial</a>

  <a mat-raised-button
     *ngIf="data.backTo === 'invoice'"
     [mat-dialog-close]="false"
     [routerLink]="['/company', dataService.company.id, 'invoices', 'nf']"
     color="primary">Criar outra nota</a>

  <a mat-raised-button
     *ngIf="data.backTo !== 'partial'"
     [mat-dialog-close]="false"
     [routerLink]="['/company', dataService.company.id, 'invoices']"
     color="primary">Ir para notas</a>

  <a mat-raised-button
     *ngIf="data.backTo === 'partial'"
     [mat-dialog-close]="false"
     [routerLink]="['/company', dataService.company.id, 'invoices', 'partials']"
     color="primary">Ir para parciais</a>

  <button mat-raised-button
          *ngIf="data.boletoTitles.length > 0 && !createdBoleto()"
          color="primary"
          (click)="createBoleto()">Gerar Boletos</button>

  <a mat-raised-button
     *ngIf="createdBoleto()"
     [mat-dialog-close]="false"
     [href]="createdBoleto()?.group_link || createdBoleto()?.boleto_link"
     target="_blank"
     color="primary">Ver Boletos</a>


  <!-- <button mat-raised-button
       *ngIf="data.backTo !== 'partial'"
       [mat-dialog-close]="false"
       (click)="chamaDialog()"
       color="primary">Enviar pesquisa</button> -->

  <!-- <button mat-raised-button
            color="primary"
            *ngIf="data.codeSystem"
            (click)="payTitles()">
       Receber
    </button> -->

</mat-dialog-actions>