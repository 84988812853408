export const PRESENCE_INDICATION = [
  {
    code: 1,
    description: 'Operação presencial'
  },
  {
    code: 2,
    description: 'Operação não presencial, pela Internet'
  },
  {
    code: 3,
    description: 'Operação não presencial, Telemarketing'
  },
  {
    code: 4,
    description: 'NFC-e em operação com entrega à domicílio'
  },
  {
    code: 9,
    description: 'Operação não presencial, outros'
  },
  {
    code: 0,
    description: 'Não se aplica'
  }
]
