import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "../../../environments/environment";
import { NfUtilities } from "../../class/nf-utilities";
import { Utilities } from "../../class/utilities";
import { City } from "../../interface/city";
import { Client } from "../../interface/client";
import { Company } from "../../interface/company";
import { detItem55, NFe } from "../../interface/NF-e";
import { NFCe } from "../../interface/NFC-e";
import { Operation } from "../../interface/operation";
import { COUNTRIES } from "../../shared/lists/country-codes";
import { DOCUMENT_TYPE_CODES } from "../../shared/lists/document-type";
import { AddressService } from "../address.service";
import { OperationService } from "../operation.service";
import { NfceService } from "./nfce.service";
import { NfeService } from "./nfe.service";
import { CFOP_list } from "../../shared/lists/cfop";
import { IFiscalParts } from "src/app/interface/ifiscal-parts";

@Injectable({
  providedIn: 'root'
})
export class FiscalService {
  public nota: NfeService | NfceService;
  public company: Company;
  public client: Client;
  public operation: Operation;
  private companyCity: City;
  public ready = new BehaviorSubject<[Client, Company, Operation]>([null, null, null]);
  public discount: number = 0;

  constructor(
    private addressService: AddressService,
    private operationService: OperationService,
  ) {
    this.onReady();
  }

  setNota(notaFiscal: NfeService | NfceService) {
    this.nota = notaFiscal;
    this.nota.ModeloDocumento = notaFiscal instanceof NfeService ? 'NFe' : 'NFCe';
    this.nota.Versao = 4.00;
  }

  public async setEmitByCompany(company: Company) {
    this.company = company;
    this.ready.next([this.client, this.company, this.operation]);
    this.companyCity = await this.addressService.getCity(company.address.city, company.address.state);
    this.nota.emit = {
      xNome: company.socialName,
      xFant: company.fancyName || company.socialName,
      CNPJ_emit: company.cnpj.replace(/\D/g, ''),
      CRT: Number(company.crt),
      IE: company.stateRegistration,
      enderEmit: {
        CEP: Number(company.address.cep.replace(/\D/g, '')),
        UF: this.addressService.getState(company.address.state).code,
        cMun: Number(this.companyCity.igbe),
        nro: company.address.number,
        xBairro: company.address.neighborhood,
        xLgr: company.address.street,
        xMun: this.companyCity.name,
        // Email: this.dataService.company.email
      }
    }
  }

  public async setDestByClient(client: Client) {
    this.client = client;
    this.ready.next([this.client, this.company, this.operation]);
    const clientCity = await this.addressService.getCity(client.address.city, client.address.state);
    let enderDest: NFCe["dest"]["enderDest"] = {
      UF_dest: this.addressService.getState(client.address.state).code,
      cMun_dest: Number(clientCity.igbe),
      nro_dest: client.address.number,
      xBairro_dest: client.address.neighborhood,
      xLgr_dest: client.address.street,
      xMun_dest: clientCity.name,
    }
    if (client.address.cep) {
      enderDest.CEP_dest = Number((client.address.cep || '').replace(/\D/g, ''));
    }
    if (client.phone1) {
      enderDest.fone_dest = Number((client.phone1 || '').replace(/\D/g, ''));
    }
    if (client.address.complement) {
      enderDest.xCpl_dest = client.address.complement;
    }
    if (client.email) {
      enderDest.xEmail_dest = client.email
    }

    let dest: NFe["dest"] = {
      xNome_dest: client.name,
      enderDest: enderDest,
      indIEDest: 9
    };

    switch (client.documentType) {
      case DOCUMENT_TYPE_CODES.juridic:
        if (client.rg) {
          dest.CNPJ_dest = client.document;
          dest.indIEDest = 1;
          dest.IE_dest = client.rg;
        } else {
          dest.CNPJ_dest = client.document;
          dest.indIEDest = 9; // ISENTO
        }
        break;
      case DOCUMENT_TYPE_CODES.physical:
        dest.CPF_dest = client.document;
        dest.indIEDest = 9;
        break;
      case DOCUMENT_TYPE_CODES.foreign:
        const country = COUNTRIES.find(c => c.code == client.address.cep);
        dest.idEstrangeiro = client.document
        dest.indIEDest = 9;
        dest.enderDest = {
          xLgr_dest: "EXTERIOR",
          nro_dest: "1",
          xBairro_dest: "EXTERIOR",
          cMun_dest: 9999999,
          xMun_dest: "EXTERIOR",
          UF_dest: "EX",
          CEP_dest: 99999998,
          cPais_dest: Number(country.code),
          xPais_dest: country.name,
        }
        if (client.phone1) {
          dest.enderDest.fone_dest = Number((client.phone1 || '').replace(/\D/g, ''));
        }
        this.nota.ide.idDest = 3;
        let EXPORTATION_CFOPs = CFOP_list.filter(cfop => cfop.isExportation);
        if (this.nota.ModeloDocumento === "NFe" && this.nota.det.some(item => EXPORTATION_CFOPs.some(cfop => cfop.code === `${item.prod.CFOP}`))) {
          (this.nota as NFe).exporta = {
            UFSaidaPais: this.nota.emit.enderEmit.UF,
            xLocExporta: this.nota.emit.enderEmit.xMun
          }
        }
        break;
    }

    this.nota.dest = dest;
  }

  public async setOperation(operation: Operation) {
    this.operation = operation;
  }

  public addPart(fiscalPart: IFiscalParts) {
    fiscalPart.nota = this;
    let det: any = this.nota.ModeloDocumento == 'NFe' ? fiscalPart.getDetItem55() : fiscalPart.getDetItem65();
    this.nota.det.push(det);
  }

  public onReady() {
    this.ready.subscribe(([client, company, operation]) => {
      if (!client || !company || !operation) {
        return;
      }
      this.setIde();
    })
  }



  public setInfoComplement(info: string) {
    if (!(this.nota instanceof NfceService)) {
      this.nota.infAdic.infCpl = info;
    }
  }

  public getTipoNota() {
    return (this.nota instanceof NfceService) ? 'NFe' : 'NFCe';
  }

  public zerar() {
    this.nota = null;
    this.operation = null;
    this.company = null;
    this.client = null;
    this.companyCity = null;
  }

  public getGeneretedNfe(): NfeService | NfceService {
    this.setFrete();
    this.nota.total = {
      ICMStot: {}
    } as any;
    let nota = this.calculateTotals(this.nota);
    this.zerar();
    return nota;
  }


  calculateTotals(invoice: NfeService | NfceService, isDevolution?: boolean): (NfeService | NfceService) {
    let netValue = 0
    let taxes = 0;
    let totalICMS = 0
    let totalPis = 0;
    let totalIPI = 0;
    let totalCofins = 0;
    let totalST = 0;
    let totalDiscount = 0;
    const isSupersimples = invoice.emit.CRT === 1 || invoice.emit.CRT === 4;

    for (const item of invoice.det) {
      let sum = 0;

      // calc gross value
      item.prod.vProd = (item.prod.vUnTrib * item.prod.qTrib) || 0;
      netValue += item.prod.vProd;
      totalDiscount += (item.prod.vDesc || 0);


      // define ICMS BC
      item.imposto.ICMS.vBC = isDevolution ? 0 : null;
      if (item.imposto.ICMS.CST === '900') {
        const readjusmentPercentage = item.imposto.ICMS.pRedBC || 0;
        item.imposto.ICMS.vBC = Utilities.roundNumber(item.prod.vProd - (item.prod.vProd * (readjusmentPercentage) / 100));
      }
      // calc ICMS values
      item.imposto.ICMS.vICMS_icms = Utilities.roundNumber(item.imposto.ICMS.pICMS ? (Number(item.imposto.ICMS.vBC) * Number(item.imposto.ICMS.pICMS) / 100) : (Number(item.imposto.ICMS['vICMS']) || Number(item.imposto.ICMS.vICMS_icms)));
      item.imposto.ICMS.pRedBC = 0;
      totalICMS += item.imposto.ICMS.vICMS_icms;

      // calc PIS value
      if ((item as detItem55).imposto.PIS && (item as detItem55).imposto.PIS.CST_pis) {
        (item as detItem55).imposto.PIS.vPIS = Utilities.roundNumber((item as detItem55).imposto.PIS.pPIS ? (item.prod.vProd * (item as detItem55).imposto.PIS.pPIS / 100) : 0);
        totalPis += (item as detItem55).imposto.PIS.vPIS;
        sum += (item as detItem55).imposto.PIS.vPIS;
      } else {
        delete item.imposto['PIS'];
      }

      // calc COFINS value
      if ((item as detItem55).imposto.COFINS && (item as detItem55).imposto.COFINS.CST_cofins) {
        (item as detItem55).imposto.COFINS.vCOFINS = Utilities.roundNumber((item as detItem55).imposto.COFINS.pCOFINS ? (item.prod.vProd * (item as detItem55).imposto.COFINS.pCOFINS / 100) : 0);
        totalCofins += (item as detItem55).imposto.COFINS.vCOFINS;
        sum += (item as detItem55).imposto.COFINS.vCOFINS;
      } else {
        delete item.imposto['COFINS'];
      }

      // calc IPI value
      const ipi_value = Utilities.roundNumber((item as detItem55).imposto.IPI && (item as detItem55).imposto.IPI.CSTIPI && Number.isFinite((item as detItem55).imposto.IPI.CSTIPI.vIPI) ? (item as detItem55).imposto.IPI.CSTIPI.vIPI : 0);
      totalIPI += ipi_value;
      sum += ipi_value;

      // calc SuperSimple
      item.imposto.ICMS.vCredICMSSN = Utilities.roundNumber((item.imposto.ICMS.pCredSN ? (item.prod.vProd * item.imposto.ICMS.pCredSN / 100) : 0));
      sum += item.imposto.ICMS.vCredICMSSN;

      // calc ICMS_ST value

      /** rejection 553: Total da BC ICMS-ST difere do somatório dos itens */
      // item.imposto.ICMS.vBCST = item.prod.vProd + (item.prod.vProd * item.imposto.ICMS.pMVAST / 100);
      item.imposto.ICMS.vBCST = 0;

      const icms_st_value = Utilities.roundNumber((item.imposto.ICMS.vBCST * item.imposto.ICMS.pICMS / 100) - item.prod.vProd);
      item.imposto.ICMS.vICMSST_icms = Math.max(icms_st_value, 0);
      item.imposto.ICMS.vICMSST_icms = 0;
      totalST += (item.imposto.ICMS.vICMSST_icms || 0);
      sum += item.imposto.ICMS.vICMSST_icms;

      taxes += sum;
      item.imposto.vTotTrib = sum;

      // cast string values to number
      item.prod.vFrete = Number(item.prod.vFrete) || 0
    }

    let freight_value = 0;
    let other_expenses = 0;
    let insurance_value = 0;
    let bcst = 0;
    if (invoice.det.length) {
      // sum freigth
      freight_value = Utilities.roundNumber(
        (invoice.det as detItem55[])
          .slice()
          .map((item) => Number(item.prod.vFrete))
          .reduce((prev, curr) => prev + curr));

      // sum others
      other_expenses = Utilities.roundNumber(
        (invoice.det as detItem55[])
          .slice()
          .map((item) => Number(item.prod.vOutro_item))
          .reduce((prev, curr) => prev + curr));

      // sum insurance
      insurance_value = Utilities.roundNumber(
        (invoice.det as detItem55[])
          .slice()
          .map((item) => Number(item.prod.vSeg))
          .reduce((prev, curr) => prev + curr));

      // sum BC_ST
      bcst = Utilities.roundNumber(
        (invoice.det as detItem55[])
          .slice()
          .map((item) => Number(item.imposto.ICMS ? item.imposto.ICMS.vBCST : 0))
          .reduce((prev, curr) => prev + curr));

      // sum volumes (transporter amount)
      const totalAmount = (invoice.det as detItem55[])
        .slice()
        .map((item) => item.prod.qTrib)
        .reduce((prev, curr) => prev + curr);
      if (invoice.transp) {
        invoice['transp']['vol'] = [{
          "nVol": '0',
          "qVol": totalAmount
        }];
      }
    }

    const ipiDevol = NfUtilities.getPropertyOf(invoice as any, 'vIPIDevol');

    // sum BC_icms
    let vBC = 0;
    if (invoice.det.length) {
      vBC = invoice.det.slice()
        .map(det => det.imposto.ICMS.vBC)
        .reduce((a, b) => a + b);
    }

    // sum taxes
    invoice['total']['ICMStot'] = {
      vICMS_ttlnfe: Utilities.roundNumber(totalICMS),
      vPIS_ttlnfe: Utilities.roundNumber(totalPis),
      vIPI_ttlnfe: Utilities.roundNumber(totalIPI),
      vCOFINS_ttlnfe: Utilities.roundNumber(totalCofins),
      vST_ttlnfe: Utilities.roundNumber(totalST),
      vProd_ttlnfe: Utilities.roundNumber(netValue),
      vTotTrib_ttlnfe: Utilities.roundNumber(taxes),
      vBC_ttlnfe: Utilities.roundNumber(vBC),
      vDesc_ttlnfe: Utilities.roundNumber(totalDiscount),
      vNF: Utilities.roundNumber(netValue - totalDiscount),
      vII_ttlnfe: 0,
      vFCPSTRet_ttlnfe: 0,
      vFCPST_ttlnfe: 0,
      vFCP_ttlnfe: 0,
      vIPIDevol_ttlnfe: Utilities.roundNumber(ipiDevol),
      vOutro: Utilities.roundNumber(other_expenses),
      vFrete_ttlnfe: Utilities.roundNumber(freight_value),
      vBCST_ttlnfe: Utilities.roundNumber(bcst),
      vSeg_ttlnfe: Utilities.roundNumber(insurance_value),
    }

    delete invoice.total['ICMSTot']

    invoice.total.ICMStot.vNF = (netValue
      + (freight_value || 0)
      + (insurance_value || 0)
      + (other_expenses || 0)
      - (totalDiscount || 0));
    return invoice
  }

  private setFrete() {
    this.nota.transp = { modFrete: 9 };
  }

  public async setIde() {
    const isIntern = this.company.address.state === this.client.address.state;

    let nNF;
    if (this.operation.docFiscal) {
      nNF = this.nota.ModeloDocumento === "NFe" ? this.company.numberInvoice : this.company.numberInvoiceNFC;
    } else {
      nNF = this.company.numberGerencial;
    }

    this.nota.ide = {
      cMunFg: Number(this.companyCity.igbe),
      cUF: this.addressService.getState(this.company.address.state).uf,
      finNFe: 1,
      dhEmi: this.getDate(),
      fusoHorario: this.getFuso(),
      idDest: isIntern ? 1 : 2,
      indFinal: 1,
      indPres: 1,
      mod: (this.nota.ModeloDocumento == 'NFe' ? "55" : "65"),
      nNF,
      natOp: this.operation.operationFunction === "Outras" ? this.operation.descriptionFunction : this.operation.operationFunction,
      serie: this.operation.docFiscal ? (this.company.serieInvoiceNFC || '000' as any) : this.company.serieGerencial,
      tpAmb: environment.migrate.tpAmb,
      tpEmis: 1,
      tpImp: this.nota.ModeloDocumento == 'NFe' ? 1 : 4,
      tpNf: 1,
      EmailArquivos: this.client ? this.client.email : ""
    } as any;
  }

  private getDate() {
    let now = new Date()
    // now.setHours(now.getHours() - 1) // MANAUS (GARAGE CAR)
    const [year, month, day, hour, minutes, seconds] = [
      now.getFullYear(),
      now.getMonth() + 1,
      now.getDate(),
      now.getHours(),
      now.getMinutes(),
      now.getSeconds()
    ].map(String).map(s => s.padStart(2, '0'));
    return `${year}-${month}-${day}T${hour}:${minutes}:${seconds}`
  }

  private getFuso() {
    let resp = "";
    const hours = new Date().getTimezoneOffset() / 60 * -1;
    if (hours < 0) {
      resp += '-'
    }
    resp += Math.abs(hours).toString().padStart(2, '0');
    resp += ':00';
    return resp;
  }

}
