export const ANP_CODES = [
    {
        "code": "110203073",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "ABO 3"
    },
    {
        "code": "110204001",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "ABOOZAR"
    },
    {
        "code": "110204002",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "ABU ASAFAH"
    },
    {
        "code": "140101027",
        "description": "MATERIAIS GRAXOS",
        "product": "ÁCIDO GRAXO DE ÓLEO DE PALMA / DENDÊ"
    },
    {
        "code": "140101026",
        "description": "MATERIAIS GRAXOS",
        "product": "ÁCIDO GRAXO DE ÓLEO DE SOJA"
    },
    {
        "code": "850101002",
        "description": "QUEROSENE DE AVIAÇÃO ALTERNATIVO",
        "product": "Ácidos graxos e ésteres hidroprocessados (SPK-HEFA)"
    },
    {
        "code": "740101005",
        "description": "ADITIVOS",
        "product": "ADITIVOS PARA BIODIESEL"
    },
    {
        "code": "740101004",
        "description": "ADITIVOS",
        "product": "ADITIVOS PARA ETANOL HIDRATADO"
    },
    {
        "code": "740101001",
        "description": "ADITIVOS",
        "product": "ADITIVOS PARA GASOLINA"
    },
    {
        "code": "740101006",
        "description": "ADITIVOS",
        "product": "ADITIVOS PARA LUBRIFICANTES"
    },
    {
        "code": "740101002",
        "description": "ADITIVOS",
        "product": "ADITIVOS PARA ÓLEO DIESEL"
    },
    {
        "code": "110203083",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "AGBAMI"
    },
    {
        "code": "910101001",
        "description": "ÁGUA",
        "product": "ÁGUA"
    },
    {
        "code": "110103001",
        "description": "BACIA DO RECÔNCAVO",
        "product": "ÁGUA GRANDE"
    },
    {
        "code": "330101001",
        "description": "SOLVENTES ALIFÁTICOS",
        "product": "AGUARRÁS MINERAL"
    },
    {
        "code": "110203091",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "AKPO"
    },
    {
        "code": "120204001",
        "description": "CONDENSADOS DO ORIENTE MÉDIO",
        "product": "AL KHAYMAH"
    },
    {
        "code": "110106001",
        "description": "BACIA DE ALAGOAS/SERGIPE",
        "product": "ALAGOANO"
    },
    {
        "code": "120206001",
        "description": "CONDENSADOS DA EUROPA E EX-URSS",
        "product": "ALBA FIELD"
    },
    {
        "code": "110101001",
        "description": "BACIA DE CAMPOS",
        "product": "ALBACORA"
    },
    {
        "code": "110101042",
        "description": "BACIA DE CAMPOS",
        "product": "ALBACORA LESTE"
    },
    {
        "code": "810201001",
        "description": "OUTROS ALCOÓIS",
        "product": "ÁLCOOL METÍLICO"
    },
    {
        "code": "110201067",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "ALEN CONDENSATE"
    },
    {
        "code": "110204003",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "ALIF"
    },
    {
        "code": "330201005",
        "description": "SOLVENTES AROMÁTICOS",
        "product": "ALQUILBENZENO AB10"
    },
    {
        "code": "330201006",
        "description": "SOLVENTES AROMÁTICOS",
        "product": "ALQUILBENZENO AB11"
    },
    {
        "code": "330201004",
        "description": "SOLVENTES AROMÁTICOS",
        "product": "ALQUILBENZENO AB9"
    },
    {
        "code": "110105001",
        "description": "BACIA POTIGUAR",
        "product": "ALTO DO RODRIGUES"
    },
    {
        "code": "110203072",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "AMENAN BLEND"
    },
    {
        "code": "110203001",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "AMNA"
    },
    {
        "code": "110201001",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "ANACO WAX"
    },
    {
        "code": "110101002",
        "description": "BACIA DE CAMPOS",
        "product": "ANEQUIM"
    },
    {
        "code": "110203002",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "ANGOLANO"
    },
    {
        "code": "120205010",
        "description": "CONDENSADOS DA ÁSIA",
        "product": "ANOA"
    },
    {
        "code": "110203003",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "ANTAN"
    },
    {
        "code": "530206002",
        "description": "CAP MODIFICADO POR BORRACHA",
        "product": "AP MODIFICADO POR BORRACHA DE PNEU AB22"
    },
    {
        "code": "110204004",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "ARABE EXTRA LEVE"
    },
    {
        "code": "110204005",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "ARABE LEVE"
    },
    {
        "code": "110204006",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "ARABE MEDIO"
    },
    {
        "code": "110204007",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "ARABE MEDIO BANOCO"
    },
    {
        "code": "110204008",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "ARABE MEDIO ZULUF"
    },
    {
        "code": "110204009",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "ARABE PESADO"
    },
    {
        "code": "110204010",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "ARABE RECON"
    },
    {
        "code": "110204011",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "ARABE SUPER LEVE"
    },
    {
        "code": "110105027",
        "description": "BACIA POTIGUAR",
        "product": "ARACARI"
    },
    {
        "code": "110103003",
        "description": "BACIA DO RECÔNCAVO",
        "product": "ARAÇÁS"
    },
    {
        "code": "110103002",
        "description": "BACIA DO RECÔNCAVO",
        "product": "ARATU"
    },
    {
        "code": "110105002",
        "description": "BACIA POTIGUAR",
        "product": "ARATUM"
    },
    {
        "code": "110205001",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "ARDJUNA"
    },
    {
        "code": "110107009",
        "description": "BACIA DE SANTOS",
        "product": "AREA DE FLORIM"
    },
    {
        "code": "110107013",
        "description": "BACIA DE SANTOS",
        "product": "AREA NORDESTE DE TUPI"
    },
    {
        "code": "110107014",
        "description": "BACIA DE SANTOS",
        "product": "AREA SUL DE GUARA"
    },
    {
        "code": "110107012",
        "description": "BACIA DE SANTOS",
        "product": "AREA SUL DE TUPI"
    },
    {
        "code": "120203002",
        "description": "CONDENSADOS DA ÁFRICA",
        "product": "ARGELINO"
    },
    {
        "code": "120205001",
        "description": "CONDENSADOS DA ÁSIA",
        "product": "ARUM"
    },
    {
        "code": "110203004",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "ARZEW"
    },
    {
        "code": "120203001",
        "description": "CONDENSADOS DA ÁFRICA",
        "product": "AR-720"
    },
    {
        "code": "530102001",
        "description": "ASFALTOS NATURAIS",
        "product": "ASFALTO NATURAL"
    },
    {
        "code": "530101002",
        "description": "INDUSTRIALIZADOS",
        "product": "ASFALTOS DILUÍDOS"
    },
    {
        "code": "530202003",
        "description": "ASFALTOS DILUÍDOS",
        "product": "ASFALTOS DILUÍDOS CM-30"
    },
    {
        "code": "530202004",
        "description": "ASFALTOS DILUÍDOS",
        "product": "ASFALTOS DILUÍDOS CM-70"
    },
    {
        "code": "530202002",
        "description": "ASFALTOS DILUÍDOS",
        "product": "ASFALTOS DILUÍDOS CR-250"
    },
    {
        "code": "530202001",
        "description": "ASFALTOS DILUÍDOS",
        "product": "ASFALTOS DILUÍDOS CR-70"
    },
    {
        "code": "110206023",
        "description": "PETRÓLEOS DA EUROPA E EX-URSS",
        "product": "ASGARD BLEND"
    },
    {
        "code": "110108001",
        "description": "BACIA DO CEARÁ",
        "product": "ATUM"
    },
    {
        "code": "110105017",
        "description": "BACIA POTIGUAR",
        "product": "AURI"
    },
    {
        "code": "110206019",
        "description": "PETRÓLEOS DA EUROPA E EX-URSS",
        "product": "AZERJ LIGHT"
    },
    {
        "code": "110205023",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "BACH HO"
    },
    {
        "code": "110203092",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "BACH HO"
    },
    {
        "code": "110201002",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "BACHAQUERO"
    },
    {
        "code": "120202001",
        "description": "CONDENSADOS DA AMÉRICA DO NORTE & CARIBE",
        "product": "BADAK"
    },
    {
        "code": "110101003",
        "description": "BACIA DE CAMPOS",
        "product": "BADEJO"
    },
    {
        "code": "140202001",
        "description": "MATÉRIA - PRIMA DE 2ª GERAÇÃO",
        "product": "BAGAÇO OU PALHA DE CANA"
    },
    {
        "code": "110101004",
        "description": "BACIA DE CAMPOS",
        "product": "BAGRE"
    },
    {
        "code": "110103004",
        "description": "BACIA DO RECÔNCAVO",
        "product": "BAIANO BORDA NORDESTE"
    },
    {
        "code": "110103005",
        "description": "BACIA DO RECÔNCAVO",
        "product": "BAIANO MISTURA"
    },
    {
        "code": "110101051",
        "description": "BACIA DE CAMPOS",
        "product": "BALEIA AZUL"
    },
    {
        "code": "110207010",
        "description": "PETRÓLEOS DA OCEANIA",
        "product": "BALNAVES"
    },
    {
        "code": "110203097",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "BAOBAB"
    },
    {
        "code": "110205037",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "BARANTAI"
    },
    {
        "code": "110101005",
        "description": "BACIA DE CAMPOS",
        "product": "BARRACUDA"
    },
    {
        "code": "110203096",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "BARROW ISLAND"
    },
    {
        "code": "110204012",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "BASRAH LEVE"
    },
    {
        "code": "110204013",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "BASRAH MEDIO"
    },
    {
        "code": "110204014",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "BASRAH PESADO"
    },
    {
        "code": "110102001",
        "description": "BACIA DE MUCURI",
        "product": "BAS-60"
    },
    {
        "code": "110107007",
        "description": "BACIA DE SANTOS",
        "product": "BAUNA"
    },
    {
        "code": "120207003",
        "description": "CONDENSADOS DA OCEANIA",
        "product": "BAYU UNDAN"
    },
    {
        "code": "110201003",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "BCF"
    },
    {
        "code": "110201004",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "BCF 22"
    },
    {
        "code": "110201005",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "BCF 23"
    },
    {
        "code": "110201006",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "BCF 24"
    },
    {
        "code": "110206001",
        "description": "PETRÓLEOS DA EUROPA E EX-URSS",
        "product": "BEATRICE"
    },
    {
        "code": "110205002",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "BEKOK"
    },
    {
        "code": "110203005",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "BELAYM"
    },
    {
        "code": "110205003",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "BELIDA"
    },
    {
        "code": "330201001",
        "description": "SOLVENTES AROMÁTICOS",
        "product": "BENZENO"
    },
    {
        "code": "110206002",
        "description": "PETRÓLEOS DA EUROPA E EX-URSS",
        "product": "BERYL"
    },
    {
        "code": "110101006",
        "description": "BACIA DE CAMPOS",
        "product": "BICUDO"
    },
    {
        "code": "110101007",
        "description": "BACIA DE CAMPOS",
        "product": "BIJUPIRÁ"
    },
    {
        "code": "110101038",
        "description": "BACIA DE CAMPOS",
        "product": "BIJUPIRÁ/SALEMA"
    },
    {
        "code": "120205002",
        "description": "CONDENSADOS DA ÁSIA",
        "product": "BINTULU"
    },
    {
        "code": "820101001",
        "description": "BIODIESEL",
        "product": "BIODIESEL B100"
    },
    {
        "code": "820101999",
        "description": "BIODIESEL",
        "product": "BIODIESEL FORA DE ESPECIFICAÇÃO"
    },
    {
        "code": "820101010",
        "description": "BIODIESEL",
        "product": "BIODIESEL FORA DE ESPECIFICAÇÃO"
    },
    {
        "code": "140301001",
        "description": "BIOGÁS",
        "product": "BIOGÁS"
    },
    {
        "code": "840101001",
        "description": "BIOMETANO",
        "product": "BIOMETANO"
    },
    {
        "code": "840101002",
        "description": "BIOMETANO",
        "product": "BIOMETANO FORA DE ESPECIFICAÇÃO"
    },
    {
        "code": "110206003",
        "description": "PETRÓLEOS DA EUROPA E EX-URSS",
        "product": "BLACK GASOIL CRUDE OIL"
    },
    {
        "code": "110201007",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "BOLIVIAN BLEND"
    },
    {
        "code": "110201008",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "BOLIVIANO"
    },
    {
        "code": "120201001",
        "description": "CONDENSADOS DA AMÉRICA DO SUL",
        "product": "BOLIVIANO"
    },
    {
        "code": "110103017",
        "description": "BACIA DO RECÔNCAVO",
        "product": "BOM LUGAR"
    },
    {
        "code": "110205004",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "BOMBAY HIGH"
    },
    {
        "code": "110203077",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "BONGA"
    },
    {
        "code": "110101008",
        "description": "BACIA DE CAMPOS",
        "product": "BONITO"
    },
    {
        "code": "110203006",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "BONNY LEVE"
    },
    {
        "code": "110203007",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "BONNY MEDIO"
    },
    {
        "code": "110201009",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "BOSCAN"
    },
    {
        "code": "110203008",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "BOURI"
    },
    {
        "code": "110203009",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "BRASS BLEND"
    },
    {
        "code": "110203010",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "BRASS RIVER"
    },
    {
        "code": "120203004",
        "description": "CONDENSADOS DA ÁFRICA",
        "product": "BREGA"
    },
    {
        "code": "110206004",
        "description": "PETRÓLEOS DA EUROPA E EX-URSS",
        "product": "BRENT"
    },
    {
        "code": "610101009",
        "description": "PARAFÍNICOS - GRUPO I",
        "product": "BRIGHT STOCK"
    },
    {
        "code": "610801001",
        "description": "YPF/ARGENTINA",
        "product": "BRIGHT STOCK"
    },
    {
        "code": "120205003",
        "description": "CONDENSADOS DA ÁSIA",
        "product": "BRUNEI"
    },
    {
        "code": "110205005",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "BRUNEI LIGHT"
    },
    {
        "code": "610811001",
        "description": "REPSOL YPF/ESPANHA",
        "product": "BS"
    },
    {
        "code": "610803003",
        "description": "CEPSA LUBRIFICANTES/ESPANHA",
        "product": "BS"
    },
    {
        "code": "610812001",
        "description": "TOTAL LUBRICANTS/FRANÇA",
        "product": "BS"
    },
    {
        "code": "610805001",
        "description": "ENI SPA./ITÁLIA",
        "product": "BS 150"
    },
    {
        "code": "610806003",
        "description": "EXXON MOBIL/EUA",
        "product": "BS 2500"
    },
    {
        "code": "110203092",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "BU ATTIFEL"
    },
    {
        "code": "110204015",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "BURGAN"
    },
    {
        "code": "210202003",
        "description": "C4",
        "product": "BUTADIENO"
    },
    {
        "code": "210202001",
        "description": "C4",
        "product": "BUTANO"
    },
    {
        "code": "210203005",
        "description": "GASES LIQUEFEITO DE PETRÓLEO - GLP",
        "product": "BUTANO COMERCIAL"
    },
    {
        "code": "210202002",
        "description": "C4",
        "product": "BUTANO ESPECIAL"
    },
    {
        "code": "110107010",
        "description": "BACIA DE SANTOS",
        "product": "BUZIOS"
    },
    {
        "code": "110105018",
        "description": "BACIA POTIGUAR",
        "product": "BV"
    },
    {
        "code": "110203011",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "CABINA/TAKULA"
    },
    {
        "code": "110203012",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "CABINDA"
    },
    {
        "code": "110101009",
        "description": "BACIA DE CAMPOS",
        "product": "CABIÚNAS MISTURA"
    },
    {
        "code": "110104001",
        "description": "BACIA DO ESPÍRITO SANTO",
        "product": "CAÇÃO"
    },
    {
        "code": "110104006",
        "description": "BACIA DO ESPÍRITO SANTO",
        "product": "CACHALOTE"
    },
    {
        "code": "110101054",
        "description": "BACIA DE CAMPOS",
        "product": "CACHALOTE"
    },
    {
        "code": "110106010",
        "description": "BACIA DE ALAGOAS/SERGIPE",
        "product": "CAJUEIRO"
    },
    {
        "code": "610802001",
        "description": "CALUMET/EUA",
        "product": "CALPAR 150"
    },
    {
        "code": "610802002",
        "description": "CALUMET/EUA",
        "product": "CALPAR 500"
    },
    {
        "code": "110202007",
        "description": "PETRÓLEOS DA AMÉRICA DO NORTE & CARIBE",
        "product": "CALYPSO"
    },
    {
        "code": "110104011",
        "description": "BACIA DO ESPÍRITO SANTO",
        "product": "CAMARUPIM"
    },
    {
        "code": "110106002",
        "description": "BACIA DE ALAGOAS/SERGIPE",
        "product": "CAMORIM"
    },
    {
        "code": "140201001",
        "description": "MATÉRIA - PRIMA DE 1ª GERAÇÃO",
        "product": "CANA DE AÇÚCAR"
    },
    {
        "code": "110111002",
        "description": "TERRA BAHIA CATU",
        "product": "CANÁRIO"
    },
    {
        "code": "110103022",
        "description": "BACIA DO RECÔNCAVO",
        "product": "CANÁRIO"
    },
    {
        "code": "110103006",
        "description": "BACIA DO RECÔNCAVO",
        "product": "CANDEIAS"
    },
    {
        "code": "110105003",
        "description": "BACIA POTIGUAR",
        "product": "CANTO DO AMARO"
    },
    {
        "code": "110201010",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "CAÑADON SECO"
    },
    {
        "code": "110201011",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "CAÑO LIMÓN"
    },
    {
        "code": "530206001",
        "description": "CAP MODIFICADO POR BORRACHA",
        "product": "CAP MODIFICADO POR BORRACHA DE PNEU AB8"
    },
    {
        "code": "530204001",
        "description": "CAP MODIFICADO POR POLÍMEROS",
        "product": "CAP MODIFICADO POR POLÍMERO 55/75-E"
    },
    {
        "code": "530204002",
        "description": "CAP MODIFICADO POR POLÍMEROS",
        "product": "CAP MODIFICADO POR POLÍMERO 60/85-E"
    },
    {
        "code": "530204003",
        "description": "CAP MODIFICADO POR POLÍMEROS",
        "product": "CAP MODIFICADO POR POLÍMERO 65/90-E"
    },
    {
        "code": "110201064",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "CARABOBO"
    },
    {
        "code": "110201012",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "CARANDA"
    },
    {
        "code": "110101010",
        "description": "BACIA DE CAMPOS",
        "product": "CARAPEBA"
    },
    {
        "code": "110101011",
        "description": "BACIA DE CAMPOS",
        "product": "CARATINGA"
    },
    {
        "code": "110108002",
        "description": "BACIA DO CEARÁ",
        "product": "CARAUNAS"
    },
    {
        "code": "110107001",
        "description": "BACIA DE SANTOS",
        "product": "CARAVELA"
    },
    {
        "code": "110105030",
        "description": "BACIA POTIGUAR",
        "product": "CARDEAL"
    },
    {
        "code": "120202002",
        "description": "CONDENSADOS DA AMÉRICA DO NORTE & CARIBE",
        "product": "CARLINE"
    },
    {
        "code": "110202011",
        "description": "PETRÓLEOS DA AMÉRICA DO NORTE & CARIBE",
        "product": "CASCADE CHINOOK"
    },
    {
        "code": "110106003",
        "description": "BACIA DE ALAGOAS/SERGIPE",
        "product": "CASTANHAL"
    },
    {
        "code": "110201066",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "CASTILLA BLEND"
    },
    {
        "code": "110108003",
        "description": "BACIA DO CEARÁ",
        "product": "CEARÁ MAR"
    },
    {
        "code": "110203085",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "CEIBA"
    },
    {
        "code": "110201013",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "CEUTA"
    },
    {
        "code": "110207001",
        "description": "PETRÓLEOS DA OCEANIA",
        "product": "CHALLIS"
    },
    {
        "code": "110205034",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "CHAMPION"
    },
    {
        "code": "110105023",
        "description": "BACIA POTIGUAR",
        "product": "CHAUÁ"
    },
    {
        "code": "110101012",
        "description": "BACIA DE CAMPOS",
        "product": "CHERNE"
    },
    {
        "code": "110205031",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "CHIM SÃO"
    },
    {
        "code": "110201014",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "CHUBUT"
    },
    {
        "code": "620501002",
        "description": "MOTORES 4 TEMPOS",
        "product": "CICLO DIESEL"
    },
    {
        "code": "620501001",
        "description": "MOTORES 4 TEMPOS",
        "product": "CICLO OTTO"
    },
    {
        "code": "610101005",
        "description": "PARAFÍNICOS - GRUPO I",
        "product": "CILINDRO I"
    },
    {
        "code": "610101006",
        "description": "PARAFÍNICOS - GRUPO I",
        "product": "CILINDRO II"
    },
    {
        "code": "530101001",
        "description": "INDUSTRIALIZADOS",
        "product": "CIMENTOS ASFÁLTICOS"
    },
    {
        "code": "530201004",
        "description": "CIMENTOS ASFÁLTICOS",
        "product": "CIMENTOS ASFÁLTICOS CAP-150-200"
    },
    {
        "code": "530201001",
        "description": "CIMENTOS ASFÁLTICOS",
        "product": "CIMENTOS ASFÁLTICOS CAP-30-45"
    },
    {
        "code": "530201002",
        "description": "CIMENTOS ASFÁLTICOS",
        "product": "CIMENTOS ASFÁLTICOS CAP-50-70"
    },
    {
        "code": "530201003",
        "description": "CIMENTOS ASFÁLTICOS",
        "product": "CIMENTOS ASFÁLTICOS CAP-85-100"
    },
    {
        "code": "530101020",
        "description": "INDUSTRIALIZADOS",
        "product": "CIMENTOS ASFÁLTICOS DE PETRÓLEO MODIFICADOS POR BORRACHA MOÍDA DE PNEUS (ASFALTOS BORRACHA)"
    },
    {
        "code": "530101018",
        "description": "INDUSTRIALIZADOS",
        "product": "CIMENTOS ASFÁLTICOS DE PETRÓLEO MODIFICADOS POR POLÍMEROS"
    },
    {
        "code": "110205006",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "CINTA"
    },
    {
        "code": "110201015",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "COBLAN BLEND"
    },
    {
        "code": "110203013",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "COCO"
    },
    {
        "code": "110202001",
        "description": "PETRÓLEOS DA AMÉRICA DO NORTE & CARIBE",
        "product": "COLD LAKE BLEND"
    },
    {
        "code": "110105033",
        "description": "BACIA POTIGUAR",
        "product": "COLIBRI"
    },
    {
        "code": "120104001",
        "description": "BACIA DO ESPIRITO SANTO",
        "product": "CONDENSADO CAMARUPIM"
    },
    {
        "code": "110107017",
        "description": "BACIA DE SANTOS",
        "product": "CONDENSADO DE MERLUZA"
    },
    {
        "code": "110107018",
        "description": "BACIA DE SANTOS",
        "product": "CONDENSADO DE MEXILHAO"
    },
    {
        "code": "120102001",
        "description": "OUTROS CONDENSADOS",
        "product": "CONDENSADO PARA PETROQUÍMICA"
    },
    {
        "code": "120104002",
        "description": "BACIA DO ESPIRITO SANTO",
        "product": "CONDENSADO PEROA"
    },
    {
        "code": "110205024",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "CONDENSADO SENIPAH"
    },
    {
        "code": "120205009",
        "description": "CONDENSADOS DA ÁSIA",
        "product": "CONDENSADO SENIPAH"
    },
    {
        "code": "610804001",
        "description": "PENRECO/USA",
        "product": "CONOSOL 260"
    },
    {
        "code": "540101002",
        "description": "COQUE",
        "product": "COQUE CALCINADO"
    },
    {
        "code": "540101001",
        "description": "COQUE",
        "product": "COQUE VERDE"
    },
    {
        "code": "110107002",
        "description": "BACIA DE SANTOS",
        "product": "CORAL"
    },
    {
        "code": "610806006",
        "description": "EXXON MOBIL/EUA",
        "product": "CORE 100"
    },
    {
        "code": "610806007",
        "description": "EXXON MOBIL/EUA",
        "product": "CORE 150"
    },
    {
        "code": "610806008",
        "description": "EXXON MOBIL/EUA",
        "product": "CORE 2500"
    },
    {
        "code": "610806009",
        "description": "EXXON MOBIL/EUA",
        "product": "CORE 600"
    },
    {
        "code": "620601003",
        "description": "ÓLEOS LUBRIFICANTES ACABADOS",
        "product": "CORRENTE DE MOTOSSERRA"
    },
    {
        "code": "110201016",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "CORRIENTES"
    },
    {
        "code": "110101013",
        "description": "BACIA DE CAMPOS",
        "product": "CORVINA"
    },
    {
        "code": "120207001",
        "description": "CONDENSADOS DA OCEANIA",
        "product": "COSSACK"
    },
    {
        "code": "110206020",
        "description": "PETRÓLEOS DA EUROPA E EX-URSS",
        "product": "CPC BLEND"
    },
    {
        "code": "110104008",
        "description": "BACIA DO ESPÍRITO SANTO",
        "product": "CREJOA"
    },
    {
        "code": "110201017",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "CUPIAGUA"
    },
    {
        "code": "110108004",
        "description": "BACIA DO CEARÁ",
        "product": "CURIMÃ/ESPADA"
    },
    {
        "code": "110201018",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "CUSIANA"
    },
    {
        "code": "330201007",
        "description": "SOLVENTES AROMÁTICOS",
        "product": "C9 DIHIDROGENADO (OU C9 DE PIRÓLISE)"
    },
    {
        "code": "110205007",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "DAÍ HUNG"
    },
    {
        "code": "110203086",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "DALIA"
    },
    {
        "code": "110205008",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "DAQUING"
    },
    {
        "code": "340101002",
        "description": "OUTROS DERIVADOS LEVES",
        "product": "DERIVADOS LEVES INTERMEDIÁRIOS"
    },
    {
        "code": "130202002",
        "description": "PRODUTOS PARA REPROCESSAMENTO",
        "product": "DERIVADOS LEVES PARA REPROCESSAMENTO"
    },
    {
        "code": "430101002",
        "description": "OUTROS DERIVADOS MÉDIOS",
        "product": "DERIVADOS MÉDIOS INTERMEDIÁRIOS"
    },
    {
        "code": "130202003",
        "description": "PRODUTOS PARA REPROCESSAMENTO",
        "product": "DERIVADOS MÉDIOS PARA REPROCESSAMENTO"
    },
    {
        "code": "560101002",
        "description": "OUTROS DERIVADOS PESADOS",
        "product": "DERIVADOS PESADOS INTERMEDIÁRIOS"
    },
    {
        "code": "130202004",
        "description": "PRODUTOS PARA REPROCESSAMENTO",
        "product": "DERIVADOS PESADOS PARA REPROCESSAMENTO"
    },
    {
        "code": "820101032",
        "description": "BIODIESEL",
        "product": "DIESEL B S10 PARA GERAÇÃO DE ENERGIA ELÉTRICA"
    },
    {
        "code": "820101026",
        "description": "BIODIESEL",
        "product": "DIESEL B S1800 NÃO RODOVIÁRIO PARA GERAÇÃO DE ENERGIA ELÉTRICA"
    },
    {
        "code": "820101026",
        "description": "BIODIESEL",
        "product": "DIESEL B S1800 PARA GERAÇÃO DE ENERGIA ELÉTRICA"
    },
    {
        "code": "820101032",
        "description": "BIODIESEL",
        "product": "DIESEL B S50 PARA GERAÇÃO DE ENERGIA ELÉTRICA"
    },
    {
        "code": "820101027",
        "description": "BIODIESEL",
        "product": "DIESEL B S500 PARA GERAÇÃO DE ENERGIA ELÉTRICA"
    },
    {
        "code": "820101004",
        "description": "BIODIESEL",
        "product": "DIESEL B10"
    },
    {
        "code": "820101005",
        "description": "BIODIESEL",
        "product": "DIESEL B15"
    },
    {
        "code": "820101022",
        "description": "BIODIESEL",
        "product": "DIESEL B2 ESPECIAL - 200 PPM ENXOFRE"
    },
    {
        "code": "820101007",
        "description": "BIODIESEL",
        "product": "DIESEL B2 INTERIOR ADITIVADO"
    },
    {
        "code": "820101002",
        "description": "BIODIESEL",
        "product": "DIESEL B2 INTERIOR COMUM"
    },
    {
        "code": "820101009",
        "description": "BIODIESEL",
        "product": "DIESEL B2 METROPOLITANO ADITIVADO"
    },
    {
        "code": "820101008",
        "description": "BIODIESEL",
        "product": "DIESEL B2 METROPOLITANO COMUM"
    },
    {
        "code": "820101014",
        "description": "BIODIESEL",
        "product": "DIESEL B20 INTERIOR ADITIVADO"
    },
    {
        "code": "820101006",
        "description": "BIODIESEL",
        "product": "DIESEL B20 INTERIOR COMUM"
    },
    {
        "code": "820101016",
        "description": "BIODIESEL",
        "product": "DIESEL B20 METROPOLITANDO ADITIVADO"
    },
    {
        "code": "820101015",
        "description": "BIODIESEL",
        "product": "DIESEL B20 METROPOLITANO COMUM"
    },
    {
        "code": "820101031",
        "description": "BIODIESEL",
        "product": "DIESEL B20 S10 ADITIVADO"
    },
    {
        "code": "820101030",
        "description": "BIODIESEL",
        "product": "DIESEL B20 S10 COMUM"
    },
    {
        "code": "820101014",
        "description": "BIODIESEL",
        "product": "DIESEL B20 S1800 - ADITIVADO"
    },
    {
        "code": "820101006",
        "description": "BIODIESEL",
        "product": "DIESEL B20 S1800 - COMUM"
    },
    {
        "code": "820101014",
        "description": "BIODIESEL",
        "product": "DIESEL B20 S1800 NÃO RODOVIÁRIO - ADITIVADO"
    },
    {
        "code": "820101006",
        "description": "BIODIESEL",
        "product": "DIESEL B20 S1800 NÃO RODOVIÁRIO - COMUM"
    },
    {
        "code": "820101031",
        "description": "BIODIESEL",
        "product": "DIESEL B20 S50 ADITIVADO"
    },
    {
        "code": "820101030",
        "description": "BIODIESEL",
        "product": "DIESEL B20 S50 COMUM"
    },
    {
        "code": "820101016",
        "description": "BIODIESEL",
        "product": "DIESEL B20 S500 - ADITIVADO"
    },
    {
        "code": "820101015",
        "description": "BIODIESEL",
        "product": "DIESEL B20 S500 - COMUM"
    },
    {
        "code": "820101025",
        "description": "BIODIESEL",
        "product": "DIESEL B30"
    },
    {
        "code": "820101007",
        "description": "BIODIESEL",
        "product": "DIESEL B4 INTERIOR ADITIVADO"
    },
    {
        "code": "820101002",
        "description": "BIODIESEL",
        "product": "DIESEL B4 INTERIOR COMUM"
    },
    {
        "code": "820101026",
        "description": "BIODIESEL",
        "product": "DIESEL B4 INTERIOR PARA GERAÇÃO DE ENERGIA ELÉTRICA"
    },
    {
        "code": "820101009",
        "description": "BIODIESEL",
        "product": "DIESEL B4 METROPOLITANO ADITIVADO"
    },
    {
        "code": "820101008",
        "description": "BIODIESEL",
        "product": "DIESEL B4 METROPOLITANO COMUM"
    },
    {
        "code": "820101027",
        "description": "BIODIESEL",
        "product": "DIESEL B4 METROPOLITANO PARA GERAÇÃO DE ENERGIA ELÉTRICA"
    },
    {
        "code": "820101007",
        "description": "BIODIESEL",
        "product": "DIESEL B4 S1800 - ADITIVADO"
    },
    {
        "code": "820101002",
        "description": "BIODIESEL",
        "product": "DIESEL B4 S1800 - COMUM"
    },
    {
        "code": "820101028",
        "description": "BIODIESEL",
        "product": "DIESEL B4 S50 ADITIVADO"
    },
    {
        "code": "820101029",
        "description": "BIODIESEL",
        "product": "DIESEL B4 S50 COMUM"
    },
    {
        "code": "820101009",
        "description": "BIODIESEL",
        "product": "DIESEL B4 S500 - ADITIVADO"
    },
    {
        "code": "820101008",
        "description": "BIODIESEL",
        "product": "DIESEL B4 S500 - COMUM"
    },
    {
        "code": "820101011",
        "description": "BIODIESEL",
        "product": "DIESEL B5 INTERIOR ADITIVADO"
    },
    {
        "code": "820101003",
        "description": "BIODIESEL",
        "product": "DIESEL B5 INTERIOR COMUM"
    },
    {
        "code": "820101013",
        "description": "BIODIESEL",
        "product": "DIESEL B5 METROPOLITANO ADITIVADO"
    },
    {
        "code": "820101012",
        "description": "BIODIESEL",
        "product": "DIESEL B5 METROPOLITANO COMUM"
    },
    {
        "code": "820101017",
        "description": "BIODIESEL",
        "product": "DIESEL MARÍTIMO - DMA B2"
    },
    {
        "code": "820101018",
        "description": "BIODIESEL",
        "product": "DIESEL MARÍTIMO - DMA B5"
    },
    {
        "code": "820101019",
        "description": "BIODIESEL",
        "product": "DIESEL MARÍTIMO - DMB B2"
    },
    {
        "code": "820101020",
        "description": "BIODIESEL",
        "product": "DIESEL MARÍTIMO - DMB B5"
    },
    {
        "code": "820101021",
        "description": "BIODIESEL",
        "product": "DIESEL NÁUTICO B2 ESPECIAL - 200 PPM ENXOFRE"
    },
    {
        "code": "330101003",
        "description": "SOLVENTES ALIFÁTICOS",
        "product": "DILUENTE DE TINTAS"
    },
    {
        "code": "130202006",
        "description": "PRODUTOS PARA REPROCESSAMENTO",
        "product": "DILUENTE PARA REPROCESSAMENTO"
    },
    {
        "code": "110203014",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "DJENO BLEND"
    },
    {
        "code": "420201001",
        "description": "ÓLEO DIESEL MARÍTIMO",
        "product": "DMA - MGO"
    },
    {
        "code": "420201003",
        "description": "ÓLEO DIESEL MARÍTIMO",
        "product": "DMB - MDO"
    },
    {
        "code": "120204010",
        "description": "CONDENSADOS DO ORIENTE MÉDIO",
        "product": "DOLPHIN"
    },
    {
        "code": "110103007",
        "description": "BACIA DO RECÔNCAVO",
        "product": "DOM JOÃO"
    },
    {
        "code": "110204017",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "DOROOD"
    },
    {
        "code": "110204051",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "DSL"
    },
    {
        "code": "110204018",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "DUBAI"
    },
    {
        "code": "110205035",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "DULANG"
    },
    {
        "code": "110205022",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "DURI CRUDE OIL"
    },
    {
        "code": "110203069",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "EA CRUDE"
    },
    {
        "code": "110203015",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "EAST ZEIT MIX"
    },
    {
        "code": "110203104",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "EBOME"
    },
    {
        "code": "610903001",
        "description": "EXXO MOBIL/EUA",
        "product": "EHC 45"
    },
    {
        "code": "610903002",
        "description": "EXXO MOBIL/EUA",
        "product": "EHC 60"
    },
    {
        "code": "110206005",
        "description": "PETRÓLEOS DA EUROPA E EX-URSS",
        "product": "EKOFISH"
    },
    {
        "code": "110203016",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "EL HUEMEL"
    },
    {
        "code": "110203017",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "EL MORGAN"
    },
    {
        "code": "110203018",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "EL ORIENTE"
    },
    {
        "code": "110203088",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "EL SHARARA"
    },
    {
        "code": "110203019",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "EMERAUDE"
    },
    {
        "code": "530203009",
        "description": "EMULSÕES ASFÁLTICAS",
        "product": "EMULSÃO ASFÁLTICA CATIÔNICA DE RUPTURA CONTROLADA PARA SERVIÇO DE LAMA ASFÁLTICA"
    },
    {
        "code": "530203006",
        "description": "EMULSÕES ASFÁLTICAS",
        "product": "EMULSÃO ASFÁLTICA DE RUPTURA LENTA CATIÔNICA PARA SERVIÇO DE LAMA ASFÁLTICA"
    },
    {
        "code": "530203007",
        "description": "EMULSÕES ASFÁLTICAS",
        "product": "EMULSÃO ASFÁLTICA DE RUPTURA LENTA DE CARGA NEUTRA PARA SERVIÇO DE LAMA ASFÁLTICA"
    },
    {
        "code": "530203008",
        "description": "EMULSÕES ASFÁLTICAS",
        "product": "EMULSÃO ASFÁLTICA PARA SERVIÇO DE IMPRIMAÇÃO"
    },
    {
        "code": "530205004",
        "description": "EMULSÕES ASFÁLTICAS CATIÔNICAS MODIFICADAS",
        "product": "EMULSÕES ASF. MOD. POR POLÍMEROS RC1C-E"
    },
    {
        "code": "530205005",
        "description": "EMULSÕES ASFÁLTICAS CATIÔNICAS MODIFICADAS",
        "product": "EMULSÕES ASF. MOD. POR POLÍMEROS RL1C-E"
    },
    {
        "code": "530205003",
        "description": "EMULSÕES ASFÁLTICAS CATIÔNICAS MODIFICADAS",
        "product": "EMULSÕES ASF. MOD. POR POLÍMEROS RM1C-E"
    },
    {
        "code": "530205001",
        "description": "EMULSÕES ASFÁLTICAS CATIÔNICAS MODIFICADAS",
        "product": "EMULSÕES ASF. MOD. POR POLÍMEROS RR1C-E"
    },
    {
        "code": "530205002",
        "description": "EMULSÕES ASFÁLTICAS CATIÔNICAS MODIFICADAS",
        "product": "EMULSÕES ASF. MOD. POR POLÍMEROS RR2C-E"
    },
    {
        "code": "530101003",
        "description": "INDUSTRIALIZADOS",
        "product": "EMULSÕES ASFÁLTICAS"
    },
    {
        "code": "530101019",
        "description": "INDUSTRIALIZADOS",
        "product": "EMULSÕES ASFÁLTICAS CATIÔNICAS MODIFICADAS POR POLÍMEROS ELASTOMÉRICOS"
    },
    {
        "code": "530203005",
        "description": "EMULSÕES ASFÁLTICAS",
        "product": "EMULSÕES ASFÁLTICAS RL-1C"
    },
    {
        "code": "530203003",
        "description": "EMULSÕES ASFÁLTICAS",
        "product": "EMULSÕES ASFÁLTICAS RM-1C"
    },
    {
        "code": "530203004",
        "description": "EMULSÕES ASFÁLTICAS",
        "product": "EMULSÕES ASFÁLTICAS RM-2C"
    },
    {
        "code": "530203001",
        "description": "EMULSÕES ASFÁLTICAS",
        "product": "EMULSÕES ASFÁLTICAS RR-1C"
    },
    {
        "code": "530203002",
        "description": "EMULSÕES ASFÁLTICAS",
        "product": "EMULSÕES ASFÁLTICAS RR-2C"
    },
    {
        "code": "110101014",
        "description": "BACIA DE CAMPOS",
        "product": "ENCHOVA"
    },
    {
        "code": "620101002",
        "description": "ÓLEOS LUBRIFICANTES INDUSTRIAIS",
        "product": "ENGRENAGENS E SISTEMAS CIRCULATÓRIOS"
    },
    {
        "code": "110107015",
        "description": "BACIA DE SANTOS",
        "product": "ENTORNO DE IARA"
    },
    {
        "code": "720101001",
        "description": "ENXOFRE",
        "product": "ENXOFRE LÍQUIDO"
    },
    {
        "code": "720101002",
        "description": "ENXOFRE",
        "product": "ENXOFRE SÓLIDO"
    },
    {
        "code": "120205004",
        "description": "CONDENSADOS DA ÁSIA",
        "product": "ERAWAN"
    },
    {
        "code": "110203079",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "ERHA"
    },
    {
        "code": "110203020",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "ES SIDER"
    },
    {
        "code": "110201019",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "ESCALANTE"
    },
    {
        "code": "110203021",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "ESCRAVOS"
    },
    {
        "code": "110108005",
        "description": "BACIA DO CEARÁ",
        "product": "ESPADA"
    },
    {
        "code": "110101015",
        "description": "BACIA DE CAMPOS",
        "product": "ESPADARTE"
    },
    {
        "code": "110104002",
        "description": "BACIA DO ESPÍRITO SANTO",
        "product": "ESPIRITO SANTO"
    },
    {
        "code": "110101016",
        "description": "BACIA DE CAMPOS",
        "product": "ESPÍRITO SANTO SUBMARINO"
    },
    {
        "code": "110206025",
        "description": "PETRÓLEOS DA EUROPA E EX-URSS",
        "product": "ESPO"
    },
    {
        "code": "120203007",
        "description": "CONDENSADOS DA ÁFRICA",
        "product": "ESPOIR"
    },
    {
        "code": "620101007",
        "description": "ÓLEOS LUBRIFICANTES INDUSTRIAIS",
        "product": "ESTAMPAGEM"
    },
    {
        "code": "140102001",
        "description": "ÉSTERES",
        "product": "ÉSTER METÍLICO"
    },
    {
        "code": "110105004",
        "description": "BACIA POTIGUAR",
        "product": "ESTREITO"
    },
    {
        "code": "110107003",
        "description": "BACIA DE SANTOS",
        "product": "ESTRELA DO MAR"
    },
    {
        "code": "110203095",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "ETAME"
    },
    {
        "code": "210301001",
        "description": "C2",
        "product": "ETANO"
    },
    {
        "code": "810102001",
        "description": "ETANOL ANIDRO",
        "product": "ETANOL ANIDRO"
    },
    {
        "code": "810102004",
        "description": "ETANOL ANIDRO",
        "product": "ETANOL ANIDRO COM CORANTE"
    },
    {
        "code": "810102003",
        "description": "ETANOL ANIDRO",
        "product": "ETANOL ANIDRO DE REFERÊNCIA - EAR"
    },
    {
        "code": "810102002",
        "description": "ETANOL ANIDRO",
        "product": "ETANOL ANIDRO FORA DE ESPECIFICAÇÃO"
    },
    {
        "code": "130201002",
        "description": "OUTROS INSUMOS BRUTOS",
        "product": "ETANOL ANIDRO INSUMO PARA BIODIESEL"
    },
    {
        "code": "810102003",
        "description": "ETANOL ANIDRO",
        "product": "ETANOL ANIDRO PADRÃO"
    },
    {
        "code": "810103001",
        "description": "ETANOL FORA DE ESPECIFICAÇÃO",
        "product": "ETANOL FORA DE ESPECIFICAÇÃO"
    },
    {
        "code": "810101002",
        "description": "ETANOL HIDRATADO",
        "product": "ETANOL HIDRATADO ADITIVADO"
    },
    {
        "code": "810101001",
        "description": "ETANOL HIDRATADO",
        "product": "ETANOL HIDRATADO COMUM"
    },
    {
        "code": "810101006",
        "description": "ETANOL HIDRATADO",
        "product": "ETANOL HIDRATADO DE REFERÊNCIA - EHR"
    },
    {
        "code": "810101003",
        "description": "ETANOL HIDRATADO",
        "product": "ETANOL HIDRATADO FORA DE ESPECIFICAÇÃO"
    },
    {
        "code": "810101004",
        "description": "ETANOL HIDRATADO",
        "product": "ETANOL HIDRATADO PREMIUM"
    },
    {
        "code": "810101005",
        "description": "ETANOL HIDRATADO",
        "product": "ETANOL HIDRATADO PREMIUM ADITIVADO"
    },
    {
        "code": "210301002",
        "description": "C2",
        "product": "ETENO"
    },
    {
        "code": "330201010",
        "description": "SOLVENTES AROMÁTICOS",
        "product": "ETILBENZENO"
    },
    {
        "code": "611003001",
        "description": "PETRONAS/MALÁSIA",
        "product": "ETRO 4"
    },
    {
        "code": "611003002",
        "description": "PETRONAS/MALÁSIA",
        "product": "ETRO 6"
    },
    {
        "code": "611003003",
        "description": "PETRONAS/MALÁSIA",
        "product": "ETRO 8"
    },
    {
        "code": "110204016",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "FAO BLEND"
    },
    {
        "code": "110105005",
        "description": "BACIA POTIGUAR",
        "product": "FAZENDA ALEGRE"
    },
    {
        "code": "110104012",
        "description": "BACIA DO ESPÍRITO SANTO",
        "product": "FAZENDA ALEGRE"
    },
    {
        "code": "110105006",
        "description": "BACIA POTIGUAR",
        "product": "FAZENDA BELÉM"
    },
    {
        "code": "110105007",
        "description": "BACIA POTIGUAR",
        "product": "FAZENDA POCINHO"
    },
    {
        "code": "110103019",
        "description": "BACIA DO RECÔNCAVO",
        "product": "FAZENDA SÃO ESTEVÃO"
    },
    {
        "code": "110104003",
        "description": "BACIA DO ESPÍRITO SANTO",
        "product": "FAZENDA SÃO RAFAEL"
    },
    {
        "code": "610904001",
        "description": "EXCEL PARALUBES/FILIPINAS",
        "product": "FLINT HILLS"
    },
    {
        "code": "110206006",
        "description": "PETRÓLEOS DA EUROPA E EX-URSS",
        "product": "FLOTTA"
    },
    {
        "code": "110206007",
        "description": "PETRÓLEOS DA EUROPA E EX-URSS",
        "product": "FLOTTA OCIDENTAL"
    },
    {
        "code": "110203022",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "FORCADOS"
    },
    {
        "code": "110204019",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "FOROOZAN"
    },
    {
        "code": "110206008",
        "description": "PETRÓLEOS DA EUROPA E EX-URSS",
        "product": "FORTIES"
    },
    {
        "code": "110206009",
        "description": "PETRÓLEOS DA EUROPA E EX-URSS",
        "product": "FORTIES BLEND"
    },
    {
        "code": "110101043",
        "description": "BACIA DE CAMPOS",
        "product": "FRADE"
    },
    {
        "code": "110201020",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "FURRIAL"
    },
    {
        "code": "110104009",
        "description": "BACIA DO ESPÍRITO SANTO",
        "product": "GAIVOTA"
    },
    {
        "code": "110105034",
        "description": "BACIA POTIGUAR",
        "product": "GALO DE CAMPINA"
    },
    {
        "code": "110203023",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "GAMBA"
    },
    {
        "code": "110101017",
        "description": "BACIA DE CAMPOS",
        "product": "GAROUPA"
    },
    {
        "code": "110101018",
        "description": "BACIA DE CAMPOS",
        "product": "GAROUPINHA"
    },
    {
        "code": "210302004",
        "description": "OUTROS GASES",
        "product": "GÁS ÁCIDO"
    },
    {
        "code": "210101001",
        "description": "GÁS COMBUSTÍVEL",
        "product": "GÁS COMBUSTÍVEL"
    },
    {
        "code": "210302003",
        "description": "OUTROS GASES",
        "product": "GÁS DE XISTO"
    },
    {
        "code": "210302002",
        "description": "OUTROS GASES",
        "product": "GÁS INTERMEDIÁRIO"
    },
    {
        "code": "210204001",
        "description": "OUTROS GASES LIQUEFEITOS",
        "product": "GÁS LIQUEFEITO INTERMEDIÁRIO"
    },
    {
        "code": "220101003",
        "description": "GÁS NATURAL",
        "product": "GÁS NATURAL COMPRIMIDO"
    },
    {
        "code": "220101004",
        "description": "GÁS NATURAL",
        "product": "GÁS NATURAL LIQUEFEITO"
    },
    {
        "code": "220101002",
        "description": "GÁS NATURAL",
        "product": "GÁS NATURAL SECO"
    },
    {
        "code": "220101001",
        "description": "GÁS NATURAL",
        "product": "GÁS NATURAL ÚMIDO"
    },
    {
        "code": "220101005",
        "description": "GÁS NATURAL",
        "product": "GÁS NATURAL VEICULAR"
    },
    {
        "code": "220101006",
        "description": "GÁS NATURAL",
        "product": "GÁS NATURAL VEICULAR PADRÃO"
    },
    {
        "code": "130202001",
        "description": "PRODUTOS PARA REPROCESSAMENTO",
        "product": "GASES LIQUEFEITOS PARA REPROCESSAMENTO"
    },
    {
        "code": "130202005",
        "description": "PRODUTOS PARA REPROCESSAMENTO",
        "product": "GASÓLEO PARA REPROCESSAMENTO"
    },
    {
        "code": "520101001",
        "description": "GASÓLEOS",
        "product": "GASÓLEOS"
    },
    {
        "code": "320101001",
        "description": "GASOLINA A",
        "product": "GASOLINA A COMUM"
    },
    {
        "code": "320101003",
        "description": "GASOLINA A",
        "product": "GASOLINA A FORA DE ESPECIFICAÇÃO"
    },
    {
        "code": "320101002",
        "description": "GASOLINA A",
        "product": "GASOLINA A PREMIUM"
    },
    {
        "code": "320103001",
        "description": "OUTRAS GASOLINAS AUTOMOTIVAS",
        "product": "GASOLINA AUTOMOTIVA PADRÃO"
    },
    {
        "code": "320102002",
        "description": "GASOLINA C",
        "product": "GASOLINA C ADITIVADA"
    },
    {
        "code": "320102001",
        "description": "GASOLINA C",
        "product": "GASOLINA C COMUM"
    },
    {
        "code": "320102002",
        "description": "GASOLINA C",
        "product": "GASOLINA C COMUM ADITIVADA"
    },
    {
        "code": "320102004",
        "description": "GASOLINA C",
        "product": "GASOLINA C FORA DE ESPECIFICAÇÃO"
    },
    {
        "code": "320102003",
        "description": "GASOLINA C",
        "product": "GASOLINA C PREMIUM"
    },
    {
        "code": "320102005",
        "description": "GASOLINA C",
        "product": "GASOLINA C PREMIUM ADITIVADA"
    },
    {
        "code": "320201001",
        "description": "GASOLINAS DE AVIAÇÃO",
        "product": "GASOLINA DE AVIAÇÃO"
    },
    {
        "code": "320201002",
        "description": "GASOLINAS DE AVIAÇÃO",
        "product": "GASOLINA DE AVIAÇÃO FORA DE ESPECIFICAÇÃO"
    },
    {
        "code": "320103001",
        "description": "OUTRAS GASOLINAS AUTOMOTIVAS",
        "product": "GASOLINA DE REFERÊNCIA"
    },
    {
        "code": "320103001",
        "description": "OUTRAS GASOLINAS AUTOMOTIVAS",
        "product": "GASOLINA DE REFERÊNCIA - NBR 16038"
    },
    {
        "code": "320103003",
        "description": "OUTRAS GASOLINAS AUTOMOTIVAS",
        "product": "GASOLINA DE REFERÊNCIA - PROCONVE L-6"
    },
    {
        "code": "220102001",
        "description": "LÍQUIDO DE GÁS NATURAL",
        "product": "GASOLINA NATURAL (C5+)"
    },
    {
        "code": "320301002",
        "description": "OUTRAS GASOLINAS",
        "product": "GASOLINA PARA EXPORTAÇÃO"
    },
    {
        "code": "110204020",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "GAVARZIN"
    },
    {
        "code": "110114003",
        "description": "BACIA DO PARNAÍBA",
        "product": "GAVIÃO BRANCO"
    },
    {
        "code": "110114001",
        "description": "BACIA DO PARNAÍBA",
        "product": "GAVIÃO REAL"
    },
    {
        "code": "110114002",
        "description": "BACIA DO PARNAÍBA",
        "product": "GAVIÃO VERMELHO"
    },
    {
        "code": "110203024",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "GEISUM"
    },
    {
        "code": "120205012",
        "description": "CONDENSADOS DA ÁSIA",
        "product": "GERAGAI"
    },
    {
        "code": "110207002",
        "description": "PETRÓLEOS DA OCEANIA",
        "product": "GIPPSLAND BLEND"
    },
    {
        "code": "110203087",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "GIRASSOL"
    },
    {
        "code": "730101002",
        "description": "OUTROS SUB-PRODUTOS",
        "product": "GLICERINA"
    },
    {
        "code": "210203001",
        "description": "GASES LIQUEFEITO DE PETRÓLEO - GLP",
        "product": "GLP"
    },
    {
        "code": "210203002",
        "description": "GASES LIQUEFEITO DE PETRÓLEO - GLP",
        "product": "GLP FORA DE ESPECIFICAÇÃO"
    },
    {
        "code": "110104005",
        "description": "BACIA DO ESPÍRITO SANTO",
        "product": "GOLFINHO"
    },
    {
        "code": "140101023",
        "description": "MATERIAIS GRAXOS",
        "product": "GORDURA BOVINA"
    },
    {
        "code": "140101024",
        "description": "MATERIAIS GRAXOS",
        "product": "GORDURA DE FRANGO"
    },
    {
        "code": "140101025",
        "description": "MATERIAIS GRAXOS",
        "product": "GORDURA DE PORCO"
    },
    {
        "code": "650101004",
        "description": "GRAXAS",
        "product": "GRAXAS DE CALCIO"
    },
    {
        "code": "650101003",
        "description": "GRAXAS",
        "product": "GRAXAS DE LITIO"
    },
    {
        "code": "650101001",
        "description": "GRAXAS",
        "product": "GRAXAS MINERAIS"
    },
    {
        "code": "110207003",
        "description": "PETRÓLEOS DA OCEANIA",
        "product": "GRIFFIN"
    },
    {
        "code": "110201021",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "GUAFITA BLEND"
    },
    {
        "code": "110103013",
        "description": "BACIA DO RECÔNCAVO",
        "product": "GUANAMBI"
    },
    {
        "code": "110201022",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "GUANIPA"
    },
    {
        "code": "110203025",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "GULF OF SUEZ"
    },
    {
        "code": "110203026",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "GULF OF SUEZ MIX"
    },
    {
        "code": "110206011",
        "description": "PETRÓLEOS DA EUROPA E EX-URSS",
        "product": "GULL FALKS"
    },
    {
        "code": "110206010",
        "description": "PETRÓLEOS DA EUROPA E EX-URSS",
        "product": "GULL FALKS C"
    },
    {
        "code": "110106013",
        "description": "BACIA DE ALAGOAS/SERGIPE",
        "product": "HARPIA"
    },
    {
        "code": "110203027",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "HASSI MESSAOUD"
    },
    {
        "code": "110203028",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "HASSI RMEL"
    },
    {
        "code": "110203028",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "HASSI RMEL"
    },
    {
        "code": "330101008",
        "description": "SOLVENTES ALIFÁTICOS",
        "product": "HEPTANO"
    },
    {
        "code": "330101002",
        "description": "SOLVENTES ALIFÁTICOS",
        "product": "HEXANO"
    },
    {
        "code": "330101009",
        "description": "SOLVENTES ALIFÁTICOS",
        "product": "HEXENO"
    },
    {
        "code": "110202009",
        "description": "PETRÓLEOS DA AMÉRICA DO NORTE & CARIBE",
        "product": "HIBERNIA"
    },
    {
        "code": "110201068",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "HIDES"
    },
    {
        "code": "620101001",
        "description": "ÓLEOS LUBRIFICANTES INDUSTRIAIS",
        "product": "HIDRÁULICO"
    },
    {
        "code": "610201001",
        "description": "NAFTÊNICOS - GRUPO V",
        "product": "HIDROGENADO LEVE"
    },
    {
        "code": "610201002",
        "description": "NAFTÊNICOS - GRUPO V",
        "product": "HIDROGENADO MÉDIO"
    },
    {
        "code": "610201003",
        "description": "NAFTÊNICOS - GRUPO V",
        "product": "HIDROGENADO PESADO"
    },
    {
        "code": "710101001",
        "description": "HIDROGÊNIO",
        "product": "HIDROGÊNIO"
    },
    {
        "code": "110203074",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "HUNGO"
    },
    {
        "code": "610808001",
        "description": "SHELL/ALEMANHA",
        "product": "HVI 120"
    },
    {
        "code": "610808002",
        "description": "SHELL/ALEMANHA",
        "product": "HVI 60"
    },
    {
        "code": "110201023",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "HYDRA"
    },
    {
        "code": "110107016",
        "description": "BACIA DE SANTOS",
        "product": "IARA"
    },
    {
        "code": "110103008",
        "description": "BACIA DO RECÔNCAVO",
        "product": "ILHÉUS"
    },
    {
        "code": "110203029",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "IMA LIGHT"
    },
    {
        "code": "120205005",
        "description": "CONDENSADOS DA ÁSIA",
        "product": "INDIANO"
    },
    {
        "code": "110204021",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "IRANIANO LEVE"
    },
    {
        "code": "110204022",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "IRANIANO MISTURA"
    },
    {
        "code": "110204023",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "IRANIANO PESADO"
    },
    {
        "code": "110105037",
        "description": "BACIA POTIGUAR",
        "product": "IRERE"
    },
    {
        "code": "620101004",
        "description": "ÓLEOS LUBRIFICANTES INDUSTRIAIS",
        "product": "ISOLANTE TIPO A"
    },
    {
        "code": "620101005",
        "description": "ÓLEOS LUBRIFICANTES INDUSTRIAIS",
        "product": "ISOLANTE TIPO B"
    },
    {
        "code": "850101003",
        "description": "QUEROSENE DE AVIAÇÃO ALTERNATIVO",
        "product": "Isoparafinas sintetizadas (SIP)"
    },
    {
        "code": "330101010",
        "description": "SOLVENTES ALIFÁTICOS",
        "product": "ISOPENTANO"
    },
    {
        "code": "110202002",
        "description": "PETRÓLEOS DA AMÉRICA DO NORTE & CARIBE",
        "product": "ISTHMUS"
    },
    {
        "code": "110202003",
        "description": "PETRÓLEOS DA AMÉRICA DO NORTE & CARIBE",
        "product": "ISTHMUS MAYA"
    },
    {
        "code": "110207004",
        "description": "PETRÓLEOS DA OCEANIA",
        "product": "JABIRU"
    },
    {
        "code": "110101046",
        "description": "BACIA DE CAMPOS",
        "product": "JABUTI"
    },
    {
        "code": "110204024",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "JAMBUR BAR HASSAN"
    },
    {
        "code": "110113001",
        "description": "BACIA DE CAMAMU",
        "product": "JIRIBATUBA"
    },
    {
        "code": "110105015",
        "description": "BACIA POTIGUAR",
        "product": "JOÃO DE BARRO"
    },
    {
        "code": "110101019",
        "description": "BACIA DE CAMPOS",
        "product": "JUBARTE"
    },
    {
        "code": "110203098",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "JUBILEE"
    },
    {
        "code": "110103015",
        "description": "BACIA DO RECÔNCAVO",
        "product": "JURITI"
    },
    {
        "code": "110205025",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "KAJI SEMOGA"
    },
    {
        "code": "110204025",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "KANGAN"
    },
    {
        "code": "110204026",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "KHAFJI"
    },
    {
        "code": "110204027",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "KHAFJI BLEND"
    },
    {
        "code": "120204009",
        "description": "CONDENSADOS DO ORIENTE MÉDIO",
        "product": "KHUFF"
    },
    {
        "code": "110205026",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "KIKEH"
    },
    {
        "code": "110204028",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "KIRKUK"
    },
    {
        "code": "110204029",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "KIRKUK BLEND"
    },
    {
        "code": "110203080",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "KISSANJE"
    },
    {
        "code": "120207004",
        "description": "CONDENSADOS DA OCEANIA",
        "product": "KITAN"
    },
    {
        "code": "110203030",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "KITINA"
    },
    {
        "code": "110105025",
        "description": "BACIA POTIGUAR",
        "product": "KOCH"
    },
    {
        "code": "110203031",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "KOLE"
    },
    {
        "code": "110203084",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "KUITO"
    },
    {
        "code": "110203032",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "KUTUBU"
    },
    {
        "code": "110204030",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "KUWAIT"
    },
    {
        "code": "110205009",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "LABUAN"
    },
    {
        "code": "110103021",
        "description": "BACIA DO RECÔNCAVO",
        "product": "LAGOA DO PAULO NORTE"
    },
    {
        "code": "110104004",
        "description": "BACIA DO ESPÍRITO SANTO",
        "product": "LAGOA PARDA"
    },
    {
        "code": "110201024",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "LAGOCINCO"
    },
    {
        "code": "110201025",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "LAGOCINCO LIVIANO"
    },
    {
        "code": "110201026",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "LAGOMEDIO"
    },
    {
        "code": "110201027",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "LAGOTRECO"
    },
    {
        "code": "110201028",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "LAGOTRECO HEAVY"
    },
    {
        "code": "110201029",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "LAGOTRECO MEDIO"
    },
    {
        "code": "110201030",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "LAGUNA"
    },
    {
        "code": "110205036",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "LALANG"
    },
    {
        "code": "110207005",
        "description": "PETRÓLEOS DA OCEANIA",
        "product": "LAMINARIA"
    },
    {
        "code": "110204031",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "LAVAN BLEND"
    },
    {
        "code": "110207006",
        "description": "PETRÓLEOS DA OCEANIA",
        "product": "LEGENDRE"
    },
    {
        "code": "110201031",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "LEONA"
    },
    {
        "code": "110201032",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "LEONA 21,9"
    },
    {
        "code": "110201033",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "LEONA 24"
    },
    {
        "code": "120204002",
        "description": "CONDENSADOS DO ORIENTE MÉDIO",
        "product": "LIBIA"
    },
    {
        "code": "110101020",
        "description": "BACIA DE CAMPOS",
        "product": "LINGUADO"
    },
    {
        "code": "120203006",
        "description": "CONDENSADOS DA ÁFRICA",
        "product": "LION"
    },
    {
        "code": "220102002",
        "description": "LÍQUIDO DE GÁS NATURAL",
        "product": "LÍQUIDO DE GÁS NATURAL"
    },
    {
        "code": "110105008",
        "description": "BACIA POTIGUAR",
        "product": "LIVRAMENTO"
    },
    {
        "code": "110203033",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "LOKELE"
    },
    {
        "code": "110105009",
        "description": "BACIA POTIGUAR",
        "product": "LORENA"
    },
    {
        "code": "110201034",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "LORETO"
    },
    {
        "code": "110204053",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "LOWER ZAKUM"
    },
    {
        "code": "110203034",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "LUCINA MARINE"
    },
    {
        "code": "110203035",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "LUCULA"
    },
    {
        "code": "110107008",
        "description": "BACIA DE SANTOS",
        "product": "LULA"
    },
    {
        "code": "640201001",
        "description": "MACROCRISTALINAS",
        "product": "MACROOLEOSAS"
    },
    {
        "code": "120205011",
        "description": "CONDENSADOS DA ÁSIA",
        "product": "MALAMPAYA"
    },
    {
        "code": "110101021",
        "description": "BACIA DE CAMPOS",
        "product": "MALHADO"
    },
    {
        "code": "120103001",
        "description": "CAMAMU",
        "product": "MANATI"
    },
    {
        "code": "110203036",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "MANDJI"
    },
    {
        "code": "120204003",
        "description": "CONDENSADOS DO ORIENTE MÉDIO",
        "product": "MARGHAM"
    },
    {
        "code": "110201035",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "MARIA IGNES"
    },
    {
        "code": "110204032",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "MARIB LIGHT"
    },
    {
        "code": "110101022",
        "description": "BACIA DE CAMPOS",
        "product": "MARIMBA"
    },
    {
        "code": "110201036",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "MARLAGO"
    },
    {
        "code": "110101023",
        "description": "BACIA DE CAMPOS",
        "product": "MARLIM"
    },
    {
        "code": "110101024",
        "description": "BACIA DE CAMPOS",
        "product": "MARLIM LESTE"
    },
    {
        "code": "110101025",
        "description": "BACIA DE CAMPOS",
        "product": "MARLIM SUL"
    },
    {
        "code": "110101039",
        "description": "BACIA DE CAMPOS",
        "product": "MARLIN/VOADOR"
    },
    {
        "code": "110204033",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "MASILA BLEND"
    },
    {
        "code": "120207002",
        "description": "CONDENSADOS DA OCEANIA",
        "product": "MAUI"
    },
    {
        "code": "110202004",
        "description": "PETRÓLEOS DA AMÉRICA DO NORTE & CARIBE",
        "product": "MAYA"
    },
    {
        "code": "110202005",
        "description": "PETRÓLEOS DA AMÉRICA DO NORTE & CARIBE",
        "product": "MAYA LEVE"
    },
    {
        "code": "110201069",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "MAYNA"
    },
    {
        "code": "110203037",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "MBIA"
    },
    {
        "code": "110203037",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "MBIA"
    },
    {
        "code": "110201037",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "MEDANITO"
    },
    {
        "code": "140201002",
        "description": "MATÉRIA - PRIMA DE 1ª GERAÇÃO",
        "product": "MELAÇO"
    },
    {
        "code": "110203078",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "MELLITAH"
    },
    {
        "code": "120203005",
        "description": "CONDENSADOS DA ÁFRICA",
        "product": "MELLITAH"
    },
    {
        "code": "120204010",
        "description": "CONDENSADOS DO ORIENTE MÉDIO",
        "product": "MELLITAH"
    },
    {
        "code": "110201038",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "MEREY"
    },
    {
        "code": "110201039",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "MEREY/LEONA"
    },
    {
        "code": "120101001",
        "description": "BACIA DE SANTOS",
        "product": "MERLUZA"
    },
    {
        "code": "110201040",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "MESA"
    },
    {
        "code": "110201041",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "MESCLA VENEZUELANO"
    },
    {
        "code": "740101007",
        "description": "ADITIVOS",
        "product": "METIL TERC BUTIL ETER - MTBE"
    },
    {
        "code": "420201003",
        "description": "ÓLEO DIESEL MARÍTIMO",
        "product": "MGO"
    },
    {
        "code": "640101001",
        "description": "MICROCRISTALINAS",
        "product": "MICROOLEOSAS"
    },
    {
        "code": "140201003",
        "description": "MATÉRIA - PRIMA DE 1ª GERAÇÃO",
        "product": "MILHO"
    },
    {
        "code": "110205027",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "MINAS"
    },
    {
        "code": "110103009",
        "description": "BACIA DO RECÔNCAVO",
        "product": "MIRANGA"
    },
    {
        "code": "110103010",
        "description": "BACIA DO RECÔNCAVO",
        "product": "MIRANGA/ÁGUA GRANDE"
    },
    {
        "code": "110205010",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "MIRI LEVE"
    },
    {
        "code": "120203008",
        "description": "CONDENSADOS DA ÁFRICA",
        "product": "MISKAR"
    },
    {
        "code": "820101018",
        "description": "BIODIESEL",
        "product": "MIST. DIESEL MARÍTIMO-95% / BIODIESEL-5%"
    },
    {
        "code": "820101017",
        "description": "BIODIESEL",
        "product": "MIST. DIESEL MARÍTIMO-98% / BIODIESEL-2%"
    },
    {
        "code": "820101006",
        "description": "BIODIESEL",
        "product": "MIST. DIESEL-80% / BIODIESEL-20% - B20"
    },
    {
        "code": "820101014",
        "description": "BIODIESEL",
        "product": "MIST. DIESEL-80% / BIODIESEL-20% - B20 INT ADITIV"
    },
    {
        "code": "820101006",
        "description": "BIODIESEL",
        "product": "MIST. DIESEL-80% / BIODIESEL-20% - B20 INT COMUM"
    },
    {
        "code": "820101016",
        "description": "BIODIESEL",
        "product": "MIST. DIESEL-80% / BIODIESEL-20% - B20 MET ADITIV"
    },
    {
        "code": "820101015",
        "description": "BIODIESEL",
        "product": "MIST. DIESEL-80% / BIODIESEL-20% - B20 MET COMUM"
    },
    {
        "code": "820101006",
        "description": "BIODIESEL",
        "product": "MIST. DIESEL-80% / BIODIESEL-20% - B5 INT COMUM"
    },
    {
        "code": "820101005",
        "description": "BIODIESEL",
        "product": "MIST. DIESEL-85% / BIODIESEL-15% - B15"
    },
    {
        "code": "820101004",
        "description": "BIODIESEL",
        "product": "MIST. DIESEL-90% / BIODIESEL-10% - B10"
    },
    {
        "code": "820101003",
        "description": "BIODIESEL",
        "product": "MIST. DIESEL-95% / BIODIESEL-5% - B5"
    },
    {
        "code": "820101011",
        "description": "BIODIESEL",
        "product": "MIST. DIESEL-95% / BIODIESEL-5% - B5 INT ADITIVADO"
    },
    {
        "code": "820101003",
        "description": "BIODIESEL",
        "product": "MIST. DIESEL-95% / BIODIESEL-5% - B5 INT COMUM"
    },
    {
        "code": "820101013",
        "description": "BIODIESEL",
        "product": "MIST. DIESEL-95% / BIODIESEL-5% - B5 MET ADITIVADO"
    },
    {
        "code": "820101012",
        "description": "BIODIESEL",
        "product": "MIST. DIESEL-95% / BIODIESEL-5% - B5 MET COMUM"
    },
    {
        "code": "820101002",
        "description": "BIODIESEL",
        "product": "MIST. DIESEL-98% / BIODIESEL-2% - B2"
    },
    {
        "code": "820101007",
        "description": "BIODIESEL",
        "product": "MIST. DIESEL-98% / BIODIESEL-2% - B2 INT ADITIVADO"
    },
    {
        "code": "820101002",
        "description": "BIODIESEL",
        "product": "MIST. DIESEL-98% / BIODIESEL-2% - B2 INT COMUM"
    },
    {
        "code": "820101009",
        "description": "BIODIESEL",
        "product": "MIST. DIESEL-98% / BIODIESEL-2% - B2 MET ADITIVADO"
    },
    {
        "code": "820101008",
        "description": "BIODIESEL",
        "product": "MIST. DIESEL-98% / BIODIESEL-2% - B2 MET COMUM"
    },
    {
        "code": "110301001",
        "description": "MISTURA",
        "product": "MISTURA DE PETRÓLEOS"
    },
    {
        "code": "110208001",
        "description": "OUTROS PETRÓLEOS IMPORTADOS",
        "product": "MISTURA DE PETRÓLEOS IMPORTADOS"
    },
    {
        "code": "110203038",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "MOANDA"
    },
    {
        "code": "110203089",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "MONDO"
    },
    {
        "code": "110201042",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "MONOGAS"
    },
    {
        "code": "110101026",
        "description": "BACIA DE CAMPOS",
        "product": "MOREIA"
    },
    {
        "code": "620502001",
        "description": "MOTORES 2 TEMPOS",
        "product": "MOTORES 2 TEMPOS"
    },
    {
        "code": "110203039",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "MOUDI"
    },
    {
        "code": "110202008",
        "description": "PETRÓLEOS DA AMÉRICA DO NORTE & CARIBE",
        "product": "MSW EDMONTON"
    },
    {
        "code": "110204034",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "MURBAN"
    },
    {
        "code": "110207009",
        "description": "PETRÓLEOS DA OCEANIA",
        "product": "MUTINEER"
    },
    {
        "code": "611201002",
        "description": "SHELL/ALEMANHA",
        "product": "MVI (N) 40 - NAFTÊNICO"
    },
    {
        "code": "611201003",
        "description": "SHELL/ALEMANHA",
        "product": "MVI 1050 - NAFTÊNICO"
    },
    {
        "code": "611201001",
        "description": "SHELL/ALEMANHA",
        "product": "MVI(N) 170 - NAFTÊNICO"
    },
    {
        "code": "610803002",
        "description": "CEPSA LUBRIFICANTES/ESPANHA",
        "product": "N 500"
    },
    {
        "code": "110110001",
        "description": "OUTROS PETRÓLEOS NACIONAIS",
        "product": "NACIONAL MISTURA"
    },
    {
        "code": "310101001",
        "description": "NAFTA PETROQUÍMICA",
        "product": "NAFTA"
    },
    {
        "code": "310102001",
        "description": "OUTRAS NAFTAS",
        "product": "NAFTA DE XISTO"
    },
    {
        "code": "310103001",
        "description": "NAFTA FORA DE ESPECIFICAÇÃO",
        "product": "NAFTA FORA DE ESPECIFICAÇÃO"
    },
    {
        "code": "310101001",
        "description": "NAFTA PETROQUÍMICA",
        "product": "NAFTA PETROQUÍMICA"
    },
    {
        "code": "611207003",
        "description": "OUTRO",
        "product": "NAFTALENOS ALQUILADOS (AN)"
    },
    {
        "code": "110101027",
        "description": "BACIA DE CAMPOS",
        "product": "NAMORADO"
    },
    {
        "code": "110205011",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "NANHAI LIGHT"
    },
    {
        "code": "110201062",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "NAPO"
    },
    {
        "code": "110203040",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "NEMBA"
    },
    {
        "code": "610801002",
        "description": "YPF/ARGENTINA",
        "product": "NEUTRAL OIL 150"
    },
    {
        "code": "610801004",
        "description": "YPF/ARGENTINA",
        "product": "NEUTRAL OIL 500"
    },
    {
        "code": "610801005",
        "description": "YPF/ARGENTINA",
        "product": "NEUTRAL OIL 60"
    },
    {
        "code": "610801003",
        "description": "YPF/ARGENTINA",
        "product": "NEUTRAL OIL300"
    },
    {
        "code": "610905001",
        "description": "MOTIVA/EUA",
        "product": "NEUTRAL 110"
    },
    {
        "code": "610101002",
        "description": "PARAFÍNICOS - GRUPO I",
        "product": "NEUTRO LEVE"
    },
    {
        "code": "610401002",
        "description": "RERREFINADOS",
        "product": "NEUTRO LEVE RR"
    },
    {
        "code": "610906002",
        "description": "LWART/BRASIL",
        "product": "NEUTRO LEVE RR"
    },
    {
        "code": "610813002",
        "description": "RERREFINADOR/BRASIL",
        "product": "NEUTRO LEVE RR"
    },
    {
        "code": "610101003",
        "description": "PARAFÍNICOS - GRUPO I",
        "product": "NEUTRO MÉDIO"
    },
    {
        "code": "610401003",
        "description": "RERREFINADOS",
        "product": "NEUTRO MÉDIO RR"
    },
    {
        "code": "610813003",
        "description": "RERREFINADOR/BRASIL",
        "product": "NEUTRO MÉDIO RR"
    },
    {
        "code": "610906003",
        "description": "LWART/BRASIL",
        "product": "NEUTRO MÉDIO RR"
    },
    {
        "code": "610101004",
        "description": "PARAFÍNICOS - GRUPO I",
        "product": "NEUTRO PESADO"
    },
    {
        "code": "610401004",
        "description": "RERREFINADOS",
        "product": "NEUTRO PESADO RR"
    },
    {
        "code": "610906004",
        "description": "LWART/BRASIL",
        "product": "NEUTRO PESADO RR"
    },
    {
        "code": "610813004",
        "description": "RERREFINADOR/BRASIL",
        "product": "NEUTRO PESADO RR"
    },
    {
        "code": "611102001",
        "description": "NESTE/FINLÂNDIA",
        "product": "NEXBASE 2002"
    },
    {
        "code": "611102002",
        "description": "NESTE/FINLÂNDIA",
        "product": "NEXBASE 2004"
    },
    {
        "code": "611102003",
        "description": "NESTE/FINLÂNDIA",
        "product": "NEXBASE 2006"
    },
    {
        "code": "611102004",
        "description": "NESTE/FINLÂNDIA",
        "product": "NEXBASE 2008"
    },
    {
        "code": "611001001",
        "description": "NESTE/FINLÂNDIA",
        "product": "NEXBASE 3030"
    },
    {
        "code": "611001002",
        "description": "NESTE/FINLÂNDIA",
        "product": "NEXBASE 3043"
    },
    {
        "code": "611001003",
        "description": "NESTE/FINLÂNDIA",
        "product": "NEXBASE 3050"
    },
    {
        "code": "611001004",
        "description": "NESTE/FINLÂNDIA",
        "product": "NEXBASE 3060"
    },
    {
        "code": "611001005",
        "description": "NESTE/FINLÂNDIA",
        "product": "NEXBASE 3080"
    },
    {
        "code": "611202001",
        "description": "PETROBRAS/BRASIL",
        "product": "NH 10 - NAFTÊNICO"
    },
    {
        "code": "611202002",
        "description": "PETROBRAS/BRASIL",
        "product": "NH 140 - NAFTÊNICO"
    },
    {
        "code": "611202003",
        "description": "PETROBRAS/BRASIL",
        "product": "NH 20 - NAFTÊNICO"
    },
    {
        "code": "110203041",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "NIGERIANO BRASS LIGHT"
    },
    {
        "code": "110203042",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "NIGERIANO LEVE"
    },
    {
        "code": "110203043",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "NIGERIANO LEVE QUA IBOE"
    },
    {
        "code": "110203094",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "NILE BLEND"
    },
    {
        "code": "110206024",
        "description": "PETRÓLEOS DA EUROPA E EX-URSS",
        "product": "NINIAN"
    },
    {
        "code": "110203044",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "NKOSSA"
    },
    {
        "code": "110203044",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "NKOSSA"
    },
    {
        "code": "430101001",
        "description": "OUTROS DERIVADOS MÉDIOS",
        "product": "NORMAL PARAFINAS"
    },
    {
        "code": "110206021",
        "description": "PETRÓLEOS DA EUROPA E EX-URSS",
        "product": "NORNE BLEND"
    },
    {
        "code": "120204004",
        "description": "CONDENSADOS DO ORIENTE MÉDIO",
        "product": "NORTH FIELD"
    },
    {
        "code": "110207007",
        "description": "PETRÓLEOS DA OCEANIA",
        "product": "NORTH WEST SHELF"
    },
    {
        "code": "611203002",
        "description": "NYNAS/FINLÂNDIA",
        "product": "NYNAS T22 - NAFTÊNICO"
    },
    {
        "code": "110203045",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "ODUDU"
    },
    {
        "code": "110201043",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "OFICINA"
    },
    {
        "code": "110203046",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "OGUENDJO"
    },
    {
        "code": "110203047",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "OKAN"
    },
    {
        "code": "110203048",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "OKONO"
    },
    {
        "code": "110203099",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "OKORO"
    },
    {
        "code": "110203081",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "OKWORI"
    },
    {
        "code": "430101004",
        "description": "OUTROS DERIVADOS MÉDIOS",
        "product": "ÓLEO COMB. P/ TURBINA GERADORA DE ENERGIA ELÉTRICA"
    },
    {
        "code": "510101003",
        "description": "ÓLEO COMBUSTÍVEL A - ALTO TEOR DE ENXOFRE",
        "product": "ÓLEO COMBUSTÍVEL A FORA DE ESPECIFICAÇÃO"
    },
    {
        "code": "510101001",
        "description": "ÓLEO COMBUSTÍVEL A - ALTO TEOR DE ENXOFRE",
        "product": "ÓLEO COMBUSTÍVEL A1"
    },
    {
        "code": "510101002",
        "description": "ÓLEO COMBUSTÍVEL A - ALTO TEOR DE ENXOFRE",
        "product": "ÓLEO COMBUSTÍVEL A2"
    },
    {
        "code": "510102003",
        "description": "ÓLEO COMBUSTÍVEL B - BAIXO TEOR DE ENXOFRE",
        "product": "ÓLEO COMBUSTÍVEL B FORA DE ESPECIFICAÇÃO"
    },
    {
        "code": "510102001",
        "description": "ÓLEO COMBUSTÍVEL B - BAIXO TEOR DE ENXOFRE",
        "product": "ÓLEO COMBUSTÍVEL B1"
    },
    {
        "code": "510102002",
        "description": "ÓLEO COMBUSTÍVEL B - BAIXO TEOR DE ENXOFRE",
        "product": "ÓLEO COMBUSTÍVEL B2"
    },
    {
        "code": "510201001",
        "description": "ÓLEOS COMBUSTÍVEIS MARÍTIMOS",
        "product": "ÓLEO COMBUSTÍVEL MARÍTIMO"
    },
    {
        "code": "510201002",
        "description": "ÓLEOS COMBUSTÍVEIS MARÍTIMOS",
        "product": "ÓLEO COMBUSTÍVEL MARÍTIMO FORA DE ESPECIFICAÇÃO"
    },
    {
        "code": "510201003",
        "description": "ÓLEOS COMBUSTÍVEIS MARÍTIMOS",
        "product": "ÓLEO COMBUSTÍVEL MARÍTIMO MISTURA (MF)"
    },
    {
        "code": "510301003",
        "description": "OUTROS ÓLEOS COMBUSTÍVEIS",
        "product": "ÓLEO COMBUSTÍVEL PARA GERAÇÃO ELÉTRICA"
    },
    {
        "code": "510103001",
        "description": "ÓLEO COMBUSTÍVEL 3 (OC3)",
        "product": "ÓLEO COMBUSTÍVEL 3 (OC3)"
    },
    {
        "code": "140101015",
        "description": "MATERIAIS GRAXOS",
        "product": "ÓLEO DE ABACATE (PERSIA AMERICANA)"
    },
    {
        "code": "140101009",
        "description": "MATERIAIS GRAXOS",
        "product": "ÓLEO DE ALGODÃO (GOSSYPIUM HIRSUT)"
    },
    {
        "code": "140101016",
        "description": "MATERIAIS GRAXOS",
        "product": "ÓLEO DE AMENDOIM (ORACHIS HYPOGEAE)"
    },
    {
        "code": "140101017",
        "description": "MATERIAIS GRAXOS",
        "product": "ÓLEO DE ANDIROBA (CARAPA GUIANERSIS)"
    },
    {
        "code": "140101005",
        "description": "MATERIAIS GRAXOS",
        "product": "ÓLEO DE BABAÇU (ORBINYA MARTIANA)"
    },
    {
        "code": "140101014",
        "description": "MATERIAIS GRAXOS",
        "product": "ÓLEO DE BURITI"
    },
    {
        "code": "140101018",
        "description": "MATERIAIS GRAXOS",
        "product": "ÓLEO DE COCO (COCUS NUMIFERA)"
    },
    {
        "code": "140101006",
        "description": "MATERIAIS GRAXOS",
        "product": "ÓLEO DE COLZA/CANOLA (BRESSICA CAMPESTRIS)"
    },
    {
        "code": "140101028",
        "description": "MATERIAIS GRAXOS",
        "product": "ÓLEO DE CRAMBE"
    },
    {
        "code": "140101021",
        "description": "MATERIAIS GRAXOS",
        "product": "ÓLEO DE FRITURA USADO"
    },
    {
        "code": "140101010",
        "description": "MATERIAIS GRAXOS",
        "product": "ÓLEO DE GIRASSOL (HELLANTHUS ANNUS)"
    },
    {
        "code": "140101012",
        "description": "MATERIAIS GRAXOS",
        "product": "ÓLEO DE LINHAÇA"
    },
    {
        "code": "140101013",
        "description": "MATERIAIS GRAXOS",
        "product": "ÓLEO DE MACAÚBA"
    },
    {
        "code": "140101001",
        "description": "MATERIAIS GRAXOS",
        "product": "ÓLEO DE MAMONA (RICINUS COMMUNIS)"
    },
    {
        "code": "140101030",
        "description": "MATERIAIS GRAXOS",
        "product": "ÓLEO DE MILHO"
    },
    {
        "code": "140101011",
        "description": "MATERIAIS GRAXOS",
        "product": "ÓLEO DE NABO-FORRAGEIRO"
    },
    {
        "code": "140101003",
        "description": "MATERIAIS GRAXOS",
        "product": "ÓLEO DE PALMA/DENDÊ (ELAEIS GUINEENSIS OU ELAEIS O"
    },
    {
        "code": "140101002",
        "description": "MATERIAIS GRAXOS",
        "product": "ÓLEO DE PALMISTE"
    },
    {
        "code": "140101008",
        "description": "MATERIAIS GRAXOS",
        "product": "ÓLEO DE PEQUI (JATROPHA CURCAS)"
    },
    {
        "code": "140101007",
        "description": "MATERIAIS GRAXOS",
        "product": "ÓLEO DE PINHÃO-MANSO"
    },
    {
        "code": "140101019",
        "description": "MATERIAIS GRAXOS",
        "product": "ÓLEO DE SÉSAMO (SESAMUN INDICUM)"
    },
    {
        "code": "140101004",
        "description": "MATERIAIS GRAXOS",
        "product": "ÓLEO DE SOJA (GLYCINE MAX)"
    },
    {
        "code": "560101001",
        "description": "OUTROS DERIVADOS PESADOS",
        "product": "ÓLEO DE XISTO"
    },
    {
        "code": "420301003",
        "description": "OUTROS ÓLEOS DIESEL",
        "product": "ÓLEO DIESEL A FORA DE ESPECIFICAÇÃO"
    },
    {
        "code": "420105001",
        "description": "ÓLEOS DIESEL A S10",
        "product": "ÓLEO DIESEL A S10"
    },
    {
        "code": "420101005",
        "description": "ÓLEOS DIESEL A S1800",
        "product": "ÓLEO DIESEL A S1800 - ADITIVADO"
    },
    {
        "code": "420101004",
        "description": "ÓLEOS DIESEL A S1800",
        "product": "ÓLEO DIESEL A S1800 - COMUM"
    },
    {
        "code": "420101003",
        "description": "ÓLEOS DIESEL A S1800",
        "product": "ÓLEO DIESEL A S1800 - FORA DE ESPECIFICAÇÃO"
    },
    {
        "code": "420101004",
        "description": "ÓLEOS DIESEL A S1800",
        "product": "ÓLEO DIESEL A S1800 NÃO RODOVIÁRIO"
    },
    {
        "code": "420101005",
        "description": "ÓLEOS DIESEL A S1800",
        "product": "ÓLEO DIESEL A S1800 NÃO RODOVIÁRIO - ADITIVADO"
    },
    {
        "code": "420101004",
        "description": "ÓLEOS DIESEL A S1800",
        "product": "ÓLEO DIESEL A S1800 NÃO RODOVIÁRIO - COMUM"
    },
    {
        "code": "420101003",
        "description": "ÓLEOS DIESEL A S1800",
        "product": "ÓLEO DIESEL A S1800 NÃO RODOVIÁRIO - FORA DE ESPECIFICAÇÃO"
    },
    {
        "code": "420102006",
        "description": "ÓLEOS DIESEL A S500",
        "product": "ÓLEO DIESEL A S50"
    },
    {
        "code": "420102004",
        "description": "ÓLEOS DIESEL A S500",
        "product": "ÓLEO DIESEL A S500"
    },
    {
        "code": "420102005",
        "description": "ÓLEOS DIESEL A S500",
        "product": "ÓLEO DIESEL A S500 - ADITIVADO"
    },
    {
        "code": "420102004",
        "description": "ÓLEOS DIESEL A S500",
        "product": "ÓLEO DIESEL A S500 - COMUM"
    },
    {
        "code": "420102003",
        "description": "ÓLEOS DIESEL A S500",
        "product": "ÓLEO DIESEL A S500 - FORA DE ESPECIFICAÇÃO"
    },
    {
        "code": "420104001",
        "description": "ÓLEOS DIESEL ESPECIAIS",
        "product": "ÓLEO DIESEL AUTOMOTIVO ESPECIAL - ENXOFRE 200 PPM"
    },
    {
        "code": "420301005",
        "description": "OUTROS ÓLEOS DIESEL",
        "product": "ÓLEO DIESEL B FORA DE ESPECIFICAÇÃO"
    },
    {
        "code": "820101033",
        "description": "BIODIESEL",
        "product": "ÓLEO DIESEL B S10 - ADITIVADO"
    },
    {
        "code": "820101034",
        "description": "BIODIESEL",
        "product": "ÓLEO DIESEL B S10 - COMUM"
    },
    {
        "code": "420106001",
        "description": "ÓLEOS DIESEL AMD",
        "product": "ÓLEO DIESEL B S10 AMD 10"
    },
    {
        "code": "820101011",
        "description": "BIODIESEL",
        "product": "ÓLEO DIESEL B S1800 - ADITIVADO"
    },
    {
        "code": "820101003",
        "description": "BIODIESEL",
        "product": "ÓLEO DIESEL B S1800 - COMUM"
    },
    {
        "code": "820101011",
        "description": "BIODIESEL",
        "product": "ÓLEO DIESEL B S1800 NÃO RODOVIÁRIO - ADITIVADO"
    },
    {
        "code": "820101003",
        "description": "BIODIESEL",
        "product": "ÓLEO DIESEL B S1800 NÃO RODOVIÁRIO - COMUM"
    },
    {
        "code": "820101028",
        "description": "BIODIESEL",
        "product": "ÓLEO DIESEL B S50 - ADITIVADO"
    },
    {
        "code": "820101029",
        "description": "BIODIESEL",
        "product": "ÓLEO DIESEL B S50 - COMUM"
    },
    {
        "code": "820101013",
        "description": "BIODIESEL",
        "product": "ÓLEO DIESEL B S500 - ADITIVADO"
    },
    {
        "code": "820101012",
        "description": "BIODIESEL",
        "product": "ÓLEO DIESEL B S500 - COMUM"
    },
    {
        "code": "420106002",
        "description": "ÓLEOS DIESEL AMD",
        "product": "ÓLEO DIESEL B S500 AMD 10"
    },
    {
        "code": "830101001",
        "description": "ÓLEO DIESEL DE CANA",
        "product": "ÓLEO DIESEL DE CANA AMD 100"
    },
    {
        "code": "420301001",
        "description": "OUTROS ÓLEOS DIESEL",
        "product": "ÓLEO DIESEL DE REFERÊNCIA - L-6 E P-7"
    },
    {
        "code": "420301004",
        "description": "OUTROS ÓLEOS DIESEL",
        "product": "OLEO DIESEL DE REFERÊNCIA - MAR-I"
    },
    {
        "code": "420301004",
        "description": "OUTROS ÓLEOS DIESEL",
        "product": "OLEO DIESEL DE REFERÊNCIA S300"
    },
    {
        "code": "420301003",
        "description": "OUTROS ÓLEOS DIESEL",
        "product": "ÓLEO DIESEL FORA DE ESPECIFICAÇÃO"
    },
    {
        "code": "420101005",
        "description": "ÓLEOS DIESEL A S1800",
        "product": "ÓLEO DIESEL INTERIOR ADITIVADO - ENXOFRE 1800 PPM"
    },
    {
        "code": "420101002",
        "description": "ÓLEOS DIESEL A S1800",
        "product": "ÓLEO DIESEL INTERIOR (B) ADITIVADO"
    },
    {
        "code": "420101001",
        "description": "ÓLEOS DIESEL A S1800",
        "product": "ÓLEO DIESEL INTERIOR (B) COMUM"
    },
    {
        "code": "420101003",
        "description": "ÓLEOS DIESEL A S1800",
        "product": "ÓLEO DIESEL INTERIOR (B) FORA DE ESPECIFICAÇÃO"
    },
    {
        "code": "420101004",
        "description": "ÓLEOS DIESEL A S1800",
        "product": "ÓLEO DIESEL INTERIOR COMUM - ENXOFRE 1800 PPM"
    },
    {
        "code": "420101003",
        "description": "ÓLEOS DIESEL A S1800",
        "product": "ÓLEO DIESEL INTERIOR FORA DE ESPECIFICAÇÃO"
    },
    {
        "code": "420201001",
        "description": "ÓLEO DIESEL MARÍTIMO",
        "product": "ÓLEO DIESEL MARÍTIMO"
    },
    {
        "code": "420203001",
        "description": "ÓLEO DIESEL MARÍTIMO COM BIOCOMBUSTÍVEL",
        "product": "ÓLEO DIESEL MARÍTIMO A2 ou DMA2"
    },
    {
        "code": "420203002",
        "description": "ÓLEO DIESEL MARÍTIMO COM BIOCOMBUSTÍVEL",
        "product": "ÓLEO DIESEL MARÍTIMO B2 ou DMB2"
    },
    {
        "code": "420201002",
        "description": "ÓLEO DIESEL MARÍTIMO",
        "product": "ÓLEO DIESEL MARÍTIMO FORA DE ESPECIFICAÇÃO"
    },
    {
        "code": "420102005",
        "description": "ÓLEOS DIESEL A S500",
        "product": "ÓLEO DIESEL METROPOLITANO ADITIVADO - ENXOFRE 500 PPM"
    },
    {
        "code": "420102004",
        "description": "ÓLEOS DIESEL A S500",
        "product": "ÓLEO DIESEL METROPOLITANO COMUM - ENXOFRE 500 PPM"
    },
    {
        "code": "420102002",
        "description": "ÓLEOS DIESEL A S500",
        "product": "ÓLEO DIESEL METROPOLITANO (D) ADITIVADO"
    },
    {
        "code": "420102001",
        "description": "ÓLEOS DIESEL A S500",
        "product": "ÓLEO DIESEL METROPOLITANO (D) COMUM"
    },
    {
        "code": "420102003",
        "description": "ÓLEOS DIESEL A S500",
        "product": "ÓLEO DIESEL METROPOLITANO (D) FORA DE ESPECIFICAÇÃO"
    },
    {
        "code": "420102003",
        "description": "ÓLEOS DIESEL A S500",
        "product": "ÓLEO DIESEL METROPOLITANO FORA DE ESPECIFICAÇÃO"
    },
    {
        "code": "420202001",
        "description": "ÓLEO DIESEL ESPECIAIS",
        "product": "ÓLEO DIESEL NÁUTICO ESPECIAL - ENXOFRE 200 PPM"
    },
    {
        "code": "420301001",
        "description": "OUTROS ÓLEOS DIESEL",
        "product": "ÓLEO DIESEL PADRÃO"
    },
    {
        "code": "820101030",
        "description": "BIODIESEL",
        "product": "ÓLEO DIESEL S10 B20 AUTORIZATIVO"
    },
    {
        "code": "820101025",
        "description": "BIODIESEL",
        "product": "ÓLEO DIESEL S10 B30 AUTORIZATIVO"
    },
    {
        "code": "820101006",
        "description": "BIODIESEL",
        "product": "ÓLEO DIESEL S1800 NÃO RODOVIÁRIO B20 AUTORIZATIVO"
    },
    {
        "code": "820101036",
        "description": "BIODIESEL",
        "product": "ÓLEO DIESEL S1800 NÃO RODOVIÁRIO B30 AUTORIZATIVO"
    },
    {
        "code": "420102006",
        "description": "ÓLEOS DIESEL A S500",
        "product": "ÓLEO DIESEL S50"
    },
    {
        "code": "420103002",
        "description": "ÓLEOS DIESEL A S50",
        "product": "ÓLEO DIESEL S500 ADITIVADO"
    },
    {
        "code": "820101015",
        "description": "BIODIESEL",
        "product": "ÓLEO DIESEL S500 B20 AUTORIZATIVO"
    },
    {
        "code": "820101035",
        "description": "BIODIESEL",
        "product": "ÓLEO DIESEL S500 B30 AUTORIZATIVO"
    },
    {
        "code": "420103001",
        "description": "ÓLEOS DIESEL A S50",
        "product": "ÓLEO DIESEL S500 COMUM"
    },
    {
        "code": "420103003",
        "description": "ÓLEOS DIESEL A S50",
        "product": "ÓLEO DIESEL S500 FORA DE ESPECIFICAÇÃO"
    },
    {
        "code": "611207002",
        "description": "OUTRO",
        "product": "ÓLEO MINERAL BRANCO"
    },
    {
        "code": "611301001",
        "description": "ÓLEOS BÁSICOS  - GRUPO I",
        "product": "ÓLEOS BÁSICOS - GRUPO I"
    },
    {
        "code": "610601001",
        "description": "ÓLEOS BÁSICOS - GRUPO II",
        "product": "ÓLEOS BÁSICOS - GRUPO II"
    },
    {
        "code": "610701001",
        "description": "ÓLEOS BÁSICOS - GRUPO III",
        "product": "ÓLEOS BÁSICOS - GRUPO III"
    },
    {
        "code": "510301002",
        "description": "OUTROS ÓLEOS COMBUSTÍVEIS",
        "product": "ÓLEOS COMBUSTÍVEIS PARA EXPORTAÇÃO"
    },
    {
        "code": "620601001",
        "description": "ÓLEOS LUBRIFICANTES ACABADOS",
        "product": "ÓLEOS EXTENSORES E PLASTIFICANTES"
    },
    {
        "code": "660101001",
        "description": "OUTROS ÓLEOS LUB. PARAF. E GRAXAS",
        "product": "ÓLEOS LUB. PARAF E GRAXAS INTERMEDIÁRIOS"
    },
    {
        "code": "620401001",
        "description": "ÓLEOS LUBRIFICANTES FERROVIÁRIOS",
        "product": "ÓLEOS LUBRIFICANTES FERROVIÁRIOS"
    },
    {
        "code": "620301001",
        "description": "ÓLEOS LUBRIFICANTES MARÍTIMOS",
        "product": "ÓLEOS LUBRIFICANTES MARÍTIMOS"
    },
    {
        "code": "620201001",
        "description": "ÓLEOS LUBRIFICANTES PARA AVIAÇÃO",
        "product": "ÓLEOS LUBRIFICANTES PARA AVIAÇÃO"
    },
    {
        "code": "630101001",
        "description": "ÓLEOS LUBRIFICANTES USADOS OU CONTAMINADOS",
        "product": "ÓLEOS LUBRIFICANTES USADOS OU CONTAMINADOS"
    },
    {
        "code": "611207004",
        "description": "OUTRO",
        "product": "ÓLEOS VEGETAIS"
    },
    {
        "code": "110202006",
        "description": "PETRÓLEOS DA AMÉRICA DO NORTE & CARIBE",
        "product": "OLMECA"
    },
    {
        "code": "110203093",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "OLOWI"
    },
    {
        "code": "110204035",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "OMAN"
    },
    {
        "code": "110203049",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "ORIBI"
    },
    {
        "code": "110201044",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "ORIENTE"
    },
    {
        "code": "110201045",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "ORITO"
    },
    {
        "code": "110206012",
        "description": "PETRÓLEOS DA EUROPA E EX-URSS",
        "product": "OSEBERG"
    },
    {
        "code": "120203003",
        "description": "CONDENSADOS DA ÁFRICA",
        "product": "OSO"
    },
    {
        "code": "110101052",
        "description": "BACIA DE CAMPOS",
        "product": "OSTRA"
    },
    {
        "code": "320301001",
        "description": "OUTRAS GASOLINAS",
        "product": "OUTRAS GASOLINAS"
    },
    {
        "code": "320103002",
        "description": "OUTRAS GASOLINAS AUTOMOTIVAS",
        "product": "OUTRAS GASOLINAS AUTOMOTIVAS"
    },
    {
        "code": "650101002",
        "description": "GRAXAS",
        "product": "OUTRAS GRAXAS"
    },
    {
        "code": "140201004",
        "description": "MATÉRIA - PRIMA DE 1ª GERAÇÃO",
        "product": "OUTRAS MATÉRIAS - PRIMAS"
    },
    {
        "code": "310102002",
        "description": "OUTRAS NAFTAS",
        "product": "OUTRAS NAFTAS"
    },
    {
        "code": "640401001",
        "description": "OUTRAS PARAFINAS",
        "product": "OUTRAS PARAFINAS"
    },
    {
        "code": "610907001",
        "description": "OUTRO",
        "product": "OUTRO"
    },
    {
        "code": "610814001",
        "description": "OUTRO",
        "product": "OUTRO"
    },
    {
        "code": "611107001",
        "description": "OUTRO",
        "product": "OUTRO"
    },
    {
        "code": "611007001",
        "description": "OUTRO",
        "product": "OUTRO"
    },
    {
        "code": "611207006",
        "description": "OUTRO",
        "product": "OUTRO"
    },
    {
        "code": "140101029",
        "description": "MATERIAIS GRAXOS",
        "product": "OUTROS ÁCIDOS GRAXOS"
    },
    {
        "code": "740101003",
        "description": "ADITIVOS",
        "product": "OUTROS ADITIVOS"
    },
    {
        "code": "810201002",
        "description": "OUTROS ALCOÓIS",
        "product": "OUTROS ALCOÓIS"
    },
    {
        "code": "530103001",
        "description": "OUTROS ASFALTOS",
        "product": "OUTROS ASFALTOS"
    },
    {
        "code": "340101003",
        "description": "OUTROS DERIVADOS LEVES",
        "product": "OUTROS DERIVADOS LEVES"
    },
    {
        "code": "430101003",
        "description": "OUTROS DERIVADOS MÉDIOS",
        "product": "OUTROS DERIVADOS MÉDIOS"
    },
    {
        "code": "560101003",
        "description": "OUTROS DERIVADOS PESADOS",
        "product": "OUTROS DERIVADOS PESADOS"
    },
    {
        "code": "611207001",
        "description": "OUTRO",
        "product": "OUTROS ÉSTERES SINTÉTICOS"
    },
    {
        "code": "210302001",
        "description": "OUTROS GASES",
        "product": "OUTROS GASES"
    },
    {
        "code": "210204002",
        "description": "OUTROS GASES LIQUEFEITOS",
        "product": "OUTROS GASES LIQUEFEITOS"
    },
    {
        "code": "130201001",
        "description": "OUTROS INSUMOS BRUTOS",
        "product": "OUTROS INSUMOS BRUTOS"
    },
    {
        "code": "530104001",
        "description": "INSUMOS NÃO REGULADOS",
        "product": "OUTROS INSUMOS PARA ASFALTOS"
    },
    {
        "code": "140101022",
        "description": "MATERIAIS GRAXOS",
        "product": "OUTROS MATERIAIS GRAXOS"
    },
    {
        "code": "140101999",
        "description": "MATERIAIS GRAXOS",
        "product": "OUTROS MATERIAIS GRAXOS"
    },
    {
        "code": "610201004",
        "description": "NAFTÊNICOS - GRUPO V",
        "product": "OUTROS NAFTÊNICOS"
    },
    {
        "code": "510301001",
        "description": "OUTROS ÓLEOS COMBUSTÍVEIS",
        "product": "OUTROS ÓLEOS COMBUSTÍVEIS"
    },
    {
        "code": "420301002",
        "description": "OUTROS ÓLEOS DIESEL",
        "product": "OUTROS ÓLEOS DIESEL"
    },
    {
        "code": "620601004",
        "description": "ÓLEOS LUBRIFICANTES ACABADOS",
        "product": "OUTROS ÓLEOS LUBRIFICANTES ACABADOS"
    },
    {
        "code": "620505001",
        "description": "OUTROS ÓLEOS LUBRIFICANTES AUTOMOTIVOS",
        "product": "OUTROS ÓLEOS LUBRIFICANTES AUTOMOTIVOS"
    },
    {
        "code": "610501001",
        "description": "OUTROS ÓLEOS LUBRIFICANTES BÁSICOS - GRUPO VI",
        "product": "OUTROS ÓLEOS LUBRIFICANTES BÁSICOS"
    },
    {
        "code": "620101008",
        "description": "ÓLEOS LUBRIFICANTES INDUSTRIAIS",
        "product": "OUTROS ÓLEOS LUBRIFICANTES INDUSTRIAIS"
    },
    {
        "code": "610101010",
        "description": "PARAFÍNICOS - GRUPO I",
        "product": "OUTROS PARAFÍNICOS"
    },
    {
        "code": "110208002",
        "description": "OUTROS PETRÓLEOS IMPORTADOS",
        "product": "OUTROS PETRÓLEOS IMPORTADOS"
    },
    {
        "code": "110110002",
        "description": "OUTROS PETRÓLEOS NACIONAIS",
        "product": "OUTROS PETRÓLEOS NACIONAIS"
    },
    {
        "code": "130202008",
        "description": "PRODUTOS PARA REPROCESSAMENTO",
        "product": "OUTROS PRODUTOS PARA REPROCESSAMENTO"
    },
    {
        "code": "410103001",
        "description": "OUTROS QUEROSENES",
        "product": "OUTROS QUEROSENES"
    },
    {
        "code": "610301002",
        "description": "SINTÉTICOS - GRUPO IV",
        "product": "OUTROS SINTÉTICOS"
    },
    {
        "code": "610302001",
        "description": "SINTÉTICOS - GRUPO V",
        "product": "OUTROS SINTÉTICOS"
    },
    {
        "code": "330101007",
        "description": "SOLVENTES ALIFÁTICOS",
        "product": "OUTROS SOLVENTES ALIFÁTICOS"
    },
    {
        "code": "330201009",
        "description": "SOLVENTES AROMÁTICOS",
        "product": "OUTROS SOLVENTES AROMÁTICOS"
    },
    {
        "code": "730101001",
        "description": "OUTROS SUB-PRODUTOS",
        "product": "OUTROS SUB-PRODUTOS"
    },
    {
        "code": "110205033",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "OYONG"
    },
    {
        "code": "110203050",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "PALANCA"
    },
    {
        "code": "110101028",
        "description": "BACIA DE CAMPOS",
        "product": "PAMPO"
    },
    {
        "code": "611106002",
        "description": "INEOS/EUA",
        "product": "PAO 4"
    },
    {
        "code": "611103001",
        "description": "CHEVRON PHILIPS/EUA",
        "product": "PAO 4"
    },
    {
        "code": "611104001",
        "description": "CHEMTURA/EUA",
        "product": "PAO 40"
    },
    {
        "code": "611106003",
        "description": "INEOS/EUA",
        "product": "PAO 6"
    },
    {
        "code": "611101007",
        "description": "EXXON MOBIL/EUA",
        "product": "PAO 8"
    },
    {
        "code": "611106001",
        "description": "INEOS/EUA",
        "product": "PAO 8"
    },
    {
        "code": "110101049",
        "description": "BACIA DE CAMPOS",
        "product": "PAPATERRA"
    },
    {
        "code": "110101029",
        "description": "BACIA DE CAMPOS",
        "product": "PARATI"
    },
    {
        "code": "110101030",
        "description": "BACIA DE CAMPOS",
        "product": "PARGO"
    },
    {
        "code": "110104007",
        "description": "BACIA DO ESPÍRITO SANTO",
        "product": "PARQUE DAS CONCHAS"
    },
    {
        "code": "110111001",
        "description": "TERRA BAHIA CATU",
        "product": "PAU LAVADO"
    },
    {
        "code": "110203102",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "PAZFLOR"
    },
    {
        "code": "610809002",
        "description": "PETROBRÁS/BRASIL",
        "product": "PBS 30"
    },
    {
        "code": "610809001",
        "description": "PETROBRÁS/BRASIL",
        "product": "PBS 33"
    },
    {
        "code": "610809003",
        "description": "PETROBRÁS/BRASIL",
        "product": "PCL 45"
    },
    {
        "code": "610809004",
        "description": "PETROBRÁS/BRASIL",
        "product": "PCL 60"
    },
    {
        "code": "120205006",
        "description": "CONDENSADOS DA ÁSIA",
        "product": "PEMBINA"
    },
    {
        "code": "110205032",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "PENARA BLEND"
    },
    {
        "code": "110203051",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "PENNINGTON"
    },
    {
        "code": "110101050",
        "description": "BACIA DE CAMPOS",
        "product": "PEREGRINO"
    },
    {
        "code": "110201065",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "PERENCO PERU BLEND"
    },
    {
        "code": "110105028",
        "description": "BACIA POTIGUAR",
        "product": "PERIQUITO"
    },
    {
        "code": "110104010",
        "description": "BACIA DO ESPÍRITO SANTO",
        "product": "PEROA"
    },
    {
        "code": "110105016",
        "description": "BACIA POTIGUAR",
        "product": "PESCADA/ARABAIANA"
    },
    {
        "code": "110209006",
        "description": "PETRÓLEOS IMPORTADOS",
        "product": "PETRÓLEO IMPORTADO ASFÁLTICO (API < 15)"
    },
    {
        "code": "110209001",
        "description": "PETRÓLEOS IMPORTADOS",
        "product": "PETRÓLEO IMPORTADO EXTRALEVE (API > 40)"
    },
    {
        "code": "110209005",
        "description": "PETRÓLEOS IMPORTADOS",
        "product": "PETRÓLEO IMPORTADO EXTRAPESADO (15 < API < 19)"
    },
    {
        "code": "110209002",
        "description": "PETRÓLEOS IMPORTADOS",
        "product": "PETRÓLEO IMPORTADO LEVE (33 < API < 40)"
    },
    {
        "code": "110209003",
        "description": "PETRÓLEOS IMPORTADOS",
        "product": "PETRÓLEO IMPORTADO  MÉDIO (27 < API < 33)"
    },
    {
        "code": "110209004",
        "description": "PETRÓLEOS IMPORTADOS",
        "product": "PETRÓLEO IMPORTADO PESADO (19 < API < 27)"
    },
    {
        "code": "611204001",
        "description": "BRASKEN/BRASIL",
        "product": "PIB 24 - POLIBUTENO"
    },
    {
        "code": "110201046",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "PILON"
    },
    {
        "code": "110106007",
        "description": "BACIA DE ALAGOAS/SERGIPE",
        "product": "PIRANEMA"
    },
    {
        "code": "110101031",
        "description": "BACIA DE CAMPOS",
        "product": "PIRAUNA"
    },
    {
        "code": "611207005",
        "description": "OUTRO",
        "product": "PLIGLICÓIS"
    },
    {
        "code": "110207008",
        "description": "PETRÓLEOS DA OCEANIA",
        "product": "PLUTO CONDENSADO"
    },
    {
        "code": "110203082",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "PLUTONIO"
    },
    {
        "code": "610809005",
        "description": "PETROBRÁS/BRASIL",
        "product": "PNL 30"
    },
    {
        "code": "610809006",
        "description": "PETROBRÁS/BRASIL",
        "product": "PNM 55"
    },
    {
        "code": "610809007",
        "description": "PETROBRÁS/BRASIL",
        "product": "PNM 80"
    },
    {
        "code": "610809008",
        "description": "PETROBRÁS/BRASIL",
        "product": "PNP 95"
    },
    {
        "code": "610301001",
        "description": "SINTÉTICOS - GRUPO IV",
        "product": "POLIALFAOLEFINA"
    },
    {
        "code": "110101032",
        "description": "BACIA DE CAMPOS",
        "product": "POLO NORDESTE"
    },
    {
        "code": "110101047",
        "description": "BACIA DE CAMPOS",
        "product": "POLVO"
    },
    {
        "code": "110105021",
        "description": "BACIA POTIGUAR",
        "product": "POTI"
    },
    {
        "code": "110105010",
        "description": "BACIA POTIGUAR",
        "product": "POTIGUAR TERRA"
    },
    {
        "code": "611206001",
        "description": "CRODA DO BRASIL/BRASIL",
        "product": "PRIOLUBE 3970 - ÉSTER"
    },
    {
        "code": "611206002",
        "description": "CRODA DO BRASIL/BRASIL",
        "product": "PRIOLUBE 3999 - ÉSTER"
    },
    {
        "code": "620101003",
        "description": "ÓLEOS LUBRIFICANTES INDUSTRIAIS",
        "product": "PROCESSO"
    },
    {
        "code": "210201001",
        "description": "C3",
        "product": "PROPANO"
    },
    {
        "code": "210203003",
        "description": "GASES LIQUEFEITO DE PETRÓLEO - GLP",
        "product": "PROPANO COMERCIAL"
    },
    {
        "code": "210201002",
        "description": "C3",
        "product": "PROPANO ESPECIAL"
    },
    {
        "code": "210203004",
        "description": "GASES LIQUEFEITO DE PETRÓLEO - GLP",
        "product": "PROPANO ESPECIAL"
    },
    {
        "code": "210201003",
        "description": "C3",
        "product": "PROPENO"
    },
    {
        "code": "610809009",
        "description": "PETROBRÁS/BRASIL",
        "product": "PSP 09"
    },
    {
        "code": "610809010",
        "description": "PETROBRÁS/BRASIL",
        "product": "PTL 25"
    },
    {
        "code": "610809011",
        "description": "PETROBRÁS/BRASIL",
        "product": "PTP 85"
    },
    {
        "code": "110105020",
        "description": "BACIA POTIGUAR",
        "product": "PTX"
    },
    {
        "code": "110105022",
        "description": "BACIA POTIGUAR",
        "product": "PTX-11"
    },
    {
        "code": "110205012",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "PULAI"
    },
    {
        "code": "620601002",
        "description": "ÓLEOS LUBRIFICANTES ACABADOS",
        "product": "PULVERIZAÇÃO AGRÍCOLA"
    },
    {
        "code": "120206003",
        "description": "CONDENSADOS DA EUROPA E EX-URSS",
        "product": "PUROVSKY"
    },
    {
        "code": "110204036",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "QATAR DUKHAN"
    },
    {
        "code": "110204037",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "QATAR LAND"
    },
    {
        "code": "110204038",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "QATAR MARINE"
    },
    {
        "code": "110203101",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "QUARUN"
    },
    {
        "code": "410101001",
        "description": "QUEROSENES DE AVIAÇÃO",
        "product": "QUEROSENE DE AVIAÇÃO"
    },
    {
        "code": "410101002",
        "description": "QUEROSENES DE AVIAÇÃO",
        "product": "QUEROSENE DE AVIAÇÃO FORA DE ESPECIFICAÇÃO"
    },
    {
        "code": "410102001",
        "description": "QUEROSENES ILUMINANTES",
        "product": "QUEROSENE ILUMINANTE"
    },
    {
        "code": "410102002",
        "description": "QUEROSENES ILUMINANTES",
        "product": "QUEROSENE ILUMINANTE FORA DE ESPECIFICAÇÃO"
    },
    {
        "code": "850101001",
        "description": "QUEROSENE DE AVIAÇÃO ALTERNATIVO",
        "product": "Querosene parafínico sintetizado hidroprocessado por Fischer-Tropsch (SPK-FT)"
    },
    {
        "code": "110103014",
        "description": "BACIA DO RECÔNCAVO",
        "product": "QUIAMBINA"
    },
    {
        "code": "110203052",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "RABI"
    },
    {
        "code": "330101005",
        "description": "SOLVENTES ALIFÁTICOS",
        "product": "RAFINADO DE PIRÓLISE"
    },
    {
        "code": "330101006",
        "description": "SOLVENTES ALIFÁTICOS",
        "product": "RAFINADO DE REFORMA"
    },
    {
        "code": "110205029",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "RANG DONG"
    },
    {
        "code": "110203053",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "RAS BUDRAN"
    },
    {
        "code": "120204008",
        "description": "CONDENSADOS DO ORIENTE MÉDIO",
        "product": "RAS GAS"
    },
    {
        "code": "110203054",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "RAS GHARIB"
    },
    {
        "code": "110204039",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "RATAWI"
    },
    {
        "code": "110201047",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "RECON BOLIVIANO"
    },
    {
        "code": "110201048",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "RECON MEREY"
    },
    {
        "code": "110103011",
        "description": "BACIA DO RECÔNCAVO",
        "product": "RECÔNCAVO"
    },
    {
        "code": "340101001",
        "description": "OUTROS DERIVADOS LEVES",
        "product": "REFORMADOS"
    },
    {
        "code": "550101001",
        "description": "RESÍDUOS PESADOS",
        "product": "RESÍDUO AROMÁTICO (RARO)"
    },
    {
        "code": "550101005",
        "description": "RESÍDUOS PESADOS",
        "product": "RESÍDUO ASFÁLTICO(RASF)"
    },
    {
        "code": "550101002",
        "description": "RESÍDUOS PESADOS",
        "product": "RESÍDUO ATMOSFÉRICO (RAT)"
    },
    {
        "code": "550101003",
        "description": "RESÍDUOS PESADOS",
        "product": "RESÍDUO DE VÁCUO"
    },
    {
        "code": "550101004",
        "description": "RESÍDUOS PESADOS",
        "product": "RESÍDUO DE VÁCUO DE ALTO TEOR DE ENXOGRE"
    },
    {
        "code": "130202007",
        "description": "PRODUTOS PARA REPROCESSAMENTO",
        "product": "RESÍDUO PARA REPROCESSAMENTO"
    },
    {
        "code": "110105011",
        "description": "BACIA POTIGUAR",
        "product": "RGN MISTURA"
    },
    {
        "code": "110105032",
        "description": "BACIA POTIGUAR",
        "product": "RIACHO TAPUIO"
    },
    {
        "code": "110201049",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "RINCÓN DE LOS SAUCES"
    },
    {
        "code": "110101048",
        "description": "BACIA DE CAMPOS",
        "product": "RJS-609"
    },
    {
        "code": "110105031",
        "description": "BACIA POTIGUAR",
        "product": "ROLINHA"
    },
    {
        "code": "110101033",
        "description": "BACIA DE CAMPOS",
        "product": "RONCADOR"
    },
    {
        "code": "110101040",
        "description": "BACIA DE CAMPOS",
        "product": "RONCADOR LESTE"
    },
    {
        "code": "110101045",
        "description": "BACIA DE CAMPOS",
        "product": "RONCADOR MISTURA"
    },
    {
        "code": "110101041",
        "description": "BACIA DE CAMPOS",
        "product": "RONCADOR OESTE"
    },
    {
        "code": "110204040",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "ROSTAM"
    },
    {
        "code": "110105019",
        "description": "BACIA POTIGUAR",
        "product": "RT"
    },
    {
        "code": "110205030",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "RUBY"
    },
    {
        "code": "110204041",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "RUMAILA"
    },
    {
        "code": "110105024",
        "description": "BACIA POTIGUAR",
        "product": "RV-1"
    },
    {
        "code": "110105036",
        "description": "BACIA POTIGUAR",
        "product": "SABIA BICO DE OSSO"
    },
    {
        "code": "110105035",
        "description": "BACIA POTIGUAR",
        "product": "SABIA DA MATA"
    },
    {
        "code": "110203070",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "SABLE CRUDE OIL"
    },
    {
        "code": "110203055",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "SAHARA MISTURA"
    },
    {
        "code": "110101053",
        "description": "BACIA DE CAMPOS",
        "product": "SALEMA"
    },
    {
        "code": "110204042",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "SALMAN"
    },
    {
        "code": "110203075",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "SALTPOND"
    },
    {
        "code": "110201050",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "SAN SEBASTIAN"
    },
    {
        "code": "110201051",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "SANTA BARBARA"
    },
    {
        "code": "110201052",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "SANTA CRUZ"
    },
    {
        "code": "110201053",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "SANTA CRUZ DO SUL"
    },
    {
        "code": "120201002",
        "description": "CONDENSADOS DA AMÉRICA DO SUL",
        "product": "SANTA ROSA"
    },
    {
        "code": "110105029",
        "description": "BACIA POTIGUAR",
        "product": "SÃO MANOEL"
    },
    {
        "code": "110107011",
        "description": "BACIA DE SANTOS",
        "product": "SAPINHOA"
    },
    {
        "code": "110203056",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "SARIR"
    },
    {
        "code": "110204043",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "SARKHOON"
    },
    {
        "code": "110203103",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "SATURNO BLEND"
    },
    {
        "code": "110203090",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "SAXI BLEND"
    },
    {
        "code": "140101020",
        "description": "MATERIAIS GRAXOS",
        "product": "SEBO"
    },
    {
        "code": "110103018",
        "description": "BACIA DO RECÔNCAVO",
        "product": "SEMPRE VIVA"
    },
    {
        "code": "110106004",
        "description": "BACIA DE ALAGOAS/SERGIPE",
        "product": "SERGIPANO MAR"
    },
    {
        "code": "110106005",
        "description": "BACIA DE ALAGOAS/SERGIPE",
        "product": "SERGIPANO MISTURA"
    },
    {
        "code": "110106006",
        "description": "BACIA DE ALAGOAS/SERGIPE",
        "product": "SERGIPANO TERRA"
    },
    {
        "code": "110205028",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "SERIA LIGHT"
    },
    {
        "code": "110105012",
        "description": "BACIA POTIGUAR",
        "product": "SERRARIA"
    },
    {
        "code": "120204005",
        "description": "CONDENSADOS DO ORIENTE MÉDIO",
        "product": "SHARJAH"
    },
    {
        "code": "110205013",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "SHENGLI"
    },
    {
        "code": "110201054",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "SHIVIYACU"
    },
    {
        "code": "110101044",
        "description": "BACIA DE CAMPOS",
        "product": "SIRI"
    },
    {
        "code": "110204044",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "SIRRI"
    },
    {
        "code": "110203057",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "SIRTICA"
    },
    {
        "code": "110203058",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "SKIKDA"
    },
    {
        "code": "120206002",
        "description": "CONDENSADOS DA EUROPA E EX-URSS",
        "product": "SLEIPNER"
    },
    {
        "code": "610805002",
        "description": "ENI SPA./ITÁLIA",
        "product": "SN 150"
    },
    {
        "code": "610803001",
        "description": "CEPSA LUBRIFICANTES/ESPANHA",
        "product": "SN 150"
    },
    {
        "code": "610812002",
        "description": "TOTAL LUBRICANTS/FRANÇA",
        "product": "SN 150"
    },
    {
        "code": "610811002",
        "description": "REPSOL YPF/ESPANHA",
        "product": "SN 150"
    },
    {
        "code": "610806001",
        "description": "EXXON MOBIL/EUA",
        "product": "SN 150"
    },
    {
        "code": "610807001",
        "description": "HAIFA/ISRAEL",
        "product": "SN 150"
    },
    {
        "code": "610810001",
        "description": "PETROGAL/PORTUGAL",
        "product": "SN 150"
    },
    {
        "code": "610806004",
        "description": "EXXON MOBIL/EUA",
        "product": "SN 275"
    },
    {
        "code": "610812003",
        "description": "TOTAL LUBRICANTS/FRANÇA",
        "product": "SN 330"
    },
    {
        "code": "610806005",
        "description": "EXXON MOBIL/EUA",
        "product": "SN 330"
    },
    {
        "code": "610811003",
        "description": "REPSOL YPF/ESPANHA",
        "product": "SN 500"
    },
    {
        "code": "610810002",
        "description": "PETROGAL/PORTUGAL",
        "product": "SN 500"
    },
    {
        "code": "610807002",
        "description": "HAIFA/ISRAEL",
        "product": "SN 500"
    },
    {
        "code": "610805003",
        "description": "ENI SPA./ITÁLIA",
        "product": "SN 500"
    },
    {
        "code": "610812004",
        "description": "TOTAL LUBRICANTS/FRANÇA",
        "product": "SN 500"
    },
    {
        "code": "610806002",
        "description": "EXXON MOBIL/EUA",
        "product": "SN 600"
    },
    {
        "code": "610812005",
        "description": "TOTAL LUBRICANTS/FRANÇA",
        "product": "SN 600"
    },
    {
        "code": "610812006",
        "description": "TOTAL LUBRICANTS/FRANÇA",
        "product": "SN 85"
    },
    {
        "code": "120206004",
        "description": "CONDENSADOS DA EUROPA E EX-URSS",
        "product": "SNOHVIT"
    },
    {
        "code": "330201008",
        "description": "SOLVENTES AROMÁTICOS",
        "product": "SOLVENTE C9 (OU C9 AROMÁTICO)"
    },
    {
        "code": "330101004",
        "description": "SOLVENTES ALIFÁTICOS",
        "product": "SOLVENTE PARA BORRACHA"
    },
    {
        "code": "110204045",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "SORROSH"
    },
    {
        "code": "110204046",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "SOUEDIA"
    },
    {
        "code": "110201063",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "SOUTH BLEND"
    },
    {
        "code": "110204055",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "SOUTHERN PARS"
    },
    {
        "code": "110206013",
        "description": "PETRÓLEOS DA EUROPA E EX-URSS",
        "product": "SOVIET EXPORT BLEND"
    },
    {
        "code": "110203059",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "SOYO"
    },
    {
        "code": "110203060",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "SOYO 38"
    },
    {
        "code": "611101001",
        "description": "EXXON MOBIL/EUA",
        "product": "SPECTRASYN 100"
    },
    {
        "code": "611101002",
        "description": "EXXON MOBIL/EUA",
        "product": "SPECTRASYN 2"
    },
    {
        "code": "611101003",
        "description": "EXXON MOBIL/EUA",
        "product": "SPECTRASYN 4"
    },
    {
        "code": "611101004",
        "description": "EXXON MOBIL/EUA",
        "product": "SPECTRASYN 40"
    },
    {
        "code": "611101005",
        "description": "EXXON MOBIL/EUA",
        "product": "SPECTRASYN 6"
    },
    {
        "code": "611101006",
        "description": "EXXON MOBIL/EUA",
        "product": "SPECTRASYN 8"
    },
    {
        "code": "610101001",
        "description": "PARAFÍNICOS - GRUPO I",
        "product": "SPINDLE"
    },
    {
        "code": "610401001",
        "description": "RERREFINADOS",
        "product": "SPINDLE RR"
    },
    {
        "code": "610906001",
        "description": "LWART/BRASIL",
        "product": "SPINDLE RR"
    },
    {
        "code": "610813001",
        "description": "RERREFINADOR/BRASIL",
        "product": "SPINDLE RR"
    },
    {
        "code": "611203001",
        "description": "NYNAS/FINLÂNDIA",
        "product": "SR 130 - NAFTÊNICO"
    },
    {
        "code": "610905002",
        "description": "MOTIVA/EUA",
        "product": "STAR 10"
    },
    {
        "code": "610905003",
        "description": "MOTIVA/EUA",
        "product": "STAR 12"
    },
    {
        "code": "610905004",
        "description": "MOTIVA/EUA",
        "product": "STAR 4"
    },
    {
        "code": "610905005",
        "description": "MOTIVA/EUA",
        "product": "STAR 6"
    },
    {
        "code": "110206015",
        "description": "PETRÓLEOS DA EUROPA E EX-URSS",
        "product": "STATFJORD"
    },
    {
        "code": "110206014",
        "description": "PETRÓLEOS DA EUROPA E EX-URSS",
        "product": "STATFJORD LOW SULFUR"
    },
    {
        "code": "110204052",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "SUEDIE"
    },
    {
        "code": "110205015",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "SUMATRAN HEAVY"
    },
    {
        "code": "110205014",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "SUMATRAN LIGHT"
    },
    {
        "code": "611105001",
        "description": "CHEVRON PHILLIPS/EUA",
        "product": "SYNFLUID PAO 100"
    },
    {
        "code": "611105002",
        "description": "CHEVRON PHILLIPS/EUA",
        "product": "SYNFLUID PAO 4"
    },
    {
        "code": "611105003",
        "description": "CHEVRON PHILLIPS/EUA",
        "product": "SYNFLUID PAO 40"
    },
    {
        "code": "611105004",
        "description": "CHEVRON PHILLIPS/EUA",
        "product": "SYNFLUID PAO 6"
    },
    {
        "code": "611105005",
        "description": "CHEVRON PHILLIPS/EUA",
        "product": "SYNFLUID PAO 8"
    },
    {
        "code": "110204047",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "SYRIAN LIGHT"
    },
    {
        "code": "611203003",
        "description": "NYNAS/FINLÂNDIA",
        "product": "T 9 - NAFTÊNICO"
    },
    {
        "code": "110106011",
        "description": "BACIA DE ALAGOAS/SERGIPE",
        "product": "TABULEIRO"
    },
    {
        "code": "110205016",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "TACHING"
    },
    {
        "code": "110203061",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "TAKULA"
    },
    {
        "code": "110205017",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "TAPIS"
    },
    {
        "code": "110204054",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "TAQ TAQ"
    },
    {
        "code": "110106009",
        "description": "BACIA DE ALAGOAS/SERGIPE",
        "product": "TARTARUGA"
    },
    {
        "code": "110101057",
        "description": "BACIA DE CAMPOS",
        "product": "TARTARUGA VERDE"
    },
    {
        "code": "110203062",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "TCHATAMBA"
    },
    {
        "code": "110206016",
        "description": "PETRÓLEOS DA EUROPA E EX-URSS",
        "product": "TENGIZ"
    },
    {
        "code": "120205007",
        "description": "CONDENSADOS DA ÁSIA",
        "product": "TERENGANU"
    },
    {
        "code": "120201003",
        "description": "CONDENSADOS DA AMÉRICA DO SUL",
        "product": "TERRA DEL FUEGO"
    },
    {
        "code": "620101006",
        "description": "ÓLEOS LUBRIFICANTES INDUSTRIAIS",
        "product": "TÊXTIL / AMACIANTE DE FIBRAS"
    },
    {
        "code": "120205008",
        "description": "CONDENSADOS DA ÁSIA",
        "product": "THAI"
    },
    {
        "code": "120204006",
        "description": "CONDENSADOS DO ORIENTE MÉDIO",
        "product": "THAMAMA"
    },
    {
        "code": "110201055",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "TIA JUANA"
    },
    {
        "code": "110201056",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "TIA JUANA MEDIO"
    },
    {
        "code": "110201057",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "TIA JUANA PESADO"
    },
    {
        "code": "110103020",
        "description": "BACIA DO RECÔNCAVO",
        "product": "TICO-TICO"
    },
    {
        "code": "110103024",
        "description": "BACIA DO RECÔNCAVO",
        "product": "TIÊ"
    },
    {
        "code": "110103016",
        "description": "BACIA DO RECÔNCAVO",
        "product": "TIGRE"
    },
    {
        "code": "110106012",
        "description": "BACIA DE ALAGOAS/SERGIPE",
        "product": "TIGRE"
    },
    {
        "code": "110205018",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "TIONG"
    },
    {
        "code": "110107005",
        "description": "BACIA DE SANTOS",
        "product": "TIRO"
    },
    {
        "code": "330201002",
        "description": "SOLVENTES AROMÁTICOS",
        "product": "TOLUENO"
    },
    {
        "code": "620504001",
        "description": "TRANSMISSÃO AUTOMÁTICA",
        "product": "TRANSMISSÃO AUTOMÁTICA"
    },
    {
        "code": "620503001",
        "description": "TRANSMISSÕES E SISTEMAS HIDRÁULICOS",
        "product": "TRANSMISSÕES E SISTEMAS HIDRÁULICOS"
    },
    {
        "code": "110101034",
        "description": "BACIA DE CAMPOS",
        "product": "TRILHA"
    },
    {
        "code": "110103023",
        "description": "BACIA DO RECÔNCAVO",
        "product": "TROVOADA"
    },
    {
        "code": "110101055",
        "description": "BACIA DE CAMPOS",
        "product": "TUBARÃO AZUL"
    },
    {
        "code": "110101056",
        "description": "BACIA DE CAMPOS",
        "product": "TUBARÃO MARTELO"
    },
    {
        "code": "110107004",
        "description": "BACIA DE SANTOS",
        "product": "TUPI"
    },
    {
        "code": "610101007",
        "description": "PARAFÍNICOS - GRUPO I",
        "product": "TURBINA LEVE"
    },
    {
        "code": "610101008",
        "description": "PARAFÍNICOS - GRUPO I",
        "product": "TURBINA PESADO"
    },
    {
        "code": "110105014",
        "description": "BACIA POTIGUAR",
        "product": "UBARANA/AGULHA"
    },
    {
        "code": "110205019",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "UDANG"
    },
    {
        "code": "110103012",
        "description": "BACIA DO RECÔNCAVO",
        "product": "UIRAPURU"
    },
    {
        "code": "110203063",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "UKPOKITI"
    },
    {
        "code": "611004001",
        "description": "S OIL/COREIA DO SUL",
        "product": "ULTRA-S 2"
    },
    {
        "code": "611004002",
        "description": "S OIL/COREIA DO SUL",
        "product": "ULTRA-S 4"
    },
    {
        "code": "611004003",
        "description": "S OIL/COREIA DO SUL",
        "product": "ULTRA-S 6"
    },
    {
        "code": "611004004",
        "description": "S OIL/COREIA DO SUL",
        "product": "ULTRA-S 8"
    },
    {
        "code": "120204007",
        "description": "CONDENSADOS DO ORIENTE MÉDIO",
        "product": "UMM SAIF"
    },
    {
        "code": "110204048",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "UMM SHAIF"
    },
    {
        "code": "110105013",
        "description": "BACIA POTIGUAR",
        "product": "UPANEMA"
    },
    {
        "code": "110204049",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "UPPER ZAKUM"
    },
    {
        "code": "110206017",
        "description": "PETRÓLEOS DA EUROPA E EX-URSS",
        "product": "URAL"
    },
    {
        "code": "110109001",
        "description": "BACIA DO SOLIMÕES",
        "product": "URUCU"
    },
    {
        "code": "110107006",
        "description": "BACIA DE SANTOS",
        "product": "URUGUA-TAMBAU"
    },
    {
        "code": "110203100",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "USAN BLEND"
    },
    {
        "code": "110203100",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "USAN BLEND"
    },
    {
        "code": "110206027",
        "description": "PETRÓLEOS DA EUROPA E EX-URSS",
        "product": "VARANDEY"
    },
    {
        "code": "120207006",
        "description": "CONDENSADOS DA OCEANIA",
        "product": "VARANUS"
    },
    {
        "code": "110201059",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "VASCONIA BLEND"
    },
    {
        "code": "110201058",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "VASCONIA 29,3"
    },
    {
        "code": "640301001",
        "description": "VASELINA",
        "product": "VASELINA"
    },
    {
        "code": "110101035",
        "description": "BACIA DE CAMPOS",
        "product": "VERMELHO"
    },
    {
        "code": "611002001",
        "description": "PETROCANADA/CANADA",
        "product": "VHVI 4"
    },
    {
        "code": "611002002",
        "description": "PETROCANADA/CANADA",
        "product": "VHVI 6"
    },
    {
        "code": "611002003",
        "description": "PETROCANADA/CANADA",
        "product": "VHVI 8"
    },
    {
        "code": "110101036",
        "description": "BACIA DE CAMPOS",
        "product": "VIOLA"
    },
    {
        "code": "611205001",
        "description": "INFINEUM BRASIL/BRASIL",
        "product": "VISTONE A-10 - ÉSTER"
    },
    {
        "code": "110206022",
        "description": "PETRÓLEOS DA EUROPA E EX-URSS",
        "product": "VITYAZ"
    },
    {
        "code": "110101037",
        "description": "BACIA DE CAMPOS",
        "product": "VOADOR"
    },
    {
        "code": "110202010",
        "description": "PETRÓLEOS DA AMÉRICA DO NORTE & CARIBE",
        "product": "WHITE ROSE"
    },
    {
        "code": "110205020",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "WIDURI"
    },
    {
        "code": "120207005",
        "description": "CONDENSADOS DA OCEANIA",
        "product": "WOOLLYBUTT CRUDE"
    },
    {
        "code": "110206018",
        "description": "PETRÓLEOS DA EUROPA E EX-URSS",
        "product": "WYTCH FARM"
    },
    {
        "code": "110108006",
        "description": "BACIA DO CEARÁ",
        "product": "XAREU/ATUM"
    },
    {
        "code": "611005001",
        "description": "SHELL/QATAR",
        "product": "XHVI 4"
    },
    {
        "code": "611005002",
        "description": "SHELL/QATAR",
        "product": "XHVI 8"
    },
    {
        "code": "110203076",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "XICOMBA"
    },
    {
        "code": "110205021",
        "description": "PETRÓLEOS DA ÁSIA",
        "product": "XIJIANG"
    },
    {
        "code": "330201003",
        "description": "SOLVENTES AROMÁTICOS",
        "product": "XILENOS"
    },
    {
        "code": "130101001",
        "description": "XISTO",
        "product": "XISTO BRUTO"
    },
    {
        "code": "110201060",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "YANAYACU"
    },
    {
        "code": "110203071",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "YOHO"
    },
    {
        "code": "611006001",
        "description": "SK/COREIA DO SUL",
        "product": "YUBASE 3"
    },
    {
        "code": "611006002",
        "description": "SK/COREIA DO SUL",
        "product": "YUBASE 4"
    },
    {
        "code": "611006003",
        "description": "SK/COREIA DO SUL",
        "product": "YUBASE 6"
    },
    {
        "code": "110203065",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "ZAFIRO"
    },
    {
        "code": "110203064",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "ZAIRE"
    },
    {
        "code": "110206026",
        "description": "PETRÓLEOS DA EUROPA E EX-URSS",
        "product": "ZAKINSKAYA"
    },
    {
        "code": "110204050",
        "description": "PETRÓLEOS DO ORIENTE MÉDIO",
        "product": "ZAKUM"
    },
    {
        "code": "110203066",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "ZARZAITINE"
    },
    {
        "code": "110203067",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "ZEIT BAY"
    },
    {
        "code": "110201061",
        "description": "PETRÓLEOS DA AMÉRICA DO SUL",
        "product": "ZUATA"
    },
    {
        "code": "110203068",
        "description": "PETRÓLEOS DA ÁFRICA",
        "product": "ZUEITINA"
    },
    {
        "code": "110105026",
        "description": "BACIA POTIGUAR",
        "product": "1-FAC-2-RN"
    },
    {
        "code": "110106008",
        "description": "BACIA DE ALAGOAS/SERGIPE",
        "product": "1-WW-1-BA"
    },
    {
        "code": "610902001",
        "description": "PHILIPS 66/EUA",
        "product": "100N"
    },
    {
        "code": "610901005",
        "description": "CHEVRON/EUA",
        "product": "100N"
    },
    {
        "code": "610901001",
        "description": "CHEVRON/EUA",
        "product": "100R"
    },
    {
        "code": "610901002",
        "description": "CHEVRON/EUA",
        "product": "150R"
    },
    {
        "code": "610901003",
        "description": "CHEVRON/EUA",
        "product": "220R"
    },
    {
        "code": "610902002",
        "description": "PHILIPS 66/EUA",
        "product": "225N"
    },
    {
        "code": "610902003",
        "description": "PHILIPS 66/EUA",
        "product": "600N"
    },
    {
        "code": "610901004",
        "description": "CHEVRON/EUA",
        "product": "600R"
    }
]