import { HttpClient } from '@angular/common/http';
import { Injectable, isSignal } from '@angular/core';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { NF_response } from '../interface/NF-response';
import { InvoiceItem } from '../interface/invoice-item';
import { ApiMovementPost, MovementGet } from '../interface/movement';
import { Operation } from '../interface/operation';
import { Part } from '../interface/part';
import { MoveTypes } from '../shared/lists/move-type';
import { environment } from '../../environments/environment';
import { firstValueFrom, Observable, of } from 'rxjs';
import { DataService } from './data.service';
import { MkgOSPart } from '../class/mkg-os-part';
import { OrderItem } from '../component/page/company/order/model/order-item';
import { OrderFull } from '../component/page/company/order/model/order.model';
import { MkgOS } from '../class/mkg-os';
import { PartService } from './part.service';
import { OperationService } from './operation.service';
import { OperationFunction } from '../shared/lists/operation-functions';
import { IOrderItem } from '../component/page/company/order/interface/order-item.interface';
import { IOrder } from '../component/page/company/order/interface/order.interface';
import { ApiPaginatedMovementQueryParams, ApiPaginatedResponse, PaginationService } from './pagination.service';
import { API_ERRORS } from '../shared/lists/errors';

@Injectable({
  providedIn: 'root'
})
export class MovementService {
  private _isFromMatrix = false;

  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private _partService: PartService,
    private _operationService: OperationService
  ) { }

  async getAll(): Promise<MovementGet[]> {
    const url = `${environment.mkgoURL}/api/v1/stockMove`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    let resp = await this.http.get(url, header).pipe(first()).toPromise();
    return resp as MovementGet[];
  }

  async getById(id: string): Promise<MovementGet> {
    const url = `${environment.mkgoURL}/api/v1/stockMove/${id}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp = await this.http.get(url, header).pipe(first()).toPromise()
    return resp as MovementGet;
  }

  async getByItemId(id: string): Promise<MovementGet[]> {
    const url = `${environment.mkgoURL}/api/v1/stockMove/item/${id}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    let resp = await firstValueFrom(this.http.get(url, header).pipe(first()));
    return (resp as MovementGet[]).map(mov => ({
      ...mov,
      createdAt: new Date(mov.createdAt),
    }));
  }

  async getAllPartsByPeriodOfTime(startDate: string, endDate: string): Promise<Part[]> {
    const url = `${environment.mkgoURL}/api/v1/stockMove/find-all/parts?startDate=${startDate}&endDate=${endDate}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const response = await firstValueFrom(this.http.get(url, header).pipe(first(), map(res => (res as any).parts)));
    return response as any;
  }

  async register(movement: ApiMovementPost) {
    const url = `${environment.mkgoURL}/api/v1/stockMove`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp = await this.http.post(url, movement, header).pipe(first()).toPromise();
    return resp
  }

  async update(movement: ApiMovementPost) {
    const url = `${environment.mkgoURL}/api/v1/stockMove/item/${movement.id}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp = await this.http.put(url, movement, header).pipe(first()).toPromise();
    return resp;
  }

  async remove(id: string): Promise<any> {
    const url = `${environment.mkgoURL}/api/v1/stockMove/${id}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp = await this.http.delete(url, header).pipe(first()).toPromise()
    return resp
  }


  /**
   * @param inOut true: in, false: out
   */
  public getMoveType(moveStock: boolean, inOut: boolean, isCancelling: boolean): MoveTypes {
    if (inOut) {
      if (isCancelling) {
        return moveStock ? MoveTypes["moveStock-in-cancelled"] : MoveTypes["noMoveStock-in-cancelled"];
      } else {
        return moveStock ? MoveTypes["moveStock-in"] : MoveTypes["noMoveStock-in"];
      }
    } else {
      if (isCancelling) {
        return moveStock ? MoveTypes["moveStock-out-cancelled"] : MoveTypes["noMoveStock-out-cancelled"]
      } else {
        return moveStock ? MoveTypes["moveStock-out"] : MoveTypes["noMoveStock-out"]
      }
    }
  }

  async createMovementFromOS(data: { nfInvoice?: NF_response, OS: MkgOS, roPart: MkgOSPart, part: Part, moveType: MoveTypes, operationFunction: OperationFunction, diference: number }, movementCaller: string) {
    try {
      const { OS, roPart, nfInvoice, moveType, operationFunction, part, diference } = data;

      let apiMovementToPost: ApiMovementPost = {
        item: part.id,
        amount: diference < 0 ? (diference * -1) : diference,
        balance: part.stock,
        caller: `${movementCaller}; MovementService.createMovementFromOS()`,
        Client: OS.client.id || OS.clientTmp,
        currentAvailableStock: part.availableStock,
        operationId: OS.operationId,
        functionType: operationFunction,
        moveType,
        OS: OS.id,
        userId: this.dataService.user.id,
        unitaryValue: isSignal(roPart.saleValue) ? roPart.saleValue() : roPart.saleValue,
      }
  
      if(OS.client){
        apiMovementToPost.Client = OS.client.id;
      }
  
      if (nfInvoice) {
        apiMovementToPost.note = nfInvoice.id;
      }
      
      await this.register(apiMovementToPost);
    } catch (error) {
      console.error(error);
    } 
  }

  async createMovementFromOrder(movementData: {
    order: OrderFull | IOrder;
    orderItem: OrderItem | IOrderItem;
    moveType: MoveTypes;
    operationFunction: OperationFunction;
    caller: string;
    operationId: string;
    diference: number;
  }) {
    const { moveType, operationId, operationFunction, order, orderItem, caller, diference } = movementData
    const part = await this._partService.get(orderItem.part);
    
    let movement: ApiMovementPost = {
      functionType: operationFunction,
      amount: diference < 0 ? (diference * -1) : diference,
      item: orderItem.part,
      unitaryValue: orderItem.saleValue,
      moveType: moveType,
      balance: part.stock,
      userId: this.dataService.user.id,
      orderId: order._id,
      operationId,
      caller: caller + "; MovementService.createMovementFromOrder()",
      currentAvailableStock: part.availableStock,
      Client: order.client
    }

    await this.register(movement);
  }
  
  async createMovementFromInvoice(data: {
    item: InvoiceItem;
    operation: Operation;
    invoice: NF_response;
    senderID: string;
    moveType: MoveTypes;
    part: Part;
    amount?: number;
    orderId?: string;
    OSId?: string;
    caller: string;
    operationFuntion: OperationFunction;
    isSupplier: boolean;
  }) {
    const { invoice, item, operation, senderID, moveType, part, orderId, caller, amount, operationFuntion, OSId, isSupplier } = data;

    let movement: ApiMovementPost = {
      functionType: operationFuntion,
      serial: invoice?.request?.Documento?.ide?.serie?.toString(),
      note: invoice?.id,
      amount: amount ?? item.amount,
      invoiceNumber: invoice?.numero,
      item: item.part.id,
      unitaryValue: item.value,
      moveType: moveType,
      balance: part.stock,
      userId: this.dataService.user.id,
      operationId: operation.id,
      caller: caller + "; MovementService.CreateMovementFromInvoice()",
      currentAvailableStock: part.availableStock
    }

    if(senderID){
      if (isSupplier) {
        movement.Supplier = senderID;
      } else {
        movement.Client = senderID;
      }
    }

    if (orderId) {
      movement.orderId = orderId;
    }

    if (OSId) {
      movement.OS = OSId;
    }
    
    await this.register(movement)
  }

  async createMovementFromNF(data: { 
    part: Part,
    amount: number,
    value: number,
    operation: Operation,
    invoice: NF_response,
    senderID: string,
    caller: string;
    OS?: MkgOS 
  }) {
    const { part, amount, value, operation, invoice, senderID, caller, OS } = data

    let movement: ApiMovementPost = {
      description: operation.description,
      functionType: operation.operationFunction,
      serial: invoice?.request?.Documento?.ide?.serie?.toString(),
      note: invoice?.id,
      invoiceNumber: invoice?.numero,
      amount,
      item: part.id,
      unitaryValue: value,
      moveType: this.getMoveType(operation.changeStock, operation.entry, false),
      balance: part.stock,
      userId: this.dataService.user.id,
      currentAvailableStock: part.availableStock,
      operationId: operation.id,
      caller: caller + "; MovementService.CreateMovementFromNF()"
    }

    if (senderID) {
      movement.Client = senderID;
    }

    if (OS) {
      movement.OS = OS.id;
    }

    await this.register(movement);
  }

  getPaginated(queryParams: ApiPaginatedMovementQueryParams): Observable<ApiPaginatedResponse<MovementGet>> {
    const url = `${environment.mkgoURL}/api/v1/stockMove/page`;
    let params = PaginationService.getParams(queryParams);

    return this.dataService.httpOptions(this._isFromMatrix, params).pipe(
      switchMap(headers => this.http.get<ApiPaginatedResponse<MovementGet>>(url, headers)),
      first(),
      catchError(err => {
        console.error(err)
        if (err.error.error === API_ERRORS.notFound) {
          return of(PaginationService.notFoundErrorDataToReturn());
        } else {
          throw err;
        }
      }),
      switchMap(resp => {
        if (queryParams.all === 1 && resp.hasNextPage) {
          queryParams.limit = resp.totalDocs;
          params = PaginationService.getParams(queryParams);
          return this.dataService.httpOptions(this._isFromMatrix, params).pipe(
            switchMap(headers2 => this.http.get<ApiPaginatedResponse<MovementGet>>(url, headers2)),
            catchError(err => {
              console.error(err)
              if (err.error.error === API_ERRORS.notFound) {
                return of(PaginationService.notFoundErrorDataToReturn());
              } else {
                throw err;
              }
            }),
            first()
          )
        } else {
          return of(resp);
        }
      })
    );
  }
}
