import { BoletoSettings } from "./boleto-settings";
import { PJBankAccount } from "./pjbank-account";

export const BoletoModuleActivationStatus = {
  enabled: 2,
  disabled: 1,
}
export interface BoletoPjBank {
  _id: string;
  pix?: string;
  status?: string;
  available?: string;
  company?: string;
  boleto_settings: BoletoSettings;
  due_date: string;
  amount: string;
  customer_name?: string;
  customer_email?: string;
  customer_phone?: string;
  customer_cpf?: string;
  customer_address?: string;
  customer_number?: string;
  customer_neighborhood?: string;
  customer_city?: string;
  customer_state?: string;
  customer_zipcode?: string;
  logo_url?: string;
  text?: string;
  instructions?: string;
  additional_instruction?: string;
  group?: string;
  webhook?: string;
  document_type?: string;
  pjbank_account?: PJBankAccount;
  order?: string;
  os?: {
    _id: string;
    client: string;
    codeSystem: number;
    code: number;
  };
  createdAt?: string;
  updatedAt?: string;
  bank_number?: string;
  boleto_link: string;
  digitable_line?: string;
  facilitator_token?: string;
  group_link?: string;
  order_number?: string;
  original_unique_id?: string;
  our_number?: string;
  unique_id?: string;
  title?: {
    _id: string;
    supplier_client: string;
    movementDate: string;
    expirationDate: string;
    value: number;
    invoiceNumber: string;
    seq: number;
  };
  rejection_reason_record?: string;
  payment_date?: String;
  credit_date?: String;
  amount_paid?: String;
  net_amount?: String;
  fee_amount?: String;
  duplicate_payment?: string;
  bank_system_record?: string;
}

export interface BoletoPjBankCreate {
  vencimento?: string;
  valor?: string;
  nome_cliente?: string;
  email_cliente?: string;
  telefone_cliente?: string;
  cpf_cliente?: string;
  endereco_cliente?: string;
  numero_cliente?: string;
  bairro_cliente?: string;
  cidade_cliente?: string;
  estado_cliente?: string;
  cep_cliente?: string;
  logo_url?: string;
  texto?: string;
  instrucoes?: string;
  instrucao_adicional?: string;
  grupo?: string;
  webhook?: string;
  pedido_numero?: string;
  especie_documento?: string;
  titulo: string;
}

export interface BoletoPjBankPaginated {
  docs: BoletoPjBank[];
  totalDocs: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: number | null;
  nextPage: number;
}
