import { Part } from "../interface/part";
import { TimeTracker } from "../interface/time-tracker";
import { signal, computed, WritableSignal, Signal, isSignal } from "@angular/core";
import { ApiOS } from "./mkg-os";
import { Money } from "./money";
import { ApiFloat } from "./api-float";
import moment, { Moment } from "moment";

export enum ITEM_BLOCK {
  "Blocked" = 1, // Bloqueado
  "Released" // Liberado
}

export class MkgOSPart {
  id?: string;
  part?: Part; // required
  /**
   * The item cost when inserted into OS
   * 
   * Multiplied by 100 into backend 
   **/
  purchaseValue?: number;
  /**
   * The average cost of item when inserted into OS
   * 
   *  Multiplied by 100 into backend 
   **/
  purchaseAverage?: number;
  /** 
   * Está sendo invertido o campo de observações para que as observações
   * que ja existiam (campo 'observation') aparecam como padrão no pdf  
   */
  observation?: string;
  status?: number;
  internalObservation?: string;
  seq?: number;
  /** 1 - not delivered | 2 - delivered */
  delivery?: 1 | 2;
  /** @see partStates */
  available?: number;
  lastUpdate?: Date;
  timeTrackers?: TimeTracker[];
  blocked?: boolean;
  running?: boolean;
  mechanic?: string;
  itenBlock?: number

  amount!: WritableSignal<number>;

  /** Multiplied by 100 into backend */
  saleValue!: WritableSignal<number>;

  /** The percentage of gross value of given discount */
  additionalDiscount!: number;

  /** 
   * The additional discount, in currency value 
   * 
   * Multiplied by 100 into backend
   */
  discountValue!: WritableSignal<number>;

  /** 
   * The currency discount equivalent to OS.discountParts
   * 
   * Multiplied by 100 into backend
   */
  discountProp!: WritableSignal<number>;

  /** 
   * The currency discount equivalent to OS.generalDiscount
   *
   * Multiplied by 100 into backend 
   */
  discountPropGeral!: WritableSignal<number>;

  grossValue!: Signal<number>;

  /** grossValue - discountValue */
  liquid1!: Signal<number>;

  /** grossValue - discountValue - discountPropParts */
  liquid2!: Signal<number>;

  /** grossValue - discountValue - discountPropParts - discountPropOS*/
  liquidValue!: Signal<number>;

  // absent in the API
  /** @deprecated */
  oldAmount?: number;
  /** @deprecated */
  oldUnitValue?: number;
  /** @deprecated */
  error?: number;

  nationalFederal?: number
  state?: number;
  municipal?: number;
  key?: string;
  nroPedido?: string;
  seqItem?: number;
  unitOfMeasurements?: string;

  updatedAt?: Moment;
  createdAt?: Moment;

  constructor(apiObj: any, divideByOneHundred = true) {
    apiObj.discountValue = isSignal(apiObj.discountValue) ? apiObj.discountValue() : apiObj.discountValue;
    apiObj.discountProp = isSignal(apiObj.discountProp) ? apiObj.discountProp() : apiObj.discountProp;
    apiObj.discountPropGeral = isSignal(apiObj.discountPropGeral) ? apiObj.discountPropGeral() : apiObj.discountPropGeral;
    apiObj.saleValue = isSignal(apiObj.saleValue) ? apiObj.saleValue() : apiObj.saleValue;

    this.purchaseValue = (apiObj.purchaseValue || 0) / (divideByOneHundred ? 100 : 1);
    this.purchaseAverage = (apiObj.purchaseAverage || 0) / (divideByOneHundred ? 100 : 1);
    this.observation = apiObj.internalObservation ?? "";
    this.internalObservation = apiObj.observation ?? "";
    this.amount = isSignal(apiObj.amount) ? apiObj.amount : signal(apiObj.amount);
    this.saleValue = signal((apiObj.saleValue ?? 0) / (divideByOneHundred ? 100 : 1));
    this.grossValue = computed(() => this.amount() * this.saleValue())
    this.discountValue = signal((apiObj.discountValue ?? 0) / (divideByOneHundred ? 100 : 1));
    this.discountProp = signal((apiObj.discountProp ?? 0) / (divideByOneHundred ? 100 : 1));
    this.discountPropGeral = signal((apiObj.discountPropGeral ?? 0) / (divideByOneHundred ? 100 : 1));
    this.liquid1 = computed(() => this.grossValue() - this.discountValue());
    this.liquid2 = computed(() => this.liquid1() - this.discountProp());
    this.liquidValue = computed(() => Money(this.liquid2() - this.discountPropGeral()));

    if (apiObj.id) {
      this.id = apiObj.id;
    }

    if (apiObj.part) {
      this.part = apiObj.part;

      if (apiObj.part?.unitMeasure) {
        this.part.unitOfMeasurements = apiObj.part.unitMeasure;
      }
    }

    if (apiObj.mechanic) {
      this.mechanic = apiObj.mechanic;
    }

    if (apiObj.lastUpdate) {
      this.lastUpdate = new Date(apiObj.lastUpdate);
    }

    if (Array.isArray(apiObj.timeTrackers)) {
      this.timeTrackers = apiObj.timeTrackers;
    } else {
      this.timeTrackers = [];
    }

    if (Number.isFinite(apiObj.status)) {
      this.status = apiObj.status;
    }

    if (Number.isFinite(apiObj.seq)) {
      this.seq = apiObj.seq;
    }

    if (Number.isFinite(apiObj.delivery)) {
      this.delivery = apiObj.delivery;
    }

    if (Number.isFinite(apiObj.available)) {
      this.available = apiObj.available;
    }

    if (Number.isFinite(apiObj.itenBlock)) {
      this.itenBlock = apiObj.itenBlock;
    }

    if (Number.isFinite(apiObj.additionalDiscount)) {
      this.additionalDiscount = apiObj.additionalDiscount
    }

    if (Object.hasOwn(apiObj, "blocked")) {
      this.blocked = apiObj.block;
    }

    if (Object.hasOwn(apiObj, "running")) {
      this.running = apiObj.running;
    }

    if (apiObj.key) {
      this.nationalFederal = apiObj.nationalFederal;
      this.state = apiObj.state;
      this.municipal = apiObj.municipal;
      this.key = apiObj.key;
    }

    if (apiObj.nroPedido) {
      this.nroPedido = apiObj.nroPedido;
    }
    
    if (apiObj.seqItem) {
      this.seqItem = apiObj.seqItem;
    }

    if (apiObj.createdAt) {
      this.createdAt = moment(apiObj.createdAt);
    }

    if (apiObj.updatedAt) {
      this.updatedAt = moment(apiObj.updatedAt);
    }
  }

  toAPI(): ApiOS['parts'][number] {
    let apiPart = {
      "amount": ApiFloat(this.amount(), 3),
      "saleValue": ApiFloat(this.saleValue()),
      "purchaseValue": ApiFloat(this.purchaseValue),
      "purchaseAverage": ApiFloat(this.purchaseAverage),
      "delivery": this.delivery ?? 1,
      "discountValue": ApiFloat(this.discountValue()),
      "discountProp": ApiFloat(this.discountProp()),
      "discountPropGeral": ApiFloat(this.discountPropGeral()),
      "observation": this.internalObservation,
      "internalObservation": this.observation,
      "available": (this.available || "0") as number,
      "liquidValue": ApiFloat(this.liquidValue()),
      "additionalDiscount": this.additionalDiscount,
      "lastUpdate": new Date()
    };
    if (this.id) {
      apiPart["id"] = this.id;
    }
    if (this.status) {
      apiPart["status"] = this.status;
    }
    if (this.timeTrackers) {
      apiPart["timeTrackers"] = this.timeTrackers;
    }
    if (this.hasOwnProperty("blocked")) {
      apiPart["blocked"] = this.blocked;
    }
    if (this.hasOwnProperty("running")) {
      apiPart["running"] = this.running;
    }
    if (this.mechanic) {
      apiPart["mechanic"] = this.mechanic;
    } else {
      apiPart["mechanic"] = "";
    }
    if (this.part && this.part.id) {
      apiPart["part"] = this.part.id;
    }
    if (Number.isFinite(this.seq)) {
      apiPart["seq"] = this.seq;
    }
    if (this.itenBlock) {
      apiPart["itenBlock"] = this.itenBlock;
    }

    if (this.nroPedido) {
      apiPart["nroPedido"] = this.nroPedido;
    }
    if (this.seqItem) {
      apiPart["seqItem"] = +this.seqItem;
    }

    return apiPart
  }
}