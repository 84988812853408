"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CNAE = void 0;
/**
 * Criado com base na tabela fornecida pela Migrate
 *
 * [TABELA CNAE](https://migrate.info/desenvolvedores/web/BR/InvoiCy/NFSe/documentos/Tabela%20cnae.xlsx)
 *
 */
;
exports.CNAE = [
    {
        "subclasse": "7229000",
        "denominacao": "DESENVOLVIMENTO DE SOFTWARE SOB ENCOMENDA E OUTRAS CONSULTORIAS EM SOFTWARE.",
        "descricao": "SERVIÇO DE ANÁLISE E DESENVOLVIMENTO  DE SISTEMA (SOFTWARE)",
        "item": "1",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "224",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7229000",
        "denominacao": "DESENVOLVIMENTO DE SOFTWARE SOB ENCOMENDA E OUTRAS CONSULTORIAS EM SOFTWARE.",
        "descricao": "SERVIÇO DE ANÁLISE E DESENVOLVIMENTO  DE SISTEMA (SOFTWARE)",
        "item": "1",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1574",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6204000",
        "denominacao": "CONSULTORIA EM TECNOLOGIA DA INFORMACAO",
        "descricao": "SERVIÇO DE ANÁLISE E DESENVOLVIMENTO  DE SISTEMA (SOFTWARE)",
        "item": "1",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "9177",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6202300",
        "denominacao": "DESENVOLVIMENTO E LICENCIAMENTO DE PROGRAMAS DE COMPUTADOR CUSTOMIZAVEIS",
        "descricao": "SERVIÇO DE ANÁLISE E DESENVOLVIMENTO  DE SISTEMA (SOFTWARE)",
        "item": "1",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "9180",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6201500",
        "denominacao": "DESENVOLVIMENTO DE PROGRAMAS DE COMPUTADOR SOB ENCOMENDA",
        "descricao": "SERVIÇO DE ANÁLISE E DESENVOLVIMENTO  DE SISTEMA (SOFTWARE)",
        "item": "1",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "9179",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6203100",
        "denominacao": "DESENVOLVIMENTO E LICENCIAMENTO DE PROGRAMAS DE COMPUTADOR NAO-CUSTOMIZAVEIS",
        "descricao": "SERVIÇO DE ANÁLISE E DESENVOLVIMENTO  DE SISTEMA (SOFTWARE)",
        "item": "1",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "9178",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6201500",
        "denominacao": "DESENVOLVIMENTO DE PROGRAMAS DE COMPUTADOR SOB ENCOMENDA",
        "descricao": "SERVIÇOS DE PROGRAMAÇÃO",
        "item": "1",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "9181",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6311900",
        "denominacao": "TRATAMENTO DE DADOS, PROVEDORES DE SERVICOS DE APLICACAO E SERVICOS DE HOSPEDAGEM NA INTERNET",
        "descricao": "SERVIÇO DE COLETA E PROCESSAMENTO DE DADOS",
        "item": "1",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "9182",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6202300",
        "denominacao": "DESENVOLVIMENTO E LICENCIAMENTO DE PROGRAMAS DE COMPUTADOR CUSTOMIZAVEIS",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE PROGRAMAS DE COMPUTADOR, INCLUSIVE DE JOGOS ELETRÔNICOS",
        "item": "1",
        "subitem": "4",
        "aliquota": "2",
        "idcnae": "9100",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6201500",
        "denominacao": "DESENVOLVIMENTO DE PROGRAMAS DE COMPUTADOR SOB ENCOMENDA",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE PROGRAMAS DE COMPUTADOR, INCLUSIVE DE JOGOS ELETRÔNICOS",
        "item": "1",
        "subitem": "4",
        "aliquota": "2",
        "idcnae": "9101",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7221400",
        "denominacao": "DESENVOLVIMENTO E EDIÇÃO DE SOFTWARE PRONTO PARA USO.",
        "descricao": "SERVIÇOS DE LICENCIAMENTO OU CESSÃO DE DIREITO DE USO DE PROGRAMAS DE COMPUTAÇÃO",
        "item": "1",
        "subitem": "5",
        "aliquota": "2",
        "idcnae": "279",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7221400",
        "denominacao": "DESENVOLVIMENTO E EDIÇÃO DE SOFTWARE PRONTO PARA USO.",
        "descricao": "SERVIÇOS DE LICENCIAMENTO OU CESSÃO DE DIREITO DE USO DE PROGRAMAS DE COMPUTAÇÃO",
        "item": "1",
        "subitem": "5",
        "aliquota": "2",
        "idcnae": "1629",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6202300",
        "denominacao": "DESENVOLVIMENTO E LICENCIAMENTO DE PROGRAMAS DE COMPUTADOR CUSTOMIZAVEIS",
        "descricao": "SERVIÇOS DE LICENCIAMENTO OU CESSÃO DE DIREITO DE USO DE PROGRAMAS DE COMPUTAÇÃO",
        "item": "1",
        "subitem": "5",
        "aliquota": "2",
        "idcnae": "9102",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6204000",
        "denominacao": "CONSULTORIA EM TECNOLOGIA DA INFORMAÇÃO",
        "descricao": "ASSESSORIA E CONSULTORIA EM INFORMÁTICA",
        "item": "1",
        "subitem": "6",
        "aliquota": "2",
        "idcnae": "11744",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7240000",
        "denominacao": "ATIVIDADES DE BANCO DE DADOS E DISTRIBUIÇÃO ON LINE DE CONTEÚDO ELETRÔNICO.",
        "descricao": "SERVIÇOS DE SUPORTE TÉCNICO EM INFORMÁTICA, INCLUSIVE INSTALAÇÃO, CONFIGURAÇÃO E MANUTENÇÃO DE PROGRAMAS DE COMPUTAÇÃO E BANCOS DE DADOS",
        "item": "1",
        "subitem": "7",
        "aliquota": "2",
        "idcnae": "177",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7240000",
        "denominacao": "ATIVIDADES DE BANCO DE DADOS E DISTRIBUIÇÃO ON LINE DE CONTEÚDO ELETRÔNICO.",
        "descricao": "SERVIÇOS DE SUPORTE TÉCNICO EM INFORMÁTICA, INCLUSIVE INSTALAÇÃO, CONFIGURAÇÃO E MANUTENÇÃO DE PROGRAMAS DE COMPUTAÇÃO E BANCOS DE DADOS",
        "item": "1",
        "subitem": "7",
        "aliquota": "2",
        "idcnae": "1527",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6110801",
        "denominacao": "SERVIÇOS DE TELEFONIA FIXA COMUTADA - STFC",
        "descricao": "SERVIÇOS DE SUPORTE TÉCNICO EM INFORMÁTICA, INCLUSIVE INSTALAÇÃO, CONFIGURAÇÃO E MANUTENÇÃO DE PROGRAMAS DE COMPUTAÇÃO E BANCOS DE DADOS",
        "item": "1",
        "subitem": "7",
        "aliquota": "2",
        "idcnae": "11725",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6209100",
        "denominacao": "SUPORTE TECNICO, MANUTENCAO E OUTROS SERVICOS EM TECNOLOGIA DA INFORMACAO",
        "descricao": "SERVIÇOS DE SUPORTE TÉCNICO EM INFORMÁTICA, INCLUSIVE INSTALAÇÃO, CONFIGURAÇÃO E MANUTENÇÃO DE PROGRAMAS DE COMPUTAÇÃO E BANCOS DE DADOS",
        "item": "1",
        "subitem": "7",
        "aliquota": "2",
        "idcnae": "11544",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6319400",
        "denominacao": "PORTAIS, PROVEDORES DE CONTEUDO E OUTROS SERVICOS DE INFORMACAO NA INTERNET",
        "descricao": "SERVIÇOS DE PLANEJAMENTO, CONFECÇÃO, MANUTENÇÃO E ATUALIZAÇÃO DE PÁGINAS ELETRÔNICAS",
        "item": "1",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "9114",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6201500",
        "denominacao": "DESENVOLVIMENTO DE PROGRAMAS DE COMPUTADOR SOB ENCOMENDA",
        "descricao": "SERVIÇOS DE PLANEJAMENTO, CONFECÇÃO, MANUTENÇÃO E ATUALIZAÇÃO DE PÁGINAS ELETRÔNICAS",
        "item": "1",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "9116",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6311900",
        "denominacao": "TRATAMENTO DE DADOS, PROVEDORES DE SERVICOS DE APLICACAO E SERVICOS DE HOSPEDAGEM NA INTERNET",
        "descricao": "SERVIÇOS DE PLANEJAMENTO, CONFECÇÃO, MANUTENÇÃO E ATUALIZAÇÃO DE PÁGINAS ELETRÔNICAS",
        "item": "1",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "9115",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6201502",
        "denominacao": "WEB DESIGN",
        "descricao": "SERVIÇOS DE PLANEJAMENTO, CONFECÇÃO, MANUTENÇÃO E ATUALIZAÇÃO DE PÁGINAS ELETRÔNICAS ",
        "item": "1",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "11745",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7220700",
        "denominacao": "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIENCIAS SOCIAIS E HUMANAS",
        "descricao": "SERVIÇOS DE PESQUISAS E DESENVOLVIMENTO",
        "item": "2",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9117",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7119702",
        "denominacao": "ATIVIDADES DE ESTUDOS GEOLOGICOS",
        "descricao": "SERVIÇOS DE PESQUISAS E DESENVOLVIMENTO",
        "item": "2",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9119",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7320300",
        "denominacao": "PESQUISAS DE MERCADO E DE OPINIAO PUBLICA",
        "descricao": "SERVIÇOS DE PESQUISAS E DESENVOLVIMENTO",
        "item": "2",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9121",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8550302",
        "denominacao": "ATIVIDADES DE APOIO A EDUCACAO, EXCETO CAIXAS ESCOLARES",
        "descricao": "SERVIÇOS DE PESQUISAS E DESENVOLVIMENTO",
        "item": "2",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9120",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7210000",
        "denominacao": "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIENCIAS FISICAS E NATURAIS",
        "descricao": "SERVIÇOS DE PESQUISAS E DESENVOLVIMENTO",
        "item": "2",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9118",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5250804",
        "denominacao": "ORGANIZAÇÃO LOGÍSTICA DO TRANSPORTE DE CARGA",
        "descricao": "SERVIÇOS DE PESQUISAS E DESENVOLVIMENTO DE QUALQUER NATUREZA",
        "item": "2",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11743",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7740300",
        "denominacao": "GESTAO DE ATIVOS INTANGIVEIS NAO-FINANCEIROS",
        "descricao": "SERVIÇOS DE CESSÃO DE DIREITO DE USO DE MARCAS E DE SINAIS DE PROPAGANDA",
        "item": "3",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9122",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9311500",
        "denominacao": "GESTAO DE INSTALACOES DE ESPORTES",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE QUADRAS ESPORTIVAS, ESTÁDIOS, GINÁSIOS E CANCHAS",
        "item": "3",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "9129",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9321200",
        "denominacao": "PARQUES DE DIVERSAO E PARQUES TEMATICOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE PARQUES DE DIVERSÕES",
        "item": "3",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "11710",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3311200",
        "denominacao": "MANUTENCAO E REPARACAO DE TANQUES, RESERVATORIOS METALICOS E CALDEIRAS, EXCETO PARA VEICULOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE CENTRO DE CONVENÇÕES OU STANDS",
        "item": "3",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "11709",
        "dtcancelamento": ""
    },
    {
        "subclasse": "0111301",
        "denominacao": "CULTIVO DE ARROZ",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE CENTRO DE CONVENÇÕES OU STANDS",
        "item": "3",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "11708",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9319101",
        "denominacao": "PRODUCAO E PROMOCAO DE EVENTOS ESPORTIVOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE QUADRAS ESPORTIVAS, ESTÁDIOS, GINÁSIOS E CANCHAS",
        "item": "3",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "11711",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9329801",
        "denominacao": "DISCOTECAS, DANCETERIAS, SALOES DE DANCA E SIMILARES",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE SALÕES DE FESTAS",
        "item": "3",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "11713",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9003500",
        "denominacao": "GESTAO DE ESPACOS PARA ARTES CENICAS, ESPETACULOS E OUTRAS ATIVIDADES ARTISTICAS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE SALÕES DE FESTAS, CENTRO DE CONVENÇÕES, ESCRITÓRIOS VIRTUAIS, STANDS, QUADRAS ESPORTIVAS, ESTÁDIOS, GINÁSIOS, AUDITÓRIOS, CASAS DE ESPETÁCULOS, PARQUES DE DIVERSÕES, CANCHAS E CONGÊNERES, PARA REALIZAÇÃO DE EVENTOS OU NEGÓCIOS DE QUALQUER NATUREZA",
        "item": "3",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "11798",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8230002",
        "denominacao": "CASAS DE FESTAS E EVENTOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE SALÕES DE FESTAS, CENTRO DE CONVENÇÕES, ESCRITÓRIOS VIRTUAIS, STANDS, QUADRAS ESPORTIVAS, ESTÁDIOS, GINÁSIOS, AUDITÓRIOS, CASAS DE ESPETÁCULOS, PARQUES DE DIVERSÕES, CANCHAS E CONGÊNERES, PARA REALIZAÇÃO DE EVENTOS OU NEGÓCIOS DE QUALQUER NATUREZA",
        "item": "3",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "11796",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8211300",
        "denominacao": "SERVIÇOS COMBINADOS DE ESCRITÓRIO E APOIO ADMINISTRATIVO",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE SALÕES DE FESTAS, CENTRO DE CONVENÇÕES, ESCRITÓRIOS VIRTUAIS, STANDS, QUADRAS ESPORTIVAS, ESTÁDIOS, GINÁSIOS, AUDITÓRIOS, CASAS DE ESPETÁCULOS, PARQUES DE DIVERSÕES, CANCHAS E CONGÊNERES, PARA REALIZAÇÃO DE EVENTOS OU NEGÓCIOS DE QUALQUER NATUREZA",
        "item": "3",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "11794",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6110801",
        "denominacao": "SERVIÇOS DE TELEFONIA FIXA COMUTADA - STFC",
        "descricao": "SERVIÇOS DE LOCAÇÃO, SUBLOCAÇÃO, ARRENDAMENTO, DIREITO DE PASSAGEM OU PERMISSÃO DE USO, COMPARTILHADO OU NÃO, DE FERROVIA, RODOVIA, POSTES, CABOS, DUTOS E CONDUTOS DE QUALQUER NATUREZA",
        "item": "3",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "11726",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9329899",
        "denominacao": "OUTRAS ATIVIDADES DE RECREACAO E LAZER NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE CESSÃO DE ANDAIMES, PALCOS, COBERTURAS E OUTRAS ESTRUTURAS DE USO TEMPORÁRIO",
        "item": "3",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9133",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7732202",
        "denominacao": "ALUGUEL DE ANDAIMES",
        "descricao": "SERVIÇOS DE CESSÃO DE ANDAIMES, PALCOS, COBERTURAS E OUTRAS ESTRUTURAS DE USO TEMPORÁRIO",
        "item": "3",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11766",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4399102",
        "denominacao": "MONTAGEM E DESMONTAGEM DE ANDAIMES E OUTRAS ESTRUTURAS TEMPORÁRIAS",
        "descricao": "SERVIÇOS DE CESSÃO DE ANDAIMES, PALCOS, COBERTURAS E OUTRAS ESTRUTURAS DE USO TEMPORÁRIO",
        "item": "3",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11765",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8610102",
        "denominacao": "ATIVIDADES DE ATENDIMENTO EM PRONTO-SOCORRO E UNIDADES HOSPITALARES PARA ATENDIMENTO A URGENCIAS",
        "descricao": "SERVIÇOS DE MEDICINA ",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "9134",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8621602",
        "denominacao": "SERVICOS MOVEIS DE ATENDIMENTO A URGENCIAS, EXCETO POR UTI MOVEL",
        "descricao": "SERVIÇOS DE MEDICINA ",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "9136",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8630506",
        "denominacao": "SERVICOS DE VACINACAO E IMUNIZACAO HUMANA",
        "descricao": "SERVIÇOS DE MEDICINA ",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "9138",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8630502",
        "denominacao": "ATIVIDADE MEDICA AMBULATORIAL COM RECURSOS PARA REALIZACAO DE EXAMES COMPLEMENTARES",
        "descricao": "SERVIÇOS DE MEDICINA ",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "9140",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8690901",
        "denominacao": "ATIVIDADES DE PRATICAS INTEGRATIVAS E COMPLEMENTARES EM SAUDE HUMANA",
        "descricao": "SERVIÇOS DE BIOMEDICINA",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "9142",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8630599",
        "denominacao": "ATIVIDADES DE ATENÇÃO AMBULATORIAL NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MEDICINA E BIOMEDICINA",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "11784",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8650003",
        "denominacao": "ATIVIDADES DE PSICOLOGIA E PSICANALISE",
        "descricao": "SERVIÇOS DE PSIQUIATRIA",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "9145",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8610101",
        "denominacao": "ATIVIDADES DE ATENDIMENTO HOSPITALAR, EXCETO PRONTO-SOCORRO E UNIDADES PARA ATENDIMENTO A URGENCIAS",
        "descricao": "SERVIÇOS DE ASSISTÊNCIA MÉDICO-HOSPITALAR",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "9144",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8610102",
        "denominacao": "ATIVIDADES DE ATENDIMENTO EM PRONTO-SOCORRO E UNIDADES HOSPITALARES PARA ATENDIMENTO A URGENCIAS",
        "descricao": "SERVIÇOS DE ASSISTÊNCIA MÉDICO-HOSPITALAR",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "9143",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8630501",
        "denominacao": "ATIVIDADE MEDICA AMBULATORIAL COM RECURSOS PARA REALIZACAO DE PROCEDIMENTOS CIRURGICOS",
        "descricao": "SERVIÇOS DE MEDICINA ",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "9141",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8630503",
        "denominacao": "ATIVIDADE MEDICA AMBULATORIAL RESTRITA A CONSULTAS",
        "descricao": "SERVIÇOS DE MEDICINA ",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "9139",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8621601",
        "denominacao": "UTI MOVEL",
        "descricao": "SERVIÇOS DE MEDICINA ",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "9137",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8610101",
        "denominacao": "ATIVIDADES DE ATENDIMENTO HOSPITALAR, EXCETO PRONTO-SOCORRO E UNIDADES PARA ATENDIMENTO A URGENCIAS",
        "descricao": "SERVIÇOS DE MEDICINA ",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "9135",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8640207",
        "denominacao": "SERVICOS DE DIAGNOSTICO POR IMAGEM SEM USO DE RADIACAO IONIZANTE, EXCETO RESSONANCIA MAGNETICA",
        "descricao": "SERVIÇOS DE ULTRA-SONOGRAFIA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "9146",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8640208",
        "denominacao": "SERVIÇOS DE DIAGNÓSTICO POR REGISTRO GRÁFICO - ECG, EEG E OUTROS EXAMES ANÁLOGOS",
        "descricao": "SERVIÇOS DE ANÁLISES CLÍNICAS, PATOLOGIA, ELETRICIDADE MÉDICA, RADIOTERAPIA, QUIMIOTERAPIA, ULTRA-SONOGRAFIA, RESSONÂNCIA MAGNÉTICA, RADIOLOGIA, TOMOGRAFIA E CONGÊNERES",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "11768",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8640209",
        "denominacao": "SERVIÇOS DE DIAGNÓSTICO POR MÉTODOS ÓPTICOS - ENDOSCOPIA E OUTROS EXAMES ANÁLOGOS",
        "descricao": "SERVIÇOS DE ANÁLISES CLÍNICAS, PATOLOGIA, ELETRICIDADE MÉDICA, RADIOTERAPIA, QUIMIOTERAPIA, ULTRA-SONOGRAFIA, RESSONÂNCIA MAGNÉTICA, RADIOLOGIA, TOMOGRAFIA E CONGÊNERES",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "11764",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8640211",
        "denominacao": "SERVIÇOS DE RADIOTERAPIA",
        "descricao": "ANÁLISES CLÍNICAS, PATOLOGIA, ELETRICIDADE MÉDICA, RADIOTERAPIA, QUIMIOTERAPIA, ULTRA-SONOGRAFIA, RESSONÂNCIA MAGNÉTICA, RADIOLOGIA, TOMOGRAFIA E CONGÊNERES",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "11763",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8640206",
        "denominacao": "SERVICOS DE RESSONANCIA MAGNETICA",
        "descricao": "SERVIÇOS DE RESSONÂNCIA MAGNÉTICA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "9156",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8640210",
        "denominacao": "SERVICOS DE QUIMIOTERAPIA",
        "descricao": "SERVIÇOS DE QUIMIOTERAPIA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "9155",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8640204",
        "denominacao": "SERVICOS DE TOMOGRAFIA",
        "descricao": "SERVIÇOS DE TOMOGRAFIA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "9154",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8640205",
        "denominacao": "SERVICOS DE DIAGNOSTICO POR IMAGEM COM USO DE RADIACAO IONIZANTE, EXCETO TOMOGRAFIA",
        "descricao": "SERVIÇOS DE RADIOTERAPIA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "9153",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8640205",
        "denominacao": "SERVICOS DE DIAGNOSTICO POR IMAGEM COM USO DE RADIACAO IONIZANTE, EXCETO TOMOGRAFIA",
        "descricao": "SERVIÇOS DE RADIOLOGIA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "9152",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8640204",
        "denominacao": "SERVICOS DE TOMOGRAFIA",
        "descricao": "SERVIÇOS DE ELETRECIDADE-MÉDICA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "9151",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8640202",
        "denominacao": "LABORATORIOS CLINICOS",
        "descricao": "SERVIÇOS DE ANÁLISES CLÍNICAS",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "9148",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8640205",
        "denominacao": "SERVICOS DE DIAGNOSTICO POR IMAGEM COM USO DE RADIACAO IONIZANTE, EXCETO TOMOGRAFIA",
        "descricao": "SERVIÇOS DE ABREUGRAFIA E RAIO X",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "9149",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8640202",
        "denominacao": "LABORATORIOS CLINICOS",
        "descricao": "SERVIÇOS DE PATOLOGIA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "9147",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8640205",
        "denominacao": "SERVICOS DE DIAGNOSTICO POR IMAGEM COM USO DE RADIACAO IONIZANTE, EXCETO TOMOGRAFIA",
        "descricao": "SERVIÇOS DE ELETRECIDADE-MÉDICA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "9150",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8640202",
        "denominacao": "LABORATORIOS CLINICOS",
        "descricao": "SERVIÇOS DE LABORATÓRIOS",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "9157",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8630503",
        "denominacao": "ATIVIDADE MEDICA AMBULATORIAL RESTRITA A CONSULTAS",
        "descricao": "SERVIÇOS AMBULATORIAIS",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "9158",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8630501",
        "denominacao": "ATIVIDADE MEDICA AMBULATORIAL COM RECURSOS PARA REALIZACAO DE PROCEDIMENTOS CIRURGICOS",
        "descricao": "SERVIÇOS AMBULATORIAIS",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "9160",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8630506",
        "denominacao": "SERVICOS DE VACINACAO E IMUNIZACAO HUMANA",
        "descricao": "SERVIÇOS DE VACINAÇÃO E IMUNIZAÇÃO HUMANA",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "9162",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8610101",
        "denominacao": "ATIVIDADES DE ATENDIMENTO HOSPITALAR, EXCETO PRONTO-SOCORRO E UNIDADES PARA ATENDIMENTO A URGENCIAS",
        "descricao": "SERVIÇOS DE MANICÔMIO",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "9164",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8610101",
        "denominacao": "ATIVIDADES DE ATENDIMENTO HOSPITALAR, EXCETO PRONTO-SOCORRO E UNIDADES PARA ATENDIMENTO A URGENCIAS",
        "descricao": "SERVIÇOS HOSPITALARES, DE CASA DE SAÚDE OU MATERNIDADE",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "9166",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8720499",
        "denominacao": "ATIVIDADES DE ASSISTENCIA PSICOSSOCIAL E A SAUDE A PORTADORES DE DISTURBIOS PSIQUICOS, DEFICIENCIA MENTAL E DEPENDENCIA QUIMICA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE REABILITAÇÃO DE DEPENDENTES QUÍMICOS",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "9168",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8610102",
        "denominacao": "ATIVIDADES DE ATENDIMENTO EM PRONTO-SOCORRO E UNIDADES HOSPITALARES PARA ATENDIMENTO A URGENCIAS",
        "descricao": "SERVIÇOS DE PRONTO-SOCORRO",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "9167",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8610101",
        "denominacao": "ATIVIDADES DE ATENDIMENTO HOSPITALAR, EXCETO PRONTO-SOCORRO E UNIDADES PARA ATENDIMENTO A URGENCIAS",
        "descricao": "SERVIÇOS DE SANATÓRIOS",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "9165",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8640203",
        "denominacao": "SERVICOS DE DIALISE E NEFROLOGIA",
        "descricao": "SERVIÇOS DE DIÁLISE",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "9163",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8610101",
        "denominacao": "ATIVIDADES DE ATENDIMENTO HOSPITALAR, EXCETO PRONTO-SOCORRO E UNIDADES PARA ATENDIMENTO A URGENCIAS",
        "descricao": "SERVIÇOS AMBULATORIAIS",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "9161",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8630502",
        "denominacao": "ATIVIDADE MEDICA AMBULATORIAL COM RECURSOS PARA REALIZACAO DE EXAMES COMPLEMENTARES",
        "descricao": "SERVIÇOS AMBULATORIAIS",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "9159",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8650099",
        "denominacao": "ATIVIDADES DE PROFISSIONAIS DA AREA DE SAUDE NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE INSTRUMENTAÇÃO CIRÚRGICA",
        "item": "4",
        "subitem": "4",
        "aliquota": "2",
        "idcnae": "9169",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8690999",
        "denominacao": "OUTRAS ATIVIDADES DE ATENCAO A SAUDE HUMANA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE INSTRUMENTAÇÃO CIRÚRGICA",
        "item": "4",
        "subitem": "4",
        "aliquota": "2",
        "idcnae": "9170",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8690901",
        "denominacao": "ATIVIDADES DE PRATICAS INTEGRATIVAS E COMPLEMENTARES EM SAUDE HUMANA",
        "descricao": "SERVIÇOS DE ACUPUNTURA",
        "item": "4",
        "subitem": "5",
        "aliquota": "2",
        "idcnae": "9171",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8650001",
        "denominacao": "ATIVIDADES DE ENFERMAGEM",
        "descricao": "SERVIÇOS DE ENFERMAGEM",
        "item": "4",
        "subitem": "6",
        "aliquota": "2",
        "idcnae": "9172",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8690999",
        "denominacao": "OUTRAS ATIVIDADES DE ATENCAO A SAUDE HUMANA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ESTERELIZAÇÃO",
        "item": "4",
        "subitem": "6",
        "aliquota": "2",
        "idcnae": "9173",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8650099",
        "denominacao": "ATIVIDADES DE PROFISSIONAIS DA AREA DE SAUDE NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS FARMACÊUTICOS",
        "item": "4",
        "subitem": "7",
        "aliquota": "2",
        "idcnae": "9174",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4771702",
        "denominacao": "COMÉRCIO VAREJISTA DE PRODUTOS FARMACÊUTICOS, COM MANIPULAÇÃO DE FÓRMULAS.",
        "descricao": "SERVIÇOS FARMACÊUTICOS",
        "item": "4",
        "subitem": "7",
        "aliquota": "2",
        "idcnae": "11715",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8650006",
        "denominacao": "ATIVIDADES DE FONOAUDIOLOGIA",
        "descricao": "SERVIÇOS DE REABILITAÇAO DA FALA OU AUDIÇÃO (CLÍNICA)",
        "item": "4",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "9176",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8650005",
        "denominacao": "ATIVIDADES DE TERAPIA OCUPACIONAL",
        "descricao": "SERVIÇOS DE TERAPIA OCUPACIONAL",
        "item": "4",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "9185",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8650006",
        "denominacao": "ATIVIDADES DE FONOAUDIOLOGIA",
        "descricao": "SERVIÇOS DE FONOAUDIOLOGIA",
        "item": "4",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "9186",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8650003",
        "denominacao": "ATIVIDADES DE PSICOLOGIA E PSICANALISE",
        "descricao": "SERVIÇOS DE PSICOTERAPIA",
        "item": "4",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "9184",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8650004",
        "denominacao": "ATIVIDADES DE FISIOTERAPIA",
        "descricao": "SERVIÇOS DE FISIOTERAPIA",
        "item": "4",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "9183",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8650007",
        "denominacao": "ATIVIDADES DE TERAPIA DE NUTRICAO ENTERAL E PARENTERAL",
        "descricao": "SERVIÇOS DE TERAPIA DE NUTRIÇÃO ENTERAL E PARENTERAL.",
        "item": "4",
        "subitem": "9",
        "aliquota": "2",
        "idcnae": "9187",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8690901",
        "denominacao": "ATIVIDADES DE PRATICAS INTEGRATIVAS E COMPLEMENTARES EM SAUDE HUMANA",
        "descricao": "SERVIÇOS DE BIOTERAPIA",
        "item": "4",
        "subitem": "9",
        "aliquota": "2",
        "idcnae": "9188",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8650007",
        "denominacao": "ATIVIDADES DE TERAPIA DE NUTRICAO ENTERAL E PARENTERAL",
        "descricao": "SERVIÇOS DE NUTRIÇÃO",
        "item": "4",
        "subitem": "10",
        "aliquota": "2",
        "idcnae": "9112",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8650002",
        "denominacao": "ATIVIDADES DE PROFISSIONAIS DA NUTRICAO",
        "descricao": "SERVIÇOS DE NUTRIÇÃO",
        "item": "4",
        "subitem": "10",
        "aliquota": "2",
        "idcnae": "9113",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8630503",
        "denominacao": "ATIVIDADE MEDICA AMBULATORIAL RESTRITA A CONSULTAS",
        "descricao": "SERVIÇOS DE OBSTETRÍCIA",
        "item": "4",
        "subitem": "11",
        "aliquota": "2",
        "idcnae": "9196",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8630502",
        "denominacao": "ATIVIDADE MEDICA AMBULATORIAL COM RECURSOS PARA REALIZACAO DE EXAMES COMPLEMENTARES",
        "descricao": "SERVIÇOS DE OBSTETRÍCIA",
        "item": "4",
        "subitem": "11",
        "aliquota": "2",
        "idcnae": "9197",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8630501",
        "denominacao": "ATIVIDADE MEDICA AMBULATORIAL COM RECURSOS PARA REALIZACAO DE PROCEDIMENTOS CIRURGICOS",
        "descricao": "SERVIÇOS DE OBSTETRÍCIA",
        "item": "4",
        "subitem": "11",
        "aliquota": "2",
        "idcnae": "9198",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8610101",
        "denominacao": "ATIVIDADES DE ATENDIMENTO HOSPITALAR, EXCETO PRONTO-SOCORRO E UNIDADES PARA ATENDIMENTO A URGENCIAS",
        "descricao": "SERVIÇOS DE OBSTETRÍCIA",
        "item": "4",
        "subitem": "11",
        "aliquota": "2",
        "idcnae": "9199",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8513802",
        "denominacao": "ATIVIDADES DE CLÍNICA ODONTOLÓGICA (CLÍNICAS, CONSULTÓRIOS E AMBULATÓRIOS)",
        "descricao": "SERVIÇOS ODONTOLÓGICOS",
        "item": "4",
        "subitem": "12",
        "aliquota": "2",
        "idcnae": "1464",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8513802",
        "denominacao": "ATIVIDADES DE CLÍNICA ODONTOLÓGICA (CLÍNICAS, CONSULTÓRIOS E AMBULATÓRIOS)",
        "descricao": "SERVIÇOS DE ASSISTÊNCIA ODONTOLÓGICA (SERVIÇOS ODONTOLÓGICOS)",
        "item": "4",
        "subitem": "12",
        "aliquota": "2",
        "idcnae": "1465",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8630504",
        "denominacao": "ATIVIDADE ODONTOLOGICA",
        "descricao": "SERVIÇOS DE ASSISTÊNCIA ODONTOLÓGICA (SERVIÇOS ODONTOLÓGICOS)",
        "item": "4",
        "subitem": "12",
        "aliquota": "2",
        "idcnae": "9201",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8630504",
        "denominacao": "ATIVIDADE ODONTOLOGICA",
        "descricao": "SERVIÇOS ODONTOLÓGICOS",
        "item": "4",
        "subitem": "12",
        "aliquota": "2",
        "idcnae": "9200",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8610101",
        "denominacao": "ATIVIDADES DE ATENDIMENTO HOSPITALAR, EXCETO PRONTO-SOCORRO E UNIDADES PARA ATENDIMENTO A URGENCIAS",
        "descricao": "SERVIÇOS DE ORTÓPTICA",
        "item": "4",
        "subitem": "13",
        "aliquota": "2",
        "idcnae": "9202",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8630501",
        "denominacao": "ATIVIDADE MEDICA AMBULATORIAL COM RECURSOS PARA REALIZACAO DE PROCEDIMENTOS CIRURGICOS",
        "descricao": "SERVIÇOS DE ORTÓPTICA",
        "item": "4",
        "subitem": "13",
        "aliquota": "2",
        "idcnae": "9205",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8630502",
        "denominacao": "ATIVIDADE MEDICA AMBULATORIAL COM RECURSOS PARA REALIZACAO DE EXAMES COMPLEMENTARES",
        "descricao": "SERVIÇOS DE ORTÓPTICA",
        "item": "4",
        "subitem": "13",
        "aliquota": "2",
        "idcnae": "9204",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8630503",
        "denominacao": "ATIVIDADE MEDICA AMBULATORIAL RESTRITA A CONSULTAS",
        "descricao": "SERVIÇOS DE ORTÓPTICA",
        "item": "4",
        "subitem": "13",
        "aliquota": "2",
        "idcnae": "9203",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3310305",
        "denominacao": "SERVIÇOS DE PRÓTESE DENTÁRIA",
        "descricao": "PROTÉTICO (SERVIÇOS DE PRÓTESE DENTÁRIA)",
        "item": "4",
        "subitem": "14",
        "aliquota": "2",
        "idcnae": "118",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3250706",
        "denominacao": "SERVICOS DE PROTESE DENTARIA",
        "descricao": "SERVIÇOS DE PRÓTESES",
        "item": "4",
        "subitem": "14",
        "aliquota": "2",
        "idcnae": "9206",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3250703",
        "denominacao": "FABRICACAO DE APARELHOS E UTENSILIOS PARA CORRECAO DE DEFEITOS FISICOS E APARELHOS ORTOPEDICOS EM GERAL SOB ENCOMENDA",
        "descricao": "SERVIÇOS DE PRÓTESES",
        "item": "4",
        "subitem": "14",
        "aliquota": "2",
        "idcnae": "9207",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3310305",
        "denominacao": "SERVIÇOS DE PRÓTESE DENTÁRIA",
        "descricao": "SERVIÇOS DE PRÓTESES",
        "item": "4",
        "subitem": "14",
        "aliquota": "2",
        "idcnae": "119",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8650003",
        "denominacao": "ATIVIDADES DE PSICOLOGIA E PSICANALISE",
        "descricao": "SERVIÇOS DE PSICANÁLISE",
        "item": "4",
        "subitem": "15",
        "aliquota": "2",
        "idcnae": "9208",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8650003",
        "denominacao": "ATIVIDADES DE PSICOLOGIA E PSICANALISE",
        "descricao": "SERVIÇOS DE PSICOLOGIA",
        "item": "4",
        "subitem": "16",
        "aliquota": "2",
        "idcnae": "9209",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8711502",
        "denominacao": "INSTITUICOES DE LONGA PERMANENCIA PARA IDOSOS",
        "descricao": "SERVIÇOS DE ASILOS",
        "item": "4",
        "subitem": "17",
        "aliquota": "2",
        "idcnae": "9210",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8511200",
        "denominacao": "EDUCACAO INFANTIL - CRECHE",
        "descricao": "SERVIOS DE CRECHES",
        "item": "4",
        "subitem": "17",
        "aliquota": "2",
        "idcnae": "9211",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8730102",
        "denominacao": "ALBERGUES ASSISTENCIAIS",
        "descricao": "SERVIÇOS DE ALBERGUES ASSISTENCIAIS",
        "item": "4",
        "subitem": "17",
        "aliquota": "2",
        "idcnae": "9212",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8711501",
        "denominacao": "CLINICAS E RESIDENCIAS GERIATRICAS",
        "descricao": "SERVIÇOS DE CASA DE REPOUSO OU RECUPERAÇÃO",
        "item": "4",
        "subitem": "17",
        "aliquota": "2",
        "idcnae": "9214",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8711503",
        "denominacao": "ATIVIDADES DE ASSISTÊNCIA A DEFICIENTES FÍSICOS, IMUNODEPRIMIDOS E CONVALESCENTES",
        "descricao": "SERVIÇOS DE CASAS DE REPOUSO E DE RECUPERAÇÃO, CRECHES, ASILOS E CONGÊNERES",
        "item": "4",
        "subitem": "17",
        "aliquota": "2",
        "idcnae": "11799",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8711505",
        "denominacao": "CONDOMÍNIOS RESIDENCIAIS PARA IDOSOS",
        "descricao": "SERVIÇOS DE CASAS DE REPOUSO E DE RECUPERAÇÃO, CRECHES, ASILOS E CONGÊNERES",
        "item": "4",
        "subitem": "17",
        "aliquota": "2",
        "idcnae": "11756",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8720499",
        "denominacao": "ATIVIDADES DE ASSISTENCIA PSICOSSOCIAL E A SAUDE A PORTADORES DE DISTURBIOS PSIQUICOS, DEFICIENCIA MENTAL E DEPENDENCIA QUIMICA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE REABILITAÇÃO DE DEPENDENTES QUÍMICOS COM ALOJAMENTO",
        "item": "4",
        "subitem": "17",
        "aliquota": "2",
        "idcnae": "9213",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8630507",
        "denominacao": "ATIVIDADES DE REPRODUCAO HUMANA ASSISTIDA",
        "descricao": "SERVIÇOS DE INSEMINAÇÃO ARTIFICIAL OU FERTILIZAÇÃO IN VITRO",
        "item": "4",
        "subitem": "18",
        "aliquota": "2",
        "idcnae": "11760",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8640212",
        "denominacao": "SERVICOS DE HEMOTERAPIA",
        "descricao": "SERVIÇOS DE BANCO DE SANGUE",
        "item": "4",
        "subitem": "19",
        "aliquota": "2",
        "idcnae": "9215",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8640214",
        "denominacao": "SERVICOS DE BANCOS DE CELULAS E TECIDOS HUMANOS",
        "descricao": "SERVIÇOS DE BANCO DE OLHOS",
        "item": "4",
        "subitem": "19",
        "aliquota": "2",
        "idcnae": "9218",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8640214",
        "denominacao": "SERVICOS DE BANCOS DE CELULAS E TECIDOS HUMANOS",
        "descricao": "SERVIÇOS DE BANCO DE PELE",
        "item": "4",
        "subitem": "19",
        "aliquota": "2",
        "idcnae": "9219",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8630507",
        "denominacao": "ATIVIDADES DE REPRODUCAO HUMANA ASSISTIDA",
        "descricao": "SERVIÇOS DE BANCO DE SÊMEN",
        "item": "4",
        "subitem": "19",
        "aliquota": "2",
        "idcnae": "9217",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8690902",
        "denominacao": "ATIVIDADES DE BANCOS DE LEITE HUMANO",
        "descricao": "SERVIÇOS DE BANCO DE LEITE",
        "item": "4",
        "subitem": "19",
        "aliquota": "2",
        "idcnae": "9216",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8630507",
        "denominacao": "ATIVIDADES DE REPRODUCAO HUMANA ASSISTIDA",
        "descricao": "SERVIÇOS DE BANCO DE ÓVULOS",
        "item": "4",
        "subitem": "19",
        "aliquota": "2",
        "idcnae": "9220",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8610101",
        "denominacao": "ATIVIDADES DE ATENDIMENTO HOSPITALAR, EXCETO PRONTO-SOCORRO E UNIDADES PARA ATENDIMENTO A URGENCIAS",
        "descricao": "SERVIÇOS DE REMOÇÕES DE ÓRGÃOS",
        "item": "4",
        "subitem": "20",
        "aliquota": "2",
        "idcnae": "9221",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8621602",
        "denominacao": "SERVICOS MOVEIS DE ATENDIMENTO A URGENCIAS, EXCETO POR UTI MOVEL",
        "descricao": "SERVIÇOS DE ASSISTÊNCIA, ATENDIMENTO OU TRATAMENTO MÓVEL",
        "item": "4",
        "subitem": "21",
        "aliquota": "2",
        "idcnae": "9222",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8621601",
        "denominacao": "UTI MOVEL",
        "descricao": "SERVIÇOS DE ASSISTÊNCIA, ATENDIMENTO OU TRATAMENTO MÓVEL",
        "item": "4",
        "subitem": "21",
        "aliquota": "2",
        "idcnae": "9223",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6550200",
        "denominacao": "PLANOS DE SAUDE",
        "descricao": "CONVÊNIO PARA PRESTAÇÃO DE ASSISTÊNCIA MÉDICA, HOSPITALAR E ODONTOLÓGICA",
        "item": "4",
        "subitem": "22",
        "aliquota": "2",
        "idcnae": "9224",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6550200",
        "denominacao": "PLANOS DE SAUDE",
        "descricao": "PLANOS DE MEDICINA DE GRUPO OU INDIVIDUAL",
        "item": "4",
        "subitem": "22",
        "aliquota": "2",
        "idcnae": "9225",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6550200",
        "denominacao": "PLANOS DE SAUDE",
        "descricao": "OUTROS PLANOS DE SAÚDE",
        "item": "4",
        "subitem": "23",
        "aliquota": "2",
        "idcnae": "9226",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7490103",
        "denominacao": "SERVICOS DE AGRONOMIA E DE CONSULTORIA AS ATIVIDADES AGRICOLAS E PECUARIAS",
        "descricao": "SERVIÇOS DE ASSESSORIA ÀS ATIVIDADES PECUÁRIAS",
        "item": "5",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9227",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7500100",
        "denominacao": "ATIVIDADES VETERINARIAS",
        "descricao": "SERVIÇOS DE ASSESSORIA ÀS ATIVIDADES PECUÁRIAS",
        "item": "5",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9228",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7500100",
        "denominacao": "ATIVIDADES VETERINARIAS",
        "descricao": "VETERINÁRIO (SERVIÇOS DE MEDICINA VETERINÁRIA)",
        "item": "5",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9230",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7500100",
        "denominacao": "ATIVIDADES VETERINARIAS",
        "descricao": "SERVIÇOS DE MEDICINA VETERINÁRIA E ZOOTECNIA",
        "item": "5",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9229",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7500100",
        "denominacao": "ATIVIDADES VETERINARIAS",
        "descricao": "SERVIÇOS AMBULATORIAIS - VETERINÁRIOS",
        "item": "5",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9231",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7500100",
        "denominacao": "ATIVIDADES VETERINARIAS",
        "descricao": "SERVIÇOS VETERINÁRIOS (CLÍNICA VETERINÁRIA)",
        "item": "5",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9234",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7500100",
        "denominacao": "ATIVIDADES VETERINARIAS",
        "descricao": "SERVIÇOS DE PRONTOS-SOCORROS - VETERINÁRIOS",
        "item": "5",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9233",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7500100",
        "denominacao": "ATIVIDADES VETERINARIAS",
        "descricao": "SERVIÇOS DE HOSPITALARES VETERINÁRIOS",
        "item": "5",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9232",
        "dtcancelamento": ""
    },
    {
        "subclasse": "0162801",
        "denominacao": "SERVICO DE INSEMINACAO ARTIFICIAL EM ANIMAIS",
        "descricao": "SERVIÇOS DE INSEMINAÇÃO ARTIFICIAL OU FERTILIZAÇÃO IN VITRO",
        "item": "5",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9235",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8630507",
        "denominacao": "ATIVIDADES DE REPRODUCAO HUMANA ASSISTIDA",
        "descricao": "SERVIÇOS DE INSEMINAÇÃO ARTIFICIAL OU FERTILIZAÇÃO IN VITRO",
        "item": "5",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9236",
        "dtcancelamento": ""
    },
    {
        "subclasse": "0162899",
        "denominacao": "ATIVIDADES DE APOIO A PECUARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE BANCO DE SANGUE E ÓRGÃOS DE ORIGEM ANIMAL",
        "item": "5",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9237",
        "dtcancelamento": ""
    },
    {
        "subclasse": "0162899",
        "denominacao": "ATIVIDADES DE APOIO A PECUARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COLETA DE SANGUE, LEITE, TECIDOS, SÊMEN, ÓRGÃOS E MATERIAIS BIOLÓGICOS DE QUALQUER ESPÉCIE, DE ORIGEM ANIMAL",
        "item": "5",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9238",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7500100",
        "denominacao": "ATIVIDADES VETERINARIAS",
        "descricao": "SERVIÇOS DE ATENDIMENTO, ASSISTÊNCIA OU  TRATAMENTO MÓVEL À ANIMAIS",
        "item": "5",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9239",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9609203",
        "denominacao": "ALOJAMENTO, HIGIENE E EMBELEZAMENTO DE ANIMAIS",
        "descricao": "SERVIÇOS DE ADESTRAMENTO DE ANIMAIS",
        "item": "5",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9240",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9609203",
        "denominacao": "ALOJAMENTO, HIGIENE E EMBELEZAMENTO DE ANIMAIS",
        "descricao": "SERVIÇOS DE GUARDA E ALOJAMENTO DE ANIMAIS",
        "item": "5",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9243",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8011102",
        "denominacao": "SERVICOS DE ADESTRAMENTO DE CAES DE GUARDA",
        "descricao": "SERVIÇOS DE ADESTRAMENTO DE ANIMAIS",
        "item": "5",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9241",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9609203",
        "denominacao": "ALOJAMENTO, HIGIENE E EMBELEZAMENTO DE ANIMAIS",
        "descricao": "SERVIÇOS DE EMBELEZAMENTO DE ANIMAIS",
        "item": "5",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9242",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9609203",
        "denominacao": "ALOJAMENTO, HIGIENE E EMBELEZAMENTO DE ANIMAIS",
        "descricao": "SERVIÇOS DE TOSQUIA",
        "item": "5",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9244",
        "dtcancelamento": ""
    },
    {
        "subclasse": "0162899",
        "denominacao": "ATIVIDADES DE APOIO A PECUARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS TRATAMENTO DE ANIMAIS",
        "item": "5",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9246",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9609208",
        "denominacao": "HIGIENE E EMBELEZAMENTO DE ANIMAIS DOMÉSTICOS",
        "descricao": "GUARDA, TRATAMENTO, AMESTRAMENTO, EMBELEZAMENTO, ALOJAMENTO E CONGÊNERES.",
        "item": "5",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "11741",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9609207",
        "denominacao": "ALOJAMENTO DE ANIMAIS DOMÉSTICOS",
        "descricao": "GUARDA, TRATAMENTO, AMESTRAMENTO, EMBELEZAMENTO, ALOJAMENTO E CONGÊNERES",
        "item": "5",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "11740",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9609203",
        "denominacao": "ALOJAMENTO, HIGIENE E EMBELEZAMENTO DE ANIMAIS",
        "descricao": "SERVIÇOS DE TRATAMENTO DE ANIMAIS",
        "item": "5",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "11722",
        "dtcancelamento": ""
    },
    {
        "subclasse": "0162802",
        "denominacao": "SERVICO DE TOSQUIAMENTO DE OVINOS",
        "descricao": "SERVIÇOS DE TOSQUIA",
        "item": "5",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9245",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6550200",
        "denominacao": "PLANOS DE SAUDE",
        "descricao": "PLANOS DE ATENDIMENTO E ASSITÊNCIA MÉDICO VETERINÁRIA",
        "item": "5",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "9247",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9602501",
        "denominacao": "CABELEIREIROS",
        "descricao": "SERVIÇOS DE CABELEIREIROS",
        "item": "6",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9248",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8690904",
        "denominacao": "ATIVIDADES DE PODOLOGIA",
        "descricao": "SERVIÇOS DE PODOLOGIA",
        "item": "6",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11742",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9602501",
        "denominacao": "CABELEIREIROS",
        "descricao": "SERVIÇOS DE BARBEARIA",
        "item": "6",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9249",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9609206",
        "denominacao": "SERVIÇOS DE TATUAGEM E COLOCAÇÃO DE PIERCING",
        "descricao": "SERVIÇOS DE TATUAGEM E COLOCAÇÃO DE PIERCING",
        "item": "6",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11716",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9602502",
        "denominacao": "ATIVIDADES DE ESTÉTICA E OUTROS SERVIÇOS DE CUIDADOS COM A BELEZA",
        "descricao": "SERVIÇOS DE MANICURI E PEDICURI",
        "item": "6",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9250",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9602502",
        "denominacao": "ATIVIDADES DE ESTÉTICA E OUTROS SERVIÇOS DE CUIDADOS COM A BELEZA",
        "descricao": "SERVIÇOS DE DEPILAÇÃO",
        "item": "6",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9251",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9602502",
        "denominacao": "ATIVIDADES DE ESTÉTICA E OUTROS SERVIÇOS DE CUIDADOS COM A BELEZA",
        "descricao": "SERVIÇOS DE ESTÉTICA",
        "item": "6",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9252",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9602502",
        "denominacao": "ATIVIDADES DE ESTÉTICA E OUTROS SERVIÇOS DE CUIDADOS COM A BELEZA",
        "descricao": "SERVIÇOS DE TRATAMENTO DE PELE",
        "item": "6",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9253",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9609201",
        "denominacao": "CLINICAS DE ESTETICA E SIMILARES",
        "descricao": "SERVIÇOS DE SAUNA, DUCHAS, BANHO E MASSAGENS",
        "item": "6",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9254",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8712300",
        "denominacao": "ATIVIDADES DE FORNECIMENTO DE INFRA-ESTRUTURA DE APOIO E ASSISTÊNCIA A PACIENTE NO DOMICÍLIO",
        "descricao": "SERVIÇOS DE BANHOS, DUCHAS, SAUNA, MASSAGENS E CONGÊNERES",
        "item": "6",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11735",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9609299",
        "denominacao": "OUTRAS ATIVIDADES DE SERVIÇOS PESSOAIS NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE BANHOS, DUCHAS, SAUNA, MASSAGENS E CONGÊNERES",
        "item": "6",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11777",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8592901",
        "denominacao": "ENSINO DE DANCA",
        "descricao": "SERVIÇOS DE ACADEMIA DE DANÇA",
        "item": "6",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9255",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9313100",
        "denominacao": "ATIVIDADES DE CONDICIONAMENTO FISICO",
        "descricao": "SERVIÇOS DE ACADEMIA DE GINÁSTICA",
        "item": "6",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9258",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8591100",
        "denominacao": "ENSINO DE ESPORTES",
        "descricao": "SERVIÇOS DE ACADEMIA DE NATAÇÃO",
        "item": "6",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9259",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8591100",
        "denominacao": "ENSINO DE ESPORTES",
        "descricao": "SERVIÇOS DE ACADEMIA DE ESPOSTES",
        "item": "6",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9257",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8591100",
        "denominacao": "ENSINO DE ESPORTES",
        "descricao": "SERVIÇOS DE ACADEMIA DE ARTES MARCIAIS",
        "item": "6",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9256",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9313100",
        "denominacao": "ATIVIDADES DE CONDICIONAMENTO FISICO",
        "descricao": "SERVIÇOS DE CONDICIONAMENTO FÍSICO CORPORAL",
        "item": "6",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9260",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9609201",
        "denominacao": "CLINICAS DE ESTETICA E SIMILARES",
        "descricao": "SERVIÇOS DE ORIENTAÇÃO PARA EMAGRECIMENTO, SPA E CONGÊNERE",
        "item": "6",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9107",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9313100",
        "denominacao": "ATIVIDADES DE CONDICIONAMENTO FISICO",
        "descricao": "SERVIÇOS DE ORIENTAÇÃO PARA EMAGRECIMENTO, SPA E CONGÊNERE",
        "item": "6",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9108",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7111100",
        "denominacao": "SERVICOS DE ARQUITETURA",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE PROJETOS DE URBANIZAÇÃO OU LOTEAMENTO",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "9109",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS DE ENGENHARIA",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "9111",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7119702",
        "denominacao": "ATIVIDADES DE ESTUDOS GEOLOGICOS",
        "descricao": "SERVIÇOS DE GEOLOGIA",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "9270",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7490103",
        "denominacao": "SERVICOS DE AGRONOMIA E DE CONSULTORIA AS ATIVIDADES AGRICOLAS E PECUARIAS",
        "descricao": "SERVIÇOS DE EXTENSÃO RURAL",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "9272",
        "dtcancelamento": ""
    },
    {
        "subclasse": "0311604",
        "denominacao": "ATIVIDADES DE APOIO A PESCA EM AGUA SALGADA",
        "descricao": "SERVIÇO DE EXTENSÃO DE PESCA",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "9274",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7410203",
        "denominacao": "DESIGN DE PRODUTOS",
        "descricao": "SERVIÇOS DE DESENHO INDUSTRIAL",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "11736",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3314707 ",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E APARELHOS DE REFRIGERAÇÃO E VENTILAÇÃO PARA USO INDUSTRIAL E COMERCIAL",
        "descricao": "APARELHOS DE REFRIGERAÇÃO E VENTILAÇÃO DE USO INDUSTRIAL, N.E., MANUTENÇÃO E REPARAÇÃO EXECUTADA POR UNIDADE ESPECIALIZADA",
        "item": "7",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11818",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7490103",
        "denominacao": "SERVICOS DE AGRONOMIA E DE CONSULTORIA AS ATIVIDADES AGRICOLAS E PECUARIAS",
        "descricao": "SERVIÇOS DE ASSESSORIA ÀS ATIVIDADES AGRÍCOLAS",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "9275",
        "dtcancelamento": ""
    },
    {
        "subclasse": "0312404",
        "denominacao": "ATIVIDADES DE APOIO A PESCA EM AGUA DOCE",
        "descricao": "SERVIÇO DE EXTENSÃO DE PESCA",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "9273",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7490103",
        "denominacao": "SERVICOS DE AGRONOMIA E DE CONSULTORIA AS ATIVIDADES AGRICOLAS E PECUARIAS",
        "descricao": "SERVIÇOS DE AGRONOMIA",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "9271",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7119701",
        "denominacao": "SERVICOS DE CARTOGRAFIA, TOPOGRAFIA E GEODESIA",
        "descricao": "SERVIÇOS DE AGRIMENSURA",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "9269",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8130300",
        "denominacao": "ATIVIDADES PAISAGISTICAS",
        "descricao": "SERVIÇOS DE PAISAGISMO",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "9192",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7111100",
        "denominacao": "SERVICOS DE ARQUITETURA",
        "descricao": "SERVIÇOS DE ARQUITETURA",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "9110",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4531402",
        "denominacao": "CONSTRUÇÃO DE ESTAÇÕES E REDES DE DISTRIBUIÇÃO DE ENERGIA ELÉTRICA",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO CIVIL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "91",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3314707",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E APARELHOS DE REFRIGERAÇÃO E VENTILAÇÃO PARA USO INDUSTRIAL E COMERCIAL",
        "descricao": " \tASPIRADORES INDUSTRIAIS, MANUTENÇÃO E REPARAÇÃO EXECUTADA POR UNIDADE ESPECIALIZADA",
        "item": "7",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "11817",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4399103",
        "denominacao": "OBRAS DE ALVENARIA",
        "descricao": "SERVIÇOS DE EXECUÇÃO, POR ADMINISTRAÇÃO, EMPREITADA OU SUBEMPREITADA, DE OBRAS DE CONSTRUÇÃO CIVIL, HIDRÁULICA OU ELÉTRICA E DE OUTRAS OBRAS SEMELHANTES, INCLUSIVE SONDAGEM, PERFURAÇÃO DE POÇOS, ESCAVAÇÃO, DRENAGEM E IRRIGAÇÃO, TERRAPLANAGEM, PAVIMENTAÇÃO, CONCRETAGEM E A INSTALAÇÃO E MONTAGEM DE PRODUTOS, PEÇAS E EQUIPAMENTOS (EXCETO O FORNECIMENTO DE MERCADORIAS PRODUZIDAS PELO PRESTADOR DE SERVIÇOS FORA DO LOCAL DA PRESTAÇÃO DOS SERVIÇOS, QUE FICA SUJEITO AO ICMS)",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11769",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4213800",
        "denominacao": "OBRAS DE URBANIZAÇÃO - RUAS, PRAÇAS E CALÇADAS",
        "descricao": "SERVIÇOS DE EXECUÇÃO, POR ADMINISTRAÇÃO, EMPREITADA OU SUBEMPREITADA, DE OBRAS DE CONSTRUÇÃO CIVIL, HIDRÁULICA OU ELÉTRICA E DE OUTRAS OBRAS SEMELHANTES, INCLUSIVE SONDAGEM, PERFURAÇÃO DE POÇOS, ESCAVAÇÃO, DRENAGEM E IRRIGAÇÃO, TERRAPLANAGEM, PAVIMENTAÇÃO, CONCRETAGEM E A INSTALAÇÃO E MONTAGEM DE PRODUTOS, PEÇAS E EQUIPAMENTOS (EXCETO O FORNECIMENTO DE MERCADORIAS PRODUZIDAS PELO PRESTADOR DE SERVIÇOS FORA DO LOCAL DA PRESTAÇÃO DOS SERVIÇOS, QUE FICA SUJEITO AO ICMS)",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11750",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4311802",
        "denominacao": "PREPARAÇÃO DE CANTEIRO E LIMPEZA DE TERRENO   ",
        "descricao": "SERVIÇOS DE EXECUÇÃO, POR ADMINISTRAÇÃO, EMPREITADA OU SUBEMPREITADA, DE OBRAS DE CONSTRUÇÃO CIVIL, HIDRÁULICA OU ELÉTRICA E DE OUTRAS OBRAS SEMELHANTES, INCLUSIVE SONDAGEM, PERFURAÇÃO DE POÇOS, ESCAVAÇÃO, DRENAGEM E IRRIGAÇÃO, TERRAPLANAGEM, PAVIMENTAÇÃO, CONCRETAGEM E A INSTALAÇÃO E MONTAGEM DE PRODUTOS, PEÇAS E EQUIPAMENTOS (EXCETO O FORNECIMENTO DE MERCADORIAS PRODUZIDAS PELO PRESTADOR DE SERVIÇOS FORA DO LOCAL DA PRESTAÇÃO DOS SERVIÇOS, QUE FICA SUJEITO AO ICMS)",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11749",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4399104",
        "denominacao": "SERVIÇOS DE OPERAÇÃO E FORNECIMENTO DE EQUIPAMENTOS PARA TRANSPORTE E ELEVAÇÃO DE CARGAS E PESSOAS PARA USO EM OBRAS",
        "descricao": "SERVIÇOS DE EXECUÇÃO, POR ADMINISTRAÇÃO, EMPREITADA OU SUBEMPREITADA, DE OBRAS DE CONSTRUÇÃO CIVIL, ...",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11730",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4299501",
        "denominacao": "CONSTRUÇÃO DE INSTALAÇÕES ESPORTIVAS E RECREATIVAS",
        "descricao": "SERVIÇOS DE EXECUÇÃO, POR ADMINISTRAÇÃO, EMPREITADA OU SUBEMPREITADA, DE OBRAS DE CONSTRUÇÃO CIVIL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11729",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4330402",
        "denominacao": "INSTALACAO DE PORTAS, JANELAS, TETOS, DIVISORIAS E ARMARIOS EMBUTIDOS DE QUALQUER MATERIAL",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE ESQUADRIAS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9316",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4330401",
        "denominacao": "IMPERMEABILIZACAO EM OBRAS DE ENGENHARIA CIVIL",
        "descricao": "SERVIÇOS DE IMPERMEABILIZAÇÃO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9315",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4330499",
        "denominacao": "OUTRAS OBRAS DE ACABAMENTO DA CONSTRUCAO",
        "descricao": "SERVIÇOS DE OBRAS DE ALVENARIA E REBOCO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9314",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4330499",
        "denominacao": "OUTRAS OBRAS DE ACABAMENTO DA CONSTRUCAO",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE PISCINAS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9313",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4322302",
        "denominacao": "INSTALACAO E MANUTENCAO DE SISTEMAS CENTRAIS DE AR CONDICIONADO, DE VENTILACAO E REFRIGERACAO",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE SISTEMAS CENTRAIS DE AR CONDICIONADO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9312",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4399102",
        "denominacao": "MONTAGEM E DESMONTAGEM DE ANDAIMES E OUTRAS ESTRUTURAS TEMPORARIAS",
        "descricao": "SERVIÇOS DE INSTALAÇÃO E MONTAGEM DE PRODUTOS, PEÇAS E EQUIPAMENTOS DESTINADOS À CONSTRUÇÃO CIVIL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9311",
        "dtcancelamento": ""
    },
    {
        "subclasse": "2511000",
        "denominacao": "FABRICACAO DE ESTRUTURAS METALICAS",
        "descricao": "SERVIÇOS DE MONTAGEM DE ESTRUTURA METÁLICAS E DE FERRO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9310",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4292801",
        "denominacao": "MONTAGEM DE ESTRUTURAS METALICAS",
        "descricao": "SERVIÇOS DE MONTAGEM DE ESTRUTURA METÁLICAS E DE FERRO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9309",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4399102",
        "denominacao": "MONTAGEM E DESMONTAGEM DE ANDAIMES E OUTRAS ESTRUTURAS TEMPORARIAS",
        "descricao": "SERVIÇOS DE MONTAGEM DE ESTRUTURA METÁLICAS E DE FERRO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9308",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4330404",
        "denominacao": "SERVICOS DE PINTURA DE EDIFICIOS EM GERAL",
        "descricao": "SERVIÇOS DE PINTURA DE CONSTRUÇÃO CIVIL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9307",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3701100",
        "denominacao": "GESTAO DE REDES DE ESGOTO",
        "descricao": "SERVIÇOS DE DRENAGEM",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9306",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4319300",
        "denominacao": "SERVICOS DE PREPARACAO DO TERRENO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE DRENAGEM",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9305",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4313400",
        "denominacao": "OBRAS DE TERRAPLENAGEM",
        "descricao": "SERVIÇOS DE TERRAPLANAGEM",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9304",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4329105",
        "denominacao": "TRATAMENTOS TERMICOS, ACUSTICOS OU DE VIBRACAO",
        "descricao": "SERVIÇOS DE TRATAMENTO ACÚSTICO E TÉRMICO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9303",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4329104",
        "denominacao": "MONTAGEM E INSTALACAO DE SISTEMAS E EQUIPAMENTOS DE ILUMINACAO E SINALIZACAO EM VIAS PUBLICAS, PORTOS E AEROPORTOS",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE PLACAS DE SINALIZAÇÃO DE TRÂNSITO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9302",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4211102",
        "denominacao": "PINTURA PARA SINALIZACAO EM PISTAS RODOVIARIAS E AEROPORTOS",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE PLACAS DE SINALIZAÇÃO DE TRÂNSITO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9301",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4322303",
        "denominacao": "INSTALACOES DE SISTEMA DE PREVENCAO CONTRA INCENDIO",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE SISTEMAS DE PREVENÇÃO CONTRA INCÊNDIOS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9300",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4329104",
        "denominacao": "MONTAGEM E INSTALACAO DE SISTEMAS E EQUIPAMENTOS DE ILUMINACAO E SINALIZACAO EM VIAS PUBLICAS, PORTOS E AEROPORTOS",
        "descricao": "SERVIÇOS DE INSTALAÇÃO HIDRÁULICAS, SANITÁRIAS E GÁS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9299",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4322301",
        "denominacao": "INSTALACOES HIDRAULICAS, SANITARIAS E DE GAS",
        "descricao": "SERVIÇOS DE INSTALAÇÃO HIDRÁULICAS, SANITÁRIAS E GÁS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9298",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4329103",
        "denominacao": "INSTALACAO, MANUTENCAO E REPARACAO DE ELEVADORES, ESCADAS E ESTEIRAS ROLANTES, EXCETO DE FABRICACAO PROPRIA",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE ELEVADORES, ESCADAS E ESTEIRAS LOLANTES",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9297",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4321500",
        "denominacao": "INSTALACAO E MANUTENCAO ELETRICA",
        "descricao": "SERVIÇOS DE ELETRICIDADE",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9296",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4399105",
        "denominacao": "PERFURACAO E CONSTRUCAO DE POCOS DE AGUA",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO DE POÇOS DE ÁGUAS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9295",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4223500",
        "denominacao": "CONSTRUCAO DE REDES DE TRANSPORTES POR DUTOS, EXCETO PARA AGUA E ESGOTO",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO DE REDES DE TRANSPORTE POR DUTOS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9294",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4222701",
        "denominacao": "CONSTRUCAO DE REDES DE ABASTECIMENTO DE AGUA, COLETA DE ESGOTO E CONSTRUCOES CORRELATAS, EXCETO OBRAS DE IRRIGACAO",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO DE REDES DE TRANSPORTE POR DUTOS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9293",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4222701",
        "denominacao": "CONSTRUCAO DE REDES DE ABASTECIMENTO DE AGUA, COLETA DE ESGOTO E CONSTRUCOES CORRELATAS, EXCETO OBRAS DE IRRIGACAO",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO DE REDES DE ÁGUA E ESGOTO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9292",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS DE SANEAMENTO AMBIENTAL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9291",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4222701",
        "denominacao": "CONSTRUCAO DE REDES DE ABASTECIMENTO DE AGUA, COLETA DE ESGOTO E CONSTRUCOES CORRELATAS, EXCETO OBRAS DE IRRIGACAO",
        "descricao": "SERVIÇOS DE SANEAMENTO AMBIENTAL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9290",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4222702",
        "denominacao": "OBRAS DE IRRIGACAO",
        "descricao": "SERVIÇOS DE OBRAS DE IRRIGAÇÃO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9289",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3600601",
        "denominacao": "CAPTACAO, TRATAMENTO E DISTRIBUICAO DE AGUA",
        "descricao": "SERVIÇOS DE OBRAS DE IRRIGAÇÃO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9288",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4291000",
        "denominacao": "OBRAS PORTUARIAS, MARITIMAS E FLUVIAIS",
        "descricao": "SERVIÇOS DE OBRAS MARÍTIMAS E FLUVIAIS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9287",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4211101",
        "denominacao": "CONSTRUCAO DE RODOVIAS E FERROVIAS",
        "descricao": "SERVIÇOS DE PAVIMENTAÇÃO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9286",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4211101",
        "denominacao": "CONSTRUCAO DE RODOVIAS E FERROVIAS",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO DE RODOVIAS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9285",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4399101",
        "denominacao": "ADMINISTRACAO DE OBRAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE OBRAS DE CONSTRUÇÃO CIVIL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9284",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4120400",
        "denominacao": "CONSTRUCAO DE EDIFICIOS",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO CIVIL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9283",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4399199",
        "denominacao": "SERVICOS ESPECIALIZADOS PARA CONSTRUCAO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE CONCRETAGEM",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9282",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4120400",
        "denominacao": "CONSTRUCAO DE EDIFICIOS",
        "descricao": "SERVIÇOS DE CONCRETAGEM",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9281",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4120400",
        "denominacao": "CONSTRUCAO DE EDIFICIOS",
        "descricao": "SERVIÇOS DE BOMBEAMENTO DE CONCRETO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9280",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4399199",
        "denominacao": "SERVICOS ESPECIALIZADOS PARA CONSTRUCAO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE BOMBEAMENTO DE CONCRETO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9279",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4312600",
        "denominacao": "PERFURACOES E SONDAGENS",
        "descricao": "SERVIÇOS DE SONDAGEM ",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9278",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4391600",
        "denominacao": "OBRAS DE FUNDACOES",
        "descricao": "SERVIÇOS DE ESTAQUEAMENTO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9277",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4313400",
        "denominacao": "OBRAS DE TERRAPLENAGEM",
        "descricao": "SERVIÇOS DE ESCAVAÇÕES",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9276",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4221904",
        "denominacao": "CONSTRUÇÃO DE ESTAÇÕES E REDES DE TELECOMUNICAÇÕES",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO CIVIL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1443",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4221902",
        "denominacao": "CONSTRUÇÃO DE ESTAÇÕES E REDES DE DISTRIBUIÇÃO DE ENERGIA ELÉTRICA",
        "descricao": "SERVIÇOS DE EXECUÇÃO, POR ADMINISTRAÇÃO, EMPREITADA OU SUBEMPREITADA, DE OBRAS DE CONSTRUÇÃO CIVIL, ...",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1441",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4212000",
        "denominacao": "CONSTRUÇÃO DE OBRAS DE ARTE ESPECIAIS",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO CIVIL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1439",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4221901",
        "denominacao": "CONSTRUÇÃO DE BARRAGENS E REPRESAS PARA GERAÇÃO DE ENERGIA ELÉTRICA",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO CIVIL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1440",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE PLANOS DIRETORES",
        "item": "7",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "9318",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3314707",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E APARELHOS DE REFRIGERAÇÃO E VENTILAÇÃO PARA USO INDUSTRIAL E COMERCIAL",
        "descricao": " \tBALCÕES E CÂMARAS FRIGORÍFICAS, DE USOS INDUSTRIAL E COMERCIAL, MANUTENÇÃO E REPARAÇÃO EXECUTADA POR UNIDADE ESPECIALIZADA",
        "item": "7",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11816",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS DE ESTUDOS DE VIABILIDADE",
        "item": "7",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "9319",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7119703",
        "denominacao": "SERVICOS DE DESENHO TECNICO RELACIONADOS A ARQUITETURA E ENGENHARIA",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE ANTEPROJETOS, PROJETOS BÁSICOS E PROJETOS EXECUTIVOS PARA TRABALHOS DE ENGENHARIA",
        "item": "7",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "9321",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS DE ESTUDOS ORGANIZACIONAIS",
        "item": "7",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "9320",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4311801",
        "denominacao": "DEMOLICAO DE EDIFICIOS E OUTRAS ESTRUTURAS",
        "descricao": "SERVIÇOS DE DEMOLIÇÃO",
        "item": "7",
        "subitem": "4",
        "aliquota": "3",
        "idcnae": "9322",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3314707",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E APARELHOS DE REFRIGERAÇÃO E VENTILAÇÃO PARA USO INDUSTRIAL E COMERCIAL",
        "descricao": " \tBEBEDOUROS ELÉTRICOS, MANUTENÇÃO E REPARAÇÃO EXECUTADA POR UNIDADE ESPECIALIZADA",
        "item": "7",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "11815",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4120400",
        "denominacao": "CONSTRUCAO DE EDIFICIOS",
        "descricao": "SERVIÇOS DE REPARAÇÃO, CONSERVAÇÃO E REFORMA DA CONST. CIVIL",
        "item": "7",
        "subitem": "5",
        "aliquota": "3",
        "idcnae": "9189",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4221905",
        "denominacao": "MANUTENCAO DE ESTACOES E REDES DE TELECOMUNICACOES",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE ESTAÇÕES E REDES DE TELEFONIA E COMUNICAÇÕES",
        "item": "7",
        "subitem": "5",
        "aliquota": "3",
        "idcnae": "9193",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3314707",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E APARELHOS DE REFRIGERAÇÃO E VENTILAÇÃO PARA USO INDUSTRIAL E COMERCIAL",
        "descricao": " \tCOIFAS (EXAUSTORES INDUSTRIAIS), MANUTENÇÃO E REPARAÇÃO EXECUTADA POR UNIDADE ESPECIALIZADA",
        "item": "7",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11814",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4221903",
        "denominacao": "MANUTENCAO DE REDES DE DISTRIBUICAO DE ENERGIA ELETRICA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE REDES DE DISTRIBUIÇÃO DE ENERGIA ELÉTRICA",
        "item": "7",
        "subitem": "5",
        "aliquota": "3",
        "idcnae": "9191",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4120400",
        "denominacao": "CONSTRUCAO DE EDIFICIOS",
        "descricao": "SERVIÇOS DE CONSERVAÇÃO DE EDIFÍCIOS",
        "item": "7",
        "subitem": "5",
        "aliquota": "3",
        "idcnae": "9190",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4330499",
        "denominacao": "OUTRAS OBRAS DE ACABAMENTO DA CONSTRUCAO",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE PERSIANAS",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "9194",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4330499",
        "denominacao": "OUTRAS OBRAS DE ACABAMENTO DA CONSTRUCAO",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE TAPETES E CORTINAS",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "9195",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4330402",
        "denominacao": "INSTALACAO DE PORTAS, JANELAS, TETOS, DIVISORIAS E ARMARIOS EMBUTIDOS DE QUALQUER MATERIAL",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE DIVISÓRIAS",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "9324",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4330405",
        "denominacao": "APLICACAO DE REVESTIMENTOS E DE RESINAS EM INTERIORES E EXTERIORES",
        "descricao": "SERVIÇO DE COLOCAÇÃO DE PELÍCULA DE CONTENÇÃO SOLA EM IMÓVEIS",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "9326",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4330405",
        "denominacao": "APLICACAO DE REVESTIMENTOS E DE RESINAS EM INTERIORES E EXTERIORES",
        "descricao": "SERVIÇO DE COLOCAÇÃO E INSTALAÇÃO DE REVESTIMENTOS DE PAREDE",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "9328",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4330499",
        "denominacao": "OUTRAS OBRAS DE ACABAMENTO DA CONSTRUCAO",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE VIDROS",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "9330",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3314707",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E APARELHOS DE REFRIGERAÇÃO E VENTILAÇÃO PARA USO INDUSTRIAL E COMERCIAL",
        "descricao": "CONGELADORES OU CONSERVADORES COMERCIAIS (FREEZERS), MANUTENÇÃO E REPARAÇÃO EXECUTADA POR UNIDADE ESPECIALIZADA",
        "item": "7",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "11813",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4330405",
        "denominacao": "APLICACAO DE REVESTIMENTOS E DE RESINAS EM INTERIORES E EXTERIORES",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE ASSOALHO",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "9329",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4330405",
        "denominacao": "APLICACAO DE REVESTIMENTOS E DE RESINAS EM INTERIORES E EXTERIORES",
        "descricao": "SERVIÇO DE COLOCAÇÃO E INSTALAÇÃO DE CARPETE",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "9327",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4330402",
        "denominacao": "INSTALACAO DE PORTAS, JANELAS, TETOS, DIVISORIAS E ARMARIOS EMBUTIDOS DE QUALQUER MATERIAL",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE FECHADURAS",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "9325",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4330403",
        "denominacao": "OBRAS DE ACABAMENTO EM GESSO E ESTUQUE",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE GESSO",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "9323",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4330405",
        "denominacao": "APLICACAO DE REVESTIMENTOS E DE RESINAS EM INTERIORES E EXTERIORES",
        "descricao": "SERVIÇOS DE RECUPERAÇÃO, RASPAGEM, POLIMENTO E LUSTRAÇÃO DE ASSOALHOS, PISOS E PAREDES",
        "item": "7",
        "subitem": "7",
        "aliquota": "3",
        "idcnae": "9331",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3314707",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E APARELHOS DE REFRIGERAÇÃO E VENTILAÇÃO PARA USO INDUSTRIAL E COMERCIAL",
        "descricao": " \tEXAUSTORES INDUSTRIAIS, MANUTENÇÃO E REPARAÇÃO EXECUTADA POR UNIDADE ESPECIALIZADA",
        "item": "7",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "11812",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4322302",
        "denominacao": "INSTALACAO E MANUTENCAO DE SISTEMAS CENTRAIS DE AR CONDICIONADO, DE VENTILACAO E REFRIGERACAO",
        "descricao": "SERVIÇOS DE CALEFAÇÃO",
        "item": "7",
        "subitem": "8",
        "aliquota": "3",
        "idcnae": "9332",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3314707",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E APARELHOS DE REFRIGERAÇÃO E VENTILAÇÃO PARA USO INDUSTRIAL E COMERCIAL",
        "descricao": " \tMÁQUINAS E APARELHOS DE REFRIGERAÇÃO PARA USO INDUSTRIAL E COMERCIAL, REPARAÇÃO E MANUTENÇÃO EXECUTADA POR UNIDADE ESPECIALIZADA",
        "item": "7",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "11811",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4930203",
        "denominacao": "TRANSPORTE RODOVIARIO DE PRODUTOS PERIGOSOS",
        "descricao": "SERVIÇOS DE REMOÇÃO DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "9340",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3811400",
        "denominacao": "COLETA DE RESIDUOS NAO-PERIGOSOS",
        "descricao": "SERVIÇOS DE REMOÇÃO DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "9343",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3821100",
        "denominacao": "TRATAMENTO E DISPOSICAO DE RESIDUOS NAO-PERIGOSOS",
        "descricao": "SERVIÇOS DE TRATAMENTO DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "9345",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8121400",
        "denominacao": "LIMPEZA EM PREDIOS E EM DOMICILIOS",
        "descricao": "SERVIÇOS DE VARRIÇÃO E COLETA DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "9347",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3821100",
        "denominacao": "TRATAMENTO E DISPOSICAO DE RESIDUOS NAO-PERIGOSOS",
        "descricao": "SERVIOS DE INCINERAÇÃO DE RESÍDUOS",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "9351",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3822000",
        "denominacao": "TRATAMENTO E DISPOSICAO DE RESIDUOS PERIGOSOS",
        "descricao": "SERVIOS DE INCINERAÇÃO DE RESÍDUOS",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "9350",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3900500",
        "denominacao": "DESCONTAMINACAO E OUTROS SERVICOS DE GESTAO DE RESIDUOS",
        "descricao": "SERVIOS DE INCINERAÇÃO DE RESÍDUOS",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "9349",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8129000",
        "denominacao": "ATIVIDADES DE LIMPEZA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE VARRIÇÃO E COLETA DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "9348",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3811400",
        "denominacao": "COLETA DE RESIDUOS NAO-PERIGOSOS",
        "descricao": "SERVIÇOS DE VARRIÇÃO E COLETA DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "9346",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3822000",
        "denominacao": "TRATAMENTO E DISPOSICAO DE RESIDUOS PERIGOSOS",
        "descricao": "SERVIÇOS DE TRATAMENTO DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "9344",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4930201",
        "denominacao": "TRANSPORTE RODOVIARIO DE CARGA, EXCETO PRODUTOS PERIGOSOS E MUDANCAS, MUNICIPAL",
        "descricao": "SERVIÇOS DE REMOÇÃO DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "9342",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4930202",
        "denominacao": "TRANSPORTE RODOVIARIO DE CARGA, EXCETO PRODUTOS PERIGOSOS E MUDANCAS, INTERMUNICIPAL, INTERESTADUAL E INTERNACIONAL",
        "descricao": "SERVIÇOS DE REMOÇÃO DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "9341",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3314707",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E APARELHOS DE REFRIGERAÇÃO E VENTILAÇÃO PARA USO INDUSTRIAL E COMERCIAL",
        "descricao": " \tMÁQUINAS E APARELHOS DE VENTILAÇÃO PARA USO INDUSTRIAL E COMERCIAL, REPARAÇÃO E MANUTENÇÃO EXECUTADA POR UNIDADE ESPECIALIZADA",
        "item": "7",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "11810",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3812200",
        "denominacao": "COLETA DE RESÍDUOS PERIGOSOS",
        "descricao": "SERVIÇOS DE VARRIÇÃO, COLETA, REMOÇÃO, INCINERAÇÃO, TRATAMENTO, RECICLAGEM, SEPARAÇÃO E DESTINAÇÃO FINAL DE LIXO, REJEITOS E OUTROS RESÍDUOS QUAISQUER",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "11793",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3702900",
        "denominacao": "ATIVIDADES RELACIONADAS A ESGOTO, EXCETO A GESTAO DE REDES",
        "descricao": "ATIVIDADES RELACIONADAS A ESGOTO, EXCETO A GESTAO DE REDES",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "11783",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4930204",
        "denominacao": "TRANSPORTE RODOVIARIO DE MUDANCAS",
        "descricao": "SERVIÇOS DE REMOÇÃO DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "9339",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3831901",
        "denominacao": "RECUPERACAO DE SUCATAS DE ALUMINIO",
        "descricao": "SERVIÇOS DE RECICLAGEM DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "9338",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3831999",
        "denominacao": "RECUPERACAO DE MATERIAIS METALICOS, EXCETO ALUMINIO",
        "descricao": "SERVIÇOS DE RECICLAGEM DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "9337",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3832700",
        "denominacao": "RECUPERACAO DE MATERIAIS PLASTICOS",
        "descricao": "SERVIÇOS DE RECICLAGEM DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "9336",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3839401",
        "denominacao": "USINAS DE COMPOSTAGEM",
        "descricao": "SERVIÇOS DE RECICLAGEM DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "9335",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3839499",
        "denominacao": "RECUPERACAO DE MATERIAIS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE RECICLAGEM DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "9334",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3821100",
        "denominacao": "TRATAMENTO E DISPOSICAO DE RESIDUOS NAO-PERIGOSOS",
        "descricao": "SERVIÇOS DE SEPARAÇÃO E DESTINAÇÃO FINAL DO LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "9333",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8129000",
        "denominacao": "ATIVIDADES DE LIMPEZA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE LIMPEZA, MANUTENÇÃO E CONSERVAÇÃO DE PISCINAS,CHAMINÉS, PARQUES, JARDINS, VIAS E LOGRADOUROS PÚBLICOS",
        "item": "7",
        "subitem": "10",
        "aliquota": "2,5",
        "idcnae": "9352",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8121400",
        "denominacao": "LIMPEZA EM PREDIOS E EM DOMICILIOS",
        "descricao": "SERVIÇOS DE LIMPEZA, MANUTENÇÃO E CONSERVAÇÃO DE IMÓVEIS",
        "item": "7",
        "subitem": "10",
        "aliquota": "2,5",
        "idcnae": "9354",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8129000",
        "denominacao": "ATIVIDADES DE LIMPEZA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE LIMPEZA, MANUTENÇÃO E CONSERVAÇÃO DE PISCINAS",
        "item": "7",
        "subitem": "10",
        "aliquota": "2,5",
        "idcnae": "9353",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8129000",
        "denominacao": "ATIVIDADES DE LIMPEZA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE LIMPEZA, MANUTENÇÃO E CONSERVAÇÃO DE PARQUES",
        "item": "7",
        "subitem": "10",
        "aliquota": "2,5",
        "idcnae": "9355",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3314707",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E APARELHOS DE REFRIGERAÇÃO E VENTILAÇÃO PARA USO INDUSTRIAL E COMERCIAL",
        "descricao": " \tREFRIGERADORES COMERCIAIS, MANUTENÇÃO E REPARAÇÃO EXECUTADA POR UNIDADE ESPECIALIZADA",
        "item": "7",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "11809",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8129000",
        "denominacao": "ATIVIDADES DE LIMPEZA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE LIMPEZA, MANUTENÇÃO E CONSERVAÇÃO DE VIAS E LOGRADOUROS PÚBLICOS",
        "item": "7",
        "subitem": "10",
        "aliquota": "2,5",
        "idcnae": "9357",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9700500",
        "denominacao": "SERVICOS DOMESTICOS",
        "descricao": "SERVIÇOS DOMÉSTICOS",
        "item": "7",
        "subitem": "10",
        "aliquota": "2,5",
        "idcnae": "9358",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8129000",
        "denominacao": "ATIVIDADES DE LIMPEZA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE LIMPEZA, MANUTENÇÃO E CONSERVAÇÃO DE JARDINS",
        "item": "7",
        "subitem": "10",
        "aliquota": "2,5",
        "idcnae": "9356",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7410202",
        "denominacao": "DECORACAO DE INTERIORES",
        "descricao": "SERVIÇOS DE DECORAÇÃO",
        "item": "7",
        "subitem": "11",
        "aliquota": "3",
        "idcnae": "9359",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3314707",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E APARELHOS DE REFRIGERAÇÃO E VENTILAÇÃO PARA USO INDUSTRIAL E COMERCIAL",
        "descricao": "VENTILADORES INDUSTRIAIS, MANUTENÇÃO E REPARAÇÃO EXECUTADA POR UNIDADE ESPECIALIZADA",
        "item": "7",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "11808",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7410299",
        "denominacao": "ATIVIDADES DE DESIGN NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE DECORAÇÃO",
        "item": "7",
        "subitem": "11",
        "aliquota": "3",
        "idcnae": "11738",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8130300",
        "denominacao": "ATIVIDADES PAISAGISTICAS",
        "descricao": "SERVIÇOS DE JARDINAGEM",
        "item": "7",
        "subitem": "11",
        "aliquota": "3",
        "idcnae": "9361",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7410202",
        "denominacao": "DECORACAO DE INTERIORES",
        "descricao": "SERVIÇOS DE DECORAÇÃO E JARDINAGEM",
        "item": "7",
        "subitem": "11",
        "aliquota": "3",
        "idcnae": "9360",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3701100",
        "denominacao": "GESTAO DE REDES DE ESGOTO",
        "descricao": "SERVIÇOS DE CONTROLE E TRATAMENTO DE EFLUENTES",
        "item": "7",
        "subitem": "12",
        "aliquota": "3",
        "idcnae": "9362",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3701100",
        "denominacao": "GESTAO DE REDES DE ESGOTO",
        "descricao": "SERVIÇOS DE TRATAMENTO E CONTROLE DE AGENTES FÍSICOS, QUÍMICOS E BIOLÓGICOS",
        "item": "7",
        "subitem": "12",
        "aliquota": "3",
        "idcnae": "9363",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8122200",
        "denominacao": "IMUNIZACAO E CONTROLE DE PRAGAS URBANAS",
        "descricao": "SERVIÇOS DE DEDETIZAÇÃO, DESINFECÇÃO, DESINSETIZAÇÃO, IMUNIZAÇÃO, HIGIENIZAÇÃO, DESRATIZAÇÃO E PULVERIZAÇÃO",
        "item": "7",
        "subitem": "13",
        "aliquota": "3",
        "idcnae": "9364",
        "dtcancelamento": ""
    },
    {
        "subclasse": "0161001",
        "denominacao": "SERVICO DE PULVERIZACAO E CONTROLE DE PRAGAS AGRICOLAS",
        "descricao": "SERVIÇOS DE PULVERIZAÇÃO",
        "item": "7",
        "subitem": "13",
        "aliquota": "3",
        "idcnae": "9365",
        "dtcancelamento": ""
    },
    {
        "subclasse": "0161003",
        "denominacao": "SERVICO DE PREPARACAO DE TERRENO, CULTIVO E COLHEITA",
        "descricao": "SERVIÇOS DE COLHEITA",
        "item": "7",
        "subitem": "16",
        "aliquota": "3",
        "idcnae": "9366",
        "dtcancelamento": ""
    },
    {
        "subclasse": "0161003",
        "denominacao": "SERVICO DE PREPARACAO DE TERRENO, CULTIVO E COLHEITA",
        "descricao": "SERVIÇO DE FLORESTAMENTO, REFLORESTAMENTO, SEMEADURA E ADUBAÇÃO",
        "item": "7",
        "subitem": "16",
        "aliquota": "3",
        "idcnae": "9367",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4299599",
        "denominacao": "OUTRAS OBRAS DE ENGENHARIA CIVIL NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ESCORAMENTO E CONTENÇÃO DE ENCOSTAS",
        "item": "7",
        "subitem": "17",
        "aliquota": "3",
        "idcnae": "9368",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4291000",
        "denominacao": "OBRAS PORTUARIAS, MARITIMAS E FLUVIAIS",
        "descricao": "SERVIÇOS DE LIMPEZA E DRENAGEM DE RIOS, PORTOS, CANAIS, BAÍAS, LAGOS, LAGOAS, REPRESAS E AÇUDES",
        "item": "7",
        "subitem": "18",
        "aliquota": "3",
        "idcnae": "9369",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS DE ACOMPANHAMENTO E FISCALIZAÇÃO DA EXECUÇÃO DE OBRAS DE ENGENHARIA, ARQUITETURA E URBANISMO",
        "item": "7",
        "subitem": "19",
        "aliquota": "3",
        "idcnae": "9370",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7111100",
        "denominacao": "SERVICOS DE ARQUITETURA",
        "descricao": "SERVIÇOS DE ACOMPANHAMENTO E FISCALIZAÇÃO DA EXECUÇÃO DE OBRAS DE ENGENHARIA, ARQUITETURA E URBANISMO",
        "item": "7",
        "subitem": "19",
        "aliquota": "3",
        "idcnae": "9371",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7119799",
        "denominacao": "ATIVIDADES TECNICAS RELACIONADAS A ENGENHARIA E ARQUITETURA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE AEROFOTOGRAMETRIA",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "9372",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7119701",
        "denominacao": "SERVICOS DE CARTOGRAFIA, TOPOGRAFIA E GEODESIA",
        "descricao": "SERVIÇOS BATIMÉTRICOS",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "9261",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7119701",
        "denominacao": "SERVICOS DE CARTOGRAFIA, TOPOGRAFIA E GEODESIA",
        "descricao": "SERVIÇOS GEODÉSICOS E GEOFÍSICOS",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "9263",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7119701",
        "denominacao": "SERVICOS DE CARTOGRAFIA, TOPOGRAFIA E GEODESIA",
        "descricao": "SERVIÇOS DE CARTOGRAFIA",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "9317",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7119701",
        "denominacao": "SERVICOS DE CARTOGRAFIA, TOPOGRAFIA E GEODESIA",
        "descricao": "SERVIÇOS DE MAPEAMENTO",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "9264",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7119701",
        "denominacao": "SERVICOS DE CARTOGRAFIA, TOPOGRAFIA E GEODESIA",
        "descricao": "SERVIÇOS GEOGRÁFICOS",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "9262",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7119701",
        "denominacao": "SERVICOS DE CARTOGRAFIA, TOPOGRAFIA E GEODESIA",
        "descricao": "SERVIÇOS GEODÉSICOS E GEOGRÁFICOS",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "9374",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7119701",
        "denominacao": "SERVICOS DE CARTOGRAFIA, TOPOGRAFIA E GEODESIA",
        "descricao": "SERVIÇOS DE BATIMETRIA",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "9373",
        "dtcancelamento": ""
    },
    {
        "subclasse": "0910600",
        "denominacao": "ATIVIDADES DE APOIO A EXTRACAO DE PETROLEO E GAS NATURAL",
        "descricao": "SERVIÇOS DE PESCARIA",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "8514",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7210000",
        "denominacao": "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIENCIAS FISICAS E NATURAIS",
        "descricao": "SERVIÇOS DE PESQUISAS RELACIOANADAS AO CARVÃO MINERAL",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "8517",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7210000",
        "denominacao": "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIENCIAS FISICAS E NATURAIS",
        "descricao": "SERVIÇOS DE PESQUISAS RELACIOANADAS AO PETRÓLEO",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "8518",
        "dtcancelamento": ""
    },
    {
        "subclasse": "0910600",
        "denominacao": "ATIVIDADES DE APOIO A EXTRACAO DE PETROLEO E GAS NATURAL",
        "descricao": "SERVIÇOS DE CONCRETAÇÃO",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "8516",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7490102",
        "denominacao": "ESCAFANDRIA E MERGULHO",
        "descricao": "SERVIÇOS DE ESCAFANDRIA E MERGULHO.",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "11788",
        "dtcancelamento": ""
    },
    {
        "subclasse": "0910600",
        "denominacao": "ATIVIDADES DE APOIO A EXTRACAO DE PETROLEO E GAS NATURAL",
        "descricao": "SERVIÇOS DE TESTEMUNHAGEM",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "9268",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7119702",
        "denominacao": "ATIVIDADES DE ESTUDOS GEOLOGICOS",
        "descricao": "SERVIÇOS DE PERFILAGEM",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "9267",
        "dtcancelamento": ""
    },
    {
        "subclasse": "0910600",
        "denominacao": "ATIVIDADES DE APOIO A EXTRACAO DE PETROLEO E GAS NATURAL",
        "descricao": "SERVIÇOS DE MERGULHO",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "9266",
        "dtcancelamento": ""
    },
    {
        "subclasse": "0910600",
        "denominacao": "ATIVIDADES DE APOIO A EXTRACAO DE PETROLEO E GAS NATURAL",
        "descricao": "SERVIÇOS DE PERFURAÇÃO DE POÇOS DE PETRÓLEO",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "9265",
        "dtcancelamento": ""
    },
    {
        "subclasse": "0910600",
        "denominacao": "ATIVIDADES DE APOIO A EXTRACAO DE PETROLEO E GAS NATURAL",
        "descricao": "SERVIÇOS DE  ESTIMULAÇÃO E OUTROS SERVIÇOS RELACIONADOS COM A EXPLORAÇÃO E EXPLOTAÇÃO DE PETRÓLEO, GÁS NATURAL E OUTROS RECURSOS MINERAIS",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "8515",
        "dtcancelamento": ""
    },
    {
        "subclasse": "0161099",
        "denominacao": "ATIVIDADES DE APOIO A AGRICULTURA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE NUCLEAÇÃO  E BOMBARDEAMENTO DE NUVENS",
        "item": "7",
        "subitem": "22",
        "aliquota": "3",
        "idcnae": "8519",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8511200",
        "denominacao": "EDUCACAO INFANTIL - CRECHE",
        "descricao": "SERVIÇOS DE EDUCAÇÃO INFANTIL - CRECHES",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8520",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8512100",
        "denominacao": "EDUCACAO INFANTIL - PRE-ESCOLA",
        "descricao": "SERVIÇOS DE EDUCAÇÃO INFANTIL - PRÉ-ESCOLA",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8521",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8520100",
        "denominacao": "ENSINO MEDIO",
        "descricao": "SERVIÇOS DE EDUCAÇÃO - ENSINO MÉDIO",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8523",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8532500",
        "denominacao": "EDUCAÇÃO SUPERIOR - GRADUAÇÃO E PÓS-GRADUAÇÃO",
        "descricao": "SERVIÇOS DE EDUÇAÇÃO - ENSINO SUPERIOR (GRADUAÇÃO E PÓS-GRADUAÇÃO)",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8525",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8541400",
        "denominacao": "EDUCACAO PROFISSIONAL DE NIVEL TECNICO",
        "descricao": "SERVIÇOS DE ENSINO TÉCNICO INDUSTRIAL OU COMERCIAL",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8527",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9492800",
        "denominacao": "ATIVIDADES DE ORGANIZAÇÕES POLÍTICAS",
        "descricao": "ARRECADAÇÃO DE FUNDOS PARA CAMPANHAS POLÍTICAS"
    },
    {
        "subclasse": "8542200",
        "denominacao": "EDUCACAO PROFISSIONAL DE NIVEL TECNOLOGICO",
        "descricao": "SERVIÇOS DE ENSINO PROFISSIONAL DE NÍVEL TECNOLÓGICO",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8528",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8533300",
        "denominacao": "EDUCAÇÃO SUPERIOR - PÓS-GRADUAÇÃO E EXTENSÃO",
        "descricao": "SERVIÇOS DE EDUÇAÇÃO - ENSINO SUPERIOR (GRADUAÇÃO E PÓS-GRADUAÇÃO E EXTENSÃO)",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8526",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8531700",
        "denominacao": "EDUCAÇÃO SUPERIOR - GRADUAÇÃO",
        "descricao": "SERVIÇOS DE EDUÇAÇÃO - ENSINO SUPERIOR (GRADUAÇÃO)",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8524",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8513900",
        "denominacao": "ENSINO FUNDAMENTAL",
        "descricao": "SERVIÇOS DE EDUCAÇÃO - ENSINO FUNDAMENTAL",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8522",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8592999",
        "denominacao": "ENSINO DE ARTE E CULTURA NÃO ESPECIFICADO ANTERIORMENTE",
        "descricao": "ENSINO DE ARTE E CULTURA NAO ESPECIFICADO ANTERIORMENTE",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "7188",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8599605",
        "denominacao": "CURSOS PREPARATORIOS PARA CONCURSOS",
        "descricao": "SERVIÇOS DE ENSINO PREPARATÓRIO PARA CONCURSOS",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "8442",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8591100",
        "denominacao": "ENSINO DE ESPORTES",
        "descricao": "SERVIÇOS DE INSTRUÇÃO DE ESPORTES",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "8444",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8599604",
        "denominacao": "TREINAMENTO EM DESENVOLVIMENTO PROFISSIONAL E GERENCIAL",
        "descricao": "SERVIÇO DE TREINAMENTO DE RECURSOS HUMANOS",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "8446",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8599699",
        "denominacao": "OUTRAS ATIVIDADES DE ENSINO NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ENSINO ARTÍSTICO",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11557",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8599699",
        "denominacao": "OUTRAS ATIVIDADES DE ENSINO NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ENSINO PREPARATÓRIO PARA CONCURSOS",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11556",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8599699",
        "denominacao": "OUTRAS ATIVIDADES DE ENSINO NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ENSINO LIGADOS ÀS ARTES E CULTURA",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11555",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8599699",
        "denominacao": "OUTRAS ATIVIDADES DE ENSINO NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE TREINAMENTO EM DESENVOLVIMENTO PROFISSIONAL E GERENCIAL",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11554",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8599699",
        "denominacao": "OUTRAS ATIVIDADES DE ENSINO NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE INSTRUÇÃO DE MERGULHO",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11553",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8599603",
        "denominacao": "TREINAMENTO EM INFORMATICA",
        "descricao": "SERVIÇOS DE ENSINO EM INFORMÁTICA",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "8532",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8593700",
        "denominacao": "ENSINO DE IDIOMAS",
        "descricao": "SERVIÇOS DE CURSOS DE LÍNGUA ESTRANGEIRA",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "8531",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8599602",
        "denominacao": "CURSOS DE PILOTAGEM",
        "descricao": "SERVIÇOS DE INSTRUÇÃO DE PILOTAGEM",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "8530",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8599601",
        "denominacao": "FORMACAO DE CONDUTORES",
        "descricao": "SERVIÇOS DE INSTRUÇÃO DE CODUTORES (AUTO-ESCOLA)",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "8529",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8599699",
        "denominacao": "OUTRAS ATIVIDADES DE ENSINO NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ENSINO EM INFORMÁTICA",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11566",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8599699",
        "denominacao": "OUTRAS ATIVIDADES DE ENSINO NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE CURSOS DE LÍNGUA ESTRANGEIRA",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11565",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8599699",
        "denominacao": "OUTRAS ATIVIDADES DE ENSINO NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE INSTRUÇÃO DE PILOTAGEM",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11564",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8599699",
        "denominacao": "OUTRAS ATIVIDADES DE ENSINO NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE INSTRUÇÃO DE CODUTORES (AUTO-ESCOLA)",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11563",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8599699",
        "denominacao": "OUTRAS ATIVIDADES DE ENSINO NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EDUCAÇÃO RELIGIOSA",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11562",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8599699",
        "denominacao": "OUTRAS ATIVIDADES DE ENSINO NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇO DE TREINAMENTO DE RECURSOS HUMANOS",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11561",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8599699",
        "denominacao": "OUTRAS ATIVIDADES DE ENSINO NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇO DE INSTRUÇÃO DE GINÁSTICA",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11560",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8599699",
        "denominacao": "OUTRAS ATIVIDADES DE ENSINO NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE INSTRUÇÃO DE ESPORTES",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11559",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8599699",
        "denominacao": "OUTRAS ATIVIDADES DE ENSINO NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ACADEMIA DE DANÇA",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11558",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9492800",
        "denominacao": "ATIVIDADES DE ORGANIZAÇÕES POLÍTICAS",
        "descricao": "COMITÊ POLÍTICO",
        "item": "8",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "11806",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8592903",
        "denominacao": "ENSINO DE MÚSICA",
        "descricao": "SERVIÇOS DE INSTRUÇÃO, TREINAMENTO, ORIENTAÇÃO PEDAGÓGICA E EDUCACIONAL, AVALIAÇÃO DE CONHECIMENTOS DE QUALQUER NATUREZA",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11771",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8599699",
        "denominacao": "OUTRAS ATIVIDADES DE ENSINO NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE INSTRUÇÃO AMBIENTAL",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11721",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8599699",
        "denominacao": "OUTRAS ATIVIDADES DE ENSINO NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "AVALIAÇÃO DE CONHECIMENTOS DE QUALQUER NATUREZA.",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11567",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8599699",
        "denominacao": "OUTRAS ATIVIDADES DE ENSINO NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EDUCAÇÃO RELIGIOSA",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "8447",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8591100",
        "denominacao": "ENSINO DE ESPORTES",
        "descricao": "SERVIÇO DE INSTRUÇÃO DE GINÁSTICA",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "8445",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8592902",
        "denominacao": "ENSINO DE ARTES CENICAS, EXCETO DANCA",
        "descricao": "SERVIÇOS DE ENSINO ARTÍSTICO",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "8443",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8592902",
        "denominacao": "ENSINO DE ARTES CENICAS, EXCETO DANCA",
        "descricao": "SERVIÇOS DE ENSINO LIGADOS ÀS ARTES E CULTURA",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "8441",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8591100",
        "denominacao": "ENSINO DE ESPORTES",
        "descricao": "SERVIÇOS DE INSTRUÇÃO DE MERGULHO",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "8163",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8599604",
        "denominacao": "TREINAMENTO EM DESENVOLVIMENTO PROFISSIONAL E GERENCIAL",
        "descricao": "SERVIÇOS DE TREINAMENTO EM DESENVOLVIMENTO PROFISSIONAL E GERENCIAL",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "8440",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9492800",
        "denominacao": "ATIVIDADES DE ORGANIZAÇÕES POLÍTICAS",
        "descricao": "DIRETÓRIO REGIONAL DE PARTIDO POLÍTICO",
        "item": "8",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11803",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9492800",
        "denominacao": "ATIVIDADES DE ORGANIZAÇÕES POLÍTICAS",
        "descricao": "ORGANIZAÇÃO POLÍTICA",
        "item": "8",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "11804",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9492800",
        "denominacao": "ATIVIDADES DE ORGANIZAÇÕES POLÍTICAS",
        "descricao": "PARTIDO POLÍTICO",
        "item": "8",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11805",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5510801",
        "denominacao": "HOTEIS",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM HOTEL",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "8448",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5590603",
        "denominacao": "PENSOES (ALOJAMENTO)",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM PENSÃO",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "8454",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5590699",
        "denominacao": "OUTROS ALOJAMENTOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM RESIDÊNCIAS",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "8457",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5590699",
        "denominacao": "OUTROS ALOJAMENTOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM RESIDENCE-SERVICE E SUÍTE-SERVECE",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "8456",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5590699",
        "denominacao": "OUTROS ALOJAMENTOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM FLAT",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "8455",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5590602",
        "denominacao": "CAMPINGS",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM CAMPING",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "8453",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5590699",
        "denominacao": "OUTROS ALOJAMENTOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE HOSPEDAGEM MARÍTIMA",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "8449",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5510802",
        "denominacao": "APART-HOTEIS",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM APART-HOTEL",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "8450",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5510803",
        "denominacao": "MOTEIS",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM MOTEL",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "8451",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5590601",
        "denominacao": "ALBERGUES, EXCETO ASSISTENCIAIS",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM ALBERGUES",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "8452",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7912100",
        "denominacao": "OPERADORES TURISTICOS",
        "descricao": "SERVIÇO DE AGENCIAMENTO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "8458",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7911200",
        "denominacao": "AGENCIAS DE VIAGENS",
        "descricao": "SERVIÇO DE AGENCIAMENTO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "8459",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7990200",
        "denominacao": "SERVICOS DE RESERVAS E OUTROS SERVICOS DE TURISMO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇO DE ORGANIZAÇÃO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "8460",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7912100",
        "denominacao": "OPERADORES TURISTICOS",
        "descricao": "SERVIÇO DE ORGANIZAÇÃO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "8462",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7911200",
        "denominacao": "AGENCIAS DE VIAGENS",
        "descricao": "SERVIÇO DE PROMOÇÃO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "8464",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7990200",
        "denominacao": "SERVICOS DE RESERVAS E OUTROS SERVICOS DE TURISMO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇO DE INTERMEDIAÇÃO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "8466",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7912100",
        "denominacao": "OPERADORES TURISTICOS",
        "descricao": "SERVIÇO DE INTERMEDIAÇÃO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "8468",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7911200",
        "denominacao": "AGENCIAS DE VIAGENS",
        "descricao": "SERVIÇO DE EXECUÇÃO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "8470",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7912100",
        "denominacao": "OPERADORES TURISTICOS",
        "descricao": "SERVIÇO DE EXECUÇÃO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "8469",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7911200",
        "denominacao": "AGENCIAS DE VIAGENS",
        "descricao": "SERVIÇO DE INTERMEDIAÇÃO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "8467",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7990200",
        "denominacao": "SERVICOS DE RESERVAS E OUTROS SERVICOS DE TURISMO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇO DE PROMOÇÃO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "8465",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7912100",
        "denominacao": "OPERADORES TURISTICOS",
        "descricao": "SERVIÇO DE PROMOÇÃO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "8463",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7911200",
        "denominacao": "AGENCIAS DE VIAGENS",
        "descricao": "SERVIÇO DE ORGANIZAÇÃO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "8461",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6330400",
        "denominacao": "ATIVIDADES DE AGÊNCIAS DE VIAGENS E ORGANIZADORES DE VIAGEM",
        "descricao": "SERVIÇOS DE GUIAS DE TURISMO",
        "item": "9",
        "subitem": "3",
        "aliquota": "2,5",
        "idcnae": "1343",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7911200",
        "denominacao": "AGENCIAS DE VIAGENS",
        "descricao": "SERVIÇOS DE GUIAS DE TURISMO",
        "item": "9",
        "subitem": "3",
        "aliquota": "2,5",
        "idcnae": "8471",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7912100",
        "denominacao": "OPERADORES TURISTICOS",
        "descricao": "SERVIÇOS DE GUIAS DE TURISMO",
        "item": "9",
        "subitem": "3",
        "aliquota": "2,5",
        "idcnae": "8472",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6330400",
        "denominacao": "ATIVIDADES DE AGÊNCIAS DE VIAGENS E ORGANIZADORES DE VIAGEM",
        "descricao": "SERVIÇOS DE GUIAS DE TURISMO",
        "item": "9",
        "subitem": "3",
        "aliquota": "2,5",
        "idcnae": "2693",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6470102",
        "denominacao": "FUNDOS DE INVESTIMENTO PREVIDENCIARIOS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE PREVIDÊNCIA PRIVADA",
        "item": "10",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "8322",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6622300",
        "denominacao": "CORRETORES E AGENTES DE SEGUROS, DE PLANOS DE PREVIDENCIA COMPLEMENTAR E DE SAUDE",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE PLANOS DE SAÚDE",
        "item": "10",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "8475",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6612603",
        "denominacao": "CORRETORAS DE CAMBIO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE CÂMBIO",
        "item": "10",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "8474",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6613400",
        "denominacao": "ADMINISTRACAO DE CARTOES DE CREDITO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE CARTÕES DE CRÉDITOS",
        "item": "10",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "8473",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6622300",
        "denominacao": "CORRETORES E AGENTES DE SEGUROS, DE PLANOS DE PREVIDENCIA COMPLEMENTAR E DE SAUDE",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE PREVIDÊNCIA PRIVADA",
        "item": "10",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "8323",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6622300",
        "denominacao": "CORRETORES E AGENTES DE SEGUROS, DE PLANOS DE PREVIDENCIA COMPLEMENTAR E DE SAUDE",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE SEGUROS",
        "item": "10",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "8324",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6719999",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DA INTERMEDIAÇÃO FINANCEIRA, NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE INTERMEDIAÇÃO DE INVESTIMENTOS",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "234",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9609202",
        "denominacao": "AGENCIAS MATRIMONIAIS",
        "descricao": "SERVIÇOS DE AGENCIAMENTOS MATRIMONIAIS",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9045",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6470103",
        "denominacao": "FUNDOS DE INVESTIMENTO IMOBILIARIOS",
        "descricao": "SERVIÇOS DE INTERMEDIAÇÃO DE INVESTIMENTOS",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9047",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7490104",
        "denominacao": "ATIVIDADES DE INTERMEDIAÇÃO E AGENCIAMENTO DE SERVIÇOS E NEGÓCIOS EM GERAL, EXCETO IMOBILIÁRIOS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE TÍTULOS EM GERAL, VALORES MOBILIÁRIOS E CONTRATOS QUAISQUER",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11778",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6619399",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS SERVICOS FINANCEIROS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE INTERMEDIAÇÃO DE INVESTIMENTOS",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9050",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6612605",
        "denominacao": "AGENTES DE INVESTIMENTOS EM APLICACOES FINANCEIRAS",
        "descricao": "SERVIÇOS DE INTERMEDIAÇÃO DE INVESTIMENTOS",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9049",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6470101",
        "denominacao": "FUNDOS DE INVESTIMENTO, EXCETO PREVIDENCIARIOS E IMOBILIARIOS",
        "descricao": "SERVIÇOS DE INTERMEDIAÇÃO DE INVESTIMENTOS",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9048",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6622300",
        "denominacao": "CORRETORES E AGENTES DE SEGUROS, DE PLANOS DE PREVIDENCIA COMPLEMENTAR E DE SAUDE",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTEMEDIAÇÃO",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9046",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6612604",
        "denominacao": "CORRETORAS DE CONTRATOS DE MERCADORIAS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE CONTRATOS",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9044",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6612601",
        "denominacao": "CORRETORAS DE TITULOS E VALORES MOBILIARIOS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE TÍTULOS",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "8325",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6719999",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DA INTERMEDIAÇÃO FINANCEIRA, NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE INTERMEDIAÇÃO DE INVESTIMENTOS",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1584",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6612601",
        "denominacao": "CORRETORAS DE TITULOS E VALORES MOBILIARIOS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE VALORES MOBILIÁRIOS",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "9043",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7490105",
        "denominacao": "AGENCIAMENTO DE PROFISSIONAIS PARA ATIVIDADES ESPORTIVAS, CULTURAIS E ARTISTICAS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE PROPRIEDADE ARTÍSTICA OU LITERÁRIA",
        "item": "10",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "9051",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7490104",
        "denominacao": "ATIVIDADES DE INTERMEDIACAO E AGENCIAMENTO DE SERVICOS E NEGOCIOS EM GERAL, EXCETO IMOBILIARIOS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE PROPRIEDADE ARTÍSTICA OU LITERÁRIA",
        "item": "10",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "9052",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7490104",
        "denominacao": "ATIVIDADES DE INTERMEDIACAO E AGENCIAMENTO DE SERVICOS E NEGOCIOS EM GERAL, EXCETO IMOBILIARIOS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE DIREITOS DE PROPRIEDADE INDUSTRIAL",
        "item": "10",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "9053",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6022502",
        "denominacao": "ATIVIDADES RELACIONADAS À TELEVISÃO POR ASSINATURA, EXCETO PROGRAMADORAS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE DIREITOS DE PROPRIEDADE INDUSTRIAL, ARTÍSTICA OU LITERÁRIA",
        "item": "10",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "11770",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7490104",
        "denominacao": "ATIVIDADES DE INTERMEDIACAO E AGENCIAMENTO DE SERVICOS E NEGOCIOS EM GERAL, EXCETO IMOBILIARIOS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO DE INGRESSOS PARA SALAS DE ESPETÁCULOS",
        "item": "10",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "9054",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6619399",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS SERVICOS FINANCEIROS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE CONTRATOS DE ARRENDAMENTO MERCANTIL (LEASING)",
        "item": "10",
        "subitem": "4",
        "aliquota": "3",
        "idcnae": "9055",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6619399",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS SERVICOS FINANCEIROS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE CONTRATOS DE FATURIZAÇÃO (FACTORING)",
        "item": "10",
        "subitem": "4",
        "aliquota": "3",
        "idcnae": "9056",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6619399",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS SERVICOS FINANCEIROS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "AGENCIAMENTO, CORRETAGEM OI INTERMEDIAÇÃO DE CONTRATOS DE FRANQUIA (FRANCHISING)",
        "item": "10",
        "subitem": "4",
        "aliquota": "3",
        "idcnae": "9057",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7031900",
        "denominacao": "CORRETAGEM E AVALIAÇÃO DE IMÓVEIS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE BENS MÓVEIS OU IMÓVEIS",
        "item": "10",
        "subitem": "5",
        "aliquota": "2",
        "idcnae": "241",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6821801",
        "denominacao": "CORRETAGEM NA COMPRA E VENDA E AVALIACAO DE IMOVEIS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE BENS MÓVEIS OU IMÓVEIS",
        "item": "10",
        "subitem": "5",
        "aliquota": "2",
        "idcnae": "9059",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6821802",
        "denominacao": "CORRETAGEM NO ALUGUEL DE IMOVEIS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE BENS MÓVEIS OU IMÓVEIS",
        "item": "10",
        "subitem": "5",
        "aliquota": "2",
        "idcnae": "9058",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7490104",
        "denominacao": "ATIVIDADES DE INTERMEDIACAO E AGENCIAMENTO DE SERVICOS E NEGOCIOS EM GERAL, EXCETO IMOBILIARIOS",
        "descricao": "AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE BENS MÓVEIS OU IMÓVEIS,NÃO ABRANGIDOS EM OUTROS ITENS OU SUBITENS, INCLUSIVE AQUELES REALIZADOS NO ÂMBITO DE BOLSAS DE MERC. E FUTUROS, POR QUAISQUER MEIOS",
        "item": "10",
        "subitem": "5",
        "aliquota": "2",
        "idcnae": "11130",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6821801",
        "denominacao": "CORRETAGEM NA COMPRA E VENDA E AVALIAÇÃO DE IMÓVEIS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE BENS MÓVEIS OU IMÓVEIS",
        "item": "10",
        "subitem": "5",
        "aliquota": "2",
        "idcnae": "1591",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7490104",
        "denominacao": "ATIVIDADES DE INTERMEDIACAO E AGENCIAMENTO DE SERVICOS E NEGOCIOS EM GERAL, EXCETO IMOBILIARIOS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO MARÍTIMO",
        "item": "10",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "9060",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6391700",
        "denominacao": "AGENCIAS DE NOTICIAS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO DE NOTÍCIAS",
        "item": "10",
        "subitem": "7",
        "aliquota": "3",
        "idcnae": "9061",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7440301",
        "denominacao": "AGÊNCIAS DE PUBLICIDADE E PROPAGANDA",
        "descricao": "SERVIÇOS DE AGENCIAMENTO DE PUBLICIDADE  OU PROPAGANDA",
        "item": "10",
        "subitem": "8",
        "aliquota": "2,5",
        "idcnae": "244",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7312200",
        "denominacao": "AGENCIAMENTO DE ESPACOS PARA PUBLICIDADE, EXCETO EM VEICULOS DE COMUNICACAO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO DE ESPAÇOS PUBLICITÁRIOS",
        "item": "10",
        "subitem": "8",
        "aliquota": "2,5",
        "idcnae": "9063",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7311400",
        "denominacao": "AGENCIAS DE PUBLICIDADE",
        "descricao": "SERVIÇOS DE AGENCIAMENTO DE PUBLICIDADE  OU PROPAGANDA",
        "item": "10",
        "subitem": "8",
        "aliquota": "2,5",
        "idcnae": "9062",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5116000",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE TÊXTEIS, VESTUÁRIO, CALÇADOS E ARTIGOS DE COURO",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "254",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5119500",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE MERCADORIAS EM GERAL (NÃO-ESPECIALIZADO)",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "257",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4618401",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE MEDICAMENTOS, COSMETICOS E PRODUTOS DE PERFUMARIA",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "9068",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4617600",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE PRODUTOS ALIMENTICIOS, BEBIDAS E FUMO",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "9069",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5118700",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO ESPECIALIZADO EM PRODUTOS NÃO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "1606",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5118700",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO ESPECIALIZADO EM PRODUTOS NÃO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "256",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4616800",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE TEXTEIS, VESTUARIO, CALCADOS E ARTIGOS DE VIAGEM",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "9070",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4614100",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE MAQUINAS, EQUIPAMENTOS, EMBARCACOES E AERONAVES",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "9072",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6619302",
        "denominacao": "CORRESPONDENTES DE INSTITUIÇÕES FINANCEIRAS",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO DE QUALQUER NATUREZA, INCLUSIVE COMERCIAL ",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "11724",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4618402",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE INSTRUMENTOS E MATERIAIS ODONTO-MEDICO-HOSPITALARES",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "9067",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4618403",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE JORNAIS, REVISTAS E OUTRAS PUBLICACOES",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "9066",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4618499",
        "denominacao": "OUTROS REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO ESPECIALIZADO EM PRODUTOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "9065",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4619200",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE MERCADORIAS EM GERAL NAO ESPECIALIZADO",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "9064",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4611700",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE MATERIAS-PRIMAS AGRICOLAS E ANIMAIS VIVOS",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "9078",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4512901",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "9077",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4530706",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE PECAS E ACESSORIOS NOVOS E USADOS PARA VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "9076",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4542101",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE MOTOCICLETAS E MOTONETAS, PECAS E ACESSORIOS",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "9075",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4612500",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE COMBUSTIVEIS, MINERAIS, PRODUTOS SIDERURGICOS E QUIMICOS",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "9074",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4613300",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE MADEIRA, MATERIAL DE CONSTRUCAO E FERRAGENS",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "9073",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4615000",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE ELETRODOMESTICOS, MOVEIS E ARTIGOS DE USO DOMESTICO",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "9071",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5119500",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE MERCADORIAS EM GERAL (NÃO-ESPECIALIZADO)",
        "descricao": "SERVIÇOS DE DISTRIBUIÇÃO DE BENS DE TERCEIROS",
        "item": "10",
        "subitem": "10",
        "aliquota": "3",
        "idcnae": "261",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8299799",
        "denominacao": "OUTRAS ATIVIDADES DE SERVICOS PRESTADOS PRINCIPALMENTE AS EMPRESAS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE DISTRIBUIÇÃO DE BENS DE TERCEIROS",
        "item": "10",
        "subitem": "10",
        "aliquota": "3",
        "idcnae": "9081",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6612602",
        "denominacao": "DISTRIBUIDORAS DE TITULOS E VALORES MOBILIARIOS",
        "descricao": "SERVIÇOS DE DISTRIBUIÇÃO DE TÍTULOS E VALORES MOBILIÁRIOS",
        "item": "10",
        "subitem": "10",
        "aliquota": "3",
        "idcnae": "9079",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5913800",
        "denominacao": "DISTRIBUICAO CINEMATOGRAFICA, DE VIDEO E DE PROGRAMAS DE TELEVISAO",
        "descricao": "SERVIÇOS DE DISTRIBUIÇÃO DE FILMES E DE VÍDEOS",
        "item": "10",
        "subitem": "10",
        "aliquota": "3",
        "idcnae": "9080",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5240199",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AEREOS, EXCETO OPERACAO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE GUARDA E ESTACIONAMENTO DE AERONAVES",
        "item": "11",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9082",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5223100",
        "denominacao": "ESTACIONAMENTO DE VEICULOS",
        "descricao": "SERVIÇOS DE GUARDA E ESTACIONAMENTO DE EMBARCAÇÕES",
        "item": "11",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9083",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5223100",
        "denominacao": "ESTACIONAMENTO DE VEICULOS",
        "descricao": "SERVIÇOS DE GUARDA E ESTACIONAMENTO DE VEÍCULOS TERRESTRES AUTOMOTORES",
        "item": "11",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9084",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8020000",
        "denominacao": "ATIVIDADES DE MONITORAMENTO DE SISTEMAS DE SEGURANÇA",
        "descricao": "ATIVIDADES DE MONITORAMENTO DE SISTEMAS DE SEGURANÇA",
        "item": "11",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "7163",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8011101",
        "denominacao": "ATIVIDADES DE VIGILANCIA E SEGURANCA PRIVADA",
        "descricao": "SERVIÇOS DE VIGILÂNCIA, SEGURANÇA OU MONITORAMENTO DE BENS E PESSOAS",
        "item": "11",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "9085",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8020001",
        "denominacao": "ATIVIDADES DE MONITORAMENTO DE SISTEMAS DE SEGURANÇA ELETRÔNICO",
        "descricao": "ATIVIDADES DE MONITORAMENTO DE SISTEMAS DE SEGURANÇA ELETRÔNICO",
        "item": "11",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "11786",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9609205",
        "denominacao": "ATIVIDADES DE SAUNA E BANHOS",
        "descricao": "ATIVIDADES DE SAUNA E BANHOS",
        "item": "11",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "11782",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9609299",
        "denominacao": "OUTRAS ATIVIDADES DE SERVIÇOS PESSOAIS NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇO DE GUARDA-VIDAS",
        "item": "11",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "11757",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8011101",
        "denominacao": "ATIVIDADES DE VIGILANCIA E SEGURANCA PRIVADA",
        "descricao": "SERVIÇOS DE ESCOLTA",
        "item": "11",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9086",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5211702",
        "denominacao": "GUARDA-MOVEIS",
        "descricao": "SERVIÇOS DE GUARDA DE BENS",
        "item": "11",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9087",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5211701",
        "denominacao": "ARMAZENS GERAIS - EMISSAO DE WARRANT",
        "descricao": "SERVIÇOS DE ARMAZENAMENTO ",
        "item": "11",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9089",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5229099",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES TERRESTRES NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE GUARDA DE BENS",
        "item": "11",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9088",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5211799",
        "denominacao": "DEPOSITOS DE MERCADORIAS PARA TERCEIROS, EXCETO ARMAZENS GERAIS E GUARDA-MOVEIS",
        "descricao": "SERVIÇOS DE ARMAZENAMENTO ",
        "item": "11",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9090",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5212500",
        "denominacao": "CARGA E DESCARGA",
        "descricao": "SERVIÇOS DE CARGA E DESCARGA ",
        "item": "11",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9092",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5211799",
        "denominacao": "DEPOSITOS DE MERCADORIAS PARA TERCEIROS, EXCETO ARMAZENS GERAIS E GUARDA-MOVEIS",
        "descricao": "SERVIÇOS DE DEPÓSITOS DE BENS DE QUALQUER NATUREZA",
        "item": "11",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9091",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9001999",
        "denominacao": "ARTES CENICAS, ESPETACULOS E ATIVIDADES COMPLEMENTARES NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ESPETÁCULOS TEATRAIS",
        "item": "12",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9093",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9001901",
        "denominacao": "PRODUCAO TEATRAL",
        "descricao": "SERVIÇOS DE ESPETÁCULOS TEATRAIS",
        "item": "12",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9094",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5914600",
        "denominacao": "ATIVIDADES DE EXIBICAO CINEMATOGRAFICA",
        "descricao": "SERVIÇOS DE EXIBIÇÃO CINEMATOGRÁFICA",
        "item": "12",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9095",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5914600",
        "denominacao": "ATIVIDADES DE EXIBICAO CINEMATOGRAFICA",
        "descricao": "SERVIÇOS DE EXIBIÇÕES CINEMATOGRÁFICAS",
        "item": "12",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9096",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9001904",
        "denominacao": "PRODUCAO DE ESPETACULOS CIRCENSES, DE MARIONETES E SIMILARES",
        "descricao": "SERVIÇOS DE ESPETÁCULOS CIRCENSES",
        "item": "12",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9097",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6022501",
        "denominacao": "PROGRAMADORAS",
        "descricao": "SERVIÇOS DE PROGRAMA DE AUDITÓRIO",
        "item": "12",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9098",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6021700",
        "denominacao": "ATIVIDADES DE TELEVISAO ABERTA",
        "descricao": "SERVIÇOS DE PROGRAMA DE AUDITÓRIO",
        "item": "12",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9099",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9103100",
        "denominacao": "ATIVIDADES DE JARDINS BOTANICOS, ZOOLOGICOS, PARQUES NACIONAIS, RESERVAS ECOLOGICAS E AREAS DE PROTECAO AMBIENTAL",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE JARDINS BOTÂNICOS, ZOOLÓGICOS, PARQUES NACIONAIS E RESERVAS ECOLÓGICAS",
        "item": "12",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "8971",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9321200",
        "denominacao": "PARQUES DE DIVERSAO E PARQUES TEMATICOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE CENTROS DE LAZER",
        "item": "12",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "8972",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9321200",
        "denominacao": "PARQUES DE DIVERSAO E PARQUES TEMATICOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE PARQUES DE DIVERSÕES",
        "item": "12",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "8973",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9329801",
        "denominacao": "DISCOTECAS, DANCETERIAS, SALOES DE DANCA E SIMILARES",
        "descricao": "SERVIÇOS DE TAXI-DANCING",
        "item": "12",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9103",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9329801",
        "denominacao": "DISCOTECAS, DANCETERIAS, SALOES DE DANCA E SIMILARES",
        "descricao": "SERVIÇOS DE BOATE",
        "item": "12",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "8974",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9329801",
        "denominacao": "DISCOTECAS, DANCETERIAS, SALOES DE DANCA E SIMILARES",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE SALÕES DE BAILES",
        "item": "12",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9104",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9001903",
        "denominacao": "PRODUCAO DE ESPETACULOS DE DANCA",
        "descricao": "SERVIÇOS DE BALLET, DANÇAS, DESFILES E SHOWS",
        "item": "12",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9105",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9001902",
        "denominacao": "PRODUCAO MUSICAL",
        "descricao": "SERVIÇOS DE ÓPERA, CONCERTOS, RECITAIS E FESTIVAIS",
        "item": "12",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9106",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8230001",
        "denominacao": "SERVICOS DE ORGANIZACAO DE FEIRAS, CONGRESSOS, EXPOSICOES E FESTAS",
        "descricao": "SERVIÇOS DE ORGANIZAÇÃO DE FEIRAS, EVENTOS E CONGRESSOS",
        "item": "12",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "11689",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8230001",
        "denominacao": "SERVICOS DE ORGANIZACAO DE FEIRAS, CONGRESSOS, EXPOSICOES E FESTAS",
        "descricao": "SERVIÇOS DE EXPOSIÇÃO ",
        "item": "12",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "11690",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9200301",
        "denominacao": "CASAS DE BINGO",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE DIVERSÕES ELETRÔNICAS OU NÃO",
        "item": "12",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "8986",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9329804",
        "denominacao": "EXPLORACAO DE JOGOS ELETRONICOS RECREATIVOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE JOGOS ELETRÔNICOS",
        "item": "12",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "8989",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9200399",
        "denominacao": "EXPLORACAO DE JOGOS DE AZAR E APOSTAS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE JOGOS E CARTEADOS",
        "item": "12",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "8987",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9329802",
        "denominacao": "EXPLORACAO DE BOLICHES",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE JOGOS DE BOLICHE E BOCHA",
        "item": "12",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "8988",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9329803",
        "denominacao": "EXPLORACAO DE JOGOS DE SINUCA, BILHAR E SIMILARES",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE JOGOS DE BILHARES",
        "item": "12",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "8990",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9001905",
        "denominacao": "PRODUCAO DE ESPETACULOS DE RODEIOS, VAQUEJADAS E SIMILARES",
        "descricao": "SERVIÇOS DE ESPETÁCULOS DE RODEIOS E VAQUEJADAS",
        "item": "12",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8991",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9200302",
        "denominacao": "EXPLORACAO DE APOSTAS EM CORRIDAS DE CAVALOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE CORRIDAS E COMPETIÇÕES DE ANIMAIS",
        "item": "12",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8992",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9200399",
        "denominacao": "EXPLORACAO DE JOGOS DE AZAR E APOSTAS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COMPETIÇÕES ESPORTIVAS OU DE DESTREZA FÍSICA OU INTELECTUAL",
        "item": "12",
        "subitem": "11",
        "aliquota": "2",
        "idcnae": "11691",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9319101",
        "denominacao": "PRODUCAO E PROMOCAO DE EVENTOS ESPORTIVOS",
        "descricao": "SERVIÇOS DE COMPETIÇÕES ESPORTIVAS OU DE DESTREZA FÍSICA OU INTELECTUAL",
        "item": "12",
        "subitem": "11",
        "aliquota": "2",
        "idcnae": "11694",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9200301",
        "denominacao": "CASAS DE BINGO",
        "descricao": "SERVIÇOS DE COMPETIÇÕES ESPORTIVAS OU DE DESTREZA FÍSICA OU INTELECTUAL",
        "item": "12",
        "subitem": "11",
        "aliquota": "2",
        "idcnae": "11693",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9200302",
        "denominacao": "EXPLORACAO DE APOSTAS EM CORRIDAS DE CAVALOS",
        "descricao": "SERVIÇOS DE COMPETIÇÕES ESPORTIVAS OU DE DESTREZA FÍSICA OU INTELECTUAL",
        "item": "12",
        "subitem": "11",
        "aliquota": "2",
        "idcnae": "11692",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9001999",
        "denominacao": "ARTES CENICAS, ESPETACULOS E ATIVIDADES COMPLEMENTARES NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EXECUÇÃO DE MÚSICA",
        "item": "12",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8997",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9001906",
        "denominacao": "ATIVIDADES DE SONORIZACAO E DE ILUMINACAO",
        "descricao": "SERVIÇOS DE EXECUÇÃO DE MÚSICA",
        "item": "12",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8999",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9001902",
        "denominacao": "PRODUCAO MUSICAL",
        "descricao": "SERVIÇOS DE EXECUÇÃO DE MÚSICA",
        "item": "12",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8998",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9001905",
        "denominacao": "PRODUCAO DE ESPETACULOS DE RODEIOS, VAQUEJADAS E SIMILARES",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE EVENTOS",
        "item": "12",
        "subitem": "13",
        "aliquota": "2",
        "idcnae": "11695",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9001903",
        "denominacao": "PRODUCAO DE ESPETACULOS DE DANCA",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE EVENTOS",
        "item": "12",
        "subitem": "13",
        "aliquota": "2",
        "idcnae": "11697",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9001904",
        "denominacao": "PRODUCAO DE ESPETACULOS CIRCENSES, DE MARIONETES E SIMILARES",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE EVENTOS",
        "item": "12",
        "subitem": "13",
        "aliquota": "2",
        "idcnae": "11696",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9001902",
        "denominacao": "PRODUCAO MUSICAL",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE EVENTOS",
        "item": "12",
        "subitem": "13",
        "aliquota": "2",
        "idcnae": "11698",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9003500",
        "denominacao": "GESTAO DE ESPACOS PARA ARTES CENICAS, ESPETACULOS E OUTRAS ATIVIDADES ARTISTICAS",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE EVENTOS",
        "item": "12",
        "subitem": "13",
        "aliquota": "2",
        "idcnae": "11700",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9001902",
        "denominacao": "PRODUCAO MUSICAL",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE SHOWS E FESTIVAIS",
        "item": "12",
        "subitem": "13",
        "aliquota": "2",
        "idcnae": "11702",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9001902",
        "denominacao": "PRODUCAO MUSICAL",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE ÓPERAS, CONCERTOS E RECITAIS",
        "item": "12",
        "subitem": "13",
        "aliquota": "2",
        "idcnae": "11704",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9329801",
        "denominacao": "DISCOTECAS, DANCETERIAS, SALOES DE DANCA E SIMILARES",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE BAILES",
        "item": "12",
        "subitem": "13",
        "aliquota": "2",
        "idcnae": "11706",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9001901",
        "denominacao": "PRODUCAO TEATRAL",
        "descricao": "SERVIÇOS DE PRODUÇÕES TEATRAIS",
        "item": "12",
        "subitem": "13",
        "aliquota": "2",
        "idcnae": "11705",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9001999",
        "denominacao": "ARTES CENICAS, ESPETACULOS E ATIVIDADES COMPLEMENTARES NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE ESPETÁCULOS, DESFILES E ENTREVISTAS",
        "item": "12",
        "subitem": "13",
        "aliquota": "2",
        "idcnae": "11703",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9001903",
        "denominacao": "PRODUCAO DE ESPETACULOS DE DANCA",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE DANÇAS",
        "item": "12",
        "subitem": "13",
        "aliquota": "2",
        "idcnae": "11701",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9001901",
        "denominacao": "PRODUCAO TEATRAL",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE EVENTOS",
        "item": "12",
        "subitem": "13",
        "aliquota": "2",
        "idcnae": "11699",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9001906",
        "denominacao": "ATIVIDADES DE SONORIZACAO E DE ILUMINACAO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE MÚSICA",
        "item": "12",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "9012",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9001903",
        "denominacao": "PRODUCAO DE ESPETACULOS DE DANCA",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE DESFILE DE BLOCOS CARNAVALESCOS OU FOLCLORÍCOS E TRIOS ELÉTRICOS",
        "item": "12",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "9013",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9001999",
        "denominacao": "ARTES CENICAS, ESPETACULOS E ATIVIDADES COMPLEMENTARES NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE DESFILE DE BLOCOS CARNAVALESCOS OU FOLCLORÍCOS E TRIOS ELÉTRICOS",
        "item": "12",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "9014",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5914600",
        "denominacao": "ATIVIDADES DE EXIBICAO CINEMATOGRAFICA",
        "descricao": "SERVIÇOS DE EXIBIÇÃO DE FILMES",
        "item": "12",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "9015",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5911199",
        "denominacao": "ATIVIDADES DE PRODUCAO CINEMATOGRAFICA, DE VIDEOS E DE PROGRAMAS DE TELEVISAO NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SEVIÇOS DE EXIBIÇÃO DE ENTREVISTAS, MUSICAIS, ESPETÁCULOS, SHOWS, CONCERTOS, DESFILES, ÓPERAS, COMPETIÇÕES ESPORTIVAS, DEDESTREZA INTELECTUAL",
        "item": "12",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "9016",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9329899",
        "denominacao": "OUTRAS ATIVIDADES DE RECREACAO E LAZER NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE RECREAÇÃO E ANIMAÇÃO",
        "item": "12",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "9017",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8299702",
        "denominacao": "EMISSAO DE VALES-ALIMENTACAO, VALES-TRANSPORTE E SIMILARES",
        "descricao": "SERVIÇOS DE EMISSÃO DE VALES ALIMENTAÇÃO, TRANSPORTE E SIMILARES",
        "item": "13",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "8165",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9001902",
        "denominacao": "PRODUCAO MUSICAL",
        "descricao": "SERVIÇOS DE FONOGRAFIA OU GRAVAÇÃO DE SONS",
        "item": "13",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9018",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5912001",
        "denominacao": "SERVICOS DE DUBLAGEM",
        "descricao": "SERVIÇOS DE DUBLAGEM SONORA",
        "item": "13",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9019",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5912002",
        "denominacao": "SERVICOS DE MIXAGEM SONORA EM PRODUCAO AUDIOVISUAL",
        "descricao": "SERVIÇOS DE MIXAGEM SONORA",
        "item": "13",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9020",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5920100",
        "denominacao": "ATIVIDADES DE GRAVACAO DE SOM E DE EDICAO DE MUSICA",
        "descricao": "SERVIÇOS DE GRAVAÇÃO DE SONS",
        "item": "13",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9022",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5920100",
        "denominacao": "ATIVIDADES DE GRAVACAO DE SOM E DE EDICAO DE MUSICA",
        "descricao": "SERVIÇOS DE TRUCAGEM SONORA",
        "item": "13",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9021",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5912099",
        "denominacao": "ATIVIDADES DE PÓS PRODUÇÃO CINEMATOGRÁFICA, DE VÍDEOS E DE PROGRAMAS DE TELEVISÃO NÃO ESPECIFICADAS ANTERIORMENTE"
    },
    {
        "subclasse": "",
        "denominacao": "SERVIÇOS DE FONOGRAFIA OU GRAVAÇÃO DE SONS, INCLUSIVE TRUCAGEM, DUBLAGEM MIXAGEM E CONGENERES",
        "descricao": "13",
        "item": "2",
        "subitem": "5",
        "aliquota": "11758",
        "idcnae": ""
    },
    {
        "subclasse": "7420002",
        "denominacao": "ATIVIDADES DE PRODUCAO DE FOTOGRAFIAS AEREAS E SUBMARINAS",
        "descricao": "SERVIÇOS FOTOGRÁFICOS",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9023",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7420001",
        "denominacao": "ATIVIDADES DE PRODUCAO DE FOTOGRAFIAS, EXCETO AEREA E SUBMARINA",
        "descricao": "SERVIÇOS FOTOGRÁFICOS",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9024",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7420004",
        "denominacao": "FILMAGEM DE FESTAS E EVENTOS",
        "descricao": "SERVIÇOS DE FILMAGEM DE FESTAS E EVENTOS",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9027",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7420002",
        "denominacao": "ATIVIDADES DE PRODUCAO DE FOTOGRAFIAS AEREAS E SUBMARINAS",
        "descricao": "SERVIÇOS DE FOTOGRAFIAS AÉREAS E SUBMARINAS ",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9026",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7420003",
        "denominacao": "LABORATORIOS FOTOGRAFICOS",
        "descricao": "SERVIÇOS DE REVELAÇÃO FOTOGRÁFICA",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9025",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8219901",
        "denominacao": "FOTOCOPIAS",
        "descricao": "SERVIÇOS DE REPRODUÇÃO DE PLANTAS OU DESENHOS",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9028",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5914600",
        "denominacao": "ATIVIDADES DE EXIBICAO CINEMATOGRAFICA",
        "descricao": "SERVIÇOS DE CINEMATOGRAFIA",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9030",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5911101",
        "denominacao": "ESTUDIOS CINEMATOGRAFICOS",
        "descricao": "SERVIÇOS DE GRAVAÇÃO DE FILMES OU VIDEOTAPES",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9031",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5912099",
        "denominacao": "ATIVIDADES DE PÓS PRODUÇÃO CINEMATOGRÁFICA, DE VÍDEOS E DE PROGRAMAS DE TELEVISÃO NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE FOTOGRAFIA E CINEMATOGRAFIA, INCLUSIVE REVELAÇÃO, AMPLIAÇÃO, CÓPIA, REPRODUÇÃO, TRUCAGEM E CONGÊNERES",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11759",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5911102",
        "denominacao": "PRODUÇÃO DE FILMES PARA PUBLICIDADE",
        "descricao": "SERVIÇOS DE FOTOGRAFIA E CINEMATOGRAFIA, INCLUSIVE REVELAÇÃO, AMPLIAÇÃO, CÓPIA, REPRODUÇÃO, TRUCAGEM E CONGÊNERES",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11731",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5911101",
        "denominacao": "ESTUDIOS CINEMATOGRAFICOS",
        "descricao": "SERVIÇOS DE CINEMATOGRAFIA",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9029",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7420005",
        "denominacao": "SERVICOS DE MICROFILMAGEM",
        "descricao": "SERVIÇOS DE MICROFILMAGEM",
        "item": "13",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9032",
        "dtcancelamento": ""
    },
    {
        "subclasse": "1830001",
        "denominacao": "REPRODUCAO DE SOM EM QUALQUER SUPORTE",
        "descricao": "SERVIÇOS DE REPROGRAFIA ",
        "item": "13",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9035",
        "dtcancelamento": ""
    },
    {
        "subclasse": "1830002",
        "denominacao": "REPRODUCAO DE VIDEO EM QUALQUER SUPORTE",
        "descricao": "SERVIÇOS DE REPROGRAFIA ",
        "item": "13",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9034",
        "dtcancelamento": ""
    },
    {
        "subclasse": "1830003",
        "denominacao": "REPRODUCAO DE SOFTWARE EM QUALQUER SUPORTE",
        "descricao": "SERVIÇOS DE REPROGRAFIA ",
        "item": "13",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9033",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8219901",
        "denominacao": "FOTOCOPIAS",
        "descricao": "SERVIÇOS DE REPROGRAFIA ",
        "item": "13",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9036",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6311900",
        "denominacao": "TRATAMENTO DE DADOS, PROVEDORES DE SERVICOS DE APLICACAO E SERVICOS DE HOSPEDAGEM NA INTERNET",
        "descricao": "SERVIÇOS DE DIGITALIZAÇÃO",
        "item": "13",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9038",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8219901",
        "denominacao": "FOTOCOPIAS",
        "descricao": "SERVIÇOS DE DIGITALIZAÇÃO",
        "item": "13",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9037",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7499302",
        "denominacao": "FOTOCÓPIAS, DIGITALIZAÇÃO E SERVIÇOS CORRELATOS.",
        "descricao": "SERVIÇOS DE FOTOCOMPOSIÇÃO ",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "410",
        "dtcancelamento": ""
    },
    {
        "subclasse": "1821100",
        "denominacao": "SERVICOS DE PRE-IMPRESSAO",
        "descricao": "SERVIÇOS DE COMPOSIÇÃO GRÁFICA",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "8950",
        "dtcancelamento": ""
    },
    {
        "subclasse": "1821100",
        "denominacao": "SERVICOS DE PRE-IMPRESSAO",
        "descricao": "SERVIÇOS DE FOTOCOMPOSIÇÃO ",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "8949",
        "dtcancelamento": ""
    },
    {
        "subclasse": "1821100",
        "denominacao": "SERVICOS DE PRE-IMPRESSAO",
        "descricao": "SERVIÇOS DE LITOGRAFIA",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9040",
        "dtcancelamento": ""
    },
    {
        "subclasse": "1821100",
        "denominacao": "SERVICOS DE PRE-IMPRESSAO",
        "descricao": "SERVIÇOS DE FOTOLITOGRAFIA  ",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9042",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7490199",
        "denominacao": "OUTRAS ATIVIDADES PROFISSIONAIS, CIENTIFICAS E TECNICAS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE CLICHERIA",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11545",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7490199",
        "denominacao": "OUTRAS ATIVIDADES PROFISSIONAIS, CIENTIFICAS E TECNICAS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE FOTOLITOGRAFIA  ",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11547",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7490199",
        "denominacao": "OUTRAS ATIVIDADES PROFISSIONAIS, CIENTIFICAS E TECNICAS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ZINCOGRAFIA",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11549",
        "dtcancelamento": ""
    },
    {
        "subclasse": "1813001",
        "denominacao": "IMPRESSÃO DE MATERIAL PARA USO PUBLICITÁRIO",
        "descricao": "SERVIÇOS DE COMPOSIÇÃO GRÁFICA",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11728",
        "dtcancelamento": ""
    },
    {
        "subclasse": "1813099",
        "denominacao": "IMPRESSAO DE MATERIAL PARA OUTROS USOS",
        "descricao": "SERVIÇOS DE COMPOSIÇÃO GRÁFICA",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11550",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7490199",
        "denominacao": "OUTRAS ATIVIDADES PROFISSIONAIS, CIENTIFICAS E TECNICAS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE LITOGRAFIA",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11548",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7490199",
        "denominacao": "OUTRAS ATIVIDADES PROFISSIONAIS, CIENTIFICAS E TECNICAS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE FOTOCOMPOSIÇÃO ",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11546",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7490199",
        "denominacao": "OUTRAS ATIVIDADES PROFISSIONAIS, CIENTIFICAS E TECNICAS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COMPOSIÇÃO GRÁFICA",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11513",
        "dtcancelamento": ""
    },
    {
        "subclasse": "1821100",
        "denominacao": "SERVICOS DE PRE-IMPRESSAO",
        "descricao": "SERVIÇOS DE ZINCOGRAFIA",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9041",
        "dtcancelamento": ""
    },
    {
        "subclasse": "1821100",
        "denominacao": "SERVICOS DE PRE-IMPRESSAO",
        "descricao": "SERVIÇOS DE CLICHERIA",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9039",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5020201",
        "denominacao": "SERVIÇOS DE MANUTENÇÃO E REPARAÇÃO DE AUTOMÓVEIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE VEÍCULOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "366",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5020201",
        "denominacao": "SERVIÇOS DE MANUTENÇÃO E REPARAÇÃO DE AUTOMÓVEIS",
        "descricao": "SERVIÇOS DE CHAPEAÇÃO (CHAPEADOR)",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "367",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5020201",
        "denominacao": "SERVIÇOS DE MANUTENÇÃO E REPARAÇÃO DE AUTOMÓVEIS",
        "descricao": "SERVIÇOS DE LANTERNAGEM E LATARIA (LANTERNEIRO/LATOEIRO)",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "460",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3314701",
        "denominacao": "MANUTENCAO E REPARACAO DE MAQUINAS MOTRIZES NAO-ELETRICAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS E MOTORES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8932",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3316301",
        "denominacao": "MANUTENCAO E REPARACAO DE AERONAVES, EXCETO A MANUTENCAO NA PISTA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS E MOTORES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8931",
        "dtcancelamento": ""
    },
    {
        "subclasse": "2851800",
        "denominacao": "FABRICACAO DE MAQUINAS E EQUIPAMENTOS PARA A PROSPECCAO E EXTRACAO DE PETROLEO, PECAS E ACESSORIOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8930",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3312102",
        "denominacao": "MANUTENCAO E REPARACAO DE APARELHOS E INSTRUMENTOS DE MEDIDA, TESTE E CONTROLE",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8929",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3312103",
        "denominacao": "MANUTENCAO E REPARACAO DE APARELHOS ELETROMEDICOS E ELETROTERAPEUTICOS E EQUIPAMENTOS DE IRRADIACAO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8928",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3312104",
        "denominacao": "MANUTENCAO E REPARACAO DE EQUIPAMENTOS E INSTRUMENTOS OPTICOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8927",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3313999",
        "denominacao": "MANUTENCAO E REPARACAO DE MAQUINAS, APARELHOS E MATERIAIS ELETRICOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8926",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3314702",
        "denominacao": "MANUTENCAO E REPARACAO DE EQUIPAMENTOS HIDRAULICOS E PNEUMATICOS, EXCETO VALVULAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8925",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3314705",
        "denominacao": "MANUTENCAO E REPARACAO DE EQUIPAMENTOS DE TRANSMISSAO PARA FINS INDUSTRIAIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8924",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9511800",
        "denominacao": "REPARACAO E MANUTENCAO DE COMPUTADORES E DE EQUIPAMENTOS PERIFERICOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, REPARAÇÃO E INSTALAÇÃO DE MÁQUINAS DE ESCRITRÓRIOS E INFORMÁTICA",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8944",
        "dtcancelamento": ""
    },
    {
        "subclasse": "2539000",
        "denominacao": "SERVICOS DE USINAGEM, SOLDA, TRATAMENTO E REVESTIMENTO EM METAIS",
        "descricao": "SERVIÇOS DE AFIAÇÃO (AFIAÇÃO DE LÂMINAS)",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8943",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9529199",
        "denominacao": "REPARACAO E MANUTENCAO DE OUTROS OBJETOS E EQUIPAMENTOS PESSOAIS E DOMESTICOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "CONSERTO DE EQUIPAMENTOS DE CAMPING",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8942",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9529101",
        "denominacao": "REPARACAO DE CALCADOS, BOLSAS E ARTIGOS DE VIAGEM",
        "descricao": "SERVIÇOS DE REPARAÇÃO DE CALÇADOS OU ARTIGOS DE COURO",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8941",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9512600",
        "denominacao": "REPARACAO E MANUTENCAO DE EQUIPAMENTOS DE COMUNICACAO",
        "descricao": "SERVIÇOS DE REPARAÇÃO E MANUTENÇÃO DE APARELHOS TELEFÔNICOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8940",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3312102",
        "denominacao": "MANUTENCAO E REPARACAO DE APARELHOS E INSTRUMENTOS DE MEDIDA, TESTE E CONTROLE",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE APARELHOS ELÉTRICOS E ELETRÔNICOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8939",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3312103",
        "denominacao": "MANUTENCAO E REPARACAO DE APARELHOS ELETROMEDICOS E ELETROTERAPEUTICOS E EQUIPAMENTOS DE IRRADIACAO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE APARELHOS ELÉTRICOS E ELETRÔNICOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8938",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3312104",
        "denominacao": "MANUTENCAO E REPARACAO DE EQUIPAMENTOS E INSTRUMENTOS OPTICOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE APARELHOS ELÉTRICOS E ELETRÔNICOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8937",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9521500",
        "denominacao": "REPARACAO E MANUTENCAO DE EQUIPAMENTOS ELETROELETRONICOS DE USO PESSOAL E DOMESTICO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE APARELHOS ELÉTRICOS E ELETRÔNICOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8936",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4322302",
        "denominacao": "INSTALACAO E MANUTENCAO DE SISTEMAS CENTRAIS DE AR CONDICIONADO, DE VENTILACAO E REFRIGERACAO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE SISTEMAS CENTRAIS DE AR CONDICIONADO",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8935",
        "dtcancelamento": ""
    },
    {
        "subclasse": "2930103",
        "denominacao": "FABRICACAO DE CABINES, CARROCERIAS E REBOQUES PARA OUTROS VEICULOS AUTOMOTORES, EXCETO CAMINHOES E ONIBUS",
        "descricao": "SERVIÇOS DE BLINDAGEM DE MÁQUINAS, VEÍCULOS, APARELHOS, EQUIPAMENTOS, MOTORES, ELEVADORES OU DE QUALQUER OBJETO",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8934",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3313901",
        "denominacao": "MANUTENCAO E REPARACAO DE GERADORES, TRANSFORMADORES E MOTORES ELETRICOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS E MOTORES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8933",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4751202",
        "denominacao": "RECARGA DE CARTUCHOS PARA EQUIPAMENTOS DE INFORMÁTICA",
        "descricao": "SERVIÇOS DE LUBRIFICAÇÃO, LIMPEZA, LUSTRAÇÃO, REVISÃO, CARGA E RECARGA, CONSERTO, RESTAURAÇÃO, BLINDAGEM, MANUTENÇÃO E CONSERVAÇÃO DE MÁQUINAS, VEÍCULOS, APARELHOS, EQUIPAMENTOS, MOTORES, ELEVADORES OU DE QUALQUER OBJETO (EXCETO PEÇAS E PARTES EMPREGADAS, QUE FICAM SUJEITAS AO ICMS)",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11795",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6190699",
        "denominacao": "OUTRAS ATIVIDADES DE TELECOMUNICAÇÕES NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE LUBRIFICAÇÃO, LIMPEZA, LUSTRAÇÃO, REVISÃO, CARGA E RECARGA, CONSERTO, RESTAURAÇÃO, BLINDAGEM, MANUTENÇÃO E CONSERVAÇÃO DE MÁQUINAS, VEÍCULOS, APARELHOS, EQUIPAMENTOS, MOTORES, ELEVADORES OU DE QUALQUER OBJETO (EXCETO PEÇAS E PARTES EMPREGADAS, QUE FICAM SUJEITAS AO ICMS)",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11789",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4543900",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MOTOCICLETAS E MOTONETAS",
        "descricao": "SERVIÇOS DE LUBRIFICAÇÃO, LIMPEZA, LUSTRAÇÃO, REVISÃO, CARGA E RECARGA, CONSERTO, RESTAURAÇÃO, BLINDAGEM, MANUTENÇÃO E CONSERVAÇÃO DE MÁQUINAS, VEÍCULOS, APARELHOS, EQUIPAMENTOS, MOTORES, ELEVADORES OU DE QUALQUER OBJETO (EXCETO PEÇAS E PARTES EMPREGADAS, QUE FICAM SUJEITAS AO ICMS)",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11767",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5240199",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AÉREOS, EXCETO OPERAÇÃO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE LIMPEZA",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11717",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4329101",
        "denominacao": "SERVIÇOS DE REPARAÇÃO OU MANUTENÇÃO DE ANÚNCIOS LUMINOSOS",
        "descricao": "REPARAÇÃO OU MANUTENÇÃO DE ANÚNCIOS LUMINOSOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11714",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9529105",
        "denominacao": "REPARACAO DE ARTIGOS DO MOBILIARIO",
        "descricao": "SERVIÇOS DE REPARAÇÃO DE ARTIGOS DE TAPEÇARIA",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8983",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9529105",
        "denominacao": "REPARACAO DE ARTIGOS DO MOBILIARIO",
        "descricao": "SERVIÇOS DE CONSERTO E RESTAURAÇÃO DE MÓVEIS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8982",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9529103",
        "denominacao": "REPARACAO DE RELOGIOS",
        "descricao": "SERVIÇOS DE CONSERTOS DE JOIAS E RELÓGIOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8981",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9529106",
        "denominacao": "REPARACAO DE JOIAS",
        "descricao": "SERVIÇOS DE CONSERTOS DE JOIAS E RELÓGIOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8980",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9529102",
        "denominacao": "CHAVEIROS",
        "descricao": "SERVIÇOS DE CONSERTOS DE FECHADURAS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8979",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4520007",
        "denominacao": "SERVICOS DE INSTALACAO, MANUTENCAO E REPARACAO DE ACESSORIOS PARA VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE PELÍCULA DE CONTENÇÃO SOLAR EM VEÍCULOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8978",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4520007",
        "denominacao": "SERVICOS DE INSTALACAO, MANUTENCAO E REPARACAO DE ACESSORIOS PARA VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE INSTALAÇÃO, MANUTENÇÃO E REPARAÇÃO DE ACESSÓRIOS PARA VEÍCULOS AUTOMOTORES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8977",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4520006",
        "denominacao": "SERVICOS DE BORRACHARIA PARA VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERTO DE PNEUS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8976",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4520005",
        "denominacao": "SERVICOS DE LAVAGEM, LUBRIFICACAO E POLIMENTO DE VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE LIMPEZA E LUSTRAÇÃO DE VEÍCULOS (LAVADOR DE CARROS)",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8975",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4520002",
        "denominacao": "SERVICOS DE LANTERNAGEM OU FUNILARIA E PINTURA DE VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE CHAPEAÇÃO (CHAPEADOR)",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8970",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4329103",
        "denominacao": "INSTALACAO, MANUTENCAO E REPARACAO DE ELEVADORES, ESCADAS E ESTEIRAS ROLANTES, EXCETO DE FABRICACAO PROPRIA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E REPARAÇÃO DE ELEVADORES, ESCADAS E ESTEIRAS LOLANTES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8969",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3314706",
        "denominacao": "MANUTENCAO E REPARACAO DE MAQUINAS, APARELHOS E EQUIPAMENTOS PARA INSTALACOES TERMICAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8923",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3314709",
        "denominacao": "MANUTENCAO E REPARACAO DE MAQUINAS DE ESCREVER, CALCULAR E DE OUTROS EQUIPAMENTOS NAO-ELETRONICOS PARA ESCRITORIO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8922",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3314711",
        "denominacao": "MANUTENCAO E REPARACAO DE MAQUINAS E EQUIPAMENTOS PARA AGRICULTURA E PECUARIA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8921",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3314713",
        "denominacao": "MANUTENCAO E REPARACAO DE MAQUINAS-FERRAMENTA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8920",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3314714",
        "denominacao": "MANUTENCAO E REPARACAO DE MAQUINAS E EQUIPAMENTOS PARA A PROSPECCAO E EXTRACAO DE PETROLEO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8919",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3314715",
        "denominacao": "MANUTENCAO E REPARACAO DE MAQUINAS E EQUIPAMENTOS PARA USO NA EXTRACAO MINERAL, EXCETO NA EXTRACAO DE PETROLEO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8918",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3314717",
        "denominacao": "MANUTENCAO E REPARACAO DE MAQUINAS E EQUIPAMENTOS DE TERRAPLENAGEM, PAVIMENTACAO E CONSTRUCAO, EXCETO TRATORES",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8917",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5020201",
        "denominacao": "SERVIÇOS DE MANUTENÇÃO E REPARAÇÃO DE AUTOMÓVEIS",
        "descricao": "SERVIÇOS DE GEOMETRI E BALANCEAMENTO",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1812",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5020201",
        "denominacao": "SERVIÇOS DE MANUTENÇÃO E REPARAÇÃO DE AUTOMÓVEIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE VEÍCULOS (MECÂNICO)",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1811",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5020201",
        "denominacao": "SERVIÇOS DE MANUTENÇÃO E REPARAÇÃO DE AUTOMÓVEIS",
        "descricao": "SERVIÇOS DE LANTERNAGEM E LATARIA (LANTERNEIRO/LATOEIRO)",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1810",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3314710",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA USO GERAL NÃO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS E MOTORES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1741",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3314708",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS, EQUIPAMENTOS E APARELHOS PARA TRANSPORTE E ELEVAÇÃO DE CARGAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE ELEVADORES, ESCADAS E ESTEIRAS ROLANTES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1740",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3314708",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS, EQUIPAMENTOS E APARELHOS PARA TRANSPORTE E ELEVAÇÃO DE CARGAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS E MOTORES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1739",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5020201",
        "denominacao": "SERVIÇOS DE MANUTENÇÃO E REPARAÇÃO DE AUTOMÓVEIS",
        "descricao": "SERVIÇOS DE CHAPEAÇÃO (CHAPEADOR)",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1717",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5020201",
        "denominacao": "SERVIÇOS DE MANUTENÇÃO E REPARAÇÃO DE AUTOMÓVEIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE VEÍCULOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1716",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5020201",
        "denominacao": "SERVIÇOS DE MANUTENÇÃO E REPARAÇÃO DE AUTOMÓVEIS",
        "descricao": "SERVIÇOS DE GEOMETRI E BALANCEAMENTO",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "462",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3314719",
        "denominacao": "MANUTENCAO E REPARACAO DE MAQUINAS E EQUIPAMENTOS PARA AS INDUSTRIAS DE ALIMENTOS, BEBIDAS E FUMO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8916",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3314720",
        "denominacao": "MANUTENCAO E REPARACAO DE MAQUINAS E EQUIPAMENTOS PARA A INDUSTRIA TEXTIL, DO VESTUARIO, DO COURO E CALCADOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8915",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3314721",
        "denominacao": "MANUTENCAO E REPARACAO DE MAQUINAS E APARELHOS PARA A INDUSTRIA DE CELULOSE, PAPEL E PAPELAO E ARTEFATOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8914",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4329104",
        "denominacao": "MONTAGEM E INSTALACAO DE SISTEMAS E EQUIPAMENTOS DE ILUMINACAO E SINALIZACAO EM VIAS PUBLICAS, PORTOS E AEROPORTOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8913",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9511800",
        "denominacao": "REPARACAO E MANUTENCAO DE COMPUTADORES E DE EQUIPAMENTOS PERIFERICOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8912",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9512600",
        "denominacao": "REPARACAO E MANUTENCAO DE EQUIPAMENTOS DE COMUNICACAO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8911",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3316301",
        "denominacao": "MANUTENCAO E REPARACAO DE AERONAVES, EXCETO A MANUTENCAO NA PISTA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE AERONAVES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8910",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3316302",
        "denominacao": "MANUTENCAO DE AERONAVES NA PISTA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE AERONAVES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8909",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3317101",
        "denominacao": "MANUTENCAO E REPARACAO DE EMBARCACOES E ESTRUTURAS FLUTUANTES",
        "descricao": "SERVIÇOS DE LIMPEZA E MANUTENÇÃO DE NAVIOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8908",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4520005",
        "denominacao": "SERVICOS DE LAVAGEM, LUBRIFICACAO E POLIMENTO DE VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE LUBRIFICAÇÃO, LUSTRAÇÃO, REVISÃO E RESTAURAÇÃO DE VEÍCULOS ",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8907",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4520001",
        "denominacao": "SERVICOS DE MANUTENCAO E REPARACAO MECANICA DE VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE VEÍCULOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8906",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3319800",
        "denominacao": "MANUTENCAO E REPARACAO DE EQUIPAMENTOS E PRODUTOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE VEÍCULOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8905",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4520003",
        "denominacao": "SERVICOS DE MANUTENCAO E REPARACAO ELETRICA DE VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE VEÍCULOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8904",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4520007",
        "denominacao": "SERVICOS DE INSTALACAO, MANUTENCAO E REPARACAO DE ACESSORIOS PARA VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE VEÍCULOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8903",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4520004",
        "denominacao": "SERVICOS DE ALINHAMENTO E BALANCEAMENTO DE VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE GEOMETRI E BALANCEAMENTO",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8902",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4520002",
        "denominacao": "SERVICOS DE LANTERNAGEM OU FUNILARIA E PINTURA DE VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE LANTERNAGEM E LATARIA (LANTERNEIRO/LATOEIRO)",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8901",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5020201",
        "denominacao": "SERVIÇOS DE MANUTENÇÃO E REPARAÇÃO DE AUTOMÓVEIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE VEÍCULOS (MECÂNICO)",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "461",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9512600",
        "denominacao": "REPARACAO E MANUTENCAO DE EQUIPAMENTOS DE COMUNICACAO",
        "descricao": "SERVIÇOS DE ASSISTÊNCIA TÉCNICA",
        "item": "14",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "8945",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9511800",
        "denominacao": "REPARACAO E MANUTENCAO DE COMPUTADORES E DE EQUIPAMENTOS PERIFERICOS",
        "descricao": "SERVIÇOS DE ASSISTÊNCIA TÉCNICA",
        "item": "14",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "8946",
        "dtcancelamento": ""
    },
    {
        "subclasse": "2950600",
        "denominacao": "RECONDICIONAMENTO E RECUPERACAO DE MOTORES PARA VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE RECONDICIONAMENTO DE MOTORES",
        "item": "14",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "8947",
        "dtcancelamento": ""
    },
    {
        "subclasse": "2212900",
        "denominacao": "REFORMA DE PNEUMATICOS USADOS",
        "descricao": "SERVIÇOS DE RECAUCHUTAGEM OU REGENERAÇÃO DE PNEUS",
        "item": "14",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "8948",
        "dtcancelamento": ""
    },
    {
        "subclasse": "1822900",
        "denominacao": "SERVICOS DE ACABAMENTOS GRAFICOS",
        "descricao": "SERVIÇOS DE PLASTIFICAÇÃO",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7298",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3211601",
        "denominacao": "LAPIDACAO DE GEMAS",
        "descricao": "SERVIÇOS DE LAPIDAÇÃO OU POLIMENTO",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7301",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8292000",
        "denominacao": "ENVASAMENTO E EMPACOTAMENTO SOB CONTRATO",
        "descricao": "SERVIÇOS DE ENVASAMENTO E EMPACOTAMENTO",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "8952",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4644301",
        "denominacao": "COMERCIO ATACADISTA DE MEDICAMENTOS E DROGAS DE USO HUMANO",
        "descricao": "SERVIÇOS DE ENVASAMENTO E EMPACOTAMENTO",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "8954",
        "dtcancelamento": ""
    },
    {
        "subclasse": "2539001",
        "denominacao": "SERVIÇOS DE USINAGEM, TORNEARIA E SOLDA",
        "descricao": "SERVIÇOS DE RESTAURAÇÃO, RECONDICIONAMENTO, ACONDICIONAMENTO, PINTURA, BENEFICIAMENTO, LAVAGEM, SECAGEM, TINGIMENTO, GALVANOPLASTIA, ANODIZAÇÃO, CORTE, RECORTE, PLASTIFICAÇÃO, COSTURA, ACABAMENTO, POLIMENTO E CONGÊNERES DE OBJETOS QUAISQUER",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11807",
        "dtcancelamento": ""
    },
    {
        "subclasse": "2391503",
        "denominacao": "APARELHAMENTO DE PLACAS E EXECUÇÃO DE TRABALHOS EM MÁRMORE, GRANITO, ARDÓSIA E OUTRAS PEDRAS",
        "descricao": "SERVIÇOS DE RESTAURAÇÃO, RECONDICIONAMENTO, ACONDICIONAMENTO, PINTURA, BENEFICIAMENTO, LAVAGEM, SECAGEM, TINGIMENTO, GALVANOPLASTIA, ANODIZAÇÃO, CORTE, RECORTE, PLASTIFICAÇÃO, COSTURA, ACABAMENTO, POLIMENTO E CONGÊNERES DE OBJETOS QUAISQUER",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11792",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3250709",
        "denominacao": "SERVIÇO DE LABORATÓRIO ÓPTICO",
        "descricao": "SERVIÇOS DE RESTAURAÇÃO, RECONDICIONAMENTO, ACONDICIONAMENTO, PINTURA, BENEFICIAMENTO, LAVAGEM, SECAGEM, TINGIMENTO, GALVANOPLASTIA, ANODIZAÇÃO, CORTE, RECORTE, POLIMENTO, PLASTIFICAÇÃO E CONGÊNERES, DE OBJETOS QUAISQUER",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11753",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4520002",
        "denominacao": "SERVICOS DE LANTERNAGEM OU FUNILARIA E PINTURA DE VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE JATEAMENTO DE BENS MÓVEIS",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "8963",
        "dtcancelamento": ""
    },
    {
        "subclasse": "2539000",
        "denominacao": "SERVICOS DE USINAGEM, SOLDA, TRATAMENTO E REVESTIMENTO EM METAIS",
        "descricao": "SERVIÇOS DE OFICINA DE TORNO",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "8962",
        "dtcancelamento": ""
    },
    {
        "subclasse": "2539000",
        "denominacao": "SERVICOS DE USINAGEM, SOLDA, TRATAMENTO E REVESTIMENTO EM METAIS",
        "descricao": "SERVIÇOS DE ANODIZAÇÃO OU GALVANOPLASTIA",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "8961",
        "dtcancelamento": ""
    },
    {
        "subclasse": "1510600",
        "denominacao": "CURTIMENTO E OUTRAS PREPARACOES DE COURO",
        "descricao": "SERVIÇOS DE  CORTE E ACABAMENTO ",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "8960",
        "dtcancelamento": ""
    },
    {
        "subclasse": "1340502",
        "denominacao": "ALVEJAMENTO, TINGIMENTO E TORCAO EM FIOS, TECIDOS, ARTEFATOS TEXTEIS E PECAS DO VESTUARIO",
        "descricao": "SERVIÇOS DE RESTAUÇÃO, RECONDICIONAMENTO, ACONDICIONAMENTO, PINTURA, BENEFICIAMENTO, LAVAGEM SECAGEM, TINGIMENTO, CORTE E RECORTE EM FIOS, TECIDOS E ARTIGOS TEXTEIS, INCLUSIVE EM PEÇAS DO VESTUÁRIO",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "8959",
        "dtcancelamento": ""
    },
    {
        "subclasse": "1340501",
        "denominacao": "ESTAMPARIA E TEXTURIZACAO EM FIOS, TECIDOS, ARTEFATOS TEXTEIS E PECAS DO VESTUARIO",
        "descricao": "SERVIÇOS DE ESTAMPARIA",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "8958",
        "dtcancelamento": ""
    },
    {
        "subclasse": "1340599",
        "denominacao": "OUTROS SERVICOS DE ACABAMENTO EM FIOS, TECIDOS, ARTEFATOS TEXTEIS E PECAS DO VESTUARIO",
        "descricao": "SERVIÇOS DE ESTAMPARIA",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "8957",
        "dtcancelamento": ""
    },
    {
        "subclasse": "2532201",
        "denominacao": "PRODUCAO DE ARTEFATOS ESTAMPADOS DE METAL",
        "descricao": "SERVIÇOS DE ESTAMPARIA",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "8956",
        "dtcancelamento": ""
    },
    {
        "subclasse": "2863100",
        "denominacao": "FABRICACAO DE MAQUINAS E EQUIPAMENTOS PARA A INDUSTRIA TEXTIL, PECAS E ACESSORIOS",
        "descricao": "SERVIÇOS DE ESTAMPARIA",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "8955",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9521500",
        "denominacao": "REPARACAO E MANUTENCAO DE EQUIPAMENTOS ELETROELETRONICOS DE USO PESSOAL E DOMESTICO",
        "descricao": "SERVIÇOS DE JATEAMENTO DE BENS MÓVEIS",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "8966",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9529104",
        "denominacao": "REPARACAO DE BICICLETAS, TRICICLOS E OUTROS VEICULOS NAO-MOTORIZADOS",
        "descricao": "SERVIÇOS DE JATEAMENTO DE BENS MÓVEIS",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "8965",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9529105",
        "denominacao": "REPARACAO DE ARTIGOS DO MOBILIARIO",
        "descricao": "SERVIÇOS DE JATEAMENTO DE BENS MÓVEIS",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "8964",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4644302",
        "denominacao": "COMERCIO ATACADISTA DE MEDICAMENTOS E DROGAS DE USO VETERINARIO",
        "descricao": "SERVIÇOS DE ENVASAMENTO E EMPACOTAMENTO",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "8953",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5250803",
        "denominacao": "AGENCIAMENTO DE CARGAS, EXCETO PARA O TRANSPORTE MARITIMO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO DE CARGAS",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "8951",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3250707",
        "denominacao": "FABRICACAO DE ARTIGOS OPTICOS",
        "descricao": "SERVIÇOS DE LAPIDAÇÃO OU POLIMENTO",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7302",
        "dtcancelamento": ""
    },
    {
        "subclasse": "1610202",
        "denominacao": "SERRARIAS SEM DESDOBRAMENTO DE MADEIRA",
        "descricao": "SERVIÇOS DE BENEFICIAMENTO DE MADEIRA",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "8900",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3250707",
        "denominacao": "FABRICACAO DE ARTIGOS OPTICOS",
        "descricao": "SERVIÇOS DE BENEFICIAMENTO DE LENTES",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7300",
        "dtcancelamento": ""
    },
    {
        "subclasse": "2722802",
        "denominacao": "RECONDICIONAMENTO DE BATERIAS E ACUMULADORES PARA VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE RECARGA OU RECONDUÇÃO DE BATERIAS",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7299",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4329101",
        "denominacao": "INSTALAÇÃO DE PAINÉIS PUBLICITÁRIOS",
        "descricao": "SERVIÇOS DE INSTALAÇÃO E MONTAGEM DE PLACAS DE PUBLICIDADE",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1774",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3321000",
        "denominacao": "INSTALACAO DE MAQUINAS E EQUIPAMENTOS INDUSTRIAIS",
        "descricao": "SERVIÇOS DE MONTAGEM DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "8856",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6190699",
        "denominacao": "OUTRAS ATIVIDADES DE TELECOMUNICAÇÕES NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE INSTALAÇÃO E MONTAGEM DE APARELHOS, MÁQUINAS E EQUIPAMENTOS, INCLUSIVE MONTAGEM INDUSTRIAL, PRESTADOS AO USUÁRIO FINAL, EXCLUSIVAMENTE COM MATERIAL POR ELE FORNECIDO",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "11790",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8020002",
        "denominacao": "OUTRAS ATIVIDADES DE SERVIÇOS DE SEGURANÇA",
        "descricao": "SERVIÇOS DE INSTALAÇÃO E MONTAGEM DE APARELHOS, MÁQUINAS E EQUIPAMENTOS, INCLUSIVE MONTAGEM INDUSTRIAL, PRESTADOS AO USUÁRIO FINAL, EXCLUSIVAMENTE COM MATERIAL POR ELE FORNECIDO",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "11775",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4329102",
        "denominacao": "INSTALACAO DE EQUIPAMENTOS PARA ORIENTACAO A NAVEGACAO MARITIMA, FLUVIAL E LACUSTRE",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE EQUIPAMENTOS PARA ORIENTAÇÃO À NAVEGAÇÃO MARÍTIMA FLUVIAL E LACUSTRE",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "8968",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4330402",
        "denominacao": "INSTALACAO DE PORTAS, JANELAS, TETOS, DIVISORIAS E ARMARIOS EMBUTIDOS DE QUALQUER MATERIAL",
        "descricao": "SERVIÇOS DE MONTAGEM OU DESMONTAGEM DE DIVISÓRIAS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "8967",
        "dtcancelamento": ""
    },
    {
        "subclasse": "2821601",
        "denominacao": "FABRICACAO DE FORNOS INDUSTRIAIS, APARELHOS E EQUIPAMENTOS NAO-ELETRICOS PARA INSTALACOES TERMICAS, PECAS E ACESSORIOS",
        "descricao": "SERVIÇOS DE INSTALAÇÃO E MONTAGEM DE APARELHOS ELÉTRICOS E ELETRÔNICOS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "8878",
        "dtcancelamento": ""
    },
    {
        "subclasse": "2823200",
        "denominacao": "FABRICACAO DE MAQUINAS E APARELHOS DE REFRIGERACAO E VENTILACAO PARA USO INDUSTRIAL E COMERCIAL, PECAS E ACESSORIOS",
        "descricao": "SERVIÇOS DE INSTALAÇÃO E MONTAGEM DE APARELHOS ELÉTRICOS E ELETRÔNICOS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "8877",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3321000",
        "denominacao": "INSTALACAO DE MAQUINAS E EQUIPAMENTOS INDUSTRIAIS",
        "descricao": "SERVIÇOS DE INSTALAÇÃO E MONTAGEM DE APARELHOS ELÉTRICOS E ELETRÔNICOS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "8876",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9521500",
        "denominacao": "REPARACAO E MANUTENCAO DE EQUIPAMENTOS ELETROELETRONICOS DE USO PESSOAL E DOMESTICO",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE PEÇAS E ACESSÓRIOS ",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "8875",
        "dtcancelamento": ""
    },
    {
        "subclasse": "2513600",
        "denominacao": "FABRICACAO DE OBRAS DE CALDEIRARIA PESADA",
        "descricao": "SERVIÇOS DE MONTAGEM INDUSTRIAL",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "8865",
        "dtcancelamento": ""
    },
    {
        "subclasse": "2631100",
        "denominacao": "FABRICACAO DE EQUIPAMENTOS TRANSMISSORES DE COMUNICACAO, PECAS E ACESSORIOS",
        "descricao": "SERVIÇOS DE MONTAGEM INDUSTRIAL",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "8864",
        "dtcancelamento": ""
    },
    {
        "subclasse": "2812700",
        "denominacao": "FABRICACAO DE EQUIPAMENTOS HIDRAULICOS E PNEUMATICOS, PECAS E ACESSORIOS, EXCETO VALVULAS",
        "descricao": "SERVIÇOS DE MONTAGEM INDUSTRIAL",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "8863",
        "dtcancelamento": ""
    },
    {
        "subclasse": "2822402",
        "denominacao": "FABRICACAO DE MAQUINAS, EQUIPAMENTOS E APARELHOS PARA TRANSPORTE E ELEVACAO DE CARGAS, PECAS E ACESSORIOS",
        "descricao": "SERVIÇOS DE MONTAGEM INDUSTRIAL",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "8862",
        "dtcancelamento": ""
    },
    {
        "subclasse": "2823200",
        "denominacao": "FABRICACAO DE MAQUINAS E APARELHOS DE REFRIGERACAO E VENTILACAO PARA USO INDUSTRIAL E COMERCIAL, PECAS E ACESSORIOS",
        "descricao": "SERVIÇOS DE MONTAGEM INDUSTRIAL",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "8861",
        "dtcancelamento": ""
    },
    {
        "subclasse": "2852600",
        "denominacao": "FABRICACAO DE OUTRAS MAQUINAS E EQUIPAMENTOS PARA USO NA EXTRACAO MINERAL, PECAS E ACESSORIOS, EXCETO NA EXTRACAO DE PETROLEO",
        "descricao": "SERVIÇOS DE MONTAGEM INDUSTRIAL",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "8860",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3101200",
        "denominacao": "FABRICACAO DE MOVEIS COM PREDOMINANCIA DE MADEIRA",
        "descricao": "SERVIÇOS DE MONTAGEM INDUSTRIAL",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "8859",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3102100",
        "denominacao": "FABRICACAO DE MOVEIS COM PREDOMINANCIA DE METAL",
        "descricao": "SERVIÇOS DE MONTAGEM INDUSTRIAL",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "8858",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3103900",
        "denominacao": "FABRICACAO DE MOVEIS DE OUTROS MATERIAIS, EXCETO MADEIRA E METAL",
        "descricao": "SERVIÇOS DE MONTAGEM INDUSTRIAL",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "8857",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3240099",
        "denominacao": "FABRICACAO DE OUTROS BRINQUEDOS E JOGOS RECREATIVOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MONTAGEM DE BRINQUEDOS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "8874",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3329501",
        "denominacao": "SERVICOS DE MONTAGEM DE MOVEIS DE QUALQUER MATERIAL",
        "descricao": "SERVIÇOS DE MONTAGEM OU DESMONTAGEM DE MÓVEIS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "8873",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4330402",
        "denominacao": "INSTALACAO DE PORTAS, JANELAS, TETOS, DIVISORIAS E ARMARIOS EMBUTIDOS DE QUALQUER MATERIAL",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE MÓVEIS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "8872",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3329501",
        "denominacao": "SERVICOS DE MONTAGEM DE MOVEIS DE QUALQUER MATERIAL",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE MÓVEIS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "8871",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3041500",
        "denominacao": "FABRICACAO DE AERONAVES",
        "descricao": "SERVIÇOS DE MONTAGEM DE AERONAVES",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "8870",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3031800",
        "denominacao": "FABRICACAO DE LOCOMOTIVAS, VAGOES E OUTROS MATERIAIS RODANTES",
        "descricao": "SERVIÇOS DE MONTAGEM DE LOCOMOTIVAS E VAGÕES",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "8869",
        "dtcancelamento": ""
    },
    {
        "subclasse": "1622601",
        "denominacao": "FABRICACAO DE CASAS DE MADEIRA PRE-FABRICADAS",
        "descricao": "SERVIÇOS DE MONTAGEM INDUSTRIAL",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "8868",
        "dtcancelamento": ""
    },
    {
        "subclasse": "1622602",
        "denominacao": "FABRICACAO DE ESQUADRIAS DE MADEIRA E DE PECAS DE MADEIRA PARA INSTALACOES INDUSTRIAIS E COMERCIAIS",
        "descricao": "SERVIÇOS DE MONTAGEM INDUSTRIAL",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "8867",
        "dtcancelamento": ""
    },
    {
        "subclasse": "2512800",
        "denominacao": "FABRICACAO DE ESQUADRIAS DE METAL",
        "descricao": "SERVIÇOS DE MONTAGEM INDUSTRIAL",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "8866",
        "dtcancelamento": ""
    },
    {
        "subclasse": "2851800",
        "denominacao": "FABRICACAO DE MAQUINAS E EQUIPAMENTOS PARA A PROSPECCAO E EXTRACAO DE PETROLEO, PECAS E ACESSORIOS",
        "descricao": "SERVIÇOS DE INSTALAÇÃO MONTAGEM DE MÁQUINAS E EQUIPAMENTOS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "8855",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3321000",
        "denominacao": "INSTALACAO DE MAQUINAS E EQUIPAMENTOS INDUSTRIAIS",
        "descricao": "SERVIÇOS DE INSTALAÇÃO MONTAGEM DE MÁQUINAS E EQUIPAMENTOS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "8854",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9002702",
        "denominacao": "RESTAURACAO DE OBRAS DE ARTE",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE MOLDURAS",
        "item": "14",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "8879",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4330403",
        "denominacao": "OBRAS DE ACABAMENTO EM GESSO E ESTUQUE",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE MOLDURAS",
        "item": "14",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "8880",
        "dtcancelamento": ""
    },
    {
        "subclasse": "1822900",
        "denominacao": "SERVICOS DE ACABAMENTOS GRAFICOS",
        "descricao": "SERVIÇOS DE PLASTIFICAÇÃO DE DOCUMENTOS",
        "item": "14",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "8881",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8219999",
        "denominacao": "PREPARACAO DE DOCUMENTOS E SERVICOS ESPECIALIZADOS DE APOIO ADMINISTRATIVO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE PLASTIFICAÇÃO DE DOCUMENTOS",
        "item": "14",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "8882",
        "dtcancelamento": ""
    },
    {
        "subclasse": "1822900",
        "denominacao": "SERVICOS DE ACABAMENTOS GRAFICOS",
        "descricao": "SERVIÇOS DE ACABAMENTOS GRÁFICOS, EXCETO ENCADERNAÇÃO E PLASTIFICAÇÃO",
        "item": "14",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "11663",
        "dtcancelamento": ""
    },
    {
        "subclasse": "1822901",
        "denominacao": "SERVICOS DE ENCADERNACAO E PLASTIFICACAO",
        "descricao": "SERVIÇOS DE ENCADERNAÇÃO E PLASTIFICAÇÃO",
        "item": "14",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "11787",
        "dtcancelamento": ""
    },
    {
        "subclasse": "1822900",
        "denominacao": "SERVICOS DE ACABAMENTOS GRAFICOS",
        "descricao": "SERVIÇOS DE ENCADERNAÇÃO, GRAVAÇÃO E DOURAÇÃO",
        "item": "14",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "8883",
        "dtcancelamento": ""
    },
    {
        "subclasse": "1412603",
        "denominacao": "FACCAO DE PECAS DO VESTUARIO, EXCETO ROUPAS INTIMAS",
        "descricao": "SERVIÇOS DE CORTE E RECORTE DE PEÇAS DE VESTUÁRIO, SOB MEDIDA",
        "item": "14",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "8884",
        "dtcancelamento": ""
    },
    {
        "subclasse": "1412602",
        "denominacao": "CONFECCAO, SOB MEDIDA, DE PECAS DO VESTUARIO, EXCETO ROUPAS INTIMAS",
        "descricao": "SERVIÇOS DE ALFAIATARIA E COSTURA  ",
        "item": "14",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "8889",
        "dtcancelamento": ""
    },
    {
        "subclasse": "1413402",
        "denominacao": "CONFECCAO, SOB MEDIDA, DE ROUPAS PROFISSIONAIS",
        "descricao": "SERVIÇOS DE ALFAIATARIA E COSTURA  ",
        "item": "14",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "8888",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9529199",
        "denominacao": "REPARACAO E MANUTENCAO DE OUTROS OBJETOS E EQUIPAMENTOS PESSOAIS E DOMESTICOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ALFAIATARIA E COSTURA  ",
        "item": "14",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "8887",
        "dtcancelamento": ""
    },
    {
        "subclasse": "1411802",
        "denominacao": "FACCAO DE ROUPAS INTIMAS",
        "descricao": "SERVIÇOS DE CORTE E RECORTE DE PEÇAS DE VESTUÁRIO, SOB MEDIDA",
        "item": "14",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "8885",
        "dtcancelamento": ""
    },
    {
        "subclasse": "1411801",
        "denominacao": "CONFECCAO DE ROUPAS INTIMAS",
        "descricao": "SERVIÇOS DE ALFAIATARIA E COSTURA  ",
        "item": "14",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "8886",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9601702",
        "denominacao": "TINTURARIAS",
        "descricao": "SERVIÇOS DE LAVANDERIA E TINTURARIA",
        "item": "14",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8890",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9601701",
        "denominacao": "LAVANDERIAS",
        "descricao": "SERVIÇOS DE LAVANDERIA E TINTURARIA",
        "item": "14",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8891",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9529105",
        "denominacao": "REPARACAO DE ARTIGOS DO MOBILIARIO",
        "descricao": "SERVIÇOS DE TAPEÇARIA E REFORMA DE ESTOFAMENTOS (ESTOFADOR)",
        "item": "14",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "8892",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9529105",
        "denominacao": "REPARACAO DE ARTIGOS DO MOBILIARIO",
        "descricao": "SERVIÇOS DE TAPEÇARIA E REFORMA DE ESTOFAMENTOS (IMPERMEABILIZADOR)",
        "item": "14",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "8893",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9529105",
        "denominacao": "REPARACAO DE ARTIGOS DO MOBILIARIO",
        "descricao": "SERVIÇOS DE TAPEÇARIA E REFORMA DE ESTOFAMENTOS",
        "item": "14",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "8894",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4520002",
        "denominacao": "SERVICOS DE LANTERNAGEM OU FUNILARIA E PINTURA DE VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE FUNELARIA E LANTERNAGEM ",
        "item": "14",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8895",
        "dtcancelamento": ""
    },
    {
        "subclasse": "2542000",
        "denominacao": "FABRICACAO DE ARTIGOS DE SERRALHERIA, EXCETO ESQUADRIAS",
        "descricao": "SERVIÇOS DE SERRALHERIA",
        "item": "14",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8896",
        "dtcancelamento": ""
    },
    {
        "subclasse": "2512800",
        "denominacao": "FABRICACAO DE ESQUADRIAS DE METAL",
        "descricao": "SERVIÇOS DE SERRALHERIA",
        "item": "14",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8897",
        "dtcancelamento": ""
    },
    {
        "subclasse": "1622699",
        "denominacao": "FABRICACAO DE OUTROS ARTIGOS DE CARPINTARIA PARA CONSTRUCAO",
        "descricao": "SERVIÇOS DE CAPINTARIA",
        "item": "14",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8898",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7243",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7244",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7245",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7246",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7248",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7250",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7260",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7259",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7258",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7257",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7256",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7255",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7254",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7253",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7252",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7251",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7356",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7355",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7354",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7353",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7352",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7351",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7350",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7349",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7348",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7347",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7346",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7345",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7344",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7343",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7342",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7341",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7340",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6613400",
        "denominacao": "ADMINISTRACAO DE CARTOES DE CREDITO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTÃO DE CRÉDITO OU DÉBITO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8899",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7385",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7384",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7383",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7382",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7381",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7380",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7379",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6493000",
        "denominacao": "ADMINISTRACAO DE CONSORCIOS PARA AQUISICAO DE BENS E DIREITOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7378",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7377",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7376",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7375",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7374",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7373",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7372",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7371",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7370",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7369",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7368",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7367",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7366",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7365",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7364",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7363",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7362",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7361",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7360",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7359",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6470101",
        "denominacao": "FUNDOS DE INVESTIMENTO, EXCETO PREVIDENCIARIOS E IMOBILIARIOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7358",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7357",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7249",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7247",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7261",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7262",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7263",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7264",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7266",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7268",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7297",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7296",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7295",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7294",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7293",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7292",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7291",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7290",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7289",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7288",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7287",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7277",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7276",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7275",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7274",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7273",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7272",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7271",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7270",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7269",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7286",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7285",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7284",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7283",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7282",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7281",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7280",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7279",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7278",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7267",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7265",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7480",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7481",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7482",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7483",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7485",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7487",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7496",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7495",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7494",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7493",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7492",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7491",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7490",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7489",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7488",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7513",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7512",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7511",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7510",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7509",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7508",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7507",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7506",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7505",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7530",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7529",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7528",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7527",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7526",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7525",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7524",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7523",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7522",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7535",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7534",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7533",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7532",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7531",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7521",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7520",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7519",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7518",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7517",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7516",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7515",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7514",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7504",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7503",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7502",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7501",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7500",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7499",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7498",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7497",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7486",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "7484",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "7303",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "7304",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "7305",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "7306",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "7308",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "7310",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "7320",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "7319",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "7318",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "7317",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "7316",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "7315",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "7314",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "7313",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "7312",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "7311",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "7309",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "7307",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7321",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7322",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7323",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7324",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7326",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7328",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7653",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7652",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7651",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7650",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7649",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7648",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7647",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7646",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7645",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7670",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7669",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7668",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7667",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7666",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7665",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7664",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7663",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7662",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6499999",
        "denominacao": "OUTRAS ATIVIDADES DE SERVIÇOS FINANCEIROS NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE CADASTRO, ELABORAÇÃO DE FICHA CADASTRAL, RENOVAÇÃO CADASTRAL E CONGÊNERES, INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS ¿ CCF OU EM QUAISQUER OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11800",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7686",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7685",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7684",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7683",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7682",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7681",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7680",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7679",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7678",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7677",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7676",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7675",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7674",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7673",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7672",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7671",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7661",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7660",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7659",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7658",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7657",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7656",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7655",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7654",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7644",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7643",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7642",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7641",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7640",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7639",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7638",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7337",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7336",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7335",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7334",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7333",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7332",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7331",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7330",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7329",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7637",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7636",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7635",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7634",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7633",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7632",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7631",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7339",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7338",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7327",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "7325",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7454",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7456",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7458",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7460",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7462",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7464",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7387",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7386",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7479",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7478",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7477",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7476",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7475",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7474",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7473",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7741",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7740",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7739",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7738",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7737",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7736",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7735",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7734",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7733",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7732",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7731",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7730",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7729",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7728",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7727",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7726",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7756",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7755",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7754",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7753",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7752",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7751",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7750",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7749",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7748",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7747",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7746",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7745",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7744",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7743",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7742",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7453",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7452",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7451",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7450",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7449",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7448",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7447",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7446",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7445",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7444",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7443",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7442",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7441",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7440",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7439",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7438",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7437",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7436",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7435",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7434",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7433",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7432",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7431",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7430",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7429",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7428",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7427",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7426",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7425",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7424",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7423",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7422",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7421",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7420",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7419",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7418",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7417",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7416",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7415",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7414",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7413",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7412",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7411",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7410",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7409",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7408",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7407",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7406",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7405",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7404",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7403",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7402",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7401",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7400",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7399",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7398",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7397",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7396",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7781",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7780",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7779",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7778",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7777",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7776",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7775",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7774",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7773",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7772",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7771",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7770",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7769",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7768",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7767",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7766",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7765",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7764",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7763",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7762",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7761",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7760",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7759",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7758",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7757",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7395",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7394",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7393",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7392",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7391",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7390",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7389",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7388",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7472",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7471",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7470",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7469",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7468",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7467",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7466",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7465",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7463",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7461",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7459",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7457",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "7455",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7536",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7541",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7543",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7564",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7563",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7562",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7561",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7560",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7559",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7558",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7557",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7556",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7555",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7554",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7553",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7552",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7551",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7550",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7549",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7548",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7547",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7546",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7545",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7544",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7630",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7629",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7628",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7627",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7626",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7625",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7624",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7623",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7622",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7621",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7620",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7619",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7618",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7617",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7616",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7615",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7614",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7613",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7612",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7611",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7610",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7609",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7608",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7607",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7606",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7605",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7604",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7603",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7602",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7601",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7600",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7599",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7598",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7597",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7596",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7595",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7594",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7593",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7592",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7591",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7590",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7589",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7588",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7587",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7586",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7585",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7584",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7583",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7582",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7581",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7580",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7579",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7578",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7577",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7576",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7575",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7574",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7573",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7572",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7571",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7570",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7569",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7568",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7567",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7566",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7565",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7542",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7540",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7537",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7538",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "7539",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7847",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7852",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7854",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7871",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7870",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7869",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7868",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7867",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7866",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7865",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7864",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7863",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7880",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7879",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7878",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7877",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7876",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7875",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7874",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7873",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7872",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7862",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7861",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7860",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7859",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7858",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7857",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7856",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7855",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7846",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7845",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7844",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7843",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7842",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7841",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7840",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7839",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7889",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7888",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7887",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7886",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7885",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7884",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7883",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7882",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7881",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7706",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7705",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7704",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7703",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7702",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7701",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7700",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7699",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7698",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7697",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7696",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7695",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7694",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7693",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7692",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7691",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7690",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7689",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7688",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7895",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7894",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7893",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7892",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7891",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7890",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7853",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7851",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7848",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7849",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "7850",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7782",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7787",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7789",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7836",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7835",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7834",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7833",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7832",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7831",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7830",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7829",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7828",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7838",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7837",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7827",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7826",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7825",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7824",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7823",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7822",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7821",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7802",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7723",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7722",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7721",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7720",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7719",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7718",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7717",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7716",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7715",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8024",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8023",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8022",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8021",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8020",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8019",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8018",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8017",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8016",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8245",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8244",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8243",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8242",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8241",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8240",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8239",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8238",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8237",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8236",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8235",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8234",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8233",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8232",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8231",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8230",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8229",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8228",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8227",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8226",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8225",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8224",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8223",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8222",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8221",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8220",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8219",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8218",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8217",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8216",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8215",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8214",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8213",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8212",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8211",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8210",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8209",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8208",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8103",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8102",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8101",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8100",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8099",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8098",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8097",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8096",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8095",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8094",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8093",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8092",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8091",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8090",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8089",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8088",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8087",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8086",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8085",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8084",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8083",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8082",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8081",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8080",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8079",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8078",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8077",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8076",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8075",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8074",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8073",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8072",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8071",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8070",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8069",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8068",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8067",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8028",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8027",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8026",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8025",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8015",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8014",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8013",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8012",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8011",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8010",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7725",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7724",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7714",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7713",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7712",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7711",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7710",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7709",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7708",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7707",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7801",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7800",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7799",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7798",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7797",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7796",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7795",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7794",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7811",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7810",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7809",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7808",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7807",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7806",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7805",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7804",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7803",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7820",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7819",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7818",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7817",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7816",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7815",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7814",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7813",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7812",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7793",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7792",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7791",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7790",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7788",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7786",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7783",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7784",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "7785",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7896",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7897",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7899",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7901",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7903",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7905",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7907",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7990",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7989",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7988",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7987",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7986",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7985",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7984",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7983",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7982",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7981",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7980",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7979",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7978",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7977",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7976",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7975",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7974",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7973",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7972",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7971",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7970",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7969",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7968",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7967",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7966",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7965",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7964",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7963",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7962",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7961",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7960",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7959",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7958",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7957",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7956",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7955",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6492100",
        "denominacao": "SECURITIZACAO DE CREDITOS",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7687",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7954",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7953",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7952",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7951",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7950",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7949",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7948",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7947",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7946",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7945",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7944",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7916",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7915",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7914",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7913",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7912",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7911",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7910",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7909",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7908",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7925",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7924",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7923",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7922",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7921",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7920",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7919",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7918",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7917",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7934",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7933",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7932",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7931",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7930",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7929",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7928",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7927",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7926",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7943",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7942",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7941",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7940",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7939",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7938",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7937",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7936",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7935",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7906",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7904",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7902",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7900",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "7898",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8029",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8034",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8036",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8053",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8052",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8051",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8050",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8049",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8048",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8047",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8046",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8045",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8044",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8043",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8042",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8041",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8040",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8039",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8038",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8037",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8062",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8061",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8060",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8059",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8058",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8057",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8056",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8055",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8054",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8066",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8065",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8064",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8063",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8035",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8033",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8030",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8031",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "8032",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "7991",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "7996",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "7998",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8201",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8200",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8199",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8198",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8197",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8196",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8195",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8194",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8193",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8513",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8512",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8511",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8510",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8509",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8508",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8507",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8506",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8505",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8504",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8503",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8502",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8501",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8500",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8499",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8498",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8497",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8496",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8495",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8494",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8493",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8492",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8491",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8490",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8489",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8488",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8487",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8486",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8485",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8484",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8483",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8482",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8481",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8480",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8479",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8478",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8477",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8476",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8207",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8206",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8205",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8204",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8203",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8202",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8192",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8191",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8190",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8189",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8188",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8187",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8186",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8007",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8006",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8005",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8004",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8003",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8002",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8001",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8000",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "7999",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8176",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8175",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8174",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8173",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8172",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8171",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8170",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8009",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8008",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8185",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8184",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8183",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8182",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8181",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8180",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8179",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8178",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8177",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "7997",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "7995",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "7992",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "7993",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "7994",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8293",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8298",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8300",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8321",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8320",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8319",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8309",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8308",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8307",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8306",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8305",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8304",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8303",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8302",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8301",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8318",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8317",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8316",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8315",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8314",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8313",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8312",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8311",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8310",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8292",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8291",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8290",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8289",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8288",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8287",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8286",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8285",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8284",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8283",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8282",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8281",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8280",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8279",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8278",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8277",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8276",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8275",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8274",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8273",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8272",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8271",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8270",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8269",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8268",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8267",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8266",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8265",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8264",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8263",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8262",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8261",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8260",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8259",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8258",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8257",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8256",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8255",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8254",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8253",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8252",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8251",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8250",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8249",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8248",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8247",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8246",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8299",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8297",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8294",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8295",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8296",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8667",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8672",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8674",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8651",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8650",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8649",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8648",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8647",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8646",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8645",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8644",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8643",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8666",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8665",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8664",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8663",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8662",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8661",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8660",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8659",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8658",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8657",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8656",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8655",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8654",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8653",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8652",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8642",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8641",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8640",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8639",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8638",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8637",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8636",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8683",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8682",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8681",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8680",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8679",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8678",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8677",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8676",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8675",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8635",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8634",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8633",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8632",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8631",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8630",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8629",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8628",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8684",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8673",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8671",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8668",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8669",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8670",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8326",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8331",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8333",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8342",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8341",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8340",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8339",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8338",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8337",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8336",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8335",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8334",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8351",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8350",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8349",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8348",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8347",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8346",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8345",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8344",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8343",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8360",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8359",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8358",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8357",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8356",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8355",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8354",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8353",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8352",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8363",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8362",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8361",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8332",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8330",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8327",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8328",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8329",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8364",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8365",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8366",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8368",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8370",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8372",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8374",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8376",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8382",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8381",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8380",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8379",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8378",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8377",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8375",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8373",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8391",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8390",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8389",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8388",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8387",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8386",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8385",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8384",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8383",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8408",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8407",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8406",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8405",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8404",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8403",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8402",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8401",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8400",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8425",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8424",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8423",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8422",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8421",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8420",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8419",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8418",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8417",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8439",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8438",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8437",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8436",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8435",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8434",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8433",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8432",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8431",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8430",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8429",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8428",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8427",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8426",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8416",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8415",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8414",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8413",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8412",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8411",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8410",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8409",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8399",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8398",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8397",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8396",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8395",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8394",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8393",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8392",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8371",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8369",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8367",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8552",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8555",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8557",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8559",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8561",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8595",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8594",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8593",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8592",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8591",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8590",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8589",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8588",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8587",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8602",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8601",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8600",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8599",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8598",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8597",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8596",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8586",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8585",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8584",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8583",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8582",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8581",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8580",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8570",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8569",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8568",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8567",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8566",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8565",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8564",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8563",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8562",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8551",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8550",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8549",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8548",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8547",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8546",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8545",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8544",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8543",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8542",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8541",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8540",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8539",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8538",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8537",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8536",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8535",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8534",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8533",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8627",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8626",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8625",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8624",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8623",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8622",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8621",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8620",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8619",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8618",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8617",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8616",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8615",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8614",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8613",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8612",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8611",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8610",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8609",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8608",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8607",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8606",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8605",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8604",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8603",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8579",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8578",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8577",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8576",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8575",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8574",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8573",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8572",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8571",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8560",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8558",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8556",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8554",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8553",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5320202",
        "denominacao": "SERVIÇOS DE ENTREGA RÁPIDA",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2500",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4923001",
        "denominacao": "SERVICO DE TAXI",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8690",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4930204",
        "denominacao": "TRANSPORTE RODOVIARIO DE MUDANCAS",
        "descricao": "SERVIÇO DE TRANSPORTE DE CARGAS E ENCOMENDAS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8692",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5320201",
        "denominacao": "SERVICOS DE MALOTE NAO REALIZADOS PELO CORREIO NACIONAL",
        "descricao": "SERVIÇOS DE TRANSPORTE DE VALORES, MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "11543",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5320201",
        "denominacao": "SERVICOS DE MALOTE NAO REALIZADOS PELO CORREIO NACIONAL",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "11542",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5320201",
        "denominacao": "SERVICOS DE MALOTE NAO REALIZADOS PELO CORREIO NACIONAL",
        "descricao": "SERVIÇOS DE TRANSPORTE DE MATERIAIS DE CONST. E ENTULHOS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "11541",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5320201",
        "denominacao": "SERVICOS DE MALOTE NAO REALIZADOS PELO CORREIO NACIONAL",
        "descricao": "SERVIÇOS DE REBOQUE DE VEÍCULOS",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "11540",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5320201",
        "denominacao": "SERVICOS DE MALOTE NAO REALIZADOS PELO CORREIO NACIONAL",
        "descricao": "SERVIÇOS DE PASSEIOS MARÍTIMOS",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "11539",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5320201",
        "denominacao": "SERVICOS DE MALOTE NAO REALIZADOS PELO CORREIO NACIONAL",
        "descricao": "SERVIÇO DE TRANSPORTE ESCOLAR DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "11538",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5320201",
        "denominacao": "SERVICOS DE MALOTE NAO REALIZADOS PELO CORREIO NACIONAL",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL (TARIFA DO STCM)",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "11537",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5320201",
        "denominacao": "SERVICOS DE MALOTE NAO REALIZADOS PELO CORREIO NACIONAL",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "11536",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5320201",
        "denominacao": "SERVICOS DE MALOTE NAO REALIZADOS PELO CORREIO NACIONAL",
        "descricao": "SERVIÇO DE TRANSPORTE DE NATUREZA MUNICIPAL (SERVIÇO DE TÁXI)",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "11535",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5320201",
        "denominacao": "SERVICOS DE MALOTE NAO REALIZADOS PELO CORREIO NACIONAL",
        "descricao": "SERVIÇO DE TRANSPORTE DE MUDANÇAS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "11534",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5320201",
        "denominacao": "SERVICOS DE MALOTE NAO REALIZADOS PELO CORREIO NACIONAL",
        "descricao": "SERVIÇO DE TRANSPORTE DE CARGAS E ENCOMENDAS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "11533",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5320201",
        "denominacao": "SERVICOS DE MALOTE NAO REALIZADOS PELO CORREIO NACIONAL",
        "descricao": "SERVIÇO DE TRANSPORTE AÉREO DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "11532",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4930201",
        "denominacao": "TRANSPORTE RODOVIARIO DE CARGA, EXCETO PRODUTOS PERIGOSOS E MUDANCAS, MUNICIPAL",
        "descricao": "SERVIÇOS DE REBOQUE DE VEÍCULOS",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8839",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5229002",
        "denominacao": "SERVICOS DE REBOQUE DE VEICULOS",
        "descricao": "SERVIÇOS DE REBOQUE DE VEÍCULOS",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8838",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4950700",
        "denominacao": "TRENS TURISTICOS, TELEFERICOS E SIMILARES",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8709",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5011401",
        "denominacao": "TRANSPORTE MARITIMO DE CABOTAGEM - CARGA",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8708",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5011402",
        "denominacao": "TRANSPORTE MARITIMO DE CABOTAGEM - PASSAGEIROS",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8707",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5012202",
        "denominacao": "TRANSPORTE MARITIMO DE LONGO CURSO - PASSAGEIROS",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8706",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5012201",
        "denominacao": "TRANSPORTE MARITIMO DE LONGO CURSO - CARGA",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8705",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5021101",
        "denominacao": "TRANSPORTE POR NAVEGACAO INTERIOR DE CARGA, MUNICIPAL, EXCETO TRAVESSIA",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8704",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5022001",
        "denominacao": "TRANSPORTE POR NAVEGACAO INTERIOR DE PASSAGEIROS EM LINHAS REGULARES, MUNICIPAL, EXCETO TRAVESSIA",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8703",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5030101",
        "denominacao": "NAVEGACAO DE APOIO MARITIMO",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8702",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5030102",
        "denominacao": "NAVEGACAO DE APOIO PORTUARIO",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8701",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4940000",
        "denominacao": "TRANSPORTE DUTOVIARIO",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8710",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5091201",
        "denominacao": "TRANSPORTE POR NAVEGACAO DE TRAVESSIA, MUNICIPAL",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8700",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5099801",
        "denominacao": "TRANSPORTE AQUAVIARIO PARA PASSEIOS TURISTICOS",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8699",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5099899",
        "denominacao": "OUTROS TRANSPORTES AQUAVIARIOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8698",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5111100",
        "denominacao": "TRANSPORTE AEREO DE PASSAGEIROS REGULAR",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8697",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5112999",
        "denominacao": "OUTROS SERVICOS DE TRANSPORTE AEREO DE PASSAGEIROS NAO-REGULAR",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8696",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5120000",
        "denominacao": "TRANSPORTE AEREO DE CARGA",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8695",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4930201",
        "denominacao": "TRANSPORTE RODOVIARIO DE CARGA, EXCETO PRODUTOS PERIGOSOS E MUDANCAS, MUNICIPAL",
        "descricao": "SERVIÇO DE TRANSPORTE DE CARGAS E ENCOMENDAS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8694",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4930203",
        "denominacao": "TRANSPORTE RODOVIARIO DE PRODUTOS PERIGOSOS",
        "descricao": "SERVIÇO DE TRANSPORTE DE CARGAS E ENCOMENDAS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8693",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4921301",
        "denominacao": "TRANSPORTE RODOVIARIO COLETIVO DE PASSAGEIROS, COM ITINERARIO FIXO, MUNICIPAL",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8691",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4923002",
        "denominacao": "SERVICO DE TRANSPORTE DE PASSAGEIROS - LOCACAO DE AUTOMOVEIS COM MOTORISTA",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8689",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4929999",
        "denominacao": "OUTROS TRANSPORTES RODOVIARIOS DE PASSAGEIROS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8685",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4929903",
        "denominacao": "ORGANIZACAO DE EXCURSOES EM VEICULOS RODOVIARIOS PROPRIOS, MUNICIPAL",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8686",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4924800",
        "denominacao": "TRANSPORTE ESCOLAR",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8688",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4929901",
        "denominacao": "TRANSPORTE RODOVIARIO COLETIVO DE PASSAGEIROS, SOB REGIME DE FRETAMENTO, MUNICIPAL",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "8687",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7416002",
        "denominacao": "ATIVIDADES DE ASSESSORIA EM GESTÃO EMPRESARIAL",
        "descricao": "SERVIÇOS DE ANÁLISE, EXAME, PESQUISA, COLETA, COMPILAÇÃO E FORNECIMENTO DE DADOS E INFORMAÇÕES DE QUALQUER NATUREZA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1155",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7416002",
        "denominacao": "ATIVIDADES DE ASSESSORIA EM GESTÃO EMPRESARIAL",
        "descricao": "SERVIÇOS DE ANÁLISE, EXAME, PESQUISA, COLETA, COMPILAÇÃO E FORNECIMENTO DE DADOS E INFORMAÇÕES DE QUALQUER NATUREZA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2505",
        "dtcancelamento": ""
    },
    {
        "subclasse": "0322107",
        "denominacao": "ATIVIDADES DE APOIO À AQÜICULTURA EM ÁGUA DOCE",
        "descricao": "SERVIÇOS DE ASSESSORIA OU CONSULTORIA DE QUALQUER NATUREZA, NÃO CONTIDA EM OUTROS ITENS DESTA LISTA"
    },
    {
        "subclasse": "0321305",
        "denominacao": "ATIVIDADES DE APOIO À AQÜICULTURA EM ÁGUA SALGADA E SALOBRA",
        "descricao": "SERVIÇOS DE ASSESSORIA OU CONSULTORIA DE QUALQUER NATUREZA, NÃO CONTIDA EM OUTROS ITENS DESTA LISTA"
    },
    {
        "subclasse": "8299701",
        "denominacao": "MEDIÇÃO DE CONSUMO DE ENERGIA ELÉTRICA, GÁS E ÁGUA",
        "descricao": "SERVIÇOS DE ASSESSORIA OU CONSULTORIA DE QUALQUER NATUREZA, NÃO CONTIDA EM OUTROS ITENS DESTA LISTA"
    },
    {
        "subclasse": "8660700",
        "denominacao": "ATIVIDADES DE APOIO À GESTÃO DE SAÚDE",
        "descricao": "SERVIÇOS DE ASSESSORIA OU CONSULTORIA DE QUALQUER NATUREZA, NÃO CONTIDA EM OUTROS ITENS DESTA LISTA"
    },
    {
        "subclasse": "6629100",
        "denominacao": "ATIVIDADES AUXILIARES DOS SEGUROS, DA PREVIDÊNCIA COMPLEMENTAR E DOS PLANOS DE SAÚDE NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "ASSESSORIA OU CONSULTORIA DE QUALQUER NATUREZA, NÃO CONTIDA EM OUTROS ITENS DESTA LISTA"
    },
    {
        "subclasse": "6399200",
        "denominacao": "OUTRAS ATIVIDADES DE PRESTAÇÃO DE SERVIÇOS DE INFORMAÇÃO NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE INFORMAÇÕES DE QUALQUER NATUREZA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11718",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7020400",
        "denominacao": "ATIVIDADES DE CONSULTORIA EM GESTAO EMPRESARIAL, EXCETO CONSULTORIA TECNICA ESPECIFICA",
        "descricao": "SERVIÇOS DE PLANEJAMENTO FINANCEIRO OU ADMINISTRATIVO",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8718",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7119702",
        "denominacao": "ATIVIDADES DE ESTUDOS GEOLOGICOS",
        "descricao": "SERVIÇOS DE ANÁLISE, EXAME, PESQUISA, COLETA, COMPILAÇÃO E FORNECIMENTO DE DADOS E INFORMAÇÕES DE QUALQUER NATUREZA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8717",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7210000",
        "denominacao": "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIENCIAS FISICAS E NATURAIS",
        "descricao": "SERVIÇOS DE ANÁLISE, EXAME, PESQUISA, COLETA, COMPILAÇÃO E FORNECIMENTO DE DADOS E INFORMAÇÕES DE QUALQUER NATUREZA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8716",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7220700",
        "denominacao": "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIENCIAS SOCIAIS E HUMANAS",
        "descricao": "SERVIÇOS DE ANÁLISE, EXAME, PESQUISA, COLETA, COMPILAÇÃO E FORNECIMENTO DE DADOS E INFORMAÇÕES DE QUALQUER NATUREZA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8715",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7320300",
        "denominacao": "PESQUISAS DE MERCADO E DE OPINIAO PUBLICA",
        "descricao": "SERVIÇOS DE ANÁLISE, EXAME, PESQUISA, COLETA, COMPILAÇÃO E FORNECIMENTO DE DADOS E INFORMAÇÕES DE QUALQUER NATUREZA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8714",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9101500",
        "denominacao": "ATIVIDADES DE BIBLIOTECAS E ARQUIVOS",
        "descricao": "SERVIÇOS DE ANÁLISE, EXAME, PESQUISA, COLETA, COMPILAÇÃO E FORNECIMENTO DE DADOS E INFORMAÇÕES DE QUALQUER NATUREZA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8713",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5229099",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES TERRESTRES NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ORGANIZAÇÃO LOGÍSTICA DE TRANSPORTE DE CARGAS",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8712",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5250805",
        "denominacao": "OPERADOR DE TRANSPORTE MULTIMODAL - OTM",
        "descricao": "SERVIÇOS DE ORGANIZAÇÃO LOGÍSTICA DE TRANSPORTE DE CARGAS",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8711",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3511500",
        "denominacao": "GERACAO DE ENERGIA ELETRICA",
        "descricao": "SERVIÇOS DE MEDIÇÃO DE CONSUMO DE ENERGIA ELÉTRICA, GÁS E ÁGUA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8164",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5232000",
        "denominacao": "ATIVIDADES DE AGENCIAMENTO MARITIMO",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8733",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5250801",
        "denominacao": "COMISSARIA DE DESPACHOS",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8732",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6204000",
        "denominacao": "CONSULTORIA EM TECNOLOGIA DA INFORMACAO",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8731",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6621502",
        "denominacao": "AUDITORIA E CONSULTORIA ATUARIAL",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8730",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6911701",
        "denominacao": "SERVICOS ADVOCATICIOS",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8729",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6920602",
        "denominacao": "ATIVIDADES DE CONSULTORIA E AUDITORIA CONTABIL E TRIBUTARIA",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8728",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7020400",
        "denominacao": "ATIVIDADES DE CONSULTORIA EM GESTAO EMPRESARIAL, EXCETO CONSULTORIA TECNICA ESPECIFICA",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8727",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7111100",
        "denominacao": "SERVICOS DE ARQUITETURA",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8726",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8725",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7319004",
        "denominacao": "CONSULTORIA EM PUBLICIDADE",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8724",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7490103",
        "denominacao": "SERVICOS DE AGRONOMIA E DE CONSULTORIA AS ATIVIDADES AGRICOLAS E PECUARIAS",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8723",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7490199",
        "denominacao": "OUTRAS ATIVIDADES PROFISSIONAIS, CIENTIFICAS E TECNICAS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8722",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7911200",
        "denominacao": "AGENCIAS DE VIAGENS",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8721",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8550302",
        "denominacao": "ATIVIDADES DE APOIO A EDUCACAO, EXCETO CAIXAS ESCOLARES",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8720",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7020400",
        "denominacao": "ATIVIDADES DE CONSULTORIA EM GESTAO EMPRESARIAL, EXCETO CONSULTORIA TECNICA ESPECIFICA",
        "descricao": "SERVIÇOS DE RELAÇÕES PÚBLICAS",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8719",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7416002",
        "denominacao": "ATIVIDADES DE ASSESSORIA EM GESTÃO EMPRESARIAL",
        "descricao": "SERVIÇOS DE RELAÇÕES PÚBLICAS",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2507",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7416002",
        "denominacao": "ATIVIDADES DE ASSESSORIA EM GESTÃO EMPRESARIAL",
        "descricao": "SERVIÇOS DE PLANEJAMENTO FINANCEIRO OU ADMINISTRATIVO",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2506",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7416002",
        "denominacao": "ATIVIDADES DE ASSESSORIA EM GESTÃO EMPRESARIAL",
        "descricao": "SERVIÇOS DE RELAÇÕES PÚBLICAS",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1157",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7416002",
        "denominacao": "ATIVIDADES DE ASSESSORIA EM GESTÃO EMPRESARIAL",
        "descricao": "SERVIÇOS DE PLANEJAMENTO FINANCEIRO OU ADMINISTRATIVO",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1156",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8211300",
        "denominacao": "SERVIÇOS COMBINADOS DE ESCRITÓRIO E APOIO ADMINISTRATIVO",
        "descricao": "SERVIÇOS DE SECRETARIA",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "2511",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5819100",
        "denominacao": "EDIÇÃO DE CADASTROS, LISTAS E OUTROS PRODUTOS GRÁFICOS",
        "descricao": "SERVIÇO DE EDICAO DE CADASTROS, LISTAS E OUTROS PRODUTOS GRAFICOS",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7054",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5813100",
        "denominacao": "EDICAO DE REVISTAS",
        "descricao": "EDICAO DE REVISTAS",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7053",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5812300",
        "denominacao": "EDIÇÃO DE JORNAIS",
        "descricao": "SERVIÇOS DE REDAÇÃO, REVISÃO E EDIÇÃO",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7052",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5821200",
        "denominacao": "EDICAO INTEGRADA A IMPRESSAO DE LIVROS",
        "descricao": "EDICAO INTEGRADA A IMPRESSAO DE LIVROS",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "11785",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5811500",
        "denominacao": "EDIÇÃO DE LIVROS",
        "descricao": "SERVIÇOS DE DATILOGRAFIA, DIGITAÇÃO, ESTENOGRAFIA, EXPEDIENTE, SECRETARIA EM GERAL, RESPOSTA AUDÍVEL, REDAÇÃO, EDIÇÃO, INTERPRETAÇÃO, REVISÃO, TRADUÇÃO, APOIO E INFRAESTRUTURA ADMINISTRATIVA E CONGÊNERES",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "11776",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5829800",
        "denominacao": "EDIÇÃO INTEGRADA À IMPRESSÃO DE CADASTROS, LISTAS E DE OUTROS PRODUTOS GRÁFICOS",
        "descricao": "SERVIÇOS DE DATILOGRAFIA, DIGITAÇÃO, ESTENOGRAFIA, EXPEDIENTE, SECRETARIA EM GERAL, RESPOSTA AUDÍVEL, REDAÇÃO, EDIÇÃO, INTERPRETAÇÃO, REVISÃO, TRADUÇÃO, APOIO E INFRAESTRUTURA ADMINISTRATIVA E CONGÊNERES",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "11774",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8299799",
        "denominacao": "OUTRAS ATIVIDADES DE SERVIÇOS PRESTADOS PRINCIPALMENTE ÀS EMPRESAS NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE DATILOGRAFIA, DIGITAÇÃO, ESTENOGRAFIA, EXPEDIENTE, SECRETARIA EM GERAL, RESPOSTA AUDÍVEL, REDAÇÃO, EDIÇÃO, INTERPRETAÇÃO, REVISÃO, TRADUÇÃO, APOIO E INFRAESTRUTURA ADMINISTRATIVA E CONGÊNERES",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "11748",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5812302",
        "denominacao": "EDIÇÃO DE JORNAIS NAO DIARIOS",
        "descricao": "SERVIÇOS DE REDAÇÃO, REVISÃO E EDIÇÃO",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "11739",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5822101",
        "denominacao": "EDIÇÃO INTEGRADA À IMPRESSÃO DE JORNAIS  DIARIOS",
        "descricao": "",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "11762",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5822102",
        "denominacao": "EDIÇÃO INTEGRADA À IMPRESSÃO DE JORNAIS PUBLICITARIOS NAO DIARIOS",
        "descricao": "",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "11761",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5822100",
        "denominacao": "EDIÇÃO INTEGRADA À IMPRESSÃO DE JORNAIS",
        "descricao": "",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "7056",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8220200",
        "denominacao": "ATIVIDADES DE TELEATENDIMENTO",
        "descricao": "SERVIÇOS DE SECRETARIA EM GERAL",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "11723",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8219999",
        "denominacao": "PREPARACAO DE DOCUMENTOS E SERVICOS ESPECIALIZADOS DE APOIO ADMINISTRATIVO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE RESPOSTAS AUDÍVEIS",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "8745",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8219999",
        "denominacao": "PREPARACAO DE DOCUMENTOS E SERVICOS ESPECIALIZADOS DE APOIO ADMINISTRATIVO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS ESTENOGRAFIA",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "8744",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8219999",
        "denominacao": "PREPARACAO DE DOCUMENTOS E SERVICOS ESPECIALIZADOS DE APOIO ADMINISTRATIVO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EXPEDIENTE",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "8743",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8219999",
        "denominacao": "PREPARACAO DE DOCUMENTOS E SERVICOS ESPECIALIZADOS DE APOIO ADMINISTRATIVO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE DATILOGRAFIA",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "8742",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8299707",
        "denominacao": "SALAS DE ACESSO A INTERNET",
        "descricao": "SERVIÇOS DE APOIO E INFRAESTRUTURA ADMINISTRATIVA",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "8741",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8219999",
        "denominacao": "PREPARACAO DE DOCUMENTOS E SERVICOS ESPECIALIZADOS DE APOIO ADMINISTRATIVO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE APOIO E INFRAESTRUTURA ADMINISTRATIVA",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "8740",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6311900",
        "denominacao": "TRATAMENTO DE DADOS, PROVEDORES DE SERVICOS DE APLICACAO E SERVICOS DE HOSPEDAGEM NA INTERNET",
        "descricao": "SERVIÇOS DE DIGITAÇÃO",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "8739",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8219999",
        "denominacao": "PREPARACAO DE DOCUMENTOS E SERVICOS ESPECIALIZADOS DE APOIO ADMINISTRATIVO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE DIGITAÇÃO",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "8738",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8299707",
        "denominacao": "SALAS DE ACESSO A INTERNET",
        "descricao": "SERVIÇOS DE DIGITAÇÃO",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "8737",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8219999",
        "denominacao": "PREPARACAO DE DOCUMENTOS E SERVICOS ESPECIALIZADOS DE APOIO ADMINISTRATIVO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE SECRETARIA",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "8736",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7490101",
        "denominacao": "SERVICOS DE TRADUCAO, INTERPRETACAO E SIMILARES",
        "descricao": "SERVIÇOS DE TRADUÇÕES E INTERPRETAÇÕES",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "8735",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7490101",
        "denominacao": "SERVICOS DE TRADUCAO, INTERPRETACAO E SIMILARES",
        "descricao": "SERVIÇOS DE REDAÇÃO, REVISÃO E EDIÇÃO",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "8734",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7020400",
        "denominacao": "ATIVIDADES DE CONSULTORIA EM GESTAO EMPRESARIAL, EXCETO CONSULTORIA TECNICA ESPECIFICA",
        "descricao": "SERVIÇOS DE PLANEJAMENTO, COORDENAÇÃO, PROGRAMAÇÃO OU ORGANIZAÇÃO TÉCNICA, FINANCEIRA OU ADMINISTRATIVA",
        "item": "17",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "8746",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7810800",
        "denominacao": "SELECAO E AGENCIAMENTO DE MAO-DE-OBRA",
        "descricao": "SERVIÇOS DE RECRUTAMENTO, AGENCIAMENTO, SELEÇÃO E COLOCAÇÃO DE MÃO-DE-OBRA",
        "item": "17",
        "subitem": "4",
        "aliquota": "2,5",
        "idcnae": "8747",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8111700",
        "denominacao": "SERVIÇOS COMBINADOS PARA APOIO A EDIFÍCIOS, EXCETO CONDOMÍNIOS PREDIAIS",
        "descricao": "SERVIÇOS COMBINADOS PARA APOIO A EDIFÍCIOS, EXCETO CONDOMÍNIOS PREDIAIS",
        "item": "17",
        "subitem": "5",
        "aliquota": "2,5",
        "idcnae": "7164",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7820500",
        "denominacao": "LOCACAO DE MAO-DE-OBRA TEMPORARIA",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE MÃO-DE-OBRA",
        "item": "17",
        "subitem": "5",
        "aliquota": "2,5",
        "idcnae": "8748",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7830200",
        "denominacao": "FORNECIMENTO E GESTAO DE RECURSOS HUMANOS PARA TERCEIROS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE MÃO-DE-OBRA",
        "item": "17",
        "subitem": "5",
        "aliquota": "2,5",
        "idcnae": "8749",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9319199",
        "denominacao": "OUTRAS ATIVIDADES ESPORTIVAS NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "OUTRAS ATIVIDADES ESPORTIVAS NÃO ESPECIFICADAS ANTERIORMENTE",
        "item": "17",
        "subitem": "5",
        "aliquota": "2,5",
        "idcnae": "7223",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7440301",
        "denominacao": "AGÊNCIAS DE PUBLICIDADE E PROPAGANDA",
        "descricao": "SERVIÇOS DE PUBLICIDADE E PROPAGANDA",
        "item": "17",
        "subitem": "6",
        "aliquota": "2,5",
        "idcnae": "1171",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9499500",
        "denominacao": "ATIVIDADES ASSOCIATIVAS NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE PROPAGANDA E PUBLICIDADE, INCLUSIVE PROMOÇÃO DE VENDAS, PLANEJAMENTO DE CAMPANHAS OU SISTEMAS DE PUBLICIDADE, ELABORAÇÃO DE DESENHOS, TEXTOS E DEMAIS MATERIAIS PUBLICITÁRIOS",
        "item": "17",
        "subitem": "6",
        "aliquota": "2,5",
        "idcnae": "11734",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9411100",
        "denominacao": "ATIVIDADES DE ORGANIZAÇÕES ASSOCIATIVAS PATRONAIS E EMPRESARIAIS",
        "descricao": "SERVIÇOS DE PROPAGANDA E PUBLICIDADE, INCLUSIVE PROMOÇÃO DE VENDAS, PLANEJAMENTO DE CAMPANHAS OU SISTEMAS DE PUBLICIDADE, ELABORAÇÃO DE DESENHOS, TEXTOS E DEMAIS MATERIAIS PUBLICITÁRIOS",
        "item": "17",
        "subitem": "6",
        "aliquota": "2,5",
        "idcnae": "11733",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7319099",
        "denominacao": "OUTRAS ATIVIDADES DE PUBLICIDADE NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE PROPAGANDA E PUBLICIDADE, INCLUSIVE PROMOÇÃO DE VENDAS, PLANEJAMENTO DE CAMPANHAS OU SISTEMAS DE PUBLICIDADE, ELABORAÇÃO DE DESENHOS, TEXTOS E DEMAIS MATERIAIS PUBLICITÁRIOS",
        "item": "17",
        "subitem": "6",
        "aliquota": "2,5",
        "idcnae": "11732",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7311400",
        "denominacao": "AGENCIAS DE PUBLICIDADE",
        "descricao": "SERVIÇOS DE PUBLICIDADE E PROPAGANDA",
        "item": "17",
        "subitem": "6",
        "aliquota": "2,5",
        "idcnae": "8750",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7319002",
        "denominacao": "PROMOÇÃO DE VENDAS",
        "descricao": "PROMOÇÃO DE VENDAS",
        "item": "17",
        "subitem": "6",
        "aliquota": "2,5",
        "idcnae": "7132",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7319003",
        "denominacao": "MARKETING DIRETO",
        "descricao": "MARKETING DIRETO",
        "item": "17",
        "subitem": "6",
        "aliquota": "2,5",
        "idcnae": "7133",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7740300",
        "denominacao": "GESTAO DE ATIVOS INTANGIVEIS NAO-FINANCEIROS",
        "descricao": "SERVIÇOS DE FRANQUIAS (FRANCHISING)",
        "item": "17",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "8751",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7119704",
        "denominacao": "SERVICOS DE PERICIA TECNICA RELACIONADOS A SEGURANCA DO TRABALHO",
        "descricao": "SERVIÇOS DE PERÍCIAS, LAUDOS, EXAMES TÉCNICOS E ANÁLISES TÉCNICAS",
        "item": "17",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "8752",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS DE PERÍCIAS, LAUDOS, EXAMES TÉCNICOS E ANÁLISES TÉCNICAS",
        "item": "17",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "8753",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6621501",
        "denominacao": "PERITOS E AVALIADORES DE SEGUROS",
        "descricao": "SERVIÇOS DE PERÍCIAS, LAUDOS, EXAMES TÉCNICOS E ANÁLISES TÉCNICAS",
        "item": "17",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "8755",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6911702",
        "denominacao": "ATIVIDADES AUXILIARES DA JUSTICA",
        "descricao": "SERVIÇOS DE PERÍCIAS, LAUDOS, EXAMES TÉCNICOS E ANÁLISES TÉCNICAS",
        "item": "17",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "8754",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8230001",
        "denominacao": "SERVICOS DE ORGANIZACAO DE FEIRAS, CONGRESSOS, EXPOSICOES E FESTAS",
        "descricao": "SERVIÇOS DE PLANEJAMENTO, ORGANIZAÇÃO E ADMINISTRAÇÃO DE FEIRAS, EXPOSIÇÕES E CONGRESSOS",
        "item": "17",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "8756",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5620102",
        "denominacao": "SERVICOS DE ALIMENTACAO PARA EVENTOS E RECEPCOES - BUFE",
        "descricao": "SERVIÇOS DE BUFÊ",
        "item": "17",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "8757",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8230002",
        "denominacao": "CASAS DE FESTAS E EVENTOS",
        "descricao": "SERVIÇOS DE ORGANIZAÇÃO DE FESTAS E RECEPÇÕES",
        "item": "17",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "8759",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8230001",
        "denominacao": "SERVICOS DE ORGANIZACAO DE FEIRAS, CONGRESSOS, EXPOSICOES E FESTAS",
        "descricao": "SERVIÇOS DE ORGANIZAÇÃO DE FESTAS E RECEPÇÕES",
        "item": "17",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "8758",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8230002",
        "denominacao": "CASAS DE FESTAS E EVENTOS",
        "descricao": "SERVIÇOS DE ORGANIZAÇÃO DE FESTAS E RECEPÇÕES"
    },
    {
        "subclasse": "5920100",
        "denominacao": "ATIVIDADES DE GRAVACAO DE SOM E DE EDICAO DE MUSICA",
        "descricao": "SERVIÇOS DE GESTÃO DE DIREITOS AUTORAIS",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "8166",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8299799",
        "denominacao": "OUTRAS ATIVIDADES DE SERVICOS PRESTADOS PRINCIPALMENTE AS EMPRESAS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "8761",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7740300",
        "denominacao": "GESTAO DE ATIVOS INTANGIVEIS NAO-FINANCEIROS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "8763",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6911702",
        "denominacao": "ATIVIDADES AUXILIARES DA JUSTICA",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "8765",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8660700",
        "denominacao": "ATIVIDADES DE APOIO À GESTÃO DE SAÚDE",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO EM GERAL, INCLUSIVE DE BENS E NEGÓCIOS DE TERCEIROS",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "11773",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9103100",
        "denominacao": "ATIVIDADES DE JARDINS BOTÂNICOS, ZOOLÓGICOS, PARQUES NACIONAIS, RESERVAS ECOLÓGICAS E ÁREAS DE PROTEÇÃO AMBIENTAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "11720",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6822600",
        "denominacao": "GESTAO E ADMINISTRACAO DA PROPRIEDADE IMOBILIARIA",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONDOMÍNIOS",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "8781",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6440900",
        "denominacao": "ARRENDAMENTO MERCANTIL",
        "descricao": "SERVIÇOS DE LICENCIAMENTO, COMPRA E VENDA E LEASING DE ATIVOS INTANGÍVEIS NÃO FINANCEIROS",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "8780",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4399101",
        "denominacao": "ADMINISTRACAO DE OBRAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "8779",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5221400",
        "denominacao": "CONCESSIONARIAS DE RODOVIAS, PONTES, TUNEIS E SERVICOS RELACIONADOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "8778",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5231101",
        "denominacao": "ADMINISTRACAO DA INFRA-ESTRUTURA PORTUARIA",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "8777",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5510801",
        "denominacao": "HOTEIS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "8776",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5920100",
        "denominacao": "ATIVIDADES DE GRAVACAO DE SOM E DE EDICAO DE MUSICA",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "8775",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6462000",
        "denominacao": "HOLDINGS DE INSTITUICOES NAO-FINANCEIRAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "8774",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6611801",
        "denominacao": "BOLSA DE VALORES",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "8773",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6611804",
        "denominacao": "ADMINISTRACAO DE MERCADOS DE BALCAO ORGANIZADOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "8772",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6612601",
        "denominacao": "CORRETORAS DE TITULOS E VALORES MOBILIARIOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "8771",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6613400",
        "denominacao": "ADMINISTRACAO DE CARTOES DE CREDITO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "8770",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6629100",
        "denominacao": "ATIVIDADES AUXILIARES DOS SEGUROS, DA PREVIDENCIA COMPLEMENTAR E DOS PLANOS DE SAUDE NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "8769",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6630400",
        "denominacao": "ATIVIDADES DE ADMINISTRACAO DE FUNDOS POR CONTRATO OU COMISSAO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "8768",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6810202",
        "denominacao": "ALUGUEL DE IMOVEIS PROPRIOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "8767",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6822600",
        "denominacao": "GESTAO E ADMINISTRACAO DA PROPRIEDADE IMOBILIARIA",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "8766",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6911703",
        "denominacao": "AGENTE DE PROPRIEDADE INDUSTRIAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "8764",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8299702",
        "denominacao": "EMISSAO DE VALES-ALIMENTACAO, VALES-TRANSPORTE E SIMILARES",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "8762",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8411600",
        "denominacao": "ADMINISTRACAO PUBLICA EM GERAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "8760",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5913800",
        "denominacao": "DISTRIBUICAO CINEMATOGRAFICA, DE VIDEO E DE PROGRAMAS DE TELEVISAO",
        "descricao": "SERVIÇOS DE GESTÃO DE DIREITOS AUTORAIS",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "8167",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5811500",
        "denominacao": "EDICAO DE LIVROS",
        "descricao": "SERVIÇOS DE GESTÃO DE DIREITOS AUTORAIS",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "8168",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6630400",
        "denominacao": "ATIVIDADES DE ADMINISTRACAO DE FUNDOS POR CONTRATO OU COMISSAO",
        "descricao": "SERVIÇOS DE GESTÃO DE FUNDOS",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "8169",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8299704",
        "denominacao": "LEILOEIROS INDEPENDENTES",
        "descricao": "SERVIÇOS DE REALIZAÇÕES DE LEILÕES",
        "item": "17",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "8782",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6911701",
        "denominacao": "SERVICOS ADVOCATICIOS",
        "descricao": "SERVIÇOS ADVOCATÍCIOS",
        "item": "17",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "8783",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6911702",
        "denominacao": "ATIVIDADES AUXILIARES DA JUSTICA",
        "descricao": "SERVIÇOS DE ARBITRAGEM",
        "item": "17",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "8784",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6621502",
        "denominacao": "AUDITORIA E CONSULTORIA ATUARIAL",
        "descricao": "SERVIÇOS DE AUDITORIA ",
        "item": "17",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8785",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6621501",
        "denominacao": "PERITOS E AVALIADORES DE SEGUROS",
        "descricao": "SERVIÇOS DE AUDITORIA ",
        "item": "17",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8787",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6920602",
        "denominacao": "ATIVIDADES DE CONSULTORIA E AUDITORIA CONTABIL E TRIBUTARIA",
        "descricao": "SERVIÇOS DE AUDITORIA ",
        "item": "17",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "8786",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7020400",
        "denominacao": "ATIVIDADES DE CONSULTORIA EM GESTAO EMPRESARIAL, EXCETO CONSULTORIA TECNICA ESPECIFICA",
        "descricao": "SERVIÇOS DE ANÁLISE DE ORGANIZAÇÃO E MÉTODOS",
        "item": "17",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "8788",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6621502",
        "denominacao": "AUDITORIA E CONSULTORIA ATUARIAL",
        "descricao": "SERVIÇOS ATUARIAIS E CÁLCULOS TÉCNICOS DE QUALQUER NATUREZA",
        "item": "17",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "8789",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7412801",
        "denominacao": "ATIVIDADES DE CONTABILIDADE",
        "descricao": "SERVIÇOS DE CONTABILIDADE",
        "item": "17",
        "subitem": "19",
        "aliquota": "2,5",
        "idcnae": "1084",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6920601",
        "denominacao": "ATIVIDADES DE CONTABILIDADE",
        "descricao": "SERVIÇOS DE CONTABILIDADE",
        "item": "17",
        "subitem": "19",
        "aliquota": "2,5",
        "idcnae": "8790",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7020400",
        "denominacao": "ATIVIDADES DE CONSULTORIA EM GESTAO EMPRESARIAL, EXCETO CONSULTORIA TECNICA ESPECIFICA",
        "descricao": "SERVIÇOS DE CONSULTORIA E ASSESSORIA ECONÔMICA OU FINANCEIRA",
        "item": "17",
        "subitem": "20",
        "aliquota": "5",
        "idcnae": "8791",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7320300",
        "denominacao": "PESQUISAS DE MERCADO E DE OPINIAO PUBLICA",
        "descricao": "SERVIÇOS DE PESQUISA DE MERCADO E OPINIÃO",
        "item": "17",
        "subitem": "21",
        "aliquota": "5",
        "idcnae": "8792",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7490199",
        "denominacao": "OUTRAS ATIVIDADES PROFISSIONAIS, CIENTIFICAS E TECNICAS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ESTATÍSTICA  ",
        "item": "17",
        "subitem": "21",
        "aliquota": "5",
        "idcnae": "8793",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7320300",
        "denominacao": "PESQUISAS DE MERCADO E DE OPINIAO PUBLICA",
        "descricao": "SERVIÇOS DE ESTATÍSTICA  ",
        "item": "17",
        "subitem": "21",
        "aliquota": "5",
        "idcnae": "8794",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8291100",
        "denominacao": "ATIVIDADES DE COBRANÇAS E INFORMAÇÕES CADASTRAIS",
        "descricao": "SERVIÇOS DE COBRANÇA",
        "item": "17",
        "subitem": "22",
        "aliquota": "5",
        "idcnae": "11552",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6110801",
        "denominacao": "SERVIÇOS DE TELEFONIA FIXA COMUTADA - STFC",
        "descricao": "SERVIÇOS DE COBRANÇA EM GERAL",
        "item": "17",
        "subitem": "22",
        "aliquota": "5",
        "idcnae": "11727",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6491300",
        "denominacao": "SOCIEDADES DE FOMENTO MERCANTIL - FACTORING",
        "descricao": "SERVIÇOS DE FACTORING",
        "item": "17",
        "subitem": "23",
        "aliquota": "5",
        "idcnae": "8795",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8599604",
        "denominacao": "TREINAMENTO EM DESENVOLVIMENTO PROFISSIONAL E GERENCIAL",
        "descricao": "SERVIÇOS DE APRESENTAÇÃO DE PALESTRAS, CONFERÊNCIAS E SEMINÁRIOS",
        "item": "17",
        "subitem": "24",
        "aliquota": "5",
        "idcnae": "8796",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6621501",
        "denominacao": "PERITOS E AVALIADORES DE SEGUROS",
        "descricao": "SERVIÇOS DE INSPEÇÃO E AVALIAÇÃO DE RISCOS PARA COBERTURA DE CONTRATOS DE SEGUROS",
        "item": "18",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8797",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6621501",
        "denominacao": "PERITOS E AVALIADORES DE SEGUROS",
        "descricao": "SERVIÇOS DE PREVENÇÃO E GERÊNCIA DE RISCOS SEGURÁVEIS",
        "item": "18",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8799",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6621501",
        "denominacao": "PERITOS E AVALIADORES DE SEGUROS",
        "descricao": "SERVIÇOS DE REGULAÇÃO DE SINISTROS VINCULADOS À CONTRATOS DE SEGUROS  ",
        "item": "18",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8798",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8299706",
        "denominacao": "CASAS LOTERICAS",
        "descricao": "SERVIÇOS DE DISTRIBUIÇÃO DE BILHETES E DEMAIS PRODUTOS DE LOTERIA, BINGOS, PULES OU CUPONS DE APOSTAS, CARTÕES, SORTEIOS, APOSTAS E PRÊMIOS",
        "item": "19",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8800",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5222200",
        "denominacao": "TERMINAIS RODOVIARIOS E FERROVIARIOS",
        "descricao": "SERVIÇOS FERROPORTUÁRIOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8801",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5231102",
        "denominacao": "OPERACOES DE TERMINAIS",
        "descricao": "SERVIOS DE UTILIZAÇÃO DE PORTOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8805",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5030101",
        "denominacao": "NAVEGACAO DE APOIO MARITIMO",
        "descricao": "SERVIÇOS DE REBOCADOR ESCOTEIRO",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8807",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5232000",
        "denominacao": "ATIVIDADES DE AGENCIAMENTO MARITIMO",
        "descricao": "SERVIÇOS DE ARMADORES",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8809",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5211701",
        "denominacao": "ARMAZENS GERAIS - EMISSAO DE WARRANT",
        "descricao": "SERVIÇOS DE ARMAZENAGEM EM PORTOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8811",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5239700",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIARIOS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ATRACAÇÃO E DESATRACAÇÃO",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8813",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5239799",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIÁRIOS NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS PORTUÁRIOS, FERROPORTUÁRIOS, UTILIZAÇÃO DE PORTO, MOVIMENTAÇÃO DE PASSAGEIROS, REBOQUE DE EMBARCAÇÕES, REBOCADOR ESCOTEIRO, ATRACAÇÃO, DESATRACAÇÃO, SERVIÇOS DE PRATICAGEM, CAPATAZIA, ARMAZENAGEM DE QUALQUER NATUREZA, SERVIÇOS ACESSÓRIOS, MOVIMENTAÇÃO DE MERCADORIAS, SERVIÇOS DE APOIO MARÍTIMO, DE MOVIMENTAÇÃO AO LARGO, SERVIÇOS DE ARMADORES, ESTIVA, CONFERÊNCIA, LOGÍSTICA E CONGÊNERES",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11754",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5231102",
        "denominacao": "OPERACOES DE TERMINAIS",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO AO LARGO",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8820",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5239700",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIARIOS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE PRATICAGEM",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8819",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5231102",
        "denominacao": "OPERACOES DE TERMINAIS",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO DE PASSAGEIROS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8818",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5231102",
        "denominacao": "OPERACOES DE TERMINAIS",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO DE MERCADORIAS EM PORTOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8817",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5030101",
        "denominacao": "NAVEGACAO DE APOIO MARITIMO",
        "descricao": "SERVIÇOS DE LOGISTICA E CONFERÊNCIAS EM PORTOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8816",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5212500",
        "denominacao": "CARGA E DESCARGA",
        "descricao": "SERVIÇOS DE ESTIVA",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8815",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5212500",
        "denominacao": "CARGA E DESCARGA",
        "descricao": "SERVIÇOS DE CAPATAZIA",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8814",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5211799",
        "denominacao": "DEPOSITOS DE MERCADORIAS PARA TERCEIROS, EXCETO ARMAZENS GERAIS E GUARDA-MOVEIS",
        "descricao": "SERVIÇOS DE ARMAZENAGEM EM PORTOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8812",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5231102",
        "denominacao": "OPERACOES DE TERMINAIS",
        "descricao": "SERVIÇOS DE ARMAZENAGEM EM PORTOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8810",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5030101",
        "denominacao": "NAVEGACAO DE APOIO MARITIMO",
        "descricao": "SERVIÇOS DE APOIO MARÍTIMO",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8808",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5030101",
        "denominacao": "NAVEGACAO DE APOIO MARITIMO",
        "descricao": "SERVIÇOS DE REBOQUE DE EMBARCAÇÕES",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8806",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5231101",
        "denominacao": "ADMINISTRACAO DA INFRA-ESTRUTURA PORTUARIA",
        "descricao": "SERVIOS DE UTILIZAÇÃO DE PORTOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8804",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5231102",
        "denominacao": "OPERACOES DE TERMINAIS",
        "descricao": "SERVIÇOS PORTUÁRIOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8802",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5231101",
        "denominacao": "ADMINISTRACAO DA INFRA-ESTRUTURA PORTUARIA",
        "descricao": "SERVIÇOS PORTUÁRIOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8803",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5240199",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AEREOS, EXCETO OPERACAO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS AEROPORTUÁRIOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "8821",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5240199",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AEREOS, EXCETO OPERACAO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE LOGISTICA E CONFERÊNCIAS EM AEROPORTOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "8829",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5212500",
        "denominacao": "CARGA E DESCARGA",
        "descricao": "SERVIÇOS DE CAPATAZIA",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "8834",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5240199",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AEREOS, EXCETO OPERACAO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO DE PASSAGEIROS EM AEROPORTOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "8833",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5240199",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AEREOS, EXCETO OPERACAO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO DE MERCADORIAS EM AEROPORTOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "8832",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5240199",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AEREOS, EXCETO OPERACAO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO DE AERONAVES",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "8831",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5240101",
        "denominacao": "OPERACAO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE LOGISTICA E CONFERÊNCIAS EM AEROPORTOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "8830",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5240199",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AEREOS, EXCETO OPERACAO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE ARMAZENAGEM EM AEROPORTOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "8828",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5240101",
        "denominacao": "OPERACAO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO AEROPORTUÁRIA",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "8823",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5240199",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AEREOS, EXCETO OPERACAO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE UTILIZAÇÃO DE AEROPORTOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "8825",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5240199",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AEREOS, EXCETO OPERACAO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE APOIO AEROPORTUÁRIOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "8826",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5240101",
        "denominacao": "OPERACAO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE APOIO AEROPORTUÁRIOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "8827",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5240101",
        "denominacao": "OPERACAO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE UTILIZAÇÃO DE AEROPORTOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "8824",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5240101",
        "denominacao": "OPERACAO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS AEROPORTUÁRIOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "8822",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5229099",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES TERRESTRES NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO DE MERCADORIAS EM  TERMINAIS RODOVIÁRIOS, FERROVIÁRIOS E METROVIÁRIOS",
        "item": "20",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "8155",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5229099",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES TERRESTRES NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO DE PASSAGEIROS EM  TERMINAIS RODOVIÁRIOS, FERROVIÁRIOS E METROVIÁRIOS",
        "item": "20",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "8837",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5222200",
        "denominacao": "TERMINAIS RODOVIARIOS E FERROVIARIOS",
        "descricao": "SERVIÇOS DE TERMINAIS RODOVIÁRIOS",
        "item": "20",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "8836",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5222200",
        "denominacao": "TERMINAIS RODOVIARIOS E FERROVIARIOS",
        "descricao": "SERVIÇOS DE TERMINAIS FERROVIÁRIOS",
        "item": "20",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "8835",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4912403",
        "denominacao": "TRANSPORTE METROVIARIO",
        "descricao": "SERVIÇOS DE TERMINAIS METROVIÁRIOS",
        "item": "20",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "8156",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5229099",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES TERRESTRES NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE TERMINAIS METROVIÁRIOS",
        "item": "20",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "8158",
        "dtcancelamento": ""
    },
    {
        "subclasse": "4912402",
        "denominacao": "TRANSPORTE FERROVIARIO DE PASSAGEIROS MUNICIPAL E EM REGIAO METROPOLITANA",
        "descricao": "SERVIÇOS DE TERMINAIS METROVIÁRIOS",
        "item": "20",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "8157",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6912500",
        "denominacao": "CARTORIOS",
        "descricao": "SERVIÇOS DE REGISTROS PÚBLICOS, CARTORIAIS E NOTORIAIS",
        "item": "21",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8159",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5221400",
        "denominacao": "CONCESSIONARIAS DE RODOVIAS, PONTES, TUNEIS E SERVICOS RELACIONADOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE RODOVIA MEDIANTE COBRANÇA DE PREÇO OU PEDÁGIO DOS USUÁRIOS",
        "item": "22",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8160",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7410201",
        "denominacao": "DESIGN",
        "descricao": "SERVIÇOS DE DESENHO INDUSTRIAL",
        "item": "23",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8161",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7311400",
        "denominacao": "AGENCIAS DE PUBLICIDADE",
        "descricao": "SERVIÇOS DE PROGRAMAÇÃO E COMUNICAÇÃO VISUAL",
        "item": "23",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8841",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9529102",
        "denominacao": "CHAVEIROS",
        "descricao": "SERVIÇOS DE CHAVEIROS (CHAVEIRO)",
        "item": "24",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8842",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3299099",
        "denominacao": "FABRICACAO DE PRODUTOS DIVERSOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE CHAVEIROS",
        "item": "24",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8845",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9529199",
        "denominacao": "REPARACAO E MANUTENCAO DE OUTROS OBJETOS E EQUIPAMENTOS PESSOAIS E DOMESTICOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE GRAVAÇÃO EM OBJETOS",
        "item": "24",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8847",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3299099",
        "denominacao": "FABRICACAO DE PRODUTOS DIVERSOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE CONFECÇÃO DE PLACAS, SINALIZAÇÃO VISUAL, BANNERS, ADESIVO E CONGÊNERES",
        "item": "24",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8846",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9529102",
        "denominacao": "CHAVEIROS",
        "descricao": "SERVIÇOS DE CHAVEIROS",
        "item": "24",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8844",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8299799",
        "denominacao": "OUTRAS ATIVIDADES DE SERVICOS PRESTADOS PRINCIPALMENTE AS EMPRESAS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE CONFECÇÃO DE CARIMBOS",
        "item": "24",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8843",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8299703",
        "denominacao": "SERVIÇOS DE GRAVAÇÃO DE CARIMBOS, EXCETO CONFECÇÃO   ",
        "descricao": "SERVIÇOS DE GRAVAÇÃO DE CARIMBOS",
        "item": "24",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11719",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9603303",
        "denominacao": "SERVICOS DE SEPULTAMENTO",
        "descricao": "SERVIÇOS DE FUNERAIS, INCLUSIVE FORNECIMENTO DE CAIXÃO, URNA E ESQUIFES",
        "item": "25",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8848",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9603399",
        "denominacao": "ATIVIDADES FUNERARIAS E SERVICOS RELACIONADOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "ALUGUEL DE CAPELA",
        "item": "25",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8850",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9603399",
        "denominacao": "ATIVIDADES FUNERARIAS E SERVICOS RELACIONADOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE FORMECIMENTO DE FLORES EM FUNERAIS, COROAS E OUTROS PARAMENTOS",
        "item": "25",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8851",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9603399",
        "denominacao": "ATIVIDADES FUNERARIAS E SERVICOS RELACIONADOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE FORMECIMENTO DE VÉU E OUTROS ADORNOS",
        "item": "25",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8853",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9603399",
        "denominacao": "ATIVIDADES FUNERARIAS E SERVICOS RELACIONADOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE DESENBARAÇO DE CERTIDÃO DE ÓBITO",
        "item": "25",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8852",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9603305",
        "denominacao": "SERVICOS DE SOMATOCONSERVACAO",
        "descricao": "SERVIÇOS DE EMBALSAMENTO, EMBELEZAMENTO, CONSERVAÇÃO OU RESTAURAÇÃO DE CADÁVERES",
        "item": "25",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8849",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9603302",
        "denominacao": "SERVICOS DE CREMACAO",
        "descricao": "SERVIÇOS DE CREMAÇÃO DE CORPOS E PARTE DE CORPOS CADAVÉRICOS",
        "item": "25",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "8104",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6622300",
        "denominacao": "CORRETORES E AGENTES DE SEGUROS, DE PLANOS DE PREVIDENCIA COMPLEMENTAR E DE SAUDE",
        "descricao": "PLANOS OU CONVÊNIOS FUNERÁRIOS",
        "item": "25",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "8105",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6511102",
        "denominacao": "PLANOS DE AUXILIO-FUNERAL",
        "descricao": "PLANOS OU CONVÊNIOS FUNERÁRIOS",
        "item": "25",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "8107",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8430200",
        "denominacao": "SEGURIDADE SOCIAL OBRIGATORIA",
        "descricao": "PLANOS OU CONVÊNIOS FUNERÁRIOS",
        "item": "25",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "8106",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9603301",
        "denominacao": "GESTAO E MANUTENCAO DE CEMITERIOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE JAZIDOS  E CEMITÉRIOS",
        "item": "25",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "8108",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8012900",
        "denominacao": "ATIVIDADES DE TRANSPORTE DE VALORES",
        "descricao": "SERVICOS DE COLETA, REMESSA OU ENTREGA DE CORRESPONDENCIAS, DOCUMENTOS, OBJETOS, BENS OU VALORES, INCLUSIVE PELOS CORREIOS E SUAS AGENCIAS FRANQUEADAS"
    },
    {
        "subclasse": "8219999",
        "denominacao": "PREPARACAO DE DOCUMENTOS E SERVICOS ESPECIALIZADOS DE APOIO ADMINISTRATIVO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COLETA, REMESSA OU ENTREGA DE CORRESPONDÊNCIAS, DOCUMENTOS, OBJETOS, BENS OU VALORES, INCLUSIVE PELOS CORREIOS E SUAS AGÊNCIAS FRANQUEADAS"
    },
    {
        "subclasse": "5310501",
        "denominacao": "ATIVIDADES DO CORREIO NACIONAL",
        "descricao": "SERVIÇOS DE COLETA, REMESSA OU ENTREGA DE CORRESPONDÊNCIAS, DOCUMENTOS, OBJETOS, BENS OU VALORES, INCLUSIVE PELOS CORREIOS E SUAS AGÊNCIAS FRANQUEADAS"
    },
    {
        "subclasse": "5320202",
        "denominacao": "SERVIÇOS DE ENTREGA RÁPIDA",
        "descricao": "SERVIÇOS DE COLETA, REMESSA OU ENTREGA DE CORRESPONDÊNCIAS, DOCUMENTOS, OBJETOS, BENS OU VALORES, INCLUSIVE PELOS CORREIOS E SUAS AGÊNCIAS FRANQUEADAS"
    },
    {
        "subclasse": "5320201",
        "denominacao": "SERVIÇOS DE MALOTE NÃO REALIZADOS PELO CORREIO NACIONAL",
        "descricao": "SERVIÇOS DE COLETA, REMESSA OU ENTREGA DE CORRESPONDÊNCIAS, DOCUMENTOS, OBJETOS, BENS OU VALORES, INCLUSIVE PELOS CORREIOS E SUAS AGÊNCIAS FRANQUEADAS"
    },
    {
        "subclasse": "5310502",
        "denominacao": "ATIVIDADES DEÂ FRANQUEADAS E PERMISSIONARIAS DO CORREIO NACIONAL",
        "descricao": "SERVIÇOS DE COLETA, REMESSA OU ENTREGA DE CORRESPONDÊNCIAS, DOCUMENTOS, OBJETOS, BENS OU VALORES, INCLUSIVE PELOS CORREIOS E SUAS AGÊNCIAS FRANQUEADAS"
    },
    {
        "subclasse": "5310502",
        "denominacao": "ATIVIDADES DEÂ FRANQUEADAS E PERMISSIONARIAS DO CORREIO NACIONAL",
        "descricao": "SERVIÇO DE COLETA, REMESSA OU ENTREGA DE CORRESPONDÊNCIAS, DOCUMENTOS, OBJETOS, BENS OU VALORES",
        "item": "26",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8109",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8219999",
        "denominacao": "PREPARACAO DE DOCUMENTOS E SERVICOS ESPECIALIZADOS DE APOIO ADMINISTRATIVO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇO DE COLETA, REMESSA OU ENTREGA DE CORRESPONDÊNCIAS, DOCUMENTOS, OBJETOS, BENS OU VALORES",
        "item": "26",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8111",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5310501",
        "denominacao": "ATIVIDADES DO CORREIO NACIONAL",
        "descricao": "SERVIÇO DE COLETA, REMESSA OU ENTREGA DE CORRESPONDÊNCIAS, DOCUMENTOS, OBJETOS, BENS OU VALORES",
        "item": "26",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8110",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8800600",
        "denominacao": "SERVICOS DE ASSISTENCIA SOCIAL SEM ALOJAMENTO",
        "descricao": "SERVIÇOS DE ASSISTÊNCIA SOCIAL",
        "item": "27",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8112",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7031900",
        "denominacao": "CORRETAGEM E AVALIAÇÃO DE IMÓVEIS",
        "descricao": "SERVIÇOS DE AVALIAÇÃO DE BENS E SERVIÇOS DE QUALQUER NATUREZA",
        "item": "28",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1029",
        "dtcancelamento": ""
    },
    {
        "subclasse": "0230600",
        "denominacao": "ATIVIDADES DE APOIO A PRODUCAO FLORESTAL",
        "descricao": "SERVIÇOS DE AVALIAÇÃO DE BENS E SERVIÇOS DE QUALQUER NATUREZA",
        "item": "28",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8117",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6621501",
        "denominacao": "PERITOS E AVALIADORES DE SEGUROS",
        "descricao": "SERVIÇOS DE AVALIAÇÃO DE BENS E SERVIÇOS DE QUALQUER NATUREZA",
        "item": "28",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8116",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6821801",
        "denominacao": "CORRETAGEM NA COMPRA E VENDA E AVALIACAO DE IMOVEIS",
        "descricao": "SERVIÇOS DE AVALIAÇÃO DE BENS E SERVIÇOS DE QUALQUER NATUREZA",
        "item": "28",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8115",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS DE AVALIAÇÃO DE BENS E SERVIÇOS DE QUALQUER NATUREZA",
        "item": "28",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8114",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7490199",
        "denominacao": "OUTRAS ATIVIDADES PROFISSIONAIS, CIENTIFICAS E TECNICAS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE AVALIAÇÃO DE BENS E SERVIÇOS DE QUALQUER NATUREZA",
        "item": "28",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8113",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9101500",
        "denominacao": "ATIVIDADES DE BIBLIOTECAS E ARQUIVOS",
        "descricao": "SERVIÇOS BIBLIOTECÁRIOS",
        "item": "29",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8118",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7310500",
        "denominacao": "PESQUISA E DESENVOLVIMENTO DAS CIÊNCIAS FÍSICAS E NATURAIS",
        "descricao": "SERVIÇOS DE BIOLOGIA",
        "item": "30",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1032",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7310500",
        "denominacao": "PESQUISA E DESENVOLVIMENTO DAS CIÊNCIAS FÍSICAS E NATURAIS",
        "descricao": "SERVIÇOS DE BIOTECNOLOGIA",
        "item": "30",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1033",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7210000",
        "denominacao": "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIENCIAS FISICAS E NATURAIS",
        "descricao": "SERVIÇOS DE QUÍMICA",
        "item": "30",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8121",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7210000",
        "denominacao": "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIENCIAS FISICAS E NATURAIS",
        "descricao": "SERVIÇOS DE BIOTECNOLOGIA",
        "item": "30",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8120",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7210000",
        "denominacao": "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIENCIAS FISICAS E NATURAIS",
        "descricao": "SERVIÇOS DE BIOLOGIA",
        "item": "30",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8119",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7310500",
        "denominacao": "PESQUISA E DESENVOLVIMENTO DAS CIÊNCIAS FÍSICAS E NATURAIS",
        "descricao": "SERVIÇOS DE QUÍMICA",
        "item": "30",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1034",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7119799",
        "denominacao": "ATIVIDADES TECNICAS RELACIONADAS A ENGENHARIA E ARQUITETURA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS TÉCNICOS EM EDIFICAÇÕES",
        "item": "31",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8122",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7119703",
        "denominacao": "SERVICOS DE DESENHO TECNICO RELACIONADOS A ARQUITETURA E ENGENHARIA",
        "descricao": "SERVIÇOS TÉCNICOS EM EDIFICAÇÕES",
        "item": "31",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8123",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS TÉCNICOS EM EDIFICAÇÕES",
        "item": "31",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8124",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7120100",
        "denominacao": "TESTES E ANALISES TECNICAS",
        "descricao": "SERVIÇOS TÉCNICOS EM EDIFICAÇÕES",
        "item": "31",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8126",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6190699",
        "denominacao": "OUTRAS ATIVIDADES DE TELECOMUNICAÇÕES NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE TÉCNICOS EM EDIFICAÇÕES, ELETRÔNICA, ELETROTÉCNICA, MECÂNICA, TELECOMUNICAÇÕES E CONGÊNERES",
        "item": "31",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11791",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS TÉCNICOS EM TELECOMUNICAÇÕES",
        "item": "31",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8130",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS TÉCNICOS EM MECÂNICA",
        "item": "31",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8129",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS TÉCNICOS EM ELETROTÉCNICA",
        "item": "31",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8128",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS TÉCNICOS EM ELETRÓNICA",
        "item": "31",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8127",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7111100",
        "denominacao": "SERVICOS DE ARQUITETURA",
        "descricao": "SERVIÇOS TÉCNICOS EM EDIFICAÇÕES",
        "item": "31",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8125",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7119703",
        "denominacao": "SERVICOS DE DESENHO TECNICO RELACIONADOS A ARQUITETURA E ENGENHARIA",
        "descricao": "SERVIÇOS DE DESENHOS TÉCNICOS",
        "item": "32",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8131",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5250802",
        "denominacao": "ATIVIDADES DE DESPACHANTES ADUANEIROS",
        "descricao": "SERVIÇOS DE DESPACHANTE ADUANEIRO",
        "item": "33",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8132",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8299799",
        "denominacao": "OUTRAS ATIVIDADES DE SERVICOS PRESTADOS PRINCIPALMENTE AS EMPRESAS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE DESPACHANTE",
        "item": "33",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8134",
        "dtcancelamento": ""
    },
    {
        "subclasse": "5250801",
        "denominacao": "COMISSARIA DE DESPACHOS",
        "descricao": "SERVIÇOS DE COMISSÁRIOS",
        "item": "33",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8133",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8030700",
        "denominacao": "ATIVIDADES DE INVESTIGACAO PARTICULAR",
        "descricao": "SERVIÇOS DE DETETIVE",
        "item": "34",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8135",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8030700",
        "denominacao": "ATIVIDADES DE INVESTIGACAO PARTICULAR",
        "descricao": "SERVIÇOS DE INVESTIGAÇÕES PARTICULARES",
        "item": "34",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8136",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7020400",
        "denominacao": "ATIVIDADES DE CONSULTORIA EM GESTAO EMPRESARIAL, EXCETO CONSULTORIA TECNICA ESPECIFICA",
        "descricao": "SERVIÇOS DE ASSESSORIA DE IMPRENSA",
        "item": "35",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8137",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9002701",
        "denominacao": "ATIVIDADES DE ARTISTAS PLASTICOS, JORNALISTAS INDEPENDENTES E ESCRITORES",
        "descricao": "SERVIÇOS DE JORNALISMO",
        "item": "35",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8138",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6391700",
        "denominacao": "AGENCIAS DE NOTICIAS",
        "descricao": "SERVIÇOS DE JORNALISMO",
        "item": "35",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8139",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7020400",
        "denominacao": "ATIVIDADES DE CONSULTORIA EM GESTAO EMPRESARIAL, EXCETO CONSULTORIA TECNICA ESPECIFICA",
        "descricao": "SERVIÇOS DE RELAÇÕES PÚBLICAS",
        "item": "35",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8140",
        "dtcancelamento": ""
    },
    {
        "subclasse": "6391700",
        "denominacao": "AGENCIAS DE NOTICIAS",
        "descricao": "SERVIÇOS DE REPORTAGEM",
        "item": "35",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8142",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9002701",
        "denominacao": "ATIVIDADES DE ARTISTAS PLASTICOS, JORNALISTAS INDEPENDENTES E ESCRITORES",
        "descricao": "SERVIÇOS DE REPORTAGEM",
        "item": "35",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8141",
        "dtcancelamento": ""
    },
    {
        "subclasse": "7490199",
        "denominacao": "OUTRAS ATIVIDADES PROFISSIONAIS, CIENTIFICAS E TECNICAS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE METEOROLOGIA",
        "item": "36",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8143",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9001999",
        "denominacao": "ARTES CENICAS, ESPETACULOS E ATIVIDADES COMPLEMENTARES NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS ARTÍSTICOS",
        "item": "37",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8144",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9001903",
        "denominacao": "PRODUCAO DE ESPETACULOS DE DANCA",
        "descricao": "SERVIÇOS ARTÍSTIVOS",
        "item": "37",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8147",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9001905",
        "denominacao": "PRODUCAO DE ESPETACULOS DE RODEIOS, VAQUEJADAS E SIMILARES",
        "descricao": "SERVIÇOS ARTÍSTIVOS",
        "item": "37",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8145",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9001904",
        "denominacao": "PRODUCAO DE ESPETACULOS CIRCENSES, DE MARIONETES E SIMILARES",
        "descricao": "SERVIÇOS ARTÍSTIVOS",
        "item": "37",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8146",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8299799",
        "denominacao": "OUTRAS ATIVIDADES DE SERVICOS PRESTADOS PRINCIPALMENTE AS EMPRESAS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MODELOS  ",
        "item": "37",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8148",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8299799",
        "denominacao": "OUTRAS ATIVIDADES DE SERVICOS PRESTADOS PRINCIPALMENTE AS EMPRESAS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ATLETAS  ",
        "item": "37",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8150",
        "dtcancelamento": ""
    },
    {
        "subclasse": "8299799",
        "denominacao": "OUTRAS ATIVIDADES DE SERVICOS PRESTADOS PRINCIPALMENTE AS EMPRESAS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MANEQUINS  ",
        "item": "37",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8149",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9102301",
        "denominacao": "ATIVIDADES DE MUSEUS E DE EXPLORACAO DE LUGARES E PREDIOS HISTORICOS E ATRACOES SIMILARES",
        "descricao": "SERVIÇOS DE MUSEOLOGIA",
        "item": "38",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8151",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3250707",
        "denominacao": "FABRICACAO DE ARTIGOS OPTICOS",
        "descricao": "SERVIÇOS DE OURIVESARIA E LAPIDAÇÃO",
        "item": "39",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8152",
        "dtcancelamento": ""
    },
    {
        "subclasse": "2399101",
        "denominacao": "DECORACAO, LAPIDACAO, GRAVACAO, VITRIFICACAO E OUTROS TRABALHOS EM CERAMICA, LOUCA, VIDRO E CRISTAL",
        "descricao": "SERVIÇOS DE OURIVESARIA E LAPIDAÇÃO",
        "item": "39",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8153",
        "dtcancelamento": ""
    },
    {
        "subclasse": "3211601",
        "denominacao": "LAPIDACAO DE GEMAS",
        "descricao": "SERVIÇOS DE OURIVESARIA E LAPIDAÇÃO",
        "item": "39",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8154",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9002701",
        "denominacao": "ATIVIDADES DE ARTISTAS PLASTICOS, JORNALISTAS INDEPENDENTES E ESCRITORES",
        "descricao": "SERVIÇOS DE CONFECÇÃO DE OBRAS DE ARTE SOB ENCOMENDA",
        "item": "40",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "8162",
        "dtcancelamento": ""
    },
    {
        "subclasse": "9003500",
        "denominacao": "GESTAO DE ESPACOS PARA ARTES CENICAS, ESPETACULOS E OUTRAS ATIVIDADES ARTISTICAS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE AUDITÓRIOS E CASAS DE ESPETÁCULOS",
        "item": "3",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "9123",
        "dtcancelamento": "20180711"
    },
    {
        "subclasse": "9003500",
        "denominacao": "GESTAO DE ESPACOS PARA ARTES CENICAS, ESPETACULOS E OUTRAS ATIVIDADES ARTISTICAS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE CENTRO DE CONVENÇÕES OU STANDS",
        "item": "3",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "9125",
        "dtcancelamento": "20180711"
    },
    {
        "subclasse": "8230002",
        "denominacao": "CASAS DE FESTAS E EVENTOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE QUADRAS ESPORTIVAS, ESTÁDIOS, GINÁSIOS E CANCHAS",
        "item": "3",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "11712",
        "dtcancelamento": "20180711"
    },
    {
        "subclasse": "8230002",
        "denominacao": "CASAS DE FESTAS E EVENTOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE CENTRO DE CONVENÇÕES OU STANDS",
        "item": "3",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "11707",
        "dtcancelamento": "20180711"
    },
    {
        "subclasse": "8219999",
        "denominacao": "PREPARACAO DE DOCUMENTOS E SERVICOS ESPECIALIZADOS DE APOIO ADMINISTRATIVO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇO DE COLETA, REMESSA OU ENTREGA DE CORRESPONDÊNCIAS, DOCUMENTOS, OBJETOS, BENS OU VALORES",
        "item": "26",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10209",
        "dtcancelamento": "20140627"
    },
    {
        "subclasse": "8219999",
        "denominacao": "PREPARACAO DE DOCUMENTOS E SERVICOS ESPECIALIZADOS DE APOIO ADMINISTRATIVO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COLETA, REMESSA OU ENTREGA DE CORRESPONDÊNCIAS, DOCUMENTOS, OBJETOS, BENS OU VALORES, INCLUSIVE PELOS CORREIOS E SUAS AGÊNCIAS FRANQUEADAS"
    },
    {
        "subclasse": "8230002",
        "denominacao": "CASAS DE FESTAS E EVENTOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE CENTRO DE CONVENÇÕES OU STANDS",
        "item": "3",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9124",
        "dtcancelamento": "20140430"
    },
    {
        "subclasse": "0111301",
        "denominacao": "CULTIVO DE ARROZ",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE CENTRO DE CONVENÇÕES OU STANDS",
        "item": "3",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9126",
        "dtcancelamento": "20140430"
    },
    {
        "subclasse": "9319101",
        "denominacao": "PRODUCAO E PROMOCAO DE EVENTOS ESPORTIVOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE QUADRAS ESPORTIVAS, ESTÁDIOS, GINÁSIOS E CANCHAS",
        "item": "3",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9130",
        "dtcancelamento": "20140430"
    },
    {
        "subclasse": "8230002",
        "denominacao": "CASAS DE FESTAS E EVENTOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE QUADRAS ESPORTIVAS, ESTÁDIOS, GINÁSIOS E CANCHAS",
        "item": "3",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9131",
        "dtcancelamento": "20140430"
    },
    {
        "subclasse": "9321200",
        "denominacao": "PARQUES DE DIVERSAO E PARQUES TEMATICOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE PARQUES DE DIVERSÕES",
        "item": "3",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9128",
        "dtcancelamento": "20140430"
    },
    {
        "subclasse": "3311200",
        "denominacao": "MANUTENCAO E REPARACAO DE TANQUES, RESERVATORIOS METALICOS E CALDEIRAS, EXCETO PARA VEICULOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE CENTRO DE CONVENÇÕES OU STANDS",
        "item": "3",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9127",
        "dtcancelamento": "20140430"
    },
    {
        "subclasse": "9329801",
        "denominacao": "DISCOTECAS, DANCETERIAS, SALOES DE DANCA E SIMILARES",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE SALÕES DE FESTAS",
        "item": "3",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9132",
        "dtcancelamento": "20140430"
    },
    {
        "subclasse": "8230001",
        "denominacao": "SERVICOS DE ORGANIZACAO DE FEIRAS, CONGRESSOS, EXPOSICOES E FESTAS",
        "descricao": "SERVIÇOS DE ORGANIZAÇÃO DE FEIRAS, EVENTOS E CONGRESSOS",
        "item": "12",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "8984",
        "dtcancelamento": "20140430"
    },
    {
        "subclasse": "8230001",
        "denominacao": "SERVICOS DE ORGANIZACAO DE FEIRAS, CONGRESSOS, EXPOSICOES E FESTAS",
        "descricao": "SERVIÇOS DE EXPOSIÇÃO ",
        "item": "12",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "8985",
        "dtcancelamento": "20140430"
    },
    {
        "subclasse": "9200399",
        "denominacao": "EXPLORACAO DE JOGOS DE AZAR E APOSTAS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COMPETIÇÕES ESPORTIVAS OU DE DESTREZA FÍSICA OU INTELECTUAL",
        "item": "12",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "8993",
        "dtcancelamento": "20140430"
    },
    {
        "subclasse": "9319101",
        "denominacao": "PRODUCAO E PROMOCAO DE EVENTOS ESPORTIVOS",
        "descricao": "SERVIÇOS DE COMPETIÇÕES ESPORTIVAS OU DE DESTREZA FÍSICA OU INTELECTUAL",
        "item": "12",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "8996",
        "dtcancelamento": "20140430"
    },
    {
        "subclasse": "9200301",
        "denominacao": "CASAS DE BINGO",
        "descricao": "SERVIÇOS DE COMPETIÇÕES ESPORTIVAS OU DE DESTREZA FÍSICA OU INTELECTUAL",
        "item": "12",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "8995",
        "dtcancelamento": "20140430"
    },
    {
        "subclasse": "9200302",
        "denominacao": "EXPLORACAO DE APOSTAS EM CORRIDAS DE CAVALOS",
        "descricao": "SERVIÇOS DE COMPETIÇÕES ESPORTIVAS OU DE DESTREZA FÍSICA OU INTELECTUAL",
        "item": "12",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "8994",
        "dtcancelamento": "20140430"
    },
    {
        "subclasse": "9001905",
        "denominacao": "PRODUCAO DE ESPETACULOS DE RODEIOS, VAQUEJADAS E SIMILARES",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE EVENTOS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "9000",
        "dtcancelamento": "20140430"
    },
    {
        "subclasse": "9001904",
        "denominacao": "PRODUCAO DE ESPETACULOS CIRCENSES, DE MARIONETES E SIMILARES",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE EVENTOS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "9001",
        "dtcancelamento": "20140430"
    },
    {
        "subclasse": "9001902",
        "denominacao": "PRODUCAO MUSICAL",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE EVENTOS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "9003",
        "dtcancelamento": "20140430"
    },
    {
        "subclasse": "9329801",
        "denominacao": "DISCOTECAS, DANCETERIAS, SALOES DE DANCA E SIMILARES",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE BAILES",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "9011",
        "dtcancelamento": "20140430"
    },
    {
        "subclasse": "9001901",
        "denominacao": "PRODUCAO TEATRAL",
        "descricao": "SERVIÇOS DE PRODUÇÕES TEATRAIS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "9010",
        "dtcancelamento": "20140430"
    },
    {
        "subclasse": "9001902",
        "denominacao": "PRODUCAO MUSICAL",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE ÓPERAS, CONCERTOS E RECITAIS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "9009",
        "dtcancelamento": "20140430"
    },
    {
        "subclasse": "9001999",
        "denominacao": "ARTES CENICAS, ESPETACULOS E ATIVIDADES COMPLEMENTARES NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE ESPETÁCULOS, DESFILES E ENTREVISTAS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "9008",
        "dtcancelamento": "20140430"
    },
    {
        "subclasse": "9001902",
        "denominacao": "PRODUCAO MUSICAL",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE SHOWS E FESTIVAIS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "9007",
        "dtcancelamento": "20140430"
    },
    {
        "subclasse": "9001903",
        "denominacao": "PRODUCAO DE ESPETACULOS DE DANCA",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE DANÇAS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "9006",
        "dtcancelamento": "20140430"
    },
    {
        "subclasse": "9003500",
        "denominacao": "GESTAO DE ESPACOS PARA ARTES CENICAS, ESPETACULOS E OUTRAS ATIVIDADES ARTISTICAS",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE EVENTOS",
        "item": "12",
        "subitem": "13",
        "aliquota": "2",
        "idcnae": "9005",
        "dtcancelamento": "20140430"
    },
    {
        "subclasse": "9001901",
        "denominacao": "PRODUCAO TEATRAL",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE EVENTOS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "9004",
        "dtcancelamento": "20140430"
    },
    {
        "subclasse": "9001903",
        "denominacao": "PRODUCAO DE ESPETACULOS DE DANCA",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE EVENTOS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "9002",
        "dtcancelamento": "20140430"
    },
    {
        "subclasse": "7020400",
        "denominacao": "ATIVIDADES DE CONSULTORIA EM GESTAO EMPRESARIAL, EXCETO CONSULTORIA TECNICA ESPECIFICA",
        "descricao": "SERVIÇOS DE CONSULTORIA E ASSESSORIA ECONÔMICA OU FINANCEIRA",
        "item": "17",
        "subitem": "20",
        "aliquota": "5",
        "idcnae": "10867",
        "dtcancelamento": "20110701"
    },
    {
        "subclasse": "6203100",
        "denominacao": "DESENVOLVIMENTO E LICENCIAMENTO DE PROGRAMAS DE COMPUTADOR NAO-CUSTOMIZAVEIS",
        "descricao": "SERVIÇO DE ANÁLISE E DESENVOLVIMENTO  DE SISTEMA (SOFTWARE)",
        "item": "1",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "11244",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6201500",
        "denominacao": "DESENVOLVIMENTO DE PROGRAMAS DE COMPUTADOR SOB ENCOMENDA",
        "descricao": "SERVIÇO DE ANÁLISE E DESENVOLVIMENTO  DE SISTEMA (SOFTWARE)",
        "item": "1",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "11245",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6202300",
        "denominacao": "DESENVOLVIMENTO E LICENCIAMENTO DE PROGRAMAS DE COMPUTADOR CUSTOMIZAVEIS",
        "descricao": "SERVIÇO DE ANÁLISE E DESENVOLVIMENTO  DE SISTEMA (SOFTWARE)",
        "item": "1",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "11246",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7229000",
        "denominacao": "DESENVOLVIMENTO DE SOFTWARE SOB ENCOMENDA E OUTRAS CONSULTORIAS EM SOFTWARE.",
        "descricao": "SERVIÇOS DE PROGRAMAÇÃO",
        "item": "1",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "1575",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6201500",
        "denominacao": "DESENVOLVIMENTO DE PROGRAMAS DE COMPUTADOR SOB ENCOMENDA",
        "descricao": "SERVIÇOS DE PROGRAMAÇÃO",
        "item": "1",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "11247",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6311900",
        "denominacao": "TRATAMENTO DE DADOS, PROVEDORES DE SERVIÇOS DE APLICAÇÃO E SERVIÇOS DE HOSPEDAGEM NA INTERNET",
        "descricao": "SERVIÇO DE COLETA E PROCESSAMENTO DE DADOS",
        "item": "1",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "1576",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6311900",
        "denominacao": "TRATAMENTO DE DADOS, PROVEDORES DE SERVICOS DE APLICACAO E SERVICOS DE HOSPEDAGEM NA INTERNET",
        "descricao": "SERVIÇO DE COLETA E PROCESSAMENTO DE DADOS",
        "item": "1",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "11248",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7229000",
        "denominacao": "DESENVOLVIMENTO DE SOFTWARE SOB ENCOMENDA E OUTRAS CONSULTORIAS EM SOFTWARE.",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE PROGRAMAS DE COMPUTADOR, INCLUSIVE DE JOGOS ELETRÔNICOS",
        "item": "1",
        "subitem": "4",
        "aliquota": "2",
        "idcnae": "1628",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6201500",
        "denominacao": "DESENVOLVIMENTO DE PROGRAMAS DE COMPUTADOR SOB ENCOMENDA",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE PROGRAMAS DE COMPUTADOR, INCLUSIVE DE JOGOS ELETRÔNICOS",
        "item": "1",
        "subitem": "4",
        "aliquota": "2",
        "idcnae": "11169",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6202300",
        "denominacao": "DESENVOLVIMENTO E LICENCIAMENTO DE PROGRAMAS DE COMPUTADOR CUSTOMIZAVEIS",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE PROGRAMAS DE COMPUTADOR, INCLUSIVE DE JOGOS ELETRÔNICOS",
        "item": "1",
        "subitem": "4",
        "aliquota": "2",
        "idcnae": "11168",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6202300",
        "denominacao": "DESENVOLVIMENTO E LICENCIAMENTO DE PROGRAMAS DE COMPUTADOR CUSTOMIZAVEIS",
        "descricao": "SERVIÇOS DE LICENCIAMENTO OU CESSÃO DE DIREITO DE USO DE PROGRAMAS DE COMPUTAÇÃO",
        "item": "1",
        "subitem": "5",
        "aliquota": "2",
        "idcnae": "11170",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7229000",
        "denominacao": "DESENVOLVIMENTO DE SOFTWARE SOB ENCOMENDA E OUTRAS CONSULTORIAS EM SOFTWARE.",
        "descricao": "SERVIÇOS DE CONSULTORIA EM SOFTWARE",
        "item": "1",
        "subitem": "6",
        "aliquota": "2",
        "idcnae": "1631",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7229000",
        "denominacao": "DESENVOLVIMENTO DE SOFTWARE SOB ENCOMENDA E OUTRAS CONSULTORIAS EM SOFTWARE.",
        "descricao": "SERVIÇOS DE PLANEJAMENTO, CONFECÇÃO, MANUTENÇÃO E ATUALIZAÇÃO DE PÁGINAS ELETRÔNICAS",
        "item": "1",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "1528",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6201500",
        "denominacao": "DESENVOLVIMENTO DE PROGRAMAS DE COMPUTADOR SOB ENCOMENDA",
        "descricao": "SERVIÇOS DE PLANEJAMENTO, CONFECÇÃO, MANUTENÇÃO E ATUALIZAÇÃO DE PÁGINAS ELETRÔNICAS",
        "item": "1",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "11184",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6319400",
        "denominacao": "PORTAIS, PROVEDORES DE CONTEUDO E OUTROS SERVICOS DE INFORMACAO NA INTERNET",
        "descricao": "SERVIÇOS DE PLANEJAMENTO, CONFECÇÃO, MANUTENÇÃO E ATUALIZAÇÃO DE PÁGINAS ELETRÔNICAS",
        "item": "1",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "11182",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7240000",
        "denominacao": "ATIVIDADES DE BANCO DE DADOS E DISTRIBUIÇÃO ON LINE DE CONTEÚDO ELETRÔNICO.",
        "descricao": "SERVIÇOS DE PLANEJAMENTO, CONFECÇÃO, MANUTENÇÃO E ATUALIZAÇÃO DE PÁGINAS ELETRÔNICAS",
        "item": "1",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "1529",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6311900",
        "denominacao": "TRATAMENTO DE DADOS, PROVEDORES DE SERVICOS DE APLICACAO E SERVICOS DE HOSPEDAGEM NA INTERNET",
        "descricao": "SERVIÇOS DE PLANEJAMENTO, CONFECÇÃO, MANUTENÇÃO E ATUALIZAÇÃO DE PÁGINAS ELETRÔNICAS",
        "item": "1",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "11183",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7220700",
        "denominacao": "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIÊNCIAS SOCIAIS E HUMANAS",
        "descricao": "SERVIÇOS DE PESQUISAS E DESENVOLVIMENTO",
        "item": "2",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1530",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7320300",
        "denominacao": "PESQUISAS DE MERCADO E DE OPINIAO PUBLICA",
        "descricao": "SERVIÇOS DE PESQUISAS E DESENVOLVIMENTO",
        "item": "2",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11189",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8550302",
        "denominacao": "ATIVIDADES DE APOIO A EDUCACAO, EXCETO CAIXAS ESCOLARES",
        "descricao": "SERVIÇOS DE PESQUISAS E DESENVOLVIMENTO",
        "item": "2",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11188",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7119702",
        "denominacao": "ATIVIDADES DE ESTUDOS GEOLOGICOS",
        "descricao": "SERVIÇOS DE PESQUISAS E DESENVOLVIMENTO",
        "item": "2",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11187",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7210000",
        "denominacao": "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIENCIAS FISICAS E NATURAIS",
        "descricao": "SERVIÇOS DE PESQUISAS E DESENVOLVIMENTO",
        "item": "2",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11186",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7220700",
        "denominacao": "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIENCIAS SOCIAIS E HUMANAS",
        "descricao": "SERVIÇOS DE PESQUISAS E DESENVOLVIMENTO",
        "item": "2",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11185",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7320300",
        "denominacao": "PESQUISAS DE MERCADO E DE OPINIÃO PÚBLICA",
        "descricao": "SERVIÇOS DE PESQUISA E DESENVOLVIMENTO",
        "item": "2",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1531",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7740300",
        "denominacao": "GESTAO DE ATIVOS INTANGIVEIS NAO-FINANCEIROS",
        "descricao": "SERVIÇOS DE CESSÃO DE DIREITO DE USO DE MARCAS E DE SINAIS DE PROPAGANDA",
        "item": "3",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "11190",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8230002",
        "denominacao": "CASAS DE FESTAS E EVENTOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE AUDITÓRIOS E CASAS DE ESPETÁCULOS",
        "item": "3",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1533",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9329801",
        "denominacao": "DISCOTECAS, DANCETERIAS, SALOES DE DANCA E SIMILARES",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE SALÕES DE FESTAS",
        "item": "3",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11200",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8230002",
        "denominacao": "CASAS DE FESTAS E EVENTOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE QUADRAS ESPORTIVAS, ESTÁDIOS, GINÁSIOS E CANCHAS",
        "item": "3",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11199",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9319101",
        "denominacao": "PRODUCAO E PROMOCAO DE EVENTOS ESPORTIVOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE QUADRAS ESPORTIVAS, ESTÁDIOS, GINÁSIOS E CANCHAS",
        "item": "3",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11198",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9311500",
        "denominacao": "GESTAO DE INSTALACOES DE ESPORTES",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE QUADRAS ESPORTIVAS, ESTÁDIOS, GINÁSIOS E CANCHAS",
        "item": "3",
        "subitem": "3",
        "aliquota": "2,5",
        "idcnae": "11197",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9321200",
        "denominacao": "PARQUES DE DIVERSAO E PARQUES TEMATICOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE PARQUES DE DIVERSÕES",
        "item": "3",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11196",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3311200",
        "denominacao": "MANUTENCAO E REPARACAO DE TANQUES, RESERVATORIOS METALICOS E CALDEIRAS, EXCETO PARA VEICULOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE CENTRO DE CONVENÇÕES OU STANDS",
        "item": "3",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11195",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "0111301",
        "denominacao": "CULTIVO DE ARROZ",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE CENTRO DE CONVENÇÕES OU STANDS",
        "item": "3",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11194",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9003500",
        "denominacao": "GESTAO DE ESPACOS PARA ARTES CENICAS, ESPETACULOS E OUTRAS ATIVIDADES ARTISTICAS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE CENTRO DE CONVENÇÕES OU STANDS",
        "item": "3",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11193",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8230002",
        "denominacao": "CASAS DE FESTAS E EVENTOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE CENTRO DE CONVENÇÕES OU STANDS",
        "item": "3",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11192",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9003500",
        "denominacao": "GESTAO DE ESPACOS PARA ARTES CENICAS, ESPETACULOS E OUTRAS ATIVIDADES ARTISTICAS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE AUDITÓRIOS E CASAS DE ESPETÁCULOS",
        "item": "3",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11191",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9329899",
        "denominacao": "OUTRAS ATIVIDADES DE RECREACAO E LAZER NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE CESSÃO DE ANDAIMES, PALCOS, COBERTURAS E OUTRAS ESTRUTURAS DE USO TEMPORÁRIO",
        "item": "3",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11201",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8513801",
        "denominacao": "ATIVIDADES DE CLÍNICA MÉDICA (CLÍNICAS, CONSULTÓRIOS E AMBULATÓRIOS)",
        "descricao": "SERVIÇOS DE MEDICINA ",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1543",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8610101",
        "denominacao": "ATIVIDADES DE ATENDIMENTO HOSPITALAR, EXCETO PRONTO-SOCORRO E UNIDADES PARA ATENDIMENTO A URGÊNCIAS",
        "descricao": "SERVIÇOS DE ASSISTÊNCIA MÉDICO-HOSPITALAR",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1545",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8610102",
        "denominacao": "ATIVIDADES DE ATENDIMENTO EM PRONTO-SOCORRO E UNIDADES HOSPITALARES PARA ATENDIMENTO A URGENCIAS",
        "descricao": "SERVIÇOS DE MEDICINA ",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "11202",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8621602",
        "denominacao": "SERVICOS MOVEIS DE ATENDIMENTO A URGENCIAS, EXCETO POR UTI MOVEL",
        "descricao": "SERVIÇOS DE MEDICINA ",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "11204",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8630506",
        "denominacao": "SERVICOS DE VACINACAO E IMUNIZACAO HUMANA",
        "descricao": "SERVIÇOS DE MEDICINA ",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "11206",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8630502",
        "denominacao": "ATIVIDADE MEDICA AMBULATORIAL COM RECURSOS PARA REALIZACAO DE EXAMES COMPLEMENTARES",
        "descricao": "SERVIÇOS DE MEDICINA ",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "11208",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8650003",
        "denominacao": "ATIVIDADES DE PSICOLOGIA E PSICANALISE",
        "descricao": "SERVIÇOS DE PSIQUIATRIA",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "11212",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8610101",
        "denominacao": "ATIVIDADES DE ATENDIMENTO HOSPITALAR, EXCETO PRONTO-SOCORRO E UNIDADES PARA ATENDIMENTO A URGENCIAS",
        "descricao": "SERVIÇOS DE ASSISTÊNCIA MÉDICO-HOSPITALAR",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "11211",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8690901",
        "denominacao": "ATIVIDADES DE PRATICAS INTEGRATIVAS E COMPLEMENTARES EM SAUDE HUMANA",
        "descricao": "SERVIÇOS DE BIOMEDICINA",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "11210",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8630501",
        "denominacao": "ATIVIDADE MEDICA AMBULATORIAL COM RECURSOS PARA REALIZACAO DE PROCEDIMENTOS CIRURGICOS",
        "descricao": "SERVIÇOS DE MEDICINA ",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "11209",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8630503",
        "denominacao": "ATIVIDADE MEDICA AMBULATORIAL RESTRITA A CONSULTAS",
        "descricao": "SERVIÇOS DE MEDICINA ",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "11207",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8621601",
        "denominacao": "UTI MOVEL",
        "descricao": "SERVIÇOS DE MEDICINA ",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "11205",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8610101",
        "denominacao": "ATIVIDADES DE ATENDIMENTO HOSPITALAR, EXCETO PRONTO-SOCORRO E UNIDADES PARA ATENDIMENTO A URGENCIAS",
        "descricao": "SERVIÇOS DE MEDICINA ",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "11203",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8610102",
        "denominacao": "ATIVIDADES DE ATENDIMENTO EM PRONTO-SOCORRO E UNIDADES HOSPITALARES PARA ATENDIMENTO A URGENCIAS",
        "descricao": "SERVIÇOS DE ASSISTÊNCIA MÉDICO-HOSPITALAR",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "11510",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8640202",
        "denominacao": "LABORATÓRIOS CLÍNICOS",
        "descricao": "SERVIÇOS DE ANÁLISES CLÍNICAS",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "1549",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8514604",
        "denominacao": "SERVIÇOS DE RAIO-X, RADIODIAGNÓSTICO E RADIOTERAPIA",
        "descricao": "SERVIÇOS DE RADIOLOGIA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "1552",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8514604",
        "denominacao": "SERVIÇOS DE RAIO-X, RADIODIAGNÓSTICO E RADIOTERAPIA",
        "descricao": "SERVIÇOS DE RADIOTERAPIA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "1553",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8514604",
        "denominacao": "SERVIÇOS DE RAIO-X, RADIODIAGNÓSTICO E RADIOTERAPIA",
        "descricao": "SERVIÇOS DE ELETRICIDADE-MÉDICA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "1551",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8640206",
        "denominacao": "SERVICOS DE RESSONANCIA MAGNETICA",
        "descricao": "SERVIÇOS DE RESSONÂNCIA MAGNÉTICA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "11222",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8640210",
        "denominacao": "SERVICOS DE QUIMIOTERAPIA",
        "descricao": "SERVIÇOS DE QUIMIOTERAPIA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "11221",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8640204",
        "denominacao": "SERVICOS DE TOMOGRAFIA",
        "descricao": "SERVIÇOS DE TOMOGRAFIA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "11220",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8640205",
        "denominacao": "SERVICOS DE DIAGNOSTICO POR IMAGEM COM USO DE RADIACAO IONIZANTE, EXCETO TOMOGRAFIA",
        "descricao": "SERVIÇOS DE RADIOTERAPIA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "11219",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8640205",
        "denominacao": "SERVICOS DE DIAGNOSTICO POR IMAGEM COM USO DE RADIACAO IONIZANTE, EXCETO TOMOGRAFIA",
        "descricao": "SERVIÇOS DE RADIOLOGIA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "11218",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8640204",
        "denominacao": "SERVICOS DE TOMOGRAFIA",
        "descricao": "SERVIÇOS DE ELETRECIDADE-MÉDICA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "11217",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8640205",
        "denominacao": "SERVICOS DE DIAGNOSTICO POR IMAGEM COM USO DE RADIACAO IONIZANTE, EXCETO TOMOGRAFIA",
        "descricao": "SERVIÇOS DE ELETRECIDADE-MÉDICA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "11216",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8640205",
        "denominacao": "SERVICOS DE DIAGNOSTICO POR IMAGEM COM USO DE RADIACAO IONIZANTE, EXCETO TOMOGRAFIA",
        "descricao": "SERVIÇOS DE ABREUGRAFIA E RAIO X",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "11215",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8640202",
        "denominacao": "LABORATORIOS CLINICOS",
        "descricao": "SERVIÇOS DE ANÁLISES CLÍNICAS",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "11214",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8640207",
        "denominacao": "SERVICOS DE DIAGNOSTICO POR IMAGEM SEM USO DE RADIACAO IONIZANTE, EXCETO RESSONANCIA MAGNETICA",
        "descricao": "SERVIÇOS DE ULTRA-SONOGRAFIA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "11213",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8640202",
        "denominacao": "LABORATORIOS CLINICOS",
        "descricao": "SERVIÇOS DE PATOLOGIA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "11482",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8514604",
        "denominacao": "SERVIÇOS DE RAIO-X, RADIODIAGNÓSTICO E RADIOTERAPIA",
        "descricao": "SERVIÇOS DE ABREUGRAFIA E RAIO X",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "1550",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8513801",
        "denominacao": "ATIVIDADES DE CLÍNICA MÉDICA (CLÍNICAS, CONSULTÓRIOS E AMBULATÓRIOS)",
        "descricao": "SERVIÇOS AMBULATORIAIS",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "1558",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8513801",
        "denominacao": "ATIVIDADES DE CLÍNICA MÉDICA (CLÍNICAS, CONSULTÓRIOS E AMBULATÓRIOS)",
        "descricao": "SERVIÇOS DE MEDICINA (CLINICAS MÉDICAS)",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "1559",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8630503",
        "denominacao": "ATIVIDADE MEDICA AMBULATORIAL RESTRITA A CONSULTAS",
        "descricao": "SERVIÇOS AMBULATORIAIS",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "11224",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8630501",
        "denominacao": "ATIVIDADE MEDICA AMBULATORIAL COM RECURSOS PARA REALIZACAO DE PROCEDIMENTOS CIRURGICOS",
        "descricao": "SERVIÇOS AMBULATORIAIS",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "11226",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8630506",
        "denominacao": "SERVICOS DE VACINACAO E IMUNIZACAO HUMANA",
        "descricao": "SERVIÇOS DE VACINAÇÃO E IMUNIZAÇÃO HUMANA",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "11228",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8610101",
        "denominacao": "ATIVIDADES DE ATENDIMENTO HOSPITALAR, EXCETO PRONTO-SOCORRO E UNIDADES PARA ATENDIMENTO A URGENCIAS",
        "descricao": "SERVIÇOS DE MANICÔMIO",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "11230",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8720499",
        "denominacao": "ATIVIDADES DE ASSISTENCIA PSICOSSOCIAL E A SAUDE A PORTADORES DE DISTURBIOS PSIQUICOS, DEFICIENCIA MENTAL E DEPENDENCIA QUIMICA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE REABILITAÇÃO DE DEPENDENTES QUÍMICOS",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "11234",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8610102",
        "denominacao": "ATIVIDADES DE ATENDIMENTO EM PRONTO-SOCORRO E UNIDADES HOSPITALARES PARA ATENDIMENTO A URGENCIAS",
        "descricao": "SERVIÇOS DE PRONTO-SOCORRO",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "11233",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8610101",
        "denominacao": "ATIVIDADES DE ATENDIMENTO HOSPITALAR, EXCETO PRONTO-SOCORRO E UNIDADES PARA ATENDIMENTO A URGENCIAS",
        "descricao": "SERVIÇOS HOSPITALARES, DE CASA DE SAÚDE OU MATERNIDADE",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "11232",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8610101",
        "denominacao": "ATIVIDADES DE ATENDIMENTO HOSPITALAR, EXCETO PRONTO-SOCORRO E UNIDADES PARA ATENDIMENTO A URGENCIAS",
        "descricao": "SERVIÇOS DE SANATÓRIOS",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "11231",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8640203",
        "denominacao": "SERVICOS DE DIALISE E NEFROLOGIA",
        "descricao": "SERVIÇOS DE DIÁLISE",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "11229",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8610101",
        "denominacao": "ATIVIDADES DE ATENDIMENTO HOSPITALAR, EXCETO PRONTO-SOCORRO E UNIDADES PARA ATENDIMENTO A URGENCIAS",
        "descricao": "SERVIÇOS AMBULATORIAIS",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "11227",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8630502",
        "denominacao": "ATIVIDADE MEDICA AMBULATORIAL COM RECURSOS PARA REALIZACAO DE EXAMES COMPLEMENTARES",
        "descricao": "SERVIÇOS AMBULATORIAIS",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "11225",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8640202",
        "denominacao": "LABORATORIOS CLINICOS",
        "descricao": "SERVIÇOS DE LABORATÓRIOS",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "11223",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8640202",
        "denominacao": "LABORATÓRIOS CLÍNICOS",
        "descricao": "SERVIÇOS LABORATORIAIS",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "1561",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8690999",
        "denominacao": "OUTRAS ATIVIDADES DE ATENÇÃO À SAÚDE HUMANA NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE INSTRUMENTAÇÃO CIRÚRGICA",
        "item": "4",
        "subitem": "4",
        "aliquota": "2",
        "idcnae": "1568",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8690999",
        "denominacao": "OUTRAS ATIVIDADES DE ATENCAO A SAUDE HUMANA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE INSTRUMENTAÇÃO CIRÚRGICA",
        "item": "4",
        "subitem": "4",
        "aliquota": "2",
        "idcnae": "11236",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8650099",
        "denominacao": "ATIVIDADES DE PROFISSIONAIS DA AREA DE SAUDE NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE INSTRUMENTAÇÃO CIRÚRGICA",
        "item": "4",
        "subitem": "4",
        "aliquota": "2",
        "idcnae": "11235",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8690901",
        "denominacao": "ATIVIDADES DE PRATICAS INTEGRATIVAS E COMPLEMENTARES EM SAUDE HUMANA",
        "descricao": "SERVIÇOS DE ACUPUNTURA",
        "item": "4",
        "subitem": "5",
        "aliquota": "2",
        "idcnae": "11237",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8650001",
        "denominacao": "ATIVIDADES DE ENFERMAGEM",
        "descricao": "SERVIÇOS DE ENFERMAGEM",
        "item": "4",
        "subitem": "6",
        "aliquota": "2",
        "idcnae": "1570",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8690999",
        "denominacao": "OUTRAS ATIVIDADES DE ATENCAO A SAUDE HUMANA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ESTERELIZAÇÃO",
        "item": "4",
        "subitem": "6",
        "aliquota": "2",
        "idcnae": "11239",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8650001",
        "denominacao": "ATIVIDADES DE ENFERMAGEM",
        "descricao": "SERVIÇOS DE ENFERMAGEM",
        "item": "4",
        "subitem": "6",
        "aliquota": "2",
        "idcnae": "11238",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8650099",
        "denominacao": "ATIVIDADES DE PROFISSIONAIS DA AREA DE SAUDE NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS FARMACÊUTICOS",
        "item": "4",
        "subitem": "7",
        "aliquota": "2",
        "idcnae": "11240",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8515404",
        "denominacao": "SERVIÇOS DE FISIOTERAPIA E TERAPIA OCUPACIONAL",
        "descricao": "SERVIÇOS DE FISIOTERAPIA",
        "item": "4",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "1577",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8650006",
        "denominacao": "ATIVIDADES DE FONOAUDIOLOGIA",
        "descricao": "SERVIÇOS DE FONOAUDIOLOGIA",
        "item": "4",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "11252",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8650005",
        "denominacao": "ATIVIDADES DE TERAPIA OCUPACIONAL",
        "descricao": "SERVIÇOS DE TERAPIA OCUPACIONAL",
        "item": "4",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "11251",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8650003",
        "denominacao": "ATIVIDADES DE PSICOLOGIA E PSICANALISE",
        "descricao": "SERVIÇOS DE PSICOTERAPIA",
        "item": "4",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "11250",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8650004",
        "denominacao": "ATIVIDADES DE FISIOTERAPIA",
        "descricao": "SERVIÇOS DE FISIOTERAPIA",
        "item": "4",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "11249",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8650006",
        "denominacao": "ATIVIDADES DE FONOAUDIOLOGIA",
        "descricao": "SERVIÇOS DE REABILITAÇAO DA FALA OU AUDIÇÃO (CLÍNICA)",
        "item": "4",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "11242",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8515404",
        "denominacao": "SERVIÇOS DE FISIOTERAPIA E TERAPIA OCUPACIONAL",
        "descricao": "SERVIÇOS DE TERAPIA OCUPACIONAL",
        "item": "4",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "1579",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8650006",
        "denominacao": "ATIVIDADES DE FONOAUDIOLOGIA",
        "descricao": "SERVIÇOS DE FONOAUDIOLOGIA",
        "item": "4",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "1580",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8690901",
        "denominacao": "ATIVIDADES DE PRÁTICAS INTEGRATIVAS E COMPLEMENTARES EM SAÚDE HUMANA",
        "descricao": "SERVIÇOS DE BIOTERAPIA",
        "item": "4",
        "subitem": "9",
        "aliquota": "2",
        "idcnae": "1582",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8690901",
        "denominacao": "ATIVIDADES DE PRATICAS INTEGRATIVAS E COMPLEMENTARES EM SAUDE HUMANA",
        "descricao": "SERVIÇOS DE BIOTERAPIA",
        "item": "4",
        "subitem": "9",
        "aliquota": "2",
        "idcnae": "11254",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8650007",
        "denominacao": "ATIVIDADES DE TERAPIA DE NUTRICAO ENTERAL E PARENTERAL",
        "descricao": "SERVIÇOS DE TERAPIA DE NUTRIÇÃO ENTERAL E PARENTERAL.",
        "item": "4",
        "subitem": "9",
        "aliquota": "2",
        "idcnae": "11253",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8650002",
        "denominacao": "ATIVIDADES DE PROFISSIONAIS DA NUTRIÇÃO",
        "descricao": "SERVIÇOS DE NUTRIÇÃO",
        "item": "4",
        "subitem": "10",
        "aliquota": "2",
        "idcnae": "1526",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8650002",
        "denominacao": "ATIVIDADES DE PROFISSIONAIS DA NUTRICAO",
        "descricao": "SERVIÇOS DE NUTRIÇÃO",
        "item": "4",
        "subitem": "10",
        "aliquota": "2",
        "idcnae": "11181",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8650007",
        "denominacao": "ATIVIDADES DE TERAPIA DE NUTRICAO ENTERAL E PARENTERAL",
        "descricao": "SERVIÇOS DE NUTRIÇÃO",
        "item": "4",
        "subitem": "10",
        "aliquota": "2",
        "idcnae": "11180",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8630503",
        "denominacao": "ATIVIDADE MEDICA AMBULATORIAL RESTRITA A CONSULTAS",
        "descricao": "SERVIÇOS DE OBSTETRÍCIA",
        "item": "4",
        "subitem": "11",
        "aliquota": "2",
        "idcnae": "11261",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8610101",
        "denominacao": "ATIVIDADES DE ATENDIMENTO HOSPITALAR, EXCETO PRONTO-SOCORRO E UNIDADES PARA ATENDIMENTO A URGENCIAS",
        "descricao": "SERVIÇOS DE OBSTETRÍCIA",
        "item": "4",
        "subitem": "11",
        "aliquota": "2",
        "idcnae": "11264",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8630502",
        "denominacao": "ATIVIDADE MEDICA AMBULATORIAL COM RECURSOS PARA REALIZACAO DE EXAMES COMPLEMENTARES",
        "descricao": "SERVIÇOS DE OBSTETRÍCIA",
        "item": "4",
        "subitem": "11",
        "aliquota": "2",
        "idcnae": "11262",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8630501",
        "denominacao": "ATIVIDADE MEDICA AMBULATORIAL COM RECURSOS PARA REALIZACAO DE PROCEDIMENTOS CIRURGICOS",
        "descricao": "SERVIÇOS DE OBSTETRÍCIA",
        "item": "4",
        "subitem": "11",
        "aliquota": "2",
        "idcnae": "11263",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8630504",
        "denominacao": "ATIVIDADE ODONTOLOGICA",
        "descricao": "SERVIÇOS ODONTOLÓGICOS",
        "item": "4",
        "subitem": "12",
        "aliquota": "2",
        "idcnae": "11265",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8630504",
        "denominacao": "ATIVIDADE ODONTOLOGICA",
        "descricao": "SERVIÇOS DE ASSISTÊNCIA ODONTOLÓGICA (SERVIÇOS ODONTOLÓGICOS)",
        "item": "4",
        "subitem": "12",
        "aliquota": "2",
        "idcnae": "11266",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8610101",
        "denominacao": "ATIVIDADES DE ATENDIMENTO HOSPITALAR, EXCETO PRONTO-SOCORRO E UNIDADES PARA ATENDIMENTO A URGENCIAS",
        "descricao": "SERVIÇOS DE ORTÓPTICA",
        "item": "4",
        "subitem": "13",
        "aliquota": "2",
        "idcnae": "11267",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8630502",
        "denominacao": "ATIVIDADE MEDICA AMBULATORIAL COM RECURSOS PARA REALIZACAO DE EXAMES COMPLEMENTARES",
        "descricao": "SERVIÇOS DE ORTÓPTICA",
        "item": "4",
        "subitem": "13",
        "aliquota": "2",
        "idcnae": "11269",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8630501",
        "denominacao": "ATIVIDADE MEDICA AMBULATORIAL COM RECURSOS PARA REALIZACAO DE PROCEDIMENTOS CIRURGICOS",
        "descricao": "SERVIÇOS DE ORTÓPTICA",
        "item": "4",
        "subitem": "13",
        "aliquota": "2",
        "idcnae": "11270",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8630503",
        "denominacao": "ATIVIDADE MEDICA AMBULATORIAL RESTRITA A CONSULTAS",
        "descricao": "SERVIÇOS DE ORTÓPTICA",
        "item": "4",
        "subitem": "13",
        "aliquota": "2",
        "idcnae": "11268",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3250706",
        "denominacao": "SERVIÇOS DE PRÓTESE DENTÁRIA",
        "descricao": "PROTÉTICO (SERVIÇOS DE PRÓTESE DENTÁRIA)",
        "item": "4",
        "subitem": "14",
        "aliquota": "2",
        "idcnae": "1468",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3250706",
        "denominacao": "SERVICOS DE PROTESE DENTARIA",
        "descricao": "SERVIÇOS DE PRÓTESES",
        "item": "4",
        "subitem": "14",
        "aliquota": "2",
        "idcnae": "11271",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3250703",
        "denominacao": "FABRICACAO DE APARELHOS E UTENSILIOS PARA CORRECAO DE DEFEITOS FISICOS E APARELHOS ORTOPEDICOS EM GERAL SOB ENCOMENDA",
        "descricao": "SERVIÇOS DE PRÓTESES",
        "item": "4",
        "subitem": "14",
        "aliquota": "2",
        "idcnae": "11272",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8650003",
        "denominacao": "ATIVIDADES DE PSICOLOGIA E PSICANALISE",
        "descricao": "SERVIÇOS DE PSICANÁLISE",
        "item": "4",
        "subitem": "15",
        "aliquota": "2",
        "idcnae": "11273",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8650003",
        "denominacao": "ATIVIDADES DE PSICOLOGIA E PSICANÁLISE",
        "descricao": "SERVIÇOS DE PSICOLOGIA",
        "item": "4",
        "subitem": "16",
        "aliquota": "2",
        "idcnae": "1471",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8650003",
        "denominacao": "ATIVIDADES DE PSICOLOGIA E PSICANALISE",
        "descricao": "SERVIÇOS DE PSICOLOGIA",
        "item": "4",
        "subitem": "16",
        "aliquota": "2",
        "idcnae": "11274",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8511200",
        "denominacao": "EDUCACAO INFANTIL - CRECHE",
        "descricao": "SERVIOS DE CRECHES",
        "item": "4",
        "subitem": "17",
        "aliquota": "2",
        "idcnae": "11457",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8730102",
        "denominacao": "ALBERGUES ASSISTENCIAIS",
        "descricao": "SERVIÇOS DE ALBERGUES ASSISTENCIAIS",
        "item": "4",
        "subitem": "17",
        "aliquota": "2",
        "idcnae": "11276",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8720499",
        "denominacao": "ATIVIDADES DE ASSISTENCIA PSICOSSOCIAL E A SAUDE A PORTADORES DE DISTURBIOS PSIQUICOS, DEFICIENCIA MENTAL E DEPENDENCIA QUIMICA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE REABILITAÇÃO DE DEPENDENTES QUÍMICOS COM ALOJAMENTO",
        "item": "4",
        "subitem": "17",
        "aliquota": "2",
        "idcnae": "11277",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8711501",
        "denominacao": "CLINICAS E RESIDENCIAS GERIATRICAS",
        "descricao": "SERVIÇOS DE CASA DE REPOUSO OU RECUPERAÇÃO",
        "item": "4",
        "subitem": "17",
        "aliquota": "2",
        "idcnae": "11278",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8711502",
        "denominacao": "INSTITUICOES DE LONGA PERMANENCIA PARA IDOSOS",
        "descricao": "SERVIÇOS DE ASILOS",
        "item": "4",
        "subitem": "17",
        "aliquota": "2",
        "idcnae": "11275",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8640212",
        "denominacao": "SERVICOS DE HEMOTERAPIA",
        "descricao": "SERVIÇOS DE BANCO DE SANGUE",
        "item": "4",
        "subitem": "19",
        "aliquota": "2",
        "idcnae": "11279",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8640214",
        "denominacao": "SERVICOS DE BANCOS DE CELULAS E TECIDOS HUMANOS",
        "descricao": "SERVIÇOS DE BANCO DE PELE",
        "item": "4",
        "subitem": "19",
        "aliquota": "2",
        "idcnae": "11283",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8630507",
        "denominacao": "ATIVIDADES DE REPRODUCAO HUMANA ASSISTIDA",
        "descricao": "SERVIÇOS DE BANCO DE ÓVULOS",
        "item": "4",
        "subitem": "19",
        "aliquota": "2",
        "idcnae": "11284",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8640214",
        "denominacao": "SERVICOS DE BANCOS DE CELULAS E TECIDOS HUMANOS",
        "descricao": "SERVIÇOS DE BANCO DE OLHOS",
        "item": "4",
        "subitem": "19",
        "aliquota": "2",
        "idcnae": "11282",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8690902",
        "denominacao": "ATIVIDADES DE BANCOS DE LEITE HUMANO",
        "descricao": "SERVIÇOS DE BANCO DE LEITE",
        "item": "4",
        "subitem": "19",
        "aliquota": "2",
        "idcnae": "11280",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8630507",
        "denominacao": "ATIVIDADES DE REPRODUCAO HUMANA ASSISTIDA",
        "descricao": "SERVIÇOS DE BANCO DE SÊMEN",
        "item": "4",
        "subitem": "19",
        "aliquota": "2",
        "idcnae": "11281",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8610101",
        "denominacao": "ATIVIDADES DE ATENDIMENTO HOSPITALAR, EXCETO PRONTO-SOCORRO E UNIDADES PARA ATENDIMENTO A URGENCIAS",
        "descricao": "SERVIÇOS DE REMOÇÕES DE ÓRGÃOS",
        "item": "4",
        "subitem": "20",
        "aliquota": "2",
        "idcnae": "11285",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8621602",
        "denominacao": "SERVICOS MOVEIS DE ATENDIMENTO A URGENCIAS, EXCETO POR UTI MOVEL",
        "descricao": "SERVIÇOS DE ASSISTÊNCIA, ATENDIMENTO OU TRATAMENTO MÓVEL",
        "item": "4",
        "subitem": "21",
        "aliquota": "2",
        "idcnae": "11286",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8621601",
        "denominacao": "UTI MOVEL",
        "descricao": "SERVIÇOS DE ASSISTÊNCIA, ATENDIMENTO OU TRATAMENTO MÓVEL",
        "item": "4",
        "subitem": "21",
        "aliquota": "2",
        "idcnae": "11287",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6550200",
        "denominacao": "PLANOS DE SAÚDE",
        "descricao": "CONVÊNIO PARA PRESTAÇÃO DE ASSISTÊNCIA MÉDICA, HOSPITALAR E ODONTOLÓGICA",
        "item": "4",
        "subitem": "22",
        "aliquota": "2",
        "idcnae": "1487",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6550200",
        "denominacao": "PLANOS DE SAUDE",
        "descricao": "PLANOS DE MEDICINA DE GRUPO OU INDIVIDUAL",
        "item": "4",
        "subitem": "22",
        "aliquota": "2",
        "idcnae": "11289",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6550200",
        "denominacao": "PLANOS DE SAUDE",
        "descricao": "CONVÊNIO PARA PRESTAÇÃO DE ASSISTÊNCIA MÉDICA, HOSPITALAR E ODONTOLÓGICA",
        "item": "4",
        "subitem": "22",
        "aliquota": "2",
        "idcnae": "11288",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6550200",
        "denominacao": "PLANOS DE SAUDE",
        "descricao": "OUTROS PLANOS DE SAÚDE",
        "item": "4",
        "subitem": "23",
        "aliquota": "2",
        "idcnae": "11290",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7500100",
        "denominacao": "ATIVIDADES VETERINÁRIAS",
        "descricao": "VETERINÁRIO (SERVIÇOS DE MEDICINA VETERINÁRIA)",
        "item": "5",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1492",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7500100",
        "denominacao": "ATIVIDADES VETERINARIAS",
        "descricao": "VETERINÁRIO (SERVIÇOS DE MEDICINA VETERINÁRIA)",
        "item": "5",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11294",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7490103",
        "denominacao": "SERVICOS DE AGRONOMIA E DE CONSULTORIA AS ATIVIDADES AGRICOLAS E PECUARIAS",
        "descricao": "SERVIÇOS DE ASSESSORIA ÀS ATIVIDADES PECUÁRIAS",
        "item": "5",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11291",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7500100",
        "denominacao": "ATIVIDADES VETERINARIAS",
        "descricao": "SERVIÇOS DE MEDICINA VETERINÁRIA E ZOOTECNIA",
        "item": "5",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11293",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7500100",
        "denominacao": "ATIVIDADES VETERINARIAS",
        "descricao": "SERVIÇOS DE ASSESSORIA ÀS ATIVIDADES PECUÁRIAS",
        "item": "5",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11292",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7500100",
        "denominacao": "ATIVIDADES VETERINÁRIAS",
        "descricao": "SERVIÇOS VETERINÁRIOS (CLÍNICA VETERINÁRIA)",
        "item": "5",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1496",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7500100",
        "denominacao": "ATIVIDADES VETERINARIAS",
        "descricao": "SERVIÇOS VETERINÁRIOS (CLÍNICA VETERINÁRIA)",
        "item": "5",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "11298",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7500100",
        "denominacao": "ATIVIDADES VETERINARIAS",
        "descricao": "SERVIÇOS DE PRONTOS-SOCORROS - VETERINÁRIOS",
        "item": "5",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "11297",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7500100",
        "denominacao": "ATIVIDADES VETERINARIAS",
        "descricao": "SERVIÇOS DE HOSPITALARES VETERINÁRIOS",
        "item": "5",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "11296",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7500100",
        "denominacao": "ATIVIDADES VETERINARIAS",
        "descricao": "SERVIÇOS AMBULATORIAIS - VETERINÁRIOS",
        "item": "5",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "11295",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7500100",
        "denominacao": "ATIVIDADES VETERINÁRIAS",
        "descricao": "SERVIÇOS LABORATORIAIS - VETERINÁRIO",
        "item": "5",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1497",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "0162801",
        "denominacao": "SERVICO DE INSEMINACAO ARTIFICIAL EM ANIMAIS",
        "descricao": "SERVIÇOS DE INSEMINAÇÃO ARTIFICIAL OU FERTILIZAÇÃO IN VITRO",
        "item": "5",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "11299",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8630507",
        "denominacao": "ATIVIDADES DE REPRODUCAO HUMANA ASSISTIDA",
        "descricao": "SERVIÇOS DE INSEMINAÇÃO ARTIFICIAL OU FERTILIZAÇÃO IN VITRO",
        "item": "5",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "11300",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "0162899",
        "denominacao": "ATIVIDADES DE APOIO A PECUARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE BANCO DE SANGUE E ÓRGÃOS DE ORIGEM ANIMAL",
        "item": "5",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11301",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "0162899",
        "denominacao": "ATIVIDADES DE APOIO A PECUARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COLETA DE SANGUE, LEITE, TECIDOS, SÊMEN, ÓRGÃOS E MATERIAIS BIOLÓGICOS DE QUALQUER ESPÉCIE, DE ORIGEM ANIMAL",
        "item": "5",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "11302",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7500100",
        "denominacao": "ATIVIDADES VETERINARIAS",
        "descricao": "SERVIÇOS DE ATENDIMENTO, ASSISTÊNCIA OU  TRATAMENTO MÓVEL À ANIMAIS",
        "item": "5",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "11303",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "0162803",
        "denominacao": "SERVIÇO DE MANEJO DE ANIMAIS",
        "descricao": "SERVIÇOS DE MANEJAMENTO DE ANIMAIS",
        "item": "5",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "1506",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "0162899",
        "denominacao": "ATIVIDADES DE APOIO A PECUARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS TRATAMENTO DE ANIMAIS",
        "item": "5",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "11484",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9609203",
        "denominacao": "ALOJAMENTO, HIGIENE E EMBELEZAMENTO DE ANIMAIS",
        "descricao": "SERVIÇOS DE ADESTRAMENTO DE ANIMAIS",
        "item": "5",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "11304",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9609203",
        "denominacao": "ALOJAMENTO, HIGIENE E EMBELEZAMENTO DE ANIMAIS",
        "descricao": "SERVIÇOS DE EMBELEZAMENTO DE ANIMAIS",
        "item": "5",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "11306",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9609203",
        "denominacao": "ALOJAMENTO, HIGIENE E EMBELEZAMENTO DE ANIMAIS",
        "descricao": "SERVIÇOS DE TOSQUIA",
        "item": "5",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "11308",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "0162802",
        "denominacao": "SERVICO DE TOSQUIAMENTO DE OVINOS",
        "descricao": "SERVIÇOS DE TOSQUIA",
        "item": "5",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "11309",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9609203",
        "denominacao": "ALOJAMENTO, HIGIENE E EMBELEZAMENTO DE ANIMAIS",
        "descricao": "SERVIÇOS DE GUARDA E ALOJAMENTO DE ANIMAIS",
        "item": "5",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "11307",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8011102",
        "denominacao": "SERVICOS DE ADESTRAMENTO DE CAES DE GUARDA",
        "descricao": "SERVIÇOS DE ADESTRAMENTO DE ANIMAIS",
        "item": "5",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "11305",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7500100",
        "denominacao": "ATIVIDADES VETERINÁRIAS",
        "descricao": "PLANOS DE ATENDIMENTO E ASSITÊNCIA MÉDICO VETERINÁRIA",
        "item": "5",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "1508",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6550200",
        "denominacao": "PLANOS DE SAUDE",
        "descricao": "PLANOS DE ATENDIMENTO E ASSITÊNCIA MÉDICO VETERINÁRIA",
        "item": "5",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "11310",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9602501",
        "denominacao": "CABELEIREIROS",
        "descricao": "SERVIÇOS DE CABELEIREIROS",
        "item": "6",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1509",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9602502",
        "denominacao": "ATIVIDADES DE ESTÉTICA E OUTROS SERVIÇOS DE CUIDADOS COM A BELEZA",
        "descricao": "SERVIÇOS DE MANICURI E PEDICURI",
        "item": "6",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11313",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9602501",
        "denominacao": "CABELEIREIROS",
        "descricao": "SERVIÇOS DE CABELEIREIROS",
        "item": "6",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11311",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9602501",
        "denominacao": "CABELEIREIROS",
        "descricao": "SERVIÇOS DE BARBEARIA",
        "item": "6",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11312",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9602502",
        "denominacao": "ATIVIDADES DE ESTÉTICA E OUTROS SERVIÇOS DE CUIDADOS COM A BELEZA",
        "descricao": "SERVIÇOS DE ESTÉTICA",
        "item": "6",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1513",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9602502",
        "denominacao": "ATIVIDADES DE ESTÉTICA E OUTROS SERVIÇOS DE CUIDADOS COM A BELEZA",
        "descricao": "SERVIÇOS DE TRATAMENTO DE PELE",
        "item": "6",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "11315",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9602502",
        "denominacao": "ATIVIDADES DE ESTÉTICA E OUTROS SERVIÇOS DE CUIDADOS COM A BELEZA",
        "descricao": "SERVIÇOS DE DEPILAÇÃO",
        "item": "6",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "11511",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9602502",
        "denominacao": "ATIVIDADES DE ESTÉTICA E OUTROS SERVIÇOS DE CUIDADOS COM A BELEZA",
        "descricao": "SERVIÇOS DE ESTÉTICA",
        "item": "6",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "11314",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9609201",
        "denominacao": "CLÍNICAS DE ESTÉTICA E SIMILARES",
        "descricao": "SERVIÇOS DE SAUNA, DUCHAS, BANHO E MASSAGENS",
        "item": "6",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1515",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9609201",
        "denominacao": "CLINICAS DE ESTETICA E SIMILARES",
        "descricao": "SERVIÇOS DE SAUNA, DUCHAS, BANHO E MASSAGENS",
        "item": "6",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11316",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9313100",
        "denominacao": "ATIVIDADES DE CONDICIONAMENTO FÍSICO",
        "descricao": "SERVIÇOS DE ACADEMIA DE ESPORTES",
        "item": "6",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1518",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9313100",
        "denominacao": "ATIVIDADES DE CONDICIONAMENTO FÍSICO",
        "descricao": "SERVIÇOS DE ACADEMIA DE GINÁSTICA",
        "item": "6",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1519",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8591100",
        "denominacao": "ENSINO DE ESPORTES",
        "descricao": "SERVIÇOS DE ACADEMIA DE ESPOSTES",
        "item": "6",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "11458",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8592901",
        "denominacao": "ENSINO DE DANCA",
        "descricao": "SERVIÇOS DE ACADEMIA DE DANÇA",
        "item": "6",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "11317",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9313100",
        "denominacao": "ATIVIDADES DE CONDICIONAMENTO FISICO",
        "descricao": "SERVIÇOS DE ACADEMIA DE GINÁSTICA",
        "item": "6",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "11319",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9313100",
        "denominacao": "ATIVIDADES DE CONDICIONAMENTO FISICO",
        "descricao": "SERVIÇOS DE CONDICIONAMENTO FÍSICO CORPORAL",
        "item": "6",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "11321",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8591100",
        "denominacao": "ENSINO DE ESPORTES",
        "descricao": "SERVIÇOS DE ACADEMIA DE NATAÇÃO",
        "item": "6",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "11320",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8591100",
        "denominacao": "ENSINO DE ESPORTES",
        "descricao": "SERVIÇOS DE ACADEMIA DE ARTES MARCIAIS",
        "item": "6",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "11318",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9609201",
        "denominacao": "CLINICAS DE ESTETICA E SIMILARES",
        "descricao": "SERVIÇOS DE ORIENTAÇÃO PARA EMAGRECIMENTO, SPA E CONGÊNERE",
        "item": "6",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11175",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9313100",
        "denominacao": "ATIVIDADES DE CONDICIONAMENTO FISICO",
        "descricao": "SERVIÇOS DE ORIENTAÇÃO PARA EMAGRECIMENTO, SPA E CONGÊNERE",
        "item": "6",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11176",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7119702",
        "denominacao": "ATIVIDADES DE ESTUDOS GEOLÓGICOS",
        "descricao": "SERVIÇOS DE GEOLOGIA",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "1406",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "0161999",
        "denominacao": "OUTRAS ATIVIDADES DE SERVIÇOS RELACIONADOS COM A AGRICULTURA",
        "descricao": "SERVIÇOS DE AGRONOMIA",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "1407",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7111100",
        "denominacao": "SERVICOS DE ARQUITETURA",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE PROJETOS DE URBANIZAÇÃO OU LOTEAMENTO",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "11177",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7490103",
        "denominacao": "SERVICOS DE AGRONOMIA E DE CONSULTORIA AS ATIVIDADES AGRICOLAS E PECUARIAS",
        "descricao": "SERVIÇOS DE ASSESSORIA ÀS ATIVIDADES AGRÍCOLAS",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "11335",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "0311604",
        "denominacao": "ATIVIDADES DE APOIO A PESCA EM AGUA SALGADA",
        "descricao": "SERVIÇO DE EXTENSÃO DE PESCA",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "11334",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "0312404",
        "denominacao": "ATIVIDADES DE APOIO A PESCA EM AGUA DOCE",
        "descricao": "SERVIÇO DE EXTENSÃO DE PESCA",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "11333",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7490103",
        "denominacao": "SERVICOS DE AGRONOMIA E DE CONSULTORIA AS ATIVIDADES AGRICOLAS E PECUARIAS",
        "descricao": "SERVIÇOS DE EXTENSÃO RURAL",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "11332",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7490103",
        "denominacao": "SERVICOS DE AGRONOMIA E DE CONSULTORIA AS ATIVIDADES AGRICOLAS E PECUARIAS",
        "descricao": "SERVIÇOS DE AGRONOMIA",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "11331",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7119702",
        "denominacao": "ATIVIDADES DE ESTUDOS GEOLOGICOS",
        "descricao": "SERVIÇOS DE GEOLOGIA",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "11330",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8130300",
        "denominacao": "ATIVIDADES PAISAGISTICAS",
        "descricao": "SERVIÇOS DE PAISAGISMO",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "11257",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS DE ENGENHARIA",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "11179",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7111100",
        "denominacao": "SERVICOS DE ARQUITETURA",
        "descricao": "SERVIÇOS DE ARQUITETURA",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "11178",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7111100",
        "denominacao": "SERVIÇOS DE ARQUITETURA",
        "descricao": "SERVIÇOS DE ARQUITETURA",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "1524",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7119701",
        "denominacao": "SERVICOS DE CARTOGRAFIA, TOPOGRAFIA E GEODESIA",
        "descricao": "SERVIÇOS DE AGRIMENSURA",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "11459",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7111100",
        "denominacao": "SERVIÇOS DE ARQUITETURA",
        "descricao": "SERVIÇOS DE ENGENHARIA",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "1525",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7111100",
        "denominacao": "SERVIÇOS DE ARQUITETURA",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE PROJETOS DE URBANIZAÇÃO OU LOTEAMENTO",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "1523",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7111100",
        "denominacao": "SERVIÇOS DE ARQUITETURA",
        "descricao": "SERVIÇOS DE PAISAGISMO",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "1459",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4391600",
        "denominacao": "OBRAS DE FUNDAÇÕES",
        "descricao": "SERVIÇOS DE ESCAVAÇÕES",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1411",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4521701",
        "denominacao": "EDIFICAÇÕES (RESIDENCIAIS, INDUSTRIAIS, COMERCIAIS E DE SERVIÇOS)",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO CIVIL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1416",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4321500",
        "denominacao": "INSTALAÇÃO E MANUTENÇÃO ELÉTRICA",
        "descricao": "SERVIÇOS DE ELETRICIDADE",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1426",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4222701",
        "denominacao": "CONSTRUCAO DE REDES DE ABASTECIMENTO DE AGUA, COLETA DE ESGOTO E CONSTRUCOES CORRELATAS, EXCETO OBRAS DE IRRIGACAO",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO DE REDES DE ÁGUA E ESGOTO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11351",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS DE SANEAMENTO AMBIENTAL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11350",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4222701",
        "denominacao": "CONSTRUCAO DE REDES DE ABASTECIMENTO DE AGUA, COLETA DE ESGOTO E CONSTRUCOES CORRELATAS, EXCETO OBRAS DE IRRIGACAO",
        "descricao": "SERVIÇOS DE SANEAMENTO AMBIENTAL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11349",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4222702",
        "denominacao": "OBRAS DE IRRIGACAO",
        "descricao": "SERVIÇOS DE OBRAS DE IRRIGAÇÃO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11348",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3600601",
        "denominacao": "CAPTACAO, TRATAMENTO E DISTRIBUICAO DE AGUA",
        "descricao": "SERVIÇOS DE OBRAS DE IRRIGAÇÃO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11347",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4291000",
        "denominacao": "OBRAS PORTUARIAS, MARITIMAS E FLUVIAIS",
        "descricao": "SERVIÇOS DE OBRAS MARÍTIMAS E FLUVIAIS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11346",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4211101",
        "denominacao": "CONSTRUCAO DE RODOVIAS E FERROVIAS",
        "descricao": "SERVIÇOS DE PAVIMENTAÇÃO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11345",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4211101",
        "denominacao": "CONSTRUCAO DE RODOVIAS E FERROVIAS",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO DE RODOVIAS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11344",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4120400",
        "denominacao": "CONSTRUCAO DE EDIFICIOS",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO CIVIL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11343",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4399199",
        "denominacao": "SERVICOS ESPECIALIZADOS PARA CONSTRUCAO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE CONCRETAGEM",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11342",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4120400",
        "denominacao": "CONSTRUCAO DE EDIFICIOS",
        "descricao": "SERVIÇOS DE CONCRETAGEM",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11341",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4120400",
        "denominacao": "CONSTRUCAO DE EDIFICIOS",
        "descricao": "SERVIÇOS DE BOMBEAMENTO DE CONCRETO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11340",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4399199",
        "denominacao": "SERVICOS ESPECIALIZADOS PARA CONSTRUCAO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE BOMBEAMENTO DE CONCRETO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11339",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4312600",
        "denominacao": "PERFURACOES E SONDAGENS",
        "descricao": "SERVIÇOS DE SONDAGEM ",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11338",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4391600",
        "denominacao": "OBRAS DE FUNDACOES",
        "descricao": "SERVIÇOS DE ESTAQUEAMENTO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11337",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4313400",
        "denominacao": "OBRAS DE TERRAPLENAGEM",
        "descricao": "SERVIÇOS DE ESCAVAÇÕES",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11336",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4550001",
        "denominacao": "OBRAS DE ALVENARIA E REBOCO.",
        "descricao": "SERVIÇOS DE OBRAS DE ALVENARIA E REBOCO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1445",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4322302",
        "denominacao": "INSTALAÇÃO E MANUTENÇÃO DE SISTEMAS CENTRAIS DE AR CONDICIONADO, DE VENTILAÇÃO E REFRIGERAÇÃO",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE SISTEMAS CENTRAIS DE AR CONDICIONADO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1442",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4529299",
        "denominacao": "OUTRAS OBRAS DE ENGENHARIA CIVIL",
        "descricao": "SERVIÇOS DE INSTALAÇÃO E MONTAGEM DE PRODUTOS, PEÇAS E EQUIPAMENTOS DESTINADOS À CONSTRUÇÃO CIVIL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1438",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4292801",
        "denominacao": "MONTAGEM DE ESTRUTURAS METÁLICAS",
        "descricao": "SERVIÇOS DE MONTAGEM DE ESTRUTURA METÁLICAS E DE FERRO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1437",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4211102",
        "denominacao": "PINTURA PARA SINALIZAÇÃO EM PISTAS RODOVIÁRIAS E AEROPORTOS",
        "descricao": "SERVIÇOS DE PINTURA DE CONSTRUÇÃO CIVIL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1435",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4513600",
        "denominacao": "TERRAPLENAGEM E OUTRAS MOVIMENTAÇÕES DE TERRA",
        "descricao": "SERVIÇOS DE DRENAGEM",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1434",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4513600",
        "denominacao": "TERRAPLENAGEM E OUTRAS MOVIMENTAÇÕES DE TERRA",
        "descricao": "SERVIÇOS DE TERRAPLANAGEM",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1433",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4511002",
        "denominacao": "PREPARAÇÃO DE TERRENOS",
        "descricao": "SERVIÇOS DE TERRAPLANAGEM",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1432",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4322301",
        "denominacao": "INSTALAÇÕES HIDRÁULICAS, SANITÁRIAS E DE GÁS",
        "descricao": "SERVIÇOS DE INSTALAÇÃO HIDRÁULICAS, SANITÁRIAS E GÁS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1428",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4322303",
        "denominacao": "INSTALACOES DE SISTEMA DE PREVENCAO CONTRA INCENDIO",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE SISTEMAS DE PREVENÇÃO CONTRA INCÊNDIOS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11358",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4329104",
        "denominacao": "MONTAGEM E INSTALACAO DE SISTEMAS E EQUIPAMENTOS DE ILUMINACAO E SINALIZACAO EM VIAS PUBLICAS, PORTOS E AEROPORTOS",
        "descricao": "SERVIÇOS DE INSTALAÇÃO HIDRÁULICAS, SANITÁRIAS E GÁS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11357",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4322301",
        "denominacao": "INSTALACOES HIDRAULICAS, SANITARIAS E DE GAS",
        "descricao": "SERVIÇOS DE INSTALAÇÃO HIDRÁULICAS, SANITÁRIAS E GÁS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11356",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4329103",
        "denominacao": "INSTALACAO, MANUTENCAO E REPARACAO DE ELEVADORES, ESCADAS E ESTEIRAS ROLANTES, EXCETO DE FABRICACAO PROPRIA",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE ELEVADORES, ESCADAS E ESTEIRAS LOLANTES",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11355",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4399105",
        "denominacao": "PERFURACAO E CONSTRUCAO DE POCOS DE AGUA",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO DE POÇOS DE ÁGUAS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11354",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4223500",
        "denominacao": "CONSTRUCAO DE REDES DE TRANSPORTES POR DUTOS, EXCETO PARA AGUA E ESGOTO",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO DE REDES DE TRANSPORTE POR DUTOS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11353",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4222701",
        "denominacao": "CONSTRUCAO DE REDES DE ABASTECIMENTO DE AGUA, COLETA DE ESGOTO E CONSTRUCOES CORRELATAS, EXCETO OBRAS DE IRRIGACAO",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO DE REDES DE TRANSPORTE POR DUTOS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11352",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4321500",
        "denominacao": "INSTALACAO E MANUTENCAO ELETRICA",
        "descricao": "SERVIÇOS DE ELETRECIDADE",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11512",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4399101",
        "denominacao": "ADMINISTRACAO DE OBRAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE OBRAS DE CONSTRUÇÃO CIVIL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11485",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4330402",
        "denominacao": "INSTALACAO DE PORTAS, JANELAS, TETOS, DIVISORIAS E ARMARIOS EMBUTIDOS DE QUALQUER MATERIAL",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE ESQUADRIAS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11374",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4330401",
        "denominacao": "IMPERMEABILIZACAO EM OBRAS DE ENGENHARIA CIVIL",
        "descricao": "SERVIÇOS DE IMPERMEABILIZAÇÃO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11373",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4330499",
        "denominacao": "OUTRAS OBRAS DE ACABAMENTO DA CONSTRUCAO",
        "descricao": "SERVIÇOS DE OBRAS DE ALVENARIA E REBOCO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11372",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4330499",
        "denominacao": "OUTRAS OBRAS DE ACABAMENTO DA CONSTRUCAO",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE PISCINAS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11371",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4322302",
        "denominacao": "INSTALACAO E MANUTENCAO DE SISTEMAS CENTRAIS DE AR CONDICIONADO, DE VENTILACAO E REFRIGERACAO",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE SISTEMAS CENTRAIS DE AR CONDICIONADO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11370",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4399102",
        "denominacao": "MONTAGEM E DESMONTAGEM DE ANDAIMES E OUTRAS ESTRUTURAS TEMPORARIAS",
        "descricao": "SERVIÇOS DE INSTALAÇÃO E MONTAGEM DE PRODUTOS, PEÇAS E EQUIPAMENTOS DESTINADOS À CONSTRUÇÃO CIVIL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11369",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "2511000",
        "denominacao": "FABRICACAO DE ESTRUTURAS METALICAS",
        "descricao": "SERVIÇOS DE MONTAGEM DE ESTRUTURA METÁLICAS E DE FERRO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11368",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4292801",
        "denominacao": "MONTAGEM DE ESTRUTURAS METALICAS",
        "descricao": "SERVIÇOS DE MONTAGEM DE ESTRUTURA METÁLICAS E DE FERRO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11367",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4399102",
        "denominacao": "MONTAGEM E DESMONTAGEM DE ANDAIMES E OUTRAS ESTRUTURAS TEMPORARIAS",
        "descricao": "SERVIÇOS DE MONTAGEM DE ESTRUTURA METÁLICAS E DE FERRO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11366",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4330404",
        "denominacao": "SERVICOS DE PINTURA DE EDIFICIOS EM GERAL",
        "descricao": "SERVIÇOS DE PINTURA DE CONSTRUÇÃO CIVIL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11365",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3701100",
        "denominacao": "GESTAO DE REDES DE ESGOTO",
        "descricao": "SERVIÇOS DE DRENAGEM",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11364",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4319300",
        "denominacao": "SERVICOS DE PREPARACAO DO TERRENO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE DRENAGEM",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11363",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4313400",
        "denominacao": "OBRAS DE TERRAPLENAGEM",
        "descricao": "SERVIÇOS DE TERRAPLANAGEM",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11362",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4329105",
        "denominacao": "TRATAMENTOS TERMICOS, ACUSTICOS OU DE VIBRACAO",
        "descricao": "SERVIÇOS DE TRATAMENTO ACÚSTICO E TÉRMICO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11361",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4329104",
        "denominacao": "MONTAGEM E INSTALACAO DE SISTEMAS E EQUIPAMENTOS DE ILUMINACAO E SINALIZACAO EM VIAS PUBLICAS, PORTOS E AEROPORTOS",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE PLACAS DE SINALIZAÇÃO DE TRÂNSITO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11360",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4211102",
        "denominacao": "PINTURA PARA SINALIZACAO EM PISTAS RODOVIARIAS E AEROPORTOS",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE PLACAS DE SINALIZAÇÃO DE TRÂNSITO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11359",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4330402",
        "denominacao": "INSTALAÇÃO DE PORTAS, JANELAS, TETOS, DIVISÓRIAS E ARMÁRIOS EMBUTIDOS DE QUALQUER MATERIAL",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE ESQUADRIAS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1448",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4330404",
        "denominacao": "SERVIÇOS DE PINTURA DE EDIFÍCIOS EM GERAL",
        "descricao": "SERVIÇOS DE PINTURA DE CONSTRUÇÃO CIVIL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1447",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4399101",
        "denominacao": "ADMINISTRAÇÃO DE OBRAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE OBRAS DE CONSTRUÇÃO CIVIL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1417",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4521701",
        "denominacao": "EDIFICAÇÕES (RESIDENCIAIS, INDUSTRIAIS, COMERCIAIS E DE SERVIÇOS)",
        "descricao": "SERVIÇOS DE CONCRETAGEM",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1415",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4521701",
        "denominacao": "EDIFICAÇÕES (RESIDENCIAIS, INDUSTRIAIS, COMERCIAIS E DE SERVIÇOS)",
        "descricao": "SERVIÇOS DE BOMBEAMENTO DE CONCRETO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1414",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVIÇOS DE ENGENHARIA",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE PLANOS DIRETORES",
        "item": "7",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "1450",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVIÇOS DE ENGENHARIA",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE ANTEPROJETOS, PROJETOS BÁSICOS E PROJETOS EXECUTIVOS PARA TRABALHOS DE ENGENHARIA",
        "item": "7",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "1453",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVIÇOS DE ENGENHARIA",
        "descricao": "SERVIÇOS DE ESTUDOS DE VIABILIDADE",
        "item": "7",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "1451",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7119703",
        "denominacao": "SERVICOS DE DESENHO TECNICO RELACIONADOS A ARQUITETURA E ENGENHARIA",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE ANTEPROJETOS, PROJETOS BÁSICOS E PROJETOS EXECUTIVOS PARA TRABALHOS DE ENGENHARIA",
        "item": "7",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "11379",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS DE ESTUDOS ORGANIZACIONAIS",
        "item": "7",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "11378",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS DE ESTUDOS DE VIABILIDADE",
        "item": "7",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "11377",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE PLANOS DIRETORES",
        "item": "7",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "11376",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4311801",
        "denominacao": "DEMOLICAO DE EDIFICIOS E OUTRAS ESTRUTURAS",
        "descricao": "SERVIÇOS DE DEMOLIÇÃO",
        "item": "7",
        "subitem": "4",
        "aliquota": "3",
        "idcnae": "11380",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4521701",
        "denominacao": "EDIFICAÇÕES (RESIDENCIAIS, INDUSTRIAIS, COMERCIAIS E DE SERVIÇOS)",
        "descricao": "SERVIÇOS DE REPARAÇÃO, CONSERVAÇÃO E REFORMA DA CONST. CIVIL",
        "item": "7",
        "subitem": "5",
        "aliquota": "3",
        "idcnae": "1455",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9102302",
        "denominacao": "RESTAURAÇÃO E CONSERVAÇÃO DE LUGARES E PRÉDIOS HISTÓRICOS",
        "descricao": "SERVIÇOS DE CONSERVAÇÃO DE EDIFÍCIOS",
        "item": "7",
        "subitem": "5",
        "aliquota": "3",
        "idcnae": "1457",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4221903",
        "denominacao": "MANUTENÇÃO DE REDES DE DISTRIBUIÇÃO DE ENERGIA ELÉTRICA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE REDES DE DISTRIBUIÇÃO DE ENERGIA ELÉTRICA",
        "item": "7",
        "subitem": "5",
        "aliquota": "3",
        "idcnae": "1458",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4120400",
        "denominacao": "CONSTRUCAO DE EDIFICIOS",
        "descricao": "SERVIÇOS DE REPARAÇÃO, CONSERVAÇÃO E REFORMA DA CONST. CIVIL",
        "item": "7",
        "subitem": "5",
        "aliquota": "3",
        "idcnae": "11255",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4221903",
        "denominacao": "MANUTENCAO DE REDES DE DISTRIBUICAO DE ENERGIA ELETRICA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE REDES DE DISTRIBUIÇÃO DE ENERGIA ELÉTRICA",
        "item": "7",
        "subitem": "5",
        "aliquota": "3",
        "idcnae": "11483",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4211101",
        "denominacao": "CONSTRUÇÃO DE RODOVIAS E FERROVIAS",
        "descricao": "SERVIÇOS DE REPARAÇÃO, CONSERVAÇÃO E REFORMA DE ESTRADAS, PONTES E PORTOS",
        "item": "7",
        "subitem": "5",
        "aliquota": "3",
        "idcnae": "1456",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4221905",
        "denominacao": "MANUTENÇÃO DE ESTAÇÕES E REDES DE TELECOMUNICAÇÕES",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE ESTAÇÕES E REDES DE TELEFONIA E COMUNICAÇÕES",
        "item": "7",
        "subitem": "5",
        "aliquota": "3",
        "idcnae": "1460",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4120400",
        "denominacao": "CONSTRUCAO DE EDIFICIOS",
        "descricao": "SERVIÇOS DE CONSERVAÇÃO DE EDIFÍCIOS",
        "item": "7",
        "subitem": "5",
        "aliquota": "3",
        "idcnae": "11256",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4221905",
        "denominacao": "MANUTENCAO DE ESTACOES E REDES DE TELECOMUNICACOES",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE ESTAÇÕES E REDES DE TELEFONIA E COMUNICAÇÕES",
        "item": "7",
        "subitem": "5",
        "aliquota": "3",
        "idcnae": "11258",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4330403",
        "denominacao": "OBRAS DE ACABAMENTO EM GESSO E ESTUQUE",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE GESSO",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "1351",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4330402",
        "denominacao": "INSTALAÇÃO DE PORTAS, JANELAS, TETOS, DIVISÓRIAS E ARMÁRIOS EMBUTIDOS DE QUALQUER MATERIAL",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE FECHADURAS",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "1353",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4330405",
        "denominacao": "APLICAÇÃO DE REVESTIMENTOS E DE RESINAS EM INTERIORES E EXTERIORES",
        "descricao": "SERVIÇO DE COLOCAÇÃO E INSTALAÇÃO DE REVESTIMENTOS DE PAREDE",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "1356",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4330499",
        "denominacao": "OUTRAS OBRAS DE ACABAMENTO DA CONSTRUCAO",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE PERSIANAS",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "11259",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4330403",
        "denominacao": "OBRAS DE ACABAMENTO EM GESSO E ESTUQUE",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE GESSO",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "11381",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4330402",
        "denominacao": "INSTALACAO DE PORTAS, JANELAS, TETOS, DIVISORIAS E ARMARIOS EMBUTIDOS DE QUALQUER MATERIAL",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE FECHADURAS",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "11383",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4330405",
        "denominacao": "APLICACAO DE REVESTIMENTOS E DE RESINAS EM INTERIORES E EXTERIORES",
        "descricao": "SERVIÇO DE COLOCAÇÃO E INSTALAÇÃO DE CARPETE",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "11385",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4330405",
        "denominacao": "APLICACAO DE REVESTIMENTOS E DE RESINAS EM INTERIORES E EXTERIORES",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE ASSOALHO",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "11387",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4330405",
        "denominacao": "APLICACAO DE REVESTIMENTOS E DE RESINAS EM INTERIORES E EXTERIORES",
        "descricao": "SERVIÇO DE COLOCAÇÃO E INSTALAÇÃO DE REVESTIMENTOS DE PAREDE",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "11386",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4330405",
        "denominacao": "APLICACAO DE REVESTIMENTOS E DE RESINAS EM INTERIORES E EXTERIORES",
        "descricao": "SERVIÇO DE COLOCAÇÃO DE PELÍCULA DE CONTENÇÃO SOLA EM IMÓVEIS",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "11384",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4330402",
        "denominacao": "INSTALACAO DE PORTAS, JANELAS, TETOS, DIVISORIAS E ARMARIOS EMBUTIDOS DE QUALQUER MATERIAL",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE DIVISÓRIAS",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "11382",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4330499",
        "denominacao": "OUTRAS OBRAS DE ACABAMENTO DA CONSTRUCAO",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE TAPETES E CORTINAS",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "11260",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4330499",
        "denominacao": "OUTRAS OBRAS DE ACABAMENTO DA CONSTRUCAO",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE VIDROS",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "11388",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4550099",
        "denominacao": "OUTRAS OBRAS DE ACABAMENTO DA CONSTRUÇÃO",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE VIDROS",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "1358",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4330405",
        "denominacao": "APLICACAO DE REVESTIMENTOS E DE RESINAS EM INTERIORES E EXTERIORES",
        "descricao": "SERVIÇOS DE RECUPERAÇÃO, RASPAGEM, POLIMENTO E LUSTRAÇÃO DE ASSOALHOS, PISOS E PAREDES",
        "item": "7",
        "subitem": "7",
        "aliquota": "3",
        "idcnae": "11389",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4322302",
        "denominacao": "INSTALACAO E MANUTENCAO DE SISTEMAS CENTRAIS DE AR CONDICIONADO, DE VENTILACAO E REFRIGERACAO",
        "descricao": "SERVIÇOS DE CALEFAÇÃO",
        "item": "7",
        "subitem": "8",
        "aliquota": "3",
        "idcnae": "11390",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9000001",
        "denominacao": "LIMPEZA URBANA - EXCETO GESTÃO DE ATERROS SANITÁRIOS",
        "descricao": "SERVIÇOS DE REMOÇÃO DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "1363",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3900500",
        "denominacao": "DESCONTAMINACAO E OUTROS SERVICOS DE GESTAO DE RESIDUOS",
        "descricao": "SERVIOS DE INCINERAÇÃO DE RESÍDUOS",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "11407",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8129000",
        "denominacao": "ATIVIDADES DE LIMPEZA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE VARRIÇÃO E COLETA DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "11406",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8121400",
        "denominacao": "LIMPEZA EM PREDIOS E EM DOMICILIOS",
        "descricao": "SERVIÇOS DE VARRIÇÃO E COLETA DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "11405",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3811400",
        "denominacao": "COLETA DE RESIDUOS NAO-PERIGOSOS",
        "descricao": "SERVIÇOS DE VARRIÇÃO E COLETA DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "11404",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3821100",
        "denominacao": "TRATAMENTO E DISPOSICAO DE RESIDUOS NAO-PERIGOSOS",
        "descricao": "SERVIÇOS DE TRATAMENTO DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "11403",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3822000",
        "denominacao": "TRATAMENTO E DISPOSICAO DE RESIDUOS PERIGOSOS",
        "descricao": "SERVIÇOS DE TRATAMENTO DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "11402",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3811400",
        "denominacao": "COLETA DE RESIDUOS NAO-PERIGOSOS",
        "descricao": "SERVIÇOS DE REMOÇÃO DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "11401",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4930201",
        "denominacao": "TRANSPORTE RODOVIARIO DE CARGA, EXCETO PRODUTOS PERIGOSOS E MUDANCAS, MUNICIPAL",
        "descricao": "SERVIÇOS DE REMOÇÃO DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "11400",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4930202",
        "denominacao": "TRANSPORTE RODOVIARIO DE CARGA, EXCETO PRODUTOS PERIGOSOS E MUDANCAS, INTERMUNICIPAL, INTERESTADUAL E INTERNACIONAL",
        "descricao": "SERVIÇOS DE REMOÇÃO DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "11399",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3821100",
        "denominacao": "TRATAMENTO E DISPOSICAO DE RESIDUOS NAO-PERIGOSOS",
        "descricao": "SERVIOS DE INCINERAÇÃO DE RESÍDUOS",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "11409",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3822000",
        "denominacao": "TRATAMENTO E DISPOSICAO DE RESIDUOS PERIGOSOS",
        "descricao": "SERVIOS DE INCINERAÇÃO DE RESÍDUOS",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "11408",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4930203",
        "denominacao": "TRANSPORTE RODOVIARIO DE PRODUTOS PERIGOSOS",
        "descricao": "SERVIÇOS DE REMOÇÃO DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "11398",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4930204",
        "denominacao": "TRANSPORTE RODOVIARIO DE MUDANCAS",
        "descricao": "SERVIÇOS DE REMOÇÃO DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "11397",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3831901",
        "denominacao": "RECUPERACAO DE SUCATAS DE ALUMINIO",
        "descricao": "SERVIÇOS DE RECICLAGEM DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "11396",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3831999",
        "denominacao": "RECUPERACAO DE MATERIAIS METALICOS, EXCETO ALUMINIO",
        "descricao": "SERVIÇOS DE RECICLAGEM DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "11395",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3832700",
        "denominacao": "RECUPERACAO DE MATERIAIS PLASTICOS",
        "descricao": "SERVIÇOS DE RECICLAGEM DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "11394",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3839401",
        "denominacao": "USINAS DE COMPOSTAGEM",
        "descricao": "SERVIÇOS DE RECICLAGEM DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "11393",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3839499",
        "denominacao": "RECUPERACAO DE MATERIAIS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE RECICLAGEM DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "11392",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3821100",
        "denominacao": "TRATAMENTO E DISPOSICAO DE RESIDUOS NAO-PERIGOSOS",
        "descricao": "SERVIÇOS DE SEPARAÇÃO E DESTINAÇÃO FINAL DO LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "11391",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7470501",
        "denominacao": "ATIVIDADES DE LIMPEZA EM IMÓVEIS",
        "descricao": "SERVIÇOS DE LIMPEZA, MANUTENÇÃO E CONSERVAÇÃO DE CHAMINÉS",
        "item": "7",
        "subitem": "10",
        "aliquota": "2,5",
        "idcnae": "1367",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7470501",
        "denominacao": "ATIVIDADES DE LIMPEZA EM IMÓVEIS",
        "descricao": "SERVIÇOS DE LIMPEZA, MANUTENÇÃO E CONSERVAÇÃO DE PARQUES",
        "item": "7",
        "subitem": "10",
        "aliquota": "2,5",
        "idcnae": "1370",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7470501",
        "denominacao": "ATIVIDADES DE LIMPEZA EM IMÓVEIS",
        "descricao": "SERVIÇOS DE LIMPEZA, MANUTENÇÃO E CONSERVAÇÃO DE JARDINS",
        "item": "7",
        "subitem": "10",
        "aliquota": "2,5",
        "idcnae": "1371",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9700500",
        "denominacao": "SERVIÇOS DOMÉSTICOS",
        "descricao": "SERVIÇOS DOMÉSTICOS",
        "item": "7",
        "subitem": "10",
        "aliquota": "2,5",
        "idcnae": "1373",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8129000",
        "denominacao": "ATIVIDADES DE LIMPEZA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE LIMPEZA, MANUTENÇÃO E CONSERVAÇÃO DE PISCINAS",
        "item": "7",
        "subitem": "10",
        "aliquota": "2,5",
        "idcnae": "11411",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8129000",
        "denominacao": "ATIVIDADES DE LIMPEZA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE LIMPEZA, MANUTENÇÃO E CONSERVAÇÃO DE PARQUES",
        "item": "7",
        "subitem": "10",
        "aliquota": "2,5",
        "idcnae": "11413",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8129000",
        "denominacao": "ATIVIDADES DE LIMPEZA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE LIMPEZA, MANUTENÇÃO E CONSERVAÇÃO DE VIAS E LOGRADOUROS PÚBLICOS",
        "item": "7",
        "subitem": "10",
        "aliquota": "2,5",
        "idcnae": "11415",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9700500",
        "denominacao": "SERVICOS DOMESTICOS",
        "descricao": "SERVIÇOS DOMÉSTICOS",
        "item": "7",
        "subitem": "10",
        "aliquota": "2,5",
        "idcnae": "11416",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8129000",
        "denominacao": "ATIVIDADES DE LIMPEZA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE LIMPEZA, MANUTENÇÃO E CONSERVAÇÃO DE JARDINS",
        "item": "7",
        "subitem": "10",
        "aliquota": "2,5",
        "idcnae": "11414",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8121400",
        "denominacao": "LIMPEZA EM PREDIOS E EM DOMICILIOS",
        "descricao": "SERVIÇOS DE LIMPEZA, MANUTENÇÃO E CONSERVAÇÃO DE IMÓVEIS",
        "item": "7",
        "subitem": "10",
        "aliquota": "2,5",
        "idcnae": "11412",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8129000",
        "denominacao": "ATIVIDADES DE LIMPEZA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE LIMPEZA, MANUTENÇÃO E CONSERVAÇÃO DE CHAMINÉS",
        "item": "7",
        "subitem": "10",
        "aliquota": "2,5",
        "idcnae": "11410",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7470501",
        "denominacao": "ATIVIDADES DE LIMPEZA EM IMÓVEIS",
        "descricao": "SERVIÇOS DE LIMPEZA, MANUTENÇÃO E CONSERVAÇÃO DE PISCINAS",
        "item": "7",
        "subitem": "10",
        "aliquota": "2,5",
        "idcnae": "1368",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7470501",
        "denominacao": "ATIVIDADES DE LIMPEZA EM IMÓVEIS",
        "descricao": "SERVIÇOS DE LIMPEZA, MANUTENÇÃO E CONSERVAÇÃO DE IMÓVEIS",
        "item": "7",
        "subitem": "10",
        "aliquota": "2,5",
        "idcnae": "1369",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7410202",
        "denominacao": "DECORAÇÃO DE INTERIORES",
        "descricao": "SERVIÇOS DE DECORAÇÃO",
        "item": "7",
        "subitem": "11",
        "aliquota": "3",
        "idcnae": "1374",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8130300",
        "denominacao": "ATIVIDADES PAISAGÍSTICAS",
        "descricao": "SERVIÇOS DE JARDINAGEM",
        "item": "7",
        "subitem": "11",
        "aliquota": "3",
        "idcnae": "1376",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8130300",
        "denominacao": "ATIVIDADES PAISAGISTICAS",
        "descricao": "SERVIÇOS DE JARDINAGEM",
        "item": "7",
        "subitem": "11",
        "aliquota": "3",
        "idcnae": "11419",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7410202",
        "denominacao": "DECORACAO DE INTERIORES",
        "descricao": "SERVIÇOS DE DECORAÇÃO E JARDINAGEM",
        "item": "7",
        "subitem": "11",
        "aliquota": "3",
        "idcnae": "11418",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7410202",
        "denominacao": "DECORACAO DE INTERIORES",
        "descricao": "SERVIÇOS DE DECORAÇÃO",
        "item": "7",
        "subitem": "11",
        "aliquota": "3",
        "idcnae": "11417",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8130300",
        "denominacao": "ATIVIDADES PAISAGÍSTICAS",
        "descricao": "SERVIÇOS DE DECORAÇÃO E JARDINAGEM",
        "item": "7",
        "subitem": "11",
        "aliquota": "3",
        "idcnae": "1375",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3701100",
        "denominacao": "GESTAO DE REDES DE ESGOTO",
        "descricao": "SERVIÇOS DE CONTROLE E TRATAMENTO DE EFLUENTES",
        "item": "7",
        "subitem": "12",
        "aliquota": "3",
        "idcnae": "11420",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3701100",
        "denominacao": "GESTAO DE REDES DE ESGOTO",
        "descricao": "SERVIÇOS DE TRATAMENTO E CONTROLE DE AGENTES FÍSICOS, QUÍMICOS E BIOLÓGICOS",
        "item": "7",
        "subitem": "12",
        "aliquota": "3",
        "idcnae": "11421",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7470502",
        "denominacao": "ATIVIDADES DE IMUNIZAÇÃO E CONTROLE DE PRAGAS URBANAS",
        "descricao": "SERVIÇOS DE DEDETIZAÇÃO, DESINFECÇÃO, DESINSETIZAÇÃO, IMUNIZAÇÃO, HIGIENIZAÇÃO, DESRATIZAÇÃO E PULVERIZAÇÃO",
        "item": "7",
        "subitem": "13",
        "aliquota": "3",
        "idcnae": "1380",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8122200",
        "denominacao": "IMUNIZACAO E CONTROLE DE PRAGAS URBANAS",
        "descricao": "SERVIÇOS DE DEDETIZAÇÃO, DESINFECÇÃO, DESINSETIZAÇÃO, IMUNIZAÇÃO, HIGIENIZAÇÃO, DESRATIZAÇÃO E PULVERIZAÇÃO",
        "item": "7",
        "subitem": "13",
        "aliquota": "3",
        "idcnae": "11422",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "0161001",
        "denominacao": "SERVICO DE PULVERIZACAO E CONTROLE DE PRAGAS AGRICOLAS",
        "descricao": "SERVIÇOS DE PULVERIZAÇÃO",
        "item": "7",
        "subitem": "13",
        "aliquota": "3",
        "idcnae": "11423",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "0161003",
        "denominacao": "SERVICO DE PREPARACAO DE TERRENO, CULTIVO E COLHEITA",
        "descricao": "SERVIÇOS DE COLHEITA",
        "item": "7",
        "subitem": "16",
        "aliquota": "3",
        "idcnae": "11424",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "0161003",
        "denominacao": "SERVICO DE PREPARACAO DE TERRENO, CULTIVO E COLHEITA",
        "descricao": "SERVIÇO DE FLORESTAMENTO, REFLORESTAMENTO, SEMEADURA E ADUBAÇÃO",
        "item": "7",
        "subitem": "16",
        "aliquota": "3",
        "idcnae": "11425",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4299599",
        "denominacao": "OUTRAS OBRAS DE ENGENHARIA CIVIL NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ESCORAMENTO E CONTENÇÃO DE ENCOSTAS",
        "item": "7",
        "subitem": "17",
        "aliquota": "3",
        "idcnae": "11426",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4291000",
        "denominacao": "OBRAS PORTUARIAS, MARITIMAS E FLUVIAIS",
        "descricao": "SERVIÇOS DE LIMPEZA E DRENAGEM DE RIOS, PORTOS, CANAIS, BAÍAS, LAGOS, LAGOAS, REPRESAS E AÇUDES",
        "item": "7",
        "subitem": "18",
        "aliquota": "3",
        "idcnae": "11427",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7420999",
        "denominacao": "OUTROS SERVIÇOS TÉCNICOS ESPECIALIZADOS",
        "descricao": "SERVIÇOS DE ACOMPANHAMENTO E FISCALIZAÇÃO DA EXECUÇÃO DE OBRAS DE ENGENHARIA, ARQUITETURA E URBANISMO",
        "item": "7",
        "subitem": "19",
        "aliquota": "3",
        "idcnae": "1386",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS DE ACOMPANHAMENTO E FISCALIZAÇÃO DA EXECUÇÃO DE OBRAS DE ENGENHARIA, ARQUITETURA E URBANISMO",
        "item": "7",
        "subitem": "19",
        "aliquota": "3",
        "idcnae": "11428",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7111100",
        "denominacao": "SERVICOS DE ARQUITETURA",
        "descricao": "SERVIÇOS DE ACOMPANHAMENTO E FISCALIZAÇÃO DA EXECUÇÃO DE OBRAS DE ENGENHARIA, ARQUITETURA E URBANISMO",
        "item": "7",
        "subitem": "19",
        "aliquota": "3",
        "idcnae": "11429",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7119701",
        "denominacao": "SERVIÇOS DE CARTOGRAFIA, TOPOGRAFIA E GEODÉSIA",
        "descricao": "SERVIÇOS DE CARTOGRAFIA",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "1389",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7119701",
        "denominacao": "SERVICOS DE CARTOGRAFIA, TOPOGRAFIA E GEODESIA",
        "descricao": "SERVIÇOS DE BATIMETRIA",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "11431",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7119701",
        "denominacao": "SERVICOS DE CARTOGRAFIA, TOPOGRAFIA E GEODESIA",
        "descricao": "SERVIÇOS GEODÉSICOS E GEOGRÁFICOS",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "11432",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7119701",
        "denominacao": "SERVICOS DE CARTOGRAFIA, TOPOGRAFIA E GEODESIA",
        "descricao": "SERVIÇOS GEOGRÁFICOS",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "11323",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7119701",
        "denominacao": "SERVICOS DE CARTOGRAFIA, TOPOGRAFIA E GEODESIA",
        "descricao": "SERVIÇOS DE MAPEAMENTO",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "11325",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7119701",
        "denominacao": "SERVICOS DE CARTOGRAFIA, TOPOGRAFIA E GEODESIA",
        "descricao": "SERVIÇOS DE CARTOGRAFIA",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "11375",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7119701",
        "denominacao": "SERVICOS DE CARTOGRAFIA, TOPOGRAFIA E GEODESIA",
        "descricao": "SERVIÇOS GEODÉSICOS E GEOFÍSICOS",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "11324",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7119701",
        "denominacao": "SERVICOS DE CARTOGRAFIA, TOPOGRAFIA E GEODESIA",
        "descricao": "SERVIÇOS BATIMÉTRICOS",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "11322",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7119701",
        "denominacao": "SERVIÇOS DE CARTOGRAFIA, TOPOGRAFIA E GEODÉSIA",
        "descricao": "SERVIÇOS DE TOPOGRAFIA E AGRIMENSURA",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "1392",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7119799",
        "denominacao": "ATIVIDADES TECNICAS RELACIONADAS A ENGENHARIA E ARQUITETURA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE AEROFOTOGRAMETRIA",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "11430",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "0910600",
        "denominacao": "ATIVIDADES DE APOIO A EXTRACAO DE PETROLEO E GAS NATURAL",
        "descricao": "SERVIÇOS DE PESCARIA",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "10598",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "0910600",
        "denominacao": "ATIVIDADES DE APOIO A EXTRACAO DE PETROLEO E GAS NATURAL",
        "descricao": "SERVIÇOS DE TESTEMUNHAGEM",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "11329",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7119702",
        "denominacao": "ATIVIDADES DE ESTUDOS GEOLOGICOS",
        "descricao": "SERVIÇOS DE PERFILAGEM",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "11328",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "0910600",
        "denominacao": "ATIVIDADES DE APOIO A EXTRACAO DE PETROLEO E GAS NATURAL",
        "descricao": "SERVIÇOS DE MERGULHO",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "11327",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "0910600",
        "denominacao": "ATIVIDADES DE APOIO A EXTRACAO DE PETROLEO E GAS NATURAL",
        "descricao": "SERVIÇOS DE PERFURAÇÃO DE POÇOS DE PETRÓLEO",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "11326",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7210000",
        "denominacao": "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIENCIAS FISICAS E NATURAIS",
        "descricao": "SERVIÇOS DE PESQUISAS RELACIOANADAS AO PETRÓLEO",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "10602",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "0910600",
        "denominacao": "ATIVIDADES DE APOIO A EXTRACAO DE PETROLEO E GAS NATURAL",
        "descricao": "SERVIÇOS DE  ESTIMULAÇÃO E OUTROS SERVIÇOS RELACIONADOS COM A EXPLORAÇÃO E EXPLOTAÇÃO DE PETRÓLEO, GÁS NATURAL E OUTROS RECURSOS MINERAIS",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "10599",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "0910600",
        "denominacao": "ATIVIDADES DE APOIO A EXTRACAO DE PETROLEO E GAS NATURAL",
        "descricao": "SERVIÇOS DE CONCRETAÇÃO",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "10600",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7210000",
        "denominacao": "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIENCIAS FISICAS E NATURAIS",
        "descricao": "SERVIÇOS DE PESQUISAS RELACIOANADAS AO CARVÃO MINERAL",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "10601",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "0161099",
        "denominacao": "ATIVIDADES DE APOIO A AGRICULTURA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE NUCLEAÇÃO  E BOMBARDEAMENTO DE NUVENS",
        "item": "7",
        "subitem": "22",
        "aliquota": "3",
        "idcnae": "10603",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8511200",
        "denominacao": "EDUCAÇÃO INFANTIL - CRECHE",
        "descricao": "SERVIÇOS DE EDUCAÇÃO INFANTIL - CRECHES",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2656",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8533300",
        "denominacao": "EDUCAÇÃO SUPERIOR - PÓS-GRADUAÇÃO E EXTENSÃO",
        "descricao": "SERVIÇOS DE EDUÇAÇÃO - ENSINO SUPERIOR (GRADUAÇÃO E PÓS-GRADUAÇÃO E EXTENSÃO)",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2662",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8542200",
        "denominacao": "EDUCAÇÃO PROFISSIONAL DE NÍVEL TECNOLÓGICO",
        "descricao": "SERVIÇOS DE ENSINO PROFISSIONAL DE NÍVEL TECNOLÓGICO",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2664",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8542200",
        "denominacao": "EDUCACAO PROFISSIONAL DE NIVEL TECNOLOGICO",
        "descricao": "SERVIÇOS DE ENSINO PROFISSIONAL DE NÍVEL TECNOLÓGICO",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10612",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8541400",
        "denominacao": "EDUCACAO PROFISSIONAL DE NIVEL TECNICO",
        "descricao": "SERVIÇOS DE ENSINO TÉCNICO INDUSTRIAL OU COMERCIAL",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10611",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8533300",
        "denominacao": "EDUCAÇÃO SUPERIOR - PÓS-GRADUAÇÃO E EXTENSÃO",
        "descricao": "SERVIÇOS DE EDUÇAÇÃO - ENSINO SUPERIOR (GRADUAÇÃO E PÓS-GRADUAÇÃO E EXTENSÃO)",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10610",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8532500",
        "denominacao": "EDUCAÇÃO SUPERIOR - GRADUAÇÃO E PÓS-GRADUAÇÃO",
        "descricao": "SERVIÇOS DE EDUÇAÇÃO - ENSINO SUPERIOR (GRADUAÇÃO E PÓS-GRADUAÇÃO)",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10609",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8531700",
        "denominacao": "EDUCAÇÃO SUPERIOR - GRADUAÇÃO",
        "descricao": "SERVIÇOS DE EDUÇAÇÃO - ENSINO SUPERIOR (GRADUAÇÃO)",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10608",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8520100",
        "denominacao": "ENSINO MEDIO",
        "descricao": "SERVIÇOS DE EDUCAÇÃO - ENSINO MÉDIO",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10607",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8513900",
        "denominacao": "ENSINO FUNDAMENTAL",
        "descricao": "SERVIÇOS DE EDUCAÇÃO - ENSINO FUNDAMENTAL",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10606",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8512100",
        "denominacao": "EDUCACAO INFANTIL - PRE-ESCOLA",
        "descricao": "SERVIÇOS DE EDUCAÇÃO INFANTIL - PRÉ-ESCOLA",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10605",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8511200",
        "denominacao": "EDUCACAO INFANTIL - CRECHE",
        "descricao": "SERVIÇOS DE EDUCAÇÃO INFANTIL - CRECHES",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10604",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8541400",
        "denominacao": "EDUCAÇÃO PROFISSIONAL DE NÍVEL TÉCNICO",
        "descricao": "SERVIÇOS DE ENSINO TÉCNICO INDUSTRIAL OU COMERCIAL",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2663",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8532500",
        "denominacao": "EDUCAÇÃO SUPERIOR - GRADUAÇÃO E PÓS-GRADUAÇÃO",
        "descricao": "SERVIÇOS DE EDUÇAÇÃO - ENSINO SUPERIOR (GRADUAÇÃO E PÓS-GRADUAÇÃO)",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2661",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8512100",
        "denominacao": "EDUCAÇÃO INFANTIL - PRÉ-ESCOLA",
        "descricao": "SERVIÇOS DE EDUCAÇÃO INFANTIL - PRÉ-ESCOLA",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2657",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8513900",
        "denominacao": "ENSINO FUNDAMENTAL",
        "descricao": "SERVIÇOS DE EDUCAÇÃO - ENSINO FUNDAMENTAL",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2658",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8531700",
        "denominacao": "EDUCAÇÃO SUPERIOR - GRADUAÇÃO",
        "descricao": "SERVIÇOS DE EDUÇAÇÃO - ENSINO SUPERIOR (GRADUAÇÃO)",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2660",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8520100",
        "denominacao": "ENSINO MÉDIO",
        "descricao": "SERVIÇOS DE EDUCAÇÃO - ENSINO MÉDIO",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2659",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8593700",
        "denominacao": "ENSINO DE IDIOMAS",
        "descricao": "SERVIÇOS DE CURSOS DE LÍNGUA ESTRANGEIRA",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "2667",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8591100",
        "denominacao": "ENSINO DE ESPORTES",
        "descricao": "SERVIÇOS DE INSTRUÇÃO DE ESPORTES",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "2674",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8599601",
        "denominacao": "FORMACAO DE CONDUTORES",
        "descricao": "SERVIÇOS DE INSTRUÇÃO DE CODUTORES (AUTO-ESCOLA)",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "10613",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8599699",
        "denominacao": "OUTRAS ATIVIDADES DE ENSINO NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EDUCAÇÃO RELIGIOSA",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "10534",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8599604",
        "denominacao": "TREINAMENTO EM DESENVOLVIMENTO PROFISSIONAL E GERENCIAL",
        "descricao": "SERVIÇO DE TREINAMENTO DE RECURSOS HUMANOS",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "10533",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8591100",
        "denominacao": "ENSINO DE ESPORTES",
        "descricao": "SERVIÇO DE INSTRUÇÃO DE GINÁSTICA",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "10532",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8591100",
        "denominacao": "ENSINO DE ESPORTES",
        "descricao": "SERVIÇOS DE INSTRUÇÃO DE ESPORTES",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "10531",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8599605",
        "denominacao": "CURSOS PREPARATORIOS PARA CONCURSOS",
        "descricao": "SERVIÇOS DE ENSINO PREPARATÓRIO PARA CONCURSOS",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "10530",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8592902",
        "denominacao": "ENSINO DE ARTES CENICAS, EXCETO DANCA",
        "descricao": "SERVIÇOS DE ENSINO LIGADOS ÀS ARTES E CULTURA",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "10529",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8599604",
        "denominacao": "TREINAMENTO EM DESENVOLVIMENTO PROFISSIONAL E GERENCIAL",
        "descricao": "SERVIÇOS DE TREINAMENTO EM DESENVOLVIMENTO PROFISSIONAL E GERENCIAL",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "10528",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8591100",
        "denominacao": "ENSINO DE ESPORTES",
        "descricao": "SERVIÇOS DE INSTRUÇÃO DE MERGULHO",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "10258",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8592902",
        "denominacao": "ENSINO DE ARTES CENICAS, EXCETO DANCA",
        "descricao": "SERVIÇOS DE ENSINO ARTÍSTICO",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11453",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8599603",
        "denominacao": "TREINAMENTO EM INFORMATICA",
        "descricao": "SERVIÇOS DE ENSINO EM INFORMÁTICA",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "10616",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8593700",
        "denominacao": "ENSINO DE IDIOMAS",
        "descricao": "SERVIÇOS DE CURSOS DE LÍNGUA ESTRANGEIRA",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "10615",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8599602",
        "denominacao": "CURSOS DE PILOTAGEM",
        "descricao": "SERVIÇOS DE INSTRUÇÃO DE PILOTAGEM",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "10614",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7450001",
        "denominacao": "SELEÇÃO E AGENCIAMENTO DE MÃO-DE-OBRA",
        "descricao": "SERVIÇO DE TREINAMENTO DE RECURSOS HUMANOS",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "2676",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9313100",
        "denominacao": "ATIVIDADES DE CONDICIONAMENTO FÍSICO",
        "descricao": "SERVIÇO DE INSTRUÇÃO DE GINÁSTICA",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "2675",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9329801",
        "denominacao": "DISCOTECAS, DANCETERIAS, SALÕES DE DANÇA E SIMILARES",
        "descricao": "SERVIÇOS DE ACADEMIA DE DANÇA",
        "item": "8",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "2673",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8599603",
        "denominacao": "TREINAMENTO EM INFORMÁTICA",
        "descricao": "SERVIÇOS DE ENSINO EM INFORMÁTICA",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "2668",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8099306",
        "denominacao": "CURSOS LIGADOS ÀS ARTES E CULTURA.",
        "descricao": "SERVIÇOS DE ENSINO LIGADOS ÀS ARTES E CULTURA",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "2670",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8599605",
        "denominacao": "CURSOS PREPARATÓRIOS PARA CONCURSOS",
        "descricao": "SERVIÇOS DE ENSINO PREPARATÓRIO PARA CONCURSOS",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "2671",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8099306",
        "denominacao": "CURSOS LIGADOS ÀS ARTES E CULTURA.",
        "descricao": "SERVIÇOS DE ENSINO ARTÍSTICO",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "2672",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8599604",
        "denominacao": "TREINAMENTO EM DESENVOLVIMENTO PROFISSIONAL E GERENCIAL",
        "descricao": "SERVIÇOS DE TREINAMENTO EM DESENVOLVIMENTO PROFISSIONAL E GERENCIAL",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "2669",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5510801",
        "denominacao": "HOTÉIS",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM HOTEL",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "2678",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5590603",
        "denominacao": "PENSÕES",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM PENSÃO",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "2684",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5590699",
        "denominacao": "OUTROS ALOJAMENTOS NÃO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM RESIDÊNCIAS",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "2687",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5510803",
        "denominacao": "MOTEIS",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM MOTEL",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "10537",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5590602",
        "denominacao": "CAMPINGS",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM CAMPING",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "10539",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5590699",
        "denominacao": "OUTROS ALOJAMENTOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM FLAT",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "10541",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5590699",
        "denominacao": "OUTROS ALOJAMENTOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM RESIDÊNCIAS",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "11454",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5510802",
        "denominacao": "APART-HOTEIS",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM APART-HOTEL",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "11504",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5590699",
        "denominacao": "OUTROS ALOJAMENTOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM RESIDENCE-SERVICE E SUÍTE-SERVECE",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "10542",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5590603",
        "denominacao": "PENSOES (ALOJAMENTO)",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM PENSÃO",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "10540",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5590601",
        "denominacao": "ALBERGUES, EXCETO ASSISTENCIAIS",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM ALBERGUES",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "10538",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5590699",
        "denominacao": "OUTROS ALOJAMENTOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE HOSPEDAGEM MARÍTIMA",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "10536",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5510801",
        "denominacao": "HOTEIS",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM HOTEL",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "10535",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5510802",
        "denominacao": "APART-HOTÉIS",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM APART-HOTEL",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "2680",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6330400",
        "denominacao": "ATIVIDADES DE AGÊNCIAS DE VIAGENS E ORGANIZADORES DE VIAGEM",
        "descricao": "SERVIÇO DE INTERMEDIAÇÃO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "2691",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7990200",
        "denominacao": "SERVICOS DE RESERVAS E OUTROS SERVICOS DE TURISMO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇO DE INTERMEDIAÇÃO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "10551",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7990200",
        "denominacao": "SERVICOS DE RESERVAS E OUTROS SERVICOS DE TURISMO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇO DE PROMOÇÃO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "10550",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7911200",
        "denominacao": "AGENCIAS DE VIAGENS",
        "descricao": "SERVIÇO DE PROMOÇÃO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "10549",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7912100",
        "denominacao": "OPERADORES TURISTICOS",
        "descricao": "SERVIÇO DE PROMOÇÃO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "10548",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7912100",
        "denominacao": "OPERADORES TURISTICOS",
        "descricao": "SERVIÇO DE ORGANIZAÇÃO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "10547",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7911200",
        "denominacao": "AGENCIAS DE VIAGENS",
        "descricao": "SERVIÇO DE ORGANIZAÇÃO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "10546",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7990200",
        "denominacao": "SERVICOS DE RESERVAS E OUTROS SERVICOS DE TURISMO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇO DE ORGANIZAÇÃO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "10545",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7911200",
        "denominacao": "AGENCIAS DE VIAGENS",
        "descricao": "SERVIÇO DE AGENCIAMENTO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "10544",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7912100",
        "denominacao": "OPERADORES TURISTICOS",
        "descricao": "SERVIÇO DE AGENCIAMENTO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "10543",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7911200",
        "denominacao": "AGENCIAS DE VIAGENS",
        "descricao": "SERVIÇO DE EXECUÇÃO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "10555",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7912100",
        "denominacao": "OPERADORES TURISTICOS",
        "descricao": "SERVIÇO DE EXECUÇÃO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "10554",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7912100",
        "denominacao": "OPERADORES TURISTICOS",
        "descricao": "SERVIÇO DE INTERMEDIAÇÃO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "10553",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7911200",
        "denominacao": "AGENCIAS DE VIAGENS",
        "descricao": "SERVIÇO DE INTERMEDIAÇÃO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "10552",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6330400",
        "denominacao": "ATIVIDADES DE AGÊNCIAS DE VIAGENS E ORGANIZADORES DE VIAGEM",
        "descricao": "SERVIÇO DE EXECUÇÃO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "2692",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7911200",
        "denominacao": "AGENCIAS DE VIAGENS",
        "descricao": "SERVIÇOS DE GUIAS DE TURISMO",
        "item": "9",
        "subitem": "3",
        "aliquota": "2,5",
        "idcnae": "10556",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7912100",
        "denominacao": "OPERADORES TURISTICOS",
        "descricao": "SERVIÇOS DE GUIAS DE TURISMO",
        "item": "9",
        "subitem": "3",
        "aliquota": "2,5",
        "idcnae": "10557",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6613400",
        "denominacao": "ADMINISTRAÇÃO DE CARTÕES DE CRÉDITO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE CARTÕES DE CRÉDITOS",
        "item": "10",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "2694",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6622300",
        "denominacao": "CORRETORES E AGENTES DE SEGUROS, DE PLANOS DE PREVIDENCIA COMPLEMENTAR E DE SAUDE",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE PLANOS DE SAÚDE",
        "item": "10",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "10560",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6629100",
        "denominacao": "ATIVIDADES AUXILIARES DOS SEGUROS, DA PREVIDÊNCIA COMPLEMENTAR E DOS PLANOS DE SAÚDE NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE SEGUROS",
        "item": "10",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "2698",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6622300",
        "denominacao": "CORRETORES E AGENTES DE SEGUROS, DE PLANOS DE PREVIDENCIA COMPLEMENTAR E DE SAUDE",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE PREVIDÊNCIA PRIVADA",
        "item": "10",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "10415",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6622300",
        "denominacao": "CORRETORES E AGENTES DE SEGUROS, DE PLANOS DE PREVIDENCIA COMPLEMENTAR E DE SAUDE",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE SEGUROS",
        "item": "10",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "10416",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6612603",
        "denominacao": "CORRETORAS DE CAMBIO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE CÂMBIO",
        "item": "10",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "10559",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6613400",
        "denominacao": "ADMINISTRACAO DE CARTOES DE CREDITO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE CARTÕES DE CRÉDITOS",
        "item": "10",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "10558",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6470102",
        "denominacao": "FUNDOS DE INVESTIMENTO PREVIDENCIARIOS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE PREVIDÊNCIA PRIVADA",
        "item": "10",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "10414",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6612604",
        "denominacao": "CORRETORAS DE CONTRATOS DE MERCADORIAS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE CONTRATOS",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1687",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6611803",
        "denominacao": "BOLSA DE MERCADORIAS E FUTUROS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTEMEDIAÇÃO",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1583",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6619399",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS SERVICOS FINANCEIROS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE INTERMEDIAÇÃO DE INVESTIMENTOS",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11120",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6612605",
        "denominacao": "AGENTES DE INVESTIMENTOS EM APLICACOES FINANCEIRAS",
        "descricao": "SERVIÇOS DE INTERMEDIAÇÃO DE INVESTIMENTOS",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11119",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6470101",
        "denominacao": "FUNDOS DE INVESTIMENTO, EXCETO PREVIDENCIARIOS E IMOBILIARIOS",
        "descricao": "SERVIÇOS DE INTERMEDIAÇÃO DE INVESTIMENTOS",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11118",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6470103",
        "denominacao": "FUNDOS DE INVESTIMENTO IMOBILIARIOS",
        "descricao": "SERVIÇOS DE INTERMEDIAÇÃO DE INVESTIMENTOS",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11117",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6622300",
        "denominacao": "CORRETORES E AGENTES DE SEGUROS, DE PLANOS DE PREVIDENCIA COMPLEMENTAR E DE SAUDE",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTEMEDIAÇÃO",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11116",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9609202",
        "denominacao": "AGENCIAS MATRIMONIAIS",
        "descricao": "SERVIÇOS DE AGENCIAMENTOS MATRIMONIAIS",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11115",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6612604",
        "denominacao": "CORRETORAS DE CONTRATOS DE MERCADORIAS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE CONTRATOS",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11114",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6612601",
        "denominacao": "CORRETORAS DE TITULOS E VALORES MOBILIARIOS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE VALORES MOBILIÁRIOS",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "11113",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6612601",
        "denominacao": "CORRETORAS DE TITULOS E VALORES MOBILIARIOS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE TÍTULOS",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "10417",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6593502",
        "denominacao": "GESTÃO DE DIREITOS AUTORAIS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE PROPRIEDADE ARTÍSTICA OU LITERÁRIA",
        "item": "10",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "1585",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7490105",
        "denominacao": "AGENCIAMENTO DE PROFISSIONAIS PARA ATIVIDADES ESPORTIVAS, CULTURAIS E ARTISTICAS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE PROPRIEDADE ARTÍSTICA OU LITERÁRIA",
        "item": "10",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "11121",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7490104",
        "denominacao": "ATIVIDADES DE INTERMEDIACAO E AGENCIAMENTO DE SERVICOS E NEGOCIOS EM GERAL, EXCETO IMOBILIARIOS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO DE INGRESSOS PARA SALAS DE ESPETÁCULOS",
        "item": "10",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "11124",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7490104",
        "denominacao": "ATIVIDADES DE INTERMEDIACAO E AGENCIAMENTO DE SERVICOS E NEGOCIOS EM GERAL, EXCETO IMOBILIARIOS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE DIREITOS DE PROPRIEDADE INDUSTRIAL",
        "item": "10",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "11123",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7490104",
        "denominacao": "ATIVIDADES DE INTERMEDIACAO E AGENCIAMENTO DE SERVICOS E NEGOCIOS EM GERAL, EXCETO IMOBILIARIOS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE PROPRIEDADE ARTÍSTICA OU LITERÁRIA",
        "item": "10",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "11122",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7740300",
        "denominacao": "GESTÃO DE ATIVOS INTANGÍVEIS NÃO-FINANCEIROS",
        "descricao": "AGENCIAMENTO, CORRETAGEM OI INTERMEDIAÇÃO DE CONTRATOS DE FRANQUIA (FRANCHISING)",
        "item": "10",
        "subitem": "4",
        "aliquota": "3",
        "idcnae": "1590",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6619399",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS SERVICOS FINANCEIROS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE CONTRATOS DE FATURIZAÇÃO (FACTORING)",
        "item": "10",
        "subitem": "4",
        "aliquota": "3",
        "idcnae": "11126",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6619399",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS SERVICOS FINANCEIROS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "AGENCIAMENTO, CORRETAGEM OI INTERMEDIAÇÃO DE CONTRATOS DE FRANQUIA (FRANCHISING)",
        "item": "10",
        "subitem": "4",
        "aliquota": "3",
        "idcnae": "11127",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6619399",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS SERVICOS FINANCEIROS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE CONTRATOS DE ARRENDAMENTO MERCANTIL (LEASING)",
        "item": "10",
        "subitem": "4",
        "aliquota": "3",
        "idcnae": "11125",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6821802",
        "denominacao": "CORRETAGEM NO ALUGUEL DE IMOVEIS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE BENS MÓVEIS OU IMÓVEIS",
        "item": "10",
        "subitem": "5",
        "aliquota": "2",
        "idcnae": "11128",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6821801",
        "denominacao": "CORRETAGEM NA COMPRA E VENDA E AVALIACAO DE IMOVEIS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE BENS MÓVEIS OU IMÓVEIS",
        "item": "10",
        "subitem": "5",
        "aliquota": "2",
        "idcnae": "11129",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9240100",
        "denominacao": "ATIVIDADES DE AGÊNCIAS DE NOTÍCIAS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO DE NOTÍCIAS",
        "item": "10",
        "subitem": "7",
        "aliquota": "3",
        "idcnae": "1593",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6391700",
        "denominacao": "AGENCIAS DE NOTICIAS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO DE NOTÍCIAS",
        "item": "10",
        "subitem": "7",
        "aliquota": "3",
        "idcnae": "11131",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7311400",
        "denominacao": "AGÊNCIAS DE PUBLICIDADE",
        "descricao": "SERVIÇOS DE AGENCIAMENTO DE PUBLICIDADE  OU PROPAGANDA",
        "item": "10",
        "subitem": "8",
        "aliquota": "2,5",
        "idcnae": "1594",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7312200",
        "denominacao": "AGENCIAMENTO DE ESPAÇOS PARA PUBLICIDADE, EXCETO EM VEÍCULOS DE COMUNICAÇÃO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO DE ESPAÇOS PUBLICITÁRIOS",
        "item": "10",
        "subitem": "8",
        "aliquota": "2,5",
        "idcnae": "1595",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7311400",
        "denominacao": "AGENCIAS DE PUBLICIDADE",
        "descricao": "SERVIÇOS DE AGENCIAMENTO DE PUBLICIDADE  OU PROPAGANDA",
        "item": "10",
        "subitem": "8",
        "aliquota": "2,5",
        "idcnae": "11132",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7312200",
        "denominacao": "AGENCIAMENTO DE ESPACOS PARA PUBLICIDADE, EXCETO EM VEICULOS DE COMUNICACAO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO DE ESPAÇOS PUBLICITÁRIOS",
        "item": "10",
        "subitem": "8",
        "aliquota": "2,5",
        "idcnae": "11133",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4616800",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE TÊXTEIS, VESTUÁRIO, CALÇADOS E ARTIGOS DE VIAGEM",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "1604",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4619200",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE MERCADORIAS EM GERAL NÃO ESPECIALIZADO",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "1607",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6619303",
        "denominacao": "REPRESENTAÇÕES DE BANCOS ESTRANGEIROS",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "1608",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4614100",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE MAQUINAS, EQUIPAMENTOS, EMBARCACOES E AERONAVES",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "11142",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4615000",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE ELETRODOMESTICOS, MOVEIS E ARTIGOS DE USO DOMESTICO",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "11141",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4616800",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE TEXTEIS, VESTUARIO, CALCADOS E ARTIGOS DE VIAGEM",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "11140",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4617600",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE PRODUTOS ALIMENTICIOS, BEBIDAS E FUMO",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "11139",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4618401",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE MEDICAMENTOS, COSMETICOS E PRODUTOS DE PERFUMARIA",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "11138",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4618402",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE INSTRUMENTOS E MATERIAIS ODONTO-MEDICO-HOSPITALARES",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "11137",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4618403",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE JORNAIS, REVISTAS E OUTRAS PUBLICACOES",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "11136",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4618499",
        "denominacao": "OUTROS REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO ESPECIALIZADO EM PRODUTOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "11135",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4619200",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE MERCADORIAS EM GERAL NAO ESPECIALIZADO",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "11134",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4611700",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE MATERIAS-PRIMAS AGRICOLAS E ANIMAIS VIVOS",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "11148",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4512901",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "11147",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4530706",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE PECAS E ACESSORIOS NOVOS E USADOS PARA VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "11146",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4542101",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE MOTOCICLETAS E MOTONETAS, PECAS E ACESSORIOS",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "11145",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4612500",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE COMBUSTIVEIS, MINERAIS, PRODUTOS SIDERURGICOS E QUIMICOS",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "11144",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4613300",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMERCIO DE MADEIRA, MATERIAL DE CONSTRUCAO E FERRAGENS",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "11143",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4615000",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE ELETRODOMÉSTICOS, MÓVEIS E ARTIGOS DE USO DOMÉSTICO",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "1603",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4613300",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE MADEIRA, MATERIAL DE CONSTRUÇÃO E FERRAGENS",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "1601",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4612500",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE COMBUSTÍVEIS, MINERAIS, PRODUTOS SIDERÚRGICOS E QUÍMICOS",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "1600",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4611700",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE MATÉRIAS-PRIMAS AGRÍCOLAS E ANIMAIS VIVOS",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "1599",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4530706",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE PEÇAS E ACESSÓRIOS NOVOS E USADOS PARA VEÍCULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "1597",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5010507",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE VEÍCULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "1596",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4617600",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE PRODUTOS ALIMENTÍCIOS, BEBIDAS E FUMO",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "1605",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4619200",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE MERCADORIAS EM GERAL NÃO ESPECIALIZADO",
        "descricao": "SERVIÇOS DE DISTRIBUIÇÃO DE BENS DE TERCEIROS",
        "item": "10",
        "subitem": "10",
        "aliquota": "3",
        "idcnae": "1611",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8299799",
        "denominacao": "OUTRAS ATIVIDADES DE SERVICOS PRESTADOS PRINCIPALMENTE AS EMPRESAS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE DISTRIBUIÇÃO DE BENS DE TERCEIROS",
        "item": "10",
        "subitem": "10",
        "aliquota": "3",
        "idcnae": "11151",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5913800",
        "denominacao": "DISTRIBUICAO CINEMATOGRAFICA, DE VIDEO E DE PROGRAMAS DE TELEVISAO",
        "descricao": "SERVIÇOS DE DISTRIBUIÇÃO DE FILMES E DE VÍDEOS",
        "item": "10",
        "subitem": "10",
        "aliquota": "3",
        "idcnae": "11150",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6612602",
        "denominacao": "DISTRIBUIDORAS DE TITULOS E VALORES MOBILIARIOS",
        "descricao": "SERVIÇOS DE DISTRIBUIÇÃO DE TÍTULOS E VALORES MOBILIÁRIOS",
        "item": "10",
        "subitem": "10",
        "aliquota": "3",
        "idcnae": "11149",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5223100",
        "denominacao": "ESTACIONAMENTO DE VEÍCULOS",
        "descricao": "SERVIÇOS DE GUARDA E ESTACIONAMENTO DE VEÍCULOS TERRESTRES AUTOMOTORES",
        "item": "11",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1615",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5240199",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AEREOS, EXCETO OPERACAO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE GUARDA E ESTACIONAMENTO DE AERONAVES",
        "item": "11",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11152",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5223100",
        "denominacao": "ESTACIONAMENTO DE VEICULOS",
        "descricao": "SERVIÇOS DE GUARDA E ESTACIONAMENTO DE EMBARCAÇÕES",
        "item": "11",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11153",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5223100",
        "denominacao": "ESTACIONAMENTO DE VEICULOS",
        "descricao": "SERVIÇOS DE GUARDA E ESTACIONAMENTO DE VEÍCULOS TERRESTRES AUTOMOTORES",
        "item": "11",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11154",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7460802",
        "denominacao": "ATIVIDADES DE VIGILÂNCIA E SEGURANÇA PRIVADA",
        "descricao": "SERVIÇOS DE VIGILÂNCIA, SEGURANÇA OU MONITORAMENTO DE BENS E PESSOAS",
        "item": "11",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "1616",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8011101",
        "denominacao": "ATIVIDADES DE VIGILANCIA E SEGURANCA PRIVADA",
        "descricao": "SERVIÇOS DE VIGILÂNCIA, SEGURANÇA OU MONITORAMENTO DE BENS E PESSOAS",
        "item": "11",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "11155",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8011101",
        "denominacao": "ATIVIDADES DE VIGILANCIA E SEGURANCA PRIVADA",
        "descricao": "SERVIÇOS DE ESCOLTA",
        "item": "11",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11481",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5212500",
        "denominacao": "CARGA E DESCARGA",
        "descricao": "SERVIÇOS DE CARGA E DESCARGA ",
        "item": "11",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1622",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5211702",
        "denominacao": "GUARDA-MOVEIS",
        "descricao": "SERVIÇOS DE GUARDA DE BENS",
        "item": "11",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "11156",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5211701",
        "denominacao": "ARMAZENS GERAIS - EMISSAO DE WARRANT",
        "descricao": "SERVIÇOS DE ARMAZENAMENTO ",
        "item": "11",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "11158",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5211799",
        "denominacao": "DEPOSITOS DE MERCADORIAS PARA TERCEIROS, EXCETO ARMAZENS GERAIS E GUARDA-MOVEIS",
        "descricao": "SERVIÇOS DE DEPÓSITOS DE BENS DE QUALQUER NATUREZA",
        "item": "11",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "11160",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5212500",
        "denominacao": "CARGA E DESCARGA",
        "descricao": "SERVIÇOS DE CARGA E DESCARGA ",
        "item": "11",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "11161",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5211799",
        "denominacao": "DEPOSITOS DE MERCADORIAS PARA TERCEIROS, EXCETO ARMAZENS GERAIS E GUARDA-MOVEIS",
        "descricao": "SERVIÇOS DE ARMAZENAMENTO ",
        "item": "11",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "11159",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5229099",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES TERRESTRES NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE GUARDA DE BENS",
        "item": "11",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "11157",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001901",
        "denominacao": "PRODUÇÃO TEATRAL",
        "descricao": "SERVIÇOS DE ESPETÁCULOS TEATRAIS",
        "item": "12",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1623",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001999",
        "denominacao": "ARTES CENICAS, ESPETACULOS E ATIVIDADES COMPLEMENTARES NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ESPETÁCULOS TEATRAIS",
        "item": "12",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11162",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001901",
        "denominacao": "PRODUCAO TEATRAL",
        "descricao": "SERVIÇOS DE ESPETÁCULOS TEATRAIS",
        "item": "12",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11163",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5914600",
        "denominacao": "ATIVIDADES DE EXIBICAO CINEMATOGRAFICA",
        "descricao": "SERVIÇOS DE EXIBIÇÃO CINEMATOGRÁFICA",
        "item": "12",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "11509",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5914600",
        "denominacao": "ATIVIDADES DE EXIBICAO CINEMATOGRAFICA",
        "descricao": "SERVIÇOS DE EXIBIÇÕES CINEMATOGRÁFICAS",
        "item": "12",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "11164",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001904",
        "denominacao": "PRODUCAO DE ESPETACULOS CIRCENSES, DE MARIONETES E SIMILARES",
        "descricao": "SERVIÇOS DE ESPETÁCULOS CIRCENSES",
        "item": "12",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11165",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6022501",
        "denominacao": "PROGRAMADORAS",
        "descricao": "SERVIÇOS DE PROGRAMA DE AUDITÓRIO",
        "item": "12",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "11166",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6021700",
        "denominacao": "ATIVIDADES DE TELEVISAO ABERTA",
        "descricao": "SERVIÇOS DE PROGRAMA DE AUDITÓRIO",
        "item": "12",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "11167",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9103100",
        "denominacao": "ATIVIDADES DE JARDINS BOTANICOS, ZOOLOGICOS, PARQUES NACIONAIS, RESERVAS ECOLOGICAS E AREAS DE PROTECAO AMBIENTAL",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE JARDINS BOTÂNICOS, ZOOLÓGICOS, PARQUES NACIONAIS E RESERVAS ECOLÓGICAS",
        "item": "12",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11043",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9321200",
        "denominacao": "PARQUES DE DIVERSAO E PARQUES TEMATICOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE CENTROS DE LAZER",
        "item": "12",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11044",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9321200",
        "denominacao": "PARQUES DE DIVERSAO E PARQUES TEMATICOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE PARQUES DE DIVERSÕES",
        "item": "12",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11045",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9329801",
        "denominacao": "DISCOTECAS, DANCETERIAS, SALOES DE DANCA E SIMILARES",
        "descricao": "SERVIÇOS DE BOATE",
        "item": "12",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "11046",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9329801",
        "denominacao": "DISCOTECAS, DANCETERIAS, SALOES DE DANCA E SIMILARES",
        "descricao": "SERVIÇOS DE TAXI-DANCING",
        "item": "12",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "11171",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9329801",
        "denominacao": "DISCOTECAS, DANCETERIAS, SALOES DE DANCA E SIMILARES",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE SALÕES DE BAILES",
        "item": "12",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "11172",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001903",
        "denominacao": "PRODUCAO DE ESPETACULOS DE DANCA",
        "descricao": "SERVIÇOS DE BALLET, DANÇAS, DESFILES E SHOWS",
        "item": "12",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "11173",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001902",
        "denominacao": "PRODUCAO MUSICAL",
        "descricao": "SERVIÇOS DE ÓPERA, CONCERTOS, RECITAIS E FESTIVAIS",
        "item": "12",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "11174",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8230001",
        "denominacao": "SERVIÇOS DE ORGANIZAÇÃO DE FEIRAS, CONGRESSOS, EXPOSIÇÕES E FESTAS",
        "descricao": "SERVIÇOS DE ORGANIZAÇÃO DE FEIRAS, EVENTOS E CONGRESSOS",
        "item": "12",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "1636",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8230001",
        "denominacao": "SERVICOS DE ORGANIZACAO DE FEIRAS, CONGRESSOS, EXPOSICOES E FESTAS",
        "descricao": "SERVIÇOS DE EXPOSIÇÃO ",
        "item": "12",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "11057",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8230001",
        "denominacao": "SERVICOS DE ORGANIZACAO DE FEIRAS, CONGRESSOS, EXPOSICOES E FESTAS",
        "descricao": "SERVIÇOS DE ORGANIZAÇÃO DE FEIRAS, EVENTOS E CONGRESSOS",
        "item": "12",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "11056",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9200301",
        "denominacao": "CASAS DE BINGO",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE DIVERSÕES ELETRÔNICAS OU NÃO",
        "item": "12",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "11058",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9200399",
        "denominacao": "EXPLORACAO DE JOGOS DE AZAR E APOSTAS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE JOGOS E CARTEADOS",
        "item": "12",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "11059",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9329802",
        "denominacao": "EXPLORACAO DE BOLICHES",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE JOGOS DE BOLICHE E BOCHA",
        "item": "12",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "11060",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9329803",
        "denominacao": "EXPLORACAO DE JOGOS DE SINUCA, BILHAR E SIMILARES",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE JOGOS DE BILHARES",
        "item": "12",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "11062",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9329804",
        "denominacao": "EXPLORACAO DE JOGOS ELETRONICOS RECREATIVOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE JOGOS ELETRÔNICOS",
        "item": "12",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "11061",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001905",
        "denominacao": "PRODUCAO DE ESPETACULOS DE RODEIOS, VAQUEJADAS E SIMILARES",
        "descricao": "SERVIÇOS DE ESPETÁCULOS DE RODEIOS E VAQUEJADAS",
        "item": "12",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "11063",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9200302",
        "denominacao": "EXPLORACAO DE APOSTAS EM CORRIDAS DE CAVALOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE CORRIDAS E COMPETIÇÕES DE ANIMAIS",
        "item": "12",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "11064",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9200302",
        "denominacao": "EXPLORACAO DE APOSTAS EM CORRIDAS DE CAVALOS",
        "descricao": "SERVIÇOS DE COMPETIÇÕES ESPORTIVAS OU DE DESTREZA FÍSICA OU INTELECTUAL",
        "item": "12",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "11480",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9200301",
        "denominacao": "CASAS DE BINGO",
        "descricao": "SERVIÇOS DE COMPETIÇÕES ESPORTIVAS OU DE DESTREZA FÍSICA OU INTELECTUAL",
        "item": "12",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "11066",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9319101",
        "denominacao": "PRODUCAO E PROMOCAO DE EVENTOS ESPORTIVOS",
        "descricao": "SERVIÇOS DE COMPETIÇÕES ESPORTIVAS OU DE DESTREZA FÍSICA OU INTELECTUAL",
        "item": "12",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "11067",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9200399",
        "denominacao": "EXPLORACAO DE JOGOS DE AZAR E APOSTAS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COMPETIÇÕES ESPORTIVAS OU DE DESTREZA FÍSICA OU INTELECTUAL",
        "item": "12",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "11065",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001999",
        "denominacao": "ARTES CÊNICAS, ESPETÁCULOS E ATIVIDADES COMPLEMENTARES NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EXECUÇÃO DE MÚSICA",
        "item": "12",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "1647",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001906",
        "denominacao": "ATIVIDADES DE SONORIZACAO E DE ILUMINACAO",
        "descricao": "SERVIÇOS DE EXECUÇÃO DE MÚSICA",
        "item": "12",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "11070",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001902",
        "denominacao": "PRODUCAO MUSICAL",
        "descricao": "SERVIÇOS DE EXECUÇÃO DE MÚSICA",
        "item": "12",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "11069",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001999",
        "denominacao": "ARTES CENICAS, ESPETACULOS E ATIVIDADES COMPLEMENTARES NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EXECUÇÃO DE MÚSICA",
        "item": "12",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "11068",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001999",
        "denominacao": "ARTES CÊNICAS, ESPETÁCULOS E ATIVIDADES COMPLEMENTARES NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE EVENTOS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "1648",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001999",
        "denominacao": "ARTES CÊNICAS, ESPETÁCULOS E ATIVIDADES COMPLEMENTARES NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE ÓPERAS, CONCERTOS E RECITAIS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "1652",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001905",
        "denominacao": "PRODUCAO DE ESPETACULOS DE RODEIOS, VAQUEJADAS E SIMILARES",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE EVENTOS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "11071",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001903",
        "denominacao": "PRODUCAO DE ESPETACULOS DE DANCA",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE EVENTOS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "11073",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001901",
        "denominacao": "PRODUCAO TEATRAL",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE EVENTOS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "11075",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9329801",
        "denominacao": "DISCOTECAS, DANCETERIAS, SALOES DE DANCA E SIMILARES",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE BAILES",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "11082",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001901",
        "denominacao": "PRODUCAO TEATRAL",
        "descricao": "SERVIÇOS DE PRODUÇÕES TEATRAIS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "11081",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001902",
        "denominacao": "PRODUCAO MUSICAL",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE ÓPERAS, CONCERTOS E RECITAIS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "11080",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001999",
        "denominacao": "ARTES CENICAS, ESPETACULOS E ATIVIDADES COMPLEMENTARES NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE ESPETÁCULOS, DESFILES E ENTREVISTAS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "11079",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001902",
        "denominacao": "PRODUCAO MUSICAL",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE SHOWS E FESTIVAIS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "11078",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001903",
        "denominacao": "PRODUCAO DE ESPETACULOS DE DANCA",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE DANÇAS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "11077",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9003500",
        "denominacao": "GESTAO DE ESPACOS PARA ARTES CENICAS, ESPETACULOS E OUTRAS ATIVIDADES ARTISTICAS",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE EVENTOS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "11076",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001902",
        "denominacao": "PRODUCAO MUSICAL",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE EVENTOS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "11074",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001904",
        "denominacao": "PRODUCAO DE ESPETACULOS CIRCENSES, DE MARIONETES E SIMILARES",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE EVENTOS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "11072",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001999",
        "denominacao": "ARTES CÊNICAS, ESPETÁCULOS E ATIVIDADES COMPLEMENTARES NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE SHOWS E FESTIVAIS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "1650",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001999",
        "denominacao": "ARTES CÊNICAS, ESPETÁCULOS E ATIVIDADES COMPLEMENTARES NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE ESPETÁCULOS, DESFILES E ENTREVISTAS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "1651",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001906",
        "denominacao": "ATIVIDADES DE SONORIZAÇÃO E DE ILUMINAÇÃO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE MÚSICA",
        "item": "12",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "1655",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001906",
        "denominacao": "ATIVIDADES DE SONORIZACAO E DE ILUMINACAO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE MÚSICA",
        "item": "12",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "11083",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001903",
        "denominacao": "PRODUCAO DE ESPETACULOS DE DANCA",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE DESFILE DE BLOCOS CARNAVALESCOS OU FOLCLORÍCOS E TRIOS ELÉTRICOS",
        "item": "12",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "11084",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001999",
        "denominacao": "ARTES CENICAS, ESPETACULOS E ATIVIDADES COMPLEMENTARES NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE DESFILE DE BLOCOS CARNAVALESCOS OU FOLCLORÍCOS E TRIOS ELÉTRICOS",
        "item": "12",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "11085",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9231299",
        "denominacao": "OUTROS SERVIÇOS ESPECIALIZADOS LIGADOS ÀS ATIVIDADES ARTÍSTICAS",
        "descricao": "SEVIÇOS DE EXIBIÇÃO DE ENTREVISTAS, MUSICAIS, ESPETÁCULOS, SHOWS, CONCERTOS, DESFILES, ÓPERAS, COMPETIÇÕES ESPORTIVAS, DEDESTREZA INTELECTUAL",
        "item": "12",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "1658",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5911199",
        "denominacao": "ATIVIDADES DE PRODUCAO CINEMATOGRAFICA, DE VIDEOS E DE PROGRAMAS DE TELEVISAO NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SEVIÇOS DE EXIBIÇÃO DE ENTREVISTAS, MUSICAIS, ESPETÁCULOS, SHOWS, CONCERTOS, DESFILES, ÓPERAS, COMPETIÇÕES ESPORTIVAS, DEDESTREZA INTELECTUAL",
        "item": "12",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "11087",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5914600",
        "denominacao": "ATIVIDADES DE EXIBICAO CINEMATOGRAFICA",
        "descricao": "SERVIÇOS DE EXIBIÇÃO DE FILMES",
        "item": "12",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "11086",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9231299",
        "denominacao": "OUTROS SERVIÇOS ESPECIALIZADOS LIGADOS ÀS ATIVIDADES ARTÍSTICAS",
        "descricao": "SERVIÇOS DE RECREAÇÃO E ANIMAÇÃO",
        "item": "12",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1659",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9329899",
        "denominacao": "OUTRAS ATIVIDADES DE RECREACAO E LAZER NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE RECREAÇÃO E ANIMAÇÃO",
        "item": "12",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "11088",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9211802",
        "denominacao": "ATIVIDADES DE PRODUÇÃO DE FILMES E FITAS DE VÍDEO - EXCETO ESTÚDIOS CINEMATOGRÁFICOS",
        "descricao": "SERVIÇOS DE FONOGRAFIA OU GRAVAÇÃO DE SONS",
        "item": "13",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1660",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5920100",
        "denominacao": "ATIVIDADES DE GRAVACAO DE SOM E DE EDICAO DE MUSICA",
        "descricao": "SERVIÇOS DE GRAVAÇÃO DE SONS",
        "item": "13",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "11092",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5920100",
        "denominacao": "ATIVIDADES DE GRAVACAO DE SOM E DE EDICAO DE MUSICA",
        "descricao": "SERVIÇOS DE TRUCAGEM SONORA",
        "item": "13",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "11091",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5912002",
        "denominacao": "SERVICOS DE MIXAGEM SONORA EM PRODUCAO AUDIOVISUAL",
        "descricao": "SERVIÇOS DE MIXAGEM SONORA",
        "item": "13",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "11090",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5912001",
        "denominacao": "SERVICOS DE DUBLAGEM",
        "descricao": "SERVIÇOS DE DUBLAGEM SONORA",
        "item": "13",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "11089",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001902",
        "denominacao": "PRODUCAO MUSICAL",
        "descricao": "SERVIÇOS DE FONOGRAFIA OU GRAVAÇÃO DE SONS",
        "item": "13",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "11508",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8299702",
        "denominacao": "EMISSAO DE VALES-ALIMENTACAO, VALES-TRANSPORTE E SIMILARES",
        "descricao": "SERVIÇOS DE EMISSÃO DE VALES ALIMENTAÇÃO, TRANSPORTE E SIMILARES",
        "item": "13",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "10260",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7420001",
        "denominacao": "ATIVIDADES DE PRODUÇÃO DE FOTOGRAFIAS, EXCETO AÉREA E SUBMARINA",
        "descricao": "SERVIÇOS FOTOGRÁFICOS",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1665",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7420004",
        "denominacao": "FILMAGEM DE FESTAS E EVENTOS",
        "descricao": "SERVIÇOS DE FILMAGEM DE FESTAS E EVENTOS",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1668",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8219901",
        "denominacao": "FOTOCÓPIAS",
        "descricao": "SERVIÇOS DE REPRODUÇÃO DE PLANTAS OU DESENHOS",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1669",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7420002",
        "denominacao": "ATIVIDADES DE PRODUÇÃO DE FOTOGRAFIAS AÉREAS E SUBMARINAS",
        "descricao": "SERVIÇOS DE FOTOGRAFIAS AÉREAS E SUBMARINAS ",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1667",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5911101",
        "denominacao": "ESTÚDIOS CINEMATOGRÁFICOS",
        "descricao": "SERVIÇOS DE CINEMATOGRAFIA",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1670",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9211802",
        "denominacao": "ATIVIDADES DE PRODUÇÃO DE FILMES E FITAS DE VÍDEO - EXCETO ESTÚDIOS CINEMATOGRÁFICOS",
        "descricao": "SERVIÇOS DE GRAVAÇÃO DE FILMES OU VIDEOTAPES",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1671",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5911101",
        "denominacao": "ESTUDIOS CINEMATOGRAFICOS",
        "descricao": "SERVIÇOS DE GRAVAÇÃO DE FILMES OU VIDEOTAPES",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11101",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5914600",
        "denominacao": "ATIVIDADES DE EXIBICAO CINEMATOGRAFICA",
        "descricao": "SERVIÇOS DE CINEMATOGRAFIA",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11100",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5911101",
        "denominacao": "ESTUDIOS CINEMATOGRAFICOS",
        "descricao": "SERVIÇOS DE CINEMATOGRAFIA",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11099",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8219901",
        "denominacao": "FOTOCOPIAS",
        "descricao": "SERVIÇOS DE REPRODUÇÃO DE PLANTAS OU DESENHOS",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11098",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7420004",
        "denominacao": "FILMAGEM DE FESTAS E EVENTOS",
        "descricao": "SERVIÇOS DE FILMAGEM DE FESTAS E EVENTOS",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11097",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7420002",
        "denominacao": "ATIVIDADES DE PRODUCAO DE FOTOGRAFIAS AEREAS E SUBMARINAS",
        "descricao": "SERVIÇOS DE FOTOGRAFIAS AÉREAS E SUBMARINAS ",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11096",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7420003",
        "denominacao": "LABORATORIOS FOTOGRAFICOS",
        "descricao": "SERVIÇOS DE REVELAÇÃO FOTOGRÁFICA",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11095",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7420001",
        "denominacao": "ATIVIDADES DE PRODUCAO DE FOTOGRAFIAS, EXCETO AEREA E SUBMARINA",
        "descricao": "SERVIÇOS FOTOGRÁFICOS",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11094",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7420002",
        "denominacao": "ATIVIDADES DE PRODUCAO DE FOTOGRAFIAS AEREAS E SUBMARINAS",
        "descricao": "SERVIÇOS FOTOGRÁFICOS",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11093",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1830002",
        "denominacao": "REPRODUÇÃO DE VÍDEO EM QUALQUER SUPORTE",
        "descricao": "SERVIÇOS DE REPROGRAFIA",
        "item": "13",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1673",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1830002",
        "denominacao": "REPRODUCAO DE VIDEO EM QUALQUER SUPORTE",
        "descricao": "SERVIÇOS DE REPROGRAFIA ",
        "item": "13",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "11104",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8219901",
        "denominacao": "FOTOCOPIAS",
        "descricao": "SERVIÇOS DE REPROGRAFIA ",
        "item": "13",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "11106",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6311900",
        "denominacao": "TRATAMENTO DE DADOS, PROVEDORES DE SERVICOS DE APLICACAO E SERVICOS DE HOSPEDAGEM NA INTERNET",
        "descricao": "SERVIÇOS DE DIGITALIZAÇÃO",
        "item": "13",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "11108",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8219901",
        "denominacao": "FOTOCOPIAS",
        "descricao": "SERVIÇOS DE DIGITALIZAÇÃO",
        "item": "13",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "11107",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1830001",
        "denominacao": "REPRODUCAO DE SOM EM QUALQUER SUPORTE",
        "descricao": "SERVIÇOS DE REPROGRAFIA ",
        "item": "13",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "11105",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7420005",
        "denominacao": "SERVICOS DE MICROFILMAGEM",
        "descricao": "SERVIÇOS DE MICROFILMAGEM",
        "item": "13",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "11102",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1830003",
        "denominacao": "REPRODUCAO DE SOFTWARE EM QUALQUER SUPORTE",
        "descricao": "SERVIÇOS DE REPROGRAFIA ",
        "item": "13",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "11103",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7499399",
        "denominacao": "OUTROS SERVIÇOS PRESTADOS PRINCIPALMENTE ÀS EMPRESAS",
        "descricao": "SERVIÇOS DE COMPOSIÇÃO GRÁFICA",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1761",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1821100",
        "denominacao": "SERVICOS DE PRE-IMPRESSAO",
        "descricao": "SERVIÇOS DE FOTOLITOGRAFIA  ",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11112",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1821100",
        "denominacao": "SERVICOS DE PRE-IMPRESSAO",
        "descricao": "SERVIÇOS DE ZINCOGRAFIA",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11111",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1821100",
        "denominacao": "SERVICOS DE PRE-IMPRESSAO",
        "descricao": "SERVIÇOS DE LITOGRAFIA",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11110",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1821100",
        "denominacao": "SERVICOS DE PRE-IMPRESSAO",
        "descricao": "SERVIÇOS DE CLICHERIA",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11109",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1822900",
        "denominacao": "SERVIÇOS DE ACABAMENTOS GRÁFICOS",
        "descricao": "SERVIÇOS DE COMPOSIÇÃO GRÁFICA",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1682",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1821100",
        "denominacao": "SERVIÇOS DE PRÉ-IMPRESSÃO",
        "descricao": "SERVIÇOS DE CLICHERIA",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1678",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1821100",
        "denominacao": "SERVICOS DE PRE-IMPRESSAO",
        "descricao": "SERVIÇOS DE COMPOSIÇÃO GRÁFICA",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11022",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1821100",
        "denominacao": "SERVICOS DE PRE-IMPRESSAO",
        "descricao": "SERVIÇOS DE FOTOCOMPOSIÇÃO ",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11021",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3290501",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE EQUIPAMENTOS TRANSMISSORES DE RÁDIO E TELEVISÃO E DE EQUIPAMENTOS PARA ESTAÇÕES TELEFÔNICAS, PARA RADIOTELEFONIA E RADIOTELEGRAFIA - INCLUSIVE DE MICROOND",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1709",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3316301",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE AERONAVES, EXCETO A MANUTENÇÃO NA PISTA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE AERONAVES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1748",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9521500",
        "denominacao": "REPARAÇÃO E MANUTENÇÃO DE EQUIPAMENTOS ELETROELETRÔNICOS DE USO PESSOAL E DOMÉSTICO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE APARELHOS ELÉTRICOS E ELETRÔNICOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1750",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7250800",
        "denominacao": "MANUTENÇÃO, REPARAÇÃO E INSTALAÇÃO DE MÁQUINAS DE ESCRITÓRIO E DE INFORMÁTICA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, REPARAÇÃO E INSTALAÇÃO DE MÁQUINAS DE ESCRITRÓRIOS E INFORMÁTICA",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1755",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9529105",
        "denominacao": "REPARACAO DE ARTIGOS DO MOBILIARIO",
        "descricao": "SERVIÇOS DE REPARAÇÃO DE ARTIGOS DE TAPEÇARIA",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11055",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9529105",
        "denominacao": "REPARACAO DE ARTIGOS DO MOBILIARIO",
        "descricao": "SERVIÇOS DE CONSERTO E RESTAURAÇÃO DE MÓVEIS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11054",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9529103",
        "denominacao": "REPARACAO DE RELOGIOS",
        "descricao": "SERVIÇOS DE CONSERTOS DE JOIAS E RELÓGIOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11053",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9529106",
        "denominacao": "REPARACAO DE JOIAS",
        "descricao": "SERVIÇOS DE CONSERTOS DE JOIAS E RELÓGIOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11052",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9529102",
        "denominacao": "CHAVEIROS",
        "descricao": "SERVIÇOS DE CONSERTOS DE FECHADURAS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11051",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4520007",
        "denominacao": "SERVICOS DE INSTALACAO, MANUTENCAO E REPARACAO DE ACESSORIOS PARA VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE PELÍCULA DE CONTENÇÃO SOLAR EM VEÍCULOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11050",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4520007",
        "denominacao": "SERVICOS DE INSTALACAO, MANUTENCAO E REPARACAO DE ACESSORIOS PARA VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE INSTALAÇÃO, MANUTENÇÃO E REPARAÇÃO DE ACESSÓRIOS PARA VEÍCULOS AUTOMOTORES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11049",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4520006",
        "denominacao": "SERVICOS DE BORRACHARIA PARA VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERTO DE PNEUS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11048",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4520005",
        "denominacao": "SERVICOS DE LAVAGEM, LUBRIFICACAO E POLIMENTO DE VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE LIMPEZA E LUSTRAÇÃO DE VEÍCULOS (LAVADOR DE CARROS)",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11047",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4520002",
        "denominacao": "SERVICOS DE LANTERNAGEM OU FUNILARIA E PINTURA DE VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE CHAPEAÇÃO (CHAPEADOR)",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11042",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4329103",
        "denominacao": "INSTALACAO, MANUTENCAO E REPARACAO DE ELEVADORES, ESCADAS E ESTEIRAS ROLANTES, EXCETO DE FABRICACAO PROPRIA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E REPARAÇÃO DE ELEVADORES, ESCADAS E ESTEIRAS LOLANTES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11041",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3313999",
        "denominacao": "MANUTENCAO E REPARACAO DE MAQUINAS, APARELHOS E MATERIAIS ELETRICOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10998",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9511800",
        "denominacao": "REPARACAO E MANUTENCAO DE COMPUTADORES E DE EQUIPAMENTOS PERIFERICOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, REPARAÇÃO E INSTALAÇÃO DE MÁQUINAS DE ESCRITRÓRIOS E INFORMÁTICA",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11016",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "2539000",
        "denominacao": "SERVICOS DE USINAGEM, SOLDA, TRATAMENTO E REVESTIMENTO EM METAIS",
        "descricao": "SERVIÇOS DE AFIAÇÃO (AFIAÇÃO DE LÂMINAS)",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11015",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9529199",
        "denominacao": "REPARACAO E MANUTENCAO DE OUTROS OBJETOS E EQUIPAMENTOS PESSOAIS E DOMESTICOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "CONSERTO DE EQUIPAMENTOS DE CAMPING",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11014",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9529101",
        "denominacao": "REPARACAO DE CALCADOS, BOLSAS E ARTIGOS DE VIAGEM",
        "descricao": "SERVIÇOS DE REPARAÇÃO DE CALÇADOS OU ARTIGOS DE COURO",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11013",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9512600",
        "denominacao": "REPARACAO E MANUTENCAO DE EQUIPAMENTOS DE COMUNICACAO",
        "descricao": "SERVIÇOS DE REPARAÇÃO E MANUTENÇÃO DE APARELHOS TELEFÔNICOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11012",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3312102",
        "denominacao": "MANUTENCAO E REPARACAO DE APARELHOS E INSTRUMENTOS DE MEDIDA, TESTE E CONTROLE",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE APARELHOS ELÉTRICOS E ELETRÔNICOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11011",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3312103",
        "denominacao": "MANUTENCAO E REPARACAO DE APARELHOS ELETROMEDICOS E ELETROTERAPEUTICOS E EQUIPAMENTOS DE IRRADIACAO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE APARELHOS ELÉTRICOS E ELETRÔNICOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11010",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3312104",
        "denominacao": "MANUTENCAO E REPARACAO DE EQUIPAMENTOS E INSTRUMENTOS OPTICOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE APARELHOS ELÉTRICOS E ELETRÔNICOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11009",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9521500",
        "denominacao": "REPARACAO E MANUTENCAO DE EQUIPAMENTOS ELETROELETRONICOS DE USO PESSOAL E DOMESTICO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE APARELHOS ELÉTRICOS E ELETRÔNICOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11008",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4322302",
        "denominacao": "INSTALACAO E MANUTENCAO DE SISTEMAS CENTRAIS DE AR CONDICIONADO, DE VENTILACAO E REFRIGERACAO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE SISTEMAS CENTRAIS DE AR CONDICIONADO",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11007",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "2930103",
        "denominacao": "FABRICACAO DE CABINES, CARROCERIAS E REBOQUES PARA OUTROS VEICULOS AUTOMOTORES, EXCETO CAMINHOES E ONIBUS",
        "descricao": "SERVIÇOS DE BLINDAGEM DE MÁQUINAS, VEÍCULOS, APARELHOS, EQUIPAMENTOS, MOTORES, ELEVADORES OU DE QUALQUER OBJETO",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11006",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3313901",
        "denominacao": "MANUTENCAO E REPARACAO DE GERADORES, TRANSFORMADORES E MOTORES ELETRICOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS E MOTORES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11005",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3314701",
        "denominacao": "MANUTENCAO E REPARACAO DE MAQUINAS MOTRIZES NAO-ELETRICAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS E MOTORES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11004",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3316301",
        "denominacao": "MANUTENCAO E REPARACAO DE AERONAVES, EXCETO A MANUTENCAO NA PISTA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS E MOTORES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11003",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "2851800",
        "denominacao": "FABRICACAO DE MAQUINAS E EQUIPAMENTOS PARA A PROSPECCAO E EXTRACAO DE PETROLEO, PECAS E ACESSORIOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11002",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3312102",
        "denominacao": "MANUTENCAO E REPARACAO DE APARELHOS E INSTRUMENTOS DE MEDIDA, TESTE E CONTROLE",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11001",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3312103",
        "denominacao": "MANUTENCAO E REPARACAO DE APARELHOS ELETROMEDICOS E ELETROTERAPEUTICOS E EQUIPAMENTOS DE IRRADIACAO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11000",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3312104",
        "denominacao": "MANUTENCAO E REPARACAO DE EQUIPAMENTOS E INSTRUMENTOS OPTICOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10999",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3314702",
        "denominacao": "MANUTENCAO E REPARACAO DE EQUIPAMENTOS HIDRAULICOS E PNEUMATICOS, EXCETO VALVULAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10997",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3314705",
        "denominacao": "MANUTENCAO E REPARACAO DE EQUIPAMENTOS DE TRANSMISSAO PARA FINS INDUSTRIAIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10996",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3314706",
        "denominacao": "MANUTENCAO E REPARACAO DE MAQUINAS, APARELHOS E EQUIPAMENTOS PARA INSTALACOES TERMICAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10995",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3314709",
        "denominacao": "MANUTENCAO E REPARACAO DE MAQUINAS DE ESCREVER, CALCULAR E DE OUTROS EQUIPAMENTOS NAO-ELETRONICOS PARA ESCRITORIO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10994",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3314711",
        "denominacao": "MANUTENCAO E REPARACAO DE MAQUINAS E EQUIPAMENTOS PARA AGRICULTURA E PECUARIA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10993",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3314713",
        "denominacao": "MANUTENCAO E REPARACAO DE MAQUINAS-FERRAMENTA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10992",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3314714",
        "denominacao": "MANUTENCAO E REPARACAO DE MAQUINAS E EQUIPAMENTOS PARA A PROSPECCAO E EXTRACAO DE PETROLEO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10991",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3314715",
        "denominacao": "MANUTENCAO E REPARACAO DE MAQUINAS E EQUIPAMENTOS PARA USO NA EXTRACAO MINERAL, EXCETO NA EXTRACAO DE PETROLEO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10990",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3314717",
        "denominacao": "MANUTENCAO E REPARACAO DE MAQUINAS E EQUIPAMENTOS DE TERRAPLENAGEM, PAVIMENTACAO E CONSTRUCAO, EXCETO TRATORES",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10989",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3314719",
        "denominacao": "MANUTENCAO E REPARACAO DE MAQUINAS E EQUIPAMENTOS PARA AS INDUSTRIAS DE ALIMENTOS, BEBIDAS E FUMO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10988",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3314720",
        "denominacao": "MANUTENCAO E REPARACAO DE MAQUINAS E EQUIPAMENTOS PARA A INDUSTRIA TEXTIL, DO VESTUARIO, DO COURO E CALCADOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10987",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3314721",
        "denominacao": "MANUTENCAO E REPARACAO DE MAQUINAS E APARELHOS PARA A INDUSTRIA DE CELULOSE, PAPEL E PAPELAO E ARTEFATOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10986",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4329104",
        "denominacao": "MONTAGEM E INSTALACAO DE SISTEMAS E EQUIPAMENTOS DE ILUMINACAO E SINALIZACAO EM VIAS PUBLICAS, PORTOS E AEROPORTOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10985",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9511800",
        "denominacao": "REPARACAO E MANUTENCAO DE COMPUTADORES E DE EQUIPAMENTOS PERIFERICOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10984",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9512600",
        "denominacao": "REPARACAO E MANUTENCAO DE EQUIPAMENTOS DE COMUNICACAO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10983",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3316301",
        "denominacao": "MANUTENCAO E REPARACAO DE AERONAVES, EXCETO A MANUTENCAO NA PISTA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE AERONAVES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10982",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3316302",
        "denominacao": "MANUTENCAO DE AERONAVES NA PISTA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE AERONAVES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10981",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3317101",
        "denominacao": "MANUTENCAO E REPARACAO DE EMBARCACOES E ESTRUTURAS FLUTUANTES",
        "descricao": "SERVIÇOS DE LIMPEZA E MANUTENÇÃO DE NAVIOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10980",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4520005",
        "denominacao": "SERVICOS DE LAVAGEM, LUBRIFICACAO E POLIMENTO DE VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE LUBRIFICAÇÃO, LUSTRAÇÃO, REVISÃO E RESTAURAÇÃO DE VEÍCULOS ",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10979",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4520001",
        "denominacao": "SERVICOS DE MANUTENCAO E REPARACAO MECANICA DE VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE VEÍCULOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10978",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3319800",
        "denominacao": "MANUTENCAO E REPARACAO DE EQUIPAMENTOS E PRODUTOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE VEÍCULOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10977",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4520003",
        "denominacao": "SERVICOS DE MANUTENCAO E REPARACAO ELETRICA DE VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE VEÍCULOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10976",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4520007",
        "denominacao": "SERVICOS DE INSTALACAO, MANUTENCAO E REPARACAO DE ACESSORIOS PARA VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE VEÍCULOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10975",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4520004",
        "denominacao": "SERVICOS DE ALINHAMENTO E BALANCEAMENTO DE VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE GEOMETRI E BALANCEAMENTO",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10974",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4520002",
        "denominacao": "SERVICOS DE LANTERNAGEM OU FUNILARIA E PINTURA DE VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE LANTERNAGEM E LATARIA (LANTERNEIRO/LATOEIRO)",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10973",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9512600",
        "denominacao": "REPARAÇÃO E MANUTENÇÃO DE EQUIPAMENTOS DE COMUNICAÇÃO",
        "descricao": "SERVIÇOS DE REPARAÇÃO E MANUTENÇÃO DE APARELHOS TELEFÔNICOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1751",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4322302",
        "denominacao": "INSTALAÇÃO E MANUTENÇÃO DE SISTEMAS CENTRAIS DE AR CONDICIONADO, DE VENTILAÇÃO E REFRIGERAÇÃO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE SISTEMAS CENTRAIS DE AR CONDICIONADO",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1749",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "2996399",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE OUTRAS MÁQUINAS E EQUIPAMENTOS DE USO ESPECÍFICO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS, MOTORES E EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1743",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4520005",
        "denominacao": "SERVIÇOS DE LAVAGEM, LUBRIFICAÇÃO E POLIMENTO DE VEÍCULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE LIMPEZA E LUSTRAÇÃO DE VEÍCULOS (LAVADOR DE CARROS)",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1722",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5279502",
        "denominacao": "REPARAÇÃO DE JÓIAS E RELÓGIOS",
        "descricao": "SERVIÇOS DE CONSERTOS DE JOIAS E RELÓGIOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1728",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9529105",
        "denominacao": "REPARAÇÃO DE ARTIGOS DO MOBILIÁRIO",
        "descricao": "SERVIÇOS DE CONSERTO E RESTAURAÇÃO DE MÓVEIS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1729",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9512600",
        "denominacao": "REPARAÇÃO E MANUTENÇÃO DE EQUIPAMENTOS DE COMUNICAÇÃO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1710",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5279502",
        "denominacao": "REPARAÇÃO E MANUTENÇÃO DE MÁQUINAS E DE APARELHOS ELETRODOMÉSTICOS - EXCETO APARELHOS TELEFÔNICOS",
        "descricao": "SERVIÇOS DE ASSISTÊNCIA TÉCNICA",
        "item": "14",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1756",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9511800",
        "denominacao": "REPARACAO E MANUTENCAO DE COMPUTADORES E DE EQUIPAMENTOS PERIFERICOS",
        "descricao": "SERVIÇOS DE ASSISTÊNCIA TÉCNICA",
        "item": "14",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "11018",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9512600",
        "denominacao": "REPARACAO E MANUTENCAO DE EQUIPAMENTOS DE COMUNICACAO",
        "descricao": "SERVIÇOS DE ASSISTÊNCIA TÉCNICA",
        "item": "14",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "11017",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9512600",
        "denominacao": "REPARAÇÃO E MANUTENÇÃO DE EQUIPAMENTOS DE COMUNICAÇÃO",
        "descricao": "SERVIÇOS DE ASSISTÊNCIA TÉCNICA",
        "item": "14",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1757",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "2950600",
        "denominacao": "RECONDICIONAMENTO E RECUPERACAO DE MOTORES PARA VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE RECONDICIONAMENTO DE MOTORES",
        "item": "14",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11019",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "2212900",
        "denominacao": "REFORMA DE PNEUMATICOS USADOS",
        "descricao": "SERVIÇOS DE RECAUCHUTAGEM OU REGENERAÇÃO DE PNEUS",
        "item": "14",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "11020",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1340501",
        "denominacao": "ESTAMPARIA E TEXTURIZAÇÃO EM FIOS, TECIDOS, ARTEFATOS TÊXTEIS E PEÇAS DO VESTUÁRIO",
        "descricao": "SERVIÇOS DE ESTAMPARIA",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1766",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1340502",
        "denominacao": "ALVEJAMENTO, TINGIMENTO E TORÇÃO EM FIOS, TECIDOS, ARTEFATOS TÊXTEIS E PEÇAS DO VESTUÁRIO",
        "descricao": "SERVIÇOS DE RESTAURAÇÃO, RECONDICIONAMENTO, ACONDICIONAMENTO, PINTURA, BENEFICIAMENTO, LAVAGEM SECAGEM, TINGIMENTO, CORTE E RECORTE EM FIOS, TECIDOS E ARTIGOS TEXTEIS, INCLUSIVE EM PEÇAS DO VESTUÁRIO",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1767",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "2539000",
        "denominacao": "SERVIÇOS DE USINAGEM, SOLDA, TRATAMENTO E REVESTIMENTO EM METAIS",
        "descricao": "SERVIÇOS DE OFICINA DE TORNO",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1770",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "2722802",
        "denominacao": "RECONDICIONAMENTO DE BATERIAS E ACUMULADORES PARA VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE RECARGA OU RECONDUÇÃO DE BATERIAS",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9434",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3211601",
        "denominacao": "LAPIDACAO DE GEMAS",
        "descricao": "SERVIÇOS DE LAPIDAÇÃO OU POLIMENTO",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9436",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1340502",
        "denominacao": "ALVEJAMENTO, TINGIMENTO E TORCAO EM FIOS, TECIDOS, ARTEFATOS TEXTEIS E PECAS DO VESTUARIO",
        "descricao": "SERVIÇOS DE RESTAUÇÃO, RECONDICIONAMENTO, ACONDICIONAMENTO, PINTURA, BENEFICIAMENTO, LAVAGEM SECAGEM, TINGIMENTO, CORTE E RECORTE EM FIOS, TECIDOS E ARTIGOS TEXTEIS, INCLUSIVE EM PEÇAS DO VESTUÁRIO",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11031",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1340501",
        "denominacao": "ESTAMPARIA E TEXTURIZACAO EM FIOS, TECIDOS, ARTEFATOS TEXTEIS E PECAS DO VESTUARIO",
        "descricao": "SERVIÇOS DE ESTAMPARIA",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11030",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1340599",
        "denominacao": "OUTROS SERVICOS DE ACABAMENTO EM FIOS, TECIDOS, ARTEFATOS TEXTEIS E PECAS DO VESTUARIO",
        "descricao": "SERVIÇOS DE ESTAMPARIA",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11029",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "2532201",
        "denominacao": "PRODUCAO DE ARTEFATOS ESTAMPADOS DE METAL",
        "descricao": "SERVIÇOS DE ESTAMPARIA",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11028",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "2863100",
        "denominacao": "FABRICACAO DE MAQUINAS E EQUIPAMENTOS PARA A INDUSTRIA TEXTIL, PECAS E ACESSORIOS",
        "descricao": "SERVIÇOS DE ESTAMPARIA",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11027",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4644301",
        "denominacao": "COMERCIO ATACADISTA DE MEDICAMENTOS E DROGAS DE USO HUMANO",
        "descricao": "SERVIÇOS DE ENVASAMENTO E EMPACOTAMENTO",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11026",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4644302",
        "denominacao": "COMERCIO ATACADISTA DE MEDICAMENTOS E DROGAS DE USO VETERINARIO",
        "descricao": "SERVIÇOS DE ENVASAMENTO E EMPACOTAMENTO",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11025",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8292000",
        "denominacao": "ENVASAMENTO E EMPACOTAMENTO SOB CONTRATO",
        "descricao": "SERVIÇOS DE ENVASAMENTO E EMPACOTAMENTO",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11024",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5250803",
        "denominacao": "AGENCIAMENTO DE CARGAS, EXCETO PARA O TRANSPORTE MARITIMO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO DE CARGAS",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11023",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9521500",
        "denominacao": "REPARACAO E MANUTENCAO DE EQUIPAMENTOS ELETROELETRONICOS DE USO PESSOAL E DOMESTICO",
        "descricao": "SERVIÇOS DE JATEAMENTO DE BENS MÓVEIS",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11038",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9529104",
        "denominacao": "REPARACAO DE BICICLETAS, TRICICLOS E OUTROS VEICULOS NAO-MOTORIZADOS",
        "descricao": "SERVIÇOS DE JATEAMENTO DE BENS MÓVEIS",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11037",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9529105",
        "denominacao": "REPARACAO DE ARTIGOS DO MOBILIARIO",
        "descricao": "SERVIÇOS DE JATEAMENTO DE BENS MÓVEIS",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11036",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4520002",
        "denominacao": "SERVICOS DE LANTERNAGEM OU FUNILARIA E PINTURA DE VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE JATEAMENTO DE BENS MÓVEIS",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11035",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "2539000",
        "denominacao": "SERVICOS DE USINAGEM, SOLDA, TRATAMENTO E REVESTIMENTO EM METAIS",
        "descricao": "SERVIÇOS DE OFICINA DE TORNO",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11034",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "2539000",
        "denominacao": "SERVICOS DE USINAGEM, SOLDA, TRATAMENTO E REVESTIMENTO EM METAIS",
        "descricao": "SERVIÇOS DE ANODIZAÇÃO OU GALVANOPLASTIA",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11033",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1510600",
        "denominacao": "CURTIMENTO E OUTRAS PREPARACOES DE COURO",
        "descricao": "SERVIÇOS DE  CORTE E ACABAMENTO ",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11032",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1610202",
        "denominacao": "SERRARIAS SEM DESDOBRAMENTO DE MADEIRA",
        "descricao": "SERVIÇOS DE BENEFICIAMENTO DE MADEIRA",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "10972",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3250707",
        "denominacao": "FABRICACAO DE ARTIGOS OPTICOS",
        "descricao": "SERVIÇOS DE LAPIDAÇÃO OU POLIMENTO",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9437",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3250707",
        "denominacao": "FABRICACAO DE ARTIGOS OPTICOS",
        "descricao": "SERVIÇOS DE BENEFICIAMENTO DE LENTES",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9435",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1822900",
        "denominacao": "SERVICOS DE ACABAMENTOS GRAFICOS",
        "descricao": "SERVIÇOS DE PLASTIFICAÇÃO",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9433",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3329501",
        "denominacao": "SERVIÇOS DE MONTAGEM DE MÓVEIS DE QUALQUER MATERIAL",
        "descricao": "SERVIÇOS DE MONTAGEM OU DESMONTAGEM DE DIVISÓRIAS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1772",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9521500",
        "denominacao": "REPARAÇÃO E MANUTENÇÃO DE EQUIPAMENTOS ELETROELETRÔNICOS DE USO PESSOAL E DOMÉSTICO",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE PEÇAS E ACESSÓRIOS ",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1786",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9521500",
        "denominacao": "REPARAÇÃO E MANUTENÇÃO DE EQUIPAMENTOS ELETROELETRÔNICOS DE USO PESSOAL E DOMÉSTICO",
        "descricao": "SERVIÇOS DE INSTALAÇÃO E MONTAGEM DE APARELHOS ELÉTRICOS E ELETRÔNICOS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1787",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3329501",
        "denominacao": "SERVIÇOS DE MONTAGEM DE MÓVEIS DE QUALQUER MATERIAL",
        "descricao": "SERVIÇOS DE MONTAGEM OU DESMONTAGEM DE MÓVEIS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1783",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "2822402",
        "denominacao": "FABRICACAO DE MAQUINAS, EQUIPAMENTOS E APARELHOS PARA TRANSPORTE E ELEVACAO DE CARGAS, PECAS E ACESSORIOS",
        "descricao": "SERVIÇOS DE MONTAGEM INDUSTRIAL",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "10934",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "2823200",
        "denominacao": "FABRICACAO DE MAQUINAS E APARELHOS DE REFRIGERACAO E VENTILACAO PARA USO INDUSTRIAL E COMERCIAL, PECAS E ACESSORIOS",
        "descricao": "SERVIÇOS DE MONTAGEM INDUSTRIAL",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "10933",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "2852600",
        "denominacao": "FABRICACAO DE OUTRAS MAQUINAS E EQUIPAMENTOS PARA USO NA EXTRACAO MINERAL, PECAS E ACESSORIOS, EXCETO NA EXTRACAO DE PETROLEO",
        "descricao": "SERVIÇOS DE MONTAGEM INDUSTRIAL",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "10932",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3101200",
        "denominacao": "FABRICACAO DE MOVEIS COM PREDOMINANCIA DE MADEIRA",
        "descricao": "SERVIÇOS DE MONTAGEM INDUSTRIAL",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "10931",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3102100",
        "denominacao": "FABRICACAO DE MOVEIS COM PREDOMINANCIA DE METAL",
        "descricao": "SERVIÇOS DE MONTAGEM INDUSTRIAL",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "10930",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3103900",
        "denominacao": "FABRICACAO DE MOVEIS DE OUTROS MATERIAIS, EXCETO MADEIRA E METAL",
        "descricao": "SERVIÇOS DE MONTAGEM INDUSTRIAL",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "10929",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3321000",
        "denominacao": "INSTALACAO DE MAQUINAS E EQUIPAMENTOS INDUSTRIAIS",
        "descricao": "SERVIÇOS DE MONTAGEM DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "10928",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "2851800",
        "denominacao": "FABRICACAO DE MAQUINAS E EQUIPAMENTOS PARA A PROSPECCAO E EXTRACAO DE PETROLEO, PECAS E ACESSORIOS",
        "descricao": "SERVIÇOS DE INSTALAÇÃO MONTAGEM DE MÁQUINAS E EQUIPAMENTOS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "10927",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3321000",
        "denominacao": "INSTALACAO DE MAQUINAS E EQUIPAMENTOS INDUSTRIAIS",
        "descricao": "SERVIÇOS DE INSTALAÇÃO MONTAGEM DE MÁQUINAS E EQUIPAMENTOS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "10926",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "2821601",
        "denominacao": "FABRICACAO DE FORNOS INDUSTRIAIS, APARELHOS E EQUIPAMENTOS NAO-ELETRICOS PARA INSTALACOES TERMICAS, PECAS E ACESSORIOS",
        "descricao": "SERVIÇOS DE INSTALAÇÃO E MONTAGEM DE APARELHOS ELÉTRICOS E ELETRÔNICOS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "10950",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "2823200",
        "denominacao": "FABRICACAO DE MAQUINAS E APARELHOS DE REFRIGERACAO E VENTILACAO PARA USO INDUSTRIAL E COMERCIAL, PECAS E ACESSORIOS",
        "descricao": "SERVIÇOS DE INSTALAÇÃO E MONTAGEM DE APARELHOS ELÉTRICOS E ELETRÔNICOS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "10949",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3321000",
        "denominacao": "INSTALACAO DE MAQUINAS E EQUIPAMENTOS INDUSTRIAIS",
        "descricao": "SERVIÇOS DE INSTALAÇÃO E MONTAGEM DE APARELHOS ELÉTRICOS E ELETRÔNICOS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "10948",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9521500",
        "denominacao": "REPARACAO E MANUTENCAO DE EQUIPAMENTOS ELETROELETRONICOS DE USO PESSOAL E DOMESTICO",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE PEÇAS E ACESSÓRIOS ",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "10947",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3240099",
        "denominacao": "FABRICACAO DE OUTROS BRINQUEDOS E JOGOS RECREATIVOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MONTAGEM DE BRINQUEDOS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "10946",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3329501",
        "denominacao": "SERVICOS DE MONTAGEM DE MOVEIS DE QUALQUER MATERIAL",
        "descricao": "SERVIÇOS DE MONTAGEM OU DESMONTAGEM DE MÓVEIS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "10945",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4330402",
        "denominacao": "INSTALACAO DE PORTAS, JANELAS, TETOS, DIVISORIAS E ARMARIOS EMBUTIDOS DE QUALQUER MATERIAL",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE MÓVEIS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "10944",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3329501",
        "denominacao": "SERVICOS DE MONTAGEM DE MOVEIS DE QUALQUER MATERIAL",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE MÓVEIS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "10943",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3041500",
        "denominacao": "FABRICACAO DE AERONAVES",
        "descricao": "SERVIÇOS DE MONTAGEM DE AERONAVES",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "10942",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3031800",
        "denominacao": "FABRICACAO DE LOCOMOTIVAS, VAGOES E OUTROS MATERIAIS RODANTES",
        "descricao": "SERVIÇOS DE MONTAGEM DE LOCOMOTIVAS E VAGÕES",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "10941",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1622601",
        "denominacao": "FABRICACAO DE CASAS DE MADEIRA PRE-FABRICADAS",
        "descricao": "SERVIÇOS DE MONTAGEM INDUSTRIAL",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "10940",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1622602",
        "denominacao": "FABRICACAO DE ESQUADRIAS DE MADEIRA E DE PECAS DE MADEIRA PARA INSTALACOES INDUSTRIAIS E COMERCIAIS",
        "descricao": "SERVIÇOS DE MONTAGEM INDUSTRIAL",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "10939",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "2512800",
        "denominacao": "FABRICACAO DE ESQUADRIAS DE METAL",
        "descricao": "SERVIÇOS DE MONTAGEM INDUSTRIAL",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "10938",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "2513600",
        "denominacao": "FABRICACAO DE OBRAS DE CALDEIRARIA PESADA",
        "descricao": "SERVIÇOS DE MONTAGEM INDUSTRIAL",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "10937",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "2631100",
        "denominacao": "FABRICACAO DE EQUIPAMENTOS TRANSMISSORES DE COMUNICACAO, PECAS E ACESSORIOS",
        "descricao": "SERVIÇOS DE MONTAGEM INDUSTRIAL",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "10936",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "2812700",
        "denominacao": "FABRICACAO DE EQUIPAMENTOS HIDRAULICOS E PNEUMATICOS, PECAS E ACESSORIOS, EXCETO VALVULAS",
        "descricao": "SERVIÇOS DE MONTAGEM INDUSTRIAL",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "10935",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4329102",
        "denominacao": "INSTALACAO DE EQUIPAMENTOS PARA ORIENTACAO A NAVEGACAO MARITIMA, FLUVIAL E LACUSTRE",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE EQUIPAMENTOS PARA ORIENTAÇÃO À NAVEGAÇÃO MARÍTIMA FLUVIAL E LACUSTRE",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "11040",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4330402",
        "denominacao": "INSTALACAO DE PORTAS, JANELAS, TETOS, DIVISORIAS E ARMARIOS EMBUTIDOS DE QUALQUER MATERIAL",
        "descricao": "SERVIÇOS DE MONTAGEM OU DESMONTAGEM DE DIVISÓRIAS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "11039",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3329501",
        "denominacao": "SERVIÇOS DE MONTAGEM DE MÓVEIS DE QUALQUER MATERIAL",
        "descricao": "SERVIÇOS DE MONTAGEM OU DESMONTAGEM DE MÓVEIS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1781",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3329501",
        "denominacao": "SERVIÇOS DE MONTAGEM DE MÓVEIS DE QUALQUER MATERIAL",
        "descricao": "SERVIÇOS DE MONTAGEM OU DESMONTAGEM DE MÓVEIS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1782",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9002702",
        "denominacao": "RESTAURAÇÃO DE OBRAS-DE-ARTE",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE MOLDURAS",
        "item": "14",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "1788",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9002702",
        "denominacao": "RESTAURACAO DE OBRAS DE ARTE",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE MOLDURAS",
        "item": "14",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "10951",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4330403",
        "denominacao": "OBRAS DE ACABAMENTO EM GESSO E ESTUQUE",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE MOLDURAS",
        "item": "14",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "10952",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1822900",
        "denominacao": "SERVICOS DE ACABAMENTOS GRAFICOS",
        "descricao": "SERVIÇOS DE PLASTIFICAÇÃO DE DOCUMENTOS",
        "item": "14",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "10953",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1822900",
        "denominacao": "SERVICOS DE ACABAMENTOS GRAFICOS",
        "descricao": "SERVIÇOS DE ENCADERNAÇÃO, GRAVAÇÃO E DOURAÇÃO",
        "item": "14",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "10955",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8219999",
        "denominacao": "PREPARACAO DE DOCUMENTOS E SERVICOS ESPECIALIZADOS DE APOIO ADMINISTRATIVO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE PLASTIFICAÇÃO DE DOCUMENTOS",
        "item": "14",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "10954",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1812001",
        "denominacao": "CONFECÇÃO DE PEÇAS DE VESTUÁRIO - EXCETO ROUPAS ÍNTIMAS, BLUSAS, CAMISAS E SEMELHANTES E AS CONFECCIONADAS SOB MEDIDA.",
        "descricao": "SERVIÇOS DE CORTE E RECORTE DE PEÇAS DE VESTUÁRIO, SOB MEDIDA",
        "item": "14",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "1792",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1412602",
        "denominacao": "CONFECÇÃO, SOB MEDIDA, DE PEÇAS DO VESTUÁRIO, EXCETO ROUPAS ÍNTIMAS",
        "descricao": "SERVIÇOS DE ALFAIATARIA E COSTURA  ",
        "item": "14",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "1795",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1412603",
        "denominacao": "FACCAO DE PECAS DO VESTUARIO, EXCETO ROUPAS INTIMAS",
        "descricao": "SERVIÇOS DE CORTE E RECORTE DE PEÇAS DE VESTUÁRIO, SOB MEDIDA",
        "item": "14",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "10956",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1411801",
        "denominacao": "CONFECCAO DE ROUPAS INTIMAS",
        "descricao": "SERVIÇOS DE ALFAIATARIA E COSTURA  ",
        "item": "14",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "10958",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1413402",
        "denominacao": "CONFECCAO, SOB MEDIDA, DE ROUPAS PROFISSIONAIS",
        "descricao": "SERVIÇOS DE ALFAIATARIA E COSTURA  ",
        "item": "14",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "10960",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1412602",
        "denominacao": "CONFECCAO, SOB MEDIDA, DE PECAS DO VESTUARIO, EXCETO ROUPAS INTIMAS",
        "descricao": "SERVIÇOS DE ALFAIATARIA E COSTURA  ",
        "item": "14",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "10961",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9529199",
        "denominacao": "REPARACAO E MANUTENCAO DE OUTROS OBJETOS E EQUIPAMENTOS PESSOAIS E DOMESTICOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ALFAIATARIA E COSTURA  ",
        "item": "14",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "10959",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1411802",
        "denominacao": "FACCAO DE ROUPAS INTIMAS",
        "descricao": "SERVIÇOS DE CORTE E RECORTE DE PEÇAS DE VESTUÁRIO, SOB MEDIDA",
        "item": "14",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "10957",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1413402",
        "denominacao": "CONFECÇÃO, SOB MEDIDA, DE ROUPAS PROFISSIONAIS",
        "descricao": "SERVIÇOS DE ALFAIATARIA E COSTURA  ",
        "item": "14",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "1794",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1412602",
        "denominacao": "CONFECÇÃO, SOB MEDIDA, DE PEÇAS DO VESTUÁRIO, EXCETO ROUPAS ÍNTIMAS",
        "descricao": "SERVIÇOS DE ALFAIATARIA E COSTURA  ",
        "item": "14",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "1793",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9301701",
        "denominacao": "LAVANDERIAS E TINTURARIAS",
        "descricao": "SERVIÇOS DE LAVANDERIA E TINTURARIA",
        "item": "14",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "1796",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9601701",
        "denominacao": "LAVANDERIAS",
        "descricao": "SERVIÇOS DE LAVANDERIA E TINTURARIA",
        "item": "14",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10963",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9601702",
        "denominacao": "TINTURARIAS",
        "descricao": "SERVIÇOS DE LAVANDERIA E TINTURARIA",
        "item": "14",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10962",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9529105",
        "denominacao": "REPARAÇÃO DE ARTIGOS DO MOBILIÁRIO",
        "descricao": "SERVIÇOS DE TAPEÇARIA E REFORMA DE ESTOFAMENTOS (ESTOFADOR)",
        "item": "14",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "1797",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9529105",
        "denominacao": "REPARAÇÃO DE ARTIGOS DO MOBILIÁRIO",
        "descricao": "SERVIÇOS DE TAPEÇARIA E REFORMA DE ESTOFAMENTOS (IMPERMEABILIZADOR)",
        "item": "14",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "1798",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9529105",
        "denominacao": "REPARAÇÃO DE ARTIGOS DO MOBILIÁRIO",
        "descricao": "SERVIÇOS DE TAPEÇARIA E REFORMA DE ESTOFAMENTOS",
        "item": "14",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "1799",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9529105",
        "denominacao": "REPARACAO DE ARTIGOS DO MOBILIARIO",
        "descricao": "SERVIÇOS DE TAPEÇARIA E REFORMA DE ESTOFAMENTOS (IMPERMEABILIZADOR)",
        "item": "14",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10965",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9529105",
        "denominacao": "REPARACAO DE ARTIGOS DO MOBILIARIO",
        "descricao": "SERVIÇOS DE TAPEÇARIA E REFORMA DE ESTOFAMENTOS",
        "item": "14",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10966",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9529105",
        "denominacao": "REPARACAO DE ARTIGOS DO MOBILIARIO",
        "descricao": "SERVIÇOS DE TAPEÇARIA E REFORMA DE ESTOFAMENTOS (ESTOFADOR)",
        "item": "14",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10964",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4520002",
        "denominacao": "SERVICOS DE LANTERNAGEM OU FUNILARIA E PINTURA DE VEICULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE FUNELARIA E LANTERNAGEM ",
        "item": "14",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10967",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "2542000",
        "denominacao": "FABRICAÇÃO DE ARTIGOS DE SERRALHERIA, EXCETO ESQUADRIAS",
        "descricao": "SERVIÇOS DE SERRALHERIA",
        "item": "14",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "1801",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3329501",
        "denominacao": "SERVIÇOS DE MONTAGEM DE MÓVEIS DE QUALQUER MATERIAL",
        "descricao": "SERVIÇOS DE CAPINTARIA",
        "item": "14",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "1802",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "2512800",
        "denominacao": "FABRICACAO DE ESQUADRIAS DE METAL",
        "descricao": "SERVIÇOS DE SERRALHERIA",
        "item": "14",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10969",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "1622699",
        "denominacao": "FABRICACAO DE OUTROS ARTIGOS DE CARPINTARIA PARA CONSTRUCAO",
        "descricao": "SERVIÇOS DE CAPINTARIA",
        "item": "14",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10970",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "2542000",
        "denominacao": "FABRICACAO DE ARTIGOS DE SERRALHERIA, EXCETO ESQUADRIAS",
        "descricao": "SERVIÇOS DE SERRALHERIA",
        "item": "14",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10968",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9378",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9383",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9385",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9481",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9480",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9479",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9478",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9477",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9476",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9475",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9474",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9473",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9498",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9497",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9496",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9495",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9494",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9493",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9492",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9491",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9490",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9513",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9512",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9511",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9510",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9509",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9508",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9507",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6493000",
        "denominacao": "ADMINISTRACAO DE CONSORCIOS PARA AQUISICAO DE BENS E DIREITOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9506",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9505",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9504",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9503",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9502",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9501",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9500",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9499",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6470101",
        "denominacao": "FUNDOS DE INVESTIMENTO, EXCETO PREVIDENCIARIOS E IMOBILIARIOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9489",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9488",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9487",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9486",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9485",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9484",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9483",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9482",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6613400",
        "denominacao": "ADMINISTRACAO DE CARTOES DE CREDITO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTÃO DE CRÉDITO OU DÉBITO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10971",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11494",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11493",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11461",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11438",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11437",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9395",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9394",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9393",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9392",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9391",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9390",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9389",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9388",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9387",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9386",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9384",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9382",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9379",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9380",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "9381",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9396",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9397",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9399",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9401",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9403",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9412",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9411",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9410",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9409",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9408",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9407",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9406",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9405",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9404",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9429",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9428",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9427",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9426",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9425",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9424",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9423",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9422",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9421",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9432",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9431",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9430",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9420",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9419",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9418",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9417",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9416",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9415",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9414",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9413",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9402",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9400",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "9398",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9605",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9606",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9608",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9610",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9612",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9646",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9645",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9644",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9643",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9642",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9641",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9640",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9639",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9638",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11496",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11463",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "11440",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9657",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9656",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9655",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9654",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9653",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9652",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9651",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9650",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9649",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9648",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9647",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9637",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9636",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9635",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9634",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9633",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9632",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9631",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9621",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9620",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9619",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9618",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9617",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9616",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9615",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9614",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9613",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9630",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9629",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9628",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9627",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9626",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9625",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9624",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9623",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9622",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9611",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9609",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "9607",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9450",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9439",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9441",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9443",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9445",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "11460",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "11436",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9449",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9448",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9447",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9446",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9444",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9442",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9440",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9438",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9453",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9452",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "9451",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9454",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9472",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9471",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9470",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9469",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9468",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9467",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9466",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9465",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9464",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9463",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9462",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9461",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9460",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11498",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11464",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "11443",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9802",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9801",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9800",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9799",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9798",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9797",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9796",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9795",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9794",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9793",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9792",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9791",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9790",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9789",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9788",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9787",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9786",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9785",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9784",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9783",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9782",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9781",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9780",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9779",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9778",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9777",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9776",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9775",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9774",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9773",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9772",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9771",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9770",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9769",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9768",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9767",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9766",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9765",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9764",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9763",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9762",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9761",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9760",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9759",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9758",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9757",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9756",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9755",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9754",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9753",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9752",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9751",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9750",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9455",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9456",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9457",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9459",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "9458",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9514",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9519",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9521",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9555",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9554",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9553",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9552",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9551",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9550",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9549",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9548",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9547",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9562",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9561",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9560",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9559",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9558",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9557",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9556",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9546",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9545",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9544",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9543",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9542",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9541",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9540",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9530",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9893",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9892",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9891",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9890",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9889",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9888",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9529",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9528",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9527",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9526",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9525",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9524",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9523",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9522",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9578",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9577",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9576",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9575",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9574",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9573",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9572",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9571",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9570",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9595",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9594",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9593",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9592",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9591",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9590",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9589",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9588",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9587",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9604",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9603",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9602",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9601",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9600",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9599",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9598",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9597",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9596",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9586",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9585",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9584",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9583",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9582",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9581",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9580",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9579",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "11499",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "11495",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "11465",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "11462",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "11445",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "11439",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9887",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9886",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9885",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9884",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9883",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9882",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9881",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9880",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9879",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9878",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9877",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9876",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9875",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9874",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9873",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9872",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9871",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9870",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9869",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9868",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9867",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9866",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9865",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9864",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9863",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9862",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9861",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9860",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9859",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9858",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9857",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9856",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9855",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9854",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9853",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9852",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9851",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9850",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9849",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9848",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9847",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9846",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9845",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9844",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9843",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9842",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9841",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9569",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9568",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9567",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9566",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9565",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9564",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9563",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9539",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9538",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9537",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9536",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9535",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9534",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9533",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9532",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9531",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9520",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9518",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9515",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9516",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "9517",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9658",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9663",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9665",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9682",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9681",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9680",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9679",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9678",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9677",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9676",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9675",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9674",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9699",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9698",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9697",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9696",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9695",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9694",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9693",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9692",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9691",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9716",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9715",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9714",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9713",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9712",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9711",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9710",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9709",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9708",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "11497",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "11442",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "11441",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9733",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9732",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9731",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9730",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9729",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9728",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9727",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9726",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9725",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9749",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9748",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9747",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9746",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9745",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9744",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9743",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9742",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9741",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9740",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9739",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9738",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9737",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9736",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9735",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9734",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9724",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9723",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9722",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9721",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9720",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9719",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9718",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9717",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9707",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9706",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9705",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9704",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9703",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9702",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9701",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9700",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9690",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9689",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9688",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9687",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9686",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9685",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9684",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9683",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9673",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9672",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9671",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9670",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9669",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9668",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9667",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9666",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9664",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9662",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9659",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9660",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "9661",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9950",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9955",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9957",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9966",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9965",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9964",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9963",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9962",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9961",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9960",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9959",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9958",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "11501",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "11500",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "11466",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "11444",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "10003",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "10002",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "10001",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "10000",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9999",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9998",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9997",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9996",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9995",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9994",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9993",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9992",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9991",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9990",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9989",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9988",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9987",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9986",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9985",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9984",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9821",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9820",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9819",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9818",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9817",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9816",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9815",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9814",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9813",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9812",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9811",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9810",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9809",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9808",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9807",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9806",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9805",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9804",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9975",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9974",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9973",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9972",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9971",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9970",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9969",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9968",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9967",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9983",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9982",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9981",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9980",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9979",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9978",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9977",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9976",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9956",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9954",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9951",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9952",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "9953",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9894",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9899",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9901",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9949",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9948",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9947",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9946",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9945",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9944",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9943",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9942",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9941",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9940",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9939",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9938",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9937",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9918",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9830",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9829",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9828",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9827",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9826",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9825",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9824",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9823",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9822",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9840",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9839",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9838",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9837",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9836",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9835",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9834",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9833",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9832",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9831",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10183",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10182",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10181",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10180",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10179",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10178",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10177",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10176",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10175",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10323",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10322",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10321",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10320",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10319",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10318",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10317",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10316",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10315",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10314",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10313",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10312",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10311",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10310",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10309",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10308",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10307",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10306",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10305",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10304",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10303",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10302",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10301",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10201",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10200",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10199",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10198",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10197",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10196",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10195",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10194",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10193",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10192",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10191",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10190",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10189",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10188",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10187",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10186",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10185",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10184",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10174",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10173",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10172",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10171",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10170",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10169",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10168",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10167",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9917",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9916",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9915",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9914",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9913",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9912",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9911",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9910",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9927",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9926",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9925",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9924",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9923",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9922",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9921",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9920",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9919",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9936",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9935",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9934",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9933",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9932",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9931",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9930",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9929",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9928",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "11488",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "11486",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "11472",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "11469",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "11468",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "11450",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "11448",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10129",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10128",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10127",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10126",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10125",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10124",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10123",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10122",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10121",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10120",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10119",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10118",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10117",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10116",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10115",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10114",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10337",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10336",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10335",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10334",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10333",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10332",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10331",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10330",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10329",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10328",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10327",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10326",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10325",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10324",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9909",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9908",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9907",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9906",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9905",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9904",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9903",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9902",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9900",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9898",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9895",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9896",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "9897",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6492100",
        "denominacao": "SECURITIZACAO DE CREDITOS",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "9803",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10009",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10011",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10020",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10019",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10018",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10017",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10016",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10015",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10014",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10013",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10012",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10053",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10052",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10051",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10050",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10049",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10048",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10047",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10046",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10045",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "11487",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10070",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10069",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10068",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10067",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10066",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10065",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10064",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10063",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10062",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "11467",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "11447",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "11446",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10087",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10086",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10085",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10084",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10083",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10082",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10081",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10080",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10079",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10094",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10093",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10092",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10091",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10090",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10089",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10088",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10078",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10077",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10076",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10075",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10074",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10073",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10072",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10071",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10061",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10060",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10059",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10058",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10057",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10056",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10055",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10054",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10044",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10043",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10042",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10041",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10040",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10039",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10038",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10037",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10036",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10035",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10034",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10033",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10032",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10031",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10030",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10029",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10028",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10027",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10026",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10025",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10024",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10023",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10022",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10021",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10010",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10008",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10004",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10005",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10007",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10006",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10154",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10160",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "11449",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10153",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10152",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10151",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10150",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10149",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10148",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10147",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10146",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10145",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10144",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10143",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10142",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10141",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10140",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10139",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10138",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10137",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10136",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10135",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10134",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10133",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10132",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10131",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10130",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10166",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10165",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10164",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10163",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10162",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10161",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10159",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10155",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10156",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10157",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "10158",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10265",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10268",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10270",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10272",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10274",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10300",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10299",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10298",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10297",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10296",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10295",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10294",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10293",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10283",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10282",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10281",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10280",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10279",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10278",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10277",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10276",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10275",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10102",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10101",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10100",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10099",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10098",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10097",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10096",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10095",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10292",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10113",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10112",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10111",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10110",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10109",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10108",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10107",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10106",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10105",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10104",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10103",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "11474",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "11471",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "11451",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10597",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10596",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10595",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10594",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10593",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10592",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10591",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10590",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10589",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10588",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10587",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10586",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10585",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10584",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10583",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10582",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10581",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10580",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10579",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10578",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10577",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10576",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10575",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10574",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10573",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10572",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10571",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10570",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10569",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10568",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10567",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10566",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10565",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10564",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10563",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10562",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10561",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10291",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10290",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10289",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10288",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10287",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10286",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10285",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10284",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10273",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10271",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10269",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10267",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10266",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10338",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10343",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10345",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10362",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10361",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10360",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10359",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10358",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10357",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10356",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10355",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10354",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10371",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10370",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10369",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10368",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10367",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10366",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10365",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10364",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10363",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10353",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10352",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10351",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10350",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10349",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10348",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10347",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10346",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10380",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10379",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10378",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10377",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10376",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10375",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10374",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10373",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10372",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10397",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10396",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10395",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10394",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10393",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10392",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10391",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10390",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10389",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10413",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10412",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10411",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10410",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10409",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10408",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10407",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10406",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10405",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10404",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10403",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10402",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10401",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10400",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10399",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10398",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10388",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10387",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10386",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10385",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10384",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10383",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10382",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10381",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10344",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10342",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10339",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10340",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10341",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10708",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10713",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10715",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10748",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10747",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10746",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10745",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10744",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10743",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10742",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10741",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10740",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10739",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10738",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10737",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10736",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10735",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10734",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10733",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10732",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10731",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10730",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10729",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10728",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10727",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10726",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10725",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10724",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10723",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10722",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10721",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10720",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10719",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10718",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10717",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10716",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10763",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10762",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10761",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10760",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "11455",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10759",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10758",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10757",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10756",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10755",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10754",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10753",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10752",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10751",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10750",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10749",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10714",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10712",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10709",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10710",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10711",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10418",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10423",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10425",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10434",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10433",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10432",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10431",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10430",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10429",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10428",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10427",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10426",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10451",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10450",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10449",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10448",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10447",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10446",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10445",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10444",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10443",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10455",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10454",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10453",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10452",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10442",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10441",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10440",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10439",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10438",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10437",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10436",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10435",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10424",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10422",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10419",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10420",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10421",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10456",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10461",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10463",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10480",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10479",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10478",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10477",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10476",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10475",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10474",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10473",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10472",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10471",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10470",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10469",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10468",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10467",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10466",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10465",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10464",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "11490",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "11489",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "11473",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "11452",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10527",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10526",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10525",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10524",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10523",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10522",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10521",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10520",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10519",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10518",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10517",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10516",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10515",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10514",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10513",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10512",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10511",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10510",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10509",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10508",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10507",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10506",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10505",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10504",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10503",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10502",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10501",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10500",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10499",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10498",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10497",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10496",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10495",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10494",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10493",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10492",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10491",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10490",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10489",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10488",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10487",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10486",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10485",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10484",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10483",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10482",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10481",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10462",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10460",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10457",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10458",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10459",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10646",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10651",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10653",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10687",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10686",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10685",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10684",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10683",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10682",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10681",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10680",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10679",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10632",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10631",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10630",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10629",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10628",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10627",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10626",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10625",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10624",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "11492",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "11491",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "11476",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "11475",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10645",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10644",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10643",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10642",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10641",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10640",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10639",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10638",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10637",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10636",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10635",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10634",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10633",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10623",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10622",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10621",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10620",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10619",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10618",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10617",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10704",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10703",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10702",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10701",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10700",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10699",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10698",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10697",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10696",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10707",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10706",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10705",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10695",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10694",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10693",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10692",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10691",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10690",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10689",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10688",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10678",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10677",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10676",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10675",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10674",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10673",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6450600",
        "denominacao": "SOCIEDADES DE CAPITALIZACAO",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10672",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10662",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6434400",
        "denominacao": "AGENCIAS DE FOMENTO",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10661",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CREDITO IMOBILIARIO",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10660",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435202",
        "denominacao": "ASSOCIACOES DE POUPANCA E EMPRESTIMO",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10659",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6435203",
        "denominacao": "COMPANHIAS HIPOTECARIAS",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10658",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6436100",
        "denominacao": "SOCIEDADES DE CREDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10657",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6437900",
        "denominacao": "SOCIEDADES DE CREDITO AO MICROEMPREENDEDOR",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10656",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438701",
        "denominacao": "BANCOS DE CAMBIO",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10655",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6438799",
        "denominacao": "OUTRAS INSTITUICOES DE INTERMEDIACAO NAO-MONETARIA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10654",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10671",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10670",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONOMICAS",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10669",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10668",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10667",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10666",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10665",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MULTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10664",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10663",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MULTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10652",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10650",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CREDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10647",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424703",
        "denominacao": "COOPERATIVAS DE CREDITO MUTUO",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10648",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6424702",
        "denominacao": "COOPERATIVAS CENTRAIS DE CREDITO",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10649",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4923001",
        "denominacao": "SERVIÇO DE TÁXI",
        "descricao": "SERVIÇO DE TRANSPORTE DE NATUREZA MUNICIPAL (SERVIÇO DE TÁXI)",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2484",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4930201",
        "denominacao": "TRANSPORTE RODOVIÁRIO DE CARGA, EXCETO PRODUTOS PERIGOSOS E MUDANÇAS, MUNICIPAL",
        "descricao": "SERVIÇOS DE TRANSPORTE DE MATERIAIS DE CONST. E ENTULHOS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2489",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6029100",
        "denominacao": "TRANSPORTE REGULAR EM BONDES, FUNICULARES, TELEFÉRICOS OU TRENS PRÓPRIOS PARA EXPLORAÇÃO DE PONTOS TURÍSTICOS",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2491",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4921301",
        "denominacao": "TRANSPORTE RODOVIARIO COLETIVO DE PASSAGEIROS, COM ITINERARIO FIXO, MUNICIPAL",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL (TARIFA DO STCM)",
        "item": "16",
        "subitem": "1",
        "aliquota": ",01",
        "idcnae": "10913",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4930201",
        "denominacao": "TRANSPORTE RODOVIARIO DE CARGA, EXCETO PRODUTOS PERIGOSOS E MUDANCAS, MUNICIPAL",
        "descricao": "SERVIÇOS DE REBOQUE DE VEÍCULOS",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10912",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5229002",
        "denominacao": "SERVICOS DE REBOQUE DE VEICULOS",
        "descricao": "SERVIÇOS DE REBOQUE DE VEÍCULOS",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10911",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4940000",
        "denominacao": "TRANSPORTE DUTOVIARIO",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10789",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4950700",
        "denominacao": "TRENS TURISTICOS, TELEFERICOS E SIMILARES",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10788",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5011401",
        "denominacao": "TRANSPORTE MARITIMO DE CABOTAGEM - CARGA",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10787",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5011402",
        "denominacao": "TRANSPORTE MARITIMO DE CABOTAGEM - PASSAGEIROS",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10786",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5012202",
        "denominacao": "TRANSPORTE MARITIMO DE LONGO CURSO - PASSAGEIROS",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10785",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5012201",
        "denominacao": "TRANSPORTE MARITIMO DE LONGO CURSO - CARGA",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10784",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5021101",
        "denominacao": "TRANSPORTE POR NAVEGACAO INTERIOR DE CARGA, MUNICIPAL, EXCETO TRAVESSIA",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10783",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5022001",
        "denominacao": "TRANSPORTE POR NAVEGACAO INTERIOR DE PASSAGEIROS EM LINHAS REGULARES, MUNICIPAL, EXCETO TRAVESSIA",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10782",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5030101",
        "denominacao": "NAVEGACAO DE APOIO MARITIMO",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10781",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5030102",
        "denominacao": "NAVEGACAO DE APOIO PORTUARIO",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10780",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5091201",
        "denominacao": "TRANSPORTE POR NAVEGACAO DE TRAVESSIA, MUNICIPAL",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10779",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5099801",
        "denominacao": "TRANSPORTE AQUAVIARIO PARA PASSEIOS TURISTICOS",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10778",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5099899",
        "denominacao": "OUTROS TRANSPORTES AQUAVIARIOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10777",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5111100",
        "denominacao": "TRANSPORTE AEREO DE PASSAGEIROS REGULAR",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10776",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5112999",
        "denominacao": "OUTROS SERVICOS DE TRANSPORTE AEREO DE PASSAGEIROS NAO-REGULAR",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10775",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5120000",
        "denominacao": "TRANSPORTE AEREO DE CARGA",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10774",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4930201",
        "denominacao": "TRANSPORTE RODOVIARIO DE CARGA, EXCETO PRODUTOS PERIGOSOS E MUDANCAS, MUNICIPAL",
        "descricao": "SERVIÇO DE TRANSPORTE DE CARGAS E ENCOMENDAS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10773",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4930203",
        "denominacao": "TRANSPORTE RODOVIARIO DE PRODUTOS PERIGOSOS",
        "descricao": "SERVIÇO DE TRANSPORTE DE CARGAS E ENCOMENDAS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10772",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4930204",
        "denominacao": "TRANSPORTE RODOVIARIO DE MUDANCAS",
        "descricao": "SERVIÇO DE TRANSPORTE DE CARGAS E ENCOMENDAS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10771",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4921301",
        "denominacao": "TRANSPORTE RODOVIARIO COLETIVO DE PASSAGEIROS, COM ITINERARIO FIXO, MUNICIPAL",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10770",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4923001",
        "denominacao": "SERVICO DE TAXI",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10769",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4923002",
        "denominacao": "SERVICO DE TRANSPORTE DE PASSAGEIROS - LOCACAO DE AUTOMOVEIS COM MOTORISTA",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10768",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4924800",
        "denominacao": "TRANSPORTE ESCOLAR",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10767",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4929901",
        "denominacao": "TRANSPORTE RODOVIARIO COLETIVO DE PASSAGEIROS, SOB REGIME DE FRETAMENTO, MUNICIPAL",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10766",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4929903",
        "denominacao": "ORGANIZACAO DE EXCURSOES EM VEICULOS RODOVIARIOS PROPRIOS, MUNICIPAL",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10765",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4929999",
        "denominacao": "OUTROS TRANSPORTES RODOVIARIOS DE PASSAGEIROS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "10764",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9329899",
        "denominacao": "OUTRAS ATIVIDADES DE RECREAÇÃO E LAZER NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE PASSEIOS MARÍTIMOS",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2503",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5320201",
        "denominacao": "SERVIÇOS DE MALOTE NÃO REALIZADOS PELO CORREIO NACIONAL",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2499",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4930204",
        "denominacao": "TRANSPORTE RODOVIÁRIO DE MUDANÇAS",
        "descricao": "SERVIÇO DE TRANSPORTE DE MUDANÇAS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2493",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4940000",
        "denominacao": "TRANSPORTE DUTOVIÁRIO",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2492",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4930203",
        "denominacao": "TRANSPORTE RODOVIÁRIO DE PRODUTOS PERIGOSOS",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2490",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4930201",
        "denominacao": "TRANSPORTE RODOVIÁRIO DE CARGA, EXCETO PRODUTOS PERIGOSOS E MUDANÇAS, MUNICIPAL",
        "descricao": "SERVIÇO DE TRANSPORTE DE CARGAS E ENCOMENDAS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2488",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6025902",
        "denominacao": "LOCAÇÃO DE VEÍCULOS RODOVIÁRIOS DE PASSAGEIROS COM MOTORISTA, MUNICIPAL",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2485",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4929903",
        "denominacao": "ORGANIZAÇÃO DE EXCURSÕES EM VEÍCULOS RODOVIÁRIOS PRÓPRIOS, MUNICIPAL",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2486",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4924800",
        "denominacao": "TRANSPORTE ESCOLAR",
        "descricao": "SERVIÇO DE TRANSPORTE ESCOLAR DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2487",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7499399",
        "denominacao": "OUTROS SERVIÇOS PRESTADOS PRINCIPALMENTE ÀS EMPRESAS",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2508",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7119702",
        "denominacao": "ATIVIDADES DE ESTUDOS GEOLOGICOS",
        "descricao": "SERVIÇOS DE ANÁLISE, EXAME, PESQUISA, COLETA, COMPILAÇÃO E FORNECIMENTO DE DADOS E INFORMAÇÕES DE QUALQUER NATUREZA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11477",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5250805",
        "denominacao": "OPERADOR DE TRANSPORTE MULTIMODAL - OTM",
        "descricao": "SERVIÇOS DE ORGANIZAÇÃO LOGÍSTICA DE TRANSPORTE DE CARGAS",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10790",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3511500",
        "denominacao": "GERACAO DE ENERGIA ELETRICA",
        "descricao": "SERVIÇOS DE MEDIÇÃO DE CONSUMO DE ENERGIA ELÉTRICA, GÁS E ÁGUA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10259",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7020400",
        "denominacao": "ATIVIDADE DE CONSULTORIA EM GESTÃO EMPRESARIAL EXCETO CONSULTORIA TÉCNICA ESPECIFICA",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "7241",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5229099",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES TERRESTRES NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ORGANIZAÇÃO LOGÍSTICA DE TRANSPORTE DE CARGAS",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10791",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7320300",
        "denominacao": "PESQUISAS DE MERCADO E DE OPINIAO PUBLICA",
        "descricao": "SERVIÇOS DE ANÁLISE, EXAME, PESQUISA, COLETA, COMPILAÇÃO E FORNECIMENTO DE DADOS E INFORMAÇÕES DE QUALQUER NATUREZA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10793",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7319004",
        "denominacao": "CONSULTORIA EM PUBLICIDADE",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10802",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7490103",
        "denominacao": "SERVICOS DE AGRONOMIA E DE CONSULTORIA AS ATIVIDADES AGRICOLAS E PECUARIAS",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10801",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7490199",
        "denominacao": "OUTRAS ATIVIDADES PROFISSIONAIS, CIENTIFICAS E TECNICAS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10800",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7911200",
        "denominacao": "AGENCIAS DE VIAGENS",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10799",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8550302",
        "denominacao": "ATIVIDADES DE APOIO A EDUCACAO, EXCETO CAIXAS ESCOLARES",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10798",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7020400",
        "denominacao": "ATIVIDADES DE CONSULTORIA EM GESTAO EMPRESARIAL, EXCETO CONSULTORIA TECNICA ESPECIFICA",
        "descricao": "SERVIÇOS DE RELAÇÕES PÚBLICAS",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10797",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7020400",
        "denominacao": "ATIVIDADES DE CONSULTORIA EM GESTAO EMPRESARIAL, EXCETO CONSULTORIA TECNICA ESPECIFICA",
        "descricao": "SERVIÇOS DE PLANEJAMENTO FINANCEIRO OU ADMINISTRATIVO",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10796",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7210000",
        "denominacao": "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIENCIAS FISICAS E NATURAIS",
        "descricao": "SERVIÇOS DE ANÁLISE, EXAME, PESQUISA, COLETA, COMPILAÇÃO E FORNECIMENTO DE DADOS E INFORMAÇÕES DE QUALQUER NATUREZA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10795",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7220700",
        "denominacao": "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIENCIAS SOCIAIS E HUMANAS",
        "descricao": "SERVIÇOS DE ANÁLISE, EXAME, PESQUISA, COLETA, COMPILAÇÃO E FORNECIMENTO DE DADOS E INFORMAÇÕES DE QUALQUER NATUREZA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10794",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6621502",
        "denominacao": "AUDITORIA E CONSULTORIA ATUARIAL",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10808",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5232000",
        "denominacao": "ATIVIDADES DE AGENCIAMENTO MARITIMO",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10811",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5250801",
        "denominacao": "COMISSARIA DE DESPACHOS",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10810",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6911701",
        "denominacao": "SERVICOS ADVOCATICIOS",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10807",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6920602",
        "denominacao": "ATIVIDADES DE CONSULTORIA E AUDITORIA CONTABIL E TRIBUTARIA",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10806",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7020400",
        "denominacao": "ATIVIDADES DE CONSULTORIA EM GESTAO EMPRESARIAL, EXCETO CONSULTORIA TECNICA ESPECIFICA",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10805",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7111100",
        "denominacao": "SERVICOS DE ARQUITETURA",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10804",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10803",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9101500",
        "denominacao": "ATIVIDADES DE BIBLIOTECAS E ARQUIVOS",
        "descricao": "SERVIÇOS DE ANÁLISE, EXAME, PESQUISA, COLETA, COMPILAÇÃO E FORNECIMENTO DE DADOS E INFORMAÇÕES DE QUALQUER NATUREZA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10792",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7490101",
        "denominacao": "SERVIÇOS DE TRADUÇÃO, INTERPRETAÇÃO E SIMILARES",
        "descricao": "SERVIÇOS DE REDAÇÃO, REVISÃO E EDIÇÃO",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "2509",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6311900",
        "denominacao": "TRATAMENTO DE DADOS, PROVEDORES DE SERVIÇOS DE APLICAÇÃO E SERVIÇOS DE HOSPEDAGEM NA INTERNET",
        "descricao": "SERVIÇOS DE DIGITAÇÃO",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "2512",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7499399",
        "denominacao": "OUTROS SERVIÇOS PRESTADOS PRINCIPALMENTE ÀS EMPRESAS",
        "descricao": "SERVIÇOS DE APOIO E INFRAESTRUTURA ADMINISTRATIVA",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "2513",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7490101",
        "denominacao": "SERVICOS DE TRADUCAO, INTERPRETACAO E SIMILARES",
        "descricao": "SERVIÇOS DE REDAÇÃO, REVISÃO E EDIÇÃO",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "10812",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8219999",
        "denominacao": "PREPARACAO DE DOCUMENTOS E SERVICOS ESPECIALIZADOS DE APOIO ADMINISTRATIVO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE SECRETARIA",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "10814",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8219999",
        "denominacao": "PREPARACAO DE DOCUMENTOS E SERVICOS ESPECIALIZADOS DE APOIO ADMINISTRATIVO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE DIGITAÇÃO",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "10816",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8219999",
        "denominacao": "PREPARACAO DE DOCUMENTOS E SERVICOS ESPECIALIZADOS DE APOIO ADMINISTRATIVO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE APOIO E INFRAESTRUTURA ADMINISTRATIVA",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "10818",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8219999",
        "denominacao": "PREPARACAO DE DOCUMENTOS E SERVICOS ESPECIALIZADOS DE APOIO ADMINISTRATIVO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE DATILOGRAFIA",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "10820",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8219999",
        "denominacao": "PREPARACAO DE DOCUMENTOS E SERVICOS ESPECIALIZADOS DE APOIO ADMINISTRATIVO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE RESPOSTAS AUDÍVEIS",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "10822",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8219999",
        "denominacao": "PREPARACAO DE DOCUMENTOS E SERVICOS ESPECIALIZADOS DE APOIO ADMINISTRATIVO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EXPEDIENTE",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "10821",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8299707",
        "denominacao": "SALAS DE ACESSO A INTERNET",
        "descricao": "SERVIÇOS DE APOIO E INFRAESTRUTURA ADMINISTRATIVA",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "10819",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6311900",
        "denominacao": "TRATAMENTO DE DADOS, PROVEDORES DE SERVICOS DE APLICACAO E SERVICOS DE HOSPEDAGEM NA INTERNET",
        "descricao": "SERVIÇOS DE DIGITAÇÃO",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "10817",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8299707",
        "denominacao": "SALAS DE ACESSO A INTERNET",
        "descricao": "SERVIÇOS DE DIGITAÇÃO",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "10815",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7490101",
        "denominacao": "SERVICOS DE TRADUCAO, INTERPRETACAO E SIMILARES",
        "descricao": "SERVIÇOS DE TRADUÇÕES E INTERPRETAÇÕES",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "10813",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8219999",
        "denominacao": "PREPARACAO DE DOCUMENTOS E SERVICOS ESPECIALIZADOS DE APOIO ADMINISTRATIVO NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS ESTENOGRAFIA",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "11505",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7490101",
        "denominacao": "SERVIÇOS DE TRADUÇÃO, INTERPRETAÇÃO E SIMILARES",
        "descricao": "SERVIÇOS DE TRADUÇÕES E INTERPRETAÇÕES",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "2510",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7416002",
        "denominacao": "ATIVIDADES DE ASSESSORIA EM GESTÃO EMPRESARIAL",
        "descricao": "SERVIÇOS DE PLANEJAMENTO, COORDENAÇÃO, PROGRAMAÇÃO OU ORGANIZAÇÃO TÉCNICA, FINANCEIRA OU ADMINISTRATIVA",
        "item": "17",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "2518",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7020400",
        "denominacao": "ATIVIDADES DE CONSULTORIA EM GESTAO EMPRESARIAL, EXCETO CONSULTORIA TECNICA ESPECIFICA",
        "descricao": "SERVIÇOS DE PLANEJAMENTO, COORDENAÇÃO, PROGRAMAÇÃO OU ORGANIZAÇÃO TÉCNICA, FINANCEIRA OU ADMINISTRATIVA",
        "item": "17",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "10823",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7450001",
        "denominacao": "SELEÇÃO E AGENCIAMENTO DE MÃO-DE-OBRA",
        "descricao": "SERVIÇOS DE RECRUTAMENTO, AGENCIAMENTO, SELEÇÃO E COLOCAÇÃO DE MÃO-DE-OBRA",
        "item": "17",
        "subitem": "4",
        "aliquota": "2,5",
        "idcnae": "2519",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7810800",
        "denominacao": "SELECAO E AGENCIAMENTO DE MAO-DE-OBRA",
        "descricao": "SERVIÇOS DE RECRUTAMENTO, AGENCIAMENTO, SELEÇÃO E COLOCAÇÃO DE MÃO-DE-OBRA",
        "item": "17",
        "subitem": "4",
        "aliquota": "2,5",
        "idcnae": "10824",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7820500",
        "denominacao": "LOCAÇÃO DE MÃO-DE-OBRA TEMPORÁRIA",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE MÃO-DE-OBRA",
        "item": "17",
        "subitem": "5",
        "aliquota": "2,5",
        "idcnae": "2520",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7820500",
        "denominacao": "LOCACAO DE MAO-DE-OBRA TEMPORARIA",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE MÃO-DE-OBRA",
        "item": "17",
        "subitem": "5",
        "aliquota": "2,5",
        "idcnae": "10825",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7830200",
        "denominacao": "FORNECIMENTO E GESTAO DE RECURSOS HUMANOS PARA TERCEIROS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE MÃO-DE-OBRA",
        "item": "17",
        "subitem": "5",
        "aliquota": "2,5",
        "idcnae": "10826",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7311400",
        "denominacao": "AGÊNCIAS DE PUBLICIDADE",
        "descricao": "SERVIÇOS DE PUBLICIDADE E PROPAGANDA",
        "item": "17",
        "subitem": "6",
        "aliquota": "2,5",
        "idcnae": "2521",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7311400",
        "denominacao": "AGENCIAS DE PUBLICIDADE",
        "descricao": "SERVIÇOS DE PUBLICIDADE E PROPAGANDA",
        "item": "17",
        "subitem": "6",
        "aliquota": "2,5",
        "idcnae": "10827",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7740300",
        "denominacao": "GESTAO DE ATIVOS INTANGIVEIS NAO-FINANCEIROS",
        "descricao": "SERVIÇOS DE FRANQUIAS (FRANCHISING)",
        "item": "17",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "11478",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7416002",
        "denominacao": "ATIVIDADES DE ASSESSORIA EM GESTÃO EMPRESARIAL",
        "descricao": "SERVIÇOS DE PERÍCIAS, LAUDOS, EXAMES TÉCNICOS E ANÁLISES TÉCNICAS",
        "item": "17",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "2523",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6621501",
        "denominacao": "PERITOS E AVALIADORES DE SEGUROS",
        "descricao": "SERVIÇOS DE PERÍCIAS, LAUDOS, EXAMES TÉCNICOS E ANÁLISES TÉCNICAS",
        "item": "17",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "10831",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6911702",
        "denominacao": "ATIVIDADES AUXILIARES DA JUSTICA",
        "descricao": "SERVIÇOS DE PERÍCIAS, LAUDOS, EXAMES TÉCNICOS E ANÁLISES TÉCNICAS",
        "item": "17",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "10830",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7119704",
        "denominacao": "SERVICOS DE PERICIA TECNICA RELACIONADOS A SEGURANCA DO TRABALHO",
        "descricao": "SERVIÇOS DE PERÍCIAS, LAUDOS, EXAMES TÉCNICOS E ANÁLISES TÉCNICAS",
        "item": "17",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "10828",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS DE PERÍCIAS, LAUDOS, EXAMES TÉCNICOS E ANÁLISES TÉCNICAS",
        "item": "17",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "10829",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8230001",
        "denominacao": "SERVIÇOS DE ORGANIZAÇÃO DE FEIRAS, CONGRESSOS, EXPOSIÇÕES E FESTAS",
        "descricao": "SERVIÇOS DE PLANEJAMENTO, ORGANIZAÇÃO E ADMINISTRAÇÃO DE FEIRAS, EXPOSIÇÕES E CONGRESSOS",
        "item": "17",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2524",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8230001",
        "denominacao": "SERVICOS DE ORGANIZACAO DE FEIRAS, CONGRESSOS, EXPOSICOES E FESTAS",
        "descricao": "SERVIÇOS DE PLANEJAMENTO, ORGANIZAÇÃO E ADMINISTRAÇÃO DE FEIRAS, EXPOSIÇÕES E CONGRESSOS",
        "item": "17",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "10832",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5620102",
        "denominacao": "SERVIÇOS DE ALIMENTAÇÃO PARA EVENTOS E RECEPÇÕES - BUFÊ",
        "descricao": "SERVIÇOS DE BUFÊ",
        "item": "17",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2525",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8230001",
        "denominacao": "SERVIÇOS DE ORGANIZAÇÃO DE FEIRAS, CONGRESSOS, EXPOSIÇÕES E FESTAS",
        "descricao": "SERVIÇOS DE ORGANIZAÇÃO DE FESTAS E RECEPÇÕES",
        "item": "17",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2526",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5620102",
        "denominacao": "SERVICOS DE ALIMENTACAO PARA EVENTOS E RECEPCOES - BUFE",
        "descricao": "SERVIÇOS DE BUFÊ",
        "item": "17",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10833",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8230001",
        "denominacao": "SERVICOS DE ORGANIZACAO DE FEIRAS, CONGRESSOS, EXPOSICOES E FESTAS",
        "descricao": "SERVIÇOS DE ORGANIZAÇÃO DE FESTAS E RECEPÇÕES",
        "item": "17",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10834",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8230002",
        "denominacao": "CASAS DE FESTAS E EVENTOS",
        "descricao": "SERVIÇOS DE ORGANIZAÇÃO DE FESTAS E RECEPÇÕES",
        "item": "17",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "10835",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6593502",
        "denominacao": "GESTÃO DE DIREITOS AUTORAIS",
        "descricao": "SERVIÇOS DE GESTÃO DE DIREITOS AUTORAIS",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "2419",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7032700",
        "denominacao": "ADMINISTRAÇÃO DE IMÓVEIS POR CONTA DE TERCEIROS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "2424",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6611803",
        "denominacao": "BOLSA DE MERCADORIAS E FUTUROS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "2422",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6911703",
        "denominacao": "AGENTE DE PROPRIEDADE INDUSTRIAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "10840",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7740300",
        "denominacao": "GESTAO DE ATIVOS INTANGIVEIS NAO-FINANCEIROS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "10839",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8299702",
        "denominacao": "EMISSAO DE VALES-ALIMENTACAO, VALES-TRANSPORTE E SIMILARES",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "10838",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8299799",
        "denominacao": "OUTRAS ATIVIDADES DE SERVICOS PRESTADOS PRINCIPALMENTE AS EMPRESAS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "10837",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8411600",
        "denominacao": "ADMINISTRACAO PUBLICA EM GERAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "10836",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6630400",
        "denominacao": "ATIVIDADES DE ADMINISTRACAO DE FUNDOS POR CONTRATO OU COMISSAO",
        "descricao": "SERVIÇOS DE GESTÃO DE FUNDOS",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "10264",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5811500",
        "denominacao": "EDICAO DE LIVROS",
        "descricao": "SERVIÇOS DE GESTÃO DE DIREITOS AUTORAIS",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "10263",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5913800",
        "denominacao": "DISTRIBUICAO CINEMATOGRAFICA, DE VIDEO E DE PROGRAMAS DE TELEVISAO",
        "descricao": "SERVIÇOS DE GESTÃO DE DIREITOS AUTORAIS",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "10262",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5920100",
        "denominacao": "ATIVIDADES DE GRAVACAO DE SOM E DE EDICAO DE MUSICA",
        "descricao": "SERVIÇOS DE GESTÃO DE DIREITOS AUTORAIS",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "10261",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6822600",
        "denominacao": "GESTAO E ADMINISTRACAO DA PROPRIEDADE IMOBILIARIA",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONDOMÍNIOS",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "10857",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6440900",
        "denominacao": "ARRENDAMENTO MERCANTIL",
        "descricao": "SERVIÇOS DE LICENCIAMENTO, COMPRA E VENDA E LEASING DE ATIVOS INTANGÍVEIS NÃO FINANCEIROS",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "10856",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4399101",
        "denominacao": "ADMINISTRACAO DE OBRAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "10855",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5221400",
        "denominacao": "CONCESSIONARIAS DE RODOVIAS, PONTES, TUNEIS E SERVICOS RELACIONADOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "10854",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5231101",
        "denominacao": "ADMINISTRACAO DA INFRA-ESTRUTURA PORTUARIA",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "10853",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5510801",
        "denominacao": "HOTEIS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "10852",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5920100",
        "denominacao": "ATIVIDADES DE GRAVACAO DE SOM E DE EDICAO DE MUSICA",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "10851",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6462000",
        "denominacao": "HOLDINGS DE INSTITUICOES NAO-FINANCEIRAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "10850",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6611801",
        "denominacao": "BOLSA DE VALORES",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "10849",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6611804",
        "denominacao": "ADMINISTRACAO DE MERCADOS DE BALCAO ORGANIZADOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "10848",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6612601",
        "denominacao": "CORRETORAS DE TITULOS E VALORES MOBILIARIOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "10847",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6613400",
        "denominacao": "ADMINISTRACAO DE CARTOES DE CREDITO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "10846",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6629100",
        "denominacao": "ATIVIDADES AUXILIARES DOS SEGUROS, DA PREVIDENCIA COMPLEMENTAR E DOS PLANOS DE SAUDE NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "10845",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6630400",
        "denominacao": "ATIVIDADES DE ADMINISTRACAO DE FUNDOS POR CONTRATO OU COMISSAO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "10844",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6810202",
        "denominacao": "ALUGUEL DE IMOVEIS PROPRIOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "10843",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6822600",
        "denominacao": "GESTAO E ADMINISTRACAO DA PROPRIEDADE IMOBILIARIA",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "10842",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6911702",
        "denominacao": "ATIVIDADES AUXILIARES DA JUSTICA",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "10841",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9311500",
        "denominacao": "GESTÃO DE INSTALAÇÕES DE ESPORTES",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "2527",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6611804",
        "denominacao": "ADMINISTRAÇÃO DE MERCADOS DE BALCÃO ORGANIZADOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "2423",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8299704",
        "denominacao": "LEILOEIROS INDEPENDENTES",
        "descricao": "SERVIÇOS DE REALIZAÇÕES DE LEILÕES",
        "item": "17",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "10858",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6911701",
        "denominacao": "SERVIÇOS ADVOCATÍCIOS",
        "descricao": "SERVIÇOS ADVOCATÍCIOS",
        "item": "17",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2429",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6911701",
        "denominacao": "SERVICOS ADVOCATICIOS",
        "descricao": "SERVIÇOS ADVOCATÍCIOS",
        "item": "17",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "10859",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6911702",
        "denominacao": "ATIVIDADES AUXILIARES DA JUSTIÇA",
        "descricao": "SERVIÇOS DE ARBITRAGEM",
        "item": "17",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "2430",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6911702",
        "denominacao": "ATIVIDADES AUXILIARES DA JUSTICA",
        "descricao": "SERVIÇOS DE ARBITRAGEM",
        "item": "17",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "10860",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6920602",
        "denominacao": "ATIVIDADES DE CONSULTORIA E AUDITORIA CONTÁBIL E TRIBUTÁRIA",
        "descricao": "SERVIÇOS DE AUDITORIA ",
        "item": "17",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "2431",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6621502",
        "denominacao": "AUDITORIA E CONSULTORIA ATUARIAL",
        "descricao": "SERVIÇOS DE AUDITORIA ",
        "item": "17",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10861",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6920602",
        "denominacao": "ATIVIDADES DE CONSULTORIA E AUDITORIA CONTABIL E TRIBUTARIA",
        "descricao": "SERVIÇOS DE AUDITORIA ",
        "item": "17",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10862",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6621501",
        "denominacao": "PERITOS E AVALIADORES DE SEGUROS",
        "descricao": "SERVIÇOS DE AUDITORIA ",
        "item": "17",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "10863",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7416002",
        "denominacao": "ATIVIDADES DE ASSESSORIA EM GESTÃO EMPRESARIAL",
        "descricao": "SERVIÇOS DE ANÁLISE DE ORGANIZAÇÃO E MÉTODOS",
        "item": "17",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2432",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7020400",
        "denominacao": "ATIVIDADES DE CONSULTORIA EM GESTAO EMPRESARIAL, EXCETO CONSULTORIA TECNICA ESPECIFICA",
        "descricao": "SERVIÇOS DE ANÁLISE DE ORGANIZAÇÃO E MÉTODOS",
        "item": "17",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "10864",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6621502",
        "denominacao": "AUDITORIA E CONSULTORIA ATUARIAL",
        "descricao": "SERVIÇOS ATUARIAIS E CÁLCULOS TÉCNICOS DE QUALQUER NATUREZA",
        "item": "17",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "10865",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6920601",
        "denominacao": "ATIVIDADES DE CONTABILIDADE",
        "descricao": "SERVIÇOS DE CONTABILIDADE",
        "item": "17",
        "subitem": "19",
        "aliquota": "2,5",
        "idcnae": "2434",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6920601",
        "denominacao": "ATIVIDADES DE CONTABILIDADE",
        "descricao": "SERVIÇOS DE CONTABILIDADE",
        "item": "17",
        "subitem": "19",
        "aliquota": "2,5",
        "idcnae": "10866",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7416002",
        "denominacao": "ATIVIDADES DE ASSESSORIA EM GESTÃO EMPRESARIAL",
        "descricao": "SERVIÇOS DE CONSULTORIA E ASSESSORIA ECONÔMICA OU FINANCEIRA",
        "item": "17",
        "subitem": "20",
        "aliquota": "5",
        "idcnae": "2435",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7320300",
        "denominacao": "PESQUISAS DE MERCADO E DE OPINIÃO PÚBLICA",
        "descricao": "SERVIÇOS DE PESQUISA DE MERCADO E OPINIÃO",
        "item": "17",
        "subitem": "21",
        "aliquota": "5",
        "idcnae": "2436",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7320300",
        "denominacao": "PESQUISAS DE MERCADO E DE OPINIAO PUBLICA",
        "descricao": "SERVIÇOS DE PESQUISA DE MERCADO E OPINIÃO",
        "item": "17",
        "subitem": "21",
        "aliquota": "5",
        "idcnae": "10868",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7320300",
        "denominacao": "PESQUISAS DE MERCADO E DE OPINIAO PUBLICA",
        "descricao": "SERVIÇOS DE ESTATÍSTICA  ",
        "item": "17",
        "subitem": "21",
        "aliquota": "5",
        "idcnae": "10870",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7490199",
        "denominacao": "OUTRAS ATIVIDADES PROFISSIONAIS, CIENTIFICAS E TECNICAS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ESTATÍSTICA  ",
        "item": "17",
        "subitem": "21",
        "aliquota": "5",
        "idcnae": "10869",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8291100",
        "denominacao": "ATIVIDADES DE COBRANÇAS E INFORMAÇÕES CADASTRAIS",
        "descricao": "SERVIÇOS DE  COBRANÇA",
        "item": "17",
        "subitem": "22",
        "aliquota": "5",
        "idcnae": "2438",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6491300",
        "denominacao": "SOCIEDADES DE FOMENTO MERCANTIL - FACTORING",
        "descricao": "SERVIÇOS DE FACTORING",
        "item": "17",
        "subitem": "23",
        "aliquota": "5",
        "idcnae": "10871",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8599604",
        "denominacao": "TREINAMENTO EM DESENVOLVIMENTO PROFISSIONAL E GERENCIAL",
        "descricao": "SERVIÇOS DE APRESENTAÇÃO DE PALESTRAS, CONFERÊNCIAS E SEMINÁRIOS",
        "item": "17",
        "subitem": "24",
        "aliquota": "5",
        "idcnae": "2440",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8599604",
        "denominacao": "TREINAMENTO EM DESENVOLVIMENTO PROFISSIONAL E GERENCIAL",
        "descricao": "SERVIÇOS DE APRESENTAÇÃO DE PALESTRAS, CONFERÊNCIAS E SEMINÁRIOS",
        "item": "17",
        "subitem": "24",
        "aliquota": "5",
        "idcnae": "10872",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6621501",
        "denominacao": "PERITOS E AVALIADORES DE SEGUROS",
        "descricao": "SERVIÇOS DE INSPEÇÃO E AVALIAÇÃO DE RISCOS PARA COBERTURA DE CONTRATOS DE SEGUROS",
        "item": "18",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10873",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6621501",
        "denominacao": "PERITOS E AVALIADORES DE SEGUROS",
        "descricao": "SERVIÇOS DE REGULAÇÃO DE SINISTROS VINCULADOS À CONTRATOS DE SEGUROS  ",
        "item": "18",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10874",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6621501",
        "denominacao": "PERITOS E AVALIADORES DE SEGUROS",
        "descricao": "SERVIÇOS DE PREVENÇÃO E GERÊNCIA DE RISCOS SEGURÁVEIS",
        "item": "18",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10875",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8299706",
        "denominacao": "CASAS LOTERICAS",
        "descricao": "SERVIÇOS DE DISTRIBUIÇÃO DE BILHETES E DEMAIS PRODUTOS DE LOTERIA, BINGOS, PULES OU CUPONS DE APOSTAS, CARTÕES, SORTEIOS, APOSTAS E PRÊMIOS",
        "item": "19",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10876",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5232000",
        "denominacao": "ATIVIDADES DE AGENCIAMENTO MARITIMO",
        "descricao": "SERVIÇOS DE ARMADORES",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11479",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5231102",
        "denominacao": "OPERACOES DE TERMINAIS",
        "descricao": "SERVIÇOS PORTUÁRIOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10878",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5231101",
        "denominacao": "ADMINISTRACAO DA INFRA-ESTRUTURA PORTUARIA",
        "descricao": "SERVIOS DE UTILIZAÇÃO DE PORTOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10880",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5030101",
        "denominacao": "NAVEGACAO DE APOIO MARITIMO",
        "descricao": "SERVIÇOS DE REBOQUE DE EMBARCAÇÕES",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10882",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5231102",
        "denominacao": "OPERACOES DE TERMINAIS",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO AO LARGO",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10894",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5239700",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIARIOS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE PRATICAGEM",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10893",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5231102",
        "denominacao": "OPERACOES DE TERMINAIS",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO DE PASSAGEIROS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10892",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5231102",
        "denominacao": "OPERACOES DE TERMINAIS",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO DE MERCADORIAS EM PORTOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10891",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5030101",
        "denominacao": "NAVEGACAO DE APOIO MARITIMO",
        "descricao": "SERVIÇOS DE LOGISTICA E CONFERÊNCIAS EM PORTOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10890",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5212500",
        "denominacao": "CARGA E DESCARGA",
        "descricao": "SERVIÇOS DE ESTIVA",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10889",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5212500",
        "denominacao": "CARGA E DESCARGA",
        "descricao": "SERVIÇOS DE CAPATAZIA",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10888",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5239700",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIARIOS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ATRACAÇÃO E DESATRACAÇÃO",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10887",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5211799",
        "denominacao": "DEPOSITOS DE MERCADORIAS PARA TERCEIROS, EXCETO ARMAZENS GERAIS E GUARDA-MOVEIS",
        "descricao": "SERVIÇOS DE ARMAZENAGEM EM PORTOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10886",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5211701",
        "denominacao": "ARMAZENS GERAIS - EMISSAO DE WARRANT",
        "descricao": "SERVIÇOS DE ARMAZENAGEM EM PORTOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10885",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5231102",
        "denominacao": "OPERACOES DE TERMINAIS",
        "descricao": "SERVIÇOS DE ARMAZENAGEM EM PORTOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10884",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5030101",
        "denominacao": "NAVEGACAO DE APOIO MARITIMO",
        "descricao": "SERVIÇOS DE APOIO MARÍTIMO",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10883",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5231102",
        "denominacao": "OPERACOES DE TERMINAIS",
        "descricao": "SERVIOS DE UTILIZAÇÃO DE PORTOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10881",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5231101",
        "denominacao": "ADMINISTRACAO DA INFRA-ESTRUTURA PORTUARIA",
        "descricao": "SERVIÇOS PORTUÁRIOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10879",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5222200",
        "denominacao": "TERMINAIS RODOVIARIOS E FERROVIARIOS",
        "descricao": "SERVIÇOS FERROPORTUÁRIOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10877",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5030101",
        "denominacao": "NAVEGACAO DE APOIO MARITIMO",
        "descricao": "SERVIÇOS DE REBOCADOR ESCOTEIRO",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11506",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5240101",
        "denominacao": "OPERACAO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE UTILIZAÇÃO DE AEROPORTOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "11456",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5240199",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AEREOS, EXCETO OPERACAO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE UTILIZAÇÃO DE AEROPORTOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "10898",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5240101",
        "denominacao": "OPERACAO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE APOIO AEROPORTUÁRIOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "10900",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5240199",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AEREOS, EXCETO OPERACAO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE LOGISTICA E CONFERÊNCIAS EM AEROPORTOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "10902",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5212500",
        "denominacao": "CARGA E DESCARGA",
        "descricao": "SERVIÇOS DE CAPATAZIA",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "10907",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5240199",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AEREOS, EXCETO OPERACAO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO DE PASSAGEIROS EM AEROPORTOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "10906",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5240199",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AEREOS, EXCETO OPERACAO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO DE MERCADORIAS EM AEROPORTOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "10905",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5240199",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AEREOS, EXCETO OPERACAO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO DE AERONAVES",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "10904",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5240101",
        "denominacao": "OPERACAO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE LOGISTICA E CONFERÊNCIAS EM AEROPORTOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "10903",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5240199",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AEREOS, EXCETO OPERACAO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE ARMAZENAGEM EM AEROPORTOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "10901",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5240199",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AEREOS, EXCETO OPERACAO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE APOIO AEROPORTUÁRIOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "10899",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5240101",
        "denominacao": "OPERACAO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO AEROPORTUÁRIA",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "10897",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5240199",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AEREOS, EXCETO OPERACAO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS AEROPORTUÁRIOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "10895",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5240101",
        "denominacao": "OPERACAO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS AEROPORTUÁRIOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "10896",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5229099",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES TERRESTRES NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO DE MERCADORIAS EM  TERMINAIS RODOVIÁRIOS, FERROVIÁRIOS E METROVIÁRIOS",
        "item": "20",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "10251",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4912403",
        "denominacao": "TRANSPORTE METROVIARIO",
        "descricao": "SERVIÇOS DE TERMINAIS METROVIÁRIOS",
        "item": "20",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "10252",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5229099",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES TERRESTRES NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE TERMINAIS METROVIÁRIOS",
        "item": "20",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "10254",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5229099",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES TERRESTRES NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO DE PASSAGEIROS EM  TERMINAIS RODOVIÁRIOS, FERROVIÁRIOS E METROVIÁRIOS",
        "item": "20",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "10910",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5222200",
        "denominacao": "TERMINAIS RODOVIARIOS E FERROVIARIOS",
        "descricao": "SERVIÇOS DE TERMINAIS RODOVIÁRIOS",
        "item": "20",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "10909",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5222200",
        "denominacao": "TERMINAIS RODOVIARIOS E FERROVIARIOS",
        "descricao": "SERVIÇOS DE TERMINAIS FERROVIÁRIOS",
        "item": "20",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "10908",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "4912402",
        "denominacao": "TRANSPORTE FERROVIARIO DE PASSAGEIROS MUNICIPAL E EM REGIAO METROPOLITANA",
        "descricao": "SERVIÇOS DE TERMINAIS METROVIÁRIOS",
        "item": "20",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "10253",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6912500",
        "denominacao": "CARTORIOS",
        "descricao": "SERVIÇOS DE REGISTROS PÚBLICOS, CARTORIAIS E NOTORIAIS",
        "item": "21",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10255",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5221400",
        "denominacao": "CONCESSIONARIAS DE RODOVIAS, PONTES, TUNEIS E SERVICOS RELACIONADOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE RODOVIA MEDIANTE COBRANÇA DE PREÇO OU PEDÁGIO DOS USUÁRIOS",
        "item": "22",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10256",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7119703",
        "denominacao": "SERVIÇOS DE DESENHO TÉCNICO RELACIONADOS À ARQUITETURA E ENGENHARIA",
        "descricao": "SERVIÇOS DE DESENHO INDUSTRIAL",
        "item": "23",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2413",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7410201",
        "denominacao": "DESIGN",
        "descricao": "SERVIÇOS DE DESENHO INDUSTRIAL",
        "item": "23",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11503",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7311400",
        "denominacao": "AGENCIAS DE PUBLICIDADE",
        "descricao": "SERVIÇOS DE PROGRAMAÇÃO E COMUNICAÇÃO VISUAL",
        "item": "23",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10914",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7440399",
        "denominacao": "OUTROS SERVIÇOS DE PUBLICIDADE",
        "descricao": "SERVIÇOS DE PROGRAMAÇÃO E COMUNICAÇÃO VISUAL",
        "item": "23",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2359",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3699499",
        "denominacao": "FABRICAÇÃO DE PRODUTOS DIVERSOS",
        "descricao": "SERVIÇOS DE CONFECÇÃO DE PLACAS, SINALIZAÇÃO VISUAL, BANNERS, ADESIVO E CONGÊNERES",
        "item": "24",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2363",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8299799",
        "denominacao": "OUTRAS ATIVIDADES DE SERVICOS PRESTADOS PRINCIPALMENTE AS EMPRESAS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE CONFECÇÃO DE CARIMBOS",
        "item": "24",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10916",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3299099",
        "denominacao": "FABRICACAO DE PRODUTOS DIVERSOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE CHAVEIROS",
        "item": "24",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10918",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9529199",
        "denominacao": "REPARACAO E MANUTENCAO DE OUTROS OBJETOS E EQUIPAMENTOS PESSOAIS E DOMESTICOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE GRAVAÇÃO EM OBJETOS",
        "item": "24",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10920",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3299099",
        "denominacao": "FABRICACAO DE PRODUTOS DIVERSOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE CONFECÇÃO DE PLACAS, SINALIZAÇÃO VISUAL, BANNERS, ADESIVO E CONGÊNERES",
        "item": "24",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10919",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9529102",
        "denominacao": "CHAVEIROS",
        "descricao": "SERVIÇOS DE CHAVEIROS",
        "item": "24",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10917",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9529102",
        "denominacao": "CHAVEIROS",
        "descricao": "SERVIÇOS DE CHAVEIROS (CHAVEIRO)",
        "item": "24",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10915",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9521500",
        "denominacao": "REPARAÇÃO E MANUTENÇÃO DE EQUIPAMENTOS ELETROELETRÔNICOS DE USO PESSOAL E DOMÉSTICO",
        "descricao": "SERVIÇOS DE GRAVAÇÃO EM OBJETOS",
        "item": "24",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2364",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9603399",
        "denominacao": "ATIVIDADES FUNERARIAS E SERVICOS RELACIONADOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "ALUGUEL DE CAPELA",
        "item": "25",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11507",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9603399",
        "denominacao": "ATIVIDADES FUNERARIAS E SERVICOS RELACIONADOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE DESENBARAÇO DE CERTIDÃO DE ÓBITO",
        "item": "25",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10924",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9603399",
        "denominacao": "ATIVIDADES FUNERARIAS E SERVICOS RELACIONADOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE FORMECIMENTO DE VÉU E OUTROS ADORNOS",
        "item": "25",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10925",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9603399",
        "denominacao": "ATIVIDADES FUNERARIAS E SERVICOS RELACIONADOS NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE FORMECIMENTO DE FLORES EM FUNERAIS, COROAS E OUTROS PARAMENTOS",
        "item": "25",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10923",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9603303",
        "denominacao": "SERVICOS DE SEPULTAMENTO",
        "descricao": "SERVIÇOS DE FUNERAIS, INCLUSIVE FORNECIMENTO DE CAIXÃO, URNA E ESQUIFES",
        "item": "25",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10921",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9603305",
        "denominacao": "SERVICOS DE SOMATOCONSERVACAO",
        "descricao": "SERVIÇOS DE EMBALSAMENTO, EMBELEZAMENTO, CONSERVAÇÃO OU RESTAURAÇÃO DE CADÁVERES",
        "item": "25",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10922",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9603302",
        "denominacao": "SERVICOS DE CREMACAO",
        "descricao": "SERVIÇOS DE CREMAÇÃO DE CORPOS E PARTE DE CORPOS CADAVÉRICOS",
        "item": "25",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "10202",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6622300",
        "denominacao": "CORRETORES E AGENTES DE SEGUROS, DE PLANOS DE PREVIDENCIA COMPLEMENTAR E DE SAUDE",
        "descricao": "PLANOS OU CONVÊNIOS FUNERÁRIOS",
        "item": "25",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "10203",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8430200",
        "denominacao": "SEGURIDADE SOCIAL OBRIGATORIA",
        "descricao": "PLANOS OU CONVÊNIOS FUNERÁRIOS",
        "item": "25",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "10204",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6511102",
        "denominacao": "PLANOS DE AUXILIO-FUNERAL",
        "descricao": "PLANOS OU CONVÊNIOS FUNERÁRIOS",
        "item": "25",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "10205",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9603301",
        "denominacao": "GESTAO E MANUTENCAO DE CEMITERIOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE JAZIDOS  E CEMITÉRIOS",
        "item": "25",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "10206",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5310502",
        "denominacao": "ATIVIDADES DE  FRANQUEADAS E PERMISSIONÁRIAS DO CORREIO NACIONAL",
        "descricao": "SERVIÇO DE COLETA, REMESSA OU ENTREGA DE CORRESPONDÊNCIAS, DOCUMENTOS, OBJETOS, BENS OU VALORES",
        "item": "26",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2377",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5310501",
        "denominacao": "ATIVIDADES DO CORREIO NACIONAL",
        "descricao": "SERVIÇOS DE COLETA, REMESSA OU ENTREGA DE CORRESPONDÊNCIAS, DOCUMENTOS, OBJETOS, BENS OU VALORES, INCLUSIVE PELOS CORREIOS E SUAS AGÊNCIAS FRANQUEADAS"
    },
    {
        "subclasse": "5310502",
        "denominacao": "ATIVIDADES DEÂ FRANQUEADAS E PERMISSIONARIAS DO CORREIO NACIONAL",
        "descricao": "SERVIÇOS DE COLETA, REMESSA OU ENTREGA DE CORRESPONDÊNCIAS, DOCUMENTOS, OBJETOS, BENS OU VALORES, INCLUSIVE PELOS CORREIOS E SUAS AGÊNCIAS FRANQUEADAS"
    },
    {
        "subclasse": "5310502",
        "denominacao": "ATIVIDADES DEÂ FRANQUEADAS E PERMISSIONARIAS DO CORREIO NACIONAL",
        "descricao": "SERVIÇO DE COLETA, REMESSA OU ENTREGA DE CORRESPONDÊNCIAS, DOCUMENTOS, OBJETOS, BENS OU VALORES",
        "item": "26",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10207",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5310501",
        "denominacao": "ATIVIDADES DO CORREIO NACIONAL",
        "descricao": "SERVIÇO DE COLETA, REMESSA OU ENTREGA DE CORRESPONDÊNCIAS, DOCUMENTOS, OBJETOS, BENS OU VALORES",
        "item": "26",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10208",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8800600",
        "denominacao": "SERVIÇOS DE ASSISTÊNCIA SOCIAL SEM ALOJAMENTO",
        "descricao": "SERVIÇOS DE ASSISTÊNCIA SOCIAL",
        "item": "27",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2378",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8800600",
        "denominacao": "SERVICOS DE ASSISTENCIA SOCIAL SEM ALOJAMENTO",
        "descricao": "SERVIÇOS DE ASSISTÊNCIA SOCIAL",
        "item": "27",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10210",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6821801",
        "denominacao": "CORRETAGEM NA COMPRA E VENDA E AVALIAÇÃO DE IMÓVEIS",
        "descricao": "SERVIÇOS DE AVALIAÇÃO DE BENS E SERVIÇOS DE QUALQUER NATUREZA",
        "item": "28",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2379",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "0230600",
        "denominacao": "ATIVIDADES DE APOIO A PRODUCAO FLORESTAL",
        "descricao": "SERVIÇOS DE AVALIAÇÃO DE BENS E SERVIÇOS DE QUALQUER NATUREZA",
        "item": "28",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11470",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6621501",
        "denominacao": "PERITOS E AVALIADORES DE SEGUROS",
        "descricao": "SERVIÇOS DE AVALIAÇÃO DE BENS E SERVIÇOS DE QUALQUER NATUREZA",
        "item": "28",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10214",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6821801",
        "denominacao": "CORRETAGEM NA COMPRA E VENDA E AVALIACAO DE IMOVEIS",
        "descricao": "SERVIÇOS DE AVALIAÇÃO DE BENS E SERVIÇOS DE QUALQUER NATUREZA",
        "item": "28",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10213",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS DE AVALIAÇÃO DE BENS E SERVIÇOS DE QUALQUER NATUREZA",
        "item": "28",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10212",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7490199",
        "denominacao": "OUTRAS ATIVIDADES PROFISSIONAIS, CIENTIFICAS E TECNICAS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE AVALIAÇÃO DE BENS E SERVIÇOS DE QUALQUER NATUREZA",
        "item": "28",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10211",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7499399",
        "denominacao": "OUTROS SERVIÇOS PRESTADOS PRINCIPALMENTE ÀS EMPRESAS",
        "descricao": "SERVIÇOS DE AVALIAÇÃO DE BENS E SERVIÇOS DE QUALQUER NATUREZA",
        "item": "28",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2380",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9101500",
        "denominacao": "ATIVIDADES DE BIBLIOTECAS E ARQUIVOS",
        "descricao": "SERVIÇOS BIBLIOTECÁRIOS",
        "item": "29",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2381",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9101500",
        "denominacao": "ATIVIDADES DE BIBLIOTECAS E ARQUIVOS",
        "descricao": "SERVIÇOS BIBLIOTECÁRIOS",
        "item": "29",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10215",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7210000",
        "denominacao": "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIENCIAS FISICAS E NATURAIS",
        "descricao": "SERVIÇOS DE BIOLOGIA",
        "item": "30",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10216",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7210000",
        "denominacao": "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIENCIAS FISICAS E NATURAIS",
        "descricao": "SERVIÇOS DE BIOTECNOLOGIA",
        "item": "30",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10217",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7210000",
        "denominacao": "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIENCIAS FISICAS E NATURAIS",
        "descricao": "SERVIÇOS DE QUÍMICA",
        "item": "30",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10218",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVIÇOS DE ENGENHARIA",
        "descricao": "SERVIÇOS TÉCNICOS EM ELETRÓNICA",
        "item": "31",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2386",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVIÇOS DE ENGENHARIA",
        "descricao": "SERVIÇOS TÉCNICOS EM MECÂNICA",
        "item": "31",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2388",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7119703",
        "denominacao": "SERVICOS DE DESENHO TECNICO RELACIONADOS A ARQUITETURA E ENGENHARIA",
        "descricao": "SERVIÇOS TÉCNICOS EM EDIFICAÇÕES",
        "item": "31",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10220",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7111100",
        "denominacao": "SERVICOS DE ARQUITETURA",
        "descricao": "SERVIÇOS TÉCNICOS EM EDIFICAÇÕES",
        "item": "31",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10222",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS TÉCNICOS EM ELETRÓNICA",
        "item": "31",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10224",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS TÉCNICOS EM MECÂNICA",
        "item": "31",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10226",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS TÉCNICOS EM TELECOMUNICAÇÕES",
        "item": "31",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10227",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS TÉCNICOS EM ELETROTÉCNICA",
        "item": "31",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10225",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7120100",
        "denominacao": "TESTES E ANALISES TECNICAS",
        "descricao": "SERVIÇOS TÉCNICOS EM EDIFICAÇÕES",
        "item": "31",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10223",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS TÉCNICOS EM EDIFICAÇÕES",
        "item": "31",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10221",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7119799",
        "denominacao": "ATIVIDADES TECNICAS RELACIONADAS A ENGENHARIA E ARQUITETURA NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS TÉCNICOS EM EDIFICAÇÕES",
        "item": "31",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10219",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7119703",
        "denominacao": "SERVIÇOS DE DESENHO TÉCNICO RELACIONADOS À ARQUITETURA E ENGENHARIA",
        "descricao": "SERVIÇOS DE DESENHOS TÉCNICOS",
        "item": "32",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2390",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7119703",
        "denominacao": "SERVICOS DE DESENHO TECNICO RELACIONADOS A ARQUITETURA E ENGENHARIA",
        "descricao": "SERVIÇOS DE DESENHOS TÉCNICOS",
        "item": "32",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10228",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7499399",
        "denominacao": "OUTROS SERVIÇOS PRESTADOS PRINCIPALMENTE ÀS EMPRESAS",
        "descricao": "SERVIÇOS DE DESPACHANTE",
        "item": "33",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2393",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5250801",
        "denominacao": "COMISSARIA DE DESPACHOS",
        "descricao": "SERVIÇOS DE COMISSÁRIOS",
        "item": "33",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10230",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8299799",
        "denominacao": "OUTRAS ATIVIDADES DE SERVICOS PRESTADOS PRINCIPALMENTE AS EMPRESAS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE DESPACHANTE",
        "item": "33",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10231",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "5250802",
        "denominacao": "ATIVIDADES DE DESPACHANTES ADUANEIROS",
        "descricao": "SERVIÇOS DE DESPACHANTE ADUANEIRO",
        "item": "33",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10229",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8030700",
        "denominacao": "ATIVIDADES DE INVESTIGACAO PARTICULAR",
        "descricao": "SERVIÇOS DE DETETIVE",
        "item": "34",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10232",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8030700",
        "denominacao": "ATIVIDADES DE INVESTIGACAO PARTICULAR",
        "descricao": "SERVIÇOS DE INVESTIGAÇÕES PARTICULARES",
        "item": "34",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10233",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9240100",
        "denominacao": "ATIVIDADES DE AGÊNCIAS DE NOTÍCIAS",
        "descricao": "SERVIÇOS DE ASSESSORIA DE IMPRENSA",
        "item": "35",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2396",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9240100",
        "denominacao": "ATIVIDADES DE AGÊNCIAS DE NOTÍCIAS",
        "descricao": "SERVIÇOS DE JORNALISMO",
        "item": "35",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2397",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7020400",
        "denominacao": "ATIVIDADES DE CONSULTORIA EM GESTAO EMPRESARIAL, EXCETO CONSULTORIA TECNICA ESPECIFICA",
        "descricao": "SERVIÇOS DE RELAÇÕES PÚBLICAS",
        "item": "35",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "11502",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6391700",
        "denominacao": "AGENCIAS DE NOTICIAS",
        "descricao": "SERVIÇOS DE REPORTAGEM",
        "item": "35",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10238",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9002701",
        "denominacao": "ATIVIDADES DE ARTISTAS PLASTICOS, JORNALISTAS INDEPENDENTES E ESCRITORES",
        "descricao": "SERVIÇOS DE REPORTAGEM",
        "item": "35",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10237",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6391700",
        "denominacao": "AGENCIAS DE NOTICIAS",
        "descricao": "SERVIÇOS DE JORNALISMO",
        "item": "35",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10236",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9002701",
        "denominacao": "ATIVIDADES DE ARTISTAS PLASTICOS, JORNALISTAS INDEPENDENTES E ESCRITORES",
        "descricao": "SERVIÇOS DE JORNALISMO",
        "item": "35",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10235",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7020400",
        "denominacao": "ATIVIDADES DE CONSULTORIA EM GESTAO EMPRESARIAL, EXCETO CONSULTORIA TECNICA ESPECIFICA",
        "descricao": "SERVIÇOS DE ASSESSORIA DE IMPRENSA",
        "item": "35",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10234",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9240100",
        "denominacao": "ATIVIDADES DE AGÊNCIAS DE NOTÍCIAS",
        "descricao": "SERVIÇOS DE RELAÇÕES PÚBLICAS",
        "item": "35",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2398",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9240100",
        "denominacao": "ATIVIDADES DE AGÊNCIAS DE NOTÍCIAS",
        "descricao": "SERVIÇOS DE REPORTAGEM",
        "item": "35",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2399",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7490199",
        "denominacao": "OUTRAS ATIVIDADES PROFISSIONAIS, CIENTIFICAS E TECNICAS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE METEOROLOGIA",
        "item": "36",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10239",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001999",
        "denominacao": "ARTES CÊNICAS, ESPETÁCULOS E ATIVIDADES COMPLEMENTARES NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS ARTÍSTICOS",
        "item": "37",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2401",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8299799",
        "denominacao": "OUTRAS ATIVIDADES DE SERVICOS PRESTADOS PRINCIPALMENTE AS EMPRESAS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE ATLETAS  ",
        "item": "37",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10246",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8299799",
        "denominacao": "OUTRAS ATIVIDADES DE SERVICOS PRESTADOS PRINCIPALMENTE AS EMPRESAS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MANEQUINS  ",
        "item": "37",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10245",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "8299799",
        "denominacao": "OUTRAS ATIVIDADES DE SERVICOS PRESTADOS PRINCIPALMENTE AS EMPRESAS NAO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MODELOS  ",
        "item": "37",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10244",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001903",
        "denominacao": "PRODUCAO DE ESPETACULOS DE DANCA",
        "descricao": "SERVIÇOS ARTÍSTIVOS",
        "item": "37",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10243",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001904",
        "denominacao": "PRODUCAO DE ESPETACULOS CIRCENSES, DE MARIONETES E SIMILARES",
        "descricao": "SERVIÇOS ARTÍSTIVOS",
        "item": "37",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10242",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001905",
        "denominacao": "PRODUCAO DE ESPETACULOS DE RODEIOS, VAQUEJADAS E SIMILARES",
        "descricao": "SERVIÇOS ARTÍSTIVOS",
        "item": "37",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10241",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9001999",
        "denominacao": "ARTES CENICAS, ESPETACULOS E ATIVIDADES COMPLEMENTARES NAO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS ARTÍSTIVOS",
        "item": "37",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10240",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7499399",
        "denominacao": "OUTROS SERVIÇOS PRESTADOS PRINCIPALMENTE ÀS EMPRESAS",
        "descricao": "SERVIÇOS DE ATLETAS  ",
        "item": "37",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2404",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "7499399",
        "denominacao": "OUTROS SERVIÇOS PRESTADOS PRINCIPALMENTE ÀS EMPRESAS",
        "descricao": "SERVIÇOS DE MODELOS  ",
        "item": "37",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2402",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9102301",
        "denominacao": "ATIVIDADES DE MUSEUS E DE EXPLORAÇÃO DE LUGARES E PRÉDIOS HISTÓRICOS E ATRAÇÕES SIMILARES",
        "descricao": "SERVIÇOS DE MUSEOLOGIA",
        "item": "38",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2405",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9102301",
        "denominacao": "ATIVIDADES DE MUSEUS E DE EXPLORACAO DE LUGARES E PREDIOS HISTORICOS E ATRACOES SIMILARES",
        "descricao": "SERVIÇOS DE MUSEOLOGIA",
        "item": "38",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10247",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3211601",
        "denominacao": "LAPIDAÇÃO DE GEMAS",
        "descricao": "SERVIÇOS DE OURIVESARIA E LAPIDAÇÃO",
        "item": "39",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2407",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "2399101",
        "denominacao": "DECORACAO, LAPIDACAO, GRAVACAO, VITRIFICACAO E OUTROS TRABALHOS EM CERAMICA, LOUCA, VIDRO E CRISTAL",
        "descricao": "SERVIÇOS DE OURIVESARIA E LAPIDAÇÃO",
        "item": "39",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10249",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3211601",
        "denominacao": "LAPIDACAO DE GEMAS",
        "descricao": "SERVIÇOS DE OURIVESARIA E LAPIDAÇÃO",
        "item": "39",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10250",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "3250707",
        "denominacao": "FABRICACAO DE ARTIGOS OPTICOS",
        "descricao": "SERVIÇOS DE OURIVESARIA E LAPIDAÇÃO",
        "item": "39",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10248",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9231299",
        "denominacao": "OUTROS SERVIÇOS ESPECIALIZADOS LIGADOS ÀS ATIVIDADES ARTÍSTICAS",
        "descricao": "SERVIÇOS DE CONFECÇÃO DE OBRAS DE ARTE SOB ENCOMENDA",
        "item": "40",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2414",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "9002701",
        "denominacao": "ATIVIDADES DE ARTISTAS PLASTICOS, JORNALISTAS INDEPENDENTES E ESCRITORES",
        "descricao": "SERVIÇOS DE CONFECÇÃO DE OBRAS DE ARTE SOB ENCOMENDA",
        "item": "40",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10257",
        "dtcancelamento": "20110630"
    },
    {
        "subclasse": "6204000",
        "denominacao": "CONSULTORIA EM TECNOLOGIA DA INFORMACAO",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "10809",
        "dtcancelamento": "20110101"
    },
    {
        "subclasse": "7740300",
        "denominacao": "GESTÃO DE ATIVOS INTANGÍVEIS NÃO-FINANCEIROS",
        "descricao": "SERVIÇOS DE CESSÃO DE DIREITO DE USO DE MARCAS E DE SINAIS DE PROPAGANDA",
        "item": "3",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1532",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8230002",
        "denominacao": "CASAS DE FESTAS E EVENTOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE CENTRO DE CONVENÇÕES OU STANDS",
        "item": "3",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1534",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8230002",
        "denominacao": "CASAS DE FESTAS E EVENTOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE PARQUES DE DIVERSÕES",
        "item": "3",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1535",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8230002",
        "denominacao": "CASAS DE FESTAS E EVENTOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE QUADRAS ESPORTIVAS, ESTÁDIOS, GINÁSIOS E CANCHAS",
        "item": "3",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1536",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8230002",
        "denominacao": "CASAS DE FESTAS E EVENTOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE SALÕES DE FESTAS",
        "item": "3",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1537",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5221400",
        "denominacao": "CONCESSIONÁRIAS DE RODOVIAS, PONTES, TÚNEIS E SERVIÇOS RELACIONADOS",
        "descricao": "SERVIÇOS DE LOCAÇÃO, SUBLOCAÇÃO, ARRENDAMENTO, DIREITO DE USO OU PERMISSÃO DE USO DE DUTOS E CONDUTOS",
        "item": "3",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1538",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5221400",
        "denominacao": "CONCESSIONÁRIAS DE RODOVIAS, PONTES, TÚNEIS E SERVIÇOS RELACIONADOS",
        "descricao": "SERVIÇOS DE LOCAÇÃO, SUBLOCAÇÃO, ARRENDAMENTO, DIREITO DE USO OU PERMISSÃO DE USO DE RODOVIA",
        "item": "3",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1540",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6810202",
        "denominacao": "ALUGUEL DE IMÓVEIS PRÓPRIOS",
        "descricao": "SERVIÇOS DE LOCAÇÃO, SUBLOCAÇÃO, ARRENDAMENTO, DIREITO DE USO OU PERMISSÃO DE USO DE POSTES",
        "item": "3",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1541",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5221400",
        "denominacao": "CONCESSIONÁRIAS DE RODOVIAS, PONTES, TÚNEIS E SERVIÇOS RELACIONADOS",
        "descricao": "SERVIÇOS DE LOCAÇÃO, SUBLOCAÇÃO, ARRENDAMENTO, DIREITO DE USO OU PERMISSÃO DE USO DE FERROVIA",
        "item": "3",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1539",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9231299",
        "denominacao": "OUTROS SERVIÇOS ESPECIALIZADOS LIGADOS ÀS ATIVIDADES ARTÍSTICAS",
        "descricao": "SERVIÇOS DE CESSÃO DE ANDAIMES, PALCOS, COBERTURAS E OUTRAS ESTRUTURAS DE USO TEMPORÁRIO",
        "item": "3",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1542",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8513801",
        "denominacao": "ATIVIDADES DE CLÍNICA MÉDICA (CLÍNICAS, CONSULTÓRIOS E AMBULATÓRIOS)",
        "descricao": "SERVIÇOS DE BIOMEDICINA",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1544",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8515499",
        "denominacao": "OUTRAS ATIVIDADES DE SERVIÇOS PROFISSIONAIS DA ÁREA DE SAÚDE",
        "descricao": "SERVIÇOS DE PSIQUIATRIA",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1546",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8513801",
        "denominacao": "ATIVIDADES DE CLÍNICA MÉDICA (CLÍNICAS, CONSULTÓRIOS E AMBULATÓRIOS)",
        "descricao": "SERVIÇOS DE ULTRA-SONOGRAFIA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "1547",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8640201",
        "denominacao": "LABORATÓRIOS DE ANATOMIA PATOLÓGICA E CITOLÓGICA",
        "descricao": "SERVIÇOS DE PATOLOGIA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "1548",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8640210",
        "denominacao": "SERVIÇOS DE QUIMIOTERAPIA",
        "descricao": "SERVIÇOS DE QUIMIOTERAPIA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "1555",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8514699",
        "denominacao": "OUTRAS ATIVIDADES DE SERVIÇOS DE COMPLEMENTAÇÃO DIAGNÓSTICA E TERAPÊUTICA",
        "descricao": "SERVIÇOS DE RESSONÂNCIA MAGNÉTICA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "1556",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8514604",
        "denominacao": "SERVIÇOS DE RAIO-X, RADIODIAGNÓSTICO E RADIOTERAPIA",
        "descricao": "SERVIÇOS DE TOMOGRAFIA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "1554",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3250707",
        "denominacao": "FABRICAÇÃO DE ARTIGOS ÓPTICOS",
        "descricao": "SERVIÇOS DE LABORATÓRIOS",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "1557",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8512000",
        "denominacao": "ATIVIDADES DE ATENDIMENTO A URGÊNCIAS E EMERGÊNCIAS",
        "descricao": "SERVIÇOS DE PRONTO-SOCORRO",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "1566",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8720401",
        "denominacao": "ATIVIDADES DE CENTROS DE ASSISTÊNCIA PSICOSSOCIAL",
        "descricao": "SERVIÇOS DE REABILITAÇÃO DE DEPENDENTES QUÍMICOS",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "1567",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8610101",
        "denominacao": "ATIVIDADES DE ATENDIMENTO HOSPITALAR, EXCETO PRONTO-SOCORRO E UNIDADES PARA ATENDIMENTO A URGÊNCIAS",
        "descricao": "SERVIÇOS HOSPITALARES, DE CASA DE SAÚDE OU MATERNIDADE",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "1565",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8630506",
        "denominacao": "SERVIÇOS DE VACINAÇÃO E IMUNIZAÇÃO HUMANA",
        "descricao": "SERVIÇOS DE VACINAÇÃO E IMUNIZAÇÃO HUMANA",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "1560",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8640203",
        "denominacao": "SERVIÇOS DE DIÁLISE E NEFROLOGIA",
        "descricao": "SERVIÇOS DE DIÁLISE",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "1562",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8610101",
        "denominacao": "ATIVIDADES DE ATENDIMENTO HOSPITALAR, EXCETO PRONTO-SOCORRO E UNIDADES PARA ATENDIMENTO A URGÊNCIAS",
        "descricao": "SERVIÇOS DE MANICÔMIO",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "1563",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8610101",
        "denominacao": "ATIVIDADES DE ATENDIMENTO HOSPITALAR, EXCETO PRONTO-SOCORRO E UNIDADES PARA ATENDIMENTO A URGÊNCIAS",
        "descricao": "SERVIÇOS DE SANATÓRIOS",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "1564",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8690901",
        "denominacao": "ATIVIDADES DE PRÁTICAS INTEGRATIVAS E COMPLEMENTARES EM SAÚDE HUMANA",
        "descricao": "SERVIÇOS DE ACUPUNTURA",
        "item": "4",
        "subitem": "5",
        "aliquota": "2",
        "idcnae": "1569",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8515499",
        "denominacao": "OUTRAS ATIVIDADES DE SERVIÇOS PROFISSIONAIS DA ÁREA DE SAÚDE",
        "descricao": "SERVIÇOS DE ESTERELIZAÇÃO",
        "item": "4",
        "subitem": "6",
        "aliquota": "2",
        "idcnae": "1571",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8515499",
        "denominacao": "OUTRAS ATIVIDADES DE SERVIÇOS PROFISSIONAIS DA ÁREA DE SAÚDE",
        "descricao": "SERVIÇOS FARMACÊUTICOS",
        "item": "4",
        "subitem": "7",
        "aliquota": "2",
        "idcnae": "1572",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8513801",
        "denominacao": "ATIVIDADES DE CLÍNICA MÉDICA (CLÍNICAS, CONSULTÓRIOS E AMBULATÓRIOS)",
        "descricao": "SERVIÇOS DE REABILITAÇAO DA FALA OU AUDIÇÃO (CLÍNICA)",
        "item": "4",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "1573",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8515404",
        "denominacao": "SERVIÇOS DE FISIOTERAPIA E TERAPIA OCUPACIONAL",
        "descricao": "SERVIÇOS DE PSICOTERAPIA",
        "item": "4",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "1578",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8650007",
        "denominacao": "ATIVIDADES DE TERAPIA DE NUTRIÇÃO ENTERAL E PARENTERAL",
        "descricao": "SERVIÇOS DE TERAPIA DE NUTRIÇÃO ENTERAL E PARENTERAL.",
        "item": "4",
        "subitem": "9",
        "aliquota": "2",
        "idcnae": "1581",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8610101",
        "denominacao": "ATIVIDADES DE ATENDIMENTO HOSPITALAR, EXCETO PRONTO-SOCORRO E UNIDADES PARA ATENDIMENTO A URGÊNCIAS",
        "descricao": "SERVIÇOS DE OBSTETRÍCIA",
        "item": "4",
        "subitem": "11",
        "aliquota": "2",
        "idcnae": "1463",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8515499",
        "denominacao": "OUTRAS ATIVIDADES DE SERVIÇOS PROFISSIONAIS DA ÁREA DE SAÚDE",
        "descricao": "SERVIÇOS DE ORTÓPTICA",
        "item": "4",
        "subitem": "13",
        "aliquota": "2",
        "idcnae": "1466",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3310303",
        "denominacao": "FABRICAÇÃO DE APARELHOS E UTENSÍLIOS PARA CORREÇÃO DE DEFEITOS FÍSICOS E APARELHOS ORTOPÉDICOS EM GERAL - INCLUSIVE SOB ENCOMENDA",
        "descricao": "SERVIÇOS DE PRÓTESES",
        "item": "4",
        "subitem": "14",
        "aliquota": "2",
        "idcnae": "1467",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3250706",
        "denominacao": "SERVIÇOS DE PRÓTESE DENTÁRIA",
        "descricao": "SERVIÇOS DE PRÓTESES",
        "item": "4",
        "subitem": "14",
        "aliquota": "2",
        "idcnae": "1469",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8515499",
        "denominacao": "OUTRAS ATIVIDADES DE SERVIÇOS PROFISSIONAIS DA ÁREA DE SAÚDE",
        "descricao": "SERVIÇOS DE PSICANÁLISE",
        "item": "4",
        "subitem": "15",
        "aliquota": "2",
        "idcnae": "1470",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8711502",
        "denominacao": "INSTITUIÇÕES DE LONGA PERMANÊNCIA PARA IDOSOS",
        "descricao": "SERVIÇOS DE ASILOS",
        "item": "4",
        "subitem": "17",
        "aliquota": "2",
        "idcnae": "1472",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8531699",
        "denominacao": "OUTROS SERVIÇOS SOCIAIS COM ALOJAMENTO",
        "descricao": "SERVIÇOS DE CASA DE REPOUSO OU RECUPERAÇÃO",
        "item": "4",
        "subitem": "17",
        "aliquota": "2",
        "idcnae": "1476",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8720499",
        "denominacao": "ATIVIDADES DE ASSISTÊNCIA PSICOSSOCIAL E À SAÚDE A PORTADORES DE DISTÚRBIOS PSÍQUICOS, DEFICIÊNCIA MENTAL E DEPENDÊNCIA QUÍMICA NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE REABILITAÇÃO DE DEPENDENTES QUÍMICOS COM ALOJAMENTO",
        "item": "4",
        "subitem": "17",
        "aliquota": "2",
        "idcnae": "1475",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8730101",
        "denominacao": "ORFANATOS",
        "descricao": "SERVIOS DE CRECHES",
        "item": "4",
        "subitem": "17",
        "aliquota": "2",
        "idcnae": "1473",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8730102",
        "denominacao": "ALBERGUES ASSISTENCIAIS",
        "descricao": "SERVIÇOS DE ALBERGUES ASSISTENCIAIS",
        "item": "4",
        "subitem": "17",
        "aliquota": "2",
        "idcnae": "1474",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8513801",
        "denominacao": "ATIVIDADES DE CLÍNICA MÉDICA (CLÍNICAS, CONSULTÓRIOS E AMBULATÓRIOS)",
        "descricao": "SERIVIÇOS DE INSEMINAÇÃO ARTIFICIAL OU FERTILIZAÇÃO IN VITRO",
        "item": "4",
        "subitem": "18",
        "aliquota": "2",
        "idcnae": "1477",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8640212",
        "denominacao": "SERVIÇOS DE HEMOTERAPIA",
        "descricao": "SERVIÇOS DE BANCO DE SANGUE",
        "item": "4",
        "subitem": "19",
        "aliquota": "2",
        "idcnae": "1478",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8640214",
        "denominacao": "SERVIÇOS DE BANCOS DE CÉLULAS E TECIDOS HUMANOS",
        "descricao": "SERVIÇOS DE BANCO DE OLHOS",
        "item": "4",
        "subitem": "19",
        "aliquota": "2",
        "idcnae": "1481",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8640214",
        "denominacao": "SERVIÇOS DE BANCOS DE CÉLULAS E TECIDOS HUMANOS",
        "descricao": "SERVIÇOS DE BANCO DE PELE",
        "item": "4",
        "subitem": "19",
        "aliquota": "2",
        "idcnae": "1482",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8690999",
        "denominacao": "OUTRAS ATIVIDADES DE ATENÇÃO À SAÚDE HUMANA NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE BANCO DE ÓVULOS",
        "item": "4",
        "subitem": "19",
        "aliquota": "2",
        "idcnae": "1483",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8690902",
        "denominacao": "ATIVIDADES DE BANCO DE LEITE HUMANO",
        "descricao": "SERVIÇOS DE BANCO DE LEITE",
        "item": "4",
        "subitem": "19",
        "aliquota": "2",
        "idcnae": "1479",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8630507",
        "denominacao": "ATIVIDADES DE REPRODUÇÃO HUMANA ASSISTIDA",
        "descricao": "SERVIÇOS DE BANCO DE SÊMEN",
        "item": "4",
        "subitem": "19",
        "aliquota": "2",
        "idcnae": "1480",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8640202",
        "denominacao": "LABORATÓRIOS CLÍNICOS",
        "descricao": "SERVIÇOS DE COLETA DE SANGUE, LEITE, TECIDOS, SÊMEN, ÓRGÃOS E MATERIAIS BIOLÓGICOS DE QUALQUER ESPÉCIE",
        "item": "4",
        "subitem": "20",
        "aliquota": "2",
        "idcnae": "1484",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8622400",
        "denominacao": "SERVIÇOS DE REMOÇÃO DE PACIENTES, EXCETO OS SERVIÇOS MÓVEIS DE ATENDIMENTO A URGÊNCIAS",
        "descricao": "SERVIÇOS DE REMOÇÕES DE ÓRGÃOS",
        "item": "4",
        "subitem": "20",
        "aliquota": "2",
        "idcnae": "1485",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8512000",
        "denominacao": "ATIVIDADES DE ATENDIMENTO A URGÊNCIAS E EMERGÊNCIAS",
        "descricao": "SERVIÇOS DE ASSISTÊNCIA, ATENDIMENTO OU TRATAMENTO MÓVEL",
        "item": "4",
        "subitem": "21",
        "aliquota": "2",
        "idcnae": "1486",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6550200",
        "denominacao": "PLANOS DE SAÚDE",
        "descricao": "PLANOS DE MEDICINA DE GRUPO OU INDIVIDUAL",
        "item": "4",
        "subitem": "22",
        "aliquota": "2",
        "idcnae": "1488",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6550200",
        "denominacao": "PLANOS DE SAÚDE",
        "descricao": "OUTROS PLANOS DE SAÚDE",
        "item": "4",
        "subitem": "23",
        "aliquota": "2",
        "idcnae": "1489",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7490103",
        "denominacao": "SERVIÇOS DE AGRONOMIA E DE CONSULTORIA ÀS ATIVIDADES AGRÍCOLAS E PECUÁRIAS",
        "descricao": "SERVIÇOS DE ASSESSORIA ÀS ATIVIDADES PECUÁRIAS",
        "item": "5",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1490",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7500100",
        "denominacao": "ATIVIDADES VETERINÁRIAS",
        "descricao": "SERVIÇOS DE MEDICINA VETERINÁRIA E ZOOTECNIA",
        "item": "5",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1491",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7500100",
        "denominacao": "ATIVIDADES VETERINÁRIAS",
        "descricao": "SERVIÇOS AMBULATORIAIS - VETERINÁRIOS",
        "item": "5",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1493",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7500100",
        "denominacao": "ATIVIDADES VETERINÁRIAS",
        "descricao": "SERVIÇOS DE PRONTOS-SOCORROS - VETERINÁRIOS",
        "item": "5",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1495",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7500100",
        "denominacao": "ATIVIDADES VETERINÁRIAS",
        "descricao": "SERVIÇOS DE HOSPITALARES VETERINÁRIOS",
        "item": "5",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1494",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "0162801",
        "denominacao": "SERVIÇO DE INSEMINAÇÃO ARTIFICIAL DE ANIMAIS",
        "descricao": "SERVIÇOS DE INSEMINAÇÃO ARTIFICIAL OU FERTILIZAÇÃO IN VITRO",
        "item": "5",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1498",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "0162799",
        "denominacao": "OUTRAS ATIVIDADES DE SERVIÇOS RELACIONADOS COM A PECUÁRIA - EXCETO ATIVIDADES VETERINÁRIAS",
        "descricao": "SERVIÇOS DE BANCO DE SANGUE E ÓRGÃOS DE ORIGEM ANIMAL",
        "item": "5",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1499",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "0162799",
        "denominacao": "OUTRAS ATIVIDADES DE SERVIÇOS RELACIONADOS COM A PECUÁRIA - EXCETO ATIVIDADES VETERINÁRIAS",
        "descricao": "SERVIÇOS DE COLETA DE SANGUE, LEITE, TECIDOS, SÊMEN, ÓRGÃOS E MATERIAIS BIOLÓGICOS DE QUALQUER ESPÉCIE, DE ORIGEM ANIMAL",
        "item": "5",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1500",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7500100",
        "denominacao": "ATIVIDADES VETERINÁRIAS",
        "descricao": "SERVIÇOS DE ATENDIMENTO, ASSISTÊNCIA OU  TRATAMENTO MÓVEL À ANIMAIS",
        "item": "5",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "1501",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "0162704",
        "denominacao": "SERVIÇO DE MANEJO DE ANIMAIS",
        "descricao": "SERVIÇOS DE MANEJAMENTO DE ANIMAIS",
        "item": "5",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "156",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "0162799",
        "denominacao": "OUTRAS ATIVIDADES DE SERVIÇOS RELACIONADOS COM A PECUÁRIA - EXCETO ATIVIDADES VETERINÁRIAS",
        "descricao": "SERVIÇOS TRATAMENTO DE ANIMAIS",
        "item": "5",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "1507",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "0162802",
        "denominacao": "SERVIÇO DE TOSQUIAMENTO DE OVINOS",
        "descricao": "SERVIÇOS DE TOSQUIA",
        "item": "5",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "1505",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9609203",
        "denominacao": "ALOJAMENTO, HIGIENE E EMBELEZAMENTO DE ANIMAIS",
        "descricao": "SERVIÇOS DE GUARDA E ALOJAMENTO DE ANIMAIS",
        "item": "5",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "1504",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9609203",
        "denominacao": "ALOJAMENTO, HIGIENE E EMBELEZAMENTO DE ANIMAIS",
        "descricao": "SERVIÇOS DE EMBELEZAMENTO DE ANIMAIS",
        "item": "5",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "1503",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8011102",
        "denominacao": "SERVIÇOS DE ADESTRAMENTO DE CÃES DE GUARDA",
        "descricao": "SERVIÇOS DE ADESTRAMENTO DE ANIMAIS",
        "item": "5",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "1502",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9602501",
        "denominacao": "CABELEIREIROS",
        "descricao": "SERVIÇOS DE BARBEARIA",
        "item": "6",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1510",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9602502",
        "denominacao": "ATIVIDADES DE ESTÉTICA E OUTROS SERVIÇOS DE CUIDADOS COM A BELEZA",
        "descricao": "SERVIÇOS DE MANICURI E PEDICURI",
        "item": "6",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1511",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9602502",
        "denominacao": "ATIVIDADES DE ESTÉTICA E OUTROS SERVIÇOS DE CUIDADOS COM A BELEZA",
        "descricao": "SERVIÇOS DE DEPILAÇÃO",
        "item": "6",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1512",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9602502",
        "denominacao": "ATIVIDADES DE ESTÉTICA E OUTROS SERVIÇOS DE CUIDADOS COM A BELEZA",
        "descricao": "SERVIÇOS DE TRATAMENTO DE PELE",
        "item": "6",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1514",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9329801",
        "denominacao": "DISCOTECAS, DANCETERIAS, SALÕES DE DANÇA E SIMILARES",
        "descricao": "SERVIÇOS DE ACADEMIA DE DANÇA",
        "item": "6",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1516",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9313100",
        "denominacao": "ATIVIDADES DE CONDICIONAMENTO FÍSICO",
        "descricao": "SERVIÇOS DE ACADEMIA DE NATAÇÃO",
        "item": "6",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1520",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9609201",
        "denominacao": "CLÍNICAS DE ESTÉTICA E SIMILARES",
        "descricao": "SERVIÇOS DE CONDICIONAMENTO FÍSICO CORPORAL",
        "item": "6",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1521",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9313100",
        "denominacao": "ATIVIDADES DE CONDICIONAMENTO FÍSICO",
        "descricao": "SERVIÇOS DE ACADEMIA DE ARTES MARCIAIS",
        "item": "6",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1517",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9609201",
        "denominacao": "CLÍNICAS DE ESTÉTICA E SIMILARES",
        "descricao": "SERVIÇOS DE ORIENTAÇÃO PARA EMAGRECIMENTO, SPA E CONGÊNERE",
        "item": "6",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1522",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7119701",
        "denominacao": "SERVIÇOS DE CARTOGRAFIA, TOPOGRAFIA E GEODÉSIA",
        "descricao": "SERVIÇOS DE AGRIMENSURA",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "1405",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "0161999",
        "denominacao": "OUTRAS ATIVIDADES DE SERVIÇOS RELACIONADOS COM A AGRICULTURA",
        "descricao": "SERVIÇOS DE EXTENSÃO RURAL",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "1408",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7490103",
        "denominacao": "SERVIÇOS DE AGRONOMIA E DE CONSULTORIA ÀS ATIVIDADES AGRÍCOLAS E PECUÁRIAS",
        "descricao": "SERVIÇOS DE ASSESSORIA ÀS ATIVIDADES AGRÍCOLAS",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "1410",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "0511804",
        "denominacao": "ATIVIDADES DE SERVIÇOS RELACIONADOS A PESCA",
        "descricao": "SERVIÇO DE EXTENSÃO DE PESCA",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "1409",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4391600",
        "denominacao": "OBRAS DE FUNDAÇÕES",
        "descricao": "SERVIÇOS DE ESTAQUEAMENTO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1412",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4211101",
        "denominacao": "CONSTRUÇÃO DE RODOVIAS E FERROVIAS",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO DE RODOVIAS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1418",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4312600",
        "denominacao": "PERFURAÇÕES E SONDAGENS",
        "descricao": "SERVIÇOS DE SONDAGEM ",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1413",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4211101",
        "denominacao": "CONSTRUÇÃO DE RODOVIAS E FERROVIAS",
        "descricao": "SERVIÇOS DE PAVIMENTAÇÃO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1419",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4329199",
        "denominacao": "OUTRAS OBRAS DE INSTALAÇÕES EM CONSTRUÇÕES NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE PISCINAS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1444",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4213800",
        "denominacao": "OBRAS DE URBANIZAÇÃO - RUAS, PRAÇAS E CALÇADAS",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO CIVIL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1436",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4329105",
        "denominacao": "TRATAMENTOS TÉRMICOS, ACÚSTICOS OU DE VIBRAÇÃO",
        "descricao": "SERVIÇOS DE TRATAMENTO ACÚSTICO E TÉRMICO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1431",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4329104",
        "denominacao": "MONTAGEM E INSTALAÇÃO DE SISTEMAS E EQUIPAMENTOS DE ILUMINAÇÃO E SINALIZAÇÃO EM VIAS PÚBLICAS, PORTOS E AEROPORTOS",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE PLACAS DE SINALIZAÇÃO DE TRÂNSITO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1430",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4322303",
        "denominacao": "INSTALAÇÕES DE SISTEMA DE PREVENÇÃO CONTRA INCÊNDIO",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE SISTEMAS DE PREVENÇÃO CONTRA INCÊNDIOS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1429",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4329103",
        "denominacao": "INSTALAÇÃO, MANUTENÇÃO E REPARAÇÃO DE ELEVADORES, ESCADAS E ESTEIRAS ROLANTES, EXCETO DE FABRICAÇÃO PRÓPRIA",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE ELEVADORES, ESCADAS E ESTEIRAS LOLANTES",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1427",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4399105",
        "denominacao": "PERFURAÇÃO E CONSTRUÇÃO DE POÇOS DE ÁGUA",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO DE POÇOS DE ÁGUAS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1425",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4223500",
        "denominacao": "CONSTRUÇÃO DE REDES DE TRANSPORTES POR DUTOS, EXCETO PARA ÁGUA E ESGOTO",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO DE REDES DE TRANSPORTE POR DUTOS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1424",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4222701",
        "denominacao": "CONSTRUÇÃO DE REDES DE ABASTECIMENTO DE ÁGUA, COLETA DE ESGOTO E CONSTRUÇÕES CORRELATAS, EXCETO OBRAS DE IRRIGAÇÃO",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO DE REDES DE ÁGUA E ESGOTO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1423",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4222701",
        "denominacao": "CONSTRUÇÃO DE REDES DE ABASTECIMENTO DE ÁGUA, COLETA DE ESGOTO E CONSTRUÇÕES CORRELATAS, EXCETO OBRAS DE IRRIGAÇÃO",
        "descricao": "SERVIÇOS DE SANEAMENTO AMBIENTAL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1422",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4222702",
        "denominacao": "OBRAS DE IRRIGAÇÃO",
        "descricao": "SERVIÇOS DE OBRAS DE IRRIGAÇÃO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1421",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4291000",
        "denominacao": "OBRAS PORTUÁRIAS, MARÍTIMAS E FLUVIAIS",
        "descricao": "SERVIÇOS DE OBRAS MARÍTIMAS E FLUVIAIS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1420",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7010600",
        "denominacao": "INCORPORAÇÃO E COMPRA E VENDA DE IMÓVEIS",
        "descricao": "SERVIÇOS DE EXECUÇÃO, POR ADMINISTRAÇÃO, DE OBRAS DE CONSTRUÇÃO CIVIL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1449",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4330401",
        "denominacao": "IMPERMEABILIZAÇÃO EM OBRAS DE ENGENHARIA CIVIL",
        "descricao": "SERVIÇOS DE IMPERMEABILIZAÇÃO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1446",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVIÇOS DE ENGENHARIA",
        "descricao": "SERVIÇOS DE ESTUDOS ORGANIZACIONAIS",
        "item": "7",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "1452",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4311801",
        "denominacao": "DEMOLIÇÃO DE EDIFÍCIOS E OUTRAS ESTRUTURAS",
        "descricao": "SERVIÇOS DE DEMOLIÇÃO",
        "item": "7",
        "subitem": "4",
        "aliquota": "3",
        "idcnae": "1454",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4330402",
        "denominacao": "INSTALAÇÃO DE PORTAS, JANELAS, TETOS, DIVISÓRIAS E ARMÁRIOS EMBUTIDOS DE QUALQUER MATERIAL",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE DIVISÓRIAS",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "1352",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4550099",
        "denominacao": "OUTRAS OBRAS DE ACABAMENTO DA CONSTRUÇÃO",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE ASSOALHO",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "1357",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7410202",
        "denominacao": "DECORAÇÃO DE INTERIORES",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE TAPETES E CORTINAS",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "1462",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7410202",
        "denominacao": "DECORAÇÃO DE INTERIORES",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE PERSIANAS",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "1461",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4330405",
        "denominacao": "APLICAÇÃO DE REVESTIMENTOS E DE RESINAS EM INTERIORES E EXTERIORES",
        "descricao": "SERVIÇO DE COLOCAÇÃO E INSTALAÇÃO DE CARPETE",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "1355",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4330405",
        "denominacao": "APLICAÇÃO DE REVESTIMENTOS E DE RESINAS EM INTERIORES E EXTERIORES",
        "descricao": "SERVIÇO DE COLOCAÇÃO DE PELÍCULA DE CONTENÇÃO SOLA EM IMÓVEIS",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "1354",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4330405",
        "denominacao": "APLICAÇÃO DE REVESTIMENTOS E DE RESINAS EM INTERIORES E EXTERIORES",
        "descricao": "SERVIÇOS DE RECUPERAÇÃO, RASPAGEM, POLIMENTO E LUSTRAÇÃO DE ASSOALHOS, PISOS E PAREDES",
        "item": "7",
        "subitem": "7",
        "aliquota": "3",
        "idcnae": "1359",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4322302",
        "denominacao": "INSTALAÇÃO E MANUTENÇÃO DE SISTEMAS CENTRAIS DE AR CONDICIONADO, DE VENTILAÇÃO E REFRIGERAÇÃO",
        "descricao": "SERVIÇOS DE CALEFAÇÃO",
        "item": "7",
        "subitem": "8",
        "aliquota": "3",
        "idcnae": "1360",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7470501",
        "denominacao": "ATIVIDADES DE LIMPEZA EM IMÓVEIS",
        "descricao": "SERVIÇOS DE SEPARAÇÃO E DESTINAÇÃO FINAL DO LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "1361",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9000001",
        "denominacao": "LIMPEZA URBANA - EXCETO GESTÃO DE ATERROS SANITÁRIOS",
        "descricao": "SERVIÇOS DE TRATAMENTO DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "1364",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9000001",
        "denominacao": "LIMPEZA URBANA - EXCETO GESTÃO DE ATERROS SANITÁRIOS",
        "descricao": "SERVIOS DE INCINERAÇÃO DE RESÍDUOS",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "1366",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9000001",
        "denominacao": "LIMPEZA URBANA - EXCETO GESTÃO DE ATERROS SANITÁRIOS",
        "descricao": "SERVIÇOS DE VARRIÇÃO E COLETA DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "1365",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9000001",
        "denominacao": "LIMPEZA URBANA - EXCETO GESTÃO DE ATERROS SANITÁRIOS",
        "descricao": "SERVIÇOS DE RECICLAGEM DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "1362",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7470501",
        "denominacao": "ATIVIDADES DE LIMPEZA EM IMÓVEIS",
        "descricao": "SERVIÇOS DE LIMPEZA, MANUTENÇÃO E CONSERVAÇÃO DE VIAS E LOGRADOUROS PÚBLICOS",
        "item": "7",
        "subitem": "10",
        "aliquota": "2,5",
        "idcnae": "1372",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "0161002",
        "denominacao": "SERVIÇO DE PODA DE ÁRVORES PARA LAVOURAS",
        "descricao": "SERVIÇOS DE JARDINAGEM",
        "item": "7",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "1377",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3701100",
        "denominacao": "GESTÃO DE REDES DE ESGOTO",
        "descricao": "SERVIÇOS DE CONTROLE E TRATAMENTO DE EFLUENTES",
        "item": "7",
        "subitem": "12",
        "aliquota": "3",
        "idcnae": "1378",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3701100",
        "denominacao": "GESTÃO DE REDES DE ESGOTO",
        "descricao": "SERVIÇOS DE TRATAMENTO E CONTROLE DE AGENTES FÍSICOS, QUÍMICOS E BIOLÓGICOS",
        "item": "7",
        "subitem": "12",
        "aliquota": "3",
        "idcnae": "1379",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "0161001",
        "denominacao": "SERVIÇO DE PULVERIZAÇÃO E CONTROLE DE PRAGAS AGRÍCOLAS",
        "descricao": "SERVIÇOS DE PULVERIZAÇÃO",
        "item": "7",
        "subitem": "13",
        "aliquota": "3",
        "idcnae": "1381",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "0161003",
        "denominacao": "SERVIÇO DE PREPARAÇÃO DE TERRENO, CULTIVO E COLHEITA",
        "descricao": "SERVIÇOS DE COLHEITA",
        "item": "7",
        "subitem": "16",
        "aliquota": "3",
        "idcnae": "1382",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "0230600",
        "denominacao": "ATIVIDADES DE APOIO À PRODUÇÃO FLORESTAL",
        "descricao": "SERVIÇO DE FLORESTAMENTO, REFLORESTAMENTO, SEMEADURA E ADUBAÇÃO",
        "item": "7",
        "subitem": "16",
        "aliquota": "3",
        "idcnae": "1383",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4399102",
        "denominacao": "MONTAGEM E DESMONTAGEM DE ANDAIMES E OUTRAS ESTRUTURAS TEMPORÁRIAS",
        "descricao": "SERVIÇOS DE ESCORAMENTO E CONTENÇÃO DE ENCOSTAS",
        "item": "7",
        "subitem": "17",
        "aliquota": "3",
        "idcnae": "1384",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7420999",
        "denominacao": "OUTROS SERVIÇOS TÉCNICOS ESPECIALIZADOS",
        "descricao": "SERVIÇOS DE LIMPEZA E DRENAGEM DE RIOS, PORTOS, CANAIS, BAÍAS, LAGOS, LAGOAS, REPRESAS E AÇUDES",
        "item": "7",
        "subitem": "18",
        "aliquota": "3",
        "idcnae": "1385",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7119701",
        "denominacao": "SERVIÇOS DE CARTOGRAFIA, TOPOGRAFIA E GEODÉSIA",
        "descricao": "SERVIÇOS DE AEROFOTOGRAMETRIA",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "1387",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7119701",
        "denominacao": "SERVIÇOS DE CARTOGRAFIA, TOPOGRAFIA E GEODÉSIA",
        "descricao": "SERVIÇOS BATIMÉTRICOS",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "1395",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7119701",
        "denominacao": "SERVIÇOS DE CARTOGRAFIA, TOPOGRAFIA E GEODÉSIA",
        "descricao": "SERVIÇOS GEODÉSICOS E GEOFÍSICOS",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "1397",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7499399",
        "denominacao": "OUTROS SERVIÇOS PRESTADOS PRINCIPALMENTE ÀS EMPRESAS",
        "descricao": "SERVIÇOS DE MAPEAMENTO",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "1399",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7119701",
        "denominacao": "SERVIÇOS DE CARTOGRAFIA, TOPOGRAFIA E GEODÉSIA",
        "descricao": "SERVIÇOS GEOLÓGICOS ",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "1398",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7119701",
        "denominacao": "SERVIÇOS DE CARTOGRAFIA, TOPOGRAFIA E GEODÉSIA",
        "descricao": "SERVIÇOS GEOGRÁFICOS",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "1396",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7119701",
        "denominacao": "SERVIÇOS DE CARTOGRAFIA, TOPOGRAFIA E GEODÉSIA",
        "descricao": "SERVIÇOS AEROFOTOGRAMETRIA ",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "1394",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7119701",
        "denominacao": "SERVIÇOS DE CARTOGRAFIA, TOPOGRAFIA E GEODÉSIA",
        "descricao": "SERVIÇOS GEODÉSICOS E GEOGRÁFICOS",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "1390",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7119701",
        "denominacao": "SERVIÇOS DE CARTOGRAFIA, TOPOGRAFIA E GEODÉSIA",
        "descricao": "SERVIÇOS GEOLÓGICOS E GEOFÍSICOS",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "1391",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7119701",
        "denominacao": "SERVIÇOS DE CARTOGRAFIA, TOPOGRAFIA E GEODÉSIA",
        "descricao": "SERVIÇOS DE CARTOGRAFIA",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "1393",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7119701",
        "denominacao": "SERVIÇOS DE CARTOGRAFIA, TOPOGRAFIA E GEODÉSIA",
        "descricao": "SERVIÇOS DE BATIMETRIA",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "1388",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "0910600",
        "denominacao": "ATIVIDADES DE APOIO À EXTRAÇÃO DE PETRÓLEO E GÁS NATURAL",
        "descricao": "SERVIÇOS DE PERFURAÇÃO DE POÇOS DE PETRÓLEO",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "1400",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7210000",
        "denominacao": "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIÊNCIAS FÍSICAS E NATURAIS",
        "descricao": "SERVIÇOS DE PESQUISAS RELACIOANADAS AO PETRÓLEO",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "2654",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7210000",
        "denominacao": "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIÊNCIAS FÍSICAS E NATURAIS",
        "descricao": "SERVIÇOS DE PESQUISAS RELACIOANADAS AO CARVÃO MINERAL",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "2653",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "1120705",
        "denominacao": "SERVIÇOS RELACIONADOS COM A EXTRAÇÃO DE PETRÓLEO E GÁS - EXCETO A PROSPECÇÃO REALIZADA POR TERCEIROS",
        "descricao": "SERVIÇOS DE CONCRETAÇÃO",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "2652",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "1120704",
        "denominacao": "SERVIÇOS RELACIONADOS COM A EXTRAÇÃO DE PETRÓLEO E GÁS - EXCETO A PROSPECÇÃO REALIZADA POR TERCEIROS",
        "descricao": "SERVIÇOS DE  ESTIMULAÇÃO E OUTROS SERVIÇOS RELACIONADOS COM A EXPLORAÇÃO E EXPLOTAÇÃO DE PETRÓLEO, GÁS NATURAL E OUTROS RECURSOS MINERAIS",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "2651",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "1120703",
        "denominacao": "SERVIÇOS RELACIONADOS COM A EXTRAÇÃO DE PETRÓLEO E GÁS - EXCETO A PROSPECÇÃO REALIZADA POR TERCEIROS",
        "descricao": "SERVIÇOS DE PESCARIA",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "2650",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "1120701",
        "denominacao": "SERVIÇOS RELACIONADOS COM A EXTRAÇÃO DE PETRÓLEO E GÁS - EXCETO A PROSPECÇÃO REALIZADA POR TERCEIROS",
        "descricao": "SERVIÇOS DE PERFILAGEM",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "1403",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "1120702",
        "denominacao": "SERVIÇOS RELACIONADOS COM A EXTRAÇÃO DE PETRÓLEO E GÁS - EXCETO A PROSPECÇÃO REALIZADA POR TERCEIROS",
        "descricao": "SERVIÇOS DE TESTEMUNHAGEM",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "1404",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "0910600",
        "denominacao": "ATIVIDADES DE APOIO À EXTRAÇÃO DE PETRÓLEO E GÁS NATURAL",
        "descricao": "SERVIÇOS DE MERGULHO",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "1402",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "0910600",
        "denominacao": "ATIVIDADES DE APOIO À EXTRAÇÃO DE PETRÓLEO E GÁS NATURAL",
        "descricao": "SERVIÇOS DE CIMENTAÇÃO DE POÇOS DE PETRÓLEO",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "1401",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "0161999",
        "denominacao": "OUTRAS ATIVIDADES DE SERVIÇOS RELACIONADOS COM A AGRICULTURA",
        "descricao": "SERVIÇOS DE NUCLEAÇÃO  E BOMBARDEAMENTO DE NUVENS",
        "item": "7",
        "subitem": "22",
        "aliquota": "3",
        "idcnae": "2655",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7490102",
        "denominacao": "ESCAFANDRIA E MERGULHO",
        "descricao": "SERVIÇOS DE INSTRUÇÃO DE MERGULHO",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "2415",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8599601",
        "denominacao": "FORMAÇÃO DE CONDUTORES",
        "descricao": "SERVIÇOS DE INSTRUÇÃO DE CODUTORES (AUTO-ESCOLA)",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "2665",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9609299",
        "denominacao": "OUTRAS ATIVIDADES DE SERVIÇOS PESSOAIS NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "AVALIAÇÃO DE CONHECIMENTOS DE QUALQUER NATUREZA.",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "7242",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9491000",
        "denominacao": "ATIVIDADES DE ORGANIZAÇÕES RELIGIOSAS",
        "descricao": "SERVIÇOS DE EDUCAÇÃO RELIGIOSA",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "2677",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8599602",
        "denominacao": "CURSOS DE PILOTAGEM",
        "descricao": "SERVIÇOS DE INSTRUÇÃO DE PILOTAGEM",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "2666",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5510801",
        "denominacao": "HOTÉIS",
        "descricao": "SERVIÇOS DE HOSPEDAGEM MARÍTIMA",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "2679",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5590699",
        "denominacao": "OUTROS ALOJAMENTOS NÃO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM RESIDENCE-SERVICE E SUÍTE-SERVECE",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "2686",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5590100",
        "denominacao": "OUTROS TIPOS DE ALOJAMENTO",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM FLAT",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "2685",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5510803",
        "denominacao": "MOTÉIS",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM MOTEL",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "2681",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5590602",
        "denominacao": "CAMPINGS",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM CAMPING",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "2683",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5590601",
        "denominacao": "ALBERGUES, EXCETO ASSISTENCIAIS",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM ALBERGUES",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "2682",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6330400",
        "denominacao": "ATIVIDADES DE AGÊNCIAS DE VIAGENS E ORGANIZADORES DE VIAGEM",
        "descricao": "SERVIÇO DE AGENCIAMENTO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "2688",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6330400",
        "denominacao": "ATIVIDADES DE AGÊNCIAS DE VIAGENS E ORGANIZADORES DE VIAGEM",
        "descricao": "SERVIÇO DE PROMOÇÃO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "2690",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6330400",
        "denominacao": "ATIVIDADES DE AGÊNCIAS DE VIAGENS E ORGANIZADORES DE VIAGEM",
        "descricao": "SERVIÇO DE ORGANIZAÇÃO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "2689",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6612603",
        "denominacao": "CORRETORAS DE CÂMBIO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE CÂMBIO",
        "item": "10",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "2695",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6629100",
        "denominacao": "ATIVIDADES AUXILIARES DOS SEGUROS, DA PREVIDÊNCIA COMPLEMENTAR E DOS PLANOS DE SAÚDE NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE PREVIDÊNCIA PRIVADA",
        "item": "10",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "2697",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6622300",
        "denominacao": "CORRETORES E AGENTES DE SEGUROS, DE PLANOS DE PREVIDÊNCIA COMPLEMENTAR E DE SAÚDE",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE PLANOS DE SAÚDE",
        "item": "10",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "2696",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6612601",
        "denominacao": "CORRETORAS DE TÍTULOS E VALORES MOBILIÁRIOS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE VALORES MOBILIÁRIOS",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1686",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6612601",
        "denominacao": "CORRETORAS DE TÍTULOS E VALORES MOBILIÁRIOS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE TÍTULOS",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "2699",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6611802",
        "denominacao": "BOLSA DE MERCADORIAS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTEMEDIAÇÃO",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1689",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9609202",
        "denominacao": "AGÊNCIAS MATRIMONIAIS",
        "descricao": "SERVIÇOS DE AGENCIAMENTOS MATRIMONIAIS",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1688",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6630400",
        "denominacao": "ATIVIDADES DE ADMINISTRAÇÃO DE FUNDOS POR CONTRATO OU COMISSÃO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE DIREITOS DE PROPRIEDADE INDUSTRIAL",
        "item": "10",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "1586",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7990200",
        "denominacao": "SERVIÇOS DE RESERVAS E OUTROS SERVIÇOS DE TURISMO NÃO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE AGENCIAMENTO DE INGRESSOS PARA SALAS DE ESPETÁCULOS",
        "item": "10",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "1587",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7740300",
        "denominacao": "GESTÃO DE ATIVOS INTANGÍVEIS NÃO-FINANCEIROS",
        "descricao": "AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE CONTRATOS DE ARRENDAMENTO MERCANTIL (LEASING)",
        "item": "10",
        "subitem": "4",
        "aliquota": "3",
        "idcnae": "1588",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7740300",
        "denominacao": "GESTÃO DE ATIVOS INTANGÍVEIS NÃO-FINANCEIROS",
        "descricao": "AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE CONTRATOS DE FATURIZAÇÃO (FACTORING)",
        "item": "10",
        "subitem": "4",
        "aliquota": "3",
        "idcnae": "1589",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7490104",
        "denominacao": "ATIVIDADES DE INTERMEDIAÇÃO E AGENCIAMENTO DE SERVIÇOS E NEGÓCIOS EM GERAL, EXCETO IMOBILIÁRIOS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO MARÍTIMO",
        "item": "10",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "1592",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5041505",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE PEÇAS E ACESSÓRIOS PARA MOTOCICLETAS E MOTONETAS",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "1598",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4614100",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE MÁQUINAS, EQUIPAMENTOS, EMBARCAÇÕES E AERONAVES",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "1602",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6612602",
        "denominacao": "DISTRIBUIDORAS DE TÍTULOS E VALORES MOBILIÁRIOS",
        "descricao": "SERVIÇOS DE DISTRIBUIÇÃO DE TÍTULOS E VALORES MOBILIÁRIOS",
        "item": "10",
        "subitem": "10",
        "aliquota": "3",
        "idcnae": "1609",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5913800",
        "denominacao": "DISTRIBUIÇÃO CINEMATOGRÁFICA, DE VÍDEO E DE PROGRAMAS DE TELEVISÃO",
        "descricao": "SERVIÇOS DE DISTRIBUIÇAO DE FILMES OU VIDEOTAPES",
        "item": "10",
        "subitem": "10",
        "aliquota": "3",
        "idcnae": "1612",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5913800",
        "denominacao": "DISTRIBUIÇÃO CINEMATOGRÁFICA, DE VÍDEO E DE PROGRAMAS DE TELEVISÃO",
        "descricao": "SERVIÇOS DE DISTRIBUIÇÃO DE FILMES E DE VÍDEOS",
        "item": "10",
        "subitem": "10",
        "aliquota": "3",
        "idcnae": "1610",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5223100",
        "denominacao": "ESTACIONAMENTO DE VEÍCULOS",
        "descricao": "SERVIÇOS DE GUARDA E ESTACIONAMENTO DE AERONAVES",
        "item": "11",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1613",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5223100",
        "denominacao": "ESTACIONAMENTO DE VEÍCULOS",
        "descricao": "SERVIÇOS DE GUARDA E ESTACIONAMENTO DE EMBARCAÇÕES",
        "item": "11",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1614",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7460802",
        "denominacao": "ATIVIDADES DE VIGILÂNCIA E SEGURANÇA PRIVADA",
        "descricao": "SERVIÇOS DE ESCOLTA",
        "item": "11",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1617",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5211702",
        "denominacao": "GUARDA-MÓVEIS",
        "descricao": "SERVIÇOS DE GUARDA DE BENS",
        "item": "11",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1618",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5211799",
        "denominacao": "DEPÓSITOS DE MERCADORIAS PARA TERCEIROS, EXCETO ARMAZÉNS GERAIS E GUARDA-MÓVEIS",
        "descricao": "SERVIÇOS DE DEPÓSITOS DE BENS DE QUALQUER NATUREZA",
        "item": "11",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1621",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5211701",
        "denominacao": "ARMAZÉNS GERAIS - EMISSÃO DE WARRANT",
        "descricao": "SERVIÇOS DE ARMAZENAMENTO ",
        "item": "11",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1619",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5211799",
        "denominacao": "DEPÓSITOS DE MERCADORIAS PARA TERCEIROS, EXCETO ARMAZÉNS GERAIS E GUARDA-MÓVEIS",
        "descricao": "SERVIÇOS DE ARRUMAÇÃO E GUARDA DE BENS DE QUALQUER NATUREZA",
        "item": "11",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1620",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5914600",
        "denominacao": "ATIVIDADES DE EXIBIÇÃO CINEMATOGRÁFICA",
        "descricao": "SERVIÇOS DE EXIBIÇÃO CINEMATOGRÁFICA",
        "item": "12",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1624",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5914600",
        "denominacao": "ATIVIDADES DE EXIBIÇÃO CINEMATOGRÁFICA",
        "descricao": "SERVIÇOS DE EXIBIÇÕES CINEMATOGRÁFICAS",
        "item": "12",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1625",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9001904",
        "denominacao": "PRODUÇÃO DE ESPETÁCULOS CIRCENSES, DE MARIONETES E SIMILARES",
        "descricao": "SERVIÇOS DE ESPETÁCULOS CIRCENSES",
        "item": "12",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1626",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9001999",
        "denominacao": "ARTES CÊNICAS, ESPETÁCULOS E ATIVIDADES COMPLEMENTARES NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE PROGRAMA DE AUDITÓRIO",
        "item": "12",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1627",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9103100",
        "denominacao": "ATIVIDADES DE JARDINS BOTÂNICOS, ZOOLÓGICOS, PARQUES NACIONAIS, RESERVAS ECOLÓGICAS E ÁREAS DE PROTEÇÃO AMBIENTAL",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE JARDINS BOTÂNICOS, ZOOLÓGICOS, PARQUES NACIONAIS E RESERVAS ECOLÓGICAS",
        "item": "12",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1718",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9321200",
        "denominacao": "PARQUES DE DIVERSÃO E PARQUES TEMÁTICOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE CENTROS DE LAZER",
        "item": "12",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1719",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9321200",
        "denominacao": "PARQUES DE DIVERSÃO E PARQUES TEMÁTICOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE PARQUES DE DIVERSÕES",
        "item": "12",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1720",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9329801",
        "denominacao": "DISCOTECAS, DANCETERIAS, SALÕES DE DANÇA E SIMILARES",
        "descricao": "SERVIÇOS DE TAXI-DANCING",
        "item": "12",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1632",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9329801",
        "denominacao": "DISCOTECAS, DANCETERIAS, SALÕES DE DANÇA E SIMILARES",
        "descricao": "SERVIÇOS DE BOATE",
        "item": "12",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1721",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9329801",
        "denominacao": "DISCOTECAS, DANCETERIAS, SALÕES DE DANÇA E SIMILARES",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE SALÕES DE BAILES",
        "item": "12",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "1633",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9003500",
        "denominacao": "GESTÃO DE ESPAÇOS PARA ARTES CÊNICAS, ESPETÁCULOS E OUTRAS ATIVIDADES ARTÍSTICAS",
        "descricao": "SERVIÇOS DE BALLET, DANÇAS, DESFILES E SHOWS",
        "item": "12",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "1634",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9003500",
        "denominacao": "GESTÃO DE ESPAÇOS PARA ARTES CÊNICAS, ESPETÁCULOS E OUTRAS ATIVIDADES ARTÍSTICAS",
        "descricao": "SERVIÇOS DE ÓPERA, CONCERTOS, RECITAIS E FESTIVAIS",
        "item": "12",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "1635",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9001999",
        "denominacao": "ARTES CÊNICAS, ESPETÁCULOS E ATIVIDADES COMPLEMENTARES NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EXPOSIÇÃO ",
        "item": "12",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "1637",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9200301",
        "denominacao": "CASAS DE BINGO",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE DIVERSÕES ELETRÔNICAS OU NÃO",
        "item": "12",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "1638",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9200399",
        "denominacao": "EXPLORAÇÃO DE JOGOS DE AZAR E APOSTAS NÃO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE JOGOS E CARTEADOS",
        "item": "12",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "1640",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9329802",
        "denominacao": "EXPLORAÇÃO DE BOLICHES",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE JOGOS DE BOLICHE E BOCHA",
        "item": "12",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "1641",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9329804",
        "denominacao": "EXPLORAÇÃO DE JOGOS ELETRÔNICOS RECREATIVOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE JOGOS ELETRÔNICOS",
        "item": "12",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "1642",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9329803",
        "denominacao": "EXPLORAÇÃO DE JOGOS DE SINUCA, BILHAR E SIMILARES",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE JOGOS DE BILHARES",
        "item": "12",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "1643",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9200399",
        "denominacao": "EXPLORAÇÃO DE JOGOS DE AZAR E APOSTAS NÃO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE DIVERSÕES ELETRÔNICAS OU NÃO",
        "item": "12",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "1639",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9001905",
        "denominacao": "PRODUÇÃO DE ESPETÁCULOS DE RODEIOS, VAQUEJADAS E SIMILARES",
        "descricao": "SERVIÇOS DE ESPETÁCULOS DE RODEIOS E VAQUEJADAS",
        "item": "12",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "1644",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9200302",
        "denominacao": "EXPLORAÇÃO DE APOSTAS EM CORRIDAS DE CAVALOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE CORRIDAS E COMPETIÇÕES DE ANIMAIS",
        "item": "12",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "1645",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9261402",
        "denominacao": "ORGANIZAÇÃO E EXPLORAÇÃO DE ATIVIDADES DESPORTIVAS",
        "descricao": "SERVIÇOS DE COMPETIÇÕES ESPORTIVAS OU DE DESTREZA FÍSICA OU INTELECTUAL",
        "item": "12",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "1646",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9001999",
        "denominacao": "ARTES CÊNICAS, ESPETÁCULOS E ATIVIDADES COMPLEMENTARES NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE DANÇAS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "1649",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9001999",
        "denominacao": "ARTES CÊNICAS, ESPETÁCULOS E ATIVIDADES COMPLEMENTARES NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE PRODUÇÕES TEATRAIS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "1653",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9329801",
        "denominacao": "DISCOTECAS, DANCETERIAS, SALÕES DE DANÇA E SIMILARES",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE BAILES",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "1654",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9199500",
        "denominacao": "OUTRAS ATIVIDADES ASSOCIATIVAS, NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE DESFILE DE BLOCOS CARNAVALESCOS OU FOLCLORÍCOS E TRIOS ELÉTRICOS",
        "item": "12",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "1656",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5914600",
        "denominacao": "ATIVIDADES DE EXIBIÇÃO CINEMATOGRÁFICA",
        "descricao": "SERVIÇOS DE EXIBIÇÃO DE FILMES",
        "item": "12",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "1657",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9211803",
        "denominacao": "SERVIÇOS DE DUBLAGEM E MIXAGEM SONORA",
        "descricao": "SERVIÇOS DE DUBLAGEM SONORA",
        "item": "13",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1661",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9211803",
        "denominacao": "SERVIÇOS DE DUBLAGEM E MIXAGEM SONORA",
        "descricao": "SERVIÇOS DE MIXAGEM SONORA",
        "item": "13",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1662",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9211803",
        "denominacao": "SERVIÇOS DE DUBLAGEM E MIXAGEM SONORA",
        "descricao": "SERVIÇOS DE TRUCAGEM SONORA",
        "item": "13",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1663",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8299702",
        "denominacao": "EMISSÃO DE VALES-ALIMENTAÇÃO, VALES-TRANSPORTE E SIMILARES",
        "descricao": "SERVIÇOS DE EMISSÃO DE VALES ALIMENTAÇÃO, TRANSPORTE E SIMILARES",
        "item": "13",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "2417",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5920100",
        "denominacao": "ATIVIDADES DE GRAVAÇÃO DE SOM E DE EDIÇÃO DE MÚSICA",
        "descricao": "SERVIÇOS DE GRAVAÇÃO DE SONS",
        "item": "13",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1664",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7420003",
        "denominacao": "LABORATÓRIOS FOTOGRÁFICOS",
        "descricao": "SERVIÇOS DE REVELAÇÃO FOTOGRÁFICA",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1666",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "1830001",
        "denominacao": "REPRODUÇÃO DE SOM EM QUALQUER SUPORTE",
        "descricao": "SERVIÇOS DE REPROGRAFIA",
        "item": "13",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1672",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8219901",
        "denominacao": "FOTOCÓPIAS",
        "descricao": "SERVIÇOS DE DIGITALIZAÇÃO",
        "item": "13",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1677",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8219901",
        "denominacao": "FOTOCÓPIAS",
        "descricao": "SERVIÇOS DE REPROGRAFIA ",
        "item": "13",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1676",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7420005",
        "denominacao": "SERVIÇOS DE MICROFILMAGEM",
        "descricao": "SERVIÇOS DE MICROFILMAGEM",
        "item": "13",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1675",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "1830003",
        "denominacao": "REPRODUÇÃO DE SOFTWARE EM QUALQUER SUPORTE",
        "descricao": "SERVIÇOS DE REPROGRAFIA",
        "item": "13",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1674",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8219901",
        "denominacao": "FOTOCÓPIAS",
        "descricao": "SERVIÇOS DE FOTOCOMPOSIÇÃO ",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1760",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "1812100",
        "denominacao": "IMPRESSÃO DE MATERIAL DE SEGURANÇA",
        "descricao": "SERVIÇOS DE COMPOSIÇÃO GRÁFICA",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1685",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "2222502",
        "denominacao": "IMPRESSÃO DE MATERIAL PARA USO INDUSTRIAL, COMERCIAL E PUBLICITÁRIO",
        "descricao": "SERVIÇOS DE COMPOSIÇÃO GRÁFICA",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1684",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "1813099",
        "denominacao": "IMPRESSÃO DE MATERIAL PARA OUTROS USOS",
        "descricao": "SERVIÇOS DE COMPOSIÇÃO GRÁFICA",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1683",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "1821100",
        "denominacao": "SERVIÇOS DE PRÉ-IMPRESSÃO",
        "descricao": "SERVIÇOS DE FOTOLITOGRAFIA  ",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1681",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "1821100",
        "denominacao": "SERVIÇOS DE PRÉ-IMPRESSÃO",
        "descricao": "SERVIÇOS DE ZINCOGRAFIA",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1680",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "1821100",
        "denominacao": "SERVIÇOS DE PRÉ-IMPRESSÃO",
        "descricao": "SERVIÇOS DE LITOGRAFIA",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1679",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3314719",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA AS INDÚSTRIAS DE ALIMENTOS, BEBIDAS E FUMO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS, MOTORES E EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1700",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3314720",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA A INDÚSTRIA TÊXTIL, DO VESTUÁRIO, DO COURO E CALÇADOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS, MOTORES E EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1702",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3313901",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE GERADORES, TRANSFORMADORES E MOTORES ELÉTRICOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1704",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3313901",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE GERADORES, TRANSFORMADORES E MOTORES ELÉTRICOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1706",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3313999",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS, APARELHOS E MATERIAIS ELÉTRICOS NÃO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS, MOTORES E EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1708",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3312102",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE APARELHOS E INSTRUMENTOS DE MEDIDA, TESTE E CONTROLE",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1712",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3312104",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE EQUIPAMENTOS E INSTRUMENTOS ÓPTICOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1714",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3314718",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS PARA A INDÚSTRIA METALÚRGICA, EXCETO MÁQUINAS-FERRAMENTA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS, MOTORES E EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1699",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "2995504",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E EQUIPAMENTOS DE TERRAPLENAGEM E PAVIMENTAÇÃO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS, MOTORES E EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1698",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3314716",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE TRATORES, EXCETO AGRÍCOLAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS, MOTORES E EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1697",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "2995502",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE OUTRAS MÁQUINAS E EQUIPAMENTOS DE USO NA EXTRAÇÃO MINERAL E CONSTRUÇÃO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS, MOTORES E EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1696",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3314714",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA A PROSPECÇÃO E EXTRAÇÃO DE PETRÓLEO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS, MOTORES E EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1695",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3314712",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE TRATORES AGRÍCOLAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS E MOTORES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1694",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3314711",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA AGRICULTURA E PECUÁRIA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS, MOTORES E EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1693",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3314705",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE EQUIPAMENTOS DE TRANSMISSÃO PARA FINS INDUSTRIAIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1692",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3021100",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE EMBARCAÇÕES E ESTRUTURAS FLUTUANTES",
        "descricao": "SERVIÇOS DE LIMPEZA E MANUTENÇÃO DE NAVIOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1732",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9529104",
        "denominacao": "REPARAÇÃO DE BICICLETAS, TRICICLOS E OUTROS VEÍCULOS NÃO-MOTORIZADOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE VEÍCULOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1731",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9529105",
        "denominacao": "REPARAÇÃO DE ARTIGOS DO MOBILIÁRIO",
        "descricao": "SERVIÇOS DE REPARAÇÃO DE ARTIGOS DE TAPEÇARIA",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1730",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9529102",
        "denominacao": "CHAVEIROS",
        "descricao": "SERVIÇOS DE CONSERTOS DE FECHADURAS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1727",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4543900",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MOTOCICLETAS E MOTONETAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE VEÍCULOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1726",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4520007",
        "denominacao": "SERVIÇOS DE INSTALAÇÃO, MANUTENÇÃO E REPARAÇÃO DE ACESSÓRIOS PARA VEÍCULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE PELÍCULA DE CONTENÇÃO SOLAR EM VEÍCULOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1725",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4520007",
        "denominacao": "SERVIÇOS DE INSTALAÇÃO, MANUTENÇÃO E REPARAÇÃO DE ACESSÓRIOS PARA VEÍCULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE INSTALAÇÃO, MANUTENÇÃO E REPARAÇÃO DE ACESSÓRIOS PARA VEÍCULOS AUTOMOTORES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1724",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4520006",
        "denominacao": "SERVIÇOS DE BORRACHARIA PARA VEÍCULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERTO DE PNEUS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1723",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4329103",
        "denominacao": "INSTALAÇÃO, MANUTENÇÃO E REPARAÇÃO DE ELEVADORES, ESCADAS E ESTEIRAS ROLANTES, EXCETO DE FABRICAÇÃO PRÓPRIA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E REPARAÇÃO DE ELEVADORES, ESCADAS E ESTEIRAS LOLANTES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1715",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3314702",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE EQUIPAMENTOS HIDRÁULICOS E PNEUMÁTICOS, EXCETO VÁLVULAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1763",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3314701",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS MOTRIZES NÃO-ELÉTRICAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS E MOTORES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1762",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9529199",
        "denominacao": "REPARAÇÃO E MANUTENÇÃO DE OUTROS OBJETOS E EQUIPAMENTOS PESSOAIS E DOMÉSTICOS NÃO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE AFIAÇÃO (AFIAÇÃO DE LÂMINAS)",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1754",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9529199",
        "denominacao": "REPARAÇÃO E MANUTENÇÃO DE OUTROS OBJETOS E EQUIPAMENTOS PESSOAIS E DOMÉSTICOS NÃO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "CONSERTO DE EQUIPAMENTOS DE CAMPING",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1753",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9529101",
        "denominacao": "REPARAÇÃO DE CALÇADOS, DE BOLSAS E ARTIGOS DE VIAGEM",
        "descricao": "SERVIÇOS DE REPARAÇÃO DE CALÇADOS OU ARTIGOS DE COURO",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1752",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3022900",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE EMBARCAÇÕES PARA ESPORTE E LAZER",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE VEÍCULOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1747",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3021100",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE EMBARCAÇÕES E ESTRUTURAS FLUTUANTES",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE VEÍCULOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1746",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3511401",
        "denominacao": "CONSTRUÇÃO E REPARAÇÃO DE EMBARCAÇÕES DE GRANDE PORTE",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE VEÍCULOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1745",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "2930103",
        "denominacao": "FABRICAÇÃO DE CABINES, CARROCERIAS E REBOQUES PARA OUTROS VEÍCULOS AUTOMOTORES, EXCETO CAMINHÕES E ÔNIBUS",
        "descricao": "SERVIÇOS DE BLINDAGEM DE MÁQUINAS, VEÍCULOS, APARELHOS, EQUIPAMENTOS, MOTORES, ELEVADORES OU DE QUALQUER OBJETO",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1744",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3314713",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS-FERRAMENTA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS E MOTORES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1742",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3314707",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E APARELHOS DE REFRIGERAÇÃO E VENTILAÇÃO PARA USO INDUSTRIAL E COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS E MOTORES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1738",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3314706",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS, APARELHOS E EQUIPAMENTOS PARA INSTALAÇÕES TÉRMICAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1737",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3314706",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS, APARELHOS E EQUIPAMENTOS PARA INSTALAÇÕES TÉRMICAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1736",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3311200",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE TANQUES, RESERVATÓRIOS METÁLICOS E CALDEIRAS, EXCETO PARA VEÍCULOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1735",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3311200",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE TANQUES, RESERVATÓRIOS METÁLICOS E CALDEIRAS, EXCETO PARA VEÍCULOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1734",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6323102",
        "denominacao": "MANUTENÇÃO DE AERONAVES NA PISTA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE AERONAVES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1733",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4520005",
        "denominacao": "SERVIÇOS DE LAVAGEM, LUBRIFICAÇÃO E POLIMENTO DE VEÍCULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE LUBRIFICAÇÃO, LUSTRAÇÃO, REVISÃO E RESTAURAÇÃO DE VEÍCULOS ",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1814",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5020202",
        "denominacao": "SERVIÇOS DE MANUTENÇÃO E REPARAÇÃO DE CAMINHÕES, ÔNIBUS E OUTROS VEÍCULOS PESADOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE VEÍCULOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1813",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3314704",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE COMPRESSORES",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1691",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3314703",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE VÁLVULAS INDUSTRIAIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1690",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3312102",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE APARELHOS E INSTRUMENTOS DE MEDIDA, TESTE E CONTROLE",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1713",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3312103",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE APARELHOS ELETROMÉDICOS E ELETROTERAPÊUTICOS E EQUIPAMENTOS DE IRRADIAÇÃO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1711",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3313902",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE BATERIAS E ACUMULADORES ELÉTRICOS, EXCETO PARA VEÍCULOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1707",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3313901",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE GERADORES, TRANSFORMADORES E MOTORES ELÉTRICOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1705",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3314721",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E APARELHOS PARA A INDÚSTRIA DE CELULOSE, PAPEL E PAPELÃO E ARTEFATOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS, MOTORES E EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1703",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3314720",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA A INDÚSTRIA TÊXTIL, DO VESTUÁRIO, DO COURO E CALÇADOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS, MOTORES E EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1701",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "2950600",
        "denominacao": "RECONDICIONAMENTO E RECUPERAÇÃO DE MOTORES PARA VEÍCULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE RECONDICIONAMENTO DE MOTORES",
        "item": "14",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1758",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "2212900",
        "denominacao": "REFORMA DE PNEUMÁTICOS USADOS",
        "descricao": "SERVIÇOS DE RECAUCHUTAGEM OU REGENERAÇÃO DE PNEUS",
        "item": "14",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1759",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6340103",
        "denominacao": "AGENCIAMENTO DE CARGAS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO DE CARGAS",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1764",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "1531902",
        "denominacao": "ACABAMENTO DE CALÇADOS DE COURO SOB CONTRATO",
        "descricao": "SERVIÇOS DE  CORTE E ACABAMENTO ",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1768",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "2539000",
        "denominacao": "SERVIÇOS DE USINAGEM, SOLDA, TRATAMENTO E REVESTIMENTO EM METAIS",
        "descricao": "SERVIÇOS DE ANODIZAÇÃO OU GALVANOPLASTIA",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1769",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "1822900",
        "denominacao": "SERVIÇOS DE ACABAMENTOS GRÁFICOS",
        "descricao": "SERVIÇOS DE PLASTIFICAÇÃO",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1875",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3310303",
        "denominacao": "FABRICAÇÃO DE APARELHOS E UTENSÍLIOS PARA CORREÇÃO DE DEFEITOS FÍSICOS E APARELHOS ORTOPÉDICOS EM GERAL - INCLUSIVE SOB ENCOMENDA",
        "descricao": "SERVIÇOS DE BENEFICIAMENTO DE LENTES",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1877",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "2399101",
        "denominacao": "DECORAÇÃO, LAPIDAÇÃO, GRAVAÇÃO, VITRIFICAÇÃO E OUTROS TRABALHOS EM CERÂMICA, LOUÇA, VIDRO E CRISTAL",
        "descricao": "SERVIÇOS DE LAPIDAÇÃO OU POLIMENTO",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1878",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "2722802",
        "denominacao": "RECONDICIONAMENTO DE BATERIAS E ACUMULADORES PARA VEÍCULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE RECARGA OU RECONDUÇÃO DE BATERIAS",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1876",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "1610202",
        "denominacao": "SERRARIAS SEM DESDOBRAMENTO DE MADEIRA",
        "descricao": "SERVIÇOS DE BENEFICIAMENTO DE MADEIRA",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1809",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "2539000",
        "denominacao": "SERVIÇOS DE USINAGEM, SOLDA, TRATAMENTO E REVESTIMENTO EM METAIS",
        "descricao": "SERVIÇOS DE JATEAMENTO DE BENS MÓVEIS",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1771",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8292000",
        "denominacao": "ENVASAMENTO E EMPACOTAMENTO SOB CONTRATO",
        "descricao": "SERVIÇOS DE ENVASAMENTO E EMPACOTAMENTO",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1765",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4329102",
        "denominacao": "INSTALAÇÃO DE EQUIPAMENTOS PARA ORIENTAÇÃO À NAVEGAÇÃO MARÍTIMA, FLUVIAL E LACUSTRE",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE EQUIPAMENTOS PARA ORIENTAÇÃO À NAVEGAÇÃO MARÍTIMA FLUVIAL E LACUSTRE",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1773",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "2929700",
        "denominacao": "FABRICAÇÃO DE OUTRAS MÁQUINAS E EQUIPAMENTOS DE USO GERAL - INCLUSIVE PEÇAS",
        "descricao": "SERVIÇOS DE INSTALAÇÃO MONTAGEM DE MÁQUINAS E EQUIPAMENTOS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1775",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4292802",
        "denominacao": "OBRAS DE MONTAGEM INDUSTRIAL",
        "descricao": "SERVIÇOS DE OBRAS DE MONTAGEM INDUSTRIAL",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1785",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3694399",
        "denominacao": "FABRICAÇÃO DE BRINQUEDOS E DE OUTROS JOGOS RECREATIVOS",
        "descricao": "SERVIÇOS DE MONTAGEM DE BRINQUEDOS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1784",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3329501",
        "denominacao": "SERVIÇOS DE MONTAGEM DE MÓVEIS DE QUALQUER MATERIAL",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE MÓVEIS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1780",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3531900",
        "denominacao": "CONSTRUÇÃO E MONTAGEM DE AERONAVES",
        "descricao": "SERVIÇOS DE MONTAGEM DE AERONAVES",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1779",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3031800",
        "denominacao": "FABRICAÇÃO DE LOCOMOTIVAS, VAGÕES E OUTROS MATERIAIS RODANTES",
        "descricao": "SERVIÇOS DE MONTAGEM DE LOCOMOTIVAS E VAGÕES",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1778",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3314710",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA USO GERAL NÃO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MONTAGEM INDUSTRIAL",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1777",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3314710",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA USO GERAL NÃO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MONTAGEM DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1776",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9002702",
        "denominacao": "RESTAURAÇÃO DE OBRAS-DE-ARTE",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE MOLDURAS (MOLDUREIRO)",
        "item": "14",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "1789",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "1822900",
        "denominacao": "SERVIÇOS DE ACABAMENTOS GRÁFICOS",
        "descricao": "SERVIÇOS DE PLASTIFICAÇÃO DE DOCUMENTOS",
        "item": "14",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "1790",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "1822900",
        "denominacao": "SERVIÇOS DE ACABAMENTOS GRÁFICOS",
        "descricao": "SERVIÇOS DE ENCADERNAÇÃO, GRAVAÇÃO E DOURAÇÃO",
        "item": "14",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "1791",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5020201",
        "denominacao": "SERVIÇOS DE MANUTENÇÃO E REPARAÇÃO DE AUTOMÓVEIS",
        "descricao": "SERVIÇOS DE FUNELARIA E LANTERNAGEM ",
        "item": "14",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "1800",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1803",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1804",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1806",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTÃO DE CRÉDITO OU DÉBITO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1805",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1807",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1839",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EDMINISTRAÇÃO DE CARTÃO DE CRÉDITO OU DÉBITO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1838",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1837",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1836",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1835",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1834",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS D EDMINISTRAÇÃO DE CARTÃO DE CRÉDITO OU DÉBITO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1833",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1832",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1822",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EDMINISTRAÇÃO DE CARTÃO DE CRÉDITO OU DÉBITO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1848",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1847",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1846",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1845",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1844",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EDMINISTRAÇÃO DE CARTÃO DE CRÉDITO OU DÉBITO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1843",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1842",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1841",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1840",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1941",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1940",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1939",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1938",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE EDMINISTRAÇÃO DE CARTÃO DE CRÉDITO OU DÉBITO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1937",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1859",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1850",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1849",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1821",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6613400",
        "denominacao": "ADMINISTRAÇÃO DE CARTÕES DE CRÉDITO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTÃO DE CRÉDITO OU DÉBITO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1820",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6613400",
        "denominacao": "ADMINISTRAÇÃO DE CARTÕES DE CRÉDITO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTÕES DE CRÉDITO OU DÉBITO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1819",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6493000",
        "denominacao": "ADMINISTRAÇÃO DE CONSÓRCIOS PARA AQUISIÇÃO DE BENS E DIREITOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1818",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1817",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1816",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE EDMINISTRAÇÃO DE CARTÃO DE CRÉDITO OU DÉBITO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1815",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1808",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1831",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1830",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1829",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS D EDMINISTRAÇÃO DE CARTÃO DE CRÉDITO OU DÉBITO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1828",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1827",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1826",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1825",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1824",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EDMINISTRAÇÃO DE CARTÃO DE CRÉDITO OU DÉBITO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1823",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1851",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1852",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1854",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1857",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1856",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1855",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1853",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1858",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1869",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1868",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1867",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1866",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1865",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1864",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1863",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1862",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1861",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1860",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1870",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1871",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1873",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1880",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1879",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1874",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1872",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1881",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1921",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1895",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1894",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1893",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1892",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1891",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1890",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1889",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1888",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1887",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1886",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1885",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1884",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1883",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1882",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "2000",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1999",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1998",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1997",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1896",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1897",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1899",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1902",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1901",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1900",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1898",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1903",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1904",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1905",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1907",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1910",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1909",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCF",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1908",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1906",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE RENOVAÇÃO CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1911",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "2061",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1942",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE RENOVAÇÃO CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1936",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1935",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1934",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1933",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE RENOVAÇÃO CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1932",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1931",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1920",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE RENOVAÇÃO CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "2064",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "2063",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "2062",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE RENOVAÇÃO CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1919",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1918",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1917",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1916",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE RENOVAÇÃO CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1915",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1914",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1913",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1912",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1930",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCF",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1929",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1928",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1927",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1926",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCF",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1925",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1924",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1923",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "1922",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "2118",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "2119",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1943",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "2120",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1944",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1946",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1948",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1950",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "2013",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "2012",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "2011",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "2010",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "2009",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "2008",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "2007",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "2006",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "2005",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "2015",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "2014",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "2004",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "2003",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "2002",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "2001",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1996",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1995",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1994",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1967",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1966",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1965",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1964",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1963",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1962",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1961",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1960",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1959",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1976",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1975",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1974",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1973",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1972",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1971",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1970",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1969",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1968",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1993",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1992",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1991",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1990",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1989",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1988",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1987",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1986",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1985",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1984",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1983",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1982",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1981",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1980",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1979",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1978",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1977",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1958",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1957",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1956",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1955",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1954",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1953",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1952",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1951",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1949",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1947",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "1945",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2016",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2017",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2019",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2021",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2023",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2040",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2039",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2038",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2037",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2036",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2035",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2034",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2033",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2032",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2049",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2048",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2047",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2046",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2045",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2044",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2043",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2042",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2041",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2060",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2059",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2058",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2057",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2056",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2055",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2054",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2053",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2052",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2051",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2050",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2031",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2030",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2029",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2028",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2027",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2026",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2025",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2024",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2022",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2020",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "2018",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2178",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2066",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2068",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2070",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2072",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2097",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2096",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2095",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2094",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2093",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2092",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2091",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2081",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2080",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2079",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2078",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2077",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2076",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2075",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2074",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2073",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2090",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2089",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2088",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2087",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2086",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2085",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2084",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2083",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2082",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2071",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2069",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2067",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2065",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2180",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2179",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ARRENDAMENTO MERCANTIL (LEASING)",
        "item": "15",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "2102",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE ARRENDAMENTO MERCANTIL (LEASING)",
        "item": "15",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "2103",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ARRENDAMENTO MERCANTIL (LEASING)",
        "item": "15",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "2101",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE ARRENDAMENTO MERCANTIL (LEASING)",
        "item": "15",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "2100",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE ARRENDAMENTO MERCANTIL (LEASING)",
        "item": "15",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "2099",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ARRENDAMENTO MERCANTIL (LEASING)",
        "item": "15",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "2098",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ARRENDAMENTO MERCANTIL (LEASING)",
        "item": "15",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "2105",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ARRENDAMENTO MERCANTIL (LEASING)",
        "item": "15",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "2106",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6440900",
        "denominacao": "ARRENDAMENTO MERCANTIL",
        "descricao": "SERVIÇOS DE ARRENDAMENTO MERCANTIL (LEASING)",
        "item": "15",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "2107",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ARRENDAMENTO MERCANTIL (LEASING)",
        "item": "15",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "2104",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2108",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2113",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2115",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2117",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2129",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2128",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2127",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2126",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2125",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2124",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2123",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2122",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2121",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2146",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2145",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2144",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2143",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2142",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2141",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2140",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2139",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2138",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2155",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2154",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2153",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2152",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2151",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2150",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2149",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2148",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2147",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2137",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2136",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2135",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2134",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2133",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2132",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2131",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2130",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2164",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2163",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2162",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2161",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2160",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2159",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2158",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2157",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2156",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2184",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2183",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2182",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2181",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2177",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2176",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2175",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2174",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2173",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2187",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2186",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2185",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2172",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2171",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2170",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2169",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2168",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2167",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2166",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2165",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2305",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2304",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2303",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2251",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2250",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2249",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2240",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2116",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2114",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2112",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2109",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2110",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "2111",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2188",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2195",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2204",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2203",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2202",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2201",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2200",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2199",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2198",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2197",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2196",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2217",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2216",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2215",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2214",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2213",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2212",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2211",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2210",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2209",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2208",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2207",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2206",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2205",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2232",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2231",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2230",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2229",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2228",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2227",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2226",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2225",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2224",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2223",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2222",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2221",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2220",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2219",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2218",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2194",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2189",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2191",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2192",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2193",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "2190",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "2233",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "2241",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6619301",
        "denominacao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "2243",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "2242",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "2239",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "2234",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "2236",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "2237",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "2238",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "2235",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2244",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2246",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2247",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2252",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2254",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, TRANSFERÊNCIA E CANCELAMNTO DE CARTA DE CRÉDITO DE IMPORTAÇÃO, EXPORTAÇÃO E GARANTIAS RECEBIDAS",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2256",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2258",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2260",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2269",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2268",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2267",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2266",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2265",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE ENVIO E RECEBIMENTO DE MENSAGENS RELACIONADAS À OPERAÇÕES DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2264",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, TRANSFERÊNCIA E CANCELAMNTO DE CARTA DE CRÉDITO DE IMPORTAÇÃO, EXPORTAÇÃO E GARANTIAS RECEBIDAS",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2263",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2262",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2261",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, TRANSFERÊNCIA E CANCELAMNTO DE CARTA DE CRÉDITO DE IMPORTAÇÃO, EXPORTAÇÃO E GARANTIAS RECEBIDAS",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2649",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2648",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2647",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2358",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ENVIO E RECEBIMENTO DE MENSAGENS RELACIONADAS À OPERAÇÕES DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2357",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, TRANSFERÊNCIA E CANCELAMNTO DE CARTA DE CRÉDITO DE IMPORTAÇÃO E EXPORTAÇÃO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2356",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ENVIO E RECEBIMENTO DE MENSAGENS RELACIONADAS À OPERAÇÕES DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2306",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2302",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2301",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2300",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ENVIO E RECEBIMENTO DE MENSAGENS RELACIONADAS À OPERAÇÕES DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2299",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, TRANSFERÊNCIA E CANCELAMNTO DE CARTA DE CRÉDITO DE IMPORTAÇÃO, EXPORTAÇÃO E GARANTIAS RECEBIDAS",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2298",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2297",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2296",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2295",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2294",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2293",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ENVIO E RECEBIMENTO DE MENSAGENS RELACIONADAS À OPERAÇÕES DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2292",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, TRANSFERÊNCIA E CANCELAMNTO DE CARTA DE CRÉDITO DE IMPORTAÇÃO, EXPORTAÇÃO E GARANTIAS RECEBIDAS",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2291",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2290",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2289",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2288",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2287",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2286",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE ENVIO E RECEBIMENTO DE MENSAGENS RELACIONADAS À OPERAÇÕES DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2285",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, TRANSFERÊNCIA E CANCELAMNTO DE CARTA DE CRÉDITO DE IMPORTAÇÃO E EXPORTAÇÃO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2284",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2283",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2282",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2281",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2280",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2279",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ENVIO E RECEBIMENTO DE MENSAGENS RELACIONADAS À OPERAÇÕES DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2278",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, TRANSFERÊNCIA E CANCELAMNTO DE CARTA DE CRÉDITO DE IMPORTAÇÃO E EXPORTAÇÃO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2277",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2276",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2275",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2274",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2273",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2272",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ENVIO E RECEBIMENTO DE MENSAGENS RELACIONADAS À OPERAÇÕES DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2271",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, TRANSFERÊNCIA E CANCELAMNTO DE CARTA DE CRÉDITO DE IMPORTAÇÃO, EXPORTAÇÃO E GARANTIAS RECEBIDAS",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2270",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2259",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE ENVIO E RECEBIMENTO DE MENSAGENS RELACIONADAS À OPERAÇÕES DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2257",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2255",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2253",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2248",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2245",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2307",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2314",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2323",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2322",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2321",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2320",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2319",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2318",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2317",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2316",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2315",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2332",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2331",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2330",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2329",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2328",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2327",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2326",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2325",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2324",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2341",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2340",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2339",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2338",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2337",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2336",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2335",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2334",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2333",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2342",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2313",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2308",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2310",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2311",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2312",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "2309",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "2343",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "2590",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "2589",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "2588",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "2355",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "2354",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "2353",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "2352",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "2351",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "2350",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "2599",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "2598",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "2597",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "2596",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "2595",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "2594",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "2593",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "2592",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "2591",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "2601",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "2600",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "2345",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "2346",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "2347",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "2349",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "2348",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "2344",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "2602",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "2604",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "2605",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "2607",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "2609",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "2611",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "2613",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "2615",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "2619",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "2618",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "2617",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "2616",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "2614",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "2612",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "2610",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "2608",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "2606",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "2603",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2530",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2537",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2628",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2627",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2626",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2625",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2624",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE CANCELAMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2623",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2622",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2621",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2620",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2645",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2644",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE CANCELAMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2643",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2642",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2641",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2640",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE CANCELAMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2639",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2638",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2637",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2646",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2636",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2635",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2634",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2633",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2632",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2631",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2630",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2629",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2538",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2536",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2531",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2533",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2534",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2535",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "2532",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2539",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2477",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2581",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2580",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2579",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2578",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2577",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2576",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2575",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6431000",
        "denominacao": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2574",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2573",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2572",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CRÉDITO IMOBILIÁRIO",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2554",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2587",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2586",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2585",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6433600",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2584",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2583",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6432800",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2582",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2553",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2552",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2551",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2550",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424704",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2549",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2548",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2547",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2546",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2571",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2570",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6423900",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2569",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2568",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2567",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2566",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2565",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6422100",
        "denominacao": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2564",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2563",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2562",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2561",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2560",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6421200",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2559",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CRÉDITO IMOBILIÁRIO",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2558",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CRÉDITO IMOBILIÁRIO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2557",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CRÉDITO IMOBILIÁRIO",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2556",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6435201",
        "denominacao": "SOCIEDADES DE CRÉDITO IMOBILIÁRIO",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2555",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2541",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2542",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2543",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2545",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2544",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6424701",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2540",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4921301",
        "denominacao": "TRANSPORTE RODOVIÁRIO COLETIVO DE PASSAGEIROS, COM ITINERÁRIO FIXO, MUNICIPAL",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL (TARIFA DO STCM)",
        "item": "16",
        "subitem": "1",
        "aliquota": ",01",
        "idcnae": "2481",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5091201",
        "denominacao": "TRANSPORTE POR NAVEGAÇÃO DE TRAVESSIA, MUNICIPAL",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2498",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4912403",
        "denominacao": "TRANSPORTE METROVIÁRIO",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2480",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4912402",
        "denominacao": "TRANSPORTE FERROVIÁRIO DE PASSAGEIROS MUNICIPAL E EM REGIÃO METROPOLITANA",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2479",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5229002",
        "denominacao": "SERVIÇOS DE REBOQUE DE VEÍCULOS",
        "descricao": "SERVIÇOS DE REBOQUE DE VEÍCULOS",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2478",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5112901",
        "denominacao": "SERVIÇO DE TÁXI AÉREO E LOCAÇÃO DE AERONAVES COM TRIPULAÇÃO",
        "descricao": "SERVIÇO DE TRANSPORTE AÉREO DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2502",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5021101",
        "denominacao": "TRANSPORTE POR NAVEGAÇÃO INTERIOR DE CARGA, MUNICIPAL, EXCETO TRAVESSIA",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2497",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4921301",
        "denominacao": "TRANSPORTE RODOVIÁRIO COLETIVO DE PASSAGEIROS, COM ITINERÁRIO FIXO, MUNICIPAL",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2482",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6111500",
        "denominacao": "TRANSPORTE MARÍTIMO DE CABOTAGEM",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2494",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6112300",
        "denominacao": "TRANSPORTE MARÍTIMO DE LONGO CURSO",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2495",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6121201",
        "denominacao": "TRANSPORTE POR NAVEGAÇÃO INTERIOR DE PASSAGEIROS, MUNICIPAL, NÃO URBANO",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2496",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "4921302",
        "denominacao": "TRANSPORTE RODOVIÁRIO COLETIVO DE PASSAGEIROS, COM ITINERÁRIO FIXO, INTERMUNICIPAL EM REGIÃO METROPOLITANA",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2483",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8299701",
        "denominacao": "MEDIÇÃO DE CONSUMO DE ENERGIA ELÉTRICA, GÁS E ÁGUA",
        "descricao": "SERVIÇOS DE MEDIÇÃO DE CONSUMO DE ENERGIA ELÉTRICA, GÁS E ÁGUA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2416",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6340104",
        "denominacao": "ORGANIZAÇÃO LOGÍSTICA DO TRANSPORTE DE CARGA - OPERADOR DE TRANSPORTE MULTIMODAL",
        "descricao": "SERVIÇOS DE ORGANIZAÇÃO LOGÍSTICA DE TRANSPORTE DE CARGAS",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2504",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7499399",
        "denominacao": "OUTROS SERVIÇOS PRESTADOS PRINCIPALMENTE ÀS EMPRESAS",
        "descricao": "SERVIÇOS DE DATILOGRAFIA",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "2514",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7499399",
        "denominacao": "OUTROS SERVIÇOS PRESTADOS PRINCIPALMENTE ÀS EMPRESAS",
        "descricao": "SERVIÇOS DE RESPOSTAS AUDÍVEIS",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "2517",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7499399",
        "denominacao": "OUTROS SERVIÇOS PRESTADOS PRINCIPALMENTE ÀS EMPRESAS",
        "descricao": "SERVIÇOS DE EXPEDIENTE",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "2515",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7499399",
        "denominacao": "OUTROS SERVIÇOS PRESTADOS PRINCIPALMENTE ÀS EMPRESAS",
        "descricao": "SERVIÇOS ESTENOGRAFIA",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "2516",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7740300",
        "denominacao": "GESTÃO DE ATIVOS INTANGÍVEIS NÃO-FINANCEIROS",
        "descricao": "SERVIÇOS DE FRANQUIAS (FRANCHISING)",
        "item": "17",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "2522",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6599407",
        "denominacao": "GESTÃO DE FUNDOS PARA FINS DIVERSOS - EXCETO INVESTIMENTOS",
        "descricao": "SERVIÇOS DE GESTÃO DE FUNDOS",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "2420",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7740300",
        "denominacao": "GESTÃO DE ATIVOS INTANGÍVEIS NÃO-FINANCEIROS",
        "descricao": "SERVIÇOS DE LICENCIAMENTO, COMPRA E VENDA E LEASING DE ATIVOS INTANGÍVEIS NÃO FINANCEIROS",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "2529",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9603301",
        "denominacao": "GESTÃO E MANUTENÇÃO DE CEMITÉRIOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "2528",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3701100",
        "denominacao": "GESTÃO DE REDES DE ESGOTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "2427",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9000002",
        "denominacao": "GESTÃO DE ATERROS SANITÁRIOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "2426",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6611802",
        "denominacao": "BOLSA DE MERCADORIAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "2421",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8112500",
        "denominacao": "CONDOMÍNIOS PREDIAIS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONDOMÍNIOS",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "2425",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8299704",
        "denominacao": "LEILOEIROS INDEPENDENTES",
        "descricao": "SERVIÇOS DE REALIZAÇÕES DE LEILÕES",
        "item": "17",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "2428",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6621502",
        "denominacao": "AUDITORIA E CONSULTORIA ATUARIAL",
        "descricao": "SERVIÇOS ATUARIAIS E CÁLCULOS TÉCNICOS DE QUALQUER NATUREZA",
        "item": "17",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "2433",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7320300",
        "denominacao": "PESQUISAS DE MERCADO E DE OPINIÃO PÚBLICA",
        "descricao": "SERVIÇOS DE ESTATÍSTICA  ",
        "item": "17",
        "subitem": "21",
        "aliquota": "5",
        "idcnae": "2437",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6491300",
        "denominacao": "SOCIEDADES DE FOMENTO MERCANTIL - FACTORING",
        "descricao": "SERVIÇOS DE FACTORING",
        "item": "17",
        "subitem": "23",
        "aliquota": "5",
        "idcnae": "2439",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6622300",
        "denominacao": "CORRETORES E AGENTES DE SEGUROS, DE PLANOS DE PREVIDÊNCIA COMPLEMENTAR E DE SAÚDE",
        "descricao": "SERVIÇOS DE REGULAÇÃO DE SINISTROS VINCULADOS A CONTRATOS DE SEGUROS",
        "item": "18",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2441",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6621501",
        "denominacao": "PERITOS E AVALIADORES DE SEGUROS",
        "descricao": "SERVIÇOS DE INSPEÇÃO E AVALIAÇÃO DE RISCOS PARA COBERTURA DE CONTRATOS DE SEGUROS",
        "item": "18",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2442",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6621501",
        "denominacao": "PERITOS E AVALIADORES DE SEGUROS",
        "descricao": "SERVIÇOS DE REGULAÇÃO DE SINISTROS VINCULADOS À CONTRATOS DE SEGUROS  ",
        "item": "18",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2443",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6621501",
        "denominacao": "PERITOS E AVALIADORES DE SEGUROS",
        "descricao": "SERVIÇOS DE PERÍCIAS PARA COBERTURA DE CONTRATOS DE SEGUROS",
        "item": "18",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2444",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6629100",
        "denominacao": "ATIVIDADES AUXILIARES DOS SEGUROS, DA PREVIDÊNCIA COMPLEMENTAR E DOS PLANOS DE SAÚDE NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE PREVENÇÃO E GERÊNCIA DE RISCOS SEGURÁVEIS",
        "item": "18",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2445",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8299706",
        "denominacao": "CASAS LOTÉRICAS",
        "descricao": "SERVIÇOS DE DISTRIBUIÇÃO DE BILHETES E DEMAIS PRODUTOS DE LOTERIA, BINGOS, PULES OU CUPONS DE APOSTAS, CARTÕES, SORTEIOS, APOSTAS E PRÊMIOS",
        "item": "19",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2446",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8299706",
        "denominacao": "CASAS LOTÉRICAS",
        "descricao": "SERVIÇOS DE VENDA DE BILHETES E DEMAIS PRODUTOS DE LOTERIA, BINGOS, PULES OU CUPONS DE APOSTAS, CARTÕES, SORTEIO, APOSTAS E PRÊMIOS",
        "item": "19",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2447",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5222200",
        "denominacao": "TERMINAIS RODOVIÁRIOS E FERROVIÁRIOS",
        "descricao": "SERVIÇOS FERROPORTUÁRIOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2448",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6322302",
        "denominacao": "REBOCAGEM EM ESTUÁRIOS E PORTOS",
        "descricao": "SERVIÇOS DE REBOQUE DE EMBARCAÇÕES",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2451",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6322301",
        "denominacao": "OPERAÇÃO DE PORTOS E TERMINAIS",
        "descricao": "SERVIOS DE UTILIZAÇÃO DE PORTOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2450",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6322301",
        "denominacao": "OPERAÇÃO DE PORTOS E TERMINAIS",
        "descricao": "SERVIÇOS PORTUÁRIOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2449",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6322302",
        "denominacao": "REBOCAGEM EM ESTUÁRIOS E PORTOS",
        "descricao": "SERVIÇOS DE REBOCADOR ESCOTEIRO",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2452",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6322399",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIÁRIOS",
        "descricao": "SERVIÇOS DE ARMADORES",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2454",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6322399",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIÁRIOS",
        "descricao": "SERVIÇOS DE ATRACAÇÃO E DESATRACAÇÃO",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2456",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6322399",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIÁRIOS",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO AO LARGO",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2463",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6322399",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIÁRIOS",
        "descricao": "SERVIÇOS DE PRATICAGEM",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2462",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6322399",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIÁRIOS",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO DE PASSAGEIROS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2461",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6322399",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIÁRIOS",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO DE MERCADORIAS EM PORTOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2460",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6322399",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIÁRIOS",
        "descricao": "SERVIÇOS DE LOGISTICA E CONFERÊNCIAS EM PORTOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2459",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6322399",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIÁRIOS",
        "descricao": "SERVIÇOS DE ESTIVA",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2458",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6322399",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIÁRIOS",
        "descricao": "SERVIÇOS DE CAPATAZIA",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2457",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6322399",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIÁRIOS",
        "descricao": "SERVIÇOS DE ARMAZENAGEM EM PORTOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2455",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6322399",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIÁRIOS",
        "descricao": "SERVIÇOS DE APOIO MARÍTIMO",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2453",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5240101",
        "denominacao": "OPERAÇÃO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS AEROPORTUÁRIOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "2464",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5240199",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AÉREOS, EXCETO OPERAÇÃO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE APOIO AEROPORTUÁRIOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "2467",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5240101",
        "denominacao": "OPERAÇÃO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE UTILIZAÇÃO DE AEROPORTOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "2466",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5240101",
        "denominacao": "OPERAÇÃO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO AEROPORTUÁRIA",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "2465",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5240199",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AÉREOS, EXCETO OPERAÇÃO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE ARMAZENAGEM EM AEROPORTOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "2468",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5240199",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AÉREOS, EXCETO OPERAÇÃO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO DE AERONAVES",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "2470",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5240199",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AÉREOS, EXCETO OPERAÇÃO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO DE PASSAGEIROS EM AEROPORTOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "2472",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5240199",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AÉREOS, EXCETO OPERAÇÃO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE CAPATAZIA",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "2473",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5240199",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AÉREOS, EXCETO OPERAÇÃO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO DE MERCADORIAS EM AEROPORTOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "2471",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5240199",
        "denominacao": "ATIVIDADES AUXILIARES DOS TRANSPORTES AÉREOS, EXCETO OPERAÇÃO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE LOGISTICA E CONFERÊNCIAS EM AEROPORTOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "2469",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5229099",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES TERRESTRES NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO DE MERCADORIAS EM  TERMINAIS RODOVIÁRIOS, FERROVIÁRIOS E METROVIÁRIOS",
        "item": "20",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "2409",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5222200",
        "denominacao": "TERMINAIS RODOVIÁRIOS E FERROVIÁRIOS",
        "descricao": "SERVIÇOS DE TERMINAIS FERROVIÁRIOS",
        "item": "20",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "2474",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5229099",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES TERRESTRES NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO DE PASSAGEIROS EM  TERMINAIS RODOVIÁRIOS, FERROVIÁRIOS E METROVIÁRIOS",
        "item": "20",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "2476",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5222200",
        "denominacao": "TERMINAIS RODOVIÁRIOS E FERROVIÁRIOS",
        "descricao": "SERVIÇOS DE TERMINAIS RODOVIÁRIOS",
        "item": "20",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "2475",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5229099",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES TERRESTRES NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE TERMINAIS METROVIÁRIOS",
        "item": "20",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "2410",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6912500",
        "denominacao": "CARTÓRIOS",
        "descricao": "SERVIÇOS DE REGISTROS PÚBLICOS, CARTORIAIS E NOTORIAIS",
        "item": "21",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2411",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5221400",
        "denominacao": "CONCESSIONÁRIAS DE RODOVIAS, PONTES, TÚNEIS E SERVIÇOS RELACIONADOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE RODOVIA MEDIANTE COBRANÇA DE PREÇO OU PEDÁGIO DOS USUÁRIOS",
        "item": "22",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2412",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9529102",
        "denominacao": "CHAVEIROS",
        "descricao": "SERVIÇOS DE CHAVEIROS (CHAVEIRO)",
        "item": "24",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2360",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3699499",
        "denominacao": "FABRICAÇÃO DE PRODUTOS DIVERSOS",
        "descricao": "SERVIÇOS DE CHAVEIROS",
        "item": "24",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2362",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3299002",
        "denominacao": "FABRICAÇÃO DE CANETAS, LÁPIS E OUTROS ARTIGOS PARA ESCRITÓRIO",
        "descricao": "SERVIÇOS DE CONFECÇÃO DE CARIMBOS",
        "item": "24",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2361",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9603303",
        "denominacao": "SERVIÇOS DE SEPULTAMENTO",
        "descricao": "SERVIÇOS DE FUNERAIS, INCLUSIVE FORNECIMENTO DE CAIXÃO, URNA E ESQUIFES",
        "item": "25",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2365",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9603399",
        "denominacao": "ATIVIDADES FUNERÁRIAS E SERVIÇOS RELACIONADOS NÃO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE TRANSPORTE DE CORPO CADAVÉRICO",
        "item": "25",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2371",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9603399",
        "denominacao": "ATIVIDADES FUNERÁRIAS E SERVIÇOS RELACIONADOS NÃO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE FORMECIMENTO DE VÉU E OUTROS ADORNOS",
        "item": "25",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2372",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9603399",
        "denominacao": "ATIVIDADES FUNERÁRIAS E SERVIÇOS RELACIONADOS NÃO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE DESENBARAÇO DE CERTIDÃO DE ÓBITO",
        "item": "25",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2370",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9603304",
        "denominacao": "SERVIÇOS DE FUNERÁRIAS",
        "descricao": "SERVIÇOS DE FUNERAIS, INCLUSIVE FORNECIMENTO DE CAIXÃO, URNA E ESQUIFES",
        "item": "25",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2366",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9603305",
        "denominacao": "SERVIÇOS DE SOMATOCONSERVAÇÃO",
        "descricao": "SERVIÇOS DE EMBALSAMENTO, EMBELEZAMENTO, CONSERVAÇÃO OU RESTAURAÇÃO DE CADÁVERES",
        "item": "25",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2367",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9603399",
        "denominacao": "ATIVIDADES FUNERÁRIAS E SERVIÇOS RELACIONADOS NÃO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE FORMECIMENTO DE FLORES EM FUNERAIS, COROAS E OUTROS PARAMENTOS",
        "item": "25",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2369",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9603399",
        "denominacao": "ATIVIDADES FUNERÁRIAS E SERVIÇOS RELACIONADOS NÃO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "ALUGUEL DE CAPELA",
        "item": "25",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2368",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9603302",
        "denominacao": "SERVIÇOS DE CREMAÇÃO",
        "descricao": "SERVIÇOS DE CREMAÇÃO DE CORPOS E PARTE DE CORPOS CADAVÉRICOS",
        "item": "25",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "2373",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6511102",
        "denominacao": "PLANOS DE AUXÍLIO-FUNERAL",
        "descricao": "PLANOS OU CONVÊNIOS FUNERÁRIOS",
        "item": "25",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "2374",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9603301",
        "denominacao": "GESTÃO E MANUTENÇÃO DE CEMITÉRIOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE JAZIDOS  E CEMITÉRIOS",
        "item": "25",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "2375",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5310501",
        "denominacao": "ATIVIDADES DO CORREIO NACIONAL",
        "descricao": "SERVIÇO DE COLETA, REMESSA OU ENTREGA DE CORRESPONDÊNCIAS, DOCUMENTOS, OBJETOS, BENS OU VALORES",
        "item": "26",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2376",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7210000",
        "denominacao": "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIÊNCIAS FÍSICAS E NATURAIS",
        "descricao": "SERVIÇOS DE BIOLOGIA",
        "item": "30",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2382",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7210000",
        "denominacao": "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIÊNCIAS FÍSICAS E NATURAIS",
        "descricao": "SERVIÇOS DE QUÍMICA",
        "item": "30",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2384",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7210000",
        "denominacao": "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIÊNCIAS FÍSICAS E NATURAIS",
        "descricao": "SERVIÇOS DE BIOTECNOLOGIA",
        "item": "30",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2383",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7111100",
        "denominacao": "SERVIÇOS DE ARQUITETURA",
        "descricao": "SERVIÇOS TÉCNICOS EM EDIFICAÇÕES",
        "item": "31",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2385",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVIÇOS DE ENGENHARIA",
        "descricao": "SERVIÇOS TÉCNICOS EM ELETROTÉCNICA",
        "item": "31",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2387",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7112000",
        "denominacao": "SERVIÇOS DE ENGENHARIA",
        "descricao": "SERVIÇOS TÉCNICOS EM TELECOMUNICAÇÕES",
        "item": "31",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2389",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5250802",
        "denominacao": "ATIVIDADES DE DESPACHANTES ADUANEIROS",
        "descricao": "SERVIÇOS DE DESPACHANTE ADUANEIRO",
        "item": "33",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2391",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "5250801",
        "denominacao": "COMISSARIA DE DESPACHOS",
        "descricao": "SERVIÇOS DE COMISSÁRIOS",
        "item": "33",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2392",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8030700",
        "denominacao": "ATIVIDADES DE INVESTIGAÇÃO PARTICULAR",
        "descricao": "SERVIÇOS DE DETETIVE",
        "item": "34",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2394",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "8030700",
        "denominacao": "ATIVIDADES DE INVESTIGAÇÃO PARTICULAR",
        "descricao": "SERVIÇOS DE INVESTIGAÇÕES PARTICULARES",
        "item": "34",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2395",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7420999",
        "denominacao": "OUTROS SERVIÇOS TÉCNICOS ESPECIALIZADOS",
        "descricao": "SERVIÇOS DE METEOROLOGIA",
        "item": "36",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2400",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "7499399",
        "denominacao": "OUTROS SERVIÇOS PRESTADOS PRINCIPALMENTE ÀS EMPRESAS",
        "descricao": "SERVIÇOS DE MANEQUINS  ",
        "item": "37",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2403",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3211601",
        "denominacao": "LAPIDAÇÃO DE GEMAS",
        "descricao": "LAPIDADOR",
        "item": "39",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2406",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "3211601",
        "denominacao": "LAPIDAÇÃO DE GEMAS",
        "descricao": "SERVIÇOS DE OURIVESARIA E LAPIDAÇÃO (LAPIDADOR)",
        "item": "39",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "2408",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9999999",
        "denominacao": "CÓDIGO INVALIDO",
        "descricao": "CODIGO INVÁLIDO",
        "item": "99",
        "subitem": "99",
        "aliquota": "9900",
        "idcnae": "2700",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "9999999",
        "denominacao": "CNAE INVÁLIDO",
        "descricao": "CNAE INVÁLIDO",
        "item": "1000",
        "subitem": "1",
        "aliquota": "0",
        "idcnae": "2418",
        "dtcancelamento": "20101231"
    },
    {
        "subclasse": "6204000",
        "denominacao": "CONSULTORIA EM TECNOLOGIA DA INFORMACAO",
        "descricao": "SERVIÇO DE ANÁLISE E DESENVOLVIMENTO  DE SISTEMA (SOFTWARE)",
        "item": "1",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "11243",
        "dtcancelamento": "20100101"
    },
    {
        "subclasse": "6204000",
        "denominacao": "CONSULTORIA EM TECNOLOGIA DA INFORMAÇÃO",
        "descricao": "SERVIÇOS DE CONSULTORIA EM HADWARE",
        "item": "1",
        "subitem": "6",
        "aliquota": "2",
        "idcnae": "1630",
        "dtcancelamento": "20100101"
    },
    {
        "subclasse": "7229000",
        "denominacao": "DESENVOLVIMENTO DE SOFTWARE SOB ENCOMENDA E OUTRAS CONSULTORIAS EM SOFTWARE.",
        "descricao": "SERVIÇOS DE PROGRAMAÇÃO",
        "item": "1",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "225",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7230300",
        "denominacao": "PROCESSAMENTO DE DADOS",
        "descricao": "SERVIÇO DE COLETA E PROCESSAMENTO DE DADOS",
        "item": "1",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "226",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7229000",
        "denominacao": "DESENVOLVIMENTO DE SOFTWARE SOB ENCOMENDA E OUTRAS CONSULTORIAS EM SOFTWARE.",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE PROGRAMAS DE COMPUTADOR, INCLUSIVE DE JOGOS ELETRÔNICOS",
        "item": "1",
        "subitem": "4",
        "aliquota": "2",
        "idcnae": "278",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7210900",
        "denominacao": "CONSULTORIA EM HARDWARE.",
        "descricao": "SERVIÇOS DE CONSULTORIA EM HADWARE",
        "item": "1",
        "subitem": "6",
        "aliquota": "2",
        "idcnae": "280",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7229000",
        "denominacao": "DESENVOLVIMENTO DE SOFTWARE SOB ENCOMENDA E OUTRAS CONSULTORIAS EM SOFTWARE.",
        "descricao": "SERVIÇOS DE CONSULTORIA EM SOFTWARE",
        "item": "1",
        "subitem": "6",
        "aliquota": "2",
        "idcnae": "281",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7229000",
        "denominacao": "DESENVOLVIMENTO DE SOFTWARE SOB ENCOMENDA E OUTRAS CONSULTORIAS EM SOFTWARE.",
        "descricao": "SERVIÇOS DE PLANEJAMENTO, CONFECÇÃO, MANUTENÇÃO E ATUALIZAÇÃO DE PÁGINAS ELETRÔNICAS",
        "item": "1",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "178",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7240000",
        "denominacao": "ATIVIDADES DE BANCO DE DADOS E DISTRIBUIÇÃO ON LINE DE CONTEÚDO ELETRÔNICO.",
        "descricao": "SERVIÇOS DE PLANEJAMENTO, CONFECÇÃO, MANUTENÇÃO E ATUALIZAÇÃO DE PÁGINAS ELETRÔNICAS",
        "item": "1",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "179",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7320200",
        "denominacao": "PESQUISA E DESENVOLVIMENTO DAS CIÊNCIAS SOCIAIS E HUMANAS",
        "descricao": "SERVIÇOS DE PESQUISAS E DESENVOLVIMENTO",
        "item": "2",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "180",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7413600",
        "denominacao": "PESQUISAS DE MERCADO E DE OPINIÃO PÚBLICA",
        "descricao": "SERVIÇOS DE PESQUISA E DESENVOLVIMENTO",
        "item": "2",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "181",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6593501",
        "denominacao": "LICENCIAMENTO, COMPRA E VENDA E LEASING DE ATIVOS INTANGÍVEIS NÃO FINANCEIROS - EXCETO DIREITOS AUTORAIS",
        "descricao": "SERVIÇOS DE CESSÃO DE DIREITO DE USO DE MARCAS E DE SINAIS DE PROPAGANDA",
        "item": "3",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "182",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499313",
        "denominacao": "CASAS DE FESTAS E EVENTOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE AUDITÓRIOS E CASAS DE ESPETÁCULOS",
        "item": "3",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "183",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499313",
        "denominacao": "CASAS DE FESTAS E EVENTOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE SALÕES DE FESTAS",
        "item": "3",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "187",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499313",
        "denominacao": "CASAS DE FESTAS E EVENTOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE QUADRAS ESPORTIVAS, ESTÁDIOS, GINÁSIOS E CANCHAS",
        "item": "3",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "186",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499313",
        "denominacao": "CASAS DE FESTAS E EVENTOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE CENTRO DE CONVENÇÕES OU STANDS",
        "item": "3",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "184",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499313",
        "denominacao": "CASAS DE FESTAS E EVENTOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE PARQUES DE DIVERSÕES",
        "item": "3",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "185",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6321502",
        "denominacao": "OPERAÇÃO DE PONTES, TÚNEIS , RODOVIAS E SERVIÇOS RELACIONADOS.",
        "descricao": "SERVIÇOS DE LOCAÇÃO, SUBLOCAÇÃO, ARRENDAMENTO, DIREITO DE USO OU PERMISSÃO DE USO DE DUTOS E CONDUTOS",
        "item": "3",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "188",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6321502",
        "denominacao": "OPERAÇÃO DE PONTES, TÚNEIS , RODOVIAS E SERVIÇOS RELACIONADOS.",
        "descricao": "SERVIÇOS DE LOCAÇÃO, SUBLOCAÇÃO, ARRENDAMENTO, DIREITO DE USO OU PERMISSÃO DE USO DE FERROVIA",
        "item": "3",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "189",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7020300",
        "denominacao": "ALUGUEL DE IMÓVEIS",
        "descricao": "SERVIÇOS DE LOCAÇÃO, SUBLOCAÇÃO, ARRENDAMENTO, DIREITO DE USO OU PERMISSÃO DE USO DE POSTES",
        "item": "3",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "191",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6321502",
        "denominacao": "OPERAÇÃO DE PONTES, TÚNEIS , RODOVIAS E SERVIÇOS RELACIONADOS.",
        "descricao": "SERVIÇOS DE LOCAÇÃO, SUBLOCAÇÃO, ARRENDAMENTO, DIREITO DE USO OU PERMISSÃO DE USO DE RODOVIA",
        "item": "3",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "190",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9231299",
        "denominacao": "OUTROS SERVIÇOS ESPECIALIZADOS LIGADOS ÀS ATIVIDADES ARTÍSTICAS",
        "descricao": "SERVIÇOS DE CESSÃO DE ANDAIMES, PALCOS, COBERTURAS E OUTRAS ESTRUTURAS DE USO TEMPORÁRIO",
        "item": "3",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "192",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8513801",
        "denominacao": "ATIVIDADES DE CLÍNICA MÉDICA (CLÍNICAS, CONSULTÓRIOS E AMBULATÓRIOS)",
        "descricao": "SERVIÇOS DE MEDICINA ",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "193",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8511100",
        "denominacao": "ATIVIDADES DE ATENDIMENTO HOSPITALAR",
        "descricao": "SERVIÇOS DE ASSISTÊNCIA MÉDICO-HOSPITALAR",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "195",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8515499",
        "denominacao": "OUTRAS ATIVIDADES DE SERVIÇOS PROFISSIONAIS DA ÁREA DE SAÚDE",
        "descricao": "SERVIÇOS DE PSIQUIATRIA",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "196",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8513801",
        "denominacao": "ATIVIDADES DE CLÍNICA MÉDICA (CLÍNICAS, CONSULTÓRIOS E AMBULATÓRIOS)",
        "descricao": "SERVIÇOS DE BIOMEDICINA",
        "item": "4",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "194",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8513801",
        "denominacao": "ATIVIDADES DE CLÍNICA MÉDICA (CLÍNICAS, CONSULTÓRIOS E AMBULATÓRIOS)",
        "descricao": "SERVIÇOS DE ULTRA-SONOGRAFIA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "197",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8514602",
        "denominacao": "ATIVIDADES DOS LABORATÓRIOS DE ANÁLISES CLÍNICAS",
        "descricao": "SERVIÇOS DE ANÁLISES CLÍNICAS",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "199",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8514604",
        "denominacao": "SERVIÇOS DE RAIO-X, RADIODIAGNÓSTICO E RADIOTERAPIA",
        "descricao": "SERVIÇOS DE ELETRECIDADE-MÉDICA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "201",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8514604",
        "denominacao": "SERVIÇOS DE RAIO-X, RADIODIAGNÓSTICO E RADIOTERAPIA",
        "descricao": "SERVIÇOS DE RADIOTERAPIA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "203",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8514699",
        "denominacao": "OUTRAS ATIVIDADES DE SERVIÇOS DE COMPLEMENTAÇÃO DIAGNÓSTICA E TERAPÊUTICA",
        "descricao": "SERVIÇOS DE RESSONÂNCIA MAGNÉTICA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "206",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8514605",
        "denominacao": "SERVIÇOS DE QUIMIOTERAPIA",
        "descricao": "SERVIÇOS DE QUIMIOTERAPIA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "205",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8514604",
        "denominacao": "SERVIÇOS DE RAIO-X, RADIODIAGNÓSTICO E RADIOTERAPIA",
        "descricao": "SERVIÇOS DE TOMOGRAFIA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "204",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8514604",
        "denominacao": "SERVIÇOS DE RAIO-X, RADIODIAGNÓSTICO E RADIOTERAPIA",
        "descricao": "SERVIÇOS DE RADIOLOGIA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "202",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8514604",
        "denominacao": "SERVIÇOS DE RAIO-X, RADIODIAGNÓSTICO E RADIOTERAPIA",
        "descricao": "SERVIÇOS DE ABREUGRAFIA E RAIO X",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "200",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8514601",
        "denominacao": "ATIVIDADES DOS LABORATÓRIOS DE ANATOMIA PATOLÓGICA/CITOLÓGICA",
        "descricao": "SERVIÇOS DE PATOLOGIA",
        "item": "4",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "198",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3340504",
        "denominacao": "SERVIÇOS DE LABORATÓRIOS ÓPTICOS",
        "descricao": "SERVIÇOS DE LABORATÓRIOS",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "207",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8514602",
        "denominacao": "ATIVIDADES DOS LABORATÓRIOS DE ANÁLISES CLÍNICAS",
        "descricao": "SERVIÇOS LABORATORIAIS",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "211",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8532402",
        "denominacao": "CENTROS DE REABILITAÇÃO PARA DEPENDENTES QUÍMICOS SEM ALOJAMENTO",
        "descricao": "SERVIÇOS DE REABILITAÇÃO DE DEPENDENTES QUÍMICOS",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "217",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8512000",
        "denominacao": "ATIVIDADES DE ATENDIMENTO A URGÊNCIAS E EMERGÊNCIAS",
        "descricao": "SERVIÇOS DE PRONTO-SOCORRO",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "216",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8511100",
        "denominacao": "ATIVIDADES DE ATENDIMENTO HOSPITALAR",
        "descricao": "SERVIÇOS HOSPITALARES, DE CASA DE SAÚDE OU MATERNIDADE",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "215",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8511100",
        "denominacao": "ATIVIDADES DE ATENDIMENTO HOSPITALAR",
        "descricao": "SERVIÇOS DE SANATÓRIOS",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "214",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8511100",
        "denominacao": "ATIVIDADES DE ATENDIMENTO HOSPITALAR",
        "descricao": "SERVIÇOS DE MANICÔMIO",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "213",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8514603",
        "denominacao": "SERVIÇOS DE DIÁLISE",
        "descricao": "SERVIÇOS DE DIÁLISE",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "212",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8513803",
        "denominacao": "SERVIÇOS DE VACINAÇÃO E IMUNIZAÇÃO HUMANA",
        "descricao": "SERVIÇOS DE VACINAÇÃO E IMUNIZAÇÃO HUMANA",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "210",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8513801",
        "denominacao": "ATIVIDADES DE CLÍNICA MÉDICA (CLÍNICAS, CONSULTÓRIOS E AMBULATÓRIOS)",
        "descricao": "SERVIÇOS AMBULATORIAIS",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "208",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8513801",
        "denominacao": "ATIVIDADES DE CLÍNICA MÉDICA (CLÍNICAS, CONSULTÓRIOS E AMBULATÓRIOS)",
        "descricao": "SERVIÇOS DE MEDICINA (CLINICAS MÉDICAS)",
        "item": "4",
        "subitem": "3",
        "aliquota": "2",
        "idcnae": "209",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8516299",
        "denominacao": "OUTRAS ATIVIDADES RELACIONADAS COM A ATENÇÃO À SAÚDE",
        "descricao": "SERVIÇOS DE INSTRUMENTAÇÃO CIRÚRGICA",
        "item": "4",
        "subitem": "4",
        "aliquota": "2",
        "idcnae": "218",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8516202",
        "denominacao": "SERVIÇOS DE ACUPUNTURA",
        "descricao": "SERVIÇOS DE ACUPUNTURA",
        "item": "4",
        "subitem": "5",
        "aliquota": "2",
        "idcnae": "219",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8515401",
        "denominacao": "SERVIÇOS DE ENFERMAGEM",
        "descricao": "SERVIÇOS DE ENFERMAGEM",
        "item": "4",
        "subitem": "6",
        "aliquota": "2",
        "idcnae": "220",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8515499",
        "denominacao": "OUTRAS ATIVIDADES DE SERVIÇOS PROFISSIONAIS DA ÁREA DE SAÚDE",
        "descricao": "SERVIÇOS DE ESTERELIZAÇÃO",
        "item": "4",
        "subitem": "6",
        "aliquota": "2",
        "idcnae": "221",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8515499",
        "denominacao": "OUTRAS ATIVIDADES DE SERVIÇOS PROFISSIONAIS DA ÁREA DE SAÚDE",
        "descricao": "SERVIÇOS FARMACÊUTICOS",
        "item": "4",
        "subitem": "7",
        "aliquota": "2",
        "idcnae": "222",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8513801",
        "denominacao": "ATIVIDADES DE CLÍNICA MÉDICA (CLÍNICAS, CONSULTÓRIOS E AMBULATÓRIOS)",
        "descricao": "SERVIÇOS DE REABILITAÇAO DA FALA OU AUDIÇÃO (CLÍNICA)",
        "item": "4",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "223",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8515405",
        "denominacao": "SERVIÇOS DE FONOAUDIOLOGIA",
        "descricao": "SERVIÇOS DE FONOAUDIOLOGIA",
        "item": "4",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "230",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8515404",
        "denominacao": "SERVIÇOS DE FISIOTERAPIA E TERAPIA OCUPACIONAL",
        "descricao": "SERVIÇOS DE TERAPIA OCUPACIONAL",
        "item": "4",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "229",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8515404",
        "denominacao": "SERVIÇOS DE FISIOTERAPIA E TERAPIA OCUPACIONAL",
        "descricao": "SERVIÇOS DE FISIOTERAPIA",
        "item": "4",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "227",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8515404",
        "denominacao": "SERVIÇOS DE FISIOTERAPIA E TERAPIA OCUPACIONAL",
        "descricao": "SERVIÇOS DE PSICOTERAPIA",
        "item": "4",
        "subitem": "8",
        "aliquota": "2",
        "idcnae": "228",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8515406",
        "denominacao": "SERVIÇOS DE TERAPIA DE NUTRIÇÃO ENTERAL E PARENTERAL.",
        "descricao": "SERVIÇOS DE TERAPIA DE NUTRIÇÃO ENTERAL E PARENTERAL.",
        "item": "4",
        "subitem": "9",
        "aliquota": "2",
        "idcnae": "231",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8516201",
        "denominacao": "ATIVIDADES DE TERAPIAS ALTERNATIVAS",
        "descricao": "SERVIÇOS DE BIOTERAPIA",
        "item": "4",
        "subitem": "9",
        "aliquota": "2",
        "idcnae": "232",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8515402",
        "denominacao": "SERVIÇOS DE NUTRIÇÃO",
        "descricao": "SERVIÇOS DE NUTRIÇÃO",
        "item": "4",
        "subitem": "10",
        "aliquota": "2",
        "idcnae": "176",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8511100",
        "denominacao": "ATIVIDADES DE ATENDIMENTO HOSPITALAR",
        "descricao": "SERVIÇOS DE OBSTETRÍCIA",
        "item": "4",
        "subitem": "11",
        "aliquota": "2",
        "idcnae": "113",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8513802",
        "denominacao": "ATIVIDADES DE CLÍNICA ODONTOLÓGICA (CLÍNICAS, CONSULTÓRIOS E AMBULATÓRIOS)",
        "descricao": "SERVIÇOS ODONTOLÓGICOS",
        "item": "4",
        "subitem": "12",
        "aliquota": "2",
        "idcnae": "114",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8513802",
        "denominacao": "ATIVIDADES DE CLÍNICA ODONTOLÓGICA (CLÍNICAS, CONSULTÓRIOS E AMBULATÓRIOS)",
        "descricao": "SERVIÇOS DE ASSISTÊNCIA ODONTOLÓGICA (SERVIÇOS ODONTOLÓGICOS)",
        "item": "4",
        "subitem": "12",
        "aliquota": "2",
        "idcnae": "115",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8515499",
        "denominacao": "OUTRAS ATIVIDADES DE SERVIÇOS PROFISSIONAIS DA ÁREA DE SAÚDE",
        "descricao": "SERVIÇOS DE ORTÓPTICA",
        "item": "4",
        "subitem": "13",
        "aliquota": "2",
        "idcnae": "116",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3310303",
        "denominacao": "FABRICAÇÃO DE APARELHOS E UTENSÍLIOS PARA CORREÇÃO DE DEFEITOS FÍSICOS E APARELHOS ORTOPÉDICOS EM GERAL - INCLUSIVE SOB ENCOMENDA",
        "descricao": "SERVIÇOS DE PRÓTESES",
        "item": "4",
        "subitem": "14",
        "aliquota": "2",
        "idcnae": "117",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8515499",
        "denominacao": "OUTRAS ATIVIDADES DE SERVIÇOS PROFISSIONAIS DA ÁREA DE SAÚDE",
        "descricao": "SERVIÇOS DE PSICANÁLISE",
        "item": "4",
        "subitem": "15",
        "aliquota": "2",
        "idcnae": "120",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8515403",
        "denominacao": "SERVIÇOS DE PSICOLOGIA",
        "descricao": "SERVIÇOS DE PSICOLOGIA",
        "item": "4",
        "subitem": "16",
        "aliquota": "2",
        "idcnae": "121",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8531601",
        "denominacao": "ASILOS",
        "descricao": "SERVIÇOS DE ASILOS",
        "item": "4",
        "subitem": "17",
        "aliquota": "2",
        "idcnae": "122",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8531699",
        "denominacao": "OUTROS SERVIÇOS SOCIAIS COM ALOJAMENTO",
        "descricao": "SERVIÇOS DE CASA DE REPOUSO OU RECUPERAÇÃO",
        "item": "4",
        "subitem": "17",
        "aliquota": "2",
        "idcnae": "126",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8531604",
        "denominacao": "CENTROS DE REABILITAÇÃO PARA DEPENDENTES QUÍMICOS COM ALOJAMENTO",
        "descricao": "SERVIÇOS DE REABILITAÇÃO DE DEPENDENTES QUÍMICOS COM ALOJAMENTO",
        "item": "4",
        "subitem": "17",
        "aliquota": "2",
        "idcnae": "125",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8531602",
        "denominacao": "ORFANATOS",
        "descricao": "SERVIOS DE CRECHES",
        "item": "4",
        "subitem": "17",
        "aliquota": "2",
        "idcnae": "123",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8531603",
        "denominacao": "ALBERGUES ASSISTENCIAIS",
        "descricao": "SERVIÇOS DE ALBERGUES ASSISTENCIAIS",
        "item": "4",
        "subitem": "17",
        "aliquota": "2",
        "idcnae": "124",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8513801",
        "denominacao": "ATIVIDADES DE CLÍNICA MÉDICA (CLÍNICAS, CONSULTÓRIOS E AMBULATÓRIOS)",
        "descricao": "SERIVIÇOS DE INSEMINAÇÃO ARTIFICIAL OU FERTILIZAÇÃO IN VITRO",
        "item": "4",
        "subitem": "18",
        "aliquota": "2",
        "idcnae": "127",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8514606",
        "denominacao": "SERVIÇOS DE BANCO DE SANGUE",
        "descricao": "SERVIÇOS DE BANCO DE SANGUE",
        "item": "4",
        "subitem": "19",
        "aliquota": "2",
        "idcnae": "128",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8516205",
        "denominacao": "SERVIÇOS DE BANCO DE ESPERMA",
        "descricao": "SERVIÇOS DE BANCO DE SÊMEN",
        "item": "4",
        "subitem": "19",
        "aliquota": "2",
        "idcnae": "130",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8516206",
        "denominacao": "SERVIÇOS DE BANCO DE ÓRGÃOS",
        "descricao": "SERVIÇOS DE BANCO DE PELE",
        "item": "4",
        "subitem": "19",
        "aliquota": "2",
        "idcnae": "132",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8516299",
        "denominacao": "OUTRAS ATIVIDADES RELACIONADAS COM A ATENÇÃO À SAÚDE",
        "descricao": "SERVIÇOS DE BANCO DE ÓVULOS",
        "item": "4",
        "subitem": "19",
        "aliquota": "2",
        "idcnae": "133",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8516206",
        "denominacao": "SERVIÇOS DE BANCO DE ÓRGÃOS",
        "descricao": "SERVIÇOS DE BANCO DE OLHOS",
        "item": "4",
        "subitem": "19",
        "aliquota": "2",
        "idcnae": "131",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8516204",
        "denominacao": "SERVIÇOS DE BANCO DE LEITE MATERNO",
        "descricao": "SERVIÇOS DE BANCO DE LEITE",
        "item": "4",
        "subitem": "19",
        "aliquota": "2",
        "idcnae": "129",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8514602",
        "denominacao": "ATIVIDADES DOS LABORATÓRIOS DE ANÁLISES CLÍNICAS",
        "descricao": "SERVIÇOS DE COLETA DE SANGUE, LEITE, TECIDOS, SÊMEN, ÓRGÃOS E MATERIAIS BIOLÓGICOS DE QUALQUER ESPÉCIE",
        "item": "4",
        "subitem": "20",
        "aliquota": "2",
        "idcnae": "134",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8516207",
        "denominacao": "SERVIÇOS DE REMOÇÕES",
        "descricao": "SERVIÇOS DE REMOÇÕES DE ÓRGÃOS",
        "item": "4",
        "subitem": "20",
        "aliquota": "2",
        "idcnae": "135",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8512000",
        "denominacao": "ATIVIDADES DE ATENDIMENTO A URGÊNCIAS E EMERGÊNCIAS",
        "descricao": "SERVIÇOS DE ASSISTÊNCIA, ATENDIMENTO OU TRATAMENTO MÓVEL",
        "item": "4",
        "subitem": "21",
        "aliquota": "2",
        "idcnae": "136",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6630300",
        "denominacao": "PLANOS DE SAÚDE",
        "descricao": "CONVÊNIO PARA PRESTAÇÃO DE ASSISTÊNCIA MÉDICA, HOSPITALAR E ODONTOLÓGICA",
        "item": "4",
        "subitem": "22",
        "aliquota": "2",
        "idcnae": "137",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6630300",
        "denominacao": "PLANOS DE SAÚDE",
        "descricao": "PLANOS DE MEDICINA DE GRUPO OU INDIVIDUAL",
        "item": "4",
        "subitem": "22",
        "aliquota": "2",
        "idcnae": "138",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6630300",
        "denominacao": "PLANOS DE SAÚDE",
        "descricao": "OUTROS PLANOS DE SAÚDE",
        "item": "4",
        "subitem": "23",
        "aliquota": "2",
        "idcnae": "139",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7416001",
        "denominacao": "ASSESSORIA ÀS ATIVIDADES AGRÍCOLAS E PECUÁRIAS",
        "descricao": "SERVIÇOS DE ASSESSORIA ÀS ATIVIDADES PECUÁRIAS",
        "item": "5",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "140",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8520000",
        "denominacao": "SERVIÇOS VETERINÁRIOS",
        "descricao": "VETERINÁRIO (SERVIÇOS DE MEDICINA VETERINÁRIA)",
        "item": "5",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "142",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8520000",
        "denominacao": "SERVIÇOS VETERINÁRIOS",
        "descricao": "SERVIÇOS DE MEDICINA VETERINÁRIA E ZOOTECNIA",
        "item": "5",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "141",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8520000",
        "denominacao": "SERVIÇOS VETERINÁRIOS",
        "descricao": "SERVIÇOS AMBULATORIAIS - VETERINÁRIOS",
        "item": "5",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "143",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8520000",
        "denominacao": "SERVIÇOS VETERINÁRIOS",
        "descricao": "SERVIÇOS DE HOSPITALARES VETERINÁRIOS",
        "item": "5",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "144",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8520000",
        "denominacao": "SERVIÇOS VETERINÁRIOS",
        "descricao": "SERVIÇOS VETERINÁRIOS (CLÍNICA VETERINÁRIA)",
        "item": "5",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "146",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8520000",
        "denominacao": "SERVIÇOS VETERINÁRIOS",
        "descricao": "SERVIÇOS DE PRONTOS-SOCORROS - VETERINÁRIOS",
        "item": "5",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "145",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8520000",
        "denominacao": "SERVIÇOS VETERINÁRIOS",
        "descricao": "SERVIÇOS LABORATORIAIS - VETERINÁRIO",
        "item": "5",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "147",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "0162701",
        "denominacao": "SERVIÇO DE INSEMINAÇÃO ARTIFICIAL",
        "descricao": "SERVIÇOS DE INSEMINAÇÃO ARTIFICIAL OU FERTILIZAÇÃO IN VITRO",
        "item": "5",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "148",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "0162799",
        "denominacao": "OUTRAS ATIVIDADES DE SERVIÇOS RELACIONADOS COM A PECUÁRIA - EXCETO ATIVIDADES VETERINÁRIAS",
        "descricao": "SERVIÇOS DE BANCO DE SANGUE E ÓRGÃOS DE ORIGEM ANIMAL",
        "item": "5",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "149",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "0162799",
        "denominacao": "OUTRAS ATIVIDADES DE SERVIÇOS RELACIONADOS COM A PECUÁRIA - EXCETO ATIVIDADES VETERINÁRIAS",
        "descricao": "SERVIÇOS DE COLETA DE SANGUE, LEITE, TECIDOS, SÊMEN, ÓRGÃOS E MATERIAIS BIOLÓGICOS DE QUALQUER ESPÉCIE, DE ORIGEM ANIMAL",
        "item": "5",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "150",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8520000",
        "denominacao": "SERVIÇOS VETERINÁRIOS",
        "descricao": "SERVIÇOS DE ATENDIMENTO, ASSISTÊNCIA OU  TRATAMENTO MÓVEL À ANIMAIS",
        "item": "5",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "151",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7460803",
        "denominacao": "SERVIÇOS DE ADESTRAMENTO DE CÃES DE GUARDA",
        "descricao": "SERVIÇOS DE ADESTRAMENTO DE ANIMAIS",
        "item": "5",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "152",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9309202",
        "denominacao": "ALOJAMENTO, HIGIENE E EMBELEZAMENTO DE ANIMAIS.",
        "descricao": "SERVIÇOS DE GUARDA E ALOJAMENTO DE ANIMAIS",
        "item": "5",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "154",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "0162799",
        "denominacao": "OUTRAS ATIVIDADES DE SERVIÇOS RELACIONADOS COM A PECUÁRIA - EXCETO ATIVIDADES VETERINÁRIAS",
        "descricao": "SERVIÇOS TRATAMENTO DE ANIMAIS",
        "item": "5",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "157",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "0162703",
        "denominacao": "SERVIÇO DE TOSQUIAMENTO DE OVELHAS",
        "descricao": "SERVIÇOS DE TOSQUIA",
        "item": "5",
        "subitem": "8",
        "aliquota": "0",
        "idcnae": "155",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9309202",
        "denominacao": "ALOJAMENTO, HIGIENE E EMBELEZAMENTO DE ANIMAIS.",
        "descricao": "SERVIÇOS DE EMBELEZAMENTO DE ANIMAIS",
        "item": "5",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "153",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8520000",
        "denominacao": "SERVIÇOS VETERINÁRIOS",
        "descricao": "PLANOS DE ATENDIMENTO E ASSITÊNCIA MÉDICO VETERINÁRIA",
        "item": "5",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "158",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9302501",
        "denominacao": "CABELEIREIROS",
        "descricao": "SERVIÇOS DE CABELEIREIROS",
        "item": "6",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "159",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9302501",
        "denominacao": "CABELEIREIROS",
        "descricao": "SERVIÇOS DE BARBEARIA",
        "item": "6",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "160",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9302502",
        "denominacao": "MANICURES E OUTROS SERVIÇOS DE TRATAMENTO DE BELEZA",
        "descricao": "SERVIÇOS DE MANICURI E PEDICURI",
        "item": "6",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "161",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9302502",
        "denominacao": "MANICURES E OUTROS SERVIÇOS DE TRATAMENTO DE BELEZA",
        "descricao": "SERVIÇOS DE DEPILAÇÃO",
        "item": "6",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "162",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9302502",
        "denominacao": "MANICURES E OUTROS SERVIÇOS DE TRATAMENTO DE BELEZA",
        "descricao": "SERVIÇOS DE ESTÉTICA",
        "item": "6",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "163",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9302502",
        "denominacao": "MANICURES E OUTROS SERVIÇOS DE TRATAMENTO DE BELEZA",
        "descricao": "SERVIÇOS DE TRATAMENTO DE PELE",
        "item": "6",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "164",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9304100",
        "denominacao": "ATIVIDADES DE MANUTENÇÃO DO FÍSICO CORPORAL",
        "descricao": "SERVIÇOS DE SAUNA, DUCHAS, BANHO E MASSAGENS",
        "item": "6",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "165",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9239803",
        "denominacao": "ACADEMIAS DE DANÇA",
        "descricao": "SERVIÇOS DE ACADEMIA DE DANÇA",
        "item": "6",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "166",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9304100",
        "denominacao": "ATIVIDADES DE MANUTENÇÃO DO FÍSICO CORPORAL",
        "descricao": "SERVIÇOS DE CONDICIONAMENTO FÍSICO CORPORAL",
        "item": "6",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "171",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9261405",
        "denominacao": "ATIVIDADES DE CONDICIONAMENTO FÍSICO.",
        "descricao": "SERVIÇOS DE ACADEMIA DE ESPOSTES",
        "item": "6",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "168",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9261405",
        "denominacao": "ATIVIDADES DE CONDICIONAMENTO FÍSICO.",
        "descricao": "SERVIÇOS DE ACADEMIA DE GINÁSTICA",
        "item": "6",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "169",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9261405",
        "denominacao": "ATIVIDADES DE CONDICIONAMENTO FÍSICO.",
        "descricao": "SERVIÇOS DE ACADEMIA DE NATAÇÃO",
        "item": "6",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "170",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9261405",
        "denominacao": "ATIVIDADES DE CONDICIONAMENTO FÍSICO.",
        "descricao": "SERVIÇOS DE ACADEMIA DE ARTES MARCIAIS",
        "item": "6",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "167",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9304100",
        "denominacao": "ATIVIDADES DE MANUTENÇÃO DO FÍSICO CORPORAL",
        "descricao": "SERVIÇOS DE ORIENTAÇÃO PARA EMAGRECIMENTO, SPA E CONGÊNERE",
        "item": "6",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "172",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420903",
        "denominacao": "SERVIÇOS TÉCNICOS DE CARTOGRAFIA, TOPOGRAFIA E GEODÉSIA",
        "descricao": "SERVIÇOS DE AGRIMENSURA",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "55",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420901",
        "denominacao": "SERVIÇOS TÉCNICOS DE ARQUITETURA",
        "descricao": "SERVIÇOS DE PAISAGISMO",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "109",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420901",
        "denominacao": "SERVIÇOS TÉCNICOS DE ARQUITETURA",
        "descricao": "SERVIÇOS DE ENGENHARIA",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "175",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420901",
        "denominacao": "SERVIÇOS TÉCNICOS DE ARQUITETURA",
        "descricao": "SERVIÇOS DE ARQUITETURA",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "174",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420901",
        "denominacao": "SERVIÇOS TÉCNICOS DE ARQUITETURA",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE PROJETOS DE URBANIZAÇÃO OU LOTEAMENTO",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "173",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420904",
        "denominacao": "ATIVIDADES DE PROSPECÇÃO GEOLÓGICA",
        "descricao": "SERVIÇOS DE GEOLOGIA",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "56",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "0161999",
        "denominacao": "OUTRAS ATIVIDADES DE SERVIÇOS RELACIONADOS COM A AGRICULTURA",
        "descricao": "SERVIÇOS DE EXTENSÃO RURAL",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "58",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "0161999",
        "denominacao": "OUTRAS ATIVIDADES DE SERVIÇOS RELACIONADOS COM A AGRICULTURA",
        "descricao": "SERVIÇOS DE AGRONOMIA",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "57",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "0511804",
        "denominacao": "ATIVIDADES DE SERVIÇOS RELACIONADOS A PESCA",
        "descricao": "SERVIÇO DE EXTENSÃO DE PESCA",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "59",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7416001",
        "denominacao": "ASSESSORIA ÀS ATIVIDADES AGRÍCOLAS E PECUÁRIAS",
        "descricao": "SERVIÇOS DE ASSESSORIA ÀS ATIVIDADES AGRÍCOLAS",
        "item": "7",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "60",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4512801",
        "denominacao": "FUNDAÇÕES DESTINADAS À CONSTRUÇÃO CIVIL",
        "descricao": "SERVIÇOS DE ESCAVAÇÕES",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "61",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4521701",
        "denominacao": "EDIFICAÇÕES (RESIDENCIAIS, INDUSTRIAIS, COMERCIAIS E DE SERVIÇOS)",
        "descricao": "SERVIÇOS DE CONCRETAGEM",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "65",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4521702",
        "denominacao": "ADMINISTRAÇÃO DE OBRAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE OBRAS DE CONSTRUÇÃO CIVIL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "67",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4541101",
        "denominacao": "INSTALAÇÃO E MANUTENÇÃO ELÉTRICA EM EDIFICAÇÕES",
        "descricao": "SERVIÇOS DE ELETRECIDADE",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "76",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4529205",
        "denominacao": "PERFURAÇÃO E CONSTRUÇÃO DE POÇOS DE ÁGUAS",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO DE POÇOS DE ÁGUAS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "75",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4529204",
        "denominacao": "CONSTRUÇÃO DE REDES DE TRANSPORTES POR DUTOS",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO DE REDES DE TRANSPORTE POR DUTOS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "74",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4529203",
        "denominacao": "CONSTRUÇÃO DE REDES DE ÁGUA E ESGOTO",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO DE REDES DE ÁGUA E ESGOTO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "73",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4529203",
        "denominacao": "CONSTRUÇÃO DE REDES DE ÁGUA E ESGOTO",
        "descricao": "SERVIÇOS DE SANEAMENTO AMBIENTAL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "72",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4529202",
        "denominacao": "OBRAS DE IRRIGAÇÃO",
        "descricao": "SERVIÇOS DE OBRAS DE IRRIGAÇÃO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "71",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4529201",
        "denominacao": "OBRAS MARÍTIMAS E FLUVIAIS",
        "descricao": "SERVIÇOS DE OBRAS MARÍTIMAS E FLUVIAIS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "70",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4522501",
        "denominacao": "OBRAS VIÁRIAS (RODOVIAS, VIAS FÉRREAS E AEROPORTOS)",
        "descricao": "SERVIÇOS DE PAVIMENTAÇÃO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "69",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4522501",
        "denominacao": "OBRAS VIÁRIAS (RODOVIAS, VIAS FÉRREAS E AEROPORTOS)",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO DE RODOVIAS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "68",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4549799",
        "denominacao": "OUTRAS OBRAS DE INSTALAÇÕES",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE PISCINAS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "94",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4533001",
        "denominacao": "CONSTRUÇÃO DE ESTAÇÕES E REDES DE TELEFONIA E COMUNICAÇÃO",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO CIVIL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "93",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4542000",
        "denominacao": "INSTALAÇÃO E MANUTENÇÃO DE SISTEMAS CENTRAIS DE AR CONDICIONADO, DE VENTILAÇÃO E REFRIGERAÇÃO",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE SISTEMAS CENTRAIS DE AR CONDICIONADO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "92",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4531401",
        "denominacao": "CONSTRUÇÃO DE BARRAGENS E REPRESAS PARA GERAÇÃO DE ENERGIA ELÉTRICA",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO CIVIL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "90",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4523300",
        "denominacao": "OBRAS DE ARTE ESPECIAIS.",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO CIVIL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "89",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4529299",
        "denominacao": "OUTRAS OBRAS DE ENGENHARIA CIVIL",
        "descricao": "SERVIÇOS DE INSTALAÇÃO E MONTAGEM DE PRODUTOS, PEÇAS E EQUIPAMENTOS DESTINADOS À CONSTRUÇÃO CIVIL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "88",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4525001",
        "denominacao": "MONTAGEM DE ESTRUTURAS METÁLICAS - EXCETO TEMPORÁRIAS.",
        "descricao": "SERVIÇOS DE MONTAGEM DE ESTRUTURA METÁLICAS E DE FERRO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "87",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4522503",
        "denominacao": "OBRAS DE URBANIZAÇÃO E PAISAGISMO.",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO CIVIL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "86",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4522502",
        "denominacao": "PINTURA PARA SINALIZAÇÃO EM PISTAS RODOVIÁRIAS E AEROPORTOS",
        "descricao": "SERVIÇOS DE PINTURA DE CONSTRUÇÃO CIVIL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "85",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7010600",
        "denominacao": "INCORPORAÇÃO E COMPRA E VENDA DE IMÓVEIS",
        "descricao": "SERVIÇOS DE EXECUÇÃO, POR ADMINISTRAÇÃO, DE OBRAS DE CONSTRUÇÃO CIVIL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "99",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4550005",
        "denominacao": "INSTALAÇÃO DE PORTAS, JANELAS, TETOS, DIVISÓRIAS E ARMÁRIOS EMBUTIDOS DE QUALQUER MATERIAL, INCLUSIVE DE ESQUADRIAS",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE ESQUADRIAS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "98",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4550004",
        "denominacao": "SERVIÇOS DE PINTURA EM EDIFICAÇÕES EM GERAL.",
        "descricao": "SERVIÇOS DE PINTURA DE CONSTRUÇÃO CIVIL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "97",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4550003",
        "denominacao": "IMPERMEABILIZAÇÃO EM OBRAS DE ENGENHARIA CIVIL.",
        "descricao": "SERVIÇOS DE IMPERMEABILIZAÇÃO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "96",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4550001",
        "denominacao": "OBRAS DE ALVENARIA E REBOCO.",
        "descricao": "SERVIÇOS DE OBRAS DE ALVENARIA E REBOCO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "95",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4513600",
        "denominacao": "TERRAPLENAGEM E OUTRAS MOVIMENTAÇÕES DE TERRA",
        "descricao": "SERVIÇOS DE DRENAGEM",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "84",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4513600",
        "denominacao": "TERRAPLENAGEM E OUTRAS MOVIMENTAÇÕES DE TERRA",
        "descricao": "SERVIÇOS DE TERRAPLANAGEM",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "83",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4511002",
        "denominacao": "PREPARAÇÃO DE TERRENOS",
        "descricao": "SERVIÇOS DE TERRAPLANAGEM",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "82",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4549703",
        "denominacao": "TRATAMENTOS ACÚSTICO E TÉRMICO",
        "descricao": "SERVIÇOS DE TRATAMENTO ACÚSTICO E TÉRMICO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "81",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4549701",
        "denominacao": "MONTAGEM E INSTALAÇÃO DE SISTEMAS E EQUIPAMENTOS DE ILUMINAÇÃO E SINALIZAÇÃO EM VIAS PÚBLICAS, PORTOS E AEROPORTOS",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE PLACAS DE SINALIZAÇÃO DE TRÂNSITO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "80",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4543802",
        "denominacao": "INSTALAÇÕES DE SISTEMA DE PREVENÇÃO CONTRA INCÊNDIO",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE SISTEMAS DE PREVENÇÃO CONTRA INCÊNDIOS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "79",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4543801",
        "denominacao": "INSTALAÇÕES HIDRÁULICAS, SANITÁRIAS E DE GÁS",
        "descricao": "SERVIÇOS DE INSTALAÇÃO HIDRÁULICAS, SANITÁRIAS E GÁS",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "78",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4541102",
        "denominacao": "INSTALAÇÃO, MANUTENÇÃO E REPARAÇÃO DE ELEVADORES, ESCADAS E ESTEIRAS ROLANTES - EXCETO DE FABRICAÇÃO PRÓPRIA",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE ELEVADORES, ESCADAS E ESTEIRAS LOLANTES",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "77",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4521701",
        "denominacao": "EDIFICAÇÕES (RESIDENCIAIS, INDUSTRIAIS, COMERCIAIS E DE SERVIÇOS)",
        "descricao": "SERVIÇOS DE CONSTRUÇÃO CIVIL",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "66",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4521701",
        "denominacao": "EDIFICAÇÕES (RESIDENCIAIS, INDUSTRIAIS, COMERCIAIS E DE SERVIÇOS)",
        "descricao": "SERVIÇOS DE BOMBEAMENTO DE CONCRETO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "64",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4512801",
        "denominacao": "FUNDAÇÕES DESTINADAS À CONSTRUÇÃO CIVIL",
        "descricao": "SERVIÇOS DE ESTAQUEAMENTO",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "62",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4512802",
        "denominacao": "SONDAGENS DESTINADAS À CONSTRUÇÃO CIVIL",
        "descricao": "SERVIÇOS DE SONDAGEM ",
        "item": "7",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "63",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420902",
        "denominacao": "SERVIÇOS TÉCNICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE PLANOS DIRETORES",
        "item": "7",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "100",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420902",
        "denominacao": "SERVIÇOS TÉCNICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE ANTEPROJETOS, PROJETOS BÁSICOS E PROJETOS EXECUTIVOS PARA TRABALHOS DE ENGENHARIA",
        "item": "7",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "103",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420902",
        "denominacao": "SERVIÇOS TÉCNICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS DE ESTUDOS ORGANIZACIONAIS",
        "item": "7",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "102",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420902",
        "denominacao": "SERVIÇOS TÉCNICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS DE ESTUDOS DE VIABILIDADE",
        "item": "7",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "101",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4511001",
        "denominacao": "DEMOLIÇÃO DE EDIFÍCIOS E OUTRAS ESTRUTURAS",
        "descricao": "SERVIÇOS DE DEMOLIÇÃO",
        "item": "7",
        "subitem": "4",
        "aliquota": "3",
        "idcnae": "104",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4521701",
        "denominacao": "EDIFICAÇÕES (RESIDENCIAIS, INDUSTRIAIS, COMERCIAIS E DE SERVIÇOS)",
        "descricao": "SERVIÇOS DE REPARAÇÃO, CONSERVAÇÃO E REFORMA DA CONST. CIVIL",
        "item": "7",
        "subitem": "5",
        "aliquota": "3",
        "idcnae": "105",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4531403",
        "denominacao": "MANUTENÇÃO DE REDES DE DISTRIBUIÇÃO DE ENERGIA ELÉTRICA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE REDES DE DISTRIBUIÇÃO DE ENERGIA ELÉTRICA",
        "item": "7",
        "subitem": "5",
        "aliquota": "3",
        "idcnae": "108",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9252502",
        "denominacao": "CONSERVAÇÃO DE LUGARES E EDIFÍCIOS HISTÓRICOS",
        "descricao": "SERVIÇOS DE CONSERVAÇÃO DE EDIFÍCIOS",
        "item": "7",
        "subitem": "5",
        "aliquota": "3",
        "idcnae": "107",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4522501",
        "denominacao": "OBRAS VIÁRIAS (RODOVIAS, VIAS FÉRREAS E AEROPORTOS)",
        "descricao": "SERVIÇOS DE REPARAÇÃO, CONSERVAÇÃO E REFORMA DE ESTRADAS, PONTES E PORTOS",
        "item": "7",
        "subitem": "5",
        "aliquota": "3",
        "idcnae": "106",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4533002",
        "denominacao": "MANUTENÇÃO DE ESTAÇÕES E REDES DE TELEFONIA E COMUNICAÇÕES",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE ESTAÇÕES E REDES DE TELEFONIA E COMUNICAÇÕES",
        "item": "7",
        "subitem": "5",
        "aliquota": "3",
        "idcnae": "110",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499306",
        "denominacao": "SERVIÇOS DE DECORAÇÃO DE INTERIORES",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE PERSIANAS",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "111",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4550005",
        "denominacao": "INSTALAÇÃO DE PORTAS, JANELAS, TETOS, DIVISÓRIAS E ARMÁRIOS EMBUTIDOS DE QUALQUER MATERIAL, INCLUSIVE DE ESQUADRIAS",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE DIVISÓRIAS",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "2",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4550099",
        "denominacao": "OUTRAS OBRAS DE ACABAMENTO DA CONSTRUÇÃO",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE VIDROS",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "8",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4550099",
        "denominacao": "OUTRAS OBRAS DE ACABAMENTO DA CONSTRUÇÃO",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE ASSOALHO",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "7",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4550006",
        "denominacao": "SERVIÇOS DE REVESTIMENTOS E APLICAÇÃO DE RESINAS EM INTERIORES E EXTERIORES",
        "descricao": "SERVIÇO DE COLOCAÇÃO E INSTALAÇÃO DE REVESTIMENTOS DE PAREDE",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "6",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4550006",
        "denominacao": "SERVIÇOS DE REVESTIMENTOS E APLICAÇÃO DE RESINAS EM INTERIORES E EXTERIORES",
        "descricao": "SERVIÇO DE COLOCAÇÃO E INSTALAÇÃO DE CARPETE",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "5",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4550006",
        "denominacao": "SERVIÇOS DE REVESTIMENTOS E APLICAÇÃO DE RESINAS EM INTERIORES E EXTERIORES",
        "descricao": "SERVIÇO DE COLOCAÇÃO DE PELÍCULA DE CONTENÇÃO SOLA EM IMÓVEIS",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "4",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4550005",
        "denominacao": "INSTALAÇÃO DE PORTAS, JANELAS, TETOS, DIVISÓRIAS E ARMÁRIOS EMBUTIDOS DE QUALQUER MATERIAL, INCLUSIVE DE ESQUADRIAS",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE FECHADURAS",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "3",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4550002",
        "denominacao": "OBRAS DE ACABAMENTO EM GESSO E ESTUQUE.",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE GESSO",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "1",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499306",
        "denominacao": "SERVIÇOS DE DECORAÇÃO DE INTERIORES",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE TAPETES E CORTINAS",
        "item": "7",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "112",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4550006",
        "denominacao": "SERVIÇOS DE REVESTIMENTOS E APLICAÇÃO DE RESINAS EM INTERIORES E EXTERIORES",
        "descricao": "SERVIÇOS DE RECUPERAÇÃO, RASPAGEM, POLIMENTO E LUSTRAÇÃO DE ASSOALHOS, PISOS E PAREDES",
        "item": "7",
        "subitem": "7",
        "aliquota": "3",
        "idcnae": "9",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4542000",
        "denominacao": "INSTALAÇÃO E MANUTENÇÃO DE SISTEMAS CENTRAIS DE AR CONDICIONADO, DE VENTILAÇÃO E REFRIGERAÇÃO",
        "descricao": "SERVIÇOS DE CALEFAÇÃO",
        "item": "7",
        "subitem": "8",
        "aliquota": "3",
        "idcnae": "10",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7470501",
        "denominacao": "ATIVIDADES DE LIMPEZA EM IMÓVEIS",
        "descricao": "SERVIÇOS DE SEPARAÇÃO E DESTINAÇÃO FINAL DO LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "11",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9000001",
        "denominacao": "LIMPEZA URBANA - EXCETO GESTÃO DE ATERROS SANITÁRIOS",
        "descricao": "SERVIÇOS DE RECICLAGEM DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "12",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9000001",
        "denominacao": "LIMPEZA URBANA - EXCETO GESTÃO DE ATERROS SANITÁRIOS",
        "descricao": "SERVIÇOS DE REMOÇÃO DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "13",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9000001",
        "denominacao": "LIMPEZA URBANA - EXCETO GESTÃO DE ATERROS SANITÁRIOS",
        "descricao": "SERVIÇOS DE TRATAMENTO DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "14",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9000001",
        "denominacao": "LIMPEZA URBANA - EXCETO GESTÃO DE ATERROS SANITÁRIOS",
        "descricao": "SERVIOS DE INCINERAÇÃO DE RESÍDUOS",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "16",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9000001",
        "denominacao": "LIMPEZA URBANA - EXCETO GESTÃO DE ATERROS SANITÁRIOS",
        "descricao": "SERVIÇOS DE VARRIÇÃO E COLETA DE LIXO",
        "item": "7",
        "subitem": "9",
        "aliquota": "3",
        "idcnae": "15",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7470501",
        "denominacao": "ATIVIDADES DE LIMPEZA EM IMÓVEIS",
        "descricao": "SERVIÇOS DE LIMPEZA, MANUTENÇÃO E CONSERVAÇÃO DE CHAMINÉS",
        "item": "7",
        "subitem": "10",
        "aliquota": "2,5",
        "idcnae": "17",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7470501",
        "denominacao": "ATIVIDADES DE LIMPEZA EM IMÓVEIS",
        "descricao": "SERVIÇOS DE LIMPEZA, MANUTENÇÃO E CONSERVAÇÃO DE PISCINAS",
        "item": "7",
        "subitem": "10",
        "aliquota": "2,5",
        "idcnae": "18",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7470501",
        "denominacao": "ATIVIDADES DE LIMPEZA EM IMÓVEIS",
        "descricao": "SERVIÇOS DE LIMPEZA, MANUTENÇÃO E CONSERVAÇÃO DE JARDINS",
        "item": "7",
        "subitem": "10",
        "aliquota": "2,5",
        "idcnae": "21",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7470501",
        "denominacao": "ATIVIDADES DE LIMPEZA EM IMÓVEIS",
        "descricao": "SERVIÇOS DE LIMPEZA, MANUTENÇÃO E CONSERVAÇÃO DE PARQUES",
        "item": "7",
        "subitem": "10",
        "aliquota": "2,5",
        "idcnae": "20",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7470501",
        "denominacao": "ATIVIDADES DE LIMPEZA EM IMÓVEIS",
        "descricao": "SERVIÇOS DE LIMPEZA, MANUTENÇÃO E CONSERVAÇÃO DE IMÓVEIS",
        "item": "7",
        "subitem": "10",
        "aliquota": "2,5",
        "idcnae": "19",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7470501",
        "denominacao": "ATIVIDADES DE LIMPEZA EM IMÓVEIS",
        "descricao": "SERVIÇOS DE LIMPEZA, MANUTENÇÃO E CONSERVAÇÃO DE VIAS E LOGRADOUROS PÚBLICOS",
        "item": "7",
        "subitem": "10",
        "aliquota": "2,5",
        "idcnae": "22",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9500100",
        "denominacao": "SERVIÇOS DOMÉSTICOS",
        "descricao": "SERVIÇOS DOMÉSTICOS",
        "item": "7",
        "subitem": "10",
        "aliquota": "2,5",
        "idcnae": "23",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499306",
        "denominacao": "SERVIÇOS DE DECORAÇÃO DE INTERIORES",
        "descricao": "SERVIÇOS DE DECORAÇÃO",
        "item": "7",
        "subitem": "11",
        "aliquota": "3",
        "idcnae": "24",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "0161903",
        "denominacao": "SERVIÇO DE PODA DE ÁRVORES",
        "descricao": "SERVIÇOS DE JARDINAGEM",
        "item": "7",
        "subitem": "11",
        "aliquota": "0",
        "idcnae": "27",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "0161901",
        "denominacao": "SERVIÇO DE JARDINAGEM - INCLUSIVE PLANTIO DE GRAMADO",
        "descricao": "SERVIÇOS DE DECORAÇÃO E JARDINAGEM",
        "item": "7",
        "subitem": "11",
        "aliquota": "3",
        "idcnae": "25",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "0161901",
        "denominacao": "SERVIÇO DE JARDINAGEM - INCLUSIVE PLANTIO DE GRAMADO",
        "descricao": "SERVIÇOS DE JARDINAGEM",
        "item": "7",
        "subitem": "11",
        "aliquota": "3",
        "idcnae": "26",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9000003",
        "denominacao": "GESTÃO DE REDES DE ESGOTO",
        "descricao": "SERVIÇOS DE CONTROLE E TRATAMENTO DE EFLUENTES",
        "item": "7",
        "subitem": "12",
        "aliquota": "3",
        "idcnae": "28",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9000003",
        "denominacao": "GESTÃO DE REDES DE ESGOTO",
        "descricao": "SERVIÇOS DE TRATAMENTO E CONTROLE DE AGENTES FÍSICOS, QUÍMICOS E BIOLÓGICOS",
        "item": "7",
        "subitem": "12",
        "aliquota": "3",
        "idcnae": "29",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7470502",
        "denominacao": "ATIVIDADES DE IMUNIZAÇÃO E CONTROLE DE PRAGAS URBANAS",
        "descricao": "SERVIÇOS DE DEDETIZAÇÃO, DESINFECÇÃO, DESINSETIZAÇÃO, IMUNIZAÇÃO, HIGIENIZAÇÃO, DESRATIZAÇÃO E PULVERIZAÇÃO",
        "item": "7",
        "subitem": "13",
        "aliquota": "3",
        "idcnae": "30",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "0161902",
        "denominacao": "SERVIÇOS DE PULVERIZAÇÃO DA LAVOURA",
        "descricao": "SERVIÇOS DE PULVERIZAÇÃO",
        "item": "7",
        "subitem": "13",
        "aliquota": "3",
        "idcnae": "31",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "0161904",
        "denominacao": "SERVIÇO DE COLHEITA",
        "descricao": "SERVIÇOS DE COLHEITA",
        "item": "7",
        "subitem": "16",
        "aliquota": "3",
        "idcnae": "32",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "0213500",
        "denominacao": "ATIVIDADES DOS SERVIÇOS RELACIONADOS COM A SILVICULTURA E A EXPLORAÇÃO FLORESTAL",
        "descricao": "SERVIÇO DE FLORESTAMENTO, REFLORESTAMENTO, SEMEADURA E ADUBAÇÃO",
        "item": "7",
        "subitem": "16",
        "aliquota": "3",
        "idcnae": "33",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4525002",
        "denominacao": "MONTAGEM E DESMONTAGEM DE ANDAIMES E OUTRAS ESTRUTURAS TEMPORÁRIAS.",
        "descricao": "SERVIÇOS DE ESCORAMENTO E CONTENÇÃO DE ENCOSTAS",
        "item": "7",
        "subitem": "17",
        "aliquota": "3",
        "idcnae": "34",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420999",
        "denominacao": "OUTROS SERVIÇOS TÉCNICOS ESPECIALIZADOS",
        "descricao": "SERVIÇOS DE LIMPEZA E DRENAGEM DE RIOS, PORTOS, CANAIS, BAÍAS, LAGOS, LAGOAS, REPRESAS E AÇUDES",
        "item": "7",
        "subitem": "18",
        "aliquota": "3",
        "idcnae": "35",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420999",
        "denominacao": "OUTROS SERVIÇOS TÉCNICOS ESPECIALIZADOS",
        "descricao": "SERVIÇOS DE ACOMPANHAMENTO E FISCALIZAÇÃO DA EXECUÇÃO DE OBRAS DE ENGENHARIA, ARQUITETURA E URBANISMO",
        "item": "7",
        "subitem": "19",
        "aliquota": "3",
        "idcnae": "36",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420903",
        "denominacao": "SERVIÇOS TÉCNICOS DE CARTOGRAFIA, TOPOGRAFIA E GEODÉSIA",
        "descricao": "SERVIÇOS DE AEROFOTOGRAMETRIA",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "37",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420903",
        "denominacao": "SERVIÇOS TÉCNICOS DE CARTOGRAFIA, TOPOGRAFIA E GEODÉSIA",
        "descricao": "SERVIÇOS DE BATIMETRIA",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "38",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420903",
        "denominacao": "SERVIÇOS TÉCNICOS DE CARTOGRAFIA, TOPOGRAFIA E GEODÉSIA",
        "descricao": "SERVIÇOS GEODÉSICOS E GEOGRÁFICOS",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "40",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420903",
        "denominacao": "SERVIÇOS TÉCNICOS DE CARTOGRAFIA, TOPOGRAFIA E GEODÉSIA",
        "descricao": "SERVIÇOS DE TOPOGRAFIA E AGRIMENSURA",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "42",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499399",
        "denominacao": "OUTROS SERVIÇOS PRESTADOS PRINCIPALMENTE ÀS EMPRESAS",
        "descricao": "SERVIÇOS DE MAPEAMENTO",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "49",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420903",
        "denominacao": "SERVIÇOS TÉCNICOS DE CARTOGRAFIA, TOPOGRAFIA E GEODÉSIA",
        "descricao": "SERVIÇOS GEOLÓGICOS ",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "48",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420903",
        "denominacao": "SERVIÇOS TÉCNICOS DE CARTOGRAFIA, TOPOGRAFIA E GEODÉSIA",
        "descricao": "SERVIÇOS GEODÉSICOS E GEOFÍSICOS",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "47",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420903",
        "denominacao": "SERVIÇOS TÉCNICOS DE CARTOGRAFIA, TOPOGRAFIA E GEODÉSIA",
        "descricao": "SERVIÇOS GEOGRÁFICOS",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "46",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420903",
        "denominacao": "SERVIÇOS TÉCNICOS DE CARTOGRAFIA, TOPOGRAFIA E GEODÉSIA",
        "descricao": "SERVIÇOS BATIMÉTRICOS",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "45",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420903",
        "denominacao": "SERVIÇOS TÉCNICOS DE CARTOGRAFIA, TOPOGRAFIA E GEODÉSIA",
        "descricao": "SERVIÇOS AEROFOTOGRAMETRIA ",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "44",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420903",
        "denominacao": "SERVIÇOS TÉCNICOS DE CARTOGRAFIA, TOPOGRAFIA E GEODÉSIA",
        "descricao": "SERVIÇOS DE CARTOGRAFIA",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "43",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420903",
        "denominacao": "SERVIÇOS TÉCNICOS DE CARTOGRAFIA, TOPOGRAFIA E GEODÉSIA",
        "descricao": "SERVIÇOS GEOLÓGICOS E GEOFÍSICOS",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "41",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420903",
        "denominacao": "SERVIÇOS TÉCNICOS DE CARTOGRAFIA, TOPOGRAFIA E GEODÉSIA",
        "descricao": "SERVIÇOS DE CARTOGRAFIA",
        "item": "7",
        "subitem": "20",
        "aliquota": "3",
        "idcnae": "39",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "1120700",
        "denominacao": "SERVIÇOS RELACIONADOS COM A EXTRAÇÃO DE PETRÓLEO E GÁS - EXCETO A PROSPECÇÃO REALIZADA POR TERCEIROS",
        "descricao": "SERVIÇOS DE MERGULHO",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "52",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "1120701",
        "denominacao": "SERVIÇOS RELACIONADOS COM A EXTRAÇÃO DE PETRÓLEO E GÁS - EXCETO A PROSPECÇÃO REALIZADA POR TERCEIROS",
        "descricao": "SERVIÇOS DE PERFILAGEM",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "53",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "1120700",
        "denominacao": "SERVIÇOS RELACIONADOS COM A EXTRAÇÃO DE PETRÓLEO E GÁS - EXCETO A PROSPECÇÃO REALIZADA POR TERCEIROS",
        "descricao": "SERVIÇOS DE PERFURAÇÃO DE POÇOS DE PETRÓLEO",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "50",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "1120704",
        "denominacao": "SERVIÇOS RELACIONADOS COM A EXTRAÇÃO DE PETRÓLEO E GÁS - EXCETO A PROSPECÇÃO REALIZADA POR TERCEIROS",
        "descricao": "SERVIÇOS DE  ESTIMULAÇÃO E OUTROS SERVIÇOS RELACIONADOS COM A EXPLORAÇÃO E EXPLOTAÇÃO DE PETRÓLEO, GÁS NATURAL E OUTROS RECURSOS MINERAIS",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "1301",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7310500",
        "denominacao": "PESQUISA E DESENVOLVIMENTO DAS CIÊNCIAS FÍSICAS E NATURAIS",
        "descricao": "SERVIÇOS DE PESQUISAS RELACIOANADAS AO CARVÃO MINERAL",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "1303",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7310500",
        "denominacao": "PESQUISA E DESENVOLVIMENTO DAS CIÊNCIAS FÍSICAS E NATURAIS",
        "descricao": "SERVIÇOS DE PESQUISAS RELACIOANADAS AO PETRÓLEO",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "1304",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "1120705",
        "denominacao": "SERVIÇOS RELACIONADOS COM A EXTRAÇÃO DE PETRÓLEO E GÁS - EXCETO A PROSPECÇÃO REALIZADA POR TERCEIROS",
        "descricao": "SERVIÇOS DE CONCRETAÇÃO",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "1302",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "1120703",
        "denominacao": "SERVIÇOS RELACIONADOS COM A EXTRAÇÃO DE PETRÓLEO E GÁS - EXCETO A PROSPECÇÃO REALIZADA POR TERCEIROS",
        "descricao": "SERVIÇOS DE PESCARIA",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "1300",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "1120700",
        "denominacao": "SERVIÇOS RELACIONADOS COM A EXTRAÇÃO DE PETRÓLEO E GÁS - EXCETO A PROSPECÇÃO REALIZADA POR TERCEIROS",
        "descricao": "SERVIÇOS DE CIMENTAÇÃO DE POÇOS DE PETRÓLEO",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "51",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "1120702",
        "denominacao": "SERVIÇOS RELACIONADOS COM A EXTRAÇÃO DE PETRÓLEO E GÁS - EXCETO A PROSPECÇÃO REALIZADA POR TERCEIROS",
        "descricao": "SERVIÇOS DE TESTEMUNHAGEM",
        "item": "7",
        "subitem": "21",
        "aliquota": "3",
        "idcnae": "54",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "0161999",
        "denominacao": "OUTRAS ATIVIDADES DE SERVIÇOS RELACIONADOS COM A AGRICULTURA",
        "descricao": "SERVIÇOS DE NUCLEAÇÃO  E BOMBARDEAMENTO DE NUVENS",
        "item": "7",
        "subitem": "22",
        "aliquota": "3",
        "idcnae": "1305",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8013600",
        "denominacao": "EDUCAÇÃO INFANTIL - CRECHES",
        "descricao": "SERVIÇOS DE EDUCAÇÃO INFANTIL - CRECHES",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1306",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8032200",
        "denominacao": "EDUCAÇÃO SUPERIOR - GRADUAÇÃO E PÓS-GRADUAÇÃO.",
        "descricao": "SERVIÇOS DE EDUÇAÇÃO - ENSINO SUPERIOR (GRADUAÇÃO E PÓS-GRADUAÇÃO)",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1311",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8096900",
        "denominacao": "EDUCAÇÃO PROFISSIONAL DE NIVEL TÉCNICO.",
        "descricao": "SERVIÇOS DE ENSINO TÉCNICO INDUSTRIAL OU COMERCIAL",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1313",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8097700",
        "denominacao": "EDUCAÇÃO PROFISSIONAL DE NÍVEL TECNOLÓGICO",
        "descricao": "SERVIÇOS DE ENSINO PROFISSIONAL DE NÍVEL TECNOLÓGICO",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1314",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8033000",
        "denominacao": "EDUCAÇÃO SUPERIOR - PÓS-GRADUAÇÃO E EXTENSÃO",
        "descricao": "SERVIÇOS DE EDUÇAÇÃO - ENSINO SUPERIOR (GRADUAÇÃO E PÓS-GRADUAÇÃO E EXTENSÃO)",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1312",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8031400",
        "denominacao": "EDUCAÇÃO SUPERIOR - GRADUAÇÃO.",
        "descricao": "SERVIÇOS DE EDUÇAÇÃO - ENSINO SUPERIOR (GRADUAÇÃO)",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1310",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8014400",
        "denominacao": "EDUCAÇÃO INFANTIL - PRÉ-ESCOLA.",
        "descricao": "SERVIÇOS DE EDUCAÇÃO INFANTIL - PRÉ-ESCOLA",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1307",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8015200",
        "denominacao": "ENSINO FUNDAMENTAL",
        "descricao": "SERVIÇOS DE EDUCAÇÃO - ENSINO FUNDAMENTAL",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1308",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8020900",
        "denominacao": "ENSINO MÉDIO.",
        "descricao": "SERVIÇOS DE EDUCAÇÃO - ENSINO MÉDIO",
        "item": "8",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1309",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499309",
        "denominacao": "ESCAFANDRIA E MERGULHO",
        "descricao": "SERVIÇOS DE INSTRUÇÃO DE MERGULHO",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1065",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8099307",
        "denominacao": "CURSOS PREPARATÓRIOS DE CONCURSOS.",
        "descricao": "SERVIÇOS DE ENSINO PREPARATÓRIO PARA CONCURSOS",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1321",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9239803",
        "denominacao": "ACADEMIAS DE DANÇA",
        "descricao": "SERVIÇOS DE ACADEMIA DE DANÇA",
        "item": "8",
        "subitem": "2",
        "aliquota": "2",
        "idcnae": "1323",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9191000",
        "denominacao": "ATIVIDADES DE ORGANIZAÇÕES RELIGIOSAS",
        "descricao": "SERVIÇOS DE EDUCAÇÃO RELIGIOSA",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1327",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7450001",
        "denominacao": "SELEÇÃO E AGENCIAMENTO DE MÃO-DE-OBRA",
        "descricao": "SERVIÇO DE TREINAMENTO DE RECURSOS HUMANOS",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1326",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9261405",
        "denominacao": "ATIVIDADES DE CONDICIONAMENTO FÍSICO.",
        "descricao": "SERVIÇO DE INSTRUÇÃO DE GINÁSTICA",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1325",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9261404",
        "denominacao": "ENSINO DE ESPORTES",
        "descricao": "SERVIÇOS DE INSTRUÇÃO DE ESPORTES",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1324",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8099306",
        "denominacao": "CURSOS LIGADOS ÀS ARTES E CULTURA.",
        "descricao": "SERVIÇOS DE ENSINO ARTÍSTICO",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1322",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8099306",
        "denominacao": "CURSOS LIGADOS ÀS ARTES E CULTURA.",
        "descricao": "SERVIÇOS DE ENSINO LIGADOS ÀS ARTES E CULTURA",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1320",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8099302",
        "denominacao": "CURSOS DE PILOTAGEM",
        "descricao": "SERVIÇOS DE INSTRUÇÃO DE PILOTAGEM",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1316",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8099303",
        "denominacao": "CURSOS DE IDIOMAS.",
        "descricao": "SENVIÇOS DE CURSOS DE LÍNGUA ESTRANGEIRA",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1317",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8099305",
        "denominacao": "TREINAMENTO EM DESENVOLVIMENTO PROFISSIONAL E GERENCIAL.",
        "descricao": "SERVIÇOS DE TREINAMENTO EM DESENVOLVIMENTO PROFISSIONAL E GERENCIAL",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1319",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8099304",
        "denominacao": "CURSOS DE INFORMÁTICA",
        "descricao": "SERVIÇOS DE ENSINO EM INFORMÁTICA",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1318",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8099301",
        "denominacao": "FORMAÇÃO DE CONDUTORES",
        "descricao": "SERVIÇOS DE INSTRUÇÃO DE CODUTORES (AUTO-ESCOLA)",
        "item": "8",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1315",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5513101",
        "denominacao": "HOTEL",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM HOTEL",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "1328",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5519002",
        "denominacao": "CAMPING",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM CAMPING",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "1333",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5590100",
        "denominacao": "OUTROS TIPOS DE ALOJAMENTO",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM FLAT",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "1335",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5519099",
        "denominacao": "OUTROS TIPOS DE ALOJAMENTO",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM RESIDÊNCIAS",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "1337",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5519099",
        "denominacao": "OUTROS TIPOS DE ALOJAMENTO",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM RESIDENCE-SERVICE E SUÍTE-SERVECE",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "1336",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5519005",
        "denominacao": "PENSÃO",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM PENSÃO",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "1334",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5519001",
        "denominacao": "ALBERGUES - EXCETO ASSISTENCIAIS",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM ALBERGUES",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "1332",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5513101",
        "denominacao": "HOTEL",
        "descricao": "SERVIÇOS DE HOSPEDAGEM MARÍTIMA",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "1329",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5513103",
        "denominacao": "MOTEL",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM MOTEL",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "1331",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5513102",
        "denominacao": "APART-HOTEL",
        "descricao": "SERVIÇOS DE HOSPEDAGEM EM APART-HOTEL",
        "item": "9",
        "subitem": "1",
        "aliquota": "2,5",
        "idcnae": "1330",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6330400",
        "denominacao": "ATIVIDADES DE AGÊNCIAS DE VIAGENS E ORGANIZADORES DE VIAGEM",
        "descricao": "SERVIÇO DE AGENCIAMENTO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "1338",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6330400",
        "denominacao": "ATIVIDADES DE AGÊNCIAS DE VIAGENS E ORGANIZADORES DE VIAGEM",
        "descricao": "SERVIÇO DE PROMOÇÃO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "1340",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6330400",
        "denominacao": "ATIVIDADES DE AGÊNCIAS DE VIAGENS E ORGANIZADORES DE VIAGEM",
        "descricao": "SERVIÇO DE INTERMEDIAÇÃO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "1341",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6330400",
        "denominacao": "ATIVIDADES DE AGÊNCIAS DE VIAGENS E ORGANIZADORES DE VIAGEM",
        "descricao": "SERVIÇO DE EXECUÇÃO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "1342",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6330400",
        "denominacao": "ATIVIDADES DE AGÊNCIAS DE VIAGENS E ORGANIZADORES DE VIAGEM",
        "descricao": "SERVIÇO DE ORGANIZAÇÃO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURÇÕES, HOSPEDAGENS E COGÊNERES",
        "item": "9",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "1339",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6720299",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS SEGUROS E DA PREVIDÊNCIA COMPLEMENTAR, NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE SEGUROS",
        "item": "10",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "1348",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6720299",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS SEGUROS E DA PREVIDÊNCIA COMPLEMENTAR, NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE PREVIDÊNCIA PRIVADA",
        "item": "10",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "1347",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6720201",
        "denominacao": "CORRETORES E AGENTES DE SEGUROS E DE PLANOS DE PREVIDÊNCIA COMPLEMENTAR E DE SAÚDE",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE PLANOS DE SAÚDE",
        "item": "10",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "1346",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6712103",
        "denominacao": "CORRETORAS DE CÂMBIO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE CÂMBIO",
        "item": "10",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "1345",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6559502",
        "denominacao": "ADMINISTRAÇÃO DE CARTÃO DE CRÉDITO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE CARTÕES DE CRÉDITOS",
        "item": "10",
        "subitem": "1",
        "aliquota": "3",
        "idcnae": "1344",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6711303",
        "denominacao": "BOLSA DE MERCADORIAS E FUTUROS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTEMEDIAÇÃO",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "233",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6712101",
        "denominacao": "CORRETORAS DE TÍTULOS E VALORES MOBILIÁRIOS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE VALORES MOBILIÁRIOS",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "336",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9309201",
        "denominacao": "ATIVIDADES DE AGÊNCIAS MATRIMONIAIS",
        "descricao": "SERVIÇOS DE AGENCIAMENTOS MATRIMONIAIS",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "338",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6711302",
        "denominacao": "BOLSA DE MERCADORIAS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTEMEDIAÇÃO",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "339",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6712101",
        "denominacao": "CORRETORAS DE TÍTULOS E VALORES MOBILIÁRIOS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE TÍTULOS",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "1349",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6712104",
        "denominacao": "CORRETORAS DE CONTRATOS DE MERCADORIAS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE CONTRATOS",
        "item": "10",
        "subitem": "2",
        "aliquota": "3",
        "idcnae": "337",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6593502",
        "denominacao": "GESTÃO DE DIREITOS AUTORAIS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE PROPRIEDADE ARTÍSTICA OU LITERÁRIA",
        "item": "10",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "235",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6712105",
        "denominacao": "ADMINISTRAÇÃO DE CARTEIRAS DE TÍTULOS E VALORES PARA TERCEIROS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE DIREITOS DE PROPRIEDADE INDUSTRIAL",
        "item": "10",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "236",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9232002",
        "denominacao": "AGÊNCIAS DE VENDA DE INGRESSOS PARA SALAS DE ESPETÁCULOS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO DE INGRESSOS PARA SALAS DE ESPETÁCULOS",
        "item": "10",
        "subitem": "3",
        "aliquota": "3",
        "idcnae": "237",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6593501",
        "denominacao": "LICENCIAMENTO, COMPRA E VENDA E LEASING DE ATIVOS INTANGÍVEIS NÃO FINANCEIROS - EXCETO DIREITOS AUTORAIS",
        "descricao": "AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE CONTRATOS DE ARRENDAMENTO MERCANTIL (LEASING)",
        "item": "10",
        "subitem": "4",
        "aliquota": "3",
        "idcnae": "238",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6593501",
        "denominacao": "LICENCIAMENTO, COMPRA E VENDA E LEASING DE ATIVOS INTANGÍVEIS NÃO FINANCEIROS - EXCETO DIREITOS AUTORAIS",
        "descricao": "AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇÃO DE CONTRATOS DE FATURIZAÇÃO (FACTORING)",
        "item": "10",
        "subitem": "4",
        "aliquota": "3",
        "idcnae": "239",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6593501",
        "denominacao": "LICENCIAMENTO, COMPRA E VENDA E LEASING DE ATIVOS INTANGÍVEIS NÃO FINANCEIROS - EXCETO DIREITOS AUTORAIS",
        "descricao": "AGENCIAMENTO, CORRETAGEM OI INTERMEDIAÇÃO DE CONTRATOS DE FRANQUIA (FRANCHISING)",
        "item": "10",
        "subitem": "4",
        "aliquota": "3",
        "idcnae": "240",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499312",
        "denominacao": "ATIVIDADE DE INTERMEDIAÇÃO E AGENCIAMENTO DE SERVIÇOS E NEGÓCIOS EM GERAL, SEM ESPECIALIZAÇÃO DEFINIDA.",
        "descricao": "SERVIÇOS DE AGENCIAMENTO MARÍTIMO",
        "item": "10",
        "subitem": "6",
        "aliquota": "3",
        "idcnae": "242",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9240100",
        "denominacao": "ATIVIDADES DE AGÊNCIAS DE NOTÍCIAS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO DE NOTÍCIAS",
        "item": "10",
        "subitem": "7",
        "aliquota": "3",
        "idcnae": "243",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7440302",
        "denominacao": "AGENCIAMENTO E LOCAÇÃO DE ESPAÇOS PUBLICITÁRIOS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO DE ESPAÇOS PUBLICITÁRIOS",
        "item": "10",
        "subitem": "8",
        "aliquota": "2,5",
        "idcnae": "245",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5010507",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE VEÍCULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "246",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6719905",
        "denominacao": "REPRESENTAÇÃO DE BANCOS ESTRANGEIROS",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "258",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5117900",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE PRODUTOS ALIMENTÍCIOS, BEBIDAS E FUMO",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "255",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5030005",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE PEÇAS E ACESSÓRIOS NOVOS E USADOS PARA VEÍCULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "247",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5041505",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE PEÇAS E ACESSÓRIOS PARA MOTOCICLETAS E MOTONETAS",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "248",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5111000",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE MATÉRIAS PRIMAS AGRÍCOLAS, ANIMAIS VIVOS, MATÉRIAS PRIMAS TÊXTEIS E PRODUTOS SEMI-ACABADOS",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "249",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5112800",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE COMBUSTÍVEIS, MINERAIS, METAIS E PRODUTOS QUÍMICOS INDUSTRIAIS",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "250",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5113600",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE MADEIRA, MATERIAL DE CONSTRUÇÃO E FERRAGENS",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "251",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5114400",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE MÁQUINAS, EQUIPAMENTOS INDUSTRIAIS, EMBARCAÇÕES E AERONAVES",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "252",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5115200",
        "denominacao": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE MÓVEIS E ARTIGOS DE USO DOMÉSTICO",
        "descricao": "SERVIÇOS DE REPRESENTAÇÃO COMERCIAL",
        "item": "10",
        "subitem": "9",
        "aliquota": "2,5",
        "idcnae": "253",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6712102",
        "denominacao": "DISTRIBUIDORAS DE TÍTULOS E VALORES MOBILIÁRIOS",
        "descricao": "SERVIÇOS DE DISTRIBUIÇÃO DE TÍTULOS E VALORES MOBILIÁRIOS",
        "item": "10",
        "subitem": "10",
        "aliquota": "3",
        "idcnae": "259",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9212600",
        "denominacao": "DISTRIBUIÇÃO DE FILMES E DE VÍDEOS",
        "descricao": "SERVIÇOS DE DISTRIBUIÇÃO DE FILMES E DE VÍDEOS",
        "item": "10",
        "subitem": "10",
        "aliquota": "3",
        "idcnae": "260",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9212600",
        "denominacao": "DISTRIBUIÇÃO DE FILMES E DE VÍDEOS",
        "descricao": "SERVIÇOS DE DISTRIBUIÇAO DE FILMES OU VIDEOTAPES",
        "item": "10",
        "subitem": "10",
        "aliquota": "3",
        "idcnae": "262",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6321503",
        "denominacao": "EXPLORAÇÃO DE ESTACIONAMENTO PARA VEÍCULOS",
        "descricao": "SERVIÇOS DE GUARDA E ESTACIONAMENTO DE AERONAVES",
        "item": "11",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "263",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6321503",
        "denominacao": "EXPLORAÇÃO DE ESTACIONAMENTO PARA VEÍCULOS",
        "descricao": "SERVIÇOS DE GUARDA E ESTACIONAMENTO DE VEÍCULOS TERRESTRES AUTOMOTORES",
        "item": "11",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "265",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6321503",
        "denominacao": "EXPLORAÇÃO DE ESTACIONAMENTO PARA VEÍCULOS",
        "descricao": "SERVIÇOS DE GUARDA E ESTACIONAMENTO DE EMBARCAÇÕES",
        "item": "11",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "264",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7460802",
        "denominacao": "ATIVIDADES DE VIGILÂNCIA E SEGURANÇA PRIVADA",
        "descricao": "SERVIÇOS DE VIGILÂNCIA, SEGURANÇA OU MONITORAMENTO DE BENS E PESSOAS",
        "item": "11",
        "subitem": "2",
        "aliquota": "2,5",
        "idcnae": "266",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7460802",
        "denominacao": "ATIVIDADES DE VIGILÂNCIA E SEGURANÇA PRIVADA",
        "descricao": "SERVIÇOS DE ESCOLTA",
        "item": "11",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "267",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6028302",
        "denominacao": "SERVIÇO DE GUARDA-MÓVEIS",
        "descricao": "SERVIÇOS DE GUARDA DE BENS",
        "item": "11",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "268",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6312601",
        "denominacao": "ARMAZÉNS GERAIS (EMISSÃO DE WARRANTS)",
        "descricao": "SERVIÇOS DE ARMAZENAMENTO ",
        "item": "11",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "269",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6312602",
        "denominacao": "OUTROS DEPÓSITOS DE MERCADORIAS PARA TERCEIROS",
        "descricao": "SERVIÇOS DE ARRUMAÇÃO E GUARDA DE BENS DE QUALQUER NATUREZA",
        "item": "11",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "270",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6311800",
        "denominacao": "CARGA E DESCARGA",
        "descricao": "SERVIÇOS DE CARGA E DESCARGA ",
        "item": "11",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "272",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6312602",
        "denominacao": "OUTROS DEPÓSITOS DE MERCADORIAS PARA TERCEIROS",
        "descricao": "SERVIÇOS DE DEPÓSITOS DE BENS DE QUALQUER NATUREZA",
        "item": "11",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "271",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9231201",
        "denominacao": "COMPANHIAS DE TEATRO",
        "descricao": "SERVIÇOS DE ESPETÁCULOS TEATRAIS",
        "item": "12",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "273",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9213400",
        "denominacao": "PROJEÇÃO DE FILMES E DE VÍDEOS",
        "descricao": "SERVIÇOS DE EXIBIÇÃO CINEMATOGRÁFICA",
        "item": "12",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "274",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9213400",
        "denominacao": "PROJEÇÃO DE FILMES E DE VÍDEOS",
        "descricao": "SERVIÇOS DE EXIBIÇÕES CINEMATOGRÁFICAS",
        "item": "12",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "275",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9239801",
        "denominacao": "PRODUÇÃO DE ESPETÁCULOS CIRCENSES, MARIONETES E SIMILARES",
        "descricao": "SERVIÇOS DE ESPETÁCULOS CIRCENSES",
        "item": "12",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "276",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9239899",
        "denominacao": "OUTRAS ATIVIDADES DE ESPETÁCULOS, NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE PROGRAMA DE AUDITÓRIO",
        "item": "12",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "277",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9253300",
        "denominacao": "ATIVIDADES DE JARDINS BOTÂNICOS, ZOOLÓGICOS, PARQUES NACIONAIS E RESERVAS ECOLÓGICAS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE JARDINS BOTÂNICOS, ZOOLÓGICOS, PARQUES NACIONAIS E RESERVAS ECOLÓGICAS",
        "item": "12",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "368",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9262207",
        "denominacao": "EXPLORAÇÃO DE PARQUES DE DIVERSÕES E SIMILARES",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE CENTROS DE LAZER",
        "item": "12",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "369",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9262207",
        "denominacao": "EXPLORAÇÃO DE PARQUES DE DIVERSÕES E SIMILARES",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE PARQUES DE DIVERSÕES",
        "item": "12",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "370",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9239804",
        "denominacao": "DISCOTECAS, DANCETERIAS E SIMILARES",
        "descricao": "SERVIÇOS DE TAXI-DANCING",
        "item": "12",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "282",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9239804",
        "denominacao": "DISCOTECAS, DANCETERIAS E SIMILARES",
        "descricao": "SERVIÇOS DE BOATE",
        "item": "12",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "371",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9239804",
        "denominacao": "DISCOTECAS, DANCETERIAS E SIMILARES",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE SALÕES DE BAILES",
        "item": "12",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "283",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9232001",
        "denominacao": "EXPLORAÇÃO DE SALAS DE ESPETÁCULOS",
        "descricao": "SERVIÇOS DE ÓPERA, CONCERTOS, RECITAIS E FESTIVAIS",
        "item": "12",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "285",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9232001",
        "denominacao": "EXPLORAÇÃO DE SALAS DE ESPETÁCULOS",
        "descricao": "SERVIÇOS DE BALLET, DANÇAS, DESFILES E SHOWS",
        "item": "12",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "284",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499307",
        "denominacao": "SERVIÇOS DE ORGANIZAÇÃO DE FESTAS E EVENTOS - EXCETO CULTURAIS E DESPORTIVOS",
        "descricao": "SERVIÇOS DE ORGANIZAÇÃO DE FEIRAS, EVENTOS E CONGRESSOS",
        "item": "12",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "286",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9231203",
        "denominacao": "PRODUÇÃO, ORGANIZAÇÃO E PROMOÇÃO DE ESPETÁCULOS ARTÍSTICOS E EVENTOS CULTURAIS",
        "descricao": "SERVIÇOS DE EXPOSIÇÃO ",
        "item": "12",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "287",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9262201",
        "denominacao": "EXPLORAÇÃO DE BINGOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE DIVERSÕES ELETRÔNICAS OU NÃO",
        "item": "12",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "288",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9262203",
        "denominacao": "ATIVIDADES DE SORTEIO VIA TELEFONE",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE DIVERSÕES ELETRÔNICAS OU NÃO",
        "item": "12",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "289",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9262204",
        "denominacao": "EXPLORAÇÃO DE OUTROS JOGOS DE AZAR",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE JOGOS E CARTEADOS",
        "item": "12",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "290",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9262206",
        "denominacao": "EXPLORAÇÃO DE FLIPERAMAS E JOGOS ELETRÔNICOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE JOGOS ELETRÔNICOS",
        "item": "12",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "292",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9262208",
        "denominacao": "EXPLORAÇÃO DE JOGOS DE SINUCA, BILHAR E SIMILARES",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE JOGOS DE BILHARES",
        "item": "12",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "293",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9262205",
        "denominacao": "EXPLORAÇÃO DE BOLICHES",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE JOGOS DE BOLICHE E BOCHA",
        "item": "12",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "291",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9239802",
        "denominacao": "PRODUÇÃO DE ESPETÁCULOS DE RODEIOS, VAQUEJADAS E SIMILARES",
        "descricao": "SERVIÇOS DE ESPETÁCULOS DE RODEIOS E VAQUEJADAS",
        "item": "12",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "294",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9261406",
        "denominacao": "ATIVIDADES LIGADAS À CORRIDA DE CAVALOS",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE CORRIDAS E COMPETIÇÕES DE ANIMAIS",
        "item": "12",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "295",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9261402",
        "denominacao": "ORGANIZAÇÃO E EXPLORAÇÃO DE ATIVIDADES DESPORTIVAS",
        "descricao": "SERVIÇOS DE COMPETIÇÕES ESPORTIVAS OU DE DESTREZA FÍSICA OU INTELECTUAL",
        "item": "12",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "296",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9231203",
        "denominacao": "PRODUÇÃO, ORGANIZAÇÃO E PROMOÇÃO DE ESPETÁCULOS ARTÍSTICOS E EVENTOS CULTURAIS",
        "descricao": "SERVIÇOS DE EXECUÇÃO DE MÚSICA",
        "item": "12",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "297",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9231203",
        "denominacao": "PRODUÇÃO, ORGANIZAÇÃO E PROMOÇÃO DE ESPETÁCULOS ARTÍSTICOS E EVENTOS CULTURAIS",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE EVENTOS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "298",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9231203",
        "denominacao": "PRODUÇÃO, ORGANIZAÇÃO E PROMOÇÃO DE ESPETÁCULOS ARTÍSTICOS E EVENTOS CULTURAIS",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE SHOWS E FESTIVAIS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "300",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9231203",
        "denominacao": "PRODUÇÃO, ORGANIZAÇÃO E PROMOÇÃO DE ESPETÁCULOS ARTÍSTICOS E EVENTOS CULTURAIS",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE ÓPERAS, CONCERTOS E RECITAIS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "302",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9239804",
        "denominacao": "DISCOTECAS, DANCETERIAS E SIMILARES",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE BAILES",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "304",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9231203",
        "denominacao": "PRODUÇÃO, ORGANIZAÇÃO E PROMOÇÃO DE ESPETÁCULOS ARTÍSTICOS E EVENTOS CULTURAIS",
        "descricao": "SERVIÇOS DE PRODUÇÕES TEATRAIS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "303",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9231203",
        "denominacao": "PRODUÇÃO, ORGANIZAÇÃO E PROMOÇÃO DE ESPETÁCULOS ARTÍSTICOS E EVENTOS CULTURAIS",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE ESPETÁCULOS, DESFILES E ENTREVISTAS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "301",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9231203",
        "denominacao": "PRODUÇÃO, ORGANIZAÇÃO E PROMOÇÃO DE ESPETÁCULOS ARTÍSTICOS E EVENTOS CULTURAIS",
        "descricao": "SERVIÇOS DE PRODUÇÃO DE DANÇAS",
        "item": "12",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "299",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9232004",
        "denominacao": "SERVIÇOS DE SONORIZAÇÃO E OUTRAS ATIVIDADES LIGADAS À GESTÃO DE SALAS DE ESPETÁCULOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE MÚSICA",
        "item": "12",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "305",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9199500",
        "denominacao": "OUTRAS ATIVIDADES ASSOCIATIVAS, NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE DESFILE DE BLOCOS CARNAVALESCOS OU FOLCLORÍCOS E TRIOS ELÉTRICOS",
        "item": "12",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "306",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9213400",
        "denominacao": "PROJEÇÃO DE FILMES E DE VÍDEOS",
        "descricao": "SERVIÇOS DE EXIBIÇÃO DE FILMES",
        "item": "12",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "307",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9231299",
        "denominacao": "OUTROS SERVIÇOS ESPECIALIZADOS LIGADOS ÀS ATIVIDADES ARTÍSTICAS",
        "descricao": "SEVIÇOS DE EXIBIÇÃO DE ENTREVISTAS, MUSICAIS, ESPETÁCULOS, SHOWS, CONCERTOS, DESFILES, ÓPERAS, COMPETIÇÕES ESPORTIVAS, DEDESTREZA INTELECTUAL",
        "item": "12",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "308",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9231299",
        "denominacao": "OUTROS SERVIÇOS ESPECIALIZADOS LIGADOS ÀS ATIVIDADES ARTÍSTICAS",
        "descricao": "SERVIÇOS DE RECREAÇÃO E ANIMAÇÃO",
        "item": "12",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "309",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9211802",
        "denominacao": "ATIVIDADES DE PRODUÇÃO DE FILMES E FITAS DE VÍDEO - EXCETO ESTÚDIOS CINEMATOGRÁFICOS",
        "descricao": "SERVIÇOS DE FONOGRAFIA OU GRAVAÇÃO DE SONS",
        "item": "13",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "310",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499311",
        "denominacao": "EMISSÃO DE VALES ALIMENTAÇÃO, TRANSPORTE E SIMILARES",
        "descricao": "SERVIÇOS DE EMISSÃO DE VALES ALIMENTAÇÃO, TRANSPORTE E SIMILARES",
        "item": "13",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1067",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9211803",
        "denominacao": "SERVIÇOS DE DUBLAGEM E MIXAGEM SONORA",
        "descricao": "SERVIÇOS DE MIXAGEM SONORA",
        "item": "13",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "312",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9211803",
        "denominacao": "SERVIÇOS DE DUBLAGEM E MIXAGEM SONORA",
        "descricao": "SERVIÇOS DE TRUCAGEM SONORA",
        "item": "13",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "313",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9211803",
        "denominacao": "SERVIÇOS DE DUBLAGEM E MIXAGEM SONORA",
        "descricao": "SERVIÇOS DE DUBLAGEM SONORA",
        "item": "13",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "311",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9211804",
        "denominacao": "ESTÚDIOS DE GRAVAÇÃO DE SOM",
        "descricao": "SERVIÇOS DE GRAVAÇÃO DE SONS",
        "item": "13",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "314",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7491801",
        "denominacao": "ESTÚDIOS FOTOGRÁFICOS",
        "descricao": "SERVIÇOS FOTOGRÁFICOS",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "315",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7491805",
        "denominacao": "FILMAGEM DE FESTAS E EVENTOS.",
        "descricao": "SERVIÇOS DE FILMAGEM DE FESTAS E EVENTOS",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "318",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9211801",
        "denominacao": "ESTÚDIOS CINEMATOGRÁFICOS",
        "descricao": "SERVIÇOS DE CINEMATOGRAFIA",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "320",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9211802",
        "denominacao": "ATIVIDADES DE PRODUÇÃO DE FILMES E FITAS DE VÍDEO - EXCETO ESTÚDIOS CINEMATOGRÁFICOS",
        "descricao": "SERVIÇOS DE GRAVAÇÃO DE FILMES OU VIDEOTAPES",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "321",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499302",
        "denominacao": "FOTOCÓPIAS, DIGITALIZAÇÃO E SERVIÇOS CORRELATOS.",
        "descricao": "SERVIÇOS DE REPRODUÇÃO DE PLANTAS OU DESENHOS",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "319",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7491804",
        "denominacao": "SERVIÇOS DE FOTOGRAFIAS AÉREAS, SUBMARINAS E SIMILARES",
        "descricao": "SERVIÇOS DE FOTOGRAFIAS AÉREAS E SUBMARINAS ",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "317",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7491803",
        "denominacao": "LABORATÓRIOS FOTOGRÁFICOS",
        "descricao": "SERVIÇOS DE REVELAÇÃO FOTOGRÁFICA",
        "item": "13",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "316",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2231400",
        "denominacao": "REPRODUÇÃO DE DISCOS E FITAS",
        "descricao": "SERVIÇOS DE REPROGRAFIA",
        "item": "13",
        "subitem": "4",
        "aliquota": "0",
        "idcnae": "322",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2234900",
        "denominacao": "REPRODUÇÃO DE PROGRAMAS DE INFORMÁTICA EM DISQUETES E FITAS",
        "descricao": "SERVIÇOS DE REPROGRAFIA",
        "item": "13",
        "subitem": "4",
        "aliquota": "0",
        "idcnae": "324",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499302",
        "denominacao": "FOTOCÓPIAS, DIGITALIZAÇÃO E SERVIÇOS CORRELATOS.",
        "descricao": "SERVIÇOS DE REPROGRAFIA ",
        "item": "13",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "326",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499302",
        "denominacao": "FOTOCÓPIAS, DIGITALIZAÇÃO E SERVIÇOS CORRELATOS.",
        "descricao": "SERVIÇOS DE DIGITALIZAÇÃO",
        "item": "13",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "327",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7491806",
        "denominacao": "SERVIÇOS DE MICROFILMAGEM.",
        "descricao": "SERVIÇOS DE MICROFILMAGEM",
        "item": "13",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "325",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2232200",
        "denominacao": "REPRODUÇÃO DE FITAS DE VÍDEOS",
        "descricao": "SERVIÇOS DE REPROGRAFIA",
        "item": "13",
        "subitem": "4",
        "aliquota": "0",
        "idcnae": "323",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2229202",
        "denominacao": "COMPOSIÇÃO DE MATRIZES PARA IMPRESSÃO GRÁFICA",
        "descricao": "SERVIÇOS DE CLICHERIA",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "328",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2229202",
        "denominacao": "COMPOSIÇÃO DE MATRIZES PARA IMPRESSÃO GRÁFICA",
        "descricao": "SERVIÇOS DE LITOGRAFIA",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "329",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2229202",
        "denominacao": "COMPOSIÇÃO DE MATRIZES PARA IMPRESSÃO GRÁFICA",
        "descricao": "SERVIÇOS DE ZINCOGRAFIA",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "330",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2229203",
        "denominacao": "SERVIÇOS DE ACABAMENTOS GRÁFICOS",
        "descricao": "SERVIÇOS DE COMPOSIÇÃO GRÁFICA",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "332",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2222502",
        "denominacao": "IMPRESSÃO DE MATERIAL PARA USO INDUSTRIAL, COMERCIAL E PUBLICITÁRIO",
        "descricao": "SERVIÇOS DE COMPOSIÇÃO GRÁFICA",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "334",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2222503",
        "denominacao": "IMPRESSÃO DE MATERIAL DE SEGURANÇA",
        "descricao": "SERVIÇOS DE COMPOSIÇÃO GRÁFICA",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "335",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2222501",
        "denominacao": "IMPRESSÃO DE MATERIAL PARA USO ESCOLAR",
        "descricao": "SERVIÇOS DE COMPOSIÇÃO GRÁFICA",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "333",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499399",
        "denominacao": "OUTROS SERVIÇOS PRESTADOS PRINCIPALMENTE ÀS EMPRESAS",
        "descricao": "SERVIÇOS DE COMPOSIÇÃO GRÁFICA",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "411",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2229202",
        "denominacao": "COMPOSIÇÃO DE MATRIZES PARA IMPRESSÃO GRÁFICA",
        "descricao": "SERVIÇOS DE FOTOLITOGRAFIA  ",
        "item": "13",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "331",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2991203",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE VÁLVULAS INDUSTRIAIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "340",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2991204",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE COMPRESSORES.",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "341",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2991205",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE EQUIPAMENTOS DE TRANSMISSÃO PARA FINS INDUSTRIAIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "342",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2993902",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE TRATORES AGRÍCOLAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS E MOTORES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "344",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2995502",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE OUTRAS MÁQUINAS E EQUIPAMENTOS DE USO NA EXTRAÇÃO MINERAL E CONSTRUÇÃO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS, MOTORES E EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "346",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2995504",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E EQUIPAMENTOS DE TERRAPLENAGEM E PAVIMENTAÇÃO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS, MOTORES E EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "348",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2996302",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA AS INDÚSTRIAS ALIMENTAR, DE BEBIDAS E FUMO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS, MOTORES E EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "350",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5020204",
        "denominacao": "SERVIÇOS DE BORRACHEIROS E GOMARIA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERTO DE PNEUS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "373",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5020203",
        "denominacao": "SERVIÇOS DE LAVAGEM, LUBRIFICAÇÃO E POLIMENTO DE VEÍCULOS",
        "descricao": "SERVIÇOS DE LIMPEZA E LUSTRAÇÃO DE VEÍCULOS (LAVADOR DE CARROS)",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "372",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4541102",
        "denominacao": "INSTALAÇÃO, MANUTENÇÃO E REPARAÇÃO DE ELEVADORES, ESCADAS E ESTEIRAS ROLANTES - EXCETO DE FABRICAÇÃO PRÓPRIA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E REPARAÇÃO DE ELEVADORES, ESCADAS E ESTEIRAS LOLANTES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "365",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3394400",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE APARELHOS E INSTRUMENTOS ÓPTICOS E CINEMATOGRÁFICOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "364",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3393600",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS, APARELHOS E EQUIPAMENTOS DE SISTEMAS ELETRÔNICOS DEDICADOS A AUTOMAÇÃO INDUSTRIAL E CONTROLE DO PROCESSO PRODUTIVO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "363",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3392800",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE APARELHOS E INSTRUMENTOS DE MEDIDA, TESTE E CONTROLE - EXCETO EQUIPAMENTOS PARA CONTROLE DE PROCESSOS INDUSTRIAIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "362",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3391000",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE APARELHOS E UTENSÍLIOS PARA USOS MÉDICO-HOSPITALARES, ODONTOLÓGICOS E DE LABORATÓRIO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "361",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3290502",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE SISTEMAS DE INTERCOMUNICAÇÃO E SEMELHANTES - EXCETO TELEFONES",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "360",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3290501",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE EQUIPAMENTOS TRANSMISSORES DE RÁDIO E TELEVISÃO E DE EQUIPAMENTOS PARA ESTAÇÕES TELEFÔNICAS, PARA RADIOTELEFONIA E RADIOTELEGRAFIA - INCLUSIVE DE MICROOND",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "359",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6322303",
        "denominacao": "LIMPEZA DE CASCOS E MANUTENÇÃO DE NAVIOS NO PORTO",
        "descricao": "SERVIÇOS DE LIMPEZA E MANUTENÇÃO DE NAVIOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "382",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5279504",
        "denominacao": "REPARAÇAO DE BICICLETAS, TRICICLOS E OUTROS VEÍCULOS RECREATIVOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE VEÍCULOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "381",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5279503",
        "denominacao": "CONSERTO E RESTAURAÇÃO DE ARTIGOS DE MADEIRA E DO MOBILIÁRIO",
        "descricao": "SERVIÇOS DE REPARAÇÃO DE ARTIGOS DE TAPEÇARIA",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "380",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5279503",
        "denominacao": "CONSERTO E RESTAURAÇÃO DE ARTIGOS DE MADEIRA E DO MOBILIÁRIO",
        "descricao": "SERVIÇOS DE CONSERTO E RESTAURAÇÃO DE MÓVEIS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "379",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5279502",
        "denominacao": "REPARAÇÃO DE JÓIAS E RELÓGIOS",
        "descricao": "SERVIÇOS DE CONSERTOS DE JOIAS E RELÓGIOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "378",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5279501",
        "denominacao": "CHAVEIROS",
        "descricao": "SERVIÇOS DE CONSERTOS DE FECHADURAS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "377",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5042300",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MOTOCICLETAS E MOTONETAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE VEÍCULOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "376",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5020205",
        "denominacao": "SERVIÇOS DE INSTALAÇÃO, MANUTENÇÃO E REPARAÇÃO DE ACESSÓRIOS PARA VEÍCULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE PELÍCULA DE CONTENÇÃO SOLAR EM VEÍCULOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "375",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5020205",
        "denominacao": "SERVIÇOS DE INSTALAÇÃO, MANUTENÇÃO E REPARAÇÃO DE ACESSÓRIOS PARA VEÍCULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE INSTALAÇÃO, MANUTENÇÃO E REPARAÇÃO DE ACESSÓRIOS PARA VEÍCULOS AUTOMOTORES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "374",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4542000",
        "denominacao": "INSTALAÇÃO E MANUTENÇÃO DE SISTEMAS CENTRAIS DE AR CONDICIONADO, DE VENTILAÇÃO E REFRIGERAÇÃO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE SISTEMAS CENTRAIS DE AR CONDICIONADO",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "399",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3532700",
        "denominacao": "REPARAÇÃO DE AERONAVES",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE AERONAVES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "398",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3512202",
        "denominacao": "REPARAÇÃO DE EMBARCAÇÕES PARA ESPORTE E LAZER",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE VEÍCULOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "397",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3511403",
        "denominacao": "REPARAÇÃO DE EMBARCAÇÕES PARA USO COMERCIAL E PARA USOS ESPECIAIS - EXCETO DE GRANDE PORTE",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE VEÍCULOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "396",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3511401",
        "denominacao": "CONSTRUÇÃO E REPARAÇÃO DE EMBARCAÇÕES DE GRANDE PORTE",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE VEÍCULOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "395",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3439800",
        "denominacao": "FABRICAÇÃO DE CABINES, CARROCERIAS E REBOQUES PARA OUTROS VEÍCULOS",
        "descricao": "SERVIÇOS DE BLINDAGEM DE MÁQUINAS, VEÍCULOS, APARELHOS, EQUIPAMENTOS, MOTORES, ELEVADORES OU DE QUALQUER OBJETO",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "394",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2996399",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE OUTRAS MÁQUINAS E EQUIPAMENTOS DE USO ESPECÍFICO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS, MOTORES E EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "393",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2994700",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS-FERRAMENTA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS E MOTORES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "392",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2992099",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE OUTRAS MÁQUINAS E EQUIPAMENTOS DE USO GERAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS E MOTORES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "391",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2991202",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE BOMBAS E CARNEIROS HIDRÁULICOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "413",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2991201",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS MOTRIZES NÃO-ELÉTRICAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS E MOTORES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "412",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7250800",
        "denominacao": "MANUTENÇÃO, REPARAÇÃO E INSTALAÇÃO DE MÁQUINAS DE ESCRITÓRIO E DE INFORMÁTICA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, REPARAÇÃO E INSTALAÇÃO DE MÁQUINAS DE ESCRITRÓRIOS E INFORMÁTICA",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "405",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5279599",
        "denominacao": "REPARAÇÃO DE OUTROS OBJETOS PESSOAIS E DOMÉSTICOS",
        "descricao": "SERVIÇOS DE AFIAÇÃO (AFIAÇÃO DE LÂMINAS)",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "404",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5279599",
        "denominacao": "REPARAÇÃO DE OUTROS OBJETOS PESSOAIS E DOMÉSTICOS",
        "descricao": "CONSERTO DE EQUIPAMENTOS DE CAMPING",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "403",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5272800",
        "denominacao": "REPARAÇÃO DE CALÇADOS",
        "descricao": "SERVIÇOS DE REPARAÇÃO DE CALÇADOS OU ARTIGOS DE COURO",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "402",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5271002",
        "denominacao": "REPARAÇÃO E MANUTENÇÃO DE APARELHOS TELEFÔNICOS",
        "descricao": "SERVIÇOS DE REPARAÇÃO E MANUTENÇÃO DE APARELHOS TELEFÔNICOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "401",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5271001",
        "denominacao": "REPARAÇÃO E MANUTENÇÃO DE MÁQUINAS E DE APARELHOS ELETRODOMÉSTICOS - EXCETO APARELHOS TELEFÔNICOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE APARELHOS ELÉTRICOS E ELETRÔNICOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "400",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2992005",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE ELEVADORES, ESCADAS E ESTEIRAS ROLANTES DE FABRICAÇÃO PRÓPRIA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE ELEVADORES, ESCADAS E ESTEIRAS ROLANTES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "390",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2992004",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS, EQUIPAMENTOS E APARELHOS PARA TRANSPORTE E ELEVAÇÃO DE CARGAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS E MOTORES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "389",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2992003",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E APARELHOS DE REFRIGERAÇÃO E VENTILAÇÃO PARA USOS INDUSTRIAL E COMERCIAL.",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS E MOTORES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "388",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2992002",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE ESTUFAS E FORNOS ELÉTRICOS PARA FINS INDUSTRIAIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "387",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2992001",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE FORNOS INDUSTRIAIS, APARELHOS E EQUIPAMENTOS NÃO-ELÉTRICOS PARA INSTALAÇÕES TÉRMICAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "386",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2882700",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE CALDEIRAS GERADORAS DE VAPOR - EXCETO PARA AQUECIMENTO CENTRAL E PARA VEÍCULOS.",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "385",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2881900",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE TANQUES, RESERVATÓRIOS METÁLICOS E CALDEIRAS PARA AQUECIMENTO CENTRAL.",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "384",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6323102",
        "denominacao": "MANUTENÇÃO DE AERONAVES NA PISTA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE AERONAVES",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "383",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3189500",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS, APARELHOS E MATERIAIS ELÉTRICOS NÃO ESPECIFICADOS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS, MOTORES E EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "358",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3182800",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE BATERIAS E ACUMULADORES ELÉTRICOS - EXCETO PARA VEÍCULOS.",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "357",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3181003",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MOTORES ELÉTRICOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "356",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3181002",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE TRANSFORMADORES, INDUTORES, CONVERSORES, SINCRONIZADORES E SEMELHANTES",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "355",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3181001",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE GERADORES DE CORRENTE CONTÍNUA OU ALTERNADA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRUAÇÃO DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "354",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2996305",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E APARELHOS PARA A INDÚSTRIA DE CELULOSE, PAPEL, PAPELÃO E ARTEFATOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS, MOTORES E EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "353",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2996304",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MAQUINAS E EQUIPAMENTOS PARA AS INDÚSTRIAS DO VESTUÁRIO E DE COURO E CALÇADOS.",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS, MOTORES E EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "352",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2996303",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA A INDÚSTRIA TÊXTIL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS, MOTORES E EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "351",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5020203",
        "denominacao": "SERVIÇOS DE LAVAGEM, LUBRIFICAÇÃO E POLIMENTO DE VEÍCULOS",
        "descricao": "SERVIÇOS DE LUBRIFICAÇÃO, LUSTRAÇÃO, REVISÃO E RESTAURAÇÃO DE VEÍCULOS ",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "464",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5020202",
        "denominacao": "SERVIÇOS DE MANUTENÇÃO E REPARAÇÃO DE CAMINHÕES, ÔNIBUS E OUTROS VEÍCULOS PESADOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE VEÍCULOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "463",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2996301",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS PARA INDÚSTRIA METALÚRGICA - EXCETO MÁQUINAS-FERRAMENTA",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS, MOTORES E EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "349",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2995503",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE TRATORES DE ESTEIRA E TRATORES DE USO NA EXTRAÇÃO MINERAL E CONSTRUÇÃO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS, MOTORES E EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "347",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2995501",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MAQUINAS E EQUIPAMENTOS PARA A PROSPECÇÃO E EXTRAÇÃO DE PETRÓLEO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS, MOTORES E EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "345",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2993901",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA AGRICULTURA, AVICULTURA E OBTENÇÃO DE PRODUTOS ANIMAIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO, CONSERVAÇÃO, LUBRIFICAÇÃO, LIMPEZA, RESTAURAÇÃO, REVISÃO E LUSTRAÇÃO DE MÁQUINAS, MOTORES E EQUIPAMENTOS",
        "item": "14",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "343",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5279502",
        "denominacao": "REPARAÇÃO E MANUTENÇÃO DE MÁQUINAS E DE APARELHOS ELETRODOMÉSTICOS - EXCETO APARELHOS TELEFÔNICOS",
        "descricao": "SERVIÇOS DE ASSISTÊNCIA TÉCNICA",
        "item": "14",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "406",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5271002",
        "denominacao": "REPARAÇÃO E MANUTENÇÃO DE APARELHOS TELEFÔNICOS",
        "descricao": "SERVIÇOS DE ASSISTÊNCIA TÉCNICA",
        "item": "14",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "407",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3450900",
        "denominacao": "RECONDICIONAMENTO OU RECUPERAÇÃO DE MOTORES PARA VEÍCULOS AUTOMOTORES",
        "descricao": "SERVIÇOS DE RECONDICIONAMENTO DE MOTORES",
        "item": "14",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "408",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2512700",
        "denominacao": "RECONDICIONAMENTO DE PNEUMÁTICOS",
        "descricao": "SERVIÇOS DE RECAUCHUTAGEM OU REGENERAÇÃO DE PNEUS",
        "item": "14",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "409",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6340103",
        "denominacao": "AGENCIAMENTO DE CARGAS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO DE CARGAS",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "414",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "1750701",
        "denominacao": "ESTAMPARIA E TEXTURIZAÇÃO EM FIOS, TECIDOS E ARTIGOS TÊXTEIS, INCLUSIVE EM PEÇAS DO VESTÁRIO",
        "descricao": "SERVIÇOS DE ESTAMPARIA",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "416",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7492600",
        "denominacao": "ATIVIDADES DE ENVASAMENTO E EMPACOTAMENTO, POR CONTA DE TERCEIROS",
        "descricao": "SERVIÇOS DE ENVASAMENTO E EMPACOTAMENTO",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "415",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "1750702",
        "denominacao": "ALVEJAMENTO, TINGIMENTO E TORÇÃO EM FIOS, TECIDOS E ARTIGOS TÊXTEIS, INCLUSIVE EM PEÇAS DO VESTUÁRIO",
        "descricao": "SERVIÇOS DE RESTAUÇÃO, RECONDICIONAMENTO, ACONDICIONAMENTO, PINTURA, BENEFICIAMENTO, LAVAGEM SECAGEM, TINGIMENTO, CORTE E RECORTE EM FIOS, TECIDOS E ARTIGOS TEXTEIS, INCLUSIVE EM PEÇAS DO VESTUÁRIO",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "417",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2839800",
        "denominacao": "TÊMPERA, CEMENTAÇÃO E TRATAMENTO TÉRMICO DO AÇO, SERVIÇOS DE USINAGEM, GALVANOTÉCNICA E SOLDA",
        "descricao": "SERVIÇOS DE ANODIZAÇÃO OU GALVANOPLASTIA",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "419",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2839800",
        "denominacao": "TÊMPERA, CEMENTAÇÃO E TRATAMENTO TÉRMICO DO AÇO, SERVIÇOS DE USINAGEM, GALVANOTÉCNICA E SOLDA",
        "descricao": "SERVIÇOS DE JATEAMENTO DE BENS MÓVEIS",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "421",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2839800",
        "denominacao": "TÊMPERA, CEMENTAÇÃO E TRATAMENTO TÉRMICO DO AÇO, SERVIÇOS DE USINAGEM, GALVANOTÉCNICA E SOLDA",
        "descricao": "SERVIÇOS DE OFICINA DE TORNO",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "420",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "1931302",
        "denominacao": "SERVIÇO DE CORTE E ACABAMENTO DE CALÇADOS",
        "descricao": "SERVIÇOS DE  CORTE E ACABAMENTO ",
        "item": "14",
        "subitem": "5",
        "aliquota": "0",
        "idcnae": "418",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3699401",
        "denominacao": "DECORAÇÃO, LAPIDAÇÃO, GRAVAÇÃO, ESPELHAÇÃO, BISOTAGEM, VITRIFICAÇÃO E OUTROS TRABALHOS EM CERÂMICA, LOUÇA, VIDRO OU CRISTAL",
        "descricao": "SERVIÇOS DE LAPIDAÇÃO OU POLIMENTO",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "528",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3310303",
        "denominacao": "FABRICAÇÃO DE APARELHOS E UTENSÍLIOS PARA CORREÇÃO DE DEFEITOS FÍSICOS E APARELHOS ORTOPÉDICOS EM GERAL - INCLUSIVE SOB ENCOMENDA",
        "descricao": "SERVIÇOS DE BENEFICIAMENTO DE LENTES",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "527",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3142902",
        "denominacao": "RECONDICIONAMENTO DE BATERIAS E ACUMULADORES PARA VEÍCULOS",
        "descricao": "SERVIÇOS DE RECARGA OU RECONDUÇÃO DE BATERIAS",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "526",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2229201",
        "denominacao": "SERVIÇOS DE ENCADERNAÇÃO E PLASTIFICAÇÃO",
        "descricao": "SERVIÇOS DE PLASTIFICAÇÃO",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "525",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2010902",
        "denominacao": "SERRARIAS SEM DESDOBRAMENTO DE MADEIRA",
        "descricao": "SERVIÇOS DE BENEFICIAMENTO DE MADEIRA",
        "item": "14",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "459",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3613702",
        "denominacao": "SERVIÇOS DE MONTAGEM DE MÓVEIS DE MATERIAIS DIVERSOS (EXCETO MADEIRA E METAL), PARA CONSUMIDOR FINAL",
        "descricao": "SERVIÇOS DE MONTAGEM OU DESMONTAGEM DE DIVISÓRIAS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "422",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4549704",
        "denominacao": "INSTALAÇÃO DE ANÚNCIOS",
        "descricao": "SERVIÇOS DE INSTALAÇÃO E MONTAGEM DE PLACAS DE PUBLICIDADE",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "424",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4549702",
        "denominacao": "INSTALAÇÃO DE EQUIPAMENTOS PARA ORIENTAÇÃO A NAVEGAÇÃO MARÍTIMA FLUVIAL E LACUSTRE",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE EQUIPAMENTOS PARA ORIENTAÇÃO À NAVEGAÇÃO MARÍTIMA FLUVIAL E LACUSTRE",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "423",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2929700",
        "denominacao": "FABRICAÇÃO DE OUTRAS MÁQUINAS E EQUIPAMENTOS DE USO GERAL - INCLUSIVE PEÇAS",
        "descricao": "SERVIÇOS DE INSTALAÇÃO MONTAGEM DE MÁQUINAS E EQUIPAMENTOS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "425",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2992099",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE OUTRAS MÁQUINAS E EQUIPAMENTOS DE USO GERAL",
        "descricao": "SERVIÇOS DE MONTAGEM INDUSTRIAL",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "427",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3531900",
        "denominacao": "CONSTRUÇÃO E MONTAGEM DE AERONAVES",
        "descricao": "SERVIÇOS DE MONTAGEM DE AERONAVES",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "429",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3611002",
        "denominacao": "SERVIÇOS DE MONTAGEM DE MÓVEIS DE MADEIRA PARA CONSUMIDOR FINAL",
        "descricao": "SERVIÇOS DE MONTAGEM OU DESMONTAGEM DE MÓVEIS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "431",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3613702",
        "denominacao": "SERVIÇOS DE MONTAGEM DE MÓVEIS DE MATERIAIS DIVERSOS (EXCETO MADEIRA E METAL), PARA CONSUMIDOR FINAL",
        "descricao": "SERVIÇOS DE MONTAGEM OU DESMONTAGEM DE MÓVEIS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "433",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5271001",
        "denominacao": "REPARAÇÃO E MANUTENÇÃO DE MÁQUINAS E DE APARELHOS ELETRODOMÉSTICOS - EXCETO APARELHOS TELEFÔNICOS",
        "descricao": "SERVIÇOS DE INSTALAÇÃO E MONTAGEM DE APARELHOS ELÉTRICOS E ELETRÔNICOS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "437",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5271001",
        "denominacao": "REPARAÇÃO E MANUTENÇÃO DE MÁQUINAS E DE APARELHOS ELETRODOMÉSTICOS - EXCETO APARELHOS TELEFÔNICOS",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE PEÇAS E ACESSÓRIOS ",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "436",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "4525003",
        "denominacao": "OBRAS DE MONTAGEM INDUSTRIAL",
        "descricao": "SERVIÇOS DE OBRAS DE MONTAGEM INDUSTRIAL",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "435",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3694399",
        "denominacao": "FABRICAÇÃO DE BRINQUEDOS E DE OUTROS JOGOS RECREATIVOS",
        "descricao": "SERVIÇOS DE MONTAGEM DE BRINQUEDOS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "434",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3612902",
        "denominacao": "SERVIÇOS DE MONTAGEM DE MÓVEIS DE METAL PARA CONSUMIDOR FINAL",
        "descricao": "SERVIÇOS DE MONTAGEM OU DESMONTAGEM DE MÓVEIS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "432",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3611002",
        "denominacao": "SERVIÇOS DE MONTAGEM DE MÓVEIS DE MADEIRA PARA CONSUMIDOR FINAL",
        "descricao": "SERVIÇOS DE INSTALAÇÃO DE MÓVEIS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "430",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3521100",
        "denominacao": "CONSTRUÇÃO E MONTAGEM DE LOCOMOTIVAS, VAGÕES E OUTROS MATERIAIS RODANTES",
        "descricao": "SERVIÇOS DE MONTAGEM DE LOCOMOTIVAS E VAGÕES",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "428",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2992099",
        "denominacao": "MANUTENÇÃO E REPARAÇÃO DE OUTRAS MÁQUINAS E EQUIPAMENTOS DE USO GERAL",
        "descricao": "SERVIÇOS DE MONTAGEM DE EQUIPAMENTOS",
        "item": "14",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "426",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9231204",
        "denominacao": "RESTAURAÇÃO DE OBRAS DE ARTE",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE MOLDURAS",
        "item": "14",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "438",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9231204",
        "denominacao": "RESTAURAÇÃO DE OBRAS DE ARTE",
        "descricao": "SERVIÇOS DE COLOCAÇÃO DE MOLDURAS (MOLDUREIRO)",
        "item": "14",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "439",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2229201",
        "denominacao": "SERVIÇOS DE ENCADERNAÇÃO E PLASTIFICAÇÃO",
        "descricao": "SERVIÇOS DE PLASTIFICAÇÃO DE DOCUMENTOS",
        "item": "14",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "440",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2229201",
        "denominacao": "SERVIÇOS DE ENCADERNAÇÃO E PLASTIFICAÇÃO",
        "descricao": "SERVIÇOS DE ENCADERNAÇÃO, GRAVAÇÃO E DOURAÇÃO",
        "item": "14",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "441",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "1812001",
        "denominacao": "CONFECÇÃO DE PEÇAS DE VESTUÁRIO - EXCETO ROUPAS ÍNTIMAS, BLUSAS, CAMISAS E SEMELHANTES E AS CONFECCIONADAS SOB MEDIDA.",
        "descricao": "SERVIÇOS DE CORTE E RECORTE DE PEÇAS DE VESTUÁRIO, SOB MEDIDA",
        "item": "14",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "442",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "1812002",
        "denominacao": "CONFECÇÃO, SOB MEDIDA, DE OUTRAS PEÇAS DO VESTUÁRIO - EXCETO ROUPAS ÍNTIMAS, BLUSAS, CAMISAS E SEMELHANTES.",
        "descricao": "SERVIÇOS DE ALFAIATARIA E COSTURA  ",
        "item": "14",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "445",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "1813902",
        "denominacao": "CONFECÇÃO, SOB MEDIDA, DE ROUPAS PROFISSIONAIS",
        "descricao": "SERVIÇOS DE ALFAIATARIA E COSTURA  ",
        "item": "14",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "444",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "1812002",
        "denominacao": "CONFECÇÃO, SOB MEDIDA, DE OUTRAS PEÇAS DO VESTUÁRIO - EXCETO ROUPAS ÍNTIMAS, BLUSAS, CAMISAS E SEMELHANTES.",
        "descricao": "SERVIÇOS DE ALFAIATARIA E COSTURA  ",
        "item": "14",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "443",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9301701",
        "denominacao": "LAVANDERIAS E TINTURARIAS",
        "descricao": "SERVIÇOS DE LAVANDERIA E TINTURARIA",
        "item": "14",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "446",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5279503",
        "denominacao": "CONSERTO E RESTAURAÇÃO DE ARTIGOS DE MADEIRA E DO MOBILIÁRIO",
        "descricao": "SERVIÇOS DE TAPEÇARIA E REFORMA DE ESTOFAMENTOS (ESTOFADOR)",
        "item": "14",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "447",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5279503",
        "denominacao": "CONSERTO E RESTAURAÇÃO DE ARTIGOS DE MADEIRA E DO MOBILIÁRIO",
        "descricao": "SERVIÇOS DE TAPEÇARIA E REFORMA DE ESTOFAMENTOS (IMPERMEABILIZADOR)",
        "item": "14",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "448",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5279503",
        "denominacao": "CONSERTO E RESTAURAÇÃO DE ARTIGOS DE MADEIRA E DO MOBILIÁRIO",
        "descricao": "SERVIÇOS DE TAPEÇARIA E REFORMA DE ESTOFAMENTOS",
        "item": "14",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "449",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5020201",
        "denominacao": "SERVIÇOS DE MANUTENÇÃO E REPARAÇÃO DE AUTOMÓVEIS",
        "descricao": "SERVIÇOS DE FUNELARIA E LANTERNAGEM ",
        "item": "14",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "450",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "2842800",
        "denominacao": "FABRICAÇÃO DE ARTIGOS DE SERRALHERIA - EXCETO ESQUADRIAS",
        "descricao": "SERVIÇOS DE SERRALHERIA",
        "item": "14",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "451",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3611002",
        "denominacao": "SERVIÇOS DE MONTAGEM DE MÓVEIS DE MADEIRA PARA CONSUMIDOR FINAL",
        "descricao": "SERVIÇOS DE CAPINTARIA",
        "item": "14",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "452",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE EDMINISTRAÇÃO DE CARTÃO DE CRÉDITO OU DÉBITO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "587",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "590",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "591",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "589",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "500",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "499",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EDMINISTRAÇÃO DE CARTÃO DE CRÉDITO OU DÉBITO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "498",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "497",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "496",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "495",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "494",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EDMINISTRAÇÃO DE CARTÃO DE CRÉDITO OU DÉBITO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "493",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "492",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "509",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "491",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "490",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "489",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE EDMINISTRAÇÃO DE CARTÃO DE CRÉDITO OU DÉBITO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "488",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "487",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "486",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "485",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "467",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "466",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE EDMINISTRAÇÃO DE CARTÃO DE CRÉDITO OU DÉBITO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "465",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "458",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "457",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "456",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTÃO DE CRÉDITO OU DÉBITO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "455",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "454",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "453",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "484",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS D EDMINISTRAÇÃO DE CARTÃO DE CRÉDITO OU DÉBITO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "483",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "482",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "481",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "480",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "479",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS D EDMINISTRAÇÃO DE CARTÃO DE CRÉDITO OU DÉBITO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "478",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "477",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "476",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CHEQUES PRÉ-DATADOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "475",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "474",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EDMINISTRAÇÃO DE CARTÃO DE CRÉDITO OU DÉBITO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "473",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "472",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE FUNDOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "471",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6559502",
        "denominacao": "ADMINISTRAÇÃO DE CARTÃO DE CRÉDITO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTÃO DE CRÉDITO OU DÉBITO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "470",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6559502",
        "denominacao": "ADMINISTRAÇÃO DE CARTÃO DE CRÉDITO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTÕES DE CRÉDITO OU DÉBITO",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "469",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6559501",
        "denominacao": "ADMINISTRAÇÃO DE CONSÓRCIOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONSÓRCIOS",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "468",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CARTEIRA DE CLIENTES",
        "item": "15",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "588",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "501",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "504",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "505",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "503",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "502",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "506",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "508",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "518",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "517",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "516",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "515",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "514",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "513",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "512",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "511",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "510",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE CONTAS ATIVAS E INATIVAS",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "519",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ABERTURA DE CONTAS EM GERAL",
        "item": "15",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "507",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "537",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "538",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "539",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "540",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "541",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "542",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "543",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "650",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "649",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "536",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "535",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "534",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "533",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "532",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "531",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "530",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "529",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "524",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "523",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "522",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "521",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "520",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "648",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "647",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E LOCAÇÃO DE COFRES",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "571",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS ELETRÔNCIOS",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "544",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TERMINAIS DE ATENDIMENTOS E DE BENS E EQUIPAMENTOS EM GERAL",
        "item": "15",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "545",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "546",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "547",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "548",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "549",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "550",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "551",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "552",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "553",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO OU EMISSÃO DE ATESTADOS",
        "item": "15",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "554",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "555",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "556",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "557",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCF",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "558",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "559",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "584",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "583",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE RENOVAÇÃO CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "582",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "581",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "580",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCF",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "579",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "578",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "577",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "576",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE RENOVAÇÃO CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "714",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "713",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "712",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "711",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "592",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE RENOVAÇÃO CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "586",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "585",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCF",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "575",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE RENOVAÇÃO DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "574",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "573",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "572",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "560",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE RENOVAÇÃO CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "561",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "562",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "563",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "564",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE RENOVAÇÃO CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "565",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "566",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE CADASTRO",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "567",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ELABORAÇÃO DE FICHA CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "568",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE RENOVAÇÃO CADASTRAL",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "569",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE INCLUSÃO OU EXCLUSÃO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCFOU OUTROS BANCOS CADASTRAIS",
        "item": "15",
        "subitem": "5",
        "aliquota": "5",
        "idcnae": "570",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "593",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "594",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "595",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "596",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "597",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "598",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "599",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "600",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "601",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "602",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "603",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "604",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "605",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "606",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "607",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "608",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "609",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "610",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "611",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "612",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "613",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "614",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "615",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "616",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "617",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "618",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "619",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "620",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "621",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "622",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "623",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "624",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "625",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "626",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "627",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "628",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "629",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "630",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "631",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "632",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "633",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "770",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "769",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "768",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "665",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "664",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "663",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "662",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "661",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "660",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "659",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "658",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "657",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "656",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "655",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "654",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "653",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "652",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "651",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "634",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "635",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "636",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "637",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "638",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE AGENCIAMENTO FIDUCIÁRIO OU DEPOSITÁRIO",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "639",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE BENS EM CUSTÓDIA",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "640",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "641",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE ABONO DE FIRMAS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "642",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "643",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA LICENCIAMENTO DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "644",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE COBRANÇA DE TAXA PARA TRANSFERÊNCIA DE VEÍCULOS",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "645",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE COMUNICAÇÃO COM OUTRA AGÊNCIA OU COM A ADMINISTRAÇÃO CENTRAL",
        "item": "15",
        "subitem": "6",
        "aliquota": "5",
        "idcnae": "646",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "666",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "668",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "670",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "695",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "694",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "693",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "692",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "691",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "690",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "689",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "688",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "687",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "704",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "703",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "702",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "701",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "700",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "699",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "698",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "697",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "696",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "686",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "685",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "684",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "683",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "682",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "681",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "680",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "679",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "678",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "677",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "676",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "675",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "674",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "673",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "672",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "671",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "710",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "709",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ACESSO A OUTRO BANCO E À REDE COMPARTILHADA",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "708",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "707",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ACESSO, MOVIMENTAÇÃO, ATENDIMENTO E CONSULTAS À CONTAS EM GERAL",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "706",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE EXTRATOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "705",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE SALDOS",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "669",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ACESSO A TERMINAIS DE ATENDIMENTO",
        "item": "15",
        "subitem": "7",
        "aliquota": "5",
        "idcnae": "667",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "828",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "829",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "830",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "716",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "718",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "720",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "722",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "724",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "733",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "732",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "731",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "730",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "729",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "728",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "727",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "726",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "725",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "742",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "741",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "740",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "739",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "738",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "737",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "736",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "735",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "734",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "747",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, CONCESSÃO, ALTERAÇÃO OU CONTRATAÇÃO DE AVAL, FIANÇA, ANUÊNCIA OU CONGÊNERES",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "746",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "745",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, CESSÃO, SUBSTITUIÇÃO, CANCELAMENTO E REGISTRO DE CONTRATO DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "744",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "743",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "723",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "721",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "719",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE ESTUDO, ANÁLISE E AVALIAÇÃO DE OPERAÇÕES DE CRÉDITO",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "717",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS RELATIVOS À ABERTURA DE CRÉDITOS",
        "item": "15",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "715",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ARRENDAMENTO MERCANTIL (LEASING)",
        "item": "15",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "748",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE ARRENDAMENTO MERCANTIL (LEASING)",
        "item": "15",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "750",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE ARRENDAMENTO MERCANTIL (LEASING)",
        "item": "15",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "752",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE ARRENDAMENTO MERCANTIL (LEASING)",
        "item": "15",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "754",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ARRENDAMENTO MERCANTIL (LEASING)",
        "item": "15",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "756",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6540400",
        "denominacao": "ARRENDAMENTO MERCANTIL",
        "descricao": "SERVIÇOS DE ARRENDAMENTO MERCANTIL (LEASING)",
        "item": "15",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "757",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ARRENDAMENTO MERCANTIL (LEASING)",
        "item": "15",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "755",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE ARRENDAMENTO MERCANTIL (LEASING)",
        "item": "15",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "753",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ARRENDAMENTO MERCANTIL (LEASING)",
        "item": "15",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "751",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE ARRENDAMENTO MERCANTIL (LEASING)",
        "item": "15",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "749",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "813",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "814",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "815",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "901",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "900",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "899",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "890",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "955",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "954",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "953",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "834",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "833",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "832",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "831",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "816",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "817",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "818",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "819",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "820",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "766",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "765",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "764",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "763",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "762",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "761",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "760",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "759",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "758",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "786",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "785",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "784",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "783",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "782",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "781",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "780",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "779",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "778",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "812",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "811",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "810",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "809",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "808",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "807",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "806",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "805",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "804",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "803",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "802",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "801",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "800",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "799",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "798",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "797",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "796",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "795",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "794",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "793",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "792",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "791",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "790",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "789",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "788",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "787",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "777",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "776",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "775",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "774",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "773",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "772",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "771",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "767",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO DE IMPRESSOS E DOCUMENTOS EM GERAL",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "837",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FICHAS DE COMPENSAÇÃO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "836",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "835",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TÍTULOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "821",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CONTAS OU CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "822",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE CÂMBIO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "823",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO DE TRIBUTOS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "824",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA, RECEBIMENTO OU PAGAMENTO POR CONTA DE TERCEIROS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "825",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE POSIÇÃO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "826",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO DE CARNÊS",
        "item": "15",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "827",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "838",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "839",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "840",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "841",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "842",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "843",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "882",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "881",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "880",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "879",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "878",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "877",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "876",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "875",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "874",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "873",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "844",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "845",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "846",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "847",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "848",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "849",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "850",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "851",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "852",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "853",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "854",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "855",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "856",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "857",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "858",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "859",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "860",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "861",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "862",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "863",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "864",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "865",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "866",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "867",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "868",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "869",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE PROTESTO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "870",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE MANUTENÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "871",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE REAPRESENTAÇÃO DE TÍTULOS",
        "item": "15",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "872",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "883",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "888",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "891",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6719901",
        "denominacao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "descricao": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "893",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "892",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "889",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "887",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "884",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "885",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE CUSTÓDIA",
        "item": "15",
        "subitem": "12",
        "aliquota": "5",
        "idcnae": "886",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "930",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "931",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "932",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "1008",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ENVIO E RECEBIMENTO DE MENSAGENS RELACIONADAS À OPERAÇÕES DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "1007",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, TRANSFERÊNCIA E CANCELAMNTO DE CARTA DE CRÉDITO DE IMPORTAÇÃO E EXPORTAÇÃO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "1006",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ENVIO E RECEBIMENTO DE MENSAGENS RELACIONADAS À OPERAÇÕES DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "956",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "933",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, TRANSFERÊNCIA E CANCELAMNTO DE CARTA DE CRÉDITO DE IMPORTAÇÃO E EXPORTAÇÃO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "934",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE ENVIO E RECEBIMENTO DE MENSAGENS RELACIONADAS À OPERAÇÕES DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "935",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "936",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "937",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "938",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "939",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "940",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE ENVIO E RECEBIMENTO DE MENSAGENS RELACIONADAS À OPERAÇÕES DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "914",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, TRANSFERÊNCIA E CANCELAMNTO DE CARTA DE CRÉDITO DE IMPORTAÇÃO, EXPORTAÇÃO E GARANTIAS RECEBIDAS",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "913",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "912",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "911",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "910",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "909",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "908",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE ENVIO E RECEBIMENTO DE MENSAGENS RELACIONADAS À OPERAÇÕES DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "907",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "905",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, TRANSFERÊNCIA E CANCELAMNTO DE CARTA DE CRÉDITO DE IMPORTAÇÃO, EXPORTAÇÃO E GARANTIAS RECEBIDAS",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "1299",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "1298",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "1297",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "904",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "903",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "902",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "898",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "897",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "896",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "895",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "894",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE FORNECIMENTO, TRANSFERÊNCIA E CANCELAMNTO DE CARTA DE CRÉDITO DE IMPORTAÇÃO, EXPORTAÇÃO E GARANTIAS RECEBIDAS",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "941",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE ENVIO E RECEBIMENTO DE MENSAGENS RELACIONADAS À OPERAÇÕES DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "942",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "943",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "944",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "945",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "946",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "947",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, TRANSFERÊNCIA E CANCELAMNTO DE CARTA DE CRÉDITO DE IMPORTAÇÃO, EXPORTAÇÃO E GARANTIAS RECEBIDAS",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "948",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "922",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ENVIO E RECEBIMENTO DE MENSAGENS RELACIONADAS À OPERAÇÕES DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "921",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, TRANSFERÊNCIA E CANCELAMNTO DE CARTA DE CRÉDITO DE IMPORTAÇÃO, EXPORTAÇÃO E GARANTIAS RECEBIDAS",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "920",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "919",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "918",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "917",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "916",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "915",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, TRANSFERÊNCIA E CANCELAMNTO DE CARTA DE CRÉDITO DE IMPORTAÇÃO, EXPORTAÇÃO E GARANTIAS RECEBIDAS",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "906",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "929",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE ENVIO E RECEBIMENTO DE MENSAGENS RELACIONADAS À OPERAÇÕES DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "928",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE FORNECIMENTO, TRANSFERÊNCIA E CANCELAMNTO DE CARTA DE CRÉDITO DE IMPORTAÇÃO E EXPORTAÇÃO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "927",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE EMISSÃO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "926",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE COBRANÇA OU DEPÓSITOS NO EXTERIOR",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "925",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "924",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "923",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ENVIO E RECEBIMENTO DE MENSAGENS RELACIONADAS À OPERAÇÕES DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "949",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE OPERAÇÃO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "950",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EDIÇÃO, ALTERAÇÃO, PRORROGAÇÃO, CANCELAMENTO E BAIXA DE CONTRATO DE CÂMBIO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "951",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO DE REGISTRO DE EXPORTAÇÃO OU CRÉDITO",
        "item": "15",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "952",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "957",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "958",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "959",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "960",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "961",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "962",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "963",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "964",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "965",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "966",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "967",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "968",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "969",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "970",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "971",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "972",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "973",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "974",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "975",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "976",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "977",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "978",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "979",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "980",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "981",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "982",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "983",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "984",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "985",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "986",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "987",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "988",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO MAGNÉTICO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "989",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO CRÉDITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "990",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO DÉBITO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "991",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÃO SALÁRIO",
        "item": "15",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "992",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "993",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "994",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "995",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "996",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "997",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "998",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "999",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "1000",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "1251",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "1250",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "1249",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "1248",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "1247",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "1246",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "1245",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "1244",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "1243",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "1242",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "1241",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "1240",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "1239",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "1238",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "1001",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "1002",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE TÍTULOS QUAISQUER",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "1003",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS RELACIONADOS A DEPÓSITOS",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "1004",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE COMPENSAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "1005",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "1252",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "1257",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "1259",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "1268",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "1267",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "1266",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "1265",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "1264",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "1263",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "1262",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "1261",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "1260",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "1269",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "1258",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "1256",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "1253",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDEM DE CRÉDITO E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "1254",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS RELACIONADOS À TRANSFERÊNCIA DE VALORES, DADOS, FUNDOS, PAGAMENTOS E SIMILARES",
        "item": "15",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "1255",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1180",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1185",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1187",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1278",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1277",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1276",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1275",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1274",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE CANCELAMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1273",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1272",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1271",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1270",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1296",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1295",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1294",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE CANCELAMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1293",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1292",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1291",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1290",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE CANCELAMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1289",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1288",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1287",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1286",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1285",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1284",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1283",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1282",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1281",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1280",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1279",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1188",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1186",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE CANCELAMENTOE OPOSIÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1184",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO E FORNECIMENTO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1181",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE DEVOLUÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1182",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE SUSTAÇÃO DE CHEQUES",
        "item": "15",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1183",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1127",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1195",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1229",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1228",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1227",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1226",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1225",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6531500",
        "denominacao": "BANCOS MÚLTIPLOS (SEM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1224",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1223",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1222",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1221",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1237",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1236",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1235",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6533100",
        "denominacao": "BANCOS DE DESENVOLVIMENTO",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1234",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1233",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1232",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1231",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6532300",
        "denominacao": "BANCOS DE INVESTIMENTO",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1230",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1220",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6523400",
        "denominacao": "CAIXAS ECONÔMICAS",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1219",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1218",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1217",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1216",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1215",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6522600",
        "denominacao": "BANCOS MÚLTIPLOS (COM CARTEIRA COMERCIAL)",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1214",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6534001",
        "denominacao": "SOCIEDADES DE CRÉDITO IMOBILIÁRIO",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1204",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1203",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1202",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1201",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1200",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524203",
        "denominacao": "COOPERATIVAS DE CRÉDITO RURAL",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1199",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1198",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1197",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1196",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1213",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1212",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1211",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1210",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6521800",
        "denominacao": "BANCOS COMERCIAIS",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1209",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6534001",
        "denominacao": "SOCIEDADES DE CRÉDITO IMOBILIÁRIO",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1208",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6534001",
        "denominacao": "SOCIEDADES DE CRÉDITO IMOBILIÁRIO",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1207",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6534001",
        "denominacao": "SOCIEDADES DE CRÉDITO IMOBILIÁRIO",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1206",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6534001",
        "denominacao": "SOCIEDADES DE CRÉDITO IMOBILIÁRIO",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1205",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524202",
        "denominacao": "COOPERATIVAS DE CRÉDITO MÚTUO",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1194",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE AVALIAÇÃO E VISTORIA DE IMÓVEIS OU OBRA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1189",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE ANÁLISE TÉCNICA E JURÍDICA",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1191",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO, REEMISSÃO, ALTERAÇÃO, TRANSFERÊNCIA E RENEGOCIÇÃO DE CONTRATO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1192",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS DE EMISSÃO E REEMISSÃO DE TERMO DE QUITAÇÃO E DEMAIS SERVIÇOS RELACIOADOS A CRÉDITO IMOBILIÁRIO",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1193",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6524201",
        "denominacao": "BANCOS COOPERATIVOS",
        "descricao": "SERVIÇOS RELACIONADOS À CRÉDITOS IMOBILIÁRIOS",
        "item": "15",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1190",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5020206",
        "denominacao": "SERVIÇOS DE REBOQUE DE VEÍCULOS",
        "descricao": "SERVIÇOS DE REBOQUE DE VEÍCULOS",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1128",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6025901",
        "denominacao": "SERVIÇOS DE TÁXIS",
        "descricao": "SERVIÇO DE TRANSPORTE DE NATUREZA MUNICIPAL (SERVIÇO DE TÁXI)",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1134",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6028301",
        "denominacao": "TRANSPORTE RODOVIÁRIO DE MUDANÇAS",
        "descricao": "SERVIÇO DE TRANSPORTE DE MUDANÇAS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1143",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6030500",
        "denominacao": "TRANSPORTE DUTOVIÁRIO",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1142",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6029100",
        "denominacao": "TRANSPORTE REGULAR EM BONDES, FUNICULARES, TELEFÉRICOS OU TRENS PRÓPRIOS PARA EXPLORAÇÃO DE PONTOS TURÍSTICOS",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1141",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6027500",
        "denominacao": "TRANSPORTE RODOVIÁRIO DE PRODUTOS PERIGOSOS",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1140",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6026701",
        "denominacao": "TRANSPORTE RODOVIÁRIO DE CARGAS EM GERAL, MUNICIPAL",
        "descricao": "SERVIÇOS DE TRANSPORTE DE MATERIAIS DE CONST. E ENTULHOS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1139",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6026701",
        "denominacao": "TRANSPORTE RODOVIÁRIO DE CARGAS EM GERAL, MUNICIPAL",
        "descricao": "SERVIÇO DE TRANSPORTE DE CARGAS E ENCOMENDAS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1138",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6025906",
        "denominacao": "TRANSPORTE ESCOLAR MUNICIPAL",
        "descricao": "SERVIÇO DE TRANSPORTE ESCOLAR DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1137",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6025904",
        "denominacao": "ORGANIZAÇÃO DE EXCURSÕES EM VEÍCULOS RODOVIÁRIOS PRÓPRIOS MUNICIPAL",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1136",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6025902",
        "denominacao": "LOCAÇÃO DE VEÍCULOS RODOVIÁRIOS DE PASSAGEIROS COM MOTORISTA, MUNICIPAL",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1135",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6220001",
        "denominacao": "SERVIÇOS DE TÁXIS AÉREOS E LOCAÇÃO DE AERONAVES COM TRIPULAÇÃO",
        "descricao": "SERVIÇO DE TRANSPORTE AÉREO DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1152",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7460804",
        "denominacao": "SERVIÇOS DE TRANSPORTE DE VALORES",
        "descricao": "SERVIÇOS DE TRANSPORTE DE VALORES, MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1151",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6412202",
        "denominacao": "SERVIÇOS DE ENTREGA RÁPIDA",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1150",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6412201",
        "denominacao": "SERVIÇOS DE MALOTE NÃO REALIZADOS PELO CORREIO NACIONAL",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1149",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6123901",
        "denominacao": "TRANSPORTE AQUAVIÁRIO MUNICIPAL, URBANO",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1148",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6122001",
        "denominacao": "TRANSPORTE POR NAVEGAÇÃO INTERIOR DE CARGA, MUNICIPAL, NÃO URBANO",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1147",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6121201",
        "denominacao": "TRANSPORTE POR NAVEGAÇÃO INTERIOR DE PASSAGEIROS, MUNICIPAL, NÃO URBANO",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1146",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6112300",
        "denominacao": "TRANSPORTE MARÍTIMO DE LONGO CURSO",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1145",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6111500",
        "denominacao": "TRANSPORTE MARÍTIMO DE CABOTAGEM",
        "descricao": "SERVIÇOS DE TRANSPORTE DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1144",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9262299",
        "denominacao": "OUTRAS ATIVIDADES RELACIONADAS AO LAZER",
        "descricao": "SERVIÇOS DE PASSEIOS MARÍTIMOS",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1153",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6024001",
        "denominacao": "TRANSPORTE RODOVIÁRIO DE PASSAGEIROS, REGULAR, MUNICIPAL NÃO URBANO",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1133",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6021600",
        "denominacao": "TRANSPORTE FERROVIÁRIO DE PASSAGEIROS MUNICIPAL E INTERMUNICIPAL METROPOLITANO",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1129",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6022400",
        "denominacao": "TRANSPORTE METROVIÁRIO",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1130",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6023201",
        "denominacao": "TRANSPORTE RODOVIÁRIO DE PASSAGEIROS, REGULAR, MUNICIPAL URBANO",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL (TARIFA DO STCM)",
        "item": "16",
        "subitem": "1",
        "aliquota": ",01",
        "idcnae": "1131",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6023201",
        "denominacao": "TRANSPORTE RODOVIÁRIO DE PASSAGEIROS, REGULAR, MUNICIPAL URBANO",
        "descricao": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS DE NATUREZA MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "1132",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499310",
        "denominacao": "SERVIÇO DE MEDIÇÃO DE CONSUMO DE ENERGIA ELÉTRICA, GÁS E ÁGUA.",
        "descricao": "SERVIÇOS DE MEDIÇÃO DE CONSUMO DE ENERGIA ELÉTRICA, GÁS E ÁGUA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1066",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6340104",
        "denominacao": "ORGANIZAÇÃO LOGÍSTICA DO TRANSPORTE DE CARGA - OPERADOR DE TRANSPORTE MULTIMODAL",
        "descricao": "SERVIÇOS DE ORGANIZAÇÃO LOGÍSTICA DE TRANSPORTE DE CARGAS",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1154",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499399",
        "denominacao": "OUTROS SERVIÇOS PRESTADOS PRINCIPALMENTE ÀS EMPRESAS",
        "descricao": "SERVIÇOS DE ASSESSORIA E CONSULTORIA",
        "item": "17",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1158",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499301",
        "denominacao": "SERVIÇOS DE TRADUÇÃO, INTERPRETAÇÃO E SIMILARES",
        "descricao": "SERVIÇOS DE REDAÇÃO, REVISÃO E EDIÇÃO",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1159",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499305",
        "denominacao": "SERVIÇOS ADMINISTRATIVOS PARA TERCEIROS",
        "descricao": "SERVIÇOS DE SECRETARIA",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1161",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499399",
        "denominacao": "OUTROS SERVIÇOS PRESTADOS PRINCIPALMENTE ÀS EMPRESAS",
        "descricao": "SERVIÇOS DE APOIO E INFRAESTRUTURA ADMINISTRATIVA",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1163",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499399",
        "denominacao": "OUTROS SERVIÇOS PRESTADOS PRINCIPALMENTE ÀS EMPRESAS",
        "descricao": "SERVIÇOS DE EXPEDIENTE",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1165",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499399",
        "denominacao": "OUTROS SERVIÇOS PRESTADOS PRINCIPALMENTE ÀS EMPRESAS",
        "descricao": "SERVIÇOS DE RESPOSTAS AUDÍVEIS",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1167",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499399",
        "denominacao": "OUTROS SERVIÇOS PRESTADOS PRINCIPALMENTE ÀS EMPRESAS",
        "descricao": "SERVIÇOS ESTENOGRAFIA",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1166",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499399",
        "denominacao": "OUTROS SERVIÇOS PRESTADOS PRINCIPALMENTE ÀS EMPRESAS",
        "descricao": "SERVIÇOS DE DATILOGRAFIA",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1164",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7230300",
        "denominacao": "PROCESSAMENTO DE DADOS",
        "descricao": "SERVIÇOS DE DIGITAÇÃO",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1162",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499301",
        "denominacao": "SERVIÇOS DE TRADUÇÃO, INTERPRETAÇÃO E SIMILARES",
        "descricao": "SERVIÇOS DE TRADUÇÕES E INTERPRETAÇÕES",
        "item": "17",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1160",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7416002",
        "denominacao": "ATIVIDADES DE ASSESSORIA EM GESTÃO EMPRESARIAL",
        "descricao": "SERVIÇOS DE PLANEJAMENTO, COORDENAÇÃO, PROGRAMAÇÃO OU ORGANIZAÇÃO TÉCNICA, FINANCEIRA OU ADMINISTRATIVA",
        "item": "17",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1168",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7450001",
        "denominacao": "SELEÇÃO E AGENCIAMENTO DE MÃO-DE-OBRA",
        "descricao": "SERVIÇOS DE RECRUTAMENTO, AGENCIAMENTO, SELEÇÃO E COLOCAÇÃO DE MÃO-DE-OBRA",
        "item": "17",
        "subitem": "4",
        "aliquota": "2,5",
        "idcnae": "1169",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7450002",
        "denominacao": "LOCAÇÃO DE MÃO-DE-OBRA",
        "descricao": "SERVIÇOS DE FORNECIMENTO DE MÃO-DE-OBRA",
        "item": "17",
        "subitem": "5",
        "aliquota": "2,5",
        "idcnae": "1170",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6593501",
        "denominacao": "LICENCIAMENTO, COMPRA E VENDA E LEASING DE ATIVOS INTANGÍVEIS NÃO FINANCEIROS - EXCETO DIREITOS AUTORAIS",
        "descricao": "SERVIÇOS DE FRANQUIAS (FRANCHISING)",
        "item": "17",
        "subitem": "8",
        "aliquota": "5",
        "idcnae": "1172",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7416002",
        "denominacao": "ATIVIDADES DE ASSESSORIA EM GESTÃO EMPRESARIAL",
        "descricao": "SERVIÇOS DE PERÍCIAS, LAUDOS, EXAMES TÉCNICOS E ANÁLISES TÉCNICAS",
        "item": "17",
        "subitem": "9",
        "aliquota": "5",
        "idcnae": "1173",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499307",
        "denominacao": "SERVIÇOS DE ORGANIZAÇÃO DE FESTAS E EVENTOS - EXCETO CULTURAIS E DESPORTIVOS",
        "descricao": "SERVIÇOS DE PLANEJAMENTO, ORGANIZAÇÃO E ADMINISTRAÇÃO DE FEIRAS, EXPOSIÇÕES E CONGRESSOS",
        "item": "17",
        "subitem": "10",
        "aliquota": "5",
        "idcnae": "1174",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5524702",
        "denominacao": "SERVIÇOS DE BUFFET",
        "descricao": "SERVIÇOS DE BUFÊ",
        "item": "17",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "1175",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499307",
        "denominacao": "SERVIÇOS DE ORGANIZAÇÃO DE FESTAS E EVENTOS - EXCETO CULTURAIS E DESPORTIVOS",
        "descricao": "SERVIÇOS DE ORGANIZAÇÃO DE FESTAS E RECEPÇÕES",
        "item": "17",
        "subitem": "11",
        "aliquota": "5",
        "idcnae": "1176",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6593502",
        "denominacao": "GESTÃO DE DIREITOS AUTORAIS",
        "descricao": "SERVIÇOS DE GESTÃO DE DIREITOS AUTORAIS",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "1069",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6599407",
        "denominacao": "GESTÃO DE FUNDOS PARA FINS DIVERSOS - EXCETO INVESTIMENTOS",
        "descricao": "SERVIÇOS DE GESTÃO DE FUNDOS",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "1070",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6593501",
        "denominacao": "LICENCIAMENTO, COMPRA E VENDA E LEASING DE ATIVOS INTANGÍVEIS NÃO FINANCEIROS - EXCETO DIREITOS AUTORAIS",
        "descricao": "SERVIÇOS DE LICENCIAMENTO, COMPRA E VENDA E LEASING DE ATIVOS INTANGÍVEIS NÃO FINANCEIROS",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "1179",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9303301",
        "denominacao": "GESTÃO E MANUTENÇÃO DE CEMITÉRIOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "1178",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9261403",
        "denominacao": "GESTÃO DE INSTALAÇÕES DESPORTIVAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "1177",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9000003",
        "denominacao": "GESTÃO DE REDES DE ESGOTO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "1077",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9000002",
        "denominacao": "GESTÃO DE ATERROS SANITÁRIOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "1076",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7040800",
        "denominacao": "CONDOMÍNIOS DE PRÉDIOS RESIDENCIAIS OU NÃO",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO DE CONDOMÍNIOS",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "1075",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7032700",
        "denominacao": "ADMINISTRAÇÃO DE IMÓVEIS POR CONTA DE TERCEIROS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "1074",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6711304",
        "denominacao": "ADMINISTRAÇÃO DE MERCADOS DE BALCÃO ORGANIZADOS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "1073",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6711303",
        "denominacao": "BOLSA DE MERCADORIAS E FUTUROS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "1072",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6711302",
        "denominacao": "BOLSA DE MERCADORIAS",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO",
        "item": "17",
        "subitem": "12",
        "aliquota": "2,5",
        "idcnae": "1071",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499304",
        "denominacao": "SERVIÇOS DE LEILOEIROS",
        "descricao": "SERVIÇOS DE REALIZAÇÕES DE LEILÕES",
        "item": "17",
        "subitem": "13",
        "aliquota": "5",
        "idcnae": "1078",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7411001",
        "denominacao": "SERVIÇOS ADVOCATÍCIOS",
        "descricao": "SERVIÇOS ADVOCATÍCIOS",
        "item": "17",
        "subitem": "14",
        "aliquota": "5",
        "idcnae": "1079",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7411003",
        "denominacao": "ATIVIDADES AUXILIARES DA JUSTIÇA",
        "descricao": "SERVIÇOS DE ARBITRAGEM",
        "item": "17",
        "subitem": "15",
        "aliquota": "5",
        "idcnae": "1080",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7412802",
        "denominacao": "ATIVIDADES DE AUDITORIA CONTÁBIL",
        "descricao": "SERVIÇOS DE AUDITORIA ",
        "item": "17",
        "subitem": "16",
        "aliquota": "5",
        "idcnae": "1081",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7416002",
        "denominacao": "ATIVIDADES DE ASSESSORIA EM GESTÃO EMPRESARIAL",
        "descricao": "SERVIÇOS DE ANÁLISE DE ORGANIZAÇÃO E MÉTODOS",
        "item": "17",
        "subitem": "17",
        "aliquota": "5",
        "idcnae": "1082",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6720203",
        "denominacao": "AUDITORIA E CONSULTORIA ATUARIAL",
        "descricao": "SERVIÇOS ATUARIAIS E CÁLCULOS TÉCNICOS DE QUALQUER NATUREZA",
        "item": "17",
        "subitem": "18",
        "aliquota": "5",
        "idcnae": "1083",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7416002",
        "denominacao": "ATIVIDADES DE ASSESSORIA EM GESTÃO EMPRESARIAL",
        "descricao": "SERVIÇOS DE CONSULTORIA E ASSESSORIA ECONÔMICA OU FINANCEIRA",
        "item": "17",
        "subitem": "20",
        "aliquota": "5",
        "idcnae": "1085",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7413600",
        "denominacao": "PESQUISAS DE MERCADO E DE OPINIÃO PÚBLICA",
        "descricao": "SERVIÇOS DE PESQUISA DE MERCADO E OPINIÃO",
        "item": "17",
        "subitem": "21",
        "aliquota": "5",
        "idcnae": "1086",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7413600",
        "denominacao": "PESQUISAS DE MERCADO E DE OPINIÃO PÚBLICA",
        "descricao": "SERVIÇOS DE ESTATÍSTICA  ",
        "item": "17",
        "subitem": "21",
        "aliquota": "5",
        "idcnae": "1087",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499308",
        "denominacao": "SERVIÇOS DE COBRANÇA E DE INFORMAÇÕES CADASTRAIS",
        "descricao": "SERVIÇOS DE  COBRANÇA",
        "item": "17",
        "subitem": "22",
        "aliquota": "5",
        "idcnae": "1088",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6559503",
        "denominacao": "FACTORING",
        "descricao": "SERVIÇOS DE FACTORING",
        "item": "17",
        "subitem": "23",
        "aliquota": "5",
        "idcnae": "1089",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8099305",
        "denominacao": "TREINAMENTO EM DESENVOLVIMENTO PROFISSIONAL E GERENCIAL.",
        "descricao": "SERVIÇOS DE APRESENTAÇÃO DE PALESTRAS, CONFERÊNCIAS E SEMINÁRIOS",
        "item": "17",
        "subitem": "24",
        "aliquota": "5",
        "idcnae": "1090",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6720201",
        "denominacao": "CORRETORES E AGENTES DE SEGUROS E DE PLANOS DE PREVIDÊNCIA COMPLEMENTAR E DE SAÚDE",
        "descricao": "SERVIÇOS DE REGULAÇÃO DE SINISTROS VINCULADOS A CONTRATOS DE SEGUROS",
        "item": "18",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1091",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6720202",
        "denominacao": "PERITOS E AVALIADORES DE SEGUROS",
        "descricao": "SERVIÇOS DE INSPEÇÃO E AVALIAÇÃO DE RISCOS PARA COBERTURA DE CONTRATOS DE SEGUROS",
        "item": "18",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1092",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6720202",
        "denominacao": "PERITOS E AVALIADORES DE SEGUROS",
        "descricao": "SERVIÇOS DE PERÍCIAS PARA COBERTURA DE CONTRATOS DE SEGUROS",
        "item": "18",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1094",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6720202",
        "denominacao": "PERITOS E AVALIADORES DE SEGUROS",
        "descricao": "SERVIÇOS DE REGULAÇÃO DE SINISTROS VINCULADOS À CONTRATOS DE SEGUROS  ",
        "item": "18",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1093",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6720299",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS SEGUROS E DA PREVIDÊNCIA COMPLEMENTAR, NÃO ESPECIFICADAS ANTERIORMENTE",
        "descricao": "SERVIÇOS DE PREVENÇÃO E GERÊNCIA DE RISCOS SEGURÁVEIS",
        "item": "18",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1095",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9262202",
        "denominacao": "ATIVIDADES DAS CONCESSIONÁRIAS E DA VENDA DE BILHETES DE LOTERIAS",
        "descricao": "SERVIÇOS DE DISTRIBUIÇÃO DE BILHETES E DEMAIS PRODUTOS DE LOTERIA, BINGOS, PULES OU CUPONS DE APOSTAS, CARTÕES, SORTEIOS, APOSTAS E PRÊMIOS",
        "item": "19",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1096",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9262202",
        "denominacao": "ATIVIDADES DAS CONCESSIONÁRIAS E DA VENDA DE BILHETES DE LOTERIAS",
        "descricao": "SERVIÇOS DE VENDA DE BILHETES E DEMAIS PRODUTOS DE LOTERIA, BINGOS, PULES OU CUPONS DE APOSTAS, CARTÕES, SORTEIO, APOSTAS E PRÊMIOS",
        "item": "19",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1097",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6321501",
        "denominacao": "TERMINAIS RODOVIÁRIOS E FERROVIÁRIOS",
        "descricao": "SERVIÇOS FERROPORTUÁRIOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1098",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6322302",
        "denominacao": "REBOCAGEM EM ESTUÁRIOS E PORTOS",
        "descricao": "SERVIÇOS DE REBOCADOR ESCOTEIRO",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1102",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6322302",
        "denominacao": "REBOCAGEM EM ESTUÁRIOS E PORTOS",
        "descricao": "SERVIÇOS DE REBOQUE DE EMBARCAÇÕES",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1101",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6322301",
        "denominacao": "OPERAÇÃO DE PORTOS E TERMINAIS",
        "descricao": "SERVIOS DE UTILIZAÇÃO DE PORTOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1100",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6322301",
        "denominacao": "OPERAÇÃO DE PORTOS E TERMINAIS",
        "descricao": "SERVIÇOS PORTUÁRIOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1099",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6322399",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIÁRIOS",
        "descricao": "SERVIÇOS DE APOIO MARÍTIMO",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1103",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6322399",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIÁRIOS",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO AO LARGO",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1113",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6322399",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIÁRIOS",
        "descricao": "SERVIÇOS DE PRATICAGEM",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1112",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6322399",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIÁRIOS",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO DE PASSAGEIROS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1111",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6322399",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIÁRIOS",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO DE MERCADORIAS EM PORTOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1110",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6322399",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIÁRIOS",
        "descricao": "SERVIÇOS DE LOGISTICA E CONFERÊNCIAS EM PORTOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1109",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6322399",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIÁRIOS",
        "descricao": "SERVIÇOS DE ESTIVA",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1108",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6322399",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIÁRIOS",
        "descricao": "SERVIÇOS DE CAPATAZIA",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1107",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6322399",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIÁRIOS",
        "descricao": "SERVIÇOS DE ATRACAÇÃO E DESATRACAÇÃO",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1106",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6322399",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIÁRIOS",
        "descricao": "SERVIÇOS DE ARMAZENAGEM EM PORTOS",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1105",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6322399",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIÁRIOS",
        "descricao": "SERVIÇOS DE ARMADORES",
        "item": "20",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1104",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6323101",
        "denominacao": "OPERAÇÃO DE AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS AEROPORTUÁRIOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1114",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6323101",
        "denominacao": "OPERAÇÃO DE AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE ADMINISTRAÇÃO AEROPORTUÁRIA",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1115",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6323199",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES AÉREOS.",
        "descricao": "SERVIÇOS DE APOIO AEROPORTUÁRIOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1117",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6323199",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES AÉREOS.",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO DE AERONAVES",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1120",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6323199",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES AÉREOS.",
        "descricao": "SERVIÇOS DE LOGISTICA E CONFERÊNCIAS EM AEROPORTOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1119",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6323199",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES AÉREOS.",
        "descricao": "SERVIÇOS DE ARMAZENAGEM EM AEROPORTOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1118",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6323101",
        "denominacao": "OPERAÇÃO DE AEROPORTOS E CAMPOS DE ATERRISSAGEM",
        "descricao": "SERVIÇOS DE UTILIZAÇÃO DE AEROPORTOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1116",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6323199",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES AÉREOS.",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO DE MERCADORIAS EM AEROPORTOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1121",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6323199",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES AÉREOS.",
        "descricao": "SERVIÇOS DE CAPATAZIA",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1123",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6323199",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES AÉREOS.",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO DE PASSAGEIROS EM AEROPORTOS",
        "item": "20",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1122",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6321599",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES TERRESTRES",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO DE MERCADORIAS EM  TERMINAIS RODOVIÁRIOS, FERROVIÁRIOS E METROVIÁRIOS",
        "item": "20",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1059",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6321599",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES TERRESTRES",
        "descricao": "SERVIÇOS DE TERMINAIS METROVIÁRIOS",
        "item": "20",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1060",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6321501",
        "denominacao": "TERMINAIS RODOVIÁRIOS E FERROVIÁRIOS",
        "descricao": "SERVIÇOS DE TERMINAIS FERROVIÁRIOS",
        "item": "20",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1124",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6321501",
        "denominacao": "TERMINAIS RODOVIÁRIOS E FERROVIÁRIOS",
        "descricao": "SERVIÇOS DE TERMINAIS RODOVIÁRIOS",
        "item": "20",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1125",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6321599",
        "denominacao": "OUTRAS ATIVIDADES AUXILIARES DOS TRANSPORTES TERRESTRES",
        "descricao": "SERVIÇOS DE MOVIMENTAÇÃO DE PASSAGEIROS EM  TERMINAIS RODOVIÁRIOS, FERROVIÁRIOS E METROVIÁRIOS",
        "item": "20",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1126",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7411002",
        "denominacao": "ATIVIDADES CARTORIAIS",
        "descricao": "SERVIÇOS DE REGISTROS PÚBLICOS, CARTORIAIS E NOTORIAIS",
        "item": "21",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1061",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6321502",
        "denominacao": "OPERAÇÃO DE PONTES, TÚNEIS , RODOVIAS E SERVIÇOS RELACIONADOS.",
        "descricao": "SERVIÇOS DE EXPLORAÇÃO DE RODOVIA MEDIANTE COBRANÇA DE PREÇO OU PEDÁGIO DOS USUÁRIOS",
        "item": "22",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1062",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7440399",
        "denominacao": "OUTROS SERVIÇOS DE PUBLICIDADE",
        "descricao": "SERVIÇOS DE PROGRAMAÇÃO E COMUNICAÇÃO VISUAL",
        "item": "23",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1009",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420905",
        "denominacao": "SERVIÇOS DE DESENHO TÉCNICO ESPECIALIZADO",
        "descricao": "SERVIÇOS DE DESENHO INDUSTRIAL",
        "item": "23",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1063",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5279501",
        "denominacao": "CHAVEIROS",
        "descricao": "SERVIÇOS DE CHAVEIROS (CHAVEIRO)",
        "item": "24",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1010",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3695100",
        "denominacao": "FABRICAÇÃO DE CANETAS, LÁPIS, FITAS IMPRESSORAS PARA MÁQUINAS E OUTROS ARTIGOS PARA ESCRITÓRIO",
        "descricao": "SERVIÇOS DE CONFECÇÃO DE CARIMBOS",
        "item": "24",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1011",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3699499",
        "denominacao": "FABRICAÇÃO DE PRODUTOS DIVERSOS",
        "descricao": "SERVIÇOS DE CHAVEIROS",
        "item": "24",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1012",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3699499",
        "denominacao": "FABRICAÇÃO DE PRODUTOS DIVERSOS",
        "descricao": "SERVIÇOS DE CONFECÇÃO DE PLACAS, SINALIZAÇÃO VISUAL, BANNERS, ADESIVO E CONGÊNERES",
        "item": "24",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1013",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "5271001",
        "denominacao": "REPARAÇÃO DE JÓIAS E RELÓGIOS",
        "descricao": "SERVIÇOS DE GRAVAÇÃO EM OBJETOS",
        "item": "24",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1014",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9303303",
        "denominacao": "SERVIÇOS DE SEPULTAMENTO",
        "descricao": "SERVIÇOS DE FUNERAIS, INCLUSIVE FORNECIMENTO DE CAIXÃO, URNA E ESQUIFES",
        "item": "25",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1015",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9303304",
        "denominacao": "SERVIÇOS DE FUNERÁRIAS",
        "descricao": "SERVIÇOS DE FUNERAIS, INCLUSIVE FORNECIMENTO DE CAIXÃO, URNA E ESQUIFES",
        "item": "25",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1016",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9303305",
        "denominacao": "SERVIÇOS DE SOMATO-CONSERVAÇÃO.",
        "descricao": "SERVIÇOS DE EMBALSAMENTO, EMBELEZAMENTO, CONSERVAÇÃO OU RESTAURAÇÃO DE CADÁVERES",
        "item": "25",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1017",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9303399",
        "denominacao": "OUTRAS ATIVIDADES FUNERÁRIAS",
        "descricao": "ALUGUEL DE CAPELA",
        "item": "25",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1018",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9303399",
        "denominacao": "OUTRAS ATIVIDADES FUNERÁRIAS",
        "descricao": "SERVIÇOS DE FORMECIMENTO DE FLORES EM FUNERAIS, COROAS E OUTROS PARAMENTOS",
        "item": "25",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1019",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9303399",
        "denominacao": "OUTRAS ATIVIDADES FUNERÁRIAS",
        "descricao": "SERVIÇOS DE DESENBARAÇO DE CERTIDÃO DE ÓBITO",
        "item": "25",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1020",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9303399",
        "denominacao": "OUTRAS ATIVIDADES FUNERÁRIAS",
        "descricao": "SERVIÇOS DE TRANSPORTE DE CORPO CADAVÉRICO",
        "item": "25",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1021",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9303399",
        "denominacao": "OUTRAS ATIVIDADES FUNERÁRIAS",
        "descricao": "SERVIÇOS DE FORMECIMENTO DE VÉU E OUTROS ADORNOS",
        "item": "25",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1022",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9303302",
        "denominacao": "SERVIÇOS DE CREMAÇÃO DE CADÁVERES HUMANOS E ANIMAIS",
        "descricao": "SERVIÇOS DE CREMAÇÃO DE CORPOS E PARTE DE CORPOS CADAVÉRICOS",
        "item": "25",
        "subitem": "2",
        "aliquota": "5",
        "idcnae": "1023",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6611702",
        "denominacao": "PLANOS DE AUXÍLIO FUNERAL",
        "descricao": "PLANOS OU CONVÊNIOS FUNERÁRIOS",
        "item": "25",
        "subitem": "3",
        "aliquota": "5",
        "idcnae": "1024",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9303301",
        "denominacao": "GESTÃO E MANUTENÇÃO DE CEMITÉRIOS",
        "descricao": "SERVIÇOS DE MANUTENÇÃO E CONSERVAÇÃO DE JAZIDOS  E CEMITÉRIOS",
        "item": "25",
        "subitem": "4",
        "aliquota": "5",
        "idcnae": "1025",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6411401",
        "denominacao": "ATIVIDADES DO CORREIO NACIONAL",
        "descricao": "SERVIÇO DE COLETA, REMESSA OU ENTREGA DE CORRESPONDÊNCIAS, DOCUMENTOS, OBJETOS, BENS OU VALORES",
        "item": "26",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1026",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6411402",
        "denominacao": "ATIVIDADES DO CORREIO NACIONAL EXECUTADAS POR FRANCHISING",
        "descricao": "SERVIÇO DE COLETA, REMESSA OU ENTREGA DE CORRESPONDÊNCIAS, DOCUMENTOS, OBJETOS, BENS OU VALORES",
        "item": "26",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1027",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8532499",
        "denominacao": "OUTROS SERVIÇOS SOCIAIS SEM ALOJAMENTO",
        "descricao": "SERVIÇOS DE ASSISTÊNCIA SOCIAL",
        "item": "27",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1028",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499399",
        "denominacao": "OUTROS SERVIÇOS PRESTADOS PRINCIPALMENTE ÀS EMPRESAS",
        "descricao": "SERVIÇOS DE AVALIAÇÃO DE BENS E SERVIÇOS DE QUALQUER NATUREZA",
        "item": "28",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1030",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9251700",
        "denominacao": "ATIVIDADES DE BIBLIOTECAS E ARQUIVOS",
        "descricao": "SERVIÇOS BIBLIOTECÁRIOS",
        "item": "29",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1031",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420901",
        "denominacao": "SERVIÇOS TÉCNICOS DE ARQUITETURA",
        "descricao": "SERVIÇOS TÉCNICOS EM EDIFICAÇÕES",
        "item": "31",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1035",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420902",
        "denominacao": "SERVIÇOS TÉCNICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS TÉCNICOS EM TELECOMUNICAÇÕES",
        "item": "31",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1039",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420902",
        "denominacao": "SERVIÇOS TÉCNICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS TÉCNICOS EM MECÂNICA",
        "item": "31",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1038",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420902",
        "denominacao": "SERVIÇOS TÉCNICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS TÉCNICOS EM ELETRÓNICA",
        "item": "31",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1036",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420902",
        "denominacao": "SERVIÇOS TÉCNICOS DE ENGENHARIA",
        "descricao": "SERVIÇOS TÉCNICOS EM ELETROTÉCNICA",
        "item": "31",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1037",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420905",
        "denominacao": "SERVIÇOS DE DESENHO TÉCNICO ESPECIALIZADO",
        "descricao": "SERVIÇOS DE DESENHOS TÉCNICOS",
        "item": "32",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1040",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6340101",
        "denominacao": "ATIVIDADES DE DESPACHANTES ADUANEIROS",
        "descricao": "SERVIÇOS DE DESPACHANTE ADUANEIRO",
        "item": "33",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1041",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499399",
        "denominacao": "OUTROS SERVIÇOS PRESTADOS PRINCIPALMENTE ÀS EMPRESAS",
        "descricao": "SERVIÇOS DE DESPACHANTE",
        "item": "33",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1043",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "6340102",
        "denominacao": "ATIVIDADES DE COMISSARIA",
        "descricao": "SERVIÇOS DE COMISSÁRIOS",
        "item": "33",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1042",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7460801",
        "denominacao": "ATIVIDADES DE INVESTIGAÇÃO PARTICULAR",
        "descricao": "SERVIÇOS DE DETETIVE",
        "item": "34",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1044",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7460801",
        "denominacao": "ATIVIDADES DE INVESTIGAÇÃO PARTICULAR",
        "descricao": "SERVIÇOS DE INVESTIGAÇÕES PARTICULARES",
        "item": "34",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1045",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9240100",
        "denominacao": "ATIVIDADES DE AGÊNCIAS DE NOTÍCIAS",
        "descricao": "SERVIÇOS DE ASSESSORIA DE IMPRENSA",
        "item": "35",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1046",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9240100",
        "denominacao": "ATIVIDADES DE AGÊNCIAS DE NOTÍCIAS",
        "descricao": "SERVIÇOS DE JORNALISMO",
        "item": "35",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1047",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9240100",
        "denominacao": "ATIVIDADES DE AGÊNCIAS DE NOTÍCIAS",
        "descricao": "SERVIÇOS DE REPORTAGEM",
        "item": "35",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1049",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9240100",
        "denominacao": "ATIVIDADES DE AGÊNCIAS DE NOTÍCIAS",
        "descricao": "SERVIÇOS DE RELAÇÕES PÚBLICAS",
        "item": "35",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1048",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7420999",
        "denominacao": "OUTROS SERVIÇOS TÉCNICOS ESPECIALIZADOS",
        "descricao": "SERVIÇOS DE METEOROLOGIA",
        "item": "36",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1050",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9231203",
        "denominacao": "PRODUÇÃO, ORGANIZAÇÃO E PROMOÇÃO DE ESPETÁCULOS ARTÍSTICOS E EVENTOS CULTURAIS",
        "descricao": "SERVIÇOS ARTÍSTIVOS",
        "item": "37",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1051",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499399",
        "denominacao": "OUTROS SERVIÇOS PRESTADOS PRINCIPALMENTE ÀS EMPRESAS",
        "descricao": "SERVIÇOS DE ATLETAS  ",
        "item": "37",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1054",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499399",
        "denominacao": "OUTROS SERVIÇOS PRESTADOS PRINCIPALMENTE ÀS EMPRESAS",
        "descricao": "SERVIÇOS DE MODELOS  ",
        "item": "37",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1052",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "7499399",
        "denominacao": "OUTROS SERVIÇOS PRESTADOS PRINCIPALMENTE ÀS EMPRESAS",
        "descricao": "SERVIÇOS DE MANEQUINS  ",
        "item": "37",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1053",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9252501",
        "denominacao": "GESTÃO DE MUSEUS",
        "descricao": "SERVIÇOS DE MUSEOLOGIA",
        "item": "38",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1055",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3691901",
        "denominacao": "LAPIDAÇÃO DE GEMAS",
        "descricao": "LAPIDADOR",
        "item": "39",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1056",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3691901",
        "denominacao": "LAPIDAÇÃO DE GEMAS",
        "descricao": "SERVIÇOS DE OURIVESARIA E LAPIDAÇÃO (LAPIDADOR)",
        "item": "39",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1058",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "3691901",
        "denominacao": "LAPIDAÇÃO DE GEMAS",
        "descricao": "SERVIÇOS DE OURIVESARIA E LAPIDAÇÃO",
        "item": "39",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1057",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9231299",
        "denominacao": "OUTROS SERVIÇOS ESPECIALIZADOS LIGADOS ÀS ATIVIDADES ARTÍSTICAS",
        "descricao": "SERVIÇOS DE CONFECÇÃO DE OBRAS DE ARTE SOB ENCOMENDA",
        "item": "40",
        "subitem": "1",
        "aliquota": "5",
        "idcnae": "1064",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9999999",
        "denominacao": "CÓDIGO INVALIDO",
        "descricao": "CODIGO INVÁLIDO",
        "item": "99",
        "subitem": "99",
        "aliquota": "9900",
        "idcnae": "1350",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "9999999",
        "denominacao": "CNAE INVÁLIDO",
        "descricao": "CNAE INVÁLIDO",
        "item": "1000",
        "subitem": "1",
        "aliquota": "0",
        "idcnae": "1068",
        "dtcancelamento": "20071231"
    },
    {
        "subclasse": "8012900",
        "denominacao": "ATIVIDADES DE TRANSPORTE DE VALORES",
        "descricao": "SERVIÇOS DE TRANSPORTE DE VALORES, MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "2501",
        "dtcancelamento": "20000101"
    },
    {
        "subclasse": "8012900",
        "denominacao": "ATIVIDADES DE TRANSPORTE DE VALORES",
        "descricao": "SERVIÇOS DE TRANSPORTE DE VALORES, MUNICIPAL",
        "item": "16",
        "subitem": "1",
        "aliquota": "2",
        "idcnae": "11551",
        "dtcancelamento": "20000101"
    }
];
