import { Injectable } from '@angular/core';
import {detItem55, NFe} from "../../interface/NF-e";
import {pagItem} from "../../interface/pag-item";
import {reboqueItem} from "../../interface/reboque-item";
import {volItem} from "../../interface/vol-item";
import {detItem65, NFCe} from "../../interface/NFC-e";

@Injectable({
  providedIn: 'root'
})
export class NfceService implements NFCe{
  ModeloDocumento: "NFCe";
  Versao: 4.00;
  cobr: never;
  dest: {
    CNPJ_dest?: string;
    CPF_dest?: string;
    idEstrangeiro?: string;
    xNome_dest?: string;
    IE_dest?: string;
    indIEDest: 1 | 2 | 9;
    enderDest?: {
      nro_dest: string;
      xCpl_dest?: string;
      xBairro_dest: string;
      xEmail_dest?: string;
      xLgr_dest: string;
      xPais_dest?: string;
      cMun_dest: number;
      xMun_dest: string;
      UF_dest: string;
      CEP_dest?: number;
      cPais_dest?: number;
      fone_dest?: number
    }
  };
  det: detItem65[] = [];
  emit: {
    CNPJ_emit?: string;
    CPF_emit?: string;
    xNome: string;
    xFant?: string;
    IE: string;
    CRT: number;
    enderEmit: {
      xLgr: string;
      nro: string;
      xCpl?: string;
      xBairro: string;
      cMun: number;
      xMun: string;
      UF: string;
      CEP: number;
      cPais?: number;
      xPais?: string;
      fone?: number;
      fax?: number;
      Email?: string
    }
  };
  ide: {
    cNF?: number;
    cUF: number;
    natOp: string;
    mod: "65";
    serie: number;
    nNF: number;
    dhEmi: string;
    fusoHorario: string;
    dhSaiEnt?: string;
    tpNf: 1;
    idDest: 1 | 2 | 3;
    indFinal: 0 | 1;
    indPres: 0 | 1 | 2 | 3 | 4 | 5 | 9;
    cMunFg: number;
    tpImp: 4 | 5;
    tpEmis: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
    tpAmb: 1 | 2;
    finNFe: 1 | 2 | 3 | 4;
    EmailArquivos?: string;
    NFRef?: { refNFe: string }[]
  };
  pag: pagItem[];
  total: {
    ICMStot: {
      vBC_ttlnfe: number;
      vICMS_ttlnfe: number;
      vBCST_ttlnfe: number;
      vST_ttlnfe: number;
      vProd_ttlnfe: number;
      vFrete_ttlnfe: number;
      vSeg_ttlnfe: number;
      vDesc_ttlnfe: number;
      vII_ttlnfe: number;
      vIPI_ttlnfe: number;
      vPIS_ttlnfe: number;
      vCOFINS_ttlnfe: number;
      vOutro: number;
      vNF: number;
      vTotTrib_ttlnfe?: number
    }
  };
  transp: {
    modFrete: 0 | 1 | 2 | 4 | 9;
    balsa?: string;
    vagao?: string;
    transporta?: {
      CNPJ_transp?: string;
      CPF_transp?: string;
      xNome_transp?: string;
      IE_transp?: string;
      xEnder?: string;
      xMun_transp?: string;
      UF_transp?: string
    };
    retTransp?: {
      vServ_transp: number;
      vBCRet: number;
      pICMSRet: number;
      vICMSRet: number;
      CFOP_transp: 5351 | 5352 | 5353 | 5354 | 5355 | 5356 | 5357 | 5359 | 5360 | 6351 | 6352 | 6353 | 6354 | 6355 | 6356 | 6357 | 6359 | 6360 | 7358;
      cMunFG_transp: number
    };
    veicTransp?: reboqueItem;
    reboque?: reboqueItem[];
    vol?: volItem[]
  };
  vTroco: number;

}
