import moment from "moment";
import { Breakdowns } from "../interface/breakdowns";
import { Checklist } from "../interface/checklist";
import { Client } from "../interface/client";
import { Comment } from "../interface/comment";
import { CustomState } from "../interface/custom-state.model";
import { Note } from "../interface/note";
import { Operation } from "../interface/operation";
import { Pdf } from "../interface/pdf";
import { RoLaborFull } from "../interface/ro-labor-full";
import { RoLaborTemp } from "../interface/ro-labor-temp";
import { DESCRIPTIVE_ITEM_STATUS, RoPartTemp } from "../interface/ro-part-temp";
import { Tag } from "../interface/tag";
import { ThirdPartyService } from "../interface/third-party-service";
import { Vehicle } from "../interface/vehicle";
import { authorizationTypes } from "../shared/lists/authorization-types";
import { laborStates } from "../shared/lists/labor-states";
import { partStates } from "../shared/lists/part-states";
import { RO_STATES } from "../shared/lists/ro-states";
import { RoStateGroup } from "../shared/pipes/ro-state-group.pipe";
import { ObjectId } from "../shared/type-aliases/object-id";
import { Utilities } from "./utilities";
import { MkgOSPart } from "./mkg-os-part";
import { PaymentCondition } from "../interface/paymentCondition";
import { Money } from "./money";
import { Budgeter } from "../interface/budgeter";
import { MatDialog } from "@angular/material/dialog";
import { AveragePurchaseCostAlertComponent } from "../component/dialog/average-purchase-cost-alert/average-purchase-cost-alert.component";
import { Part } from "../interface/part";
import { LayoutService } from "../service/layout.service";
import { checklistAssig, RoType } from "../interface/ro-type";
import { ApiFinanceIN } from "../interface/ro-finance";
import { MigrateService, TaxesParams } from "../service/migrate.service";
import { Company } from "../interface/company";
import { Decimal } from "decimal.js"
import { Farm } from "../interface/farm";
import { Labor } from "../interface/labor";
import { TimeTracker } from "../interface/time-tracker";

export enum OS_BLOCK {
    "Blocked" = 1, // Bloqueada
    "Released" // Liberada
}

export interface IOsClient {
    address: {
        cep: string;
        city: string;
        neighborhood: string;
        number: string;
        state: string;
        street: string;
    };

    document: string;
    documentType: number;
    emailIntegrated: string;
    farm: Farm[];
    lang: number;
    maritalStatus: number;
    name: string;
    phone1: string;
    phone2: string;
    rg?: string;
    type: string;
    id: string;
    email?: string;
    fancyName?: string;
    availableLimit?: number;
    creditLimit?: number;
    taxpayer?: boolean;
    retPIS?: boolean;
    retCOFINS?: boolean;
    retIRPJ?: boolean;
    retINSS?: boolean;
    retCSLL?: boolean;
};

export interface IOSVehicle {
    brand: string;
    cilindradas: string;
    color: string;
    fuel: string;
    id: string;
    model: string;
    modelYear: string;
    motor: string;
    plate: string;
    vin: string;
    year: string;
}

export interface IOSOperation {
    _id: string;
    id: string;
    description: string;
    docFiscal: boolean;
    docFiscalServico: boolean;
    /** In the api the 'operationFunction' is 'function' */
    function: string;
    /** In the api the 'entry' is 'inOut' */
    inOut: boolean;
    /** In the api the 'generateFinancial' is 'financialGenera' */
    financialGenera: boolean;
    /** In the api the 'changeStock' is 'moveStock' */
    moveStock: boolean;
    /** In the api the 'entry' is 'inOut' */
    entry: boolean;
    /** In the api the 'operationFunction' is 'function' */
    operationFunction: string;

    /** In the api the 'changeStock' is 'moveStock' */
    changeStock?: boolean;
    /** In the api the 'generateFinancial' is 'financialGenera' */
    generateFinancial?: boolean;

    PIS?: {
        Aliquot?: number;
        valMinRet?: number;
    };
    COFINS?: {
        Aliquot?: number,
        valMinRet?: number,
    };
    CSLL?: {
        aliquot?: number;
        valMinRet?: number;
    };
    IRPJ?: {
        aliquotLabor?: number;
        valMinRet?: number;
    };
    INSS?: {
        aliquot?: number;
        valMinRet?: number;
    };
}

interface IOSRoType {
    id: string;
    _id: string;
    observation: string;
    type: string;
    checklistAssig: checklistAssig;
    nfseCnpj: string;
    nfeCnpj: string;
    operation: string;
}

export interface OsLaborPopulate {
    id?: string;
    description?: string;
    code?: string;
    IRPJ?: { aliquotLabor?: Labor['IRPJ']['aliquotLabor'] };
    CSLL?: { aliquot?: Labor['CSLL']['aliquot'] };
    INSS?: { aliquot?: Labor['INSS']['aliquot'] };
    CNAE?: Labor['CNAE'];
    LCCode?: Labor['LCCode'];
    KmToChange?: Labor['KmToChange'];
    monthToChange?: Labor['monthToChange'];
    saleValue?: Labor['saleValue'];
    group?: Labor['group'];
}

export interface OsLabor {
    id?: string;
    labor?: OsLaborPopulate;
    amount?: number;
    saleValue?: number;
    available?: number;
    observation?: string;
    liquidValue?: number;
    additionalDiscount?: number;
    mechanic?: string;
    timeTrackers?: TimeTracker[];
    discountValue?: number,
    internalObservation?: string;
    discountProp?: number,
    discountPropGeral?: number,

    /** @local only */
    oldAmount?: number;
    /** @local only */
    oldUnitValue?: number;
    /** @local only */
    blocked?: boolean;
    /** @local only */
    running?: boolean;
}


export interface ApiOS {
    authorizationType?: number;
    id?: string;
    type?: IOSRoType;
    code?: number;
    problem?: string;
    currentState?: number;
    client?: IOsClient;
    vehicle?: IOSVehicle;
    checklist?: any[];
    breakdowns?: any;
    parts?: {
        id?: string;
        part?: Part;
        amount?: number;
        purchaseValue?: number;
        purchaseAverage?: number;
        saleValue?: number;
        observation?: string;
        available?: number;
        liquidValue?: number;
        additionalDiscount?: number;
        // fixedValue: number;
        mechanic?: string;
        seq?: number;
        timeTrackers?: any[];
        discountValue?: number;
        internalObservation?: string;
        delivery?: number;
        unitMeasure?: string;
    }[];
    labors?: OsLabor[];
    liquidValue?: number;
    createdAt?: string;
    discountParts?: number;
    discountLabors?: number;
    notes?: any[];
    shortUrl?: string;
    receipts?: any;
    companyLogo?: string;
    companyInfo?: string;
    user?: string;
    updatedAt?: string;
    lastUpdate?: string;
    laborsTMP?: any[];
    partsTMP?: any[];
    budgetCode?: number;
    km?: number;
    operation?: IOSOperation;
    generalDiscount?: number;
    paymentConditions?: {
        /** send a string, receive a object */
        paymentCondition?: string | PaymentCondition;
        value?: number;
        _id?: string;
        jsonAny?: string;
        cardInfo?: any;
    }[]
    thirdPartyServices?: any[];
    clientTmp?: string;
    vehicleTmp?: string;
    prisma?: string;
    tags?: any[];
    chat?: any[];
    valueDiscountParts?: number;
    valueDiscountLabors?: number;
    valueDiscountOs?: number;
    grossValue?: number;
    liquidValueParts?: number;
    grossValueParts?: number;
    liquidValueLabors?: number;
    grossValueLabors?: number;
    observation?: string;
    budgeter?: Budgeter;
    importsBudget?: string[];
    valDeducoes?: number;
    valDeducoesParts?: number;
}

export type ApiOSPost = Omit<ApiOS, 'client' | 'vehicle' | 'operation' | 'type'> & {
    client: string;
    vehicle: string;
    operation: string;
    type: string;
}

export type TaxesParamsOS = Omit<TaxesParams, 'operation' | 'client'> & {
    operation: IOSOperation;
    client: IOsClient
}

export class MkgOS {
    id?: string;
    type?: IOSRoType;
    code?: number;
    codeSystem?: number;
    problem?: string;
    authorizationType?: number;
    client?: IOsClient;
    vehicle?: IOSVehicle;
    appointment?: string;
    deliveryForecast?: string;
    breakdowns?: Breakdowns;
    /** @see authorizationTypes */
    complementaryApprove?: number;
    createdAt?: string;
    priority?: number;
    budgeter?: string;
    showPDF?: number;
    shortUrl?: string;
    startDate?: string;
    receiveDate?: string;
    evaluationDate?: string;
    evaluation?: number;
    importsBudget?: string[];

    /** @unused */
    receipts?: string;

    /** used into WebClient app */
    companyLogo?: string;
    observation?: string;
    km?: number;
    recall?: string;
    authDisposal?: number;

    /** id of checklist-model-item applied to this OS */
    checklistModelItem?: string;

    /** id of survey applied to this OS */
    surveyModelItem?: string

    /**
     * A string cointaining the company cnpj, the company name and company site, joined by two asterisks (**)
    *
    * Used in WebCliente
    * @example"companycnpj**companyname**companysite" */
    companyInfo?: string;

    /** Email of the user that opened the buget (Consultor)*/
    user?: string;
    updatedAt?: string;
    lastUpdate?: string;
    evaluationText?: string;
    pdf?: Pdf;
    operation?: IOSOperation;
    additionalState?: string;

    /** @test */
    prisma?: string;
    /** @todo */
    model?: string;
    /** @todo */
    serie?: string;

    packet?: string;
    review?: string;
    menu?: string;
    checklist?: Checklist[] = [];
    private _parts?: MkgOSPart[] = [];
    public get parts(): MkgOSPart[] { return this._parts }
    public set parts(value: MkgOSPart[]) { this._parts = value }
    labors?: OsLabor[] = [];
    chat?: Comment[] = [];
    tags?: Tag[] = [];
    notes?: Note[] = [];
    partsTMP?: RoPartTemp[] = [];
    laborsTMP?: RoLaborTemp[] = [];
    thirdPartyServices?: ThirdPartyService[] = [];
    paymentConditions?: ApiFinanceIN[] = [];

    /** When use defalt client, show this value as client name */
    clientTmp?: string;

    /** When use defalt vehicle, show this value as vehicle plate */
    vehicleTmp?: string;

    // Discount Control
    osBlock?: OS_BLOCK;

    // the id of selected farm when document type of client is rural producer
    idFarmer?: ObjectId;

    additionalStateObject?: CustomState;
    operationId?: string;
    budgeterObject?: Budgeter;
    clientObject?: Client;
    vehicleObject?: Vehicle;
    budgetCode?: number;
    partsExpenseValue?: number;

    /**
     * The value calculated for DEDUCTION of ISS retention
     * @readonly Please call the function `calculateRetentions()` to define it
     */
    get retISS() { return this._retISS }
    private set retISS(value) { this._retISS = value }
    private _retISS = 0;

    /**
     * The sum of `itemVlDed` of available OS labors
     * @readonly Please call the function `calculateRetentions()` to define it before create the titles
    */
    get valDeducoesLabors() { return this._valDeducoesLabors }
    private set valDeducoesLabors(value) { this._valDeducoesLabors = value }
    private _valDeducoesLabors = 0;

    /**
     * The sum of `itemVlDed` of available OS parts
     * @readonly Please call the function `calculateRetentions()` to define it before create the titles
    */
    get valDeducoesParts() { return this._valDeducoesParts }
    private set valDeducoesParts(value) { this._valDeducoesParts = value }
    private _valDeducoesParts = 0;


    /**
     * Sum reduction values of all calculated retentions from a preview
     * of the retentions of each available item of this OS.
     *
     * This method define the values for follow properties:
     * - retISS
     * - valDeducoes
     * - valDeducoesParts
     *
     * The values are taken from the same functions on MigrateService, where taxes are calculated.
     *
     * Please call this method before create titles for this OS, after update the client of OS
     * and after update the operation.
     *
     * @param companyLabors The company used to emit the labors invoice
     * @param companyParts The company used to emit the parts invoice, if ommited the companyLabors will be used instead
     * @returns Boolean properties indicating if this OS and items should be updateds into database.
     */
    calculateRetentions(companyLabors: Company, _companyParts?: Company): {
        issRetChanged: boolean,
        valDeductionsLaborsChanged: boolean,
        valDeductionsPartsChanged: boolean
    } {
        if (!this.client || !this.operation) {
            return {
                issRetChanged: false,
                valDeductionsLaborsChanged: false,
                valDeductionsPartsChanged: false
            }
        }

        // if(!companyParts){
        //     companyParts = companyLabors;
        // }
        /**
         * @todo get two operations for two invoices
         */


        // -----------------------------------------
        // for parts, we don't calculate reductions
        // -----------------------------------------
        let deductionsPartsSum = 0;
        // if(companyParts.deduNFE == BOOLEAN_ENUM.TRUE){
        //     const retTrib = MigrateService.getRetTrib(
        //         this.liquidValueParts,
        //         this.operation,
        //         this.clientObject,
        //         this.availableParts);

        //     if (retTrib) {
        //         deductionsPartsSum = Money((retTrib.vRetPIS || 0) // PIS
        //             + (retTrib.vRetCOFINS_servttlnfe || 0) // COFINS
        //             + (retTrib.vRetCSLL || 0) // CSLL
        //             + (retTrib.vIRRF || 0) // IR
        //             + (retTrib.vRetPrev || 0)); // INSS
        //     }
        // }


        const retISS = this.client?.taxpayer && this.liquidValueLabors >= companyLabors.minISS;
        const retPIS = this.client?.retPIS && this.liquidValueLabors >= this.operation?.PIS?.valMinRet;
        const retCOFINS = this.client?.retCOFINS && this.liquidValueLabors >= this.operation?.COFINS?.valMinRet;
        const retCSLL = this.client?.retCSLL && this.liquidValueLabors >= this.operation?.CSLL?.valMinRet;
        const retIRPJ = this.client?.retIRPJ && this.liquidValueLabors >= this.operation?.IRPJ?.valMinRet;
        const retINSS = this.client?.retINSS && this.liquidValueLabors >= this.operation?.INSS?.valMinRet;

        const retentions = {
            ISS: 0,
            PIS: 0,
            COFINS: 0,
            CSLL: 0,
            IRPJ: 0,
            INSS: 0,
        }

        for (const osLabor of this.availableLabors) {
            const taxesParams: TaxesParamsOS = {
                client: this.client,
                operation: this.operation,
                ItemBaseCalculo: osLabor.liquidValue,
                labor: osLabor.labor
            }
            const issTaxes = MigrateService.getISStaxes({ company: companyLabors, ...taxesParams });
            if (retISS) {
                retentions.ISS = Decimal.add(retentions.ISS, issTaxes.ItemvIss || 0).toNumber();
            }
            if (retPIS) {
                const pisTaxes = MigrateService.getPIStaxes(taxesParams);
                retentions.PIS = Decimal.add(retentions.PIS, pisTaxes.ItemValPIS || 0).toNumber();
            }
            if (retCOFINS) {
                const cofinsTaxes = MigrateService.getCOFINStaxes(taxesParams);
                retentions.COFINS = Decimal.add(retentions.COFINS, cofinsTaxes.ItemValCOFINS || 0).toNumber();
            }
            if (retCSLL) {
                const csllTaxes = MigrateService.getCSLLtaxes(taxesParams);
                retentions.CSLL = Decimal.add(retentions.CSLL, csllTaxes.ItemValCSLL || 0).toNumber();
            }
            if (retIRPJ) {
                const irTaxes = MigrateService.getIRtaxes(taxesParams);
                retentions.IRPJ = Decimal.add(retentions.IRPJ, irTaxes.ItemValIR || 0).toNumber();
            }
            if (retINSS) {
                const inssTaxes = MigrateService.getINSStaxes(taxesParams);
                retentions.INSS = Decimal.add(retentions.INSS, inssTaxes.ItemValINSS || 0).toNumber();
            }
        }


        for (const outsourced of this.thirdPartyServices) {
            const taxesParams: TaxesParamsOS = {
                client: this.client,
                operation: this.operation,
                ItemBaseCalculo: outsourced.collectedValue,
            }
            if (retISS) {
                const issTaxes = MigrateService.getISStaxes({ company: companyLabors, ...taxesParams })
                retentions.ISS = Decimal.add(retentions.ISS, issTaxes.ItemvIss || 0).toNumber();
            }
            if (retPIS) {
                const pisTaxes = MigrateService.getPIStaxes(taxesParams);
                retentions.PIS = Decimal.add(retentions.PIS, pisTaxes.ItemValPIS).toNumber();
            }
            if (retCOFINS) {
                const cofinsTaxes = MigrateService.getCOFINStaxes(taxesParams);
                retentions.COFINS = Decimal.add(retentions.COFINS, cofinsTaxes.ItemValCOFINS).toNumber();
            }
            if (retCSLL) {
                const csllTaxes = MigrateService.getCSLLtaxes(taxesParams);
                retentions.CSLL = Decimal.add(retentions.CSLL, csllTaxes.ItemValCSLL).toNumber();
            }
            if (retIRPJ) {
                const irTaxes = MigrateService.getIRtaxes(taxesParams);
                retentions.IRPJ = Decimal.add(retentions.IRPJ, irTaxes.ItemValIR).toNumber();
            }
            if (retINSS) {
                const inssTaxes = MigrateService.getINSStaxes(taxesParams);
                retentions.INSS = Decimal.add(retentions.INSS, inssTaxes.ItemValINSS).toNumber();
            }
        }

        // sum labors retentions
        const federalRetentions = new Decimal(0)
            .plus(retentions.PIS)
            .plus(retentions.COFINS)
            .plus(retentions.CSLL)
            .plus(retentions.IRPJ)
            .plus(retentions.INSS);

        let issDeduction = 0;
        if (companyLabors.deduISS) {
            issDeduction = retentions.ISS
        }

        console.groupCollapsed("Calculando retenções de NFSe...")
        let federalDeductions = 0;
        if (companyLabors.deduNFSE) {
            console.log("+", retentions.PIS.toFixed(2), "PIS")
            console.log("+", retentions.COFINS.toFixed(2), "COFINS")
            console.log("+", retentions.CSLL.toFixed(2), "CSLL")
            console.log("+", retentions.IRPJ.toFixed(2), "IRPJ")
            console.log("+", retentions.INSS.toFixed(2), "INSS")
            federalDeductions = Decimal(federalDeductions).plus(federalRetentions).toNumber();
            console.log("-----------------")
            console.log("=", federalRetentions.toFixed(2), "Total retenções Federais deduzidas")
        }
        console.log("+", issDeduction.toFixed(2), "ISS Retido deduzido")
        console.groupEnd()

        const valDeductionsLaborsChanged = !Decimal(federalDeductions).equals(this.valDeducoesLabors);
        const valDeductionsPartsChanged = deductionsPartsSum !== this.valDeducoesParts;
        if (valDeductionsPartsChanged) {
            this._valDeducoesParts = deductionsPartsSum;
        }
        if (valDeductionsLaborsChanged) {
            this._valDeducoesLabors = federalDeductions;
        }

        let issRetChanged = !Decimal(issDeduction).equals(this.retISS);

        if (issRetChanged) {
            this._retISS = issDeduction;
        }

        return { issRetChanged, valDeductionsLaborsChanged, valDeductionsPartsChanged };
    }

    // GROSS VALUE
    private _grossValue: number;
    public get grossValue(): number { return this._grossValue; }
    public set grossValue(value: number) { this._grossValue = value; }

    // LIQUID VALUE
    private _liquidValue: number;
    public get liquidValue(): number { return this._liquidValue; }
    public set liquidValue(value: number) { this._liquidValue = value; }
    public setLiquidValue(value: number) {
        this.valueDiscountOs = Money(Money(this.sumDiscountValues.baseGeral()) - Money(value));
        this._generalDiscount = Utilities.getPercentage(this._valueDiscountOs, this.sumDiscountValues.baseGeral(), 10);
        this.recalculate("MkgOS.setLiquidValue()")
    }

    // GENERAL DISCOUNT VALUE OF OS
    private _valueDiscountOs: number;
    public get valueDiscountOs(): number { return this._valueDiscountOs || 0; }
    public set valueDiscountOs(value: number) { this._valueDiscountOs = value; }
    public setValueDiscountOs(value: number) {
        this._valueDiscountOs = Money(value);
        this._generalDiscount = Utilities.getPercentage(this._valueDiscountOs, this.sumDiscountValues.baseGeral(), 10);
        this.recalculate("MkgOS.setValueDiscountOs()")
    }

    // GENERAL DISCOUNT PERCENTAGE OF OS
    private _generalDiscount: number;
    public get generalDiscount(): number { return this._generalDiscount; }
    public set generalDiscount(value: number) { this._generalDiscount = value; }
    public setGeneralDiscount(value: number) {
        this._generalDiscount = value;
        const base = this.sumDiscountValues.baseGeral();
        this._valueDiscountOs = Utilities.calcPercentage(value, base);
        this.recalculate("MkgOS.setGeneralDiscount()")
    }

    getPartsLiquidValue1() {
        const partsTMPliquid1 = this.availablePartsTMP.reduce((acc, part) => acc + part.amount * part.saleValue, 0);
        return Money(this.availableParts.reduce((acc, part) => acc + part.liquid1(), partsTMPliquid1));
    }

    getPartsLiquidValue2() {
        const partsTMPliquid2 = this.availablePartsTMP.reduce((acc, part) => acc + part.amount * part.saleValue - part._discountProp, 0);
        return Money(this.availableParts.reduce((acc, part) => acc + part.liquid2(), partsTMPliquid2));
    }

    getLaborLiquid1(labor: RoLaborFull) {
        const grossValue = Money(labor.amount * labor.saleValue);
        return Money(grossValue - labor.discountValue);
    }

    getLaborLiquid2(labor: RoLaborFull) {
        const grossValue = Money(labor.amount * labor.saleValue);
        return Money(grossValue - labor.discountValue - labor.discountProp);
    }

    getLaborsLiquidValue1() {
        const laborsLiquid1 = this.availableLabors.reduce((acc, labor) => acc + this.getLaborLiquid1(labor), 0);
        const laborsTMPliquid1 = this.availableLaborsTMP.reduce((acc, labor) => acc + (labor.amount * labor.saleValue), 0);
        const outsourcedLiquid1 = this.thirdPartyServices.reduce((acc, labor) => acc + labor.collectedValue, 0);
        return Money(laborsLiquid1 + laborsTMPliquid1 + outsourcedLiquid1);
    }

    getLaborsLiquidValue2() {
        const laborsLiquid2 = this.availableLabors.reduce((acc, labor) => acc + this.getLaborLiquid2(labor), 0);
        const laborsTMPliquid2 = this.availableLaborsTMP.reduce((acc, labor) => acc + (labor.amount * labor.saleValue) - labor._discountProp, 0);
        const outsourcedLiquid2 = this.thirdPartyServices.reduce((acc, labor) => acc + (labor.collectedValue - labor.discountPropLabor), 0);
        return Money(laborsLiquid2 + laborsTMPliquid2 + outsourcedLiquid2);
    }

    // GROSS VALUE OF PARTS
    private _grossValueParts: number;
    public get grossValueParts(): number { return this._grossValueParts }
    public set grossValueParts(value: number) { this._grossValueParts = value }
    private _getGrossValueParts() {
        const grossValuePartsTMP = this.availablePartsTMP.reduce((acc, part) => acc + Money(part.amount * part.saleValue), 0);
        return this.availableParts.reduce((acc, part) => acc + Money(part.grossValue()), grossValuePartsTMP);
    }
    // GROSS VALUE OF LABORS
    private _grossValueLabors: number;
    public get grossValueLabors(): number { return this._grossValueLabors }
    public set grossValueLabors(value: number) { this._grossValueLabors = value }
    private _getGrossValueLabors() {
        const grossValueLabors = this.availableLabors.reduce((acc, labor) => acc + Money(labor.amount * labor.saleValue), 0);
        const grossValueLaborsTMP = this.availableLaborsTMP.reduce((acc, part) => acc + Money(part.amount * part.saleValue), 0);
        return (grossValueLabors + grossValueLaborsTMP + this.outsourcedValue);
    }

    // PERCENTAGE OF DISCOUNT FOR PARTS
    private _discountParts: number;
    public get discountParts(): number { return this._discountParts }
    public set discountParts(value: number) { this._discountParts = value }
    public setDiscountParts(value: number) {
        this._discountParts = Money(value);
        this._valueDiscountParts = Utilities.calcPercentage(value, this.getPartsLiquidValue1());
        this.recalculate("MkgOS.setDiscountParts()");
    }
    // PERCENTAGE OF DISCOUNT FOR LABORS
    private _discountLabors: number;
    public get discountLabors(): number { return this._discountLabors }
    public set discountLabors(value: number) { this._discountLabors = value }
    public setDiscountLabors(value: number) {
        this._discountLabors = Money(value);
        this._valueDiscountLabors = Utilities.calcPercentage(value, this.getLaborsLiquidValue1());
        this.recalculate("MkgOS.setDiscountLabors()")
    }

    // VALUE OF DISCOUNT FOR PARTS
    private _valueDiscountParts: number;
    public get valueDiscountParts(): number { return this._valueDiscountParts || 0 }
    public set valueDiscountParts(value: number) { this._valueDiscountParts = value }
    public setValueDiscountParts(value: number) {
        this._valueDiscountParts = Money(value);
        this._discountParts = Utilities.getPercentage(value, this.getPartsLiquidValue1(), 10);
        this.recalculate("MkgOS.setValueDiscountParts()")
    }
    // VALUE OF DISCOUNT FOR LABORS
    private _valueDiscountLabors: number;
    public get valueDiscountLabors(): number { return this._valueDiscountLabors || 0 }
    public set valueDiscountLabors(value: number) { this._valueDiscountLabors = value }
    public setValueDiscountLabors(value: number) {
        this._valueDiscountLabors = Money(value);
        this._discountLabors = Utilities.getPercentage(value, this.getLaborsLiquidValue1(), 10);
        this.recalculate("MkgOS.setValueDiscountLabors()");
    }

    // LIQUID VALUE FOR PARTS
    private _liquidValueParts: number;
    public get liquidValueParts(): number { return this._liquidValueParts }
    public set liquidValueParts(value: number) { this._liquidValueParts = value }
    public setLiquidValueParts(value: number) {
        const propGeral = this.sumDiscountValues.parts.propGeral();
        this._valueDiscountParts = this.getPartsLiquidValue1() - propGeral - value;
        this._discountParts = Utilities.getPercentage(this._valueDiscountParts, this.getPartsLiquidValue1(), 10);
        this._liquidValueParts = value;
        // dont recalcutate
    }
    // LIQUID VALUE FOR LABORS
    private _liquidValueLabors: number;
    public get liquidValueLabors(): number { return this._liquidValueLabors }
    public set liquidValueLabors(value: number) { this._liquidValueLabors = value }
    public setLiquidValueLabors(value: number) {
        this._liquidValueLabors = value;
        const propGeral = this.sumDiscountValues.labors.propGeral();
        this._valueDiscountLabors = this.getLaborsLiquidValue1() - propGeral - value;
        this._discountLabors = Utilities.getPercentage(this._valueDiscountLabors, this.getLaborsLiquidValue1(), 10);
        // dont recalcutate
    }

    /** The CNPJ of company where the OS is from */
    cnpj?: string;

    private _stateGroup: RoStateGroup;
    get stateGroup() {
        return this._stateGroup;
    }

    private _currentState: number;
    set currentState(value: number) {
        switch (value) {
            case RO_STATES.budget: // 2
            case RO_STATES.authorization: // 4
            case RO_STATES.appointment: // 5
            case RO_STATES.readyToStart: // 6
                this._stateGroup = 'budget';
                break;
            case RO_STATES.started: // 7
                this._stateGroup = 'opened';
                break;
            case RO_STATES.toReceive: // 8
            case RO_STATES.evaluation: // 9
                this._stateGroup = 'closed';
                break;
            case RO_STATES.finished: // 10
                this._stateGroup = 'ended';
                break;
            case RO_STATES.rejected: // 11
                this._stateGroup = 'rejected';
                break;
            default:
                this._stateGroup = "ended";
                break;
        }
        this._currentState = value;

    }
    get currentState() {
        return this._currentState;
    }

    constructor(apiOS: ApiOS & { cnpj?: string }, dontDivideByOneHundred?: boolean) {
        const apiParts = apiOS.parts;
        delete apiOS.parts; // prevent to set non instances to array of MkgOSPart

        Object.assign(this, apiOS);

        this._valDeducoesParts

        if (Number.isFinite(apiOS.liquidValue)) {
            if (moment(apiOS.lastUpdate).isSameOrBefore(moment('2024-09-01'))) {
                this.setLiquidValue(Money(apiOS.liquidValue / (dontDivideByOneHundred ? 1 : 100)));
            } else {
                this.liquidValue = Money(apiOS.liquidValue / (dontDivideByOneHundred ? 1 : 100));
            }
        }

        if (apiOS.authorizationType == authorizationTypes.rejected.id) {
            this.currentState = RO_STATES.rejected;
        }

        if (apiOS.budgeter) {
            this.budgeterObject = apiOS.budgeter;
            this.budgeter = apiOS.budgeter?._id;
        }

        this.operationId = (apiOS.operation?._id ?? apiOS.operation?.id) ?? apiOS.operation as any;

        if (apiOS.operation && typeof apiOS.operation !== "string") {
            if (apiOS.operation.moveStock !== null && apiOS.operation.moveStock !== undefined) {
                this.operation.changeStock = apiOS.operation.moveStock;
            }

            if (apiOS.operation.financialGenera !== null && apiOS.operation.financialGenera !== undefined) {
                this.operation.generateFinancial = apiOS.operation.financialGenera;
            }

            if (apiOS.operation.inOut !== null && apiOS.operation.inOut !== undefined) {

            }

            this.operation.id = apiOS.operation._id;
        }

        if (apiOS.type && typeof apiOS.type !== "string") {
            this.type.id = apiOS.type.id ?? apiOS.type._id;
        }

        this._initializeArrays();

        if (Array.isArray(apiParts)) {
            this._parts = apiParts.map(apiPart => new MkgOSPart(apiPart, !dontDivideByOneHundred));
        }

        // sum partTMP values
        for (const partTMP of this.partsTMP) {
            let netValue = partTMP.amount * partTMP.saleValue; // gross value
            netValue -= Utilities.calcPercentage(this.discountParts, netValue, 'round'); // discount of parts
            netValue -= Utilities.calcPercentage(this.generalDiscount, netValue);// discount general of OS
            partTMP._netValue = netValue;
            const internalObservation = partTMP.internalObservation;
            const observation = partTMP.observation;
            partTMP.observation = internalObservation;
            partTMP.internalObservation = observation;
            if (!Object.hasOwn(partTMP, "_discountProp")) {
                partTMP._discountProp = 0;
            }
            if (!Object.hasOwn(partTMP, "_discountPropGeral")) {
                partTMP._discountPropGeral = 0;
            }
        }

        for (const roLabor of this.labors) {
            roLabor.saleValue = (roLabor.saleValue || 0) / (dontDivideByOneHundred ? 1 : 100);
            roLabor.discountValue = (roLabor.discountValue || 0) / (dontDivideByOneHundred ? 1 : 100);
            roLabor.liquidValue = (roLabor.liquidValue || 0) / (dontDivideByOneHundred ? 1 : 100);
            roLabor.discountProp = (roLabor.discountProp || 0) / (dontDivideByOneHundred ? 1 : 100);
            roLabor.discountPropGeral = (roLabor.discountPropGeral || 0) / (dontDivideByOneHundred ? 1 : 100);

            /**
             * Está sendo invertido o campo de observações para que as observações
             * que ja existiam (campo 'observation') aparecam como padrão no pdf
            */
            const observation = roLabor.observation ?? "";
            roLabor.observation = roLabor.internalObservation ?? "";
            roLabor.internalObservation = observation;
        }

        // sum laborTMP values
        for (const laborTMP of this.laborsTMP) {
            let netValue = laborTMP.amount * laborTMP.saleValue; // gross value
            netValue -= Utilities.calcPercentage(this.discountLabors, netValue, 'round'); // discount of labors
            netValue -= Utilities.calcPercentage(this.generalDiscount, netValue);// discount general of OS
            laborTMP._netValue = netValue;
            const internalObservation = laborTMP.internalObservation;
            const observation = laborTMP.observation;
            laborTMP.observation = internalObservation;
            laborTMP.internalObservation = observation;
            if (!Object.hasOwn(laborTMP, "_discountProp")) {
                laborTMP._discountProp = 0;
            }
            if (!Object.hasOwn(laborTMP, "_discountPropGeral")) {
                laborTMP._discountPropGeral = 0;
            }
        }

        for (const outsourced of this.thirdPartyServices) {
            outsourced.id = outsourced._id;
            outsourced.collectedValue = (outsourced.collectedValue || 0) / (dontDivideByOneHundred ? 1 : 100);
            outsourced.paymentValue = (outsourced.paymentValue || 0) / (dontDivideByOneHundred ? 1 : 100);
            if (!outsourced.discountPropLabor) {
                outsourced.discountPropLabor = 0; // prevent NaN
            }
            if (!outsourced.discountPropGeral) {
                outsourced.discountPropGeral = 0; // prevent NaN
            }
        }

        if (!Number.isFinite(apiOS.grossValueParts)) {
            this.grossValueParts = this._getGrossValueParts();
        }

        if (!Number.isFinite(apiOS.grossValueLabors)) {
            this.grossValueLabors = this._getGrossValueLabors();
        }

        if (!Number.isFinite(apiOS.grossValue)) {
            this.grossValue = this.grossValueParts + this.grossValueLabors;
        }

        if (!Number.isFinite(apiOS.generalDiscount)) {
            this.generalDiscount = Utilities.getPercentage(this.valueDiscountOs || 0, this.sumDiscountValues.baseGeral());
        }

        // redistribute discounts (for older OSs)
        if (this.valueDiscountParts
            && (this.availableParts.some(p => !p.discountProp())
                || this.availablePartsTMP.some(p => !p._discountProp))) {
            this.redistributeDiscountParts();
        }
        if (this.valueDiscountLabors
            && (this.availableLabors.some(l => !l.discountProp)
                || this.availableLaborsTMP.some(l => !l._discountProp)
                || this.thirdPartyServices.some(o => !o.discountPropLabor))) {
            this.redistributeDiscountLabors();
        }
        if (this.valueDiscountOs
            && (this.availableParts.some(p => !p.discountPropGeral())
                || this.availablePartsTMP.some(p => !p._discountPropGeral)
                || this.availableLabors.some(l => !l.discountPropGeral)
                || this.availableLaborsTMP.some(l => !l._discountPropGeral)
                || this.thirdPartyServices.some(o => !o.discountPropGeral))) {
            this.redistributeDiscountGeral();
        }

        // adjusting liquid values
        for (const labor of this.labors) {
            labor.liquidValue = (labor.amount * labor.saleValue) - labor.discountValue - labor.discountProp - labor.discountPropGeral;
        }

        if (!Number.isFinite(apiOS.liquidValue)) {
            this.liquidValue = this.liquidValueParts + this.liquidValueLabors;
        }

        this.recalculate("constructor")

    }

    /** Prevent error: reduce of empty array */
    private _initializeArrays() {
        if (!Array.isArray(this.parts))
            this._parts = [];
        if (!Array.isArray(this.partsTMP))
            this.partsTMP = [];
        if (!Array.isArray(this.labors))
            this.labors = [];
        if (!Array.isArray(this.laborsTMP))
            this.laborsTMP = [];
        if (!Array.isArray(this.thirdPartyServices))
            this.thirdPartyServices = [];
        if (!Array.isArray(this.paymentConditions))
            this.paymentConditions = [];
        if (!Array.isArray(this.chat))
            this.chat = [];
        if (!Array.isArray(this.tags))
            this.tags = [];
        if (!Array.isArray(this.checklist))
            this.checklist = [];
        if (!Array.isArray(this.notes))
            this.notes = [];
        if (!Array.isArray(this.importsBudget))
            this.importsBudget = [];
    }

    /** The not-rejected parts */
    get availableParts() {
        return this.parts.filter(p => p.available != partStates.rejected.id);
    }

    get approvedParts() {
        return this.parts.filter(p => p.available == partStates.approved.id);
    }

    /** the not-rejected partsTMP */
    get availablePartsTMP() {
        return this.partsTMP.filter(ptmp => ptmp.status != DESCRIPTIVE_ITEM_STATUS.INACTIVE);
    }

    /** The not rejected labors */
    get availableLabors() {
        return this.labors.filter(l => l.available != laborStates.rejected.id);
    }

    get approvedLabors() {
        return this.labors.filter(l => l.available == laborStates.approved.id);
    }

    /** The not rejected laborsTMP */
    get availableLaborsTMP() {
        return this.laborsTMP.filter(ltmp => ltmp.status != DESCRIPTIVE_ITEM_STATUS.INACTIVE);
    }

    /** Sum of collectedValue of thirdPartyServices */
    get outsourcedValue(): number {
        return this.thirdPartyServices.reduce((acc, out) => acc + out.collectedValue, 0)
    }

    /** Sum of paid of thirdPartyServices */
    get outsourcedPaidValue(): number {
        return this.thirdPartyServices.reduce((acc, out) => acc + out.paymentValue, 0)
    }

    sumDiscountValues = {
        partsTMP: {
            propParts: () => this.availablePartsTMP.reduce((acc, part) => Money(acc) + Money(part._discountProp), 0),
            propGeral: () => this.availablePartsTMP.reduce((acc, part) => Money(acc) + Money(part._discountPropGeral), 0)
        },
        parts: {
            additional: () => this.availableParts.reduce((acc, part) => Money(acc) + Money(part.discountValue()), 0),
            propParts: () => this.availableParts.reduce((acc, part) => Money(acc) + Money(part.discountProp()), this.sumDiscountValues.partsTMP.propParts()),
            propGeral: () => this.availableParts.reduce((acc, part) => Money(acc) + Money(part.discountPropGeral()), this.sumDiscountValues.partsTMP.propGeral()),
            totalDiscountParts: () => this.sumDiscountValues.parts.additional() + this.sumDiscountValues.parts.propParts() + this.sumDiscountValues.parts.propGeral()
        },
        laborsTMP: {
            propLabors: () => this.availableLaborsTMP.reduce((acc, labor) => Money(acc) + Money(labor._discountProp), 0),
            propGeral: () => this.availableLaborsTMP.reduce((acc, labor) => Money(acc) + Money(labor._discountPropGeral), 0)
        },
        labors: {
            additional: () => this.availableLabors.reduce((acc, labor) => Money(acc) + Money(labor.discountValue), 0),
            propLabors: () => this.availableLabors.reduce((acc, labor) => Money(acc) + Money(labor.discountProp), this.sumDiscountValues.laborsTMP.propLabors() + this.sumDiscountValues.outsourced.sumOutsourcedDiscountLabors()),
            propGeral: () => this.availableLabors.reduce((acc, labor) => Money(acc) + Money(labor.discountPropGeral), this.sumDiscountValues.laborsTMP.propGeral() + this.sumDiscountValues.outsourced.sumOutsourcedDiscountGeral())
        },
        outsourced: {
            // sumOutsourcedCollectedValue: () => this.thirdPartyServices.reduce((acc, out) => Money(acc) + Money(out.collectedValue || 0), 0),
            sumOutsourcedDiscountLabors: () => this.thirdPartyServices.reduce((acc, out) => Money(acc) + Money(out.discountPropLabor || 0), 0),
            sumOutsourcedDiscountGeral: () => this.thirdPartyServices.reduce((acc, out) => Money(acc) + Money(out.discountPropGeral || 0), 0)
        },
        baseGeral: () => this.getPartsLiquidValue2() + this.getLaborsLiquidValue2(),
    }

    get isBudgetOrRejected(): boolean {
        return ["budget", "rejected"].includes(this.stateGroup);
    }

    get isBudget(): boolean {
        return this.stateGroup == "budget";
    }

    get isOS(): boolean {
        return ["opened", "closed", "ended"].includes(this.stateGroup);
    }

    /** budgetCode or codeSystem, according state */
    get relativeCode(): number {
        return this.isBudgetOrRejected ? this.budgetCode : this.codeSystem;
    }

    get isRejected(): boolean {
        return this.stateGroup === "rejected";
    }

    get isOpened() {
        return this.stateGroup === "opened";
    }

    get isClosed() {
        return this.stateGroup === "closed";
    }

    get isEnded() {
        return this.stateGroup === "ended";
    }

    get canImportItems() {
        return this._currentState === RO_STATES.appointment;
    }

    get isClosedOrEnded(): boolean {
        return ["closed", "ended"].includes(this.stateGroup);
    }

    get isClosedOrEndedOrRejected(): boolean {
        return ["closed", "ended", "rejected"].includes(this.stateGroup);
    }

    get paymentConditionsSum() {
        return this.paymentConditions.reduce((sum, condition) => sum + condition.value, 0);
    }

    get restantToPay() {
        return Money(
            Number((
                this.liquidValue
                - this.paymentConditionsSum
                - this.retISS
                - this.valDeducoesLabors
                - this.valDeducoesParts
            ).toFixed(3))
        );
    }

    get maxDiscountForParts() {
        return this.grossValueParts - this.sumDiscountValues.parts.additional();
    }

    get maxDiscountForLabors() {
        return this.grossValueLabors - this.sumDiscountValues.labors.additional();
    }

    /**
     * indicate if the user can modify the OS values
     */
    get canEdit() {
        return this.isBudget || this.isOpened;
    }


    // recalculatePart(roPart: RoPartFull) {
    //     const grossValue = roPart.amount * roPart.saleValue;
    //     const discountValue = Utilities.calcPercentage(roPart.additionalDiscount, grossValue - roPart.discountProp);
    //     const discountProp = Utilities.calcPercentage(roPart.additionalDiscount, grossValue - discountValue)

    //     roPart.discountValue = Utilities.calcPercentage(roPart.additionalDiscount, grossValue - discountProp);
    //     roPart.discountProp = discountProp;
    //     roPart.discountPropGeral = Utilities.calcPercentage(this.generalDiscount, grossValue - roPart.discountValue - roPart.discountProp);
    //     roPart.liquidValue = grossValue - roPart.discountValue - roPart.discountProp;
    // }

    // recalculateLabor(rolabor: RoLaborFull) {
    //     const grossValue = rolabor.amount * rolabor.saleValue;
    //     rolabor.discountValue = Utilities.calcPercentage(rolabor.additionalDiscount, grossValue);
    //     rolabor.discountProp = Utilities.calcPercentage(this.discountLabors, grossValue - rolabor.discountValue);
    //     rolabor.discountPropGeral = Utilities.calcPercentage(this.generalDiscount, grossValue - rolabor.discountValue - rolabor.discountProp);
    //     rolabor.liquidValue = grossValue - rolabor.discountValue - rolabor.discountProp;
    // }

    static fromForm(formValue: {
        id: string,
        user: string,
        km: number,
        problem: string,
        prisma: string,
        clientTmp: string,
        vehicleTmp: string,
        type?: string | RoType,
        vehicle?: string | Vehicle,
        client?: string | Client,
        operation?: string | Operation,
        currentState?: RO_STATES
    }) {
        const OS = {
            id: formValue.id,
            user: formValue.user,
            km: formValue.km,
            problem: formValue.problem,
            prisma: formValue.prisma,
            clientTmp: formValue.clientTmp,
            vehicleTmp: formValue.vehicleTmp,
            currentState: formValue.currentState
        }

        if (formValue.type) {
            let typeID;
            if (typeof formValue.type === 'string') {
                typeID = formValue.type;
            } else {
                typeID = (formValue.type as RoType).id;
            }
            Object.defineProperty(OS, "type", {
                value: typeID,
                enumerable: true
            });
        }

        if (formValue.vehicle) {
            let vehicleID;
            if (typeof formValue.vehicle == 'string') {
                vehicleID = formValue.vehicle;
            } else {
                vehicleID = (formValue.vehicle as Vehicle).id;
            }
            Object.defineProperty(OS, "vehicle", {
                value: vehicleID,
                enumerable: true
            });
        }

        if (formValue.client) {
            let clientID;
            if (typeof formValue.client == 'string') {
                clientID = formValue.client;
            } else {
                clientID = (formValue.client as Client).id;
            }
            Object.defineProperty(OS, "client", {
                value: clientID,
                enumerable: true
            });
        }

        if (formValue.operation) {
            let operationID;
            if (typeof formValue.operation == 'string') {
                operationID = formValue.operation;
            } else {
                operationID = (formValue.operation as Operation).id;
            }

            Object.defineProperty(OS, "operation", {
                value: operationID,
                enumerable: true
            })
        }

        return OS
    }

    checkPartsValues(isMobile: boolean, services: {
        dialog: MatDialog,
        layoutService: LayoutService
    }, compareToZero = false): boolean {
        const partsWithAverageOrPurchaseCostHigherThanLiquidValueOrEqualZero: Array<{
            part: Part;
            liquidValue: number;
        }> = [];
        const { dialog, layoutService } = services;

        layoutService.addLoaderTrigger("checkPartsValues()");

        const registeredParts = this.availableParts.map(part => ({
            part: part.part,
            liquidValue: (part.liquidValue() / part.amount())
        }))

        registeredParts.forEach(part => {
            const { cost, averageCost } = part.part;
            const { liquidValue } = part;

            if ((cost > liquidValue) || (averageCost > liquidValue)) {
                partsWithAverageOrPurchaseCostHigherThanLiquidValueOrEqualZero.push(part);
            } else if (compareToZero && ((cost === 0) || averageCost === 0)) {
                partsWithAverageOrPurchaseCostHigherThanLiquidValueOrEqualZero.push(part);
            };
        });


        if (partsWithAverageOrPurchaseCostHigherThanLiquidValueOrEqualZero.length) {
            layoutService.removeLoaderTrigger("checkPartsValues()");

            dialog.open(AveragePurchaseCostAlertComponent, {
                data: {
                    parts: partsWithAverageOrPurchaseCostHigherThanLiquidValueOrEqualZero,
                    isMobile: isMobile,
                },
                minWidth: isMobile ? "90vw" : "30rem",
                minHeight: isMobile ? "90vh" : "25rem",
                maxWidth: isMobile ? "95vw" : "",
                maxHeight: isMobile ? "95vh" : "",
            });
        };

        layoutService.removeLoaderTrigger("checkPartsValues()");
        return partsWithAverageOrPurchaseCostHigherThanLiquidValueOrEqualZero.length > 0;
    }


    // OK
    redistributeDiscountParts() {
        let propDiscounts: { part?: MkgOSPart, partTmp?: RoPartTemp, discountProp: number }[] = [];
        for (const part of this.availableParts) {
            const partRatio = Utilities.getPercentage(part.liquid1(), this.getPartsLiquidValue1(), 10);
            const discountProp = Utilities.calcPercentage(partRatio, this.valueDiscountParts);
            propDiscounts.push({ part, discountProp });
        }
        for (const partTmp of this.availablePartsTMP) {
            const grossValue = Money(partTmp.saleValue * partTmp.amount);
            const partRatio = Utilities.getPercentage(grossValue, this.getPartsLiquidValue1(), 10);
            const discountProp = Utilities.calcPercentage(partRatio, this.valueDiscountParts);
            propDiscounts.push({ partTmp, discountProp });
        }
        if (propDiscounts.length) {
            Utilities.fixCentsDifference(propDiscounts, "discountProp", this.valueDiscountParts);
            for (const obj of propDiscounts) {
                if (obj.part) {
                    obj.part.discountProp.set(obj.discountProp);
                } else if (obj.partTmp) {
                    obj.partTmp._discountProp = obj.discountProp;
                }
            }
        }
    }

    redistributeDiscountLabors() {
        let propDiscounts: {
            labor?: RoLaborFull,
            laborTmp?: RoLaborTemp,
            thirdPartyService?: ThirdPartyService,
            discountProp: number
        }[] = [];
        const base = this.getLaborsLiquidValue1();
        for (const labor of this.availableLabors) {
            const laborRatio = Utilities.getPercentage((labor.amount * labor.saleValue) - labor.discountValue, base, 10);
            const discountProp = Utilities.calcPercentage(laborRatio, this.valueDiscountLabors);
            propDiscounts.push({ labor, discountProp });
        }
        for (const laborTmp of this.availableLaborsTMP) {
            const grossValue = Money(laborTmp.saleValue * laborTmp.amount);
            const laborRatio = Utilities.getPercentage(grossValue, base, 10);
            const discountProp = Utilities.calcPercentage(laborRatio, this.valueDiscountLabors);
            propDiscounts.push({ laborTmp, discountProp });
        }

        for (const thirdPartyService of this.thirdPartyServices) {
            const grossValue = Money(thirdPartyService.collectedValue);
            const laborRatio = Utilities.getPercentage(grossValue, base, 10);
            const discountProp = Utilities.calcPercentage(laborRatio, this.valueDiscountLabors);
            propDiscounts.push({ thirdPartyService, discountProp });
        }

        if (propDiscounts.length) {
            Utilities.fixCentsDifference(propDiscounts, "discountProp", this.valueDiscountLabors);
            for (const obj of propDiscounts) {
                if (obj.labor) {
                    obj.labor.discountProp = obj.discountProp;
                } else if (obj.thirdPartyService) {
                    obj.thirdPartyService.discountPropLabor = obj.discountProp;
                } else if (obj.laborTmp) {
                    obj.laborTmp._discountProp = obj.discountProp;
                }
            }
        }
    }

    redistributeDiscountGeral() {
        const partsLiquidValue2 = this.getPartsLiquidValue2();
        const laborsLiquidValue2 = this.getLaborsLiquidValue2();
        const base = partsLiquidValue2 + laborsLiquidValue2;
        let propDiscounts: {
            part?: MkgOSPart,
            partTmp?: RoPartTemp,
            labor?: RoLaborFull,
            laborTmp?: RoLaborTemp,
            thirdPartyService?: ThirdPartyService,
            discountPropGeral: number
        }[] = [];
        for (const part of this.availableParts) {
            const partRatio = Utilities.getPercentage(part.liquid2(), base, 10);
            const discountPropGeral = Utilities.calcPercentage(partRatio, this.valueDiscountOs);
            propDiscounts.push({ part, discountPropGeral });
        }
        for (const partTmp of this.availablePartsTMP) {
            const grossValue = Money(partTmp.saleValue * partTmp.amount);
            const liquid2 = Money(grossValue - partTmp._discountProp);
            const partRatio = Utilities.getPercentage(liquid2, base, 10);
            const discountPropGeral = Utilities.calcPercentage(partRatio, this.valueDiscountOs);
            propDiscounts.push({ partTmp, discountPropGeral });
        }
        for (const labor of this.availableLabors) {
            const liquid2 = this.getLaborLiquid2(labor);
            const laborRatio = Utilities.getPercentage(liquid2, base, 10);
            const discountPropGeral = Utilities.calcPercentage(laborRatio, this.valueDiscountOs);
            propDiscounts.push({ labor, discountPropGeral });
        }
        for (const laborTmp of this.availableLaborsTMP) {
            const grossValue = Money(laborTmp.saleValue * laborTmp.amount);
            const liquid2 = Money(grossValue - laborTmp._discountProp);
            const laborRatio = Utilities.getPercentage(liquid2, base, 10);
            const discountPropGeral = Utilities.calcPercentage(laborRatio, this.valueDiscountOs);
            propDiscounts.push({ laborTmp, discountPropGeral });
        }
        for (const thirdPartyService of this.thirdPartyServices) {
            const grossValue = Money(thirdPartyService.collectedValue);
            const liquid1 = Money(thirdPartyService.collectedValue - thirdPartyService.discountPropLabor)
            const laborRatio = Utilities.getPercentage(liquid1, base, 10);
            const discountPropGeral = Utilities.calcPercentage(laborRatio, this.valueDiscountOs);
            propDiscounts.push({ thirdPartyService, discountPropGeral });
        }

        if (propDiscounts.length) {
            Utilities.fixCentsDifference(propDiscounts, "discountPropGeral", this.valueDiscountOs);
            for (const obj of propDiscounts) {
                if (obj.part) {
                    obj.part.discountPropGeral.set(obj.discountPropGeral);
                } else if (obj.partTmp) {
                    obj.partTmp._discountPropGeral = obj.discountPropGeral;
                } else if (obj.labor) {
                    obj.labor.discountPropGeral = obj.discountPropGeral;
                } else if (obj.laborTmp) {
                    obj.laborTmp._discountPropGeral = obj.discountPropGeral;
                } else if (obj.thirdPartyService) {
                    obj.thirdPartyService.discountPropGeral = obj.discountPropGeral;
                }
            }
        }

    }

    setGeneralDiscountsToZero() {
        this.setValueDiscountLabors(0);
        this.setValueDiscountParts(0);
        this.setValueDiscountOs(0);
    }

    public recalculate(caller: string) {
        if (this.isEnded && caller !== "constructor") {
            return
        }
        // soma valor bruto peças
        this.grossValueParts = this._getGrossValueParts();
        // soma valor bruto serviços
        this.grossValueLabors = this._getGrossValueLabors();

        // dividir os discount prop de peças,
        this.redistributeDiscountParts();

        // dividir discount prop de serviços
        this.redistributeDiscountLabors();

        // dividir discount prop geral
        this.redistributeDiscountGeral();

        // soma valor liquido de peças
        for (const partTmp of this.availablePartsTMP) {
            partTmp._netValue = Money(partTmp.amount * partTmp.saleValue - partTmp._discountProp - partTmp._discountPropGeral);
        }

        const liquidValuePartsTmp = Money(this.availablePartsTMP.reduce((acc, part) => acc + part._netValue, 0));
        const liquidValueParts = Money(this.availableParts.reduce((acc, part) => acc + Money(part.liquidValue()), 0));
        this.liquidValueParts = Money(liquidValuePartsTmp + liquidValueParts);

        // soma valor liquido de serviços
        for (const roLabor of this.labors) {
            roLabor.liquidValue = (roLabor.amount * roLabor.saleValue) - roLabor.discountValue - roLabor.discountProp - roLabor.discountPropGeral;
        }

        for (const laborTmp of this.availableLaborsTMP) {
            laborTmp._netValue = Money(laborTmp.amount * laborTmp.saleValue - laborTmp._discountProp - laborTmp._discountPropGeral);
        }

        const liquidValueLaborsTmp = Money(this.availableLaborsTMP.reduce((acc, labor) => acc + labor._netValue, 0));
        const liquidValueLabors = Money(this.availableLabors.reduce((acc, labor) => acc + Money(labor.liquidValue), 0));
        const sumOutsourcedCollectedValue = this.thirdPartyServices.reduce((acc, out) => acc + out.collectedValue, 0);
        const sumOutsourcedDiscountLabors = this.thirdPartyServices.reduce((acc, out) => acc + out.discountPropLabor, 0);
        const sumOutsourcedDiscountGeral = this.thirdPartyServices.reduce((acc, out) => acc + out.discountPropGeral, 0);
        const outsourcedsLiquidValue = Money(sumOutsourcedCollectedValue - sumOutsourcedDiscountLabors - sumOutsourcedDiscountGeral);
        this.liquidValueLabors = Money(liquidValueLaborsTmp + liquidValueLabors + outsourcedsLiquidValue);

        // soma valor bruto da os
        this.grossValue = this.grossValueParts + this.grossValueLabors;
        // soma valor líquido da OS
        this.liquidValue = Money(this.liquidValueParts + this.liquidValueLabors);

        this._generalDiscount = Utilities.getPercentage(this._valueDiscountOs, this.sumDiscountValues.baseGeral(), 10);
        this._discountParts = Utilities.getPercentage(this._valueDiscountParts, this.getPartsLiquidValue1(), 10);
        this._discountLabors = Utilities.getPercentage(this._valueDiscountLabors, this.getLaborsLiquidValue1(), 10);
    }


    toAPI(): ApiOSPost {
        return {
            breakdowns: this.breakdowns,
            budgetCode: this.budgetCode,
            chat: this.chat,
            checklist: this.checklist,
            client: this.client.id,
            clientTmp: this.clientTmp,
            code: this.code,
            companyInfo: this.companyInfo,
            companyLogo: this.companyLogo,
            createdAt: this.createdAt,
            currentState: this.currentState,
            discountLabors: this.discountLabors,
            discountParts: this.discountParts,
            generalDiscount: this.generalDiscount,
            grossValue: this.grossValue,
            grossValueLabors: this.grossValueLabors,
            grossValueParts: this.grossValueParts,
            id: this.id,
            km: this.km,
            labors: this.labors as any,
            laborsTMP: this.laborsTMP,
            lastUpdate: this.lastUpdate,
            liquidValue: this.liquidValue,
            liquidValueLabors: this.liquidValueLabors,
            liquidValueParts: this.liquidValueParts,
            notes: this.notes,
            operation: this.operationId,
            parts: this.parts.slice().map(p => p.toAPI()),
            partsTMP: this.partsTMP,
            paymentConditions: this.paymentConditions,
            prisma: this.prisma,
            problem: this.problem,
            receipts: this.receipts,
            shortUrl: this.shortUrl,
            tags: this.tags,
            thirdPartyServices: this.thirdPartyServices,
            type: this.type.id,
            updatedAt: this.updatedAt,
            user: this.user,
            valueDiscountLabors: this.valueDiscountLabors,
            valueDiscountOs: this.valueDiscountOs,
            valueDiscountParts: this.valueDiscountParts,
            vehicle: this.vehicle?.id,
            vehicleTmp: this.vehicleTmp,
            importsBudget: this.importsBudget
        }
    }
}
