import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Part } from 'src/app/interface/part';

@Component({
  selector: 'app-average-purchase-cost-alert',
  templateUrl: './average-purchase-cost-alert.component.html',
  styleUrl: './average-purchase-cost-alert.component.scss'
})
export class AveragePurchaseCostAlertComponent {

  parts: Array<{
    part: Part;
    liquidValue: number;
  }>;
  isMobile: boolean;

  columns = ['code', 'description']
  constructor ( 
    @Inject(MAT_DIALOG_DATA) private data: {
      parts: Array<{
        part: Part;
        liquidValue: number;
      }>,
      isMobile: boolean;
    },
  ) {
    if (data) {
      this.parts = data.parts;
      this.isMobile = data.isMobile;
    }
  }
  
}
