import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, first, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { NfUtilities } from '../class/nf-utilities';
import { Utilities } from '../class/utilities';
import { IOrderItem } from '../component/page/company/order/interface/order-item.interface';
import { OrderFull } from '../component/page/company/order/model/order.model';
import { OrderService } from '../component/page/company/order/service/order.service';
import { StockController } from '../controllers/stock.controller';
import { NFe, detItem55 } from '../interface/NF-e';
import { NF_response } from '../interface/NF-response';
import { NFCe, detItem65 } from '../interface/NFC-e';
import { NFSe, RPS } from '../interface/NFS-e';
import { NFSE_response } from '../interface/NFSE-response';
import { City } from '../interface/city';
import { Client } from '../interface/client';
import { Company } from '../interface/company';
import { ImportedInvoice } from '../interface/imported-invoice';
import { INVOICE_EVENT_CODES, InvoiceEvent } from '../interface/invoice-event';
import { ITEM_MODIFY, ItemResponse } from '../interface/item-response';
import { Labor } from '../interface/labor';
import { NFSE_item } from '../interface/nfse-item';
import { Parcela } from '../interface/nfse-parcel';
import { Operation } from '../interface/operation';
import { pagItem } from '../interface/pag-item';
import { Part } from '../interface/part';
import { PartGroup } from '../interface/part-group';
import { PaymentCondition } from '../interface/paymentCondition';
import { Finance } from '../interface/ro-finance';
import { RoLaborFull } from '../interface/ro-labor-full';
import { RoPartFull } from '../interface/ro-part-full';
import { Supplier } from '../interface/supplier';
import { ANP_CODES } from '../shared/lists/ANP_codes';
import { CFOP_list } from '../shared/lists/cfop';
import { COUNTRIES } from '../shared/lists/country-codes';
import { DOCUMENT_TYPE_CODES } from '../shared/lists/document-type';
import { API_ERRORS } from '../shared/lists/errors';
import { INVOICE_STATE } from '../shared/lists/invoice-state';
import { PAYMENT_METHODS } from '../shared/lists/payment-methods';
import { UTRIB_BY_NCM } from '../shared/lists/utrib-by-ncm';
import { InvoicePropertyPipe } from '../shared/pipes/invoice-property.pipe';
import { AddressService } from './address.service';
import { CashTitleService } from './cash-title.service';
import { ClientService } from './client.service';
import { CompanyService } from './company.service';
import { DataService } from './data.service';
import { GroupService } from './group.service';
import { LaborService } from './labor.service';
import { LayoutService } from './layout.service';
import { MovementService } from './movement.service';
import { OperationService } from './operation.service';
import { PartService } from './part.service';
import { PaymentService } from './payment.service';
import { RoService } from './ro.service';
import { SnackbarService } from './snackbar.service';
import { SupplierService } from './supplier.service';
import { UserService } from './user.service';
import { VehicleService } from './vehicle.service';
import { firstValueFrom, of } from 'rxjs';
import { RoTypeService } from './ro-type.service';
import moment from 'moment';
import { MigrateCommonService } from './migrate-common.service';
import { Farm } from '../interface/farm';
import { ObjectId } from '../shared/type-aliases/object-id';
import { LOG_REPORTER_KEYS, LogReporterService } from './log-reporter.service';
import { IOsClient, IOSOperation, MkgOS, OsLaborPopulate, TaxesParamsOS } from '../class/mkg-os';
import { AskRedirectComponentDataType } from '../component/dialog/ask-redirect/ask-redirect.component';
import { NcmObservationTrib } from '../interface/ncm-observation-trib';
import { partStates } from '../shared/lists/part-states';
import { laborStates } from '../shared/lists/labor-states';
import { MkgOSPart } from '../class/mkg-os-part';
import { Money } from '../class/money';
import { SessionOSservice } from './session/session-os.service';
import { IbptImportService } from './ibpt-import.service';
import { ReduceSumPipe } from '../shared/pipes/reduce-sum.pipe';
import { CNAE } from "t5-common";
import { BoletoModuleActivationStatus } from '../interface/boleto-pjbank';

export interface TaxesParams {
  client: Client;
  operation: Operation;
  ItemBaseCalculo: number;
  labor?: OsLaborPopulate;
}


const DEFAULT_PAG: pagItem[] = [{
  indPag_pag: "0",
  tPag: PAYMENT_METHODS['money'],
  vPag: 0
}];

export enum SEFAZ_CODES {
  // Autorizado o Uso
  AUTORIZADO = 100,

  /** Cancelamento homologado */
  CANCELADO = 101,

  /** Inutilização de número homologado  */
  INUTILIZADO = 102,

  /** Documento pendente  */
  PENDENTE = 105,

  /** documento não consta na base de dados do InvoiCy, caso queira enviá-lo, preencha todas as informações do evento  */
  INEXISTENTE = 106,

  /** Documento pendente em contingência  */
  CONTINGENCIA = 108,

  /** Documento está rejeitado, não é possível enviar o evento  */
  REJEITADO = 112,

  /** Documento está com status [Descrição Status]. O evento será enviado automaticamente quando o documento obtiver um status final  */
  AGUARDANDO = 113,

  /** Evento autorizado e vinculado ao documento  */
  ALTERADO = 135, // carta de correção OK

  DENEGADA = 205,

  /** Uso Denegado : Irregularidade fiscal do destinatario */
  DEVOLUCAO_DENEGADA = 302
};

@Injectable({
  providedIn: 'root'
})
export class MigrateService {
  readonly NO_ACCESS_KEY = "_SEM_CHAVE_DE_ACESSO";
  readonly URL = environment.migrate.URL;

  groupMap = new Map<string, PartGroup>();
  conditionsMap = new Map<string, PaymentCondition>();
  private _http = inject(HttpClient);
  private _dataService = inject(DataService);
  private _roService = inject(RoService);
  private _clientService = inject(ClientService);
  private _addressService = inject(AddressService);
  private _partService = inject(PartService);
  private _laborService = inject(LaborService);
  private _operationService = inject(OperationService);
  private _layout = inject(LayoutService);
  private _companyService = inject(CompanyService);
  private _snackbar = inject(SnackbarService);
  private _cashTitleService = inject(CashTitleService);
  private _stockController = inject(StockController);
  private _movementService = inject(MovementService);
  private _invoicePropertyPipe = inject(InvoicePropertyPipe);
  private _vehicleService = inject(VehicleService);
  private _userService = inject(UserService);
  private _supplierService = inject(SupplierService);
  private _orderService = inject(OrderService);
  private _paymentService = inject(PaymentService);
  private _groupService = inject(GroupService);
  private _roTypeService = inject(RoTypeService);
  private _migrateCommonService = inject(MigrateCommonService);
  private _logReporter = inject(LogReporterService);
  private _sessionOSservice = inject(SessionOSservice);
  private _ibptImportService = inject(IbptImportService);
  private boletoTitles: string[] = [];

  public getDate(date = new Date()) {
    const [year, month, day, hour, minutes, seconds] = [
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    ].map(String).map(s => s.padStart(2, '0'));
    return `${year}-${month}-${day}T${hour}:${minutes}:${seconds}`;
  }

  public getFuso() {
    let resp = "";
    const hours = new Date().getTimezoneOffset() / 60 * -1;
    if (hours < 0) {
      resp += '-';
    }
    resp += Math.abs(hours).toString().padStart(2, '0');
    resp += ':00';
    return resp;
  }

  getErrors(obj: NF_response | NFSE_response, isNFSE: boolean, ignoreMigrate?: boolean) {
    return this._migrateCommonService.getErrors(obj, isNFSE);
  }

  public getErrorsOfConsult(consult: any) {
    let migrateCode, migrateDescription, sefazCode, sefazDescription;
    // read consult
    if (consult.Codigo) {
      migrateCode = consult.Codigo;
      migrateDescription = consult.Descricao;
    }
    if (consult.Documentos && consult.Documentos[0]) {
      const doc = consult.Documentos[0];
      if (doc.DocSitCodigo) {
        sefazCode = doc.DocSitCodigo;
        sefazDescription = doc.DocSitDescricao;
      }
    }
    if ((!migrateCode || migrateCode === 100) && sefazCode === SEFAZ_CODES.AUTORIZADO) {
      return null;
    }

    return {
      migrateCode: migrateCode,
      migrateDescription: migrateDescription,
      sefazCode: sefazCode,
      sefazDescription: sefazDescription
    };
  }

  public getItemsToUpdate(invoice: NF_response | NFSE_response, items: (MkgOSPart | RoLaborFull | IOrderItem)[]): ItemResponse[] {
    let resp: ItemResponse[] = [];
    for (const item of items) {
      let ref: ItemResponse;
      if (invoice.items) {
        ref = invoice.items.find(respItem => respItem.keyId === item.id);
      }
      if (ref) {
        const code = (item as MkgOSPart).part ? (item as MkgOSPart).part.code : (item as RoLaborFull).labor.code;
        const itemResponse: ItemResponse = {
          keyId: item.id,
          code: code.toString(),
          amount: ref.amount,
          modify: (ref ? ref.modify : ITEM_MODIFY.not_modified)
        };
        if (invoice.request.Documento.ModeloDocumento !== 'NFSE') {
          if (Number.isFinite((item as MkgOSPart).purchaseAverage)) {
            itemResponse.averageCost = (item as MkgOSPart).purchaseAverage;
          }
          if (Number.isFinite((item as MkgOSPart).purchaseValue)) {
            itemResponse.lastCost = (item as MkgOSPart).purchaseValue;
          }
        }
        resp.push(itemResponse);
      }
    }
    return resp;
  }

  public getItemsToRegister(items: (MkgOSPart | RoLaborFull)[]): ItemResponse[] {
    let resp: ItemResponse[] = [];
    for (const item of items) {
      const part: Partial<Part> = (item as MkgOSPart).part;
      if (part) {
        const rItem: ItemResponse = {
          keyId: part.id,
          code: part.code.toString(),
          amount: typeof item.amount === 'number' ? item.amount : item.amount(),
          modify: ITEM_MODIFY.not_modified,
        };
        if (Number.isFinite((item as RoPartFull).purchaseAverage)) {
          rItem.averageCost = (item as RoPartFull).purchaseAverage;
        }
        if (Number.isFinite((item as RoPartFull).purchaseValue)) {
          rItem.lastCost = (item as RoPartFull).purchaseValue;
        }
        resp.push(rItem);
      } else if ((item as RoLaborFull).labor) {
        resp.push({
          keyId: (item as RoLaborFull).labor.id,
          code: (item as RoLaborFull).labor.code.toString(),
          amount: typeof item.amount === "number" ? item.amount : item.amount(),
          modify: ITEM_MODIFY.not_modified
        });
      }
    }
    return resp;
  }

  private getPagsOfOS(OS: MkgOS, invoiceType: 'nf' | 'nfse'): (pagItem | Parcela)[] {
    let payments = OS.paymentConditions.sort((p1, p2) => {
      const p1Condition = (typeof p1.paymentCondition === "string" ? this.conditionsMap.get(p1.paymentCondition) : p1.paymentCondition as PaymentCondition);
      const p2Condition = (typeof p2.paymentCondition === "string" ? this.conditionsMap.get(p2.paymentCondition) : p2.paymentCondition as PaymentCondition);
      return p1Condition && p2Condition ? Number(p1Condition.form) - Number(p2Condition.form) : 0;
    });

    const fullValue = payments.reduce((acc, finance) => acc + finance.value, 0);

    if (invoiceType === 'nf') {
      let pags: pagItem[] = [];
      for (const payment of payments) {
        const condition = (typeof payment.paymentCondition === "string" ? this.conditionsMap.get(payment.paymentCondition) : payment.paymentCondition as PaymentCondition);
        const conditionPercentage = Utilities.getPercentage(payment.value, fullValue, 10);
        const conditionValue = Utilities.calcPercentage(conditionPercentage, OS.liquidValueParts);

        let pag: pagItem = {
          tPag: condition.form,
          vPag: Money(conditionValue),
          indPag_pag: condition && condition.form === PAYMENT_METHODS.money ? "0" : "1",
        };
        if (pag.tPag === PAYMENT_METHODS.other) {
          pag['xPag'] = condition ? condition.description : '';
        } else if ([
          PAYMENT_METHODS.credit,
          PAYMENT_METHODS.debit,
          PAYMENT_METHODS.dynamicPix
        ].includes(`${pag.tPag}` as PAYMENT_METHODS)) {
          // pag['card'] = payment.cardInfo;
          pag.card = {
            tipoIntegracao: 2
          }
        }

        pags.push(pag);
      }

      if (!pags.length) {
        console.warn('Verifique as condições de pagamento para essa OS');
      }
      return pags;
    } else {
      let parcels: Parcela[] = [];
      for (const payment of payments) {
        let conditionParcels: Parcela[] = [];
        const condition = (typeof payment.paymentCondition === "string" ? this.conditionsMap.get(payment.paymentCondition) : payment.paymentCondition as PaymentCondition);
        const conditionPercentage = Utilities.getPercentage(payment.value, fullValue, 10);
        const conditionValue = Utilities.calcPercentage(conditionPercentage, OS.liquidValueLabors);

        let paymentParcels = condition.parcels;

        if (condition.form === PAYMENT_METHODS.other && payment.jsonAny) { // 999
          paymentParcels = JSON.parse(payment.jsonAny) as PaymentCondition['parcels'];
        }

        // calculate the percentage of total this payment represents
        for (const paymentParcel of paymentParcels) {
          const parcelValue = Utilities.calcPercentage(paymentParcel.percentage, conditionValue);
          if (!paymentParcel.expirationDate) {
            const expirationDate = this._cashTitleService.getExpirationDate(paymentParcel.days, new Date());
            paymentParcel.expirationDate = moment(expirationDate).format("yyyy-MM-DD");
          }

          let parcel: Parcela = {
            PrcValor: parcelValue,
            PrcValLiquido: parcelValue,
            PrcValDesconto: 0,
            PrcSequencial: paymentParcel.seq,
            PrcDtaVencimento: paymentParcel.expirationDate
          };
          conditionParcels.push(parcel);
        }
        Utilities.fixCentsDifference(conditionParcels, "PrcValor", conditionValue);
        Utilities.fixCentsDifference(conditionParcels, "PrcValLiquido", conditionValue);
        parcels = parcels.concat(conditionParcels);
      }

      Utilities.fixCentsDifference(parcels, "PrcValLiquido", OS.liquidValueLabors);
      Utilities.fixCentsDifference(parcels, "PrcValor", OS.liquidValueLabors);

      return parcels;
    }
  }

  private getPagsOfOrder(order: OrderFull, invoiceType: 'nf' | 'nfse'): pagItem[] {

    let payments: Partial<Finance>[] = order.finances.slice();
    payments = payments.sort((p1, p2) => Number(p1.paymentCondition.form) - Number(p2.paymentCondition.form))

    if (invoiceType === 'nf') {
      let pags: pagItem[] = [];
      pags = payments.map((payment: Finance) => {
        let pag: pagItem = {
          tPag: payment.paymentCondition.form,
          vPag: Money(payment.value),
          indPag_pag: PAYMENT_METHODS.money === payment.paymentCondition.form ? "0" : "1",
        };
        if (pag.tPag === PAYMENT_METHODS.other) {
          pag['xPag'] = payment.paymentCondition.description;
        } else if ([
          PAYMENT_METHODS.credit,
          PAYMENT_METHODS.debit,
          PAYMENT_METHODS.dynamicPix
        ].includes(`${pag.tPag}` as PAYMENT_METHODS)) {
          // pag['card'] = payment.cardInfo;
          pag.card = {
            tipoIntegracao: 2
          }
        }
        return pag;
      })

      if (!pags.length) {
        console.warn('Verifique as condições de pagamento para essa OS');
      }

      return pags;
    }
    return [];
  }

  public async getDest(person: Client | Supplier, CPF?: string, farmId?: ObjectId): Promise<NFe["dest"]> {
    let destAddress = person.address;
    let selectedFarm: Farm;
    if ((person.documentType === DOCUMENT_TYPE_CODES.rural) && farmId) {
      selectedFarm = (person as Client).farm.find(f => f._id === farmId);
      destAddress = selectedFarm.address;
    }
    const clientState = await this._addressService.getState(destAddress.state);
    const clientCity = await this._addressService.getCity(destAddress.city, destAddress.state);

    let enderDest: NFCe["dest"]["enderDest"] = {
      UF_dest: clientState ? clientState.code : "",
      cMun_dest: clientCity ? Number(clientCity.igbe) : undefined,
      xBairro_dest: destAddress.neighborhood,
      nro_dest: destAddress.number,
      xLgr_dest: destAddress.street,
      xMun_dest: clientCity ? clientCity.name : "",
      xPais_dest: "BRASIL",
      cPais_dest: 1058
    };

    if (destAddress.cep) {
      enderDest.CEP_dest = Number((destAddress.cep || '').replace(/\D/g, ''));
    }
    if (person.phone1) {
      enderDest.fone_dest = Number((person.phone1 || '').replace(/\D/g, ''));
    }
    if (destAddress.complement) {
      enderDest.xCpl_dest = destAddress.complement;
    }
    if (person.email) {
      enderDest.xEmail_dest = person.email;
    }

    let dest: NFe["dest"] = {
      xNome_dest: person.name,
      enderDest: enderDest,
      indIEDest: 9
    };

    switch (person.documentType) {
      case DOCUMENT_TYPE_CODES.rural:
        if (selectedFarm) {
          dest.CPF_dest = CPF || person.document;
          dest.IE_dest = (person as Client)?.contribICMS ? person.rg : selectedFarm.inscRural;
          dest.indIEDest = 1;
        }
        break;
      case DOCUMENT_TYPE_CODES.juridic:
        if (person.rg) {
          dest.CNPJ_dest = person.document;
          dest.indIEDest = 1;
          dest.IE_dest = person.rg;
        } else {
          dest.CNPJ_dest = person.document;
          dest.indIEDest = 9; // ISENTO
        }
        break;
      case DOCUMENT_TYPE_CODES.foreign:
        const country = COUNTRIES.find(c => c.code == person.address.cep);
        dest.idEstrangeiro = CPF || person.document
        dest.indIEDest = 9;
        dest.enderDest = {
          xLgr_dest: "EXTERIOR",
          nro_dest: "1",
          xBairro_dest: "EXTERIOR",
          cMun_dest: 9999999,
          xMun_dest: "EXTERIOR",
          UF_dest: "EX",
          CEP_dest: 99999998,
          cPais_dest: Number(country.code),
          xPais_dest: country.name,
        };
        if (person.phone1) {
          dest.enderDest.fone_dest = Number((person.phone1 || '').replace(/\D/g, ''));
        }
        break;
      case DOCUMENT_TYPE_CODES.physical:
        dest.CPF_dest = Utilities.removeMask(CPF || person.document);
        dest.indIEDest = 9;
        break;
      default:
        dest.CPF_dest = Utilities.removeMask(CPF || person.document);
        dest.indIEDest = 9;
        console.warn(`O destinatário "${person.name}" não possui um tipo de documento vinculado (Pessoa física, Pessoa Jurídica, Estrangeiro).\nPor favor atualize o cadastro assim que possível.`)
        console.log(`${location.protocol}//${location.host}/company/${this._dataService.company.id}/person/client/${person.id}`, '\n\n');
        break;
    }

    // not send documnet tag if all digits are equals
    if (dest.CPF_dest && this.areAllDigitsEquals(dest.CPF_dest)) {
      console.warn('Destinatário não informado');
      return null;
    }
    if (dest.CNPJ_dest && this.areAllDigitsEquals(dest.CNPJ_dest)) {
      console.warn('Destinatário não informado');
      return null;
    }

    return dest;
  }

  private areAllDigitsEquals(value: string | number): boolean {
    const str = Utilities.removeMask(`${value}`);
    const arr = str.split('');
    return arr.every(char => char === arr[0]);
  }

  private getEmit(company: Company, companyCity: City) {
    const state = this._addressService.getState(company.address.state);
    return {
      xNome: company.socialName,
      xFant: company.fancyName || company.socialName,
      CNPJ_emit: company.cnpj.replace(/\D/g, ''),
      CRT: Number(this._dataService.company.crt),
      IE: company.stateRegistration,
      enderEmit: {
        CEP: Number(company.address?.cep?.replace(/\D/g, '')),
        UF: state ? state.code : '',
        cMun: Number(companyCity?.igbe),
        nro: company.address.number,
        xBairro: company.address.neighborhood,
        xLgr: company.address.street,
        xMun: companyCity?.name ?? '',
        cPais: 1058,
        xPais: "BRASIL",
        Email: this._dataService.company.email,
        fone: Number(Utilities.removeMask(this._dataService.company.phone))
      }
    };
  }

  public getNtipoItemOf(part: Part): detItem65["prod"]['nTipoItem'] {
    return (part.groupObject ? ([2, 3].includes(part.groupObject.type) ? 4/** combustível/lubrificante */ : 0) : 0)
  }

  static getValMinRet(parts: Part[], operation: Operation, key: "PIS" | "COFINS" | "CSLL" | "IRRF" | "INSS"): number {
    let valMinRet = 0;

    if (parts) {
      for (const part of parts) {
        if (part[key] && part[key].valMinRet as any) {
          valMinRet += part[key].valMinRet;
        }
      }
    } else {
      if (operation[key] && operation[key].valMinRet as any) {
        valMinRet += operation[key].valMinRet;
      }
    }


    return valMinRet;
  }

  static getRetTrib(invoiceValue: number, operation: Operation, client: Client, parts?: MkgOSPart[] | Part[]): NFe['total']['retTrib'] {
    const retTrib: NFe['total']['retTrib'] = {};
    const partArray: Part[] = (parts || []).reduce((arr: Part[], roPart) => {
      if (roPart instanceof MkgOSPart) {
        return arr.concat(roPart.part);
      }
      return arr.concat(roPart)
    }, []);


    if (client && Number.isFinite(invoiceValue)) {

      // calculate PIS retention
      if (client.retPIS) {
        const aliquot = operation.PIS?.Aliquot || 0;
        const valMin = this.getValMinRet(partArray, operation, 'PIS');
        if (aliquot && invoiceValue >= valMin) {
          retTrib.vRetPIS = Money(Utilities.calcPercentage(aliquot, invoiceValue));
        }
      }

      // calculate COFINS retention
      if (client.retCOFINS) {
        const aliquot = operation.COFINS?.Aliquot || 0;
        const valMin = this.getValMinRet(partArray, operation, 'COFINS');
        if (aliquot && invoiceValue >= valMin) {
          retTrib.vRetCOFINS_servttlnfe = Money(Utilities.calcPercentage(aliquot, invoiceValue));
        }
      }

      // calculate CSLL retention
      if (client.retCSLL) {
        const aliquot = operation.CSLL?.aliquot || 0;
        const valMin = this.getValMinRet(partArray, operation, 'CSLL');
        if (aliquot && invoiceValue >= valMin) {
          retTrib.vRetCSLL = Money(Utilities.calcPercentage(aliquot, invoiceValue));
        }
      }

      // calculate IRRF retention
      if (client.retIRPJ) {
        const aliquot = operation.IRPJ?.aliquotPart || 0;
        const valMin = this.getValMinRet(partArray, operation, 'IRRF');
        if (aliquot && invoiceValue >= valMin) {
          retTrib.vBCIRRF = invoiceValue;
          retTrib.vIRRF = Money(Utilities.calcPercentage(aliquot, retTrib.vBCIRRF));
        }
      }

      // calculate INSS retention
      if (client.retINSS) {
        const aliquot = operation.INSS?.aliquot || 0;
        const valMin = this.getValMinRet(partArray, operation, "INSS");
        if (aliquot && invoiceValue >= valMin) {
          retTrib.vBCRetPrev = invoiceValue;
          retTrib.vRetPrev = Money(Utilities.calcPercentage(aliquot, retTrib.vBCRetPrev));
        }
      }
    }

    return retTrib;
  }

  static getISStaxes({ company, ItemBaseCalculo }: { company: Company, ItemBaseCalculo: number }) {

    // define item ISS
    let ItemAliquota: NFSE_item['ItemAliquota'] = 0;
    let ItemvIss: NFSE_item['ItemvIss'] = 0;

    const aliquot = Number(company.ISS) || 0;
    if (aliquot) {
      ItemAliquota = aliquot;
      ItemvIss = Money(ItemBaseCalculo * aliquot / 100);
    }

    return {
      ItemAliquota,
      ItemvIss,
      ItemBaseCalculo
    }

  }

  static getPIStaxes({ operation, ItemBaseCalculo }: { client: Client | IOsClient, operation: Operation | IOSOperation, ItemBaseCalculo: number }) {
    let ItemValAliqPIS: NFSE_item['ItemValAliqPIS'];
    let ItemValPIS: NFSE_item['ItemValPIS'];
    let ItemValBCPIS: NFSE_item['ItemValBCPIS'];
    const aliquot = operation.PIS?.Aliquot || 0;
    if (aliquot) {
      ItemValAliqPIS = aliquot;
      ItemValPIS = Money(ItemBaseCalculo * aliquot / 100);
      ItemValBCPIS = ItemBaseCalculo;
    }

    return {
      ItemValAliqPIS,
      ItemValPIS,
      ItemValBCPIS
    }

  }

  static getCOFINStaxes({ operation, ItemBaseCalculo }: TaxesParams | TaxesParamsOS) {
    let ItemValAliqCOFINS: NFSE_item['ItemValAliqCOFINS'];
    let ItemValCOFINS: NFSE_item['ItemValCOFINS'];
    let ItemValBCCOFINS: NFSE_item['ItemValBCCOFINS'];

    const aliquot = operation.COFINS?.Aliquot || 0;
    if (aliquot) {
      ItemValAliqCOFINS = aliquot;
      ItemValCOFINS = Money(ItemBaseCalculo * aliquot / 100);
      ItemValBCCOFINS = ItemBaseCalculo;
    }

    return {
      ItemValAliqCOFINS,
      ItemValCOFINS,
      ItemValBCCOFINS
    }

  }

  static getCSLLtaxes({ operation, ItemBaseCalculo, labor }: TaxesParams | TaxesParamsOS) {
    let ItemValAliqCSLL: NFSE_item['ItemValAliqCSLL'];
    let ItemValCSLL: NFSE_item['ItemValCSLL'];
    let ItemValBCCSLL: NFSE_item['ItemValBCCSLL'];

    const aliquot = labor?.CSLL?.aliquot || operation.CSLL?.aliquot || 0
    if (aliquot) {
      ItemValAliqCSLL = aliquot;
      ItemValCSLL = Money(ItemBaseCalculo * aliquot / 100);
      ItemValBCCSLL = ItemBaseCalculo;
    }

    return {
      ItemValAliqCSLL,
      ItemValCSLL,
      ItemValBCCSLL
    }

  }

  static getIRtaxes({ operation, ItemBaseCalculo, labor }: TaxesParams | TaxesParamsOS) {
    let ItemValAliqIR: NFSE_item['ItemValAliqIR'];
    let ItemValIR: NFSE_item['ItemValIR'];
    let ItemValBCIRRF: NFSE_item['ItemValBCIRRF'];

    const aliquot = labor?.IRPJ?.aliquotLabor || operation.IRPJ?.aliquotLabor || 0;
    if (aliquot) {
      ItemValAliqIR = aliquot;
      ItemValIR = Money(ItemBaseCalculo * aliquot / 100);
      ItemValBCIRRF = ItemBaseCalculo;
    }

    return {
      ItemValAliqIR,
      ItemValIR,
      ItemValBCIRRF
    }

  }

  static getINSStaxes({ operation, ItemBaseCalculo, labor }: TaxesParams | TaxesParamsOS) {
    let ItemValAliqINSS: NFSE_item['ItemValAliqINSS'];
    let ItemValINSS: NFSE_item['ItemValINSS'];
    let ItemvalBCINSS: NFSE_item['ItemvalBCINSS'];

    const aliquot = labor?.INSS?.aliquot || operation.INSS?.aliquot || 0;
    if (aliquot) {
      ItemValAliqINSS = aliquot;
      ItemValINSS = Money(ItemBaseCalculo * aliquot / 100);
      ItemvalBCINSS = ItemBaseCalculo;
    }

    return {
      ItemValAliqINSS,
      ItemValINSS,
      ItemvalBCINSS
    }

  }

  /**
   * Sum taxes of NFSe and modify
   *
   * Ensure the client param is taken via `ClientService.get()` instead `ClientService.getAll()`
   *
   * @param rps Will be modified with correct values
   *
   */
  static sumNFSeTaxes(rps: RPS, params: { labors: Labor[], operation: Operation, company: Company, client?: Client }) {
    const reducer = new ReduceSumPipe();
    const { labors, client, operation, company } = params;

    for (let item of rps.ListaItens) {

      /** can be undefined (thirdPartyServices)  */
      const labor = labors.find(l => l.code === item.ItemCod);

      const taxesParams: TaxesParams = {
        client,
        ItemBaseCalculo: item.ItemBaseCalculo,
        operation,
        labor
      }

      // define item ISS
      const issTaxes = MigrateService.getISStaxes({ ...taxesParams, company });
      for (const key in issTaxes) {
        const value = issTaxes[key];
        if (value !== undefined) {
          item[key] = value;
        }
      }

      // define item PIS
      const pisTaxes = MigrateService.getPIStaxes(taxesParams);
      for (const key in pisTaxes) {
        const value = pisTaxes[key];
        if (value !== undefined) {
          item[key] = value;
        }
      }


      // define item COFINS
      const cofinsTaxes = MigrateService.getCOFINStaxes(taxesParams);
      for (const key in cofinsTaxes) {
        const value = cofinsTaxes[key];
        if (value !== undefined) {
          item[key] = value;
        }
      }


      // define item CSLL
      const csllTaxes = MigrateService.getCSLLtaxes(taxesParams)
      for (const key in csllTaxes) {
        const value = csllTaxes[key];
        if (value !== undefined) {
          item[key] = value;
        }
      }


      // define item IR
      const irTaxes = MigrateService.getIRtaxes(taxesParams);
      for (const key in irTaxes) {
        const value = irTaxes[key];
        if (value !== undefined) {
          item[key] = value;
        }
      }


      // define item INSS
      const inssTaxes = MigrateService.getINSStaxes(taxesParams);
      for (const key in inssTaxes) {
        const value = inssTaxes[key];
        if (value !== undefined) {
          item[key] = value;
        }
      }
    }

    // define general ISS
    const ValISS = Money(reducer.transform(rps.ListaItens, "ItemvIss"));
    if (ValISS) {
      rps.Servico.Valores.ValISS = ValISS;
      rps.Servico.Valores.ValAliqISS = company.ISS;
      rps.Servico.Valores.ValBaseCalculo = Money(reducer.transform(rps.ListaItens, "ItemBaseCalculo"));

      // define ISS retentions of items
      for (const item of rps.ListaItens) {
        if (client?.taxpayer) {
          if (rps.Servico.Valores.ValBaseCalculo >= (company.minISS || 0)) {
            item.ItemIssRetido = 1;
            item.ItemvlrISSRetido = item.ItemvIss;
            switch (company.munIncidISS) {
              case 'Cliente':
                item.ItemRespRetencao = 1;
                break;
              case 'Prestador':
                item.ItemRespRetencao = 3;
                break;
              default:
                item.ItemRespRetencao = company.patternNFSE === "IPM" ? 1 : undefined;
                break;
            }
          }
        } else {
          item.ItemvlrISSRetido = 0;
          item.ItemRespRetencao = 1;
          item.ItemIssRetido = 2;
        }
      }

      // define general ISS retentions
      const ValISSRetido = Money(reducer.transform(rps.ListaItens, "ItemvlrISSRetido"));
      if (ValISSRetido) {
        rps.Servico.Valores.ISSRetido = 1;
        rps.Servico.Valores.ValISSRetido = ValISSRetido;
        if (company.patternNFSE == "INFISC") {
          rps.Servico.Valores.ValBCISSRetido = Money(reducer.transform(rps.ListaItens, "ItemBaseCalculo"));
        }
      } else {
        rps.Servico.Valores.ISSRetido = 2;
      }
    }

    // define general PIS
    const ValPIS = Money(reducer.transform(rps.ListaItens, "ItemValPIS"));
    if (ValPIS) {
      rps.Servico.Valores.ValPIS = ValPIS;
      rps.Servico.Valores.ValBCPIS = Money(reducer.transform(rps.ListaItens, "ItemValBCPIS"));
      if (client.retPIS && rps.Servico.Valores.ValBCPIS >= operation.PIS?.valMinRet) {
        rps.Servico.Valores.PISRetido = 1;
      } else {
        rps.Servico.Valores.PISRetido = 2;
      }
    }

    // define general COFINS
    const ValCOFINS = Money(reducer.transform(rps.ListaItens, "ItemValCOFINS"));
    if (ValCOFINS) {
      rps.Servico.Valores.ValCOFINS = ValCOFINS;
      rps.Servico.Valores.ValBCCOFINS = Money(reducer.transform(rps.ListaItens, "ItemValBCCOFINS"));
      if (client?.retCOFINS && rps.Servico.Valores.ValBCCOFINS >= operation.COFINS?.valMinRet) {
        rps.Servico.Valores.COFINSRetido = 1;
      } else {
        rps.Servico.Valores.COFINSRetido = 2;
      }
    }

    // define general INSS
    const ValINSS = Money(reducer.transform(rps.ListaItens, "ItemValINSS"));
    if (ValINSS) {
      rps.Servico.Valores.ValINSS = ValINSS;
      rps.Servico.Valores.ValBCINSS = Money(reducer.transform(rps.ListaItens, "ItemvalBCINSS"));
      if (client?.retINSS && rps.Servico.Valores.ValBCINSS >= operation.INSS?.valMinRet) {
        rps.Servico.Valores.INSSRetido = 1;
      } else {
        rps.Servico.Valores.INSSRetido = 2;
      }
    }

    // define general CSLL
    const ValCSLL = Money(reducer.transform(rps.ListaItens, "ItemValCSLL"));
    if (ValCSLL) {
      rps.Servico.Valores.ValCSLL = ValCSLL;
      rps.Servico.Valores.ValBCCSLL = Money(reducer.transform(rps.ListaItens, "ItemValBCCSLL"));
      if (client?.retCSLL && rps.Servico.Valores.ValBCCSLL >= operation?.CSLL.valMinRet) {
        rps.Servico.Valores.CSLLRetido = 1;
      } else {
        rps.Servico.Valores.CSLLRetido = 2;
      }
    }

    // define general IR
    const ValIR = Money(reducer.transform(rps.ListaItens, "ItemValIR"));
    if (ValIR) {
      rps.Servico.Valores.ValIR = ValIR;
      rps.Servico.Valores.ValBCIRRF = Money(reducer.transform(rps.ListaItens, "ItemValBCIRRF"));
      if (client?.retIRPJ && rps.Servico.Valores.ValBCIRRF >= operation.IRPJ.valMinRet) {
        rps.Servico.Valores.IRRetido = 1;
      } else {
        rps.Servico.Valores.IRRetido = 2;
      }
    }

    const retentions = MigrateService._applyDeductions(rps, company);

    return retentions;

  }

  /** reduce retentions from invoice value (DEDUCTION) */
  private static _applyDeductions(rps: RPS, company: Company) {
    const valores = rps.Servico.Valores; // alias
    let invoiceValue = valores.ValServicos;
    const federalRetentions = {
      totalPISRetido: 0,
      totalCOFINSRetido: 0,
      totalINSSRetido: 0,
      totalIRRetido: 0,
      totalCSLLRetido: 0,
    }
    let totalFederalRetentions = 0;

    if (company.deduISS && [1, 3].includes(valores.ISSRetido)) {
      invoiceValue = Money(invoiceValue - (valores.ValISSRetido || 0));
    }

    if (company.deduNFSE) {
      if (valores.PISRetido == 1) {
        federalRetentions.totalPISRetido = valores.ValPIS || 0;
        invoiceValue = Money(invoiceValue - federalRetentions.totalPISRetido);
        totalFederalRetentions = Money(totalFederalRetentions + federalRetentions.totalPISRetido);
      }
      if (valores.COFINSRetido == 1) {
        federalRetentions.totalCOFINSRetido = valores.ValCOFINS || 0;
        invoiceValue = Money(invoiceValue - federalRetentions.totalCOFINSRetido);
        totalFederalRetentions = Money(totalFederalRetentions + federalRetentions.totalCOFINSRetido);
      }
      if (valores.INSSRetido == 1) {
        federalRetentions.totalINSSRetido = valores.ValINSS || 0;
        invoiceValue = Money(invoiceValue - federalRetentions.totalINSSRetido);
        totalFederalRetentions = Money(totalFederalRetentions + federalRetentions.totalINSSRetido);
      }
      if (valores.IRRetido == 1) {
        federalRetentions.totalIRRetido = valores.ValIR || 0;
        invoiceValue = Money(invoiceValue - federalRetentions.totalIRRetido);
        totalFederalRetentions = Money(totalFederalRetentions + federalRetentions.totalIRRetido);
      }
      if (valores.CSLLRetido == 1) {
        federalRetentions.totalCSLLRetido = valores.ValCSLL || 0
        invoiceValue = Money(invoiceValue - federalRetentions.totalCSLLRetido);
        totalFederalRetentions = Money(totalFederalRetentions + federalRetentions.totalCSLLRetido);
      }
      // if (valores.OutrasRetencoesRetido == 1) {
      //   invoiceValue = Money(invoiceValue - (valores.ValOutrasRetencoes || 0));
      // }
    }

    rps.Servico.Valores.ValLiquido = invoiceValue;

    return {
      ValISSRetido: valores.ValISSRetido || 0,
      totalFederalRetentions,
      federalRetentions
    }
  }

  async assemblyNFCeFromOS(OS: MkgOS, operation: Operation, company: Company, CPF?: string): Promise<NFCe> {
    company = await this._companyService.get(company.id, true); // get updated company, for rigth number and serie
    const client = await this._clientService.get(OS.client.id);
    const companyCity = await this._addressService.getCity(company.address.city, company.address.state);
    const UF = (await this._addressService.getState(client.address.state)).code;
    const isIntern = company.address.state === client.address.state;
    const osParts = OS.parts.filter(part => part.available === partStates.approved.id);

    if (!this.conditionsMap.size) {
      const conditions = await this._paymentService.getAll();
      for (const condition of conditions) {
        this.conditionsMap.set(condition._id, condition);
      }
    }

    const items: detItem65[] = [];
    const orderedParts = osParts.sort((i1, i2) => i1.seq = i2.seq);
    for (const partOS of orderedParts) {
      const part = await this._partService.get(partOS.part.id);
      let ICMS: Operation['ICMS'] | Part['ICMS'];
      if (part.ICMS && part.ICMS.CST) {
        ICMS = part.ICMS;
      } else {
        ICMS = operation.ICMS;
      }

      let vDesc = Money(partOS.discountValue());
      vDesc = Money(vDesc + partOS.discountProp());
      vDesc = Money(vDesc + partOS.discountPropGeral());

      const prod: detItem65['prod'] = {
        xProd: part.description,
        NCM: part.NCM.toString() || "0",
        CFOP: await this.getCFOPofPart(part, operation),
        cEAN: part.EANcode,
        CEST: part.CEST,
        cEANTrib: part.EANcode,
        cProd: Utilities.removeSpecialCharacters(`${part.code}`).trim(),
        indTot: 1,
        nTipoItem: this.getNtipoItemOf(part),
        uCOM: part.unitOfMeasurements,
        uTrib: part.unitOfMeasurements,
        qCOM: partOS.amount(),
        qTrib: partOS.amount(),
        vUnCom: partOS.saleValue(),
        vUnTrib: partOS.saleValue(),
        vProd: partOS.grossValue(),
        vDesc,
      }

      const item: detItem65 = {
        prod,
        imposto: {
          ICMS: NfUtilities.calculateICMSof(prod, {
            companyCRT: company.crt,
            icmsConfig: ICMS,
            part
          })
        }
      };

      if (UF) {
        const comb = this.getTagComb(part, UF);
        if (comb) {
          item.prod.nTipoItem = 4;
          item.prod.comb = comb;
        }
      }

      if (part.cbenef && !['00', '10', '60', '90'].includes(ICMS.CST)) {
        item.prod['cBenef'] = part.cbenef;
      }

      if (partOS.seqItem) {
        item["prod"]["nItemPed"] = partOS.seqItem.toString();
      }

      if (partOS.nroPedido) {
        item["prod"]["xPed_item"] = partOS.nroPedido;
      }

      items.push(item);
    }

    const ICMStot = NfUtilities.sumICMStotOfNFCeItems(items);

    const nf: Partial<NFCe> = {
      ModeloDocumento: 'NFCe',
      Versao: 4,
      det: items,
      emit: {
        xNome: company.socialName,
        xFant: company.fancyName || company.socialName,
        CNPJ_emit: company.cnpj.replace(/\D/g, ''),
        CRT: Number(company.crt),
        IE: company.stateRegistration,
        enderEmit: {
          CEP: Number((company.address.cep || '').replace(/\D/g, '')),
          UF: this._addressService.getState(company.address.state).code,
          cMun: Number(companyCity.igbe),
          nro: company.address.number,
          xBairro: company.address.neighborhood,
          xLgr: company.address.street,
          xMun: companyCity.name,
          Email: this._dataService.company.email,
          fone: Number(Utilities.removeMask(this._dataService.company.phone))
        }
      },
      ide: {
        cMunFg: Number(companyCity.igbe),
        cUF: this._addressService.getState(company.address.state).uf,
        finNFe: 1,
        dhEmi: this.getDate(),
        fusoHorario: this.getFuso(),
        idDest: isIntern ? 1 : 2,
        indFinal: 1,
        indPres: 1,
        mod: '65',
        nNF: operation.docFiscal ? Number(company.numberInvoiceNFC) : Number(company.numberGerencial),
        natOp: operation.operationFunction === "Outras" ? operation.descriptionFunction : operation.operationFunction,
        serie: operation.docFiscal ? company.serieInvoiceNFC || '000' as any : company.serieGerencial,
        tpAmb: environment.migrate.tpAmb,
        tpEmis: 1,
        tpImp: 4,
        tpNf: 1,
        EmailArquivos: client ? client.email : ""
      },
      total: {
        ICMStot: {
          vNF: OS.liquidValueParts,
          vProd_ttlnfe: OS.grossValueParts,
          vBC_ttlnfe: OS.liquidValueParts,
          vDesc_ttlnfe: OS.sumDiscountValues.parts.totalDiscountParts(),
          vFrete_ttlnfe: ICMStot.vFrete_ttlnfe,
          vICMS_ttlnfe: ICMStot.vICMS_ttlnfe,
          vSeg_ttlnfe: ICMStot.vSeg_ttlnfe,
          vST_ttlnfe: ICMStot.vST_ttlnfe,
          vOutro: ICMStot.vOutro,
        },
        retTrib: MigrateService.getRetTrib(OS.liquidValueLabors, operation, client)
      },
      pag: this.getPagsOfOS(OS, 'nf') as pagItem[],
      transp: {
        modFrete: 9
      }
    }

    if (CPF) {
      // value returned from AskCpfComponent popup
      nf['dest'] = await this.getDest(client, CPF, OS.idFarmer);
      if (client.documentType === DOCUMENT_TYPE_CODES.foreign) {
        nf.ide.idDest = 3;
      }
    }

    if (company.authorized?.length) {
      nf.autXML = this.createAuthorizedTag(company.authorized);
    }

    return nf as NFCe
  }

  async assemblyNFeFromOS(OS: MkgOS, operation: Operation, company: Company): Promise<NFe> {
    let ncmObservationTrib: NcmObservationTrib = {
      municipal: 0,
      state: 0,
      federal: 0,
      vTotTrib_ttlnfe: 0,
      keys: []
    }

    company = await this._companyService.get(company.id, true); // get updated company, for rigth number and serie
    const client = await this._clientService.get(OS.client.id);
    const vehicle = await this._vehicleService.get(OS.vehicle.id);
    const user = (await this._userService.getAll()).find(user => user.email === OS.user);
    const companyCity = await this._addressService.getCity(company.address.city, company.address.state);
    const osParts = OS.parts.filter(part => part.available === partStates.approved.id);

    if (OS?.client.document === environment.DEFAULT_CLIENT_DOCUMENT){
      OS.client.name = OS.clientTmp;
    }

    if (!companyCity) {
      console.warn("Não foi possível identificar a cidade nas informações de endereço. Verifique o cadastro da empresa.\n\n")
    }
    const isIntern = company.address.state === client.address.state;
    const dest = await this.getDest(client, null, OS.idFarmer);
    const emit = this.getEmit(company, companyCity);

    if (!this.conditionsMap.size) {
      const conditions = await this._paymentService.getAll();
      for (const condition of conditions) {
        this.conditionsMap.set(condition._id, condition);
      }
    }

    for (const partOS of osParts) {
      const part = await this._partService.get(partOS.part.id);
      partOS.part = part;
    }

    const items: detItem55[] = [];
    const orderedParts = osParts.sort((i1, i2) => i1.seq - i2.seq);

    for (const partOS of orderedParts) {
      try {
        const ibptData = await this._ibptImportService.getIbptData(partOS.part.NCM);

        ncmObservationTrib.federal += (+partOS.liquidValue()) * (ibptData.nationalFederal / 100);
        ncmObservationTrib.state += (+partOS.liquidValue()) * (ibptData.state / 100);
        ncmObservationTrib.municipal += (+partOS.liquidValue()) * (ibptData.municipal / 100);
        ncmObservationTrib.vTotTrib_ttlnfe += (ncmObservationTrib.federal + ncmObservationTrib.state + ncmObservationTrib.municipal);

        if (!ncmObservationTrib.keys.find(key => key === ibptData.key)) {
          ncmObservationTrib.keys.push(ibptData.key);
        }
      } catch (error) {
        console.error(error);
        this._snackbar.error("NCM.NOT_FOUND", { ncm: partOS.part.NCM });
      }

      const part = partOS.part;
      const nTipoItem = this.getNtipoItemOf(part);

      let ICMS: Operation['ICMS'] | Part['ICMS'];
      if (part.ICMS && part.ICMS.CST) {
        ICMS = part.ICMS;
      } else {
        ICMS = operation.ICMS;
      }

      let PIS: Operation['PIS'] | Part['PIS'];
      if (part.PIS && part.PIS.CST) {
        PIS = part.PIS;
      } else if (operation.PIS && operation.PIS.CST) {
        PIS = operation.PIS;
      }

      let COFINS: Operation['COFINS'] | Part['COFINS'];
      if (part.COFINS && part.COFINS.CST) {
        COFINS = part.COFINS;
      } else if (operation.COFINS && operation.COFINS.CST) {
        COFINS = operation.COFINS;
      }

      let CST_IPI: string;
      if (part.IPI && part.IPI.CST) {
        CST_IPI = part.IPI.CST;
      } else if (operation.IPI) {
        CST_IPI = operation.IPI;
      }

      let vDesc = Money(partOS.discountValue());
      vDesc = Money(vDesc + partOS.discountProp());
      vDesc = Money(vDesc + partOS.discountPropGeral());

      const prod: detItem55['prod'] = {
        xProd: part.description,
        NCM: part.NCM ? part.NCM.toString() : "0",
        CFOP: await this.getCFOPofPart(part, operation, isIntern),
        CEST: part.CEST,
        cEAN: part.EANcode,
        cEANTrib: part.EANcode,
        cProd: Utilities.removeSpecialCharacters(`${part.code}`).trim(),
        indTot: 1,
        nTipoItem: nTipoItem,
        uCOM: part.unitOfMeasurements,
        uTrib: part.unitOfMeasurements,
        qCOM: partOS.amount(),
        qTrib: partOS.amount(),
        vUnCom: partOS.saleValue(),
        vUnTrib: partOS.saleValue(),
        vProd: partOS.grossValue(),
        vDesc
      }

      const item: detItem55 = {
        prod,
        imposto: {
          ICMS: NfUtilities.calculateICMSof(prod, {
            companyCRT: company.crt,
            icmsConfig: ICMS,
            part
          }),
          PIS: NfUtilities.calculatePISof({ pisConfig: PIS, vBC_pis: partOS.grossValue() }),
          COFINS: NfUtilities.calculateCOFINSof({ cofinsConfig: COFINS, vBC_cofins: partOS.grossValue() }),
          IPI: NfUtilities.calculateIPIof(CST_IPI)
        }
      };

      if (dest && dest.enderDest) {
        const comb = this.getTagComb(part, dest.enderDest.UF_dest);
        if (comb) {
          item.prod.nTipoItem = 4;
          item.prod.comb = comb;
        }
      }

      if (part.cbenef && !['00', '10', '60', '90'].includes(ICMS.CST)) {
        item.prod['cBenef'] = part.cbenef;
      }

      if (partOS.key) {
        ncmObservationTrib.federal += partOS.liquidValue() * (partOS.nationalFederal / 100);
        ncmObservationTrib.state += partOS.liquidValue() * (partOS.state / 100);
        ncmObservationTrib.municipal += partOS.liquidValue() * (partOS.municipal / 100);
        ncmObservationTrib.vTotTrib_ttlnfe += (ncmObservationTrib.federal + ncmObservationTrib.state + ncmObservationTrib.municipal);
        if (!ncmObservationTrib.keys.find(key => key === partOS.key)) {
          ncmObservationTrib.keys.push(partOS.key);
        }
      }

      if (partOS.seqItem) {
        item["prod"]["nItemPed"] = partOS.seqItem.toString();
      }

      if (partOS.nroPedido) {
        item["prod"]["xPed_item"] = partOS.nroPedido;
      }

      items.push(item);
    }

    const ICMStot = NfUtilities.sumICMStotOfNFeItems(items);

    const retTrib = MigrateService.getRetTrib(OS.liquidValueParts, operation, client, orderedParts);

    const retTribValues = {
      totalPISRetido: Money(retTrib.vRetPIS),
      totalCOFINSRetido: Money(retTrib.vRetCOFINS_servttlnfe),
      totalINSSRetido: Money(retTrib.vRetPrev),
      totalIRRetido: Money(retTrib.vIRRF),
      totalCSLLRetido: Money(retTrib.vRetCSLL)
    }

    // Calculando a soma de todos os valores
    const totalRetTrib = Object.values(retTribValues).reduce((acc, value) => acc + value, 0);

    const retTribMessage = `Valor dos impostos retidos: R$(${Money(totalRetTrib)}) `

    let nf: Partial<NFe> = {
      ModeloDocumento: 'NFe',
      Versao: 4,
      det: items,
      emit: emit,
      dest: dest as any,
      ide: {
        cMunFg: Number(companyCity.igbe),
        cUF: this._addressService.getState(company.address.state).uf,
        finNFe: 1,
        dhEmi: this.getDate(),
        fusoHorario: this.getFuso(),
        idDest: isIntern ? 1 : 2,
        indFinal: 1,
        indPres: 1,
        mod: '55',
        nNF: operation.docFiscal ? Number(company.numberInvoice) : Number(company.numberGerencial),
        natOp: operation.operationFunction === "Outras" ? operation.descriptionFunction : operation.operationFunction,
        serie: operation.docFiscal ? company.serieInvoice || '000' as any : company.serieGerencial || undefined,
        tpAmb: environment.migrate.tpAmb,
        tpEmis: 1,
        tpImp: 1,
        tpNf: 1,
        EmailArquivos: client ? client.email : ""
      },
      total: {
        ICMStot: {
          vNF: OS.liquidValueParts,
          vProd_ttlnfe: OS.grossValueParts,
          vBC_ttlnfe: OS.liquidValueParts,
          vDesc_ttlnfe: OS.sumDiscountValues.parts.totalDiscountParts(),
          vTotTrib_ttlnfe: ncmObservationTrib.vTotTrib_ttlnfe,
          vCOFINS_ttlnfe: ICMStot.vCOFINS_ttlnfe,
          vFrete_ttlnfe: ICMStot.vFrete_ttlnfe,
          vICMS_ttlnfe: ICMStot.vICMS_ttlnfe,
          vIPI_ttlnfe: ICMStot.vIPI_ttlnfe,
          vPIS_ttlnfe: ICMStot.vPIS_ttlnfe,
          vSeg_ttlnfe: ICMStot.vSeg_ttlnfe,
          vST_ttlnfe: ICMStot.vST_ttlnfe,
          vOutro: ICMStot.vOutro,
        },
        retTrib: MigrateService.getRetTrib(OS.liquidValueParts, operation, client, orderedParts)
      },
      pag: this.getPagsOfOS(OS, 'nf') as pagItem[],
      cobr: this.getTagCobr(OS.paymentConditions.map(apiObj => new Finance(apiObj, this.conditionsMap.get(apiObj.paymentCondition))), OS),
      transp: {
        modFrete: 9
      },
      infAdic: {
        infCpl: `Cliente: ${client.name} - O.S.: ${OS.codeSystem} - Consultor: ${user ? user.name : ''} - Modelo: ${vehicle.model} - Chassi: ${vehicle.vin || ''} - Fab/Mod: ${vehicle.year || ''}/${vehicle.modelYear || ''} - Placa: ${vehicle.plate || ''} - KM: ${vehicle.km || ''} - Cor: ${vehicle.color || ''} - ${this.createApproximateTax(ncmObservationTrib)} ${operation.operationMessage} ${OS.observation || ''}`
      }
    }

    if (totalRetTrib != 0 && !isNaN(totalRetTrib)) {
      nf.infAdic.infCpl = retTribMessage + ' -  ' + nf.infAdic.infCpl;
    }

    if (nf.total.ICMStot.vTotTrib_ttlnfe === 0) {
      delete nf.total.ICMStot.vTotTrib_ttlnfe;
    } else {
      nf.total.ICMStot.vTotTrib_ttlnfe = ncmObservationTrib.vTotTrib_ttlnfe;
    }

    if (client.documentType === DOCUMENT_TYPE_CODES.foreign) {
      nf.ide.idDest = 3;
      let EXPORTATION_CFOPs = CFOP_list.filter(cfop => cfop.isExportation);
      if (nf.det.some(item => EXPORTATION_CFOPs.some(cfop => cfop.code === `${item.prod.CFOP}`))) {
        nf.exporta = {
          UFSaidaPais: nf.emit.enderEmit.UF,
          xLocExporta: nf.emit.enderEmit.xMun
        };

        nf.det.forEach(item => {
          const uTrib = this._getUTribByNCM(item.prod.NCM);
          if (uTrib) {
            item.prod.uTrib = uTrib;
          }
        });
      }
    }

    if (company.authorized?.length) {
      nf.autXML = this.createAuthorizedTag(company.authorized);
    }

    return nf as NFe
  }

  public createAuthorizedTag(authorized: string[]): ({ "CNPJ_aut"?: string } | { "CPF_aut"?: string })[] {
    return authorized.map(auth => {
      if (auth.length === 14) {
        return { CNPJ_aut: auth };
      } else if (auth.length == 11) {
        return { CPF_aut: auth };
      }
    }).filter(el => !!el);
  }

  async assemblyNFeFromOrder(orderID: string): Promise<NFe> {
    const ncmObservationTrib: NcmObservationTrib = {
      municipal: 0,
      state: 0,
      federal: 0,
      vTotTrib_ttlnfe: 0,
      keys: []
    };

    const company = this._dataService.company;
    const apiOrder = await this._orderService.get(orderID);
    const client = await this._clientService.get(apiOrder.client);
    const user = await this._userService.get(apiOrder.seller);
    const operation = await this._operationService.getById(apiOrder.operation);
    const companyCity = await this._addressService.getCity(company.address.city, company.address.state);
    const isIntern = company.address.state === client.address.state;
    const dest = await this.getDest(client, null, apiOrder.idFarmer);

    const emit = this.getEmit(company, companyCity);
    const partsMap = new Map<string, Part>();
    const conditions = await this._paymentService.getAll();

    for (const orderItem of apiOrder.parts) {
      const part = await this._partService.get(orderItem.part);
      partsMap.set(orderItem.part, part);
    }

    const order = new OrderFull(apiOrder, Array.from(partsMap.values()), conditions);

    const items: detItem55[] = []
    const orderedParts = apiOrder.parts.sort((i1, i2) => i1.seq - i2.seq);
    for (const orderItem of orderedParts) {
      const part = partsMap.get(orderItem.part);
      const nTipoItem = (part.groupObject ? ([2, 3].includes(part.groupObject.type) ? 5 /** combustível/lubrificante */ : 0) : 0);
      const qTrib = (part.unitOfMeasurements === 'kilo' ? orderItem.amount * (part.grossWeight || 1) : orderItem.amount);
      const ICMS = (part.ICMS && part.ICMS.CST && part.ICMS.Aliquot ? part.ICMS : operation.ICMS);
      const grossValue = orderItem.amount * orderItem.saleValue;
      const pis_aliquot = (part.PIS && part.PIS.CST ? part.PIS.Aliquot : (operation.PIS ? operation.PIS.Aliquot : 0));
      const cofins_aliquot = (part.COFINS && part.COFINS.CST ? part.COFINS.Aliquot : (operation.COFINS ? operation.COFINS.Aliquot : 0));
      const additionalDiscount = Utilities.calcPercentage(orderItem.additionalDiscount, qTrib * orderItem.saleValue);
      const osDiscount = Utilities.calcPercentage(apiOrder.generalDiscont, grossValue - additionalDiscount);
      const totalDiscount = additionalDiscount + osDiscount;

      const item: detItem55 = {
        prod: {
          xProd: part.description,
          NCM: part.NCM ? part.NCM.toString() : "0",
          CFOP: await this.getCFOPofPart(part, operation, isIntern),
          CEST: part.CEST,
          cEAN: part.EANcode,
          cEANTrib: part.EANcode,
          cProd: Utilities.removeSpecialCharacters(`${part.code}`).trim(),
          indTot: 1,
          nTipoItem: nTipoItem,
          uCOM: part.unitOfMeasurements,
          uTrib: part.unitOfMeasurements,
          qCOM: qTrib,
          qTrib: qTrib,
          vUnCom: orderItem.saleValue,
          vUnTrib: orderItem.saleValue,
          vProd: (qTrib * orderItem.saleValue),
          vDesc: totalDiscount,
          pRedBC: 0
        } as any,
        imposto: {
          ICMS: {
            CST: ICMS.CST,
            modBC: 0,
            orig: part.origin,
            pICMS: ICMS ? ICMS.Aliquot : 0,
            vICMS_icms: Utilities.calcPercentage(ICMS ? ICMS.Aliquot : 0, (qTrib * orderItem.saleValue)),
            vBC: company.crt === "1" || company.crt === "4" ? 0 : (qTrib * orderItem.saleValue),
          } as any,
          PIS: {
            CST_pis: part.PIS.CST || operation.PIS.CST,
            pPIS: pis_aliquot,
            vPIS: (pis_aliquot ? (grossValue * pis_aliquot / 100) : 0)
          },
          COFINS: {
            CST_cofins: part.COFINS.CST || operation.COFINS.CST,
            pCOFINS: cofins_aliquot,
            vCOFINS: (cofins_aliquot ? (orderItem.liquidValue * cofins_aliquot / 100) : 0)
          }
        }
      };

      const comb = this.getTagComb(part, dest.enderDest.UF_dest);
      if (comb) {
        item.prod.nTipoItem = 4;
        item.prod.comb = comb;
      }

      if (item.imposto.PIS.pPIS) {
        item.imposto.PIS['vBC_pis'] = grossValue;
      }

      if (item.imposto.COFINS.pCOFINS) {
        item.imposto.COFINS['vBC_cofins'] = grossValue;
      }

      if (part.cbenef) {
        item.prod['cBenef'] = part.cbenef;
      }

      if (orderItem.seqItem) {
        item["prod"]["nItemPed"] = orderItem.seqItem.toString();
      }

      if (orderItem.nroPedido) {
        item["prod"]["xPed_item"] = orderItem.nroPedido;
      }

      if (orderItem.key) {
        ncmObservationTrib.federal += orderItem.liquidValue * (orderItem.nationalFederal / 100);
        ncmObservationTrib.state += orderItem.liquidValue * (orderItem.state / 100);
        ncmObservationTrib.municipal += orderItem.liquidValue * (orderItem.municipal / 100);
        ncmObservationTrib.vTotTrib_ttlnfe += (ncmObservationTrib.federal + ncmObservationTrib.state + ncmObservationTrib.municipal);
        if (!ncmObservationTrib.keys.find(key => key === orderItem.key)) {
          ncmObservationTrib.keys.push(orderItem.key);
        }
      }

      items.push(item);
    }


    const nf: Partial<NFe> = {
      ModeloDocumento: 'NFe',
      Versao: 4,
      det: items,
      emit: emit,
      dest: dest as any,
      ide: {
        cMunFg: Number(companyCity.igbe),
        cUF: this._addressService.getState(company.address.state).uf,
        finNFe: 1,
        dhEmi: this.getDate(),
        fusoHorario: this.getFuso(),
        idDest: isIntern ? 1 : 2,
        indFinal: 1,
        indPres: 1,
        mod: '55',
        nNF: operation.docFiscal ? Number(this._dataService.company.numberInvoice) : Number(this._dataService.company.numberGerencial),
        natOp: operation.operationFunction === "Outras" ? operation.descriptionFunction : operation.operationFunction,
        serie: operation.docFiscal ? this._dataService.company.serieInvoice || '000' as any : this._dataService.company.serieGerencial || undefined,
        tpAmb: environment.migrate.tpAmb,
        tpEmis: 1,
        tpImp: 1,
        tpNf: 1,
        EmailArquivos: client ? client.email : ""
      },
      total: {
        ICMStot: {
          vBCST_ttlnfe: 0,
          vBC_ttlnfe: 0,
          vCOFINS_ttlnfe: 0,
          vDesc_ttlnfe: 0,
          vFrete_ttlnfe: 0,
          vICMS_ttlnfe: 0,
          vII_ttlnfe: 0,
          vIPI_ttlnfe: 0,
          vNF: 0,
          vOutro: 0,
          vPIS_ttlnfe: 0,
          vProd_ttlnfe: 0,
          vST_ttlnfe: 0,
          vSeg_ttlnfe: 0,
          vFCPSTRet_ttlnfe: 0,
          vFCPST_ttlnfe: 0,
          vFCP_ttlnfe: 0,
          vIPIDevol_ttlnfe: 0,
          vFCPUFDest_ttlnfe: 0,
          vTotTrib_ttlnfe: ncmObservationTrib.vTotTrib_ttlnfe
        }
      },
      pag: this.getPagsOfOrder(order, 'nf') as pagItem[],
      cobr: this.getTagCobr(order.finances),
      transp: {
        modFrete: 9
      },
      infAdic: {
        infCpl: `Cliente: ${client.name} - Pedido: ${apiOrder.code} - Vendedor: ${user ? user.name : ''} - ${operation.operationMessage} - ${apiOrder.observation || ''} ${this.createApproximateTax(ncmObservationTrib)} ${ncmObservationTrib.keys.join(" ")}`
      }
    };

    if (order.delivery) {
      nf.entrega = order.delivery;
    }

    if (nf.total.ICMStot.vTotTrib_ttlnfe === 0) {
      delete nf.total.ICMStot.vTotTrib_ttlnfe;
    } else {
      nf.total.ICMStot.vTotTrib_ttlnfe = ncmObservationTrib.vTotTrib_ttlnfe;
    }

    if (client.documentType === DOCUMENT_TYPE_CODES.foreign) {
      nf.ide.idDest = 3;
      let EXPORTATION_CFOPs = CFOP_list.filter(cfop => cfop.isExportation);
      if (nf.det.some(item => EXPORTATION_CFOPs.some(cfop => cfop.code === `${item.prod.CFOP}`))) {
        // send exportation info
        nf.exporta = {
          UFSaidaPais: nf.emit.enderEmit.UF,
          xLocExporta: nf.emit.enderEmit.xMun
        }

        nf.det.forEach(item => {
          const uTrib = this._getUTribByNCM(item.prod.NCM);
          if (uTrib) {
            item.prod.uTrib = uTrib;
          }
        });

      }
    }

    if (company.authorized?.length) {
      nf.autXML = this.createAuthorizedTag(company.authorized);
    }

    const resp = NfUtilities.calculateTotals(nf as any);

    const partsArray = Array.from(partsMap.values());


    const retTrib = MigrateService.getRetTrib(resp.total.ICMStot.vNF, operation, client, partsArray);
    resp.total.retTrib = retTrib;

    const retTribValues = {
      totalPISRetido: Money(retTrib.vRetPIS),
      totalCOFINSRetido: Money(retTrib.vRetCOFINS_servttlnfe),
      totalINSSRetido: Money(retTrib.vRetPrev),
      totalIRRetido: Money(retTrib.vIRRF),
      totalCSLLRetido: Money(retTrib.vRetCSLL)
    };

    const totalRetTrib = Object.values(retTribValues).reduce((acc, value) => acc + value, 0);

    if (totalRetTrib !== 0 && !isNaN(totalRetTrib)) {
      const retTribMessage = `Valor dos impostos retidos: R$(${Money(totalRetTrib)})`;

      nf.infAdic.infCpl = retTribMessage + ' - ' + nf.infAdic.infCpl;
    }


    return resp as NFe;
  }

  async assemblyNFCeFromOrder(orderID: string, CPF?: string, idFarmer?: string): Promise<NFCe> {
    const company = await this._companyService.get(this._dataService.company.id, true); // get updated company, for rigth number and serie
    const apiOrder = await this._orderService.get(orderID);
    const client = apiOrder.client ? await this._clientService.get(apiOrder.client) : null;
    const UF = apiOrder.client ? (await this._addressService.getState(client.address.state)).code : null;
    const operation = await this._operationService.getById(apiOrder.operation);
    const companyCity = await this._addressService.getCity(company.address.city, company.address.state);
    const isIntern = apiOrder.client ? company.address.state === client.address.state : false;
    const emit = this.getEmit(company, companyCity);
    const partsMap = new Map<string, Part>();
    const conditions = await this._paymentService.getAll();

    for (const orderItem of apiOrder.parts) {
      const part = await this._partService.get(orderItem.part);
      partsMap.set(orderItem.part, part);
    }
    const order = new OrderFull(apiOrder, Array.from(partsMap.values()), conditions);
    const items: detItem65[] = [];
    const orderedParts = apiOrder.parts.sort((i1, i2) => i1.seq - i2.seq);
    for (const orderItem of orderedParts) {
      const part = partsMap.get(orderItem.part);
      const qTrib = (part.unitOfMeasurements === 'kilo' ? orderItem.amount * (part.grossWeight || 1) : orderItem.amount);
      const ICMS = (part.ICMS && part.ICMS.CST && part.ICMS.Aliquot ? part.ICMS : operation.ICMS);
      const grossValue = orderItem.amount * orderItem.saleValue;
      const additionalDiscount = Utilities.calcPercentage(orderItem.additionalDiscount, qTrib * orderItem.saleValue);
      const osDiscount = Utilities.calcPercentage(apiOrder.generalDiscont, grossValue - additionalDiscount);
      const totalDiscount = additionalDiscount + osDiscount;

      const item: detItem65 = {
        prod: {
          xProd: part.description,
          NCM: part.NCM ? part.NCM.toString() : "0",
          CFOP: await this.getCFOPofPart(part, operation, isIntern),
          CEST: part.CEST,
          cEAN: part.EANcode,
          cEANTrib: part.EANcode,
          cProd: Utilities.removeSpecialCharacters(`${part.code}`).trim(),
          indTot: 1,
          nTipoItem: this.getNtipoItemOf(part),
          uCOM: part.unitOfMeasurements,
          uTrib: part.unitOfMeasurements,
          qCOM: qTrib,
          qTrib: qTrib,
          vUnCom: orderItem.saleValue,
          vUnTrib: orderItem.saleValue,
          vProd: (qTrib * orderItem.saleValue),
          vDesc: totalDiscount,
          pRedBC: 0
        } as any,
        imposto: {
          ICMS: {
            CST: ICMS.CST,
            modBC: 0,
            orig: part.origin,
            pICMS: ICMS ? ICMS.Aliquot : 0,
            vICMS_icms: Utilities.calcPercentage(ICMS ? ICMS.Aliquot : 0, (qTrib * orderItem.saleValue)),
            vBC: company.crt === "1" || company.crt === "4" ? 0 : (qTrib * orderItem.saleValue),
          } as any
        }
      };

      const comb = this.getTagComb(part, UF);
      if (comb) {
        item.prod.nTipoItem = 4;
        item.prod.comb = comb;
      }

      if (part.cbenef) {
        item.prod['cBenef'] = part.cbenef;
      }

      if (orderItem.seqItem) {
        item["prod"]["nItemPed"] = orderItem.seqItem.toString();
      }

      if (orderItem.nroPedido) {
        item["prod"]["xPed_item"] = orderItem.nroPedido;
      }

      items.push(item)
    }


    const nf: Partial<NFCe> = {
      ModeloDocumento: 'NFCe',
      Versao: 4,
      det: items,
      emit: emit,
      ide: {
        cMunFg: Number(companyCity.igbe),
        cUF: this._addressService.getState(company.address.state).uf,
        finNFe: 1,
        dhEmi: this.getDate(),
        fusoHorario: this.getFuso(),
        idDest: isIntern ? 1 : 2,
        indFinal: 1,
        indPres: 1,
        mod: '65',
        nNF: undefined,
        natOp: operation.operationFunction === "Outras" ? operation.descriptionFunction : operation.operationFunction,
        serie: undefined,
        tpAmb: environment.migrate.tpAmb,
        tpEmis: 1,
        tpImp: 4,
        tpNf: 1,
        EmailArquivos: client ? client.email : ""
      },
      total: {
        ICMStot: {
          vBCST_ttlnfe: 0,
          vBC_ttlnfe: 0,
          vCOFINS_ttlnfe: 0,
          vDesc_ttlnfe: 0,
          vFrete_ttlnfe: 0,
          vICMS_ttlnfe: 0,
          vII_ttlnfe: 0,
          vIPI_ttlnfe: 0,
          vNF: 0,
          vOutro: 0,
          vPIS_ttlnfe: 0,
          vProd_ttlnfe: 0,
          vST_ttlnfe: 0,
          vSeg_ttlnfe: 0
        }
      },
      pag: this.getPagsOfOrder(order, 'nf') as pagItem[],
      transp: {
        modFrete: 9
      }
    }

    if (CPF) {
      // value returned from AskCpfComponent popup
      nf['dest'] = await this.getDest(client, CPF, idFarmer);
      if (client.documentType === DOCUMENT_TYPE_CODES.foreign) {
        nf.ide.idDest = 3;
      }
    }

    if (company.authorized?.length) {
      nf.autXML = this.createAuthorizedTag(company.authorized);
    }

    const resp = NfUtilities.calculateTotals(nf as any);

    resp.total.retTrib = MigrateService.getRetTrib(resp.total.ICMStot.vNF, operation, client);
    return resp as NFCe
  }

  async reassemblyNFeFromItems(document: NF_response, isDevolution: boolean): Promise<NFe> {
    const cnpj = await this._invoicePropertyPipe.transform(document, "cnpj", this._dataService.company);
    let company = await this._companyService.getByCnpj(cnpj, true);
    const operation = await this._operationService.getById(document.operationId);

    document.items = await this.getItemsFromInvoice(document.id, cnpj);

    for (const pag of document.request.Documento.pag) {
      if ([PAYMENT_METHODS.credit, PAYMENT_METHODS.debit].includes(pag.tPag)) {
        pag['card'] = {
          tipoIntegracao: 2
        }
      }
    }

    for (const documentPart of document.items) {
      const part = await this._partService.get(documentPart.keyId);
      const index = document.request.Documento.det.findIndex(d => d.prod.cProd === documentPart.code);
      const currentDet = document.request.Documento.det.find(d => d.prod.cProd === documentPart.code);

      const qTrib = (part.unitOfMeasurements === 'kilo' ? documentPart.amount * (part.grossWeight || 1) : documentPart.amount);
      const ICMS = (part.ICMS && part.ICMS.CST && part.ICMS.Aliquot ? part.ICMS : operation.ICMS);
      const grossValue = documentPart.amount * part.saleValue;
      const pis_aliquot = (part.PIS && part.PIS.CST ? part.PIS.Aliquot : (operation.PIS ? operation.PIS.Aliquot : 0));
      const cofins_aliquot = (part.COFINS && part.COFINS.CST ? part.COFINS.Aliquot : (operation.COFINS ? operation.COFINS.Aliquot : 0));

      if (document && document.request && document.request.Documento && document.request.Documento.dest && document.request.Documento.dest.enderDest) {
        const UF = document.request.Documento.dest.enderDest.UF_dest;
        const comb = this.getTagComb(part, UF)
        if (comb) {
          currentDet.prod.nTipoItem = 4;
          (currentDet as detItem55).prod.comb = comb;
        }
      }

      currentDet.prod.CEST = part.CEST;
      currentDet.imposto.ICMS.modBC = 0;
      currentDet.imposto.ICMS.orig = part.origin;
      currentDet.imposto.ICMS.pICMS = ICMS ? ICMS.Aliquot : 0;
      currentDet.imposto.ICMS.vICMS_icms = Utilities.calcPercentage(ICMS ? ICMS.Aliquot : 0, (qTrib * currentDet.prod.vUnCom));
      currentDet.imposto.ICMS.vBC = company.crt === "1" || company.crt === "4" ? 0 : (qTrib * currentDet.prod.vUnCom);

      // handle PIS tag
      if (!currentDet.imposto['PIS']) {
        currentDet.imposto['PIS'] = {};
      }
      currentDet.imposto['PIS']['CST_pis'] = part.PIS.CST || operation.PIS.CST || currentDet.imposto['PIS']['CST_pis'];
      currentDet.imposto['PIS']['pPIS'] = pis_aliquot;
      currentDet.imposto['PIS']['vPIS'] = (pis_aliquot ? (grossValue * pis_aliquot / 100) : 0);
      if (currentDet.imposto['PIS']['pPIS']) {
        currentDet.imposto['PIS']['vBC_pis'] = grossValue;
      }
      if (!currentDet.imposto['PIS']['CST_pis']) {
        currentDet.imposto['PIS'] = {}; // invalid CST
      }

      // handle COFINS tag
      if (!currentDet.imposto['COFINS']) {
        currentDet.imposto['COFINS'] = {};
      }
      currentDet.imposto['COFINS']['CST_cofins'] = part.COFINS.CST || operation.COFINS.CST || currentDet.imposto['COFINS']['CST_cofins'];
      currentDet.imposto['COFINS']['vCOFINS'] = (cofins_aliquot ? (currentDet.prod.vUnCom * cofins_aliquot / 100) : 0);
      if (currentDet.imposto['COFINS']['pCOFINS']) {
        currentDet.imposto['COFINS']['vBC_cofins'] = grossValue;
      }
      if (!currentDet.imposto['COFINS']['CST_cofins']) {
        currentDet.imposto['COFINS'] = {}; // invalid CST
      }

      if (part.cbenef) {
        currentDet.prod['cBenef'] = part.cbenef;
      }
      if (part.CEST) {
        currentDet['prod']['CEST'] = part.CEST;
      }
      document.request.Documento.det[index] = currentDet;
    };

    const resp = NfUtilities.calculateTotals(document.request.Documento as any, isDevolution);
    return resp as NFe
  }

  private getDiscriminationTable(OS: MkgOS): string {
    if (!OS) {
      return "";
    }
    let str = "";
    if (OS.labors && OS.labors.length) {
      for (const roLabor of OS.labors) {
        str += "SERVICO:" + roLabor.labor.description + " ";
        str += "QUANTIDADE:" + roLabor.amount + " ";
        str += "VALOR: " + new DecimalPipe('pt-br').transform(roLabor.liquidValue, '1.2-2')
        str += "!CHR13!";
      }

    }
    if (OS.thirdPartyServices && OS.thirdPartyServices.length) {
      const thirdPartyValue = OS.thirdPartyServices.slice().map(outs => outs.collectedValue).reduce((a, b) => a + b)
      str += "TOT. SERV. DE TERCEIROS: " + new DecimalPipe('pt-br').transform(thirdPartyValue || 0, '1.2-2');
    }

    return str
  }

  createApproximateTax(ncmObservationTrib: NcmObservationTrib): string {
    const { federal = 0, keys, municipal = 0, state = 0 } = ncmObservationTrib || {};
    const moneyPipe = new CurrencyPipe('pt-br', "BRL");

    let approximateTaxString = "Trib aprox: "

    if (federal > 0) {
      const federalStr = moneyPipe.transform(federal) + " Federal, ";
      approximateTaxString += federalStr;
    }

    if (state > 0) {
      const stateStr = moneyPipe.transform(state) + " Estadual, ";
      approximateTaxString += stateStr;
    }

    if (municipal > 0) {
      const municipalStr = moneyPipe.transform(municipal) + " Municipal, ";
      approximateTaxString += municipalStr;
    }

    if ((federal <= 0) && (state <= 0) && (municipal <= 0)) {
      approximateTaxString = "";
    } else {
      approximateTaxString = approximateTaxString.concat(` ${keys.join(" ")}`);
    }

    return approximateTaxString;
  }

  async assemblyNFSeFromOS(company: Company, operation: Operation, OS: MkgOS, farmId?: string): Promise<NFSe> {
    const textLogger = this._logReporter.textLogger("assemblyNFSeFromOS", LOG_REPORTER_KEYS.INVOICE)
    textLogger("Buscando dados atualizados da empresa")
    company = await this._companyService.get(company.id, true); // get updated company, for rigth number and serie
    const isSEFINNet = company.patternNFSE === "SEFINNet";
    textLogger("Buscando dados de endereço da empresa")
    const prestCity = await this._addressService.getCity(company.address.city, company.address.state);
    textLogger(`Cidade: ${prestCity?.name}`)
    textLogger("Calculando total de serviço de terceiros")
    textLogger(`Vl terceiros: ${OS.outsourcedValue?.toFixed(2)}`)
    textLogger("buscando dados do cliente da nota")
    const client = await this._clientService.get(OS.client.id);
    textLogger(`Cliente: ${client?.name}`)
    textLogger(`Buscando dados do veículo da nota`)
    const vehicle = await this._vehicleService.get(OS.vehicle.id);
    textLogger(`Veículo: ${vehicle?.plate}`);
    textLogger(`Buscando dados do consultor da OS`);
    const consulter = (await this._userService.getAll()).find(user => user.email === OS.user);
    textLogger(`Consultor: ${consulter?.name}`);
    let infAdic = "";
    textLogger("Montando tag com informações adicionais da nota")
    if (client && vehicle) {
      infAdic += `Cliente: ${client.name} - O.S.: ${OS.codeSystem} - Consultor: ${consulter ? consulter.name : ''} - Modelo: ${vehicle.model} - Chassi: ${vehicle.vin || ''} - Fab/Mod: ${vehicle.year || ''}/${vehicle.modelYear || ''} - Placa: ${vehicle.plate || ''} - KM: ${vehicle.km || ''} - Cor: ${vehicle.color || ''} - ${operation.operationMessage} - ${OS.observation || ''}`
    }
    textLogger(`<infAdic>${infAdic}</infAdic>`)
    let selectedFarm: Farm;

    if ((client.documentType === DOCUMENT_TYPE_CODES.rural) && farmId) {
      selectedFarm = (client as Client).farm.find(f => f._id === farmId);
    }

    textLogger("montando tag endereço do prestador")
    let enderPrest: RPS["Prestador"]["enderPrest"] = {
      CEP: Number((company.address.cep || '').replace(/\D/g, '')),
      TPEnd: company.address.type,
      UF: this._addressService.getState(company.address.state).code,
      cMun: Number(prestCity.igbe),
      xBairro: company.address.neighborhood,
      xLgr: company.address.street,
    }
    if (company.email) {
      enderPrest.Email = company.email;
    }
    if (company.phone) {
      enderPrest.fone = company.phone;
    }
    if (company.address.number) {
      enderPrest.nro = company.address.number;
    }
    if (company.address.complement) {
      enderPrest.xCpl = company.address.complement;
    }

    textLogger("Montando tag de Prestador")
    const Prestador: RPS["Prestador"] = {
      CNPJ_prest: company.cnpj.replace(/\D/g, ''),
      IM: company.IM,
      xNome: company.socialName,
      xFant: company.fancyName || company.socialName,
      enderPrest: enderPrest
    }
    if (company.stateRegistration) {
      Prestador.IE = company.stateRegistration.replace(/\D/g, '');
    }

    let tribMunicipio = company.tributMunicipio || "1401";

    textLogger("buscando serviços")
    const approvedOsLabors = OS.labors.filter(labor => labor.available === laborStates.approved.id);
    const labors: Labor[] = [];
    for (const roLabor of approvedOsLabors) {
      // complete labor (to get others properties, like CNAE)
      const labor = await this._laborService.get(roLabor.labor.id);
      labors.push(labor);
      roLabor.labor = labor;
    }

    if (!this.conditionsMap.size) {
      textLogger("buscando condições de pagamento")
      const conditions = await this._paymentService.getAll();
      for (const condition of conditions) {
        this.conditionsMap.set(condition._id, condition);
      }
    }

    textLogger("montando tag de lista de itens")
    let ListaItens: NFSE_item[] = [];

    for (const roLabor of approvedOsLabors) {
      textLogger(`item: ${roLabor?.labor?.description}`)
      let item: Partial<NFSE_item> = {
        ItemBaseCalculo: roLabor.liquidValue,
        ItemvlDed: 0, // will be calculated
        ItemQtde: roLabor.amount,
        ItemCod: roLabor.labor.code,
        ItemcCnae: roLabor.labor.CNAE,
        ItemvUnit: Money(roLabor.liquidValue / roLabor.amount),
        ItemDesc: roLabor.labor.description + " " + (roLabor.observation || ''),
        ItemTributavel: "S",
        ItemVlrLiquido: roLabor.liquidValue,
        ItemVlrTotal: roLabor.liquidValue,
        ItemSeq: approvedOsLabors.indexOf(roLabor),
        ItemuMed: "UN",
        ItemIteListServico: `${roLabor.labor.LCCode || company.tributMunicipio || "1401"}`,
      }

      if (company.exigibilidade) {
        item.ItemExigibilidadeISS = company.exigibilidade;
      }

      ListaItens.push(item as NFSE_item);
    }

    if (OS.thirdPartyServices) {
      textLogger("Inserindo serviços de terceiro na lista de itens")
      // ListaItens
      for (const outsourced of OS.thirdPartyServices) {
        const liquidValue = Money(outsourced.collectedValue - outsourced.discountPropLabor - outsourced.discountPropGeral);
        textLogger(`Serv. ter: ${outsourced?.description}`)
        const item: Partial<NFSE_item> = {
          ItemBaseCalculo: liquidValue,
          ItemvlDed: 0,
          ItemQtde: 1,
          ItemCod: outsourced.code,
          ItemcCnae: company.cnaeCode,
          ItemvUnit: liquidValue,
          ItemDesc: outsourced.description,
          ItemTributavel: "S",
          ItemVlrLiquido: liquidValue,
          ItemVlrTotal: liquidValue,
          ItemSeq: ListaItens.length,
          ItemuMed: "UN",
          ItemIteListServico: tribMunicipio
        }
        if (company.exigibilidade) {
          item.ItemExigibilidadeISS = company.exigibilidade;
        }
        ListaItens.push(item as NFSE_item)
      }
    }


    textLogger("Montando tag de serviço")
    let Servico: RPS["Servico"] = {
      Discriminacao: isSEFINNet ? infAdic : this.getDiscriminationTable(OS),
      IteListServico: tribMunicipio,
      TributMunicipio: tribMunicipio,
      cMun: enderPrest.cMun,
      Cnae: Number(company.cnaeCode),
      Valores: {
        ValBaseCalculo: OS.liquidValueLabors,
        ValLiquido: OS.liquidValueLabors,
        ValServicos: OS.liquidValueLabors
      },
      LocalPrestacao: {
        SerEndxMun: prestCity.name,
        SerEndBairro: enderPrest.xBairro,
        SerEndCep: enderPrest.CEP,
        SerEndcMun: enderPrest.cMun,
        SerEndComplemento: enderPrest.xCpl,
        SerEndLgr: enderPrest.xLgr,
        SerEndNumero: enderPrest.nro,
        SerEndSiglaUF: enderPrest.UF,
        SerEndTpLgr: enderPrest.TPEnd
      }
    }

    textLogger("Definindo município de incidencia de ISS")

    switch (company.munIncidISS) {
      case "Prestador":
        Servico.cMunIncidencia = enderPrest.cMun;
        ListaItens.forEach(item => {
          item.ItemcMunIncidencia = Servico.cMunIncidencia;
        });
        break;
      case "Cliente":
        if (client && client.address) {
          try {
            const state = await this._addressService.getState(client.address.state);
            const city = await this._addressService.getCity(client.address.city);
            if (city && city.igbe) {
              Servico.cMunIncidencia = Number(city.igbe);
              ListaItens.forEach(item => {
                item.ItemcMunIncidencia = Servico.cMunIncidencia;
              });
            }
            if (company.patternNFSE === "E&L") {
              Servico.LocalPrestacao = {
                SerEndBairro: client.address.neighborhood,
                SerEndCep: Number(client.address.cep),
                SerEndcMun: Number(city.igbe),
                SerEndComplemento: client.address.complement,
                SerEndLgr: client.address.street,
                SerEndNumero: client.address.number,
                SerEndSiglaUF: state.code,
                SerEndTpLgr: client.address.type,
                SerEndxMun: city.name
              }
            }
          } catch (error) {
            console.error(error);
          }
        }
        break;
      case "Não informa":
      default:
        delete Servico.cMunIncidencia;
        ListaItens.forEach(item => {
          delete item.ItemcMunIncidencia;
        });
    }

    textLogger(`Incidencia: ${company.munIncidISS}`)

    let address = client.address;
    if (client.documentType === DOCUMENT_TYPE_CODES.rural && OS.idFarmer) {
      textLogger("Buscando endereço da fazenda para tomador")
      const selectedFarm = client.farm.find(farm => farm._id == OS.idFarmer);
      if (selectedFarm) {
        address = selectedFarm.address;
      }
    }

    textLogger("Montando RPS")
    const country = COUNTRIES.find(c => c.code == client.address.cep);
    let rps: RPS = {
      IncCult: 2,
      OptSN: company.crt === "1" || company.crt === "4" ? 1 : 2,
      RPSNumero: operation.docFiscalServico ? Number(company.numberInvoiceNFS) : Number(company.numberGerencial),
      RPSSerie: operation.docFiscalServico ? `${company.serieInvoiceNFS}` : `${company.serieGerencial}`,
      RPSTipo: 1,
      Status: 1,
      dEmis: this.getDate(),
      dCompetencia: this.getDate(),
      tpAmb: environment.migrate.tpAmb,
      LocalPrestServ: 1,
      natOp: Number(company.exigibilidade) || 1,
      Prestador,
      Servico,
      ListaItens,
      ListaParcelas: this.getPagsOfOS(OS, 'nfse') as Parcela[],
      Tomador: {
        TomaRazaoSocial: client.name,
        TomaBairro: address.neighborhood,
        TomaComplemento: address.complement,
        TomaEmail: client.email,
        TomaEndereco: address.street,
        TomaNumero: address.number,
        TomaTelefone: client.phone1,
        TomaUF: Boolean(address) ? await this._addressService.getState(address.state)?.code : undefined,
        TomatpLgr: address.type,
        TomacMun: Boolean(address) ? Number((await this._addressService.getCity(address.city))?.igbe) : undefined,
        TomaxMun: Boolean(address) ? ((await this._addressService.getCity(address.city))?.name) : undefined,
        TomaCEP: Number((address.cep || '').replace(/\D/g, '')),
        TomaPais: 'Brasil'
      },
      NFSOutrasinformacoes: infAdic
    }

    const retentions = MigrateService.sumNFSeTaxes(rps, {
      labors,
      company,
      operation,
      client
    });

    // `Cliente: ${client.name} - O.S.: ${OS.codeSystem} - Consultor: ${user?.name} - Modelo: ${vehicle.model} - Chassi: ${vehicle.vin || ''} - Fab/Mod: ${vehicle.year || ''}/${vehicle.modelYear || ''} - Placa: ${vehicle.plate || ''} - KM: ${vehicle.km || ''} - Cor: ${vehicle.color || ''} ${operation.operationMessage} ${OS.observation || ''}`

    textLogger("Ajustando documento do tomador")
    switch (client.documentType) {
      case DOCUMENT_TYPE_CODES.physical:
      case DOCUMENT_TYPE_CODES.rural:
        rps['Tomador']['TomaCPF'] = client.document.replace(/\D/g, '');

        if (client.documentType === DOCUMENT_TYPE_CODES.rural && selectedFarm) {
          rps['Tomador']['TomaIE'] = (client.contribICMS) ? client.rg : selectedFarm.inscRural;
        }

        break;
      case DOCUMENT_TYPE_CODES.juridic:
        rps['Tomador']['TomaCNPJ'] = client.document.replace(/\D/g, '');
        if ((company.address.city === client.address.city) && client.insMun) {
          rps['Tomador']['TomaIM'] = client.insMun;
        }
        break;
      default:
        rps['Tomador'] = {
          TomaRazaoSocial: client.name,
          TomaPais: country ? country.name : 'Brasil',
          TomaEmail: client.email
        };
        break;
    }


    if (company.regEspTrib) {
      rps.RegEspTrib = company.regEspTrib as any;
    }

    if (company.verificaRPS) {
      rps.cVerificaRPS = company.verificaRPS;
    }

    if (approvedOsLabors && approvedOsLabors.length) {
      rps = await this.adjustRPStoPattern(company, rps, approvedOsLabors[0].labor.id, OS.valueDiscountLabors);
    }


    let nfse: NFSe = {
      ModeloDocumento: 'NFSE',
      Versao: 1,
      RPS: [rps]
    }


    if (retentions.totalFederalRetentions != 0 && !isNaN(retentions.totalFederalRetentions)) {
      const retMsg = `Valor dos impostos retidos: R$ ${Money(retentions.totalFederalRetentions)} `;

      rps.NFSOutrasinformacoes = retMsg + '- ' + rps.NFSOutrasinformacoes;
    }

    return nfse;
  }

  /** Adjust the RPS to NFSe pattern */
  public async adjustRPStoPattern(company: Company, rps: RPS, laborId?: string, discount = 0) {

    const textLogger = this._logReporter.textLogger("adjustRPStoPattern", LOG_REPORTER_KEYS.INVOICE);

    let person: Client;

    textLogger("Ajustando nota ao padrão de NFSe")
    textLogger(`PADRÃO: ${company.patternNFSE}`)
    switch (company.patternNFSE) {
      case "NFS-e Nacional":
        rps.Servico.codtributNacional = company.tributMunicipio;
        break;
      case "ABACO":
        try {
          let tomador = rps['Tomador'];
          const documento = tomador['TomaCPF'] || tomador['TomaCNPJ'] || '';
          const p = await this._clientService.getByDocument(documento);
          person = p;
        } catch (error) {
          console.error(error)
        }
        rps['IncCult'] = 2;
        break;
      case "ISS.NET":
        rps.Servico.TributMunicipio = company.cnaeCode;
        rps.Servico.Cnae = Number(company.cnaeCode);
        for (const labor of rps.ListaItens) {
          labor['ItemIteListServico'] = "14.01";
        }
        if (person && person.phone1) {
          rps.Tomador.TomaTelefone = Utilities.removeMask(person.phone1)
        }
        break;
      case "ISS.NET (Brasília)":
        rps.Servico.IteListServico = "14.01";
        rps.Servico.TributMunicipio = "1401";
        rps.Servico.Cnae = Number(company.cnaeCode);
        for (const labor of rps.ListaItens) {
          labor['ItemIteListServico'] = "14.01";
        }
        if (person && person.phone1) {
          rps.Tomador.TomaTelefone = Utilities.removeMask(person.phone1)
        }
        rps.Servico.Valores.ValAliqISS = 0;
        break;
      case "GISS":
        if (rps.Servico.IteListServico) {
          // transform 1401 into 14.01
          rps.Servico.IteListServico = rps.Servico.IteListServico.replace(/(\d{2})(\d{2})/g, "\$1.\$2");
        }

        // only send federal taxes under retentions
        // (http://atendimento.tecinco.com.br/otrs/index.pl?Action=AgentTicketZoom;TicketID=286760;ArticleID=1175952)
        if (rps.Servico.Valores.INSSRetido != 1) {
          for (const item of rps.ListaItens) {
            item.ItemvalBCINSS = 0;
            item.ItemValAliqINSS = 0;
            item.ItemValINSS = 0;
          }
          rps.Servico.Valores.ValBCINSS = 0;
          rps.Servico.Valores.ValAliqINSS = 0;
          rps.Servico.Valores.ValINSS = 0;
        }
        delete rps.Servico.Valores.INSSRetido;

        if (rps.Servico.Valores.IRRetido != 1) {
          for (const item of rps.ListaItens) {
            item.ItemValBCIRRF = 0;
            item.ItemValAliqIR = 0;
            item.ItemValIR = 0;
          }
          rps.Servico.Valores.ValBCIRRF = 0;
          rps.Servico.Valores.ValAliqIR = 0;
          rps.Servico.Valores.ValIR = 0;
        }
        delete rps.Servico.Valores.IRRetido;

        if (rps.Servico.Valores.CSLLRetido != 1) {
          for (const item of rps.ListaItens) {
            item.ItemValBCCSLL = 0;
            item.ItemValAliqCSLL = 0;
            item.ItemValCSLL = 0;
          }
          rps.Servico.Valores.ValBCCSLL = 0;
          rps.Servico.Valores.ValAliqCSLL = 0;
          rps.Servico.Valores.ValCSLL = 0;
        }
        delete rps.Servico.Valores.CSLLRetido;

        if (rps.Servico.Valores.COFINSRetido != 1) {
          for (const item of rps.ListaItens) {
            item.ItemValBCCOFINS = 0;
            item.ItemValAliqCOFINS = 0;
            item.ItemValCOFINS = 0;
          }
          rps.Servico.Valores.ValBCCOFINS = 0;
          rps.Servico.Valores.ValAliqCOFINS = 0;
          rps.Servico.Valores.ValCOFINS = 0;
        }
        delete rps.Servico.Valores.COFINSRetido;

        if (rps.Servico.Valores.PISRetido != 1) {
          for (const item of rps.ListaItens) {
            item.ItemValBCPIS = 0;
            item.ItemValAliqPIS = 0;
            item.ItemValPIS = 0;
          }
          rps.Servico.Valores.ValBCPIS = 0;
          rps.Servico.Valores.ValAliqPIS = 0;
          rps.Servico.Valores.ValPIS = 0;
        }
        delete rps.Servico.Valores.PISRetido;
      case "GINFES":
        for (const item of rps.ListaItens) {
          const itemDesc = (item.ItemDescCondicionado || 0) + (item.ItemDescIncondicionado || 0);
          item.ItemBaseCalculo -= itemDesc;
          item.ItemVlrLiquido -= itemDesc;
          item.ItemVlrTotal = item.ItemVlrLiquido;
          item.ItemvUnit = item.ItemVlrLiquido / item.ItemQtde;
        }
        break;
      case "SEFINNet":
        for (const item of rps.ListaItens) {
          const itemDesc = (item.ItemDescCondicionado || 0) + (item.ItemDescIncondicionado || 0);
          item.ItemBaseCalculo -= itemDesc;
          item.ItemVlrLiquido -= itemDesc;
          item.ItemVlrTotal = item.ItemVlrLiquido;
          item.ItemvUnit = item.ItemVlrLiquido / item.ItemQtde;
          item.ItemBaseCalculo = 0;

          if (rps.Servico.Valores.ISSRetido == 1) {
            item.ItemExigibilidadeISS = 1;
            item.ItemAliquota = Number(item.ItemAliquota) || 0;
          } else {
            item.ItemAliquota = 0;
            item.ItemExigibilidadeISS = 3;
          }
        }


        rps.Servico.Valores.ValBaseCalculo = 0;
        if (rps.Servico.Valores.ISSRetido == 2) {
          rps.Servico.Valores.ValISS = 0;
        }

        let tomador = rps['Tomador'];
        if (tomador) {
          const documento = tomador['TomaCPF'] || tomador['TomaCNPJ'] || '';
          if (documento) {
            const p = await this._clientService.getByDocument(documento);
            person = p;
          }
        }

        if (person) {
          // define CFPS as tributMunicipio
          if (person.documentType === DOCUMENT_TYPE_CODES.foreign) {
            // Para Tomador ou Destinatário estabelecido ou domiciliado no exterior
            rps.Servico.TributMunicipio = "9204";
          } else {
            const clientState = await this._addressService.getState(person.address.state);
            const clientCity = await this._addressService.getCity(person.address.city);
            const isSameState = company.address.state === clientState.id;
            const isSameCity = isSameState && company.address.city === clientCity.id;
            if (isSameCity) {
              // Para Tomador ou Destinatário estabelecido ou domiciliado no município
              rps.Servico.TributMunicipio = "9201";
            } else if (isSameState) {
              // Para Tomador ou Destinatário estabelecido ou domiciliado fora do município
              rps.Servico.TributMunicipio = "9202";
            } else {
              // Para Tomador ou Destinatário estabelecido ou domiciliado em outro estado da federação
              rps.Servico.TributMunicipio = "9203";
            }
          }
        }
        break
      case "DUETO":
        rps.Servico.TributMunicipio = company.cnaeCode;
      case "Betha 2.0":
        rps['IncCult'] = 2;
        break;
      case "PMJP":
        delete rps.RegEspTrib
        break;
      case 'NF PAULISTANA':
        if (laborId) {
          const flabor = await this._laborService.get(laborId);
          if (flabor && flabor.LCCode) {
            rps['Servico']['IteListServico'] = String(flabor.LCCode);
          }
        }
        break;
      case "Lexsom":
        rps['IncCult'] = 2;
        break;
      case "Elotech":
        rps.IncCult = 2;
        rps.Servico.Valores.Tributavel = "S";
        rps.Servico.Cnae = Number(company.cnaeCode);
        rps.natOp = 7; // fixo ELotech
        if (rps.Prestador && rps.Prestador.enderPrest) {
          rps.Servico.cMun = rps.Prestador.enderPrest.cMun;
          rps.Servico.cMunIncidencia = rps.Prestador.enderPrest.cMun;
        }
        rps.ListaItens.forEach(ite => {
          ite.ItemExigibilidadeISS = 7; // fixo Elotech
          ite.ItemTributavel = "S";
          ite.ItemcMunIncidencia = rps.Servico.cMun;
        });

        rps.Servico.Valores.PISRetido = 2;
        rps.Servico.Valores.COFINSRetido = 2;
        rps.Servico.Valores.CppRetido = 2;
        rps.Servico.Valores.INSSRetido = 2;
        rps.Servico.Valores.CSLLRetido = 2;
        rps.Servico.Valores.IRRetido = 2;
        rps.Servico.Valores.OutrasRetencoesRetido = 2;

        delete rps.RegEspTrib; // qualquer valor informado para `RegEspTrib` dá rejeição
        delete rps.Servico.TributMunicDesc;
        delete rps.Tomador.TomaIE;
        break;
      case "Bauhaus":
        rps['Servico']['Cnae'] = Number(company.cnaeCode);
        break;
      case "GIAP":
        rps['Tomador']['TomaPais'] = "Brasil";
        for (const item of rps['ListaItens']) {
          item.ItemvlDed = 0;
          if (!item.ItemcCnae) {
            item.ItemcCnae = company.cnaeCode
          }
        }
        break;
      case "SIGCORP ABRASF":
        rps.IncCult = 2;
      case "SMARAPDSil WS2":
      case "HM2 Soluções":
      case "WEB ISS 2.0":
      case "TIPLAN 2.0":
      case "Futurize":
        switch (company.exigibilidade) {
          case 7: rps['natOp'] = 1; // Exigível
            break;
          case 8: rps['natOp'] = 2; // Não incidência
            break;
          case 3: rps['natOp'] = 3; // Isenção
            break;
          case 9: rps['natOp'] = 4; // Exportação
            break;
          case 4: rps['natOp'] = 5; // Imunidade
            break;
          case 5: rps['natOp'] = 6; // Exigibilidade suspensa por decisão judicial
            break;
          case 6: rps['natOp'] = 7; // Exigibilidade suspensa por processo administrativo
            break;
          default:
            rps['natOp'] = Number(company.exigibilidade) || 1;
            break;
        }
        break;
      case "Equiplano":
        const tomaDocumento = rps.Tomador.TomaCPF || rps.Tomador.TomaCNPJ;
        if (tomaDocumento && tomaDocumento.split('').every(char => char === '0')) {
          // do not send <Tomador> if his document is 0000000000
          delete rps['Tomador'];
        } else {
          rps['Tomador']['TomaPais'] = "Brasil";
        }
        break;
      case 'Prescon WS':
        rps['natOp'] = company.exigibilidade
        break;
      case 'Nota Natalense':
        delete rps.Servico.Valores.ValISS;
        delete rps.Servico.Valores.ValAliqISS;
        break;
      case 'Tecnos':
        if (rps.Servico.Valores.ISSRetido === 2) {
          rps.Servico.Valores.RespRetencao = 1;
        }
        if (rps.Servico.Valores.RespRetencao === 3) {
          rps.Servico.Valores.RespRetencao = 2;
        }
        break;
      case 'Embras Siap.Net':
        rps['Servico']['Cnae'] = Number(company.cnaeCode);
        break;
      case "IPM (Brusque)":
        try {
          let tomador = rps['Tomador'];
          const documento = tomador['TomaCPF'] || tomador['TomaCNPJ'] || '';
          const p = await this._clientService.getByDocument(documento);
          person = p;
        } catch (error) {
          console.error(error)
        }
        if (person && person.taxpayer) {
          rps.natOp = 11;
        }
        break;
      case "CECAM":
        rps.RPSSerie = rps.RPSSerie.padStart(3, "0");
        break;
      case "NFPSe WS":
        for (const item of rps.ListaItens) {
          const cnaeCode = item.ItemcCnae || company.cnaeCode;
          item.ItemTributMunicipio = cnaeCode; // ID CNAE conforme prefeitura
          const cnae = CNAE.find(c => c.idcnae == cnaeCode);
          if (cnae) {
            item.ItemcCnae = cnae.subclasse; // SUBCLASSE CNAE conforme prefeitura
          }
        }
        break;
      case "IPM (Araranguá)":
        delete rps.Servico.IteListServico;
        delete rps.Servico.TributMunicipio;
      default: // "IPM" and others
        break;
    }

    for (const item of rps.ListaItens) {
      delete item.ItemDescCondicionado;
      delete item.ItemDescIncondicionado;
      delete item.ItemvDesconto;
    }
    delete rps.Servico.Valores.ValDescIncond;
    delete rps.Servico.Valores.ValDescCond;

    return rps
  }
  async getCFOPofPart(part: Part, operation: Operation, sameState = true, devolution?: boolean): Promise<number> {
    const stateOperation = (sameState ? 'stateOperations' : 'interstateOperations');
    let cfopCode = "";

    if (!devolution) {
      // 1. get CFOP/interstateCFOP of part
      if (part.ICMS) {
        if (sameState) {
          cfopCode = part.ICMS.CFOP;
        } else {
          cfopCode = part.ICMS.CFOP_I;
        }
      }

      // 2. set CFOP according part group
      if (part.group && part.group.length && !part.groupObject) {
        let group = this.groupMap.get(part.group[0]);
        if (!group) {
          try {
            group = await this._groupService.getById(part.group[0]);
          } catch (err) {
            console.error(`Grupo não encontrado (${part.group[0]})`, err)
          }
          if (group) {
            this.groupMap.set(part.group[0], group);
          }
        }
        part.groupObject = group;
      }
      if (!cfopCode && part.groupObject) {
        switch (part.groupObject.type) {
          case 2: // Combustível
          case 3: // Lubrificantes
            cfopCode = operation[stateOperation].naturalezaCombLubrificante;
            break;
          case 7: // Industrializado
            cfopCode = operation[stateOperation].naturalezaItemIndustrializado;
            break;
        }
      }
    }

    if (!cfopCode && operation) {
      const isSimpleNational = this._dataService.company.crt === "1" || this._dataService.company.crt === "4";
      const CSTGROUP10 = isSimpleNational ? ['10', '70', '201', '203'] : ['10'];
      const CSTGROUP60 = isSimpleNational ? ['60', '500'] : ['60'];

      // 3. set specific CFOP according CST of part (operation nature)
      if (part.ICMS && part.ICMS.CST) {
        const partCST = part.ICMS.CST;
        if (CSTGROUP10.includes(partCST)) {
          cfopCode = operation[stateOperation].naturalezaSTConsumidorCST10;
        } else if (CSTGROUP60.includes(partCST)) {
          cfopCode = operation[stateOperation].naturalezaSTConsumidorCST60;
        } else if (partCST === '00') {
          cfopCode = operation[stateOperation].naturalezaNaoContribuinte;
        }
      }

      // 4. set specific CFOP according CST of operation (operation nature)
      if (!cfopCode && operation.ICMS) {
        const operationCST = operation.ICMS.CST;
        if (CSTGROUP10.includes(operationCST)) {
          cfopCode = operation[stateOperation].naturalezaSTConsumidorCST10;
        } else if (CSTGROUP60.includes(operationCST)) {
          cfopCode = operation[stateOperation].naturalezaSTConsumidorCST60;
        } else if (operationCST === '00') {
          cfopCode = operation[stateOperation].naturalezaNaoContribuinte;
        }
      }
    }

    // 5. set CFOP global of operation
    if (!cfopCode && operation && operation.ICMS) {
      if (sameState) {
        cfopCode = operation.ICMS.CFOP;
      } else {
        cfopCode = operation.ICMS.CFOP_I;
      }
    }

    return Number(cfopCode || '');
  }

  async emitNFfromOS(OS: MkgOS, model: "NFCe" | "NFe", CPF?: string, onlyOne?: {
    skipLabors?: boolean,
    skipParts?: boolean
  }) {
    const errorLogger = this._logReporter.errorLogger("emitNFfromOS", LOG_REPORTER_KEYS.INVOICE);
    try {
      this._layout.loader = true;
      const textLogger = this._logReporter.textLogger("emitNFfromOS", LOG_REPORTER_KEYS.INVOICE);

      textLogger(`Usuário logado: ${this._dataService.user.email}`);
      textLogger(`Empresa logada: ${this._dataService.company.cnpj}`);
      textLogger(`id da OS: ${OS?.id}`);
      textLogger(`Número da OS: ${OS?.codeSystem}`);
      textLogger(`Valor liq. da OS: ${OS?.liquidValue?.toFixed(2)}`);
      textLogger(`Valor peças ${OS?.liquidValueParts?.toFixed(2)}`);
      textLogger(`Valor serviços ${OS?.liquidValueLabors?.toFixed(2)}`);
      textLogger(`modelo de nota de peças: ${model}`);
      if (onlyOne) {
        if (onlyOne.skipLabors) {
          textLogger(`Emitir apenas nota de peças`);
        } else {
          textLogger(`Emitir apenas nota de serviços`);
        }
      }

      if (CPF) {
        textLogger(`CPF na nota: ${CPF}`);
      }

      const {
        companyLabors,
        companyParts,
        matrixIsFor,
        operationParts,
        operationLabors } = await this._roService.getMatrixConfigFor(OS);

      const osParts = OS.parts.filter(part => part.available === partStates.approved.id);

      textLogger(`Emitir nota de peças em ${companyParts?.cnpj}`);
      textLogger(`Operação para nota de peças: ${operationParts?.id}`);
      textLogger(`Emitir nota de serviços em ${companyLabors?.cnpj}`);
      textLogger(`Operação para nota de serviços: ${operationLabors?.id}`);

      textLogger("Verificando se a empresa de peças está criada na Migrate");
      await this.isCompanyCreated(companyParts);
      textLogger("Verificando se a empresa de serviços está criada na Migrate");
      await this.isCompanyCreated(companyLabors);

      textLogger("Verificando se essa OS já possui nota de peças emitida");
      const partsInvoices = await this.getAll_NF(companyParts, { osId: OS.id });
      const nfPartsCreated = partsInvoices.find(invoice =>
        invoice.osId && invoice.osId === OS.id && ![
          INVOICE_STATE.DEVOLUTIONED,
          INVOICE_STATE.NOT_DEVOLUTIONED,
          INVOICE_STATE.GERENCIAL_DEVOLUTIONED,
          INVOICE_STATE.USELESS,
          INVOICE_STATE.DENIED,
          INVOICE_STATE.CANCELED
        ].includes(invoice.state)
      );
      textLogger(`R: ${!!nfPartsCreated ? "SIM" : "NÃO"}`);

      // update/emit NFe/NFCe
      let nfResp: NF_response;
      let nfErrors;
      let nf: NFCe | NFe;
      try {
        try {
          if (osParts.length && !onlyOne?.skipParts) {
            if (model === "NFCe") {
              textLogger("Montando cupom fiscal");
              nf = await this.assemblyNFCeFromOS(OS, operationParts, companyParts, CPF);
            } else {
              textLogger("Montando nota");
              nf = await this.assemblyNFeFromOS(OS, operationParts, companyParts);
            }
            textLogger(`Nota de peças Nº:${nf?.ide?.nNF}, Série:${nf?.ide?.serie}`);
          } else {
            textLogger("A OS não posssui peças");
          }

        } catch (error) {
          console.error('\nErro ao montar a nota fiscal\n\n', error);
        }

        if (nf) {
          if (nfPartsCreated && !operationParts.docFiscal) {
            textLogger("Atualizando nota não-fiscal de peças");
            let items = this.getItemsToUpdate(nfPartsCreated, osParts);
            nfResp = await this.updateNF({
              company: companyParts,
              id: nfPartsCreated.id,
              model,
              operation: operationParts,
              OS,
              newNF: nf,
              parts: items,
              invoiceNumber: nfPartsCreated.request.Documento.ide.nNF
            });
          } else {
            let items = this.getItemsToRegister(osParts);
            if (companyParts.migrateIntegrated && operationParts.docFiscal) {
              if (nfPartsCreated) {
                textLogger("Atualizando nota fiscal de peças");
                // alreadCreated.id, model as any, OS.operationId, osId, items, null, alreadCreated.request.Documento.ide.nNF
                nfResp = await this.updateNF({
                  company: companyParts,
                  id: nfPartsCreated.id,
                  model,
                  operation: operationParts,
                  OS,
                  newNF: nf,
                  parts: items,
                  invoiceNumber: nfPartsCreated.request.Documento.ide.nNF
                });
              } else {
                textLogger("Criando nota fiscal de peças");
                nfResp = await this.registerFiscalNF({
                  invoice: nf,
                  osId: OS.id,
                  operationId: operationParts.id,
                  parts: items,
                  company: companyParts
                });
              }
            } else {
              textLogger("Criando nota não-fiscal de peças");
              nfResp = await this.registerGerencialNF({
                invoice: nf,
                osId: OS.id,
                idOperation: operationParts.id,
                state: INVOICE_STATE.GERENCIAL,
                items,
                company: companyParts
              });
            }
          }
          textLogger(`id da nota de peças: ${nfResp.id}`);
        }

      } catch (error) {
        console.error(error);
        textLogger(`Erro ao ${nfPartsCreated ? 'atualizar' : 'emitir'} a nota`);
        errorLogger(error);
        console.error(`\nErro ao ${nfPartsCreated ? 'atualizar' : 'emitir'} a nota fiscal\n\n`, error);
      }

      // read errors
      textLogger("Interpretando resposta da nota de peças");
      nfErrors = this.getErrors(nfResp, false);

      const errStr = JSON.stringify(nfErrors);

      textLogger(`Rejeições da nota: ${errStr}`);

      if (nfResp) {
        // increase company invoice number
        if (nfResp.id && !nfPartsCreated) {
          // prevent to emit two gerencial invoices with the same company/number/serie
          if (Utilities.equalUnmasked(companyLabors.cnpj, companyParts.cnpj)) {
            if (!operationParts.docFiscal && !operationLabors.docFiscal) {
              textLogger("Aumentando numeração novamente (Local), pois as duas notas são gerenciais")
              companyLabors.numberGerencial = `${Number(companyLabors.numberGerencial) + 1}`;
            }
          }
        }

        // update invoice state
        if (companyParts.migrateIntegrated && operationParts.docFiscal) {
          if (!nfErrors) {
            textLogger("Atualizando status na nota de peças para APROVADA");
            await this.updateInvoiceState(nfResp.id, INVOICE_STATE.APPROVED, companyParts.cnpj);
          } else {
            textLogger("Atualizando status na nota de peças para REJEITADA");
            await this.updateInvoiceState(nfResp.id, INVOICE_STATE.REJECTED, companyParts.cnpj);
          }
        }
      }

      textLogger("Verificando se essa OS já possui nota de serviços emitida");
      const laborsInvoices = await this.getAll_NFSE(companyLabors, OS.id);
      const nfLaborsCreated = laborsInvoices.find(invoice => invoice.osId && invoice.osId === OS.id);
      textLogger(`R: ${!!nfLaborsCreated ? "SIM" : "NÃO"}`);

      // update/emit NFSe
      let nfseResp: NFSE_response;
      let nfseErrors;
      const approvedOsLabors = OS.labors.filter(labor => labor.available === laborStates.approved.id);

      if (((approvedOsLabors.length && OS.liquidValueLabors) || OS.thirdPartyServices.length) && !onlyOne?.skipLabors) {
        if (operationLabors.docFiscalServico) {
          textLogger(`${!!nfLaborsCreated ? "Atualizando" : "Criando"} nota fiscal de serviços`);
          nfseResp = await this.emitNFSe(companyLabors, operationLabors, OS); // create/update nfse
        } else {
          textLogger("Montando nota gerencial de serviços")
          const nfse = await this.assemblyNFSeFromOS(companyLabors, operationLabors, OS, OS?.idFarmer);
          textLogger(`${!!nfLaborsCreated ? "Atualizando" : "Criando"} nota não-fiscal de serviços`);
          nfseResp = await this.registerGerencialNFSe(nfse, OS.id, operationLabors.id, INVOICE_STATE.GERENCIAL, [], companyLabors) as any;
        }
        textLogger(`id na nota de serviços: ${nfseResp?.id}`);
        textLogger("Interpretando resposta da nota de serviços");
        nfseErrors = this.getErrors(nfseResp, true);
        textLogger(`Rejeições da nota de serviços: ${JSON.stringify(nfseErrors)}`);
      }

      // handle stock
      if (!nfErrors && osParts.length) {
        if (operationParts.changeStock) {
          textLogger("Criando movimentações de estoque");
        } else {
          textLogger("A operação de peças não movimenta estoque");
        }
        for (const roPart of osParts) {
          let updatedPart = await this._partService.get(roPart.part.id);
          // change stock
          updatedPart = await this._stockController.onOSfinished(updatedPart, roPart.amount(), {
            caller: "RoDetailComponent.emitNF(); MigrateService.emitNFfromOS()",
            invoice: nfResp,
            operation: operationParts,
            OS,
            senderID: OS.client.id,
            value: roPart.saleValue()
          });
        }
      }

      // NO ERRORS
      if (!nfErrors && !nfseErrors) {

        let matrixCnpj = this._dataService.company.cnpj;
        let filialCnpj = this._dataService.company.cnpj;
        if (matrixIsFor !== "none") {
          matrixCnpj = matrixIsFor === "parts" ? companyParts.cnpj : companyLabors.cnpj;
          filialCnpj = matrixIsFor === "labors" ? companyParts.cnpj : companyLabors.cnpj;
        }

        // activate matrix OS titles
        textLogger("Ativando títulos na matriz");
        const matrixTitles = await firstValueFrom(this._cashTitleService.findOsTitles(OS.id, OS.codeSystem, matrixCnpj));

        if (!matrixTitles.length) {
          textLogger("Não há títulos na matriz");
        }

        for (const title of matrixTitles) {
          if (title.adiantamento) {
            textLogger(`Título "${title?._id}" não será atualizado por ser de adiantamento`);
            continue;
          }

          title['status'] = 1;
          const paymentCondition = await this._paymentService.getById(title.paymentCondition as any);
          if ((await paymentCondition).form === PAYMENT_METHODS.credit) {
            title['expirationDate'] = new Date();
          }
          try {
            if (nfResp && nfResp.request) {
              title['invoiceNumber'] = this._invoicePropertyPipe.transform(nfResp, 'number');
              title['serial'] = this._invoicePropertyPipe.transform(nfResp, "serie");
            } else {
              title['invoiceNumber'] = this._invoicePropertyPipe.transform(nfseResp, 'number');
              title['serial'] = this._invoicePropertyPipe.transform(nfseResp, "serie");
            }
          } catch (error) {
            textLogger("Erro ao definir numero da nota no título");
            textLogger(error);
            console.error(error);
          }
          const updatedTitle = await this._cashTitleService.update(title, matrixCnpj);

          if (paymentCondition.form === PAYMENT_METHODS.bankSlip && this._dataService.company.boletos === BoletoModuleActivationStatus.enabled) {

            this.boletoTitles.push(updatedTitle.id);
          }
          textLogger(`Título "${title?._id}" ativado com sucesso em "${matrixCnpj}"`);
        }

        // activate filial OS titles
        textLogger("Ativando títulos na filial");
        const filialTitles = await firstValueFrom(this._cashTitleService.findOsTitles(OS.id, OS.codeSystem, filialCnpj));
        if (!filialTitles.length) {
          textLogger("Não há títulos na filial");
        }

        for (const title of filialTitles) {
          if (title.adiantamento) {
            textLogger(`Título "${title?._id}" não será atualizado por ser de adiantamento`);
            continue;
          }

            title['status'] = 1;
            try {
              if (nfResp && nfResp.request) {
                title['invoiceNumber'] = this._invoicePropertyPipe.transform(nfResp, 'number');
                title['serial'] = this._invoicePropertyPipe.transform(nfResp, "serie");
              } else {
                title['invoiceNumber'] = this._invoicePropertyPipe.transform(nfseResp, 'number');
                title['serial'] = this._invoicePropertyPipe.transform(nfseResp, "serie");
              }
            } catch (error) {
              textLogger("Erro ao definir numero da nota no título");
              errorLogger(error);
              console.error(error);
            }
            await this._cashTitleService.update(title, filialCnpj);
            // TODO: checar criação de boleto para as filiais
            textLogger(`Título "${title?._id}" ativado com sucesso em "${filialCnpj}"`);
          }

        // finish RO
        textLogger(`Movendo OS para "Faturadas"`)
        await this._roService.finish(OS.id)

        textLogger("Abrindo pop-up com resultados")
        // ask user to redirect
        let data: AskRedirectComponentDataType = {
          model: model,
          linkNf: (nfResp ? nfResp.id : ''),
          backTo: 'budget',
          companyLabors,
          boletoTitles: this.boletoTitles,
          companyParts,
          os: OS.id
        }
        if (OS) {
          data.osAsk = OS;
        }
        if (nfseResp && nfseResp.id) {
          data.linkNFSe = nfseResp.id;
        }
        if (OS.codeSystem) {
          data.codeSystem = OS.codeSystem;
        }

        this._layout.loader = false;
        /**
         *  @todo solve circular dependency
         * askRedirectComponent <=> MigrateService
         */
        this._migrateCommonService.openAskRedirectComponent(data, true);
        this.boletoTitles = []

        return nfResp;

      }

        textLogger("Abrindo pop-up com resultados");
        // ask user to redirect
        let data: AskRedirectComponentDataType = {
          model: model,
          linkNf: (nfResp ? nfResp.id : ''),
          backTo: 'budget',
          companyLabors,
          boletoTitles: this.boletoTitles,
          companyParts,
          os: OS.id
        };
        if (OS) {
          data.osAsk = OS;
        }
        if (nfseResp && nfseResp.id) {
          data.linkNFSe = nfseResp.id;
        }
        if (OS.codeSystem) {
          data.codeSystem = OS.codeSystem;
        }

        this._layout.loader = false;
        /**
         *  @todo solve circular dependency
         * askRedirectComponent <=> MigrateService
         */
        this._migrateCommonService.openAskRedirectComponent(data, true);
        this.boletoTitles = [];

      return nfResp;


    } catch (error) {
      errorLogger(error);
      this._snackbar.error('INVOICE.ERRORS', null, error);
      console.error(error);
    } finally {
      this._layout.loader = false;
    }
  }

  async emitNFSe(company: Company, operation: Operation, OS: MkgOS): Promise<NFSE_response> {

    const textLogger = this._logReporter.textLogger("emitNFSe", LOG_REPORTER_KEYS.INVOICE);
    const errorLogger = this._logReporter.errorLogger("emitNFSe", LOG_REPORTER_KEYS.INVOICE);

    textLogger(`cnpj nota serviço: ${company.cnpj}`)
    textLogger(`operação nota serviço: ${operation.id}`)
    textLogger(`OS: ${OS.id}`)
    if (!company.migrateIntegrated) {
      textLogger("A empresa não está integrada com a Migrate")
      return
    }
    const approvedOsLabors = OS.labors.filter(labor => labor.available === laborStates.approved.id);

    if (!approvedOsLabors || !approvedOsLabors.length) {
      if (!OS.thirdPartyServices || !OS.thirdPartyServices.length) {
        textLogger("A OS não possui serviços")
        return
      }
    }

    try {
      textLogger("buscando notas de serviços da empresa")
      const invoices = await this.getAll_NFSE(company, OS.id);
      textLogger("verificando se a nota já existe")
      const alreadCreated = invoices.find(invoice => invoice.osId === OS.id);
      textLogger("Montando nota de serviços a partir da OS")
      const nfse = await this.assemblyNFSeFromOS(company, operation, OS, OS?.idFarmer);
      const labors = approvedOsLabors.map(roLabor => roLabor.labor);

      let resp: NFSE_response;
      if (alreadCreated) {
        // update
        textLogger("Nota já criada")
        const items = this.getItemsToUpdate(alreadCreated, labors);
        textLogger("Atualizando nota")
        resp = await this.updateNFSe(alreadCreated.id, OS.id, operation.id, items, null, alreadCreated.request.Documento.RPS[0].RPSNumero);
        return resp;
      } else {
        // create
        textLogger("preparando itens para nota")
        const items = this.getItemsToRegister(approvedOsLabors);
        textLogger("Envia nota para a API")
        resp = await this.registerFiscalNFSe(nfse as NFSe, OS.id, operation.id, items, company);
        try {
          textLogger("Interpretando response da nota criada")
          const errors = this.getErrors(resp as any, true);
          if (!errors) {
            textLogger("Sem erros, atualizando status para efetivada")
            await this.updateInvoiceState(resp.id, INVOICE_STATE.APPROVED, company.cnpj, true)
          } else {
            textLogger("Rejeição, atualizando status para rejeitada")
            await this.updateInvoiceState(resp.id, INVOICE_STATE.REJECTED, company.cnpj, true)
          }
        } catch (error) {
          textLogger("Erro ao criar nota")
          errorLogger(error)
          console.error(error);
        }
      }
      return resp
    } catch (error) {
      textLogger("Erro ao atualizar/emitir a NFSe")
      errorLogger(error);
      console.error(`\nErro ao atualizar/emitir a NFSe\n\n`, error)
    }
  }

  /** avoid register the company more than one time */
  public async isCompanyCreated(company: Company) {
    return await this._migrateCommonService.isCompanyCreated(company);
  }

  /**
   * @param accessKey The migrate API access key for this company
   * @param lastNSU the company numberInvoice
   */
  public async registerCompany(accessKey: string, lastNSU: string, gerencial: boolean) {
    return await this._migrateCommonService.registerCompany(accessKey, lastNSU, gerencial);
  }

  /**
   *  @todo delete
   */
  async registerCompanyMKGO(company: Company) {
    const url = `${this.URL}/v1/companies/register`;
    const options = await firstValueFrom(this._dataService.httpOptions(false))
    const resp = await this._http.post(url, company, options).pipe(first()).toPromise();
    return resp
  }

  public async getCompanyByCNPJ(cnpj: string) {
    return await this._migrateCommonService.getCompanyByCNPJ(cnpj);
  }

  async getAll_NF(company: Company, filter?: {
    osId?: string,
    orderID?: string
  }): Promise<NF_response[]> {
    if (filter) {
      return await this._migrateCommonService.getAll_NF(company, filter);
    } else {
      return await this._migrateCommonService.getAll_NF(company);
    }
  }

  public async getManuallyNF(company: Company): Promise<NF_response[]> {
    // verify if this company is integrated with migrate
    try {
      const created = await this.isCompanyCreated(company);
      if (!created) {
        return []
      }
    } catch (error) {
      console.error(error)
      return []
    }

    let url = `${this.URL}/v1/invoice-mkgo/nfce/no/os`;
    const options = await firstValueFrom(this._dataService.httpOptions(false));
    const resp = await this._http.get(url, options).pipe(first()).toPromise();
    // return resp as any
    return (resp as any[]).map(nf => {
      let newDet = []
      if (!Array.isArray(nf.request.Documento.det)) {
        // det need be a array
        nf.request.Documento.det = [nf.request.Documento.det];
      }
      nf.request.Documento.det.slice().map(detItem => {
        let newItem = { ...detItem };
        /**
         * detItem.imposto.ICMS.ICMS00 = { ... }
         *
         * detItem.imposto.ICMS = { ... }
         */
        const icmsTag = detItem.imposto.ICMS;
        if (icmsTag) {
          const keys = Object.keys(icmsTag);
          const childName = keys.find(key => key.startsWith('ICMS'));
          if (childName) {
            newItem['imposto']['ICMS'] = icmsTag[childName]
          }
        }

        const ipiTag = detItem.imposto.IPI;
        if (ipiTag) {
          const keys = Object.keys(ipiTag);
          const childName = keys.find(key => key.startsWith('IPI'));
          if (childName) {
            let CSTIPI = ipiTag[childName];
            CSTIPI['CST_IPI'] = CSTIPI['subkey'];

            // xml.imposto.IPI.IPINT.CST
            (newItem as detItem55)['imposto']['IPI']['CSTIPI'] = CSTIPI
          }
        }

        newDet.push(newItem)
      })
      nf.request.Documento.det = newDet;
      return nf;
    }).reverse();
  }

  async getAll_NFSE(companyLabors: Company, osId?: string): Promise<NFSE_response[]> {
    if (osId) {
      return await this._migrateCommonService.getAll_NFSE(companyLabors, osId)
    } else {
      return await this._migrateCommonService.getAll_NFSE(companyLabors)
    }
  }

  /**
   * create invoice without emit into SEFAZ (GERENCIAL)
   * @param invoice The invoice to register
   * @param idOperation The operation id
   */
  public async registerGerencialNF(
    data: {
      invoice: NFCe | NFe,
      osId?: string,
      orderID?: string,
      idOperation: string,
      state: INVOICE_STATE.GERENCIAL | INVOICE_STATE.DEVOLUTIONED | INVOICE_STATE.GERENCIAL_DEVOLUTIONED | INVOICE_STATE.IMPORTED_VIA_XML | INVOICE_STATE.INPUT_WITHOUT_XML,
      items: ItemResponse[],
      company: Company,
      accessKey?: string,
      /** Pass true if you don't change the invoice number and serie, create as it was */
      keepNumberAndSerie?: boolean
    }): Promise<NF_response> {
    NfUtilities.removeSpecialCharactersOf(data.invoice);
    const url = `${this.URL}/v1/invoice-mkgo/nfce`;
    let body: Partial<NF_response> = {
      operationId: data.idOperation,
      state: data.state,
      response: {
        Documentos: <NF_response['response']['Documentos']>[{
          DocChaAcesso: data.accessKey
        }]
      } as any, // gerencial invoices don't have Migrate/Sefaz responses
      items: this.removeNullValuesOf(data.items)
    };

    // get updated company, preventing wrong values for number and serie
    const company = await this._companyService.get(data.company.id, true);

    const emitterDocument = this._invoicePropertyPipe.transform(
      <NF_response>{ request: { Documento: data.invoice } }, 'cnpj', company);
    body.cnpjEmitente = Utilities.removeMask(emitterDocument);

    if (data.keepNumberAndSerie) {
      body.numero = this._invoicePropertyPipe.transform(<NF_response>{ request: { Documento: data.invoice } }, "number", company);
      body.serie = this._invoicePropertyPipe.transform(<NF_response>{ request: { Documento: data.invoice } }, "serie", company);
    } else {
      const { numero, serie } = await this._getNextNumberAndSerieFor(company, emitterDocument, "NFe", false);
      body.numero = `${numero}`;
      body.serie = `${serie}`;
      data.invoice.ide.nNF = numero;
      data.invoice.ide.serie = serie;
    }

    body.request = { Documento: data.invoice };
    if (data.osId) {
      body.osId = data.osId;
    }
    if (data.orderID) {
      body.orderID = data.orderID;
    }
    let options = await firstValueFrom(this._dataService.httpOptions(company.cnpj));
    const resp = await firstValueFrom(this._http.post<[NF_response] | NF_response>(url, body, options));
    if (Array.isArray(resp)) {
      return resp[0];
    } else {
      return resp;
    }
  }

  public async registerGerencialNFSe(
    invoice: NFSe,
    osId: string,
    idOperation: string,
    state: INVOICE_STATE.GERENCIAL | INVOICE_STATE.DEVOLUTIONED | INVOICE_STATE.GERENCIAL_DEVOLUTIONED,
    items: ItemResponse[],
    company: Company
  ): Promise<NFSE_response> {
    NfUtilities.removeSpecialCharactersOf(invoice);
    const url = `${this.URL}/v1/invoice-mkgo/nfse`;
    let body: Partial<NFSE_response> = {
      operationId: idOperation,
      state: state,
      response: {
        Documento: invoice
      } as any, // gerencial invoices don't have Migrate/Mayor responses
      items: this.removeNullValuesOf(items)
    };

    // get updated company, preventing wrong values for number and serie
    company = await this._companyService.get(company.id, true);
    const emitterDocument = this._invoicePropertyPipe.transform(<NFSE_response>{ request: { Documento: invoice } }, 'cnpj', company);
    const { numero, serie } = await this._getNextNumberAndSerieFor(company, emitterDocument, "NFSe", false);

    body.cnpjEmitente = Utilities.removeMask(emitterDocument);
    body.numero = `${numero}`;
    invoice.RPS[0].RPSNumero = numero;
    body.serie = `${serie}`;
    invoice.RPS[0].RPSSerie = `${serie}`;

    if (osId) {
      body['osId'] = osId;
    }
    body['request'] = { Documento: invoice };

    const options = await firstValueFrom(this._dataService.httpOptions(company.cnpj));
    const resp = await firstValueFrom(this._http.post<[NFSE_response] | NFSE_response>(url, body, options));
    if (Array.isArray(resp)) {
      return resp[0]
    } else {
      return resp
    }
  }

  public async registerFiscalNF(data: {
    invoice: NFCe | NFe,
    osId?: string,
    orderID?: string,
    operationId: string,
    parts: ItemResponse[],
    company: Company
  }): Promise<NF_response> {
    if (!Object.keys(data.invoice).length) {
      throw new Error("Invalid document");
    }
    // ensure have pag tag
    if (!data.invoice.pag || !data.invoice.pag.length) {
      data.invoice['pag'] = DEFAULT_PAG;
    }
    NfUtilities.removeSpecialCharactersOf(data.invoice);
    const url = `${this.URL}/v1/invoice-mkgo/nfce/api`;
    let body: Partial<NF_response> = {
      "operationId": data.operationId,
      "state": INVOICE_STATE.PENDING,
      "items": this.removeNullValuesOf(data.parts)
    };

    // get updated company, preventing wrong values for number and serie
    const company = await this._companyService.get(data.company.id, true);
    const model = data.invoice.ModeloDocumento;
    const emitterDocument = this._invoicePropertyPipe.transform(<NF_response>{ request: { Documento: data.invoice } },
      'cnpj', company);
    const { numero, serie } = await this._getNextNumberAndSerieFor(data.company, emitterDocument, model, true);

    if (data.osId) {
      body.osId = data.osId;
    }

    if (data.orderID) {
      body.orderID = data.orderID;
    }
    delete data.invoice.ide['accessKey'];
    let cleanInvoice = Utilities.removeNull(data.invoice, true) as NFe | NFCe;
    cleanInvoice.ide.nNF = numero;
    cleanInvoice.ide.serie = serie;
    body.numero = `${numero}`;
    body.serie = `${serie}`;
    body.cnpjEmitente = Utilities.removeMask(emitterDocument);
    body.request = { Documento: cleanInvoice };

    let options = await firstValueFrom(this._dataService.httpOptions(company.cnpj));
    const resp = await firstValueFrom(this._http.post(url, body, options));
    return resp as NF_response;
  }

  /**
  * @param invoice The invoice to register
  * @param osId The id of OS that created this invoice, or operation id
  * @param state use a `INVOICE_STATE` key value
  * @param labors use `getItemsToUpdate()` function to get this value
  */
  async registerFiscalNFSe(invoice: NFSe, osId: string, operationId: string, labors: ItemResponse[], company: Company): Promise<NFSE_response> {
    if (!Object.keys(invoice).length) {
      throw new Error("Invalid document");
    }
    NfUtilities.removeSpecialCharactersOf(invoice)
    let url = `${this.URL}/v1/invoice-mkgo/nfse/api`;
    let body: Partial<NFSE_response> = {
      "operationId": operationId,
      "state": INVOICE_STATE.PENDING,
      "request": {
        Documento: invoice
      },
      "items": labors
    };

    // get updated company, preventing wrong values for number and serie
    company = await this._companyService.get(company.id, true);
    const emitterDocument = this._invoicePropertyPipe.transform(<NFSE_response>{ request: { Documento: invoice } }, 'cnpj', company);
    const { numero, serie } = await this._getNextNumberAndSerieFor(company, emitterDocument, "NFSe", true);

    body.cnpjEmitente = Utilities.removeMask(emitterDocument);
    body.numero = `${numero}`;
    invoice.RPS[0].RPSNumero = numero;
    body.serie = `${serie}`;
    invoice.RPS[0].RPSSerie = `${serie}`;

    const options = await firstValueFrom(this._dataService.httpOptions(company.cnpj));

    if (osId) {
      body['osId'] = osId
    }

    return firstValueFrom(this._http.post<NFSE_response>(url, body, options));
  }

  /**
   * @param osId id of OS or operation
   * @param state use a `INVOICE_STATE` key value
   * @param parts use `getItemsToUpdate()` function to get this value
   */
  // async updateNF(id: string, model: 'NFCe' | 'NFe', operationId: string, osId: string, parts: ItemResponse[], newNF?: NFe | NFCe, invoiceNumber?: number): Promise<NF_response> {
  async updateNF(
    data: {
      company: Company,
      id: string,
      model: 'NFCe' | 'NFe',
      operation: Operation,
      OS: MkgOS,
      parts: ItemResponse[],
      newNF?: NFe | NFCe,
      invoiceNumber?: number,
      orderID?: string
    }): Promise<NF_response> {
    let invoice: NFe | NFCe;

    // ensure have pag tag
    if (!data.newNF.pag || !data.newNF.pag.length) {
      data.newNF['pag'] = DEFAULT_PAG;
    }

    if (!data.operation.docFiscal) {
      // prevent to try emit again a gerencial invoice
      throw new Error("BLOQUEADA TENTATIVA DE CORREÇÃO FISCAL DE UMA NOTA GERENCIAL")
    }

    if (data.model === "NFCe") {
      if (data.OS) {
        invoice = await this.assemblyNFCeFromOS(data.OS, data.operation, data.company);
        if (data.newNF && data.newNF.dest) {
          invoice.dest = data.newNF.dest;
        }
      } else {
        invoice = data.newNF;
        invoice['ide']['indFinal'] = 1;
      }
    } else {
      if (data.OS) {
        invoice = await this.assemblyNFeFromOS(data.OS, data.operation, data.company)
        if (data.newNF && data.newNF.dest) {
          invoice.dest = data.newNF.dest as any;
        }
      } else {
        invoice = data.newNF;
      }
    }

    invoice = NfUtilities.calculateTotals(invoice, invoice.ide.finNFe == 4);

    // get emit updated
    if (!data.OS) {
      if (invoice.dest && invoice.dest.enderDest) {
        const document = invoice.dest.CNPJ_dest || invoice.dest.CPF_dest || invoice.dest['CNPJ'] || invoice.dest['CPF'];
        let person;
        try {
          const client = await this._clientService.getByDocument(document);
          person = client;
        } catch (error) {
          try {
            const supplier = await this._supplierService.getByDocument(document);
            person = supplier;
          } catch (error) {
            console.warn(`Cliente/fornecedor com documento ${document} não foi encontrado`)
          }
        }
        if (person) {
          invoice.dest = await this.getDest(person);
          if (person.documentType === DOCUMENT_TYPE_CODES.foreign) {
            invoice.ide.idDest = 3;
          }
          invoice['ide']['indFinal'] = (person as Client).type === "Consumidor" ? 1 : 0;
        }
      }
    }

    if (data.invoiceNumber) {
      invoice['ide']['nNF'] = data.invoiceNumber;
    }

    NfUtilities.removeSpecialCharactersOf(invoice);
    delete invoice['ide']['accessKey'];
    if (!invoice.ide.EmailArquivos) {
      delete invoice.ide.EmailArquivos
    }
    invoice.ide.dhEmi = this.getDate();
    let cleanInvoice = Utilities.removeNull(invoice, true)


    if (!Object.keys(invoice).length) {
      throw new Error("Invalid document");
    }


    const cnpj = data.company.cnpj;
    const url = `${this.URL}/v1/invoice-mkgo/nfce/${data.id}`;
    const options = await firstValueFrom(this._dataService.httpOptions(cnpj));

    let body = {
      "operationId": data.operation.id,
      "osId": data.OS?.id,
      "orderID": data.orderID,
      "state": INVOICE_STATE.PENDING,
      "request": {
        Documento: cleanInvoice
      },
      // "items": this.removeNullValuesOf(parts)
    };
    body['numero'] = this._invoicePropertyPipe.transform(body as any, 'number').toString();
    body['serie'] = this._invoicePropertyPipe.transform(body as any, 'serie').toString();
    body['cnpjEmitente'] = Utilities.removeMask(cnpj);

    if (!body['osId']) {
      delete body['osId'];
    }
    if (!body['orderID']) {
      delete body.orderID
    }
    const resp = await this._http.put(url, body, options).pipe(first()).toPromise();
    return resp as NF_response
  }

  async updateNFSe(id: string, osId: string, operationId: string, labors: ItemResponse[], newNFSE?: NFSe, invoiceNumber?: number): Promise<NFSE_response> {
    let company = this._dataService.company;
    let invoice: NFSe;
    let OS: MkgOS;
    let operation;
    if (osId) {

      await firstValueFrom(this._sessionOSservice.forceCloseAllSessions(osId));

      OS = await this._roService.get(osId);
      const roType = OS.type;
      if (OS.type) {
        if (roType.nfseCnpj && roType.nfseCnpj !== company.cnpj) {
          company = await this._companyService.getByCnpj(roType.nfseCnpj, true);
        }

        if (roType.operation) {
          operation = await this._operationService.getById(roType.operation);
        }
      }
    }

    if (!operation) {
      if (OS) {
        operation = await this._operationService.getById(OS.operation.id);
      } else {
        operation = await this._operationService.getById(operationId);
      }
    }

    if (osId) {
      invoice = await this.assemblyNFSeFromOS(company, operation, OS, OS?.idFarmer);
      console.log("Nova nota", invoice)
    } else {
      invoice = newNFSE;
    }
    if (invoiceNumber) {
      invoice['numero'] = invoiceNumber;
      invoice.RPS[0].RPSNumero = invoiceNumber;
    }
    invoice.RPS[0].dEmis = this.getDate();
    invoice.RPS[0].dCompetencia = this.getDate();

    if (!Object.keys(invoice).length) {
      throw new Error("Invalid document");
    }
    let url = `${this.URL}/v1/invoice-mkgo/nfse/${id}`;
    const options = await firstValueFrom(this._dataService.httpOptions(company.cnpj));
    NfUtilities.removeSpecialCharactersOf(invoice);
    const body = {
      "operationId": operationId,
      "state": INVOICE_STATE.PENDING,
      "request": {
        Documento: invoice
      },
      "items": labors.slice().map(item => {
        return {
          amount: item.amount,
          code: item.code,
          keyId: item.keyId,
          modify: item.modify || ITEM_MODIFY.not_modified
        }
      })
    };
    if (osId) {
      body["osId"] = osId;
      this._sessionOSservice.forceCloseAllSessions(osId).pipe(first()).subscribe();
    }
    return firstValueFrom(this._http.put<NFSE_response>(url, body, options));
  }

  async cancelInvoice(invoice: NF_response, reazon: string, cnpj: string): Promise<NF_response | NFSE_response> {
    const nf = invoice.request.Documento as NFCe | NFe;
    const nfSerie = nf.ide.serie;
    const nfNumber = nf.ide.nNF
    const url = `${this.URL}/v1/invoice-mkgo/nfce/${invoice.id}/cancel`;
    const options = await firstValueFrom(this._dataService.httpOptions(cnpj));

    const cancelEvent: InvoiceEvent = {
      NtfCnpjEmissor: cnpj,
      NtfNumero: nfNumber,
      NtfSerie: nfSerie.toString(),
      tpAmb: environment.migrate.tpAmb,
      EveInf: {
        EveDh: this.getDate(),
        EveFusoHorario: this.getFuso(),
        EveTp: INVOICE_EVENT_CODES["Cancelamento"],
        EvenSeq: 1,
        Evedet: {
          EveDesc: "Cancelamento",
          EvexJust: reazon
        }
      }
    };

    const body = {
      operationId: invoice.operationId,
      "state": invoice.state,
      request: {
        ModeloDocumento: invoice.request.Documento.ModeloDocumento,
        Versao: 4.0,
        Evento: cancelEvent,
      }
    };

    const resp = await this._http.put(url, body, options).pipe(first()).toPromise();
    return resp as any;
  }

  async correctionLetterInvoice(invoice: NF_response, reazon: string): Promise<NF_response | NFSE_response> {
    const nf = invoice.request.Documento as NFCe | NFe;
    const cnpj = this._invoicePropertyPipe.transform(invoice, "cnpj");
    const nfSerie = nf.ide.serie;
    const nfNumber = nf.ide.nNF;
    const url = `${this.URL}/v1/invoice-mkgo/nfce/${invoice.id}/cancel`;
    const options = await firstValueFrom(this._dataService.httpOptions(cnpj));
    let eventSeq = 1;
    if (invoice.response && invoice.response.Documentos && invoice.response.Documentos.length) {
      eventSeq = invoice.response.Documentos[0].DocEvenSeq ? (invoice.response.Documentos[0].DocEvenSeq + 1) : 1;
    }

    const correctionLetterEvent: InvoiceEvent = {
      NtfCnpjEmissor: nf.emit.CNPJ_emit,
      NtfCpfEmissor: nf.emit.CPF_emit,
      NtfNumero: nfNumber,
      NtfSerie: nfSerie.toString(),
      tpAmb: environment.migrate.tpAmb,
      EveInf: {
        EveDh: this.getDate(),
        EveFusoHorario: this.getFuso(),
        EveTp: INVOICE_EVENT_CODES["CC-e"],
        EvenSeq: eventSeq,
        Evedet: {
          EveDesc: "Carta de Correcao",
          EveCorrecao: reazon,
          EveCondUso: "A Carta de Correcao e disciplinada pelo paragrafo 1o-A do art. 7o do Convenio S/N, de 15 de dezembro de 1970 e pode ser utilizada para regularizacao de erro ocorrido na emissao de documento fiscal, desde que o erro nao esteja relacionado com: I - as variaveis que determinam o valor do imposto tais como: base de calculo, aliquota, diferenca de preco, quantidade, valor da operacao ou da prestacao; II - a correcao de dados cadastrais que implique mudanca do remetente ou do destinatario; III - a data de emissao ou de saida."
        }
      }
    };

    const body = {
      operationId: invoice.operationId,
      "state": invoice.state,
      request: {
        ModeloDocumento: invoice.request.Documento.ModeloDocumento,
        Versao: 4.0,
        Evento: correctionLetterEvent,
      }
    };

    const resp = await this._http.put(url, body, options).pipe(first()).toPromise();
    return resp as any;
  }

  /** @todo test */
  async cancelNFSe(invoice: NFSE_response, reazon: string): Promise<NF_response | NFSE_response> {
    const nfse = invoice.request.Documento as NFSe;
    const cnpj = nfse.RPS[0].Prestador.CNPJ_prest;
    const nfSerie = nfse.RPS[0].RPSSerie;
    const nfNumber = nfse.RPS[0].RPSNumero;
    const url = `${this.URL}/v1/invoice-mkgo/nfse/${invoice.id}/cancel`;
    const options = await firstValueFrom(this._dataService.httpOptions(cnpj));
    const cancelEvent: InvoiceEvent = {
      NtfCnpjEmissor: cnpj,
      NtfNumero: nfNumber,
      NtfSerie: nfSerie,
      tpAmb: environment.migrate.tpAmb,
      EveInf: {
        EveDh: this.getDate(),
        EveFusoHorario: this.getFuso(),
        EveTp: INVOICE_EVENT_CODES['Cancelamento'],
        EvenSeq: 1,
        Evedet: {
          EveDesc: "Cancelamento",
          EvexJust: reazon
        }
      }
    };

    const body = {
      operationId: invoice.operationId,
      "state": invoice.state,
      request: {
        ModeloDocumento: invoice.request.Documento.ModeloDocumento,
        Versao: "1.0",
        Evento: cancelEvent
      }
    };

    const resp = await this._http.put(url, body, options).pipe(first()).toPromise();
    return resp as any;
  }

  /**
   * Use only to delete a gerencial invoice
   */
  async deleteInvoice(id: string, type: 'nfce' | 'nfse', cnpj: string) {
    const url = `${this.URL}/v1/invoice-mkgo/${type}/${id}`;
    const options = await firstValueFrom(this._dataService.httpOptions(cnpj));
    const resp = await this._http.delete(url, options).pipe(first()).toPromise();
    return resp;
  }

  public async deny(id: string, invoice: NFe | NFCe, reazon: string, cnpj: string) {
    if (!invoice.ModeloDocumento) {
      invoice.ModeloDocumento = invoice.ide.mod == '55' ? "NFe" : "NFCe";
    }
    const states = await this._addressService.getStates();
    const emitState = states.find(state => state.code === invoice.emit.enderEmit.UF);
    const url = `${this.URL}/v1/invoice-mkgo/nfce/${id}/disable`;
    console.log("inutilizar", invoice);
    const body = {
      "ModeloDocumento": invoice.ModeloDocumento,
      "Versao": "4.00",
      "UfEmissor": Number(emitState.uf),
      "NumeroInicial": invoice.ide.nNF.toString(),
      "NumeroFinal": invoice.ide.nNF.toString(),
      "Serie": invoice.ide.serie.toString(),
      "Justificativa": reazon
    };
    const options = await firstValueFrom(this._dataService.httpOptions(cnpj));
    return firstValueFrom(this._http.post<string>(url, body, options).pipe(
      first(),
      map(resp => JSON.parse(resp))
    ));
  }


  public async getItemsFromInvoice(invoiceId: string, cnpj: string, isNFSE = false) {
    const url = `${this.URL}/v1/invoice-mkgo/${isNFSE ? 'nfse' : 'nfce'}/${invoiceId}/item`;
    const options = await firstValueFrom(this._dataService.httpOptions(cnpj));
    const resp = await this._http.get(url, options).pipe(first()).toPromise();
    return resp as ItemResponse[];
  }


  public async addItemsToInvoice(items: ItemResponse[], invoiceId: string, cnpj: string) {
    const url = `${this.URL}/v1/invoice-mkgo/nfce/${invoiceId}/item`;
    const options = await firstValueFrom(this._dataService.httpOptions(cnpj));
    const validItems = this.removeNullValuesOf(items);
    for (const item of validItems) {
      if (item.code) {
        item.code = (`${item.code}`).trim();
      }
    }
    const resp = await this._http.post(url, { items: validItems }, options).pipe(first()).toPromise();
    return resp;
  }

  /** @todo test */
  public async removeItemFromInvoice(itemId: string, invoiceId: string, cnpj: string) {
    const url = `${this.URL}/v1/invoice-mkgo/nfce/${invoiceId}/item/${itemId}`;
    const options = await firstValueFrom(this._dataService.httpOptions(cnpj));
    const resp = await this._http.delete(url, options).pipe(first()).toPromise();
    return resp;
  }

  public async updateItemIntoInvoice(item: ItemResponse, invoiceId: string, cnpj: string) {
    console.log({ item, invoiceId, cnpj })
    const url = `${this.URL}/v1/invoice-mkgo/nfce/${invoiceId}/item/${item['id']}`;
    const modifiedItem: ItemResponse = {
      amount: item.amount || -1, // full devolutioned items have -1 as amount avalable to devolution
      code: item.code,
      keyId: item.keyId,
      modify: ITEM_MODIFY.modified
    };
    console.log({ item, modifiedItem });
    const options = await firstValueFrom(this._dataService.httpOptions(cnpj));
    const resp = await this._http.put(url, modifiedItem, options).pipe(first()).toPromise();
    return resp;
  }

  private removeNullValuesOf<T>(array: T[]): T[] {
    for (const item of array) {
      for (const key of Object.keys(item)) {
        if (item[key] === null) {
          delete item[key];
        }
      }
    }
    return array;
  }

  async insertItemsIntoInvoice(items: ItemResponse[], invoiceId: string, cnpj: string) {
    const url = `${this.URL}/v1/invoice-mkgo/nfce/${invoiceId}/item`;
    const body = {
      items: NfUtilities.removeNull(items)
    }
    const options = await firstValueFrom(this._dataService.httpOptions(cnpj));
    await this._http.post(url, body, options).pipe(first()).toPromise()
  }

  async updateInvoiceState(invoiceId: string, state: number, cnpj: string, isNFSE = false) {
    if (!invoiceId) {
      return;
    }
    const url = `${this.URL}/v1/invoice-mkgo/${isNFSE ? 'nfse' : 'nfce'}/${invoiceId}/state/${state}`;
    const options = await firstValueFrom(this._dataService.httpOptions(cnpj));
    const resp = await this._http.put(url, null, options).pipe(first()).toPromise();
    return resp;
  }

  /** @todo test */
  async getImportedInvoices(): Promise<ImportedInvoice[]> {
    const url = `${this.URL}/v1/invoice`;
    const header = await firstValueFrom(this._dataService.httpOptions(false));
    const resp: ImportedInvoice[] = await this._http.get(url, header).pipe(first()).toPromise() as any;
    return resp;
  }

  /** @todo test */
  async updateImportedInvoice(invoiceId: string, eventType: number) {
    const url = `${this.URL}/v1/invoice/api/${invoiceId}`;
    const header = await firstValueFrom(this._dataService.httpOptions(false));
    const body = {
      eventType: eventType
    }
    const resp: any = await this._http.post(url, body, header).pipe(first()).toPromise();
    return resp;
  }

  /** @todo test */
  async getImportedByNSU(nsu: number) {
    const url = `${this.URL}/v1/invoice/${nsu}`;
    const header = await firstValueFrom(this._dataService.httpOptions(false));
    const resp: any = await this._http.get(url, header).pipe(first()).toPromise();
    return resp;
  }

  /**
   * Use to check if an invoice already is existent
   * @param invoiceNumber
   * @param serie
   * @param cnpjEmitente The cnpj of emitent/prestador
   * @param companyCnpj The cnpj of company to find the invoice
   * @returns A Promise with the error if the invoice is existent, otherwise null
   */
  async isInvoiceExistent(invoiceNumber: number | string, serie: string, cnpjEmitente: string, companyCnpj: string, model: "nfce" | "nfse"): Promise<boolean> {
    const unmasked = Utilities.removeMask(cnpjEmitente);
    const url = `${this.URL}/v1/invoice-mkgo/${model}/index/${invoiceNumber}/${serie}/${unmasked}`;
    const header = await firstValueFrom(this._dataService.httpOptions(companyCnpj));
    return firstValueFrom(this._http.get(url, header)
      .pipe(
        first(),
        catchError((error, _caught) => {
          if (error instanceof HttpErrorResponse) {
            switch (error.error.error) {
              case API_ERRORS.notFound:
                // the API not found an invoice with these config
                return of(false);
              default:
                console.error(`A nota nº${invoiceNumber}, serie ${serie} para o cliente ${cnpjEmitente} já existe`, error.error);
                return of(true);
            }
          }
          console.error(`A nota nº${invoiceNumber}, serie ${serie} para o cliente ${cnpjEmitente} já existe`, error);
          return of(true);
        }),
        map((resp) => {
          if (typeof resp == 'boolean') {
            return resp;
          }
          return true;
        })
      ));
  }




  async getUpdatedNF(invoice: NF_response, model: "NFe" | "NFCe") {
    const url = `${this.URL}/v1/invoice-mkgo/${model.toLowerCase()}/api/query/${invoice.id}`;
    const cnpj = this._invoicePropertyPipe.transform(invoice, 'cnpj', this._dataService.company);
    const header = await firstValueFrom(this._dataService.httpOptions(cnpj));
    const body = {
      NumeroInicial: Number(this._invoicePropertyPipe.transform(invoice, 'number')),
      NumeroFinal: Number(this._invoicePropertyPipe.transform(invoice, 'number')),
      Serie: this._invoicePropertyPipe.transform(invoice, 'serie').toString(),
      CnpjEmissor: Utilities.removeMask(cnpj),
      CnpjEmpresa: Utilities.removeMask(cnpj),
      ModeloDocumento: this._invoicePropertyPipe.transform(invoice, 'model'),
    };
    const resp: any = await this._http.post(url, body, header).pipe(first()).toPromise();
    return resp;
  }

  async getUpdatedNFSe(invoice: NFSE_response) {
    const url = `${this.URL}/v1/invoice-mkgo/nfse/api/query/${invoice.id}`;
    const cnpj = this._invoicePropertyPipe.transform(invoice, 'cnpj', this._dataService.company);
    const header = await firstValueFrom(this._dataService.httpOptions(cnpj));
    const body = {
      NumeroInicial: Number(this._invoicePropertyPipe.transform(invoice, 'number')),
      NumeroFinal: Number(this._invoicePropertyPipe.transform(invoice, 'number')),
      Serie: this._invoicePropertyPipe.transform(invoice, 'serie'),
      CnpjEmissor: Utilities.removeMask(cnpj),
      CnpjEmpresa: Utilities.removeMask(cnpj),
      ModeloDocumento: this._invoicePropertyPipe.transform(invoice, 'model'),
    };
    const resp: any = await this._http.post(url, body, header).pipe(first()).toPromise();
    return resp;
  }

  /**
   * @param part The item to verify
   * @param UFcons The UF where the combustible has sold
   * @returns data of tag `<comb>` if part have a ANP code
   */
  public getTagComb(part: Part, UFcons?: string): (detItem55['prod']['comb'] | undefined) {
    if (part.ANP) {
      let anpObj = ANP_CODES.find(obj => obj.code == part.ANP);
      if (anpObj) {
        return {
          cProdANP: Number(part.ANP),
          descANP: anpObj.description,
          UFcons
        }
      }
    }
    return undefined;
  }

  getTagCobr(finances: Finance[], OS?: MkgOS): NFe['cobr'] | null {
    if (!finances || !finances.length) {
      return null;
    }

    // calculating title values
    let fullValue = finances.reduce((acc, finance) => acc + finance.value, 0);
    fullValue = Number(fullValue.toFixed(2));

    let vOrig = fullValue;
    let vLiq = fullValue;
    let vDesc_cob = 0;
    if (OS) {
      vOrig = OS.liquidValueParts;
      vDesc_cob = 0;
      vLiq = OS.liquidValueParts;
    }

    const cobr: NFe['cobr'] = {
      fat: {
        nFat: '1',
        vDesc_cob,
        vLiq,
        vOrig
      },
      dup: []
    };

    for (const finance of finances) {
      let financeDups: NFe['cobr']['dup'] = [];

      // calculate the percentage of total this payment represents
      const conditionPercentage = Utilities.getPercentage(finance.value, fullValue, 10);
      const conditionValue = Utilities.calcPercentage(conditionPercentage, vLiq, "round", 10);

      const firstParcelPercentage = Math.floor(finance.paymentCondition.parcels[0].percentage);
      if (finance.paymentCondition.parcels.every(p => Math.floor(p.percentage) === firstParcelPercentage)) {
        // all parcels have the same value
        let parcelValue = conditionValue / finance.paymentCondition.parcels.length;
        let parcelDups: NFe['cobr']['dup'] = [];
        if (finance.paymentCondition.form === PAYMENT_METHODS.credit) {
          const parcel = finance.paymentCondition.parcels[0];
          let expiration = parcel.expirationDate || this._cashTitleService.getExpirationDate(parcel.days);
          if (finance.previewTitles?.length) {
            const customParcel = finance.previewTitles.find(pt => pt.parcel === parcel.seq);
            parcelValue = customParcel?.value;
            expiration = customParcel?.expiration;
          }

          let expirationMoment = moment(expiration);
          if (expirationMoment.isBefore(moment())) {
            /** Avoid rejection 900: 'Data de vencimento da parcela não informada ou menor que Data de Emissão' */
            expirationMoment = moment();
          }

          parcelDups.push({
            dVenc: expirationMoment.format("yyyy-MM-DD"),
            vDup: Number((parcelValue).toFixed(2)),
            nDup: ""
          });
        } else {
          for (const parcel of finance.paymentCondition.parcels) {
            let expiration = parcel.expirationDate || this._cashTitleService.getExpirationDate(parcel.days);
            if (finance.previewTitles?.length) {
              const customParcel = finance.previewTitles.find(pt => pt.parcel === parcel.seq);
              parcelValue = customParcel?.value;
              expiration = customParcel?.expiration;
            }

            let expirationMoment = moment(expiration);
            if (expirationMoment.isBefore(moment())) {
              /** Avoid rejection 900: 'Data de vencimento da parcela não informada ou menor que Data de Emissão' */
              expirationMoment = moment();
            }

            parcelDups.push({
              dVenc: expirationMoment.format("yyyy-MM-DD"),
              vDup: Number((parcelValue).toFixed(2)),
              nDup: ""
            });
          }
        }

        Utilities.fixCentsDifference(parcelDups, 'vDup', conditionValue);
        financeDups = financeDups.concat(parcelDups);

      } else {
        let parcelDups: NFe['cobr']['dup'] = [];
        if (finance.paymentCondition.form === PAYMENT_METHODS.credit) {
          const parcel = finance.paymentCondition.parcels[0];
          let parcelValue = Utilities.calcPercentage(parcel.percentage, conditionValue);
          let expiration = parcel.expirationDate || this._cashTitleService.getExpirationDate(parcel.days);
          if (finance.previewTitles?.length) {
            const customParcel = finance.previewTitles.find(pt => pt.parcel === parcel.seq);
            parcelValue = customParcel?.value;
            expiration = customParcel?.expiration;
          }

          let expirationMoment = moment(expiration);
          if (expirationMoment.isBefore(moment())) {
            /** Avoid rejection 900: 'Data de vencimento da parcela não informada ou menor que Data de Emissão' */
            expirationMoment = moment();
          }

          parcelDups.push({
            dVenc: expirationMoment.format("yyyy-MM-DD"),
            vDup: Number(parcelValue.toFixed(2)),
            nDup: ""
          });
        } else {
          for (const parcel of finance.paymentCondition.parcels) {
            let parcelValue = Utilities.calcPercentage(parcel.percentage, conditionValue);
            let expiration = parcel.expirationDate || this._cashTitleService.getExpirationDate(parcel.days);
            if (finance.previewTitles?.length) {
              const customParcel = finance.previewTitles.find(pt => pt.parcel === parcel.seq);
              parcelValue = customParcel?.value;
              expiration = customParcel?.expiration;
            }

            let expirationMoment = moment(expiration);
            if (expirationMoment.isBefore(moment())) {
              /** Avoid rejection 900: 'Data de vencimento da parcela não informada ou menor que Data de Emissão' */
              expirationMoment = moment();
            }

            parcelDups.push({
              dVenc: expirationMoment.format("yyyy-MM-DD"),
              vDup: Number(parcelValue.toFixed(2)),
              nDup: ""
            });
          }
        }

        Utilities.fixCentsDifference(parcelDups, 'vDup', conditionValue);
        financeDups = financeDups.concat(parcelDups);
      }

      cobr.dup = cobr.dup.concat(financeDups);
    }

    Utilities.fixCentsDifference(cobr.dup, "vDup", vLiq);

    // sort parcels by expiration date
    cobr.dup = cobr.dup.sort((dup1, dup2) => moment(dup1.dVenc).isAfter(moment(dup2.dVenc)) ? 1 : -1);

    // set nDup
    for (let index = 0; index < cobr.dup.length; index++) {
      cobr.dup[index].nDup = `${index + 1}`.padStart(3, '0');
    }

    return cobr;
  }


  private _getUTribByNCM(ncm: string): string {
    const option = UTRIB_BY_NCM.find(trib => trib.NCMs.includes(ncm));
    if (option) {
      return option.uTrib;
    }
    return "";
  }


  async getNFById(id: string, cnpj?: string) {
    const url = `${this.URL}/v1/invoice-mkgo/nfce/${id}`;
    const options = await firstValueFrom(this._dataService.httpOptions(cnpj));
    return firstValueFrom(this._http.get<NF_response>(url, options).pipe(first()));
  }

  async getNFSEById(id: string, cnpj?: string) {
    const url = `${this.URL}/v1/invoice-mkgo/nfse/${id}`;
    const options = await firstValueFrom(this._dataService.httpOptions(cnpj));
    return firstValueFrom(this._http.get<NFSE_response>(url, options).pipe(first()));
  }

  private async _getNextNumberAndSerieFor(company: Company, emitterCNPJ: string, docType: "NFe" | "NFCe" | "NFSe", isFiscal: boolean) {
    let numero: number;
    let serie: number;
    let isExistent = false;
    const MAX_ATTEMPS = 15;// prevent infinite looping
    let attemps = 0;

    if (isFiscal) {
      switch (docType) {
        case 'NFe':
          numero = Number(company.numberInvoice);
          serie = company.serieInvoice;
          break;
        case 'NFCe':
          numero = Number(company.numberInvoiceNFC);
          serie = company.serieInvoiceNFC;
          break;
        case 'NFSe':
          numero = Number(company.numberInvoiceNFS);
          serie = company.serieInvoiceNFS;
          break;
      }
    } else {
      numero = Number(company.numberGerencial);
      serie = company.serieGerencial;
    }

    do {
      // increase number until find a not existent combination
      isExistent = await this.isInvoiceExistent(numero + attemps, `${serie}`, emitterCNPJ, company.cnpj, docType == "NFSe" ? 'nfse' : 'nfce');
      if (isExistent) {
        attemps++;
      }
    } while (isExistent && attemps < MAX_ATTEMPS);

    if (attemps >= MAX_ATTEMPS) {
      const docName = isFiscal ? docType : "Operação interna";
      alert(`A ${docName} de Número:${numero}, Série: ${serie} para o Cliente: ${emitterCNPJ} já existe. Verifique numeração e tente novamente.`);
      return {
        numero, serie
      }
    } else {
      numero += attemps;
      const nextNumber = numero + 1;
      // update company with rigth value for the next emission
      if (isFiscal) {
        switch (docType) {
          case 'NFe':
            company.numberInvoice = `${numero}`;
            this._companyService.setInvoiceNumber(company.cnpj, 'numberInvoice', nextNumber)
              .pipe(first())
              .subscribe();
            break;
          case 'NFCe':
            company.numberInvoiceNFC = `${numero}`;
            this._companyService.setInvoiceNumber(company.cnpj, 'numberInvoiceNFC', nextNumber)
              .pipe(first())
              .subscribe();
            break;
          case 'NFSe':
            company.numberInvoiceNFS = `${numero}`;
            this._companyService.setInvoiceNumber(company.cnpj, 'numberInvoiceNFS', nextNumber)
              .pipe(first())
              .subscribe();
            break;
        }
      } else {
        company.numberGerencial = `${numero}`;
        this._companyService.setInvoiceNumber(company.cnpj, 'numberGerencial', nextNumber)
          .pipe(first())
          .subscribe();
      }
    }

    if (this._dataService.company.id == company.id) {
      this._dataService.company$.next(company);
    }
    return { numero, serie };
  }
}
