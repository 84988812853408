"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DAMAGE_TYPE = void 0;
var DAMAGE_TYPE;
(function (DAMAGE_TYPE) {
    DAMAGE_TYPE[DAMAGE_TYPE["crushed"] = 0] = "crushed";
    DAMAGE_TYPE[DAMAGE_TYPE["scratched"] = 1] = "scratched";
    DAMAGE_TYPE[DAMAGE_TYPE["broken"] = 2] = "broken";
    DAMAGE_TYPE[DAMAGE_TYPE["optional"] = 3] = "optional";
    DAMAGE_TYPE[DAMAGE_TYPE["fuel"] = 4] = "fuel";
})(DAMAGE_TYPE || (exports.DAMAGE_TYPE = DAMAGE_TYPE = {}));
