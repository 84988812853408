export enum MoveTypes {
    "moveStock-out" = 1,
    "noMoveStock-out" = 3,
    "moveStock-in" = 2,
    "noMoveStock-in" = 4,
    "amountAdded" = 5,
    "amountSubtracted" = 6,
    "openedRo" = 7,
    "turnedIntoRejected",
    "changedTheOperation",
    "rejectedComplementar",
    "approvedComplementar",
    "orderCreated",
    "orderCanceled",
    "moveStock-out-cancelled",
    "noMoveStock-out-cancelled",
    "moveStock-in-cancelled",
    "noMoveStock-in-cancelled",
};