import moment from "moment";
import { Parcel } from "./parcel";
import { PaymentCondition } from "./paymentCondition";
import { ObjectId } from "../shared/type-aliases/object-id";

/** The API representation of paymentCondition inside the OS */
interface ApiFinance {
    paymentCondition: ObjectId;
    value: number;
    cardAdmId?: ObjectId;
    
    /**
     * The paymentConditions object is not saved into API
     * so, we transform his parcels into a string and store into jsonAny property
    *
    * Used for payment condition 999 (PAYMENT_METHODS.other), indicate the parcels selected by the user
    *
    */
   jsonAny?: string;
}

/** at fetching data (GET), the payment conditions have `_id` instead `id` */
export interface ApiFinanceIN extends ApiFinance {
    _id?: string;
}

/** When saving the data (POST/PUT) we should to send `id` instead `_id` */
export interface ApiFinanceOUT extends ApiFinance  {
    id?: ObjectId;
}

export class Finance {
    _id?: string;
    paymentCondition: PaymentCondition = {};
    value: number;
    cardAdmId?: string;
    jsonAny?: string;

    /** @TODO receive a PreviewTitlesPipe instance into params and set the property previewTitles on constructor */
    previewTitles?: PreviewTitle[];
    /** @TODO use getter and setter to previewTitles and define this value on setter  */
    previewTitlesTotalValue?: number;
    /** @TODO use getter and setter to previewTitles and define this value on setter  */
    previewTitlesTotalPercentage?: number;

    constructor(apiObj: ApiFinanceIN, condition: PaymentCondition) {
        if (apiObj._id) {
            this._id = apiObj._id;
        }
        if (apiObj.cardAdmId) {
            this.cardAdmId = apiObj.cardAdmId;
        }
        if (Number.isFinite(apiObj.value)) {
            this.value = apiObj.value;
        }
        if (condition) {
            this.paymentCondition = condition;
            this.paymentCondition.condition = condition._id;
        }
        if (!this.paymentCondition.condition) {
            this.paymentCondition.condition = apiObj.paymentCondition;
        }
        if (apiObj.jsonAny) {
            this.paymentCondition.parcels = JSON.parse(apiObj.jsonAny);
        }
    }

    toAPI(): ApiFinanceOUT {
        let parcels: Parcel[] = [];
        const now = moment().startOf('day');
        if (this.previewTitles) {
            for (const pt of this.previewTitles) {
                let parcel: Parcel = {
                    days: moment(pt.expiration).diff(now, 'days', false),
                    expirationDate: pt.expiration.toISOString(),
                    percentage: pt.percentage,
                    value: pt.value
                }
                parcels.push(parcel);
            }
        } else {
            parcels = this.paymentCondition.parcels;
        }
        console.log(JSON.stringify(parcels))
        const apiFinance: ApiFinanceOUT = {
            value: this.value,
            jsonAny: JSON.stringify(parcels),
            paymentCondition: this.paymentCondition.condition
        }
        if (this._id) {
            apiFinance.id = this._id;
        }
        if (this.cardAdmId) {
            apiFinance.cardAdmId = this.cardAdmId;
        }
        return apiFinance;
    }

}

export interface PreviewTitle {
    parcel: number,
    percentage: number,
    value: number,
    expiration: Date,
    condition?: PaymentCondition
}

