
export enum INVOICE_EVENT_CODES {
         "CC-e" = 110110,
         "Cancelamento" = 110111,
         "Cancelamento por substituição" = 110112,
         "Confirmação da Operação" = 210200,
         "Ciência da Operação " = 210210,
         "Desconhecimento da Operação" = 210220,
         "Operação não Realizada" = 210240,
         "Evento pedido de prorrogação 1º. prazo" = 111500,
         "Evento pedido de prorrogação 2º. prazo" = 111501,
         "Evento Cancelamento de Pedido de Prorrogação 1º. Prazo" = 111502,
         "Evento Cancelamento de Pedido de Prorrogação 2°. Prazo" = 111503,
         "Registro de saída" = 910111,
         "Cancelamento do Registro de saída" = 910112,
         "Ator interessado na NF-e" = 110150,
         "Comprovante de Entrega da NF-e" = 110130,
         "Cancelamento do Comprovante de Entrega da NF-e" = 110131,
}

export interface InvoiceEvent {

    /** CNPJ do emissor 
     * 
     * O CNPJ ou CPF deverão ser informados com os zeros não significativos.
    */
    NtfCnpjEmissor?: string;

    /** CPF do emissor
     * 
     * O CNPJ ou CPF deverão ser informados com os zeros não significativos.
    */
    NtfCpfEmissor?: string;

    /** Número do Documento Fiscal */
    NtfNumero: number;

    /** Número da Série da Nota Fiscal */
    NtfSerie: string;

    /** Ambiente Autorizador 
     * 
     * 1-Produção
     * 2-Homologação
    */
    tpAmb: 1 | 2;

    /** Chave de acesso */
    ChaAcesso?: string;

    /** Chave de acesso do documento substituto */
    ChaAcessoSubstituta?: string;

    /** TAG do grupo de informações do evento */
    EveInf: {

        /** Data e hora do evento no formato AAAA-MM-DDTHH:MM:SS */
        EveDh: string;

        /** Fuso horário do estado 
         * 
         * Preencher com o fuso horário do estado emissor do evento. UTC – Universal Coordinated Time, onde pode ser -02:00 (Fernando de Noronha), -03:00 (Brasília) ou -04:00 (Manaus). No horário de verão serão             -01:00, -02:00 e -03:00 respectivamente. 
        */
        EveFusoHorario: string;

        /** Código do tipo do evento
         * - 110110 - CC-e
         * - 110111 - Cancelamento
         * - 110112 - Cancelamento por substituição
         * - 210200 - Confirmação da Operação
         * - 210210 - Ciência da Operação 
         * - 210220 - Desconhecimento da Operação
         * - 210240 - Operação não Realizada
         * - 111500 - Evento pedido de prorrogação 1º. prazo
         * - 111501 - Evento pedido de prorrogação 2º. prazo
         * - 111502 - Evento Cancelamento de Pedido de Prorrogação 1º. Prazo
         * - 111503 - Evento Cancelamento de Pedido de Prorrogação 2°. Prazo
         * - 910111 - Registro de saída
         * - 910112 - Cancelamento do Registro de saída
         * - 110150 - Ator interessado na NF-e
         * - 110130 – Comprovante de Entrega da NF-e
         * - 110131 – Cancelamento do Comprovante de Entrega da NF-e
        */
        EveTp: number;

        /** Código sequencial do evento 
         * 
         * Sequencial para o mesmo tipo de evento. Na maioria dos casos será "1", havendo a necessidade de emitir o mesmo evento mais de uma vez, utilizar este campo para controle do sequencial.
        */
        EvenSeq: number;

        /** TAG do grupo de detalhes do evento */
        Evedet: {
            /** Descrição do evento 
             * 
             * Texto fixo a ser passado pelo ERP. Informar uma das seguintes descrições:
             * - Carta de Correcao
             * - Cancelamento
             * - Confirmacao da Operacao 
             * - Ciencia da Operacao
             * - Desconhecimento da Operacao
             * - Operacao nao Realizada
             * - Pedido de Prorrogação
             * - Cancelamento de Pedido de Prorrogação
             * - Registro de saida
             * - Ator interessado na NF-e
             * - Comprovante de Entrega da NF-e
             * - Cancelamento Comprovante de Entrega da NF-e
             * */
            EveDesc: string;

            /** Correção a ser considerada, em forma de texto livre 
             * 
             * Obrigatório informar para evento de carta de correção. Correção a ser considerada, texto livre. 
            */
            EveCorrecao?: string;

            /** Texto fixo a ser passado pelo ERP 
             * 
             * Deve ser passado o texto fixo conforme abaixo:
             * A Carta de Correcao e disciplinada pelo paragrafo 1o-A do art. 7o do Convenio S/N, 
             * de 15 de dezembro de 1970 e pode ser utilizada para regularizacao de erro ocorrido na emissao de documento fiscal, 
             * desde que o erro nao esteja relacionado com: 
             * 
             * I - as variaveis que determinam o valor do imposto tais como: 
             * base de calculo, aliquota, diferenca de preco, quantidade, valor da operacao ou da prestacao; 
             * 
             * II - a correcao de dados cadastrais que implique mudanca do remetente ou do destinatario; 
             * 
             * III - a data de emissao ou de saida.
             */
            EveCondUso?: "A Carta de Correcao e disciplinada pelo paragrafo 1o-A do art. 7o do Convenio S/N, de 15 de dezembro de 1970 e pode ser utilizada para regularizacao de erro ocorrido na emissao de documento fiscal, desde que o erro nao esteja relacionado com: I - as variaveis que determinam o valor do imposto tais como: base de calculo, aliquota, diferenca de preco, quantidade, valor da operacao ou da prestacao; II - a correcao de dados cadastrais que implique mudanca do remetente ou do destinatario; III - a data de emissao ou de saida.";

            /** Justificativa do cancelamento 
             * 
             * Obrigatório informar para evento 
             * de cancelamento (110111), Operacao não Realizada (210240) e cancelamento do registro de saída (910112). 
             * 
             * E opcional para os eventos de manifestação (210200, 210210, 210220).
            */
            EvexJust?: string;


            /** Protocolo do documento 
             * 
             * Poderá ser informando no evento de cancelamento, se informado irá agilizar o processo de emissão. 
             * 
             * Caso contrário o InvoiCy irá preencher automaticamente. 
             * 
             * Obrigatório informar o protocolo do evento de registro de saída autorizado quando enviar o evento de cancelamento do registro de saída (910112).
            */
            EvenProt?: number;

            /** Pedido de Prorrogação 
             * 
             * Obrigatório informar para evento de pedido de prorrogação (111500 ou 111501).
             * 
             * Recomenda-se agrupar a maior quantidade de itens em cada Pedido de Prorrogação.
             * Não informar este grupo quando a integração for API Rest.
             * 
             * @ignore
            */
            ProrrogICMS?: {
                /** Número do item da NF-e
                 * 
                 * O número do item deverá ser o mesmo número do item na NF-e.
                 */
                NumeroItem?: number;

                /** Quantidade do item que deseja prorrogar
                 * 
                 * Esta quantidade não pode ser maior que o campo qCom da NF-e.
                 */
                QtdeItem?: number;
            }[];

            /** Cancelamento do pedido de prorrogação
             * 
             * Obrigatório informar para evento de cancelamento de pedido de prorrogação (111502 ou 111503).
             */
            CancPedProrrogICMS?: {
                /** Identificador do evento a ser cancelado
                 * 
                 * A regra de formação do Id é:
                 * ```
                 * “ID” + tpEvento + chave da NF-e + nSeqEvento
                 * ```
                 */
                IDEvePedProrrog?: string;

                /** Número do Protocolo de Autorização do Pedido de Prorrogação a ser cancelado
                 * 
                 * Informar o número do Protocolo de Autorização do Pedido de Prorrogação a ser cancelado.
                 */
                ProtEvePedProrrog?: string;

            };

            /**
             * Evento de Registro de saída para Minas Gerais
             * 
             * Obrigatório informar para evento de registro de saída (910111). 
             * Válido apenas para UF de Minas Gerais (MG).
             */
            RegistroSaida?: {
                /** Placa e UF do veículo responsável pelo transporte das mercadorias descritas na NF-e */
                placaVeiculo: {
                    /** Número da placa */
                    numPlaca: string;

                    /** Sigla da UF */
                    cUF
                }

                /** Placa e UF da carreta responsável pelo transporte das mercadorias descritas na NF-e
                 * 
                 * Não informar este grupo quando a integração for API Rest.
                 * @ingnore
                 */
                placasCarreta: {
                    /** Número da placa */
                    numPlaca: string;

                    /** Sigla da UF */
                    cUF: string;
                }[];
            }

            /** Evento de Ator interessado na NF-e */
            InclusaoAtorInteressado?: {
                /** Autor do evento
                 * 
                 * Informar uma das opções abaixo:
                 * - 1 - Empresa Emitente;
                 * - 2 - Empresa Destinatária;
                 * - 3 - Empresa Transportadora.
                 */
                tpAutor: 1 | 2 | 3;

                /** Pessoas autorizadas a acessar o XML da NF-e 
                 * 
                 * O CNPJ ou CPF deverão ser informados com os zeros não significativos.
                 * 
                 * @ignore
                */
                autXML: {
                    CNPJ_Ator: string;
                    CPF_Ator: string;
                }

                /** Tipo de autorização
                 * 
                 * Preencher somente quando o tpAutor for igual a 1 - Empresa Emitente ou 2 - Empresa Destinatária.
                 * 
                 * Informar uma das opções abaixo:
                 * - 0 – Não permite;
                 * - 1 – Permite o transportador autorizado pelo emitente ou destinatário autorizar outros transportadores para ter acesso ao download da NF-e
                 */
                tpAutorizacao: number;
            }

            /** Evento do Comprovante de Entrega da NF-e 
             * 
             * Obrigatório informar para evento de comprovante de entrega da NF-e (110130).
            */
            ComprovanteEntrega?: {

                /** Autor do evento
                 * 
                 * Preencher com o valor 1:
                 * @default 1 = Empresa Emitente.
                 */
                tpAutor: number;

                /** Data e hora do final da entrega no formato AAAA-MM-DDTHH:MM:SS */
                dhEntrega: string;

                /** Fuso horário do estado
                 * 
                 * Preencher com o fuso horário do estado emissor do evento. UTC – Universal Coordinated Time, onde pode ser -02:00 (Fernando de Noronha), -03:00 (Brasília) ou -04:00 (Manaus). 
                 * No horário de verão serão -01:00, -02:00 e -03:00 respectivamente. 
                 */
                fusoEntrega: string;

                /** Número do documento de identificação da pessoa que assinou o Comprovante de Entrega da NF-e */
                nDoc: string;

                /** Informar o número do Protocolo de Autorização do Evento da NF-e a que se refere este cancelamento.
                 * 
                 * Informar apenas quando for evento 110131 – Cancelamento do Comprovante de Entrega da NF-e
                 */
                nProtCE?: number;

                /** Nome da pessoa que assinou o Comprovante de Entrega da NF-e */
                xNome?: string;

                /** Latitude do ponto de entrega */
                latitude?: number;

                /** Longitude do ponto de entrega */
                longitude?: number;

                /** Hash SHA-1, no formato Base64, resultante da concatenação de:
                 *  
                 * Chave de Acesso da NF-e + Base64 da imagem capturada do Comprovante de Entrega da NF-e 
                 * 
                 * (Exemplo: imagem capturada da assinatura eletrônica, digital do recebedor, foto, etc)
                */
                hashEntrega?: string;

                /** Data e hora da geração do hash do Comprovante de Entrega da NF-e
                 * 
                 * Formato AAAA-MM-DDTHH:MM:SS
                 */
                dhHashEntrega?: string;

                /** Fuso horário da geração do hash do Comprovante de Entrega da NF-e */
                fusoHashEntrega?: string;
            }

        }

    }
}