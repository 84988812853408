import { Utilities } from "../../../../../class/utilities";
import { Part } from "../../../../../interface/part";
import { IOrderItem } from "../interface/order-item.interface";

export class OrderItem implements IOrderItem {

  id?: string;
  private _amount: number;
  private _saleValue: number;
  private _additionalDiscount: number;
  private _discountValue: number;
  part: string;
  purchaseAverage: number;
  purchaseValue: number;
  liquidValue: number;
  mechanic: string;
  observation: string;
  seq: number;
  
  nroPedido: string;
  seqItem: number;

  lastUpdate?: Date;
  removedStock?: number = 0;
  available?: number = 1;
  oldAmount?: number = 0;
  oldUnitValue?: number;

  // local only
  partObject: Part;

  constructor(data?: IOrderItem, part?: Part, amount?: number) {
    if(data){
      this.seq = data.seq;
      this.removedStock = data.amount;
      this.mechanic = data.mechanic ? data.mechanic : "";
      this.observation = data.observation ? data.observation : "";
      this.partObject = part;
      this.available = 1;
      this.oldUnitValue = data.saleValue || (part ? part.saleValue : 0);
      this._saleValue = data.saleValue || (part ? part.saleValue : 0);
      this.nroPedido = data.nroPedido;
      this.seqItem = data.seqItem;

      if(part){
        this.part = this.partObject.id;
        this.purchaseAverage = part.averageCost || part.saleValue;
        this.purchaseValue = part.cost || part.saleValue;
      }
      this.oldAmount = amount || 0;
      this._amount = amount || 1;

      // using setter here to calculate liquidValue
      this.additionalDiscount = data.additionalDiscount || 0;
    }
  }

  static fromPart(part: Part, amount = 1): OrderItem {
    const orderItem = new OrderItem();
    orderItem.part = part.id;
    orderItem.partObject = part;
    orderItem.purchaseAverage = part.averageCost || part.saleValue;
    orderItem.purchaseValue = part.cost || part.saleValue;
    orderItem.available = 1;
    orderItem._saleValue = part.saleValue || 0;
    orderItem._amount = amount;
    // using setter here to calculate liquidValue
    orderItem.additionalDiscount = 0;
    return orderItem;
  }

  private _calculateValues() {
    const grossValue = this._amount * this._saleValue;
    let discountValue = grossValue - this._discountValue ? (this._discountValue  * this._amount) : Utilities.calcPercentage(this._additionalDiscount || 0, grossValue);
    
    this.liquidValue = grossValue - discountValue;
  }

  public get amount(): number {
    return this._amount;
  }
  public set amount(value: number) {
    if(!Number.isFinite(this.oldAmount)){
      this.oldAmount = value;
    }
    this._amount = value;
    this._calculateValues();
  }

  public get saleValue(): number {
    return this._saleValue;
  }
  public set saleValue(value: number) {
    this._saleValue = value;
    this._calculateValues();
  }

  public get additionalDiscount(): number {
    return this._additionalDiscount;
  }
  public set additionalDiscount(value: number) {
    this._additionalDiscount = value;
    this._discountValue = Utilities.calcPercentage(value, this.saleValue)
    this._calculateValues();
  }

  public get discountValue(): number {
    return this._discountValue;
  }

  public set discountValue(value: number) {
    this._discountValue = value;
    this._additionalDiscount = Utilities.getPercentage(value, this.saleValue)
    this._calculateValues();
  }

  /**
   * Recalculate the part stock, don't forgot to update the part
   *
   * @returns the part with new stock values
   */
  removeStock(): Part {
    let diffStock = this._amount - this.removedStock;
    this.removedStock = this._amount;

    if (diffStock > 0) {
      if (diffStock <= this.partObject.availableStock) {
        this.partObject.availableStock = this.partObject.availableStock - diffStock;
        this.partObject.requestedStock = this.partObject.requestedStock + diffStock;
        return this.partObject;
      }
    }

    if (diffStock < 0) {
      diffStock = diffStock * -1;
      if (diffStock <= this.partObject.requestedStock) {
        this.partObject.availableStock = this.partObject.availableStock + diffStock;
        this.partObject.requestedStock = this.partObject.requestedStock - diffStock;
        return this.partObject;
      }
    }
    return null;
  }


  public getObjectToSave(): IOrderItem {
    return {
      part: this.part,
      amount: this._amount,
      additionalDiscount: this._additionalDiscount,
      discountValue: this._discountValue,
      liquidValue: this.liquidValue,
      mechanic: this.mechanic,
      observation: this.observation,
      purchaseValue: this.purchaseValue || this.saleValue,
      purchaseAverage: this.purchaseAverage || this.saleValue,
      saleValue: this.saleValue,
      seq: this.seq
    }
  }

  needStock(): boolean{
    return this.amount > this.partObject.stock;
  }
}
